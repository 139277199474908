import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import BrandLogoText from "components/Misc/BrandLogoText";

function CookiePolicyContent(props) {
    const cookies = [
        {
            name: "cookie_consent",
            description: "Kasutatakse meeldejätmiseks, kas külastaja on nõustunud küpsiste kasutustingimustega, või tuleks näidata vastavat teadet. Aegub peale 30 päeva möödumist."
        },
        {
            name: "cookie_consent-legacy",
            description: "Kasutatakse meeldejätmiseks, kas külastaja on nõustunud küpsiste kasutustingimustega, või tuleks näidata vastavat teadet. Aegub peale 30 päeva möödumist."
        },
        {
            name: "csrftoken",
            description: "Kaitseb CSRF rünnakute vastu. Aegub peale 1 aasta möödumist."
        },
        {
            name: "sessionid",
            description: "Sessiooniküpsis, mis aegub peale kasutaja sessiooni lõppemist või veebilehitseja sulgemist."
        },
    ];

    const columns = [
        {
            dataField: "name",
            text: "Nimi",
        },
        {
            dataField: "description",
            text: "Kirjeldus",
        },
    ];

    return (
        <article className="regular-14 text-left">
            <h3 className="heading text-center">Küpsised</h3>
            <p>18.08.2020</p>
            <p>Eesti Pandipakendi e-keskkonna võrgulehel kasutatatakse küpsiseid. Küpsistest saate loobuda igal ajal, muutes kasutatava seadme veebilehitseja seadistusi ja kustutades salvestatud küpsised.</p>

            <p className="semibold-14">Veebis kasutatavad küpsised</p>
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="no-top-border"
                keyField="name"
                data={cookies}
                columns={columns}
            />
        </article>
    );
}

function CookiePolicy(props) {
    return (
        <Container className="align-middle">
            <Row className="text-center justify-content-center">
                <Col>
                    <Card>
                        <CardBody className="px-3 px-md-4" style={{ "paddingTop": "32px" }}>
                            <BrandLogoText />
                            <Row>
                                <Col>
                                    <CookiePolicyContent />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(CookiePolicy);
