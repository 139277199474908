import React from "react";

import MassSearch from "../../components/Reports/Mass/Search/MassSearch";
import MassTable from "../../components/Reports/Mass/Table/MassTable";

class MassReport extends React.Component {

    render() {
        return (
            <>
                <MassSearch />
                <div className="mt-3">
                    <MassTable />
                </div>
            </>
        );
    }
}

export default MassReport;