// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config
import React from "react";
import urls from "config/urls";

import PackageRegistryView from "views/admin/PackageRegistry";
import ApplicationsView from "components/Admin/Registry/Applications/ApplicationsView";
import TestingView from "components/Admin/Registry/Testing/TestingView";
import RetestingView from "components/Admin/Registry/Retesting/RetestingView";
import PackagesView from "components/Admin/Registry/Packages/PackagesView";
import PackageDetailsView from "components/Admin/Registry/PackageDetails/PackageDetails";
import DebtsView from "views/admin/Debts";
import AdminInvoicesView from "views/admin/Invoices";
import PrivacyPolicyView from "./views/public/PrivacyPolicy";
import CookiePolicyView from "./views/public/CookiePolicy";

import UsersView from "views/admin/Users";
import ClientsView from "components/Admin/Users/Clients/ClientsView";
import ClientView from "components/Admin/Users/Clients/Client/ClientView";
import Profile from "components/Admin/Users/Clients/Client/Profile";
import EppProfile from "./components/Profile/Profile/Profile";
import PartnersView from "components/Admin/Users/Partners/PartnersView";
import EppUsersView from "components/Admin/Users/EPP/Users";
import Users from "components/Admin/Users/Clients/Client/Users";
import OriginalSalesReportView from "views/Reports/OriginalSalesReport";
import RepairSalesReportView from "views/Reports/RepairSalesReport";
import AdminReportsView from "views/admin/Reports";
import AdminSalesReportsView from "views/admin/SalesReports";
import UnconfirmedRepairReports from "views/admin/UnconfirmedRepairReports";
import UnsubmittedRepairReports from "views/admin/UnsubmittedSalesReports";
import SettingsView from "views/admin/Settings";
import Categories from "components/Admin/Settings/Categories";
import Types from "components/Admin/Settings/Types";
import Colors from "components/Admin/Settings/Colors";
import Volumes from "components/Admin/Settings/Volumes";
import ProcessFees from "components/Admin/Settings/ProcessFees";
import ReturnReportListView from "./components/ReturnReports/ListView";
import ReturnReportDetailsView from "./components/ReturnReports/DetailsView";
import AdditionalDepositReportListView from "./components/AdditionalDepositReports/ListView";
import AdditionalDepositReportDetailsView from "./components/AdditionalDepositReports/DetailsView";

const routes = [
    {
        path: urls.EPP_REGISTRY,
        label: "",
        component: PackageRegistryView,
        routes: [
            {
                path: urls.EPP_REGISTRY_APPLICATIONS,
                label: "Taotlused",
                component: ApplicationsView,
            },
            {
                path: urls.EPP_REGISTRY_TESTING,
                label: "Testimine",
                component: TestingView,
            },
            {
                path: urls.EPP_REGISTRY_RETESTING,
                label: "Kordustestimine",
                component: RetestingView,
            },
            {
                path: urls.EPP_REGISTRY_PACKAGES,
                label: "Kõik pakendid",
                component: PackagesView,
            },
            {
                path: urls.EPP_REGISTRY_PACKAGE_DETAILS,
                label: "Pakendiregister",
                component: PackageDetailsView,
            },
        ],
    },
    {
        exact: true,
        path: urls.REPORTS_SALE_FIX,
        label: "Müügiaruanne",
        component: RepairSalesReportView,
    },
    {
        exact: true,
        path: urls.REPORTS_SALE,
        label: "Müügiaruanne",
        component: OriginalSalesReportView,
    },
    {
        path: urls.EPP_REPORTS,
        label: "Müügiaruanded",
        component: AdminReportsView,
        routes: [
            {
                path: urls.EPP_REPORTS_SALES,
                label: "",
                exact: true,
                component: AdminSalesReportsView,
            },
            {
                path: urls.EPP_REPORTS_SALES_UNSUBMITTED,
                label: "Esitamata",
                exact: true,
                component: UnsubmittedRepairReports,
            },
            {
                path: urls.EPP_UNCONFIRMED_REPAIR_REPORTS,
                label: "Kinnitamata",
                exact: true,
                component: UnconfirmedRepairReports,
            },
            {
                exact: true,
                path: urls.EPP_REPORTS_RETURN_DETAILS,
                label: "Tagastusaruanne",
                component: ({match}) => <ReturnReportDetailsView id={Number(match.params.id)} />,
            },
            {
                path: urls.EPP_REPORTS_RETURN_LIST,
                exact: true,
                label: "Tagastusaruanded",
                component: ReturnReportListView,
            },
            {
                exact: true,
                path: urls.EPP_REPORTS_ADDITIONAL_DEPOSIT_DETAILS,
                label: "Täiendava pandi aruanne",
                component: ({match}) => <AdditionalDepositReportDetailsView id={Number(match.params.id)} />,
            },
            {
                path: urls.EPP_REPORTS_ADDITIONAL_DEPOSIT_LIST,
                exact: true,
                label: "Täiendava pandi aruanded",
                component: AdditionalDepositReportListView,
            },
        ],
    },
    {
        path: urls.EPP_DEBTS,
        exact: true,
        label: "Võlgnevused",
        component: DebtsView,
    },
    {
        path: urls.EPP_DEBTS_INVOICES,
        exact: true,
        label: "Arvete saldo",
        component: AdminInvoicesView,
    },
    {
        path: urls.EPP_USERS,
        label: "",
        component: UsersView,
        routes: [
            {
                path: urls.EPP_USERS_CLIENTS,
                label: "Kliendid",
                exact: true,
                component: ClientsView,
            },
            {
                path: urls.EPP_USERS_CLIENTS_CLIENT,
                label: "Klient",
                component: ClientView,
                routes: [
                    {
                        path: urls.EPP_USERS_CLIENTS_CLIENT_PROFILE,
                        exact: true,
                        component: Profile,
                    },
                    {
                        path: urls.EPP_USERS_CLIENTS_CLIENT_USERS,
                        exact: true,
                        component: Users,
                    },
                ],
            },
            {
                path: urls.EPP_USERS_PARTNERS,
                label: "Partnerid",
                exact: true,
                component: PartnersView,
            },
            {
                path: urls.EPP_USERS_EPP,
                label: "EPP kasutajad",
                exact: true,
                component: EppUsersView,
            },
        ],
    },
    {
        path: urls.EPP_PROFILE,
        label: "Profiil",
        component: EppProfile,
    },
    {
        path: urls.EPP_SETTINGS,
        label: "Seaded",
        component: SettingsView,
        routes: [
            {
                path: urls.EPP_SETTINGS_CATEGORIES,
                label: "Kategooriad",
                component: Categories,
            },
            {
                path: urls.EPP_SETTINGS_TYPES,
                label: "Tüübid",
                component: Types,
            },
            {
                path: urls.EPP_SETTINGS_COLORS,
                label: "Värvid",
                component: Colors,
            },
            {
                path: urls.EPP_SETTINGS_VOLUMES,
                label: "Mahud",
                component: Volumes,
            },
            {
                path: urls.EPP_SETTINGS_PROCESS_FEES,
                label: "Käitlustasud",
                component: ProcessFees,
            },
        ],
    },
    {
        path: urls.PRIVACY_POLICY,
        label: "Privaatsuspoliitika",
        component: PrivacyPolicyView,
    },
    {
        path: urls.COOKIE_POLICY,
        label: "Küpsised",
        component: CookiePolicyView,
    },
];

export default routes;
