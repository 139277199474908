import React from "react";

import Select from "components/Form/Select";
import { components } from "react-select";
import { PackageConditionValues } from "./options";
import { TestConditionCircle, PackageConditionsColors } from "components/Form/PackageConditions/PackageConditionsIcons";

import { ReactComponent as SyncStatusIcon } from "assets/icons/sync.svg";
import { ReactComponent as ReportsStatusIcon } from "assets/icons/raport.svg";
import { ReactComponent as DebtStatusIcon } from "assets/icons/debt.svg";

import "./PackageConditionsSelect.scss";

const ICON_CLASS_NAME = "package-condition-icon mr-1";

const CustomMultiValueContainer = (props) => {
    if (props.data.icon) {
        return (
            <props.data.icon className={ICON_CLASS_NAME} />
        );
    }
    return (
        <components.MultiValueContainer {...props}>
            {props.children}
        </components.MultiValueContainer>
    )
}

const IconOption = (props) => (
    <components.Option {...props}>
        <props.data.icon className={ICON_CLASS_NAME} />
        {props.data.label}
    </components.Option>
);

const LastTestHasStandardCommentIcon = () => <TestConditionCircle svgMode={true} fill={PackageConditionsColors[PackageConditionValues.LAST_TEST_HAS_STANDARD_COMMENT]} />
const LastTestHasCommentIcon = () => <TestConditionCircle svgMode={true} fill={PackageConditionsColors[PackageConditionValues.LAST_TEST_HAS_COMMENT]} />

export default function PackageConditionsSelect({ value, onChange, hideTestConditions }) {
    let options = [
        { value: PackageConditionValues.UNSYNCED, label: "Sünkroniseerimata", icon: SyncStatusIcon },
        { value: PackageConditionValues.UNSUBMITTED_REPORTS, label: "Esitamata aruanded", icon: ReportsStatusIcon },
        { value: PackageConditionValues.DEBT, label: "Võlgnevus", icon: DebtStatusIcon },
    ];
    if (!hideTestConditions) {
        options = [
            ...options,
            { value: PackageConditionValues.LAST_TEST_HAS_STANDARD_COMMENT, label: "Testis tüüpkommentaare", icon: LastTestHasStandardCommentIcon },
            { value: PackageConditionValues.LAST_TEST_HAS_COMMENT, label: "Testis tavakommentaar", icon: LastTestHasCommentIcon }
        ];
    }

    return (
        < div className="package-conditions-select-wrapper" >
            <Select
                isMulti
                placeholder="Seisundid"
                className="mb-2"
                options={options}
                value={value}
                onChange={onChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isClearable={false}
                components={{ MultiValueContainer: CustomMultiValueContainer, Option: IconOption }}
            />
        </div >
    )
}

PackageConditionsSelect.defaultProps = {
    value: undefined,
    onChange: undefined,
    hideTestConditions: false // needed to hide test options in application search where there are no tests yet
}
