import React, {Component} from 'react';
import {Route} from "react-router-dom";

// routes
import {withRouter} from "react-router";


class RouteWithSubRoutes extends Component {

    render() {
        // A special wrapper for <Route> that knows how to
        // handle "sub"-routes by passing them in a `routes`
        // prop to the component it renders.
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={props => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes}/>
                    )}
                />
            );
        }

        return (
            <>
                {this.props.routes.map((route, i) => (
                    <RouteWithSubRoutes key={this.props.location.key + i} {...route} />
                ))}
            </>
        );
    }
}

export default withRouter(RouteWithSubRoutes);