import { isClientMode, isPartnerMode, isAdminMode } from "util/Access";

export const ID_PLACEHOLDER = ":id(\\d+)";

const PARTNER_PACKAGES = "/packages";
const INVOICES = "/invoices";
const EPP_REGISTRY = "/registry";
const EPP_USERS = "/users";
const EPP_DEBTS = "/debts";

const urls = {
    DOMAIN: "/",
    ANY: "/*",
    DASHBOARD: "/dashboard",
    CONTRACT: "/dashboard/contract",
    PACKAGES: "/packages",
    ADD_PACKAGES: "/packages/add-package",
    APPLICATIONS: "/applications",
    REPORTS: "/reports",
    REPORTS_SALES: "/reports/sales",
    REPORTS_SALE: `/reports/sale/${ID_PLACEHOLDER}`,
    REPORTS_SALE_FIX: `/reports/sale/${ID_PLACEHOLDER}/repair`,
    REPORTS_SUMMARY: "/reports/summary",
    REPORTS_MASS: "/reports/mass",
    REPORTS_RETURN_LIST: "/reports/returns",
    REPORTS_RETURN_DETAILS: `/reports/returns/${ID_PLACEHOLDER}`,
    REPORTS_ADDITIONAL_DEPOSIT_LIST: "/reports/additional-deposit/",
    REPORTS_ADDITIONAL_DEPOSIT_DETAILS: `/reports/additional-deposit/${ID_PLACEHOLDER}`,
    INVOICES: INVOICES,
    INVOICES_PREVIEW: `${INVOICES}/${ID_PLACEHOLDER}`,
    PROFILE: "/profile",
    PROFILE_EDIT: "/profile/edit",
    PROFILE_CONTRACT: "/profile/contract",
    PROFILE_USERS: "/profile/users",
    PROFILE_NOTIFICATIONS: "/profile/notifications",

    //Public
    LOGIN: "/login",
    LOGIN_WITH_EMAIL: "/login/email",
    FORGOT_PASSWORD: "/forgot-password",
    CHANGE_PASSWORD: "/change-password/:uid/:token",
    PRIVACY_POLICY: "/privacy-policy",
    COOKIE_POLICY: "/cookies",

    // Partner specific
    PARTNER_PACKAGES,
    PARTNER_PACKAGES_IN_TESTING: `${PARTNER_PACKAGES}/testing`,
    PARTNER_PACKAGES_IN_RETESTING: `${PARTNER_PACKAGES}/retesting`,
    PARTNER_PACKAGES_UNCONFIRMED: `${PARTNER_PACKAGES}/unconfirmed`,
    PARTNER_PACKAGES_CONFIRMED: `${PARTNER_PACKAGES}/confirmed`,
    PARTNER_PACKAGES_ALL: `${PARTNER_PACKAGES}/all`,

    // EPP Admin specific
    EPP_REGISTRY,
    EPP_REGISTRY_APPLICATIONS: `${EPP_REGISTRY}/applications`,
    EPP_REGISTRY_TESTING: `${EPP_REGISTRY}/testing`,
    EPP_REGISTRY_RETESTING: `${EPP_REGISTRY}/retesting`,
    EPP_REGISTRY_PACKAGES: `${EPP_REGISTRY}/packages`,
    EPP_REGISTRY_PACKAGE_DETAILS: `${EPP_REGISTRY}/package/${ID_PLACEHOLDER}`,
    EPP_REPORTS: "/reports",
    EPP_REPORTS_SALES: "/reports/sales",
    EPP_REPORTS_SALES_UNSUBMITTED: "/reports/unsubmitted",
    EPP_REPORTS_RETURN_LIST: "/reports/returns/",
    EPP_REPORTS_RETURN_DETAILS: `/reports/returns/${ID_PLACEHOLDER}`,
    EPP_REPORTS_ADDITIONAL_DEPOSIT_LIST: "/reports/additional-deposit/",
    EPP_REPORTS_ADDITIONAL_DEPOSIT_DETAILS: `/reports/additional-deposit/${ID_PLACEHOLDER}`,
    EPP_UNCONFIRMED_REPAIR_REPORTS: "/reports/unconfirmed",
    EPP_DEBTS: `${EPP_DEBTS}`,
    EPP_DEBTS_INVOICES: `/client-invoices/${ID_PLACEHOLDER}`,
    EPP_USERS,
    EPP_PROFILE: "/epp-profile",
    EPP_USERS_CLIENTS: `${EPP_USERS}/clients`,
    EPP_USERS_CLIENTS_CLIENT: `${EPP_USERS}/clients/:id`,
    EPP_USERS_CLIENTS_CLIENT_PROFILE: `${EPP_USERS}/clients/:id/profile`,
    EPP_USERS_CLIENTS_CLIENT_USERS: `${EPP_USERS}/clients/:id/users`,
    EPP_USERS_PARTNERS: `${EPP_USERS}/partners`,
    EPP_USERS_EPP: `${EPP_USERS}/epp`,
    EPP_SETTINGS: "/settings",
    EPP_SETTINGS_CATEGORIES: "/settings/categories",
    EPP_SETTINGS_TYPES: "/settings/types",
    EPP_SETTINGS_COLORS: "/settings/colors",
    EPP_SETTINGS_VOLUMES: "/settings/volumes",
    EPP_SETTINGS_PROCESS_FEES: "/settings/process-fees",
};

export function getAdminClientProfileUrl(id) {
    return urls.EPP_USERS_CLIENTS_CLIENT_PROFILE.replace(":id", id);
}
export function getAdminClientUsersUrl(id) {
    return urls.EPP_USERS_CLIENTS_CLIENT_USERS.replace(":id", id);
}

export function getSaleReportUrl(id) {
    return urls.REPORTS_SALE.replace(ID_PLACEHOLDER, id);
}

export function getSaleReportFixUrl(id) {
    return urls.REPORTS_SALE_FIX.replace(ID_PLACEHOLDER, id);
}

export function getSalesReportOrRepairUrl(id, isRepair) {
    if (isRepair) {
        return urls.REPORTS_SALE_FIX.replace(ID_PLACEHOLDER, id);
    }
    return urls.REPORTS_SALE.replace(ID_PLACEHOLDER, id);
}

export function getInvoicePreviewUrl(id) {
    return urls.INVOICES_PREVIEW.replace(ID_PLACEHOLDER, id);
}

export function getAdminPackageDetailsUrl(id) {
    return urls.EPP_REGISTRY_PACKAGE_DETAILS.replace(ID_PLACEHOLDER, id);
}

export function getAdminDebtsClientInvoicesUrl(id) {
    return urls.EPP_DEBTS_INVOICES.replace(ID_PLACEHOLDER, id);
}

export function getDefaultUrl(user) {
    if (isAdminMode()) {
        return urls.EPP_REGISTRY_APPLICATIONS;
    } else if (isPartnerMode()) {
        return urls.PARTNER_PACKAGES_IN_TESTING;
    } else if (isClientMode()) {
        return urls.DASHBOARD;
    }

    return urls.DOMAIN;
}

export default urls;
