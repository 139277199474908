import * as actionTypes from './actionTypes';

export const packagesFetchStart = () => {
    return {
        type: actionTypes.PACKAGES_FETCH_START
    }
};

export const packagesFetchSuccess = (packagesList) => {
    return {
        type: actionTypes.PACKAGES_FETCH_SUCCESS,
        packagesList: packagesList
    }
};

export const packagesFetchFail = (err) => {
    return {
        type: actionTypes.PACKAGES_FETCH_FAIL,
        error: err
    }
};

export const eanTypesFetchStart = () => {
    return {
        type: actionTypes.EAN_TYPES_FETCH_START,
    }
};

export const eanTypesFetchSuccess = (eanTypes) => {
    return {
        type: actionTypes.EAN_TYPES_FETCH_SUCCESS,
        eanTypes: eanTypes
    }
};

export const eanTypesFetchFail = (err) => {
    return {
        type: actionTypes.EAN_TYPES_FETCH_FAIL,
        error: err
    }
};

export const categoriesFetchStart = () => {
    return {
        type: actionTypes.PRODUCT_CATEGORIES_FETCH_START,
    }
};

export const categoriesFetchSuccess = (categories) => {
    return {
        type: actionTypes.PRODUCT_CATEGORIES_FETCH_SUCCESS,
        categories: categories
    }
};

export const categoriesFetchFail = (err) => {
    return {
        type: actionTypes.PRODUCT_CATEGORIES_FETCH_FAIL,
        error: err
    }
};

export const packageTypesFetchStart = () => {
    return {
        type: actionTypes.PACKAGE_TYPES_FETCH_START
    }
};

export const packageTypesFetchSuccess = (packageTypes) => {
    return {
        type: actionTypes.PACKAGE_TYPES_FETCH_SUCCESS,
        packageTypes: packageTypes
    }
};

export const packageTypesFetchFail = (err) => {
    return {
        type: actionTypes.PACKAGE_TYPES_FETCH_FAIL,
        error: err
    }
};

export const packageColorsFetchStart = () => {
    return {
        type: actionTypes.PACKAGE_COLORS_FETCH_START
    }
};

export const packageColorsFetchSuccess = (packageColors) => {
    return {
        type: actionTypes.PACKAGE_COLORS_FETCH_SUCCESS,
        packageColors: packageColors
    }
};

export const packageColorsFetchFail = (err) => {
    return {
        type: actionTypes.PACKAGE_COLORS_FETCH_FAIL,
        error: err
    }
};

export const packageVolumesFetchStart = () => {
    return {
        type: actionTypes.PACKAGE_VOLUMES_FETCH_START
    }
};

export const packageVolumesFetchSuccess = (packageVolumes) => {
    return {
        type: actionTypes.PACKAGE_VOLUMES_FETCH_SUCCESS,
        packageVolumes: packageVolumes
    }
};

export const packageVolumesFetchFail = (err) => {
    return {
        type: actionTypes.PACKAGE_VOLUMES_FETCH_FAIL,
        error: err
    }
};

export const packageStatusesFetchStart = () => {
    return {
        type: actionTypes.PACKAGE_STATUSES_FETCH_START
    }
};

export const packageStatusesFetchSuccess = (packageStatuses) => {
    return {
        type: actionTypes.PACKAGE_STATUSES_FETCH_SUCCESS,
        packageStatuses: packageStatuses
    }
};

export const packageStatusesFetchFail = (err) => {
    return {
        type: actionTypes.PACKAGE_STATUSES_FETCH_FAIL,
        error: err
    }
};



export const packageTestResultsFetchStart = () => {
    return {
        type: actionTypes.PACKAGE_TEST_RESULTS_FETCH_START
    }
};

export const packageTestResultsFetchSuccess = (packageTestResults) => {
    return {
        type: actionTypes.PACKAGE_TEST_RESULTS_FETCH_SUCCESS,
        packageTestResults: packageTestResults
    }
};

export const packageTestResultsFetchFail = (err) => {
    return {
        type: actionTypes.PACKAGE_TEST_RESULTS_FETCH_FAIL,
        error: err
    }
};

export const packagePostStart = () => {
    return {
        type: actionTypes.PACKAGE_POST_START
    }
};

export const packagePostSuccess = () => {
    return {
        type: actionTypes.PACKAGE_POST_SUCCESS,
        error: null
    }
};

export const packagePostFail = (err) => {
    return {
        type: actionTypes.PACKAGE_POST_FAIL,
        error: err
    }
};