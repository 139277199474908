import React, { useEffect } from "react";
import { useTranslation, Trans } from 'react-i18next';

import {
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Row,
    Col,
} from "reactstrap";

import { fetchNotifications } from "util/Notifications";
import { useSelector } from "react-redux";
import NotificationDropdownItems from "components/Profile/Notifications/NotificationDropdown/NotificationDropdownItems";

import "./NotificationDropdown.scss";

import { ReactComponent as NotificationsIcon } from "assets/icons/notifications.svg";
import { useHistory } from "react-router";
import urls from "config/urls";

export default function NotificationDropdown(props) {
    const { t } = useTranslation();
    const notifications = useSelector(
        (state) => state.notifications.notifications
    );
    const history = useHistory();

    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sumProperty = (arr) => {
        return arr.reduce((total, obj) => {
            if (obj.is_seen === false) {
                return total + 1;
            }
            return total;
        }, 0);
    };

    const renderNotificationsCount = (notifications) => {
        const unseenNotificationsCount = sumProperty(notifications);
        if (unseenNotificationsCount < 0) {
            return null;
        }

        const baseClasses = "badge notification-number";

        if (unseenNotificationsCount > 99) {
            return <span className={baseClasses + " notification-number-limit"}>99+</span>;
        }

        return <span className={baseClasses}>{unseenNotificationsCount}</span>;
    };

    return (
        <UncontrolledButtonDropdown
            direction={"down"}
            className={`user-mode-selection regular-14 pointer'`}
            inNavbar
        >
            <DropdownToggle tag="div">
                <Row className="align-items-center">
                    <Col className="pr-0 col-auto mr-3">
                        <div className="notification-container btn">
                            <NotificationsIcon />
                            {renderNotificationsCount(notifications)}
                        </div>
                    </Col>
                </Row>
            </DropdownToggle>
            <DropdownMenu
                className="notification-dropdown-menu"
            >
                <DropdownItem className="notification-option">
                    <span className="bold-16">{t("notifications.dropdown.header", "Teavitused")}</span>
                </DropdownItem>
                <hr />
                {notifications && notifications.length === 0 && (
                    <DropdownItem className="notification-empty-message">
                        <span>{t("notifications.dropdown.noNotificationsText", "Teavitused puuduvad!")}</span>
                    </DropdownItem>
                )}
                <NotificationDropdownItems notifications={notifications} />
                <DropdownItem className="notification-option text-right all-notifications-link">
                    <span
                        onClick={() => history.push(urls.PROFILE_NOTIFICATIONS)}
                        className="bold-12 color-info notification-link"
                    >
                        {notifications.length > 3 &&
                            <Trans i18nKey="notifications.dropdown.viewAllText2">Vaata kõiki {{ count: notifications.length }}</Trans>
                        }
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>
    );
}
