import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Card, CardBody } from "reactstrap";
// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import WrappedLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";

import "./MassTable.scss";
import { withTranslation } from "react-i18next";

class MassReportTable extends React.Component {
    static propTypes = {
        filters: PropTypes.object.isRequired,
        massList: PropTypes.array,
        massListLoading: PropTypes.bool,
        error: PropTypes.any,
    };

    render() {
        let content;
        if (this.props.massListLoading) {
            return <WrappedLoader />;
        } else if (!this.props.massList) {
            return false; //content = this.renderText(<span>Otsi.</span>);
        } else {
            content = this.renderTable();
        }

        return (
            <>
                <Card className="mass-report-card">
                    <CardBody className="p-0">{content}</CardBody>
                </Card>
            </>
        );
    }

    renderCenteredContent = (content) => {
        return <CenteredRowCol>{content}</CenteredRowCol>;
    };

    renderTable() {
        const { t } = this.props;
        const data = this.props.massList;

        /*  Had to add the first id column with hidden: true, 
            because it didn't work correctly with all other columns being
            negative filter (!filter...) values.
        */
        const columns = [
            {
                dataField: "id",
                text: t("reports.filter.id", "ID"),
                classes: "semibold-14",
                hidden: true,
            },
            {
                dataField: "EAN",
                text: t("reports.filter.ean", "EAN"),
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "name",
                text: t("reports.filter.packageName", "Nimetus"),
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "package_type",
                text: t("reports.filter.packageType", "Pakendi tüüp"),
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "weight",
                text: t("reports.filter.weight", "kaal(grammi)"),
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "volume",
                text: t("reports.filter.volume", "Maht"),
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "total_quantity",
                text: t("reports.filter.quantity", "Deklareeritud(tk)"),
                classes: "regular-14",
                sort: true,
            },
            {
                dataField: "total_weight",
                text: t("reports.filter.totalWeight", "Kaal(tonni)"),
                classes: "regular-14",
                sort: true,
            },
        ];
        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="sales-details-table no-top-border"
                keyField="id"
                data={data}
                columns={columns}
                noDataIndication={
                    <this.NoDataIndication
                        loading={this.props.massListLoading}
                        error={this.props.error}
                        t={t}
                    />
                }
            />
        );
    }

    NoDataIndication(props) {
        let content = props.t(
            "reports.massReportNoPackagesErrorMessage",
            "Ei leitud ühtegi pakendit, mis vastaks otsingutingimustele."
        );
        if (props.loading) {
            content = <WrappedLoader />;
        } else if (props.error) {
            content = <span className="color-error">{props.error}</span>;
        }

        return <div>{content}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        filters: state.massReport.filters,
        massList: state.massReport.massList,
        massListLoading: state.massReport.massListLoading,
        error: state.massReport.error,
    };
};

export default connect(mapStateToProps)(
    withTranslation("common")(MassReportTable)
);
