import React from 'react';

import { NavLink, Route } from "react-router-dom";
import { Switch } from "react-router";

import urls from "config/urls";

class PackageRegistryView extends React.Component {
    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={props => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        return (
            <>
                <div className="navigation-buttons pb-3">
                    <NavLink to={urls.EPP_REGISTRY_APPLICATIONS} className="btn btn-default mr-1 mb-2">Taotlused</NavLink>
                    <NavLink to={urls.EPP_REGISTRY_TESTING} className="btn btn-default ml-1 mr-1 mb-2">Testimine</NavLink>
                    <NavLink to={urls.EPP_REGISTRY_RETESTING} className="btn btn-default ml-1 mr-1 mb-2">Kordustestimine</NavLink>
                    <NavLink exact to={urls.EPP_REGISTRY_PACKAGES} className="btn btn-default ml-1 mb-2">Kõik pakendid</NavLink>
                </div>

                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>

            </>
        );
    }
}

export default PackageRegistryView;
