import React, { useEffect } from "react";
import { fetchAdminSettingsColors } from "util/admin/Settings";
import { useSelector } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import WrappedLoader from "components/Loader/WrappedLoader";

function NoDataIndication(props) {
    let content = "Ei leitud kasutajaid.";
    if (props.loading) {
        content = <WrappedLoader />;
    }

    return <div className="text-center">{content}</div>;
}

export const Colors = (props) => {
    // Getting values from redux state
    const colors = useSelector((state) => state.admin.settings.colors);
    const loading = useSelector((state) => state.admin.settings.loading);

    const columns = [
        {
            dataField: "value",
            text: "Värvus",
            sort: true,
            classes: "semibold-14",
        },
    ];

    // useEffect is used instead of lifecycle hooks
    // if the second argument is [], then content is executed only once on mount
    useEffect(() => {
        if (colors.length === 0) {
            fetchAdminSettingsColors();
        }
    }, [colors.length]);

    return (
        <Card>
            <CardHeader>
                <Row className="align-items-center">
                    <Col>
                        <span className="heading">Pakendite värvused</span>
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="p-0">
                <BootstrapTable
                    bootstrap4
                    bordered={false}
                    classes="table-layout-auto no-top-border"
                    wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                    keyField="id"
                    data={colors}
                    columns={columns}
                    noDataIndication={<NoDataIndication loading={loading} />}
                />
            </CardBody>
        </Card>
    );
};

export default Colors;
