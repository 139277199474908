import React from 'react';

import {Route} from "react-router-dom";
import {Switch} from "react-router";

class AdminUsersView extends React.Component {
    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={props => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes}/>
                    )}
                />
            );
        }

        return (
            <>
                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>

            </>
        );
    }
}

export default AdminUsersView;