import * as actionTypes from "redux/actions/actionTypes";

export const adminUnconfirmedSalesReportsFetchStart = () => {
    return {
        type: actionTypes.ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_START,
    };
};

export const adminUnconfirmedSalesReportsFetchSuccess = (
    unfilteredSalesReportsList,
    salesReportsList,
    filters
) => {
    return {
        type: actionTypes.ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_SUCCESS,
        unfilteredSalesReportsList,
        salesReportsList,
        filters,
    };
};

export const adminUnconfirmedSalesReportsFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_FAIL,
        error,
    };
};

export const adminUnconfirmedSalesReportsFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_UNCONFIRMED_SALES_REPORTS_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const adminUnconfirmedSalesReportsFilterClear = () => {
    return {
        type: actionTypes.ADMIN_UNCONFIRMED_SALES_REPORTS_FILTER_CLEAR,
    };
};
