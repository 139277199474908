import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";

const initialFilters = {
    ean: "",
    eanType: null,
    name: "",
    client: null,
    category: null,
    type: null,
    volume: null,
    weight: null,
    status: null,
    testResult: null,
    startDate: null,
    endDate: null,
};

const initialSorting = {
    dataField: "package_id",
    order: "desc",
};

const getSizePerPageList = (dataSize) => {
    return [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ];
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: getSizePerPageList(0),
};

const initialState = {
    filters: initialFilters,
    sorting: initialSorting,
    pagination: initialPagination,

    packagesList: [],
    packagesListFiltersUsed: initialFilters,
    packagesListLoading: false,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const onAdminAllPackagesFetchStart = (state) => {
    return updateObject(state, {
        packagesListLoading: true,
        packagesList: [],
    });
};

const onAdminAllPackagesFetchSuccess = (state, action, filters) => {
    return updateObject(state, {
        packagesListLoading: false,
        packagesList: action.packagesList,
        pagination: {
            ...state.pagination,
            dataSize:
                action.dataSize /*sizePerPageList: getSizePerPageList(action.dataSize)*/,
        },
        packagesListFiltersUsed: action.filters,
    });
};

const onAdminAllPackagesFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        packagesList: [],
        packagesListLoading: false,
    });
};

const onAdminAllPackagesFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
        pagination: {
            ...state.pagination,
            page: 1,
        },
    });
};

const onAdminAllPackagesFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
        pagination: {
            ...state.pagination,
            page: 1,
        },
    });
};

const onAdminAllPackagesSortingUpdate = (state, action) => {
    return updateObject(state, {
        sorting: action.sorting,
    });
};

const onAdminAllPackagesPaginationUpdate = (state, action) => {
    return updateObject(state, {
        pagination: action.pagination,
    });
};

export const allPackages = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.ADMIN_ALL_PACKAGES_FETCH_START:
            return onAdminAllPackagesFetchStart(state, action);
        case actionTypes.ADMIN_ALL_PACKAGES_FETCH_SUCCESS:
            return onAdminAllPackagesFetchSuccess(state, action);
        case actionTypes.ADMIN_ALL_PACKAGES_FETCH_FAIL:
            return onAdminAllPackagesFetchFail(state, action);
        // FILTER
        case actionTypes.ADMIN_ALL_PACKAGES_FILTER_UPDATE:
            return onAdminAllPackagesFilterUpdate(state, action);
        case actionTypes.ADMIN_ALL_PACKAGES_FILTER_CLEAR:
            return onAdminAllPackagesFilterClear(state, action);
        // SORT
        case actionTypes.ADMIN_ALL_PACKAGES_SORTING_UPDATE:
            return onAdminAllPackagesSortingUpdate(state, action);
        // PAGINATION
        case actionTypes.ADMIN_ALL_PACKAGES_PAGINATION_UPDATE:
            return onAdminAllPackagesPaginationUpdate(state, action);

        // GENERIC
        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
