import React from "react";
import axios from "../../../../../axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { Button } from "reactstrap";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import { getServerErrorMessage } from "util/Errors";

import {
    clearLocalAlerts,
    addNewLocalSuccessAlert,
    addNewLocalErrorAlert,
} from "util/Alerts";

import {
    ADMIN_CLIENT_PACKAGES_SYNCHRONIZE,
} from "config/Api";


class SynchronizeButton extends React.Component {
    static propTypes = {
        clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    }

    static defaultProps = {
    };

    state = { synchronizeModalOpen: false, synchronizeIsLoading: false };
    static alertGroup = "SynchronizeButton";

    render() {
        return (
            <>
                <Button
                    outline
                    className="mb-3"
                    color="success"
                    onClick={this.onOpenSynchronizeModal}
                >
                    Sünkroniseeri pakendid
                </Button>

                {this.renderSynchronizeModal()}
            </>
        );
    }

    renderSynchronizeModal() {
        return (
            <ConfirmationModal
                title={"Sünkroniseerimine"}
                isOpen={this.state.synchronizeModalOpen}
                onToggle={this.onCancelSynchronize}
                onCancel={this.onCancelSynchronize}
                onConfirm={this.onSynchronize}
                isLoading={this.state.synchronizeIsLoading}
                isDisabled={this.state.synchronizeIsLoading}
                alertGroups={[SynchronizeButton.alertGroup]}
            >
                <p className="regular-14">Protsess jookseb taustal ja võib võtta kuni paar minutit. Protsessi lõppemisel saadetakse teile teavitusks e-mail.</p>
                <p className="regular-14">Olete kindel, et tahate sünkroniseerida?</p>
            </ConfirmationModal>
        );
    }

    onOpenSynchronizeModal = () => {
        this.setState({
            synchronizeModalOpen: true,
        });
    };

    onCancelSynchronize = () => {
        this.setState({
            synchronizeModalOpen: false,
        });
        clearLocalAlerts();
    };

    onSynchronize = () => {
        clearLocalAlerts();

        this.setState({
            synchronizeIsLoading: true,
        });

        const endpoint = ADMIN_CLIENT_PACKAGES_SYNCHRONIZE.replace("<client_id>", this.props.clientId);
        const data = {
            migrate_all: true // Always true, because migrating recent does not work quite as expected
        };
        return axios
            .post(endpoint, data)
            .then((resp) => {
                addNewLocalSuccessAlert(
                    resp.data,
                    this.props.location.pathname
                );
                this.setState({
                    synchronizeModalOpen: false,
                });
            })
            .catch((error) => {
                const message = `Sünkroniseerimine ebaõnnestus: ${getServerErrorMessage(error)}`;
                addNewLocalErrorAlert(message, SynchronizeButton.alertGroup, false);
            })
            .finally(() => {
                this.setState({
                    synchronizeIsLoading: false,
                });
            });
    };
}

export default withRouter(SynchronizeButton);
