import React from "react";

import { Row, Col, UncontrolledTooltip } from "reactstrap";

import { ReactComponent as ReportsStatusIcon } from "assets/icons/raport.svg";
import { ReactComponent as SyncStatusIcon } from "assets/icons/sync.svg";
import { ReactComponent as DebtStatusIcon } from "assets/icons/debt.svg";

import { currencyFormatter } from "util/General";
import { PackageConditionValues } from "components/Form/PackageConditions/options";

import "./PackageConditionsIcons.scss";

const tooltipInnerClassName = "package-conditions-tooltip-inner";
const tooltipArrowClassName = "package-conditions-tooltip-arrow";

export const PackageConditionsColors = {
    /*
    normal icon and icon-problem class used for these instead.
    [PackageConditionValues.DEBT]: "#F12B2C",
    [PackageConditionValues.UNSUBMITTED_REPORTS]: "#F12B2C",
    [PackageConditionValues.UNSYNCED]: "#F12B2C", 
    */
    [PackageConditionValues.LAST_TEST_HAS_COMMENT]: "#0057B7",
    [PackageConditionValues.LAST_TEST_HAS_STANDARD_COMMENT]: "#FFD500",
};


export function TestConditionCircle({ fill, cx, svgMode }) {
    const circle = (
        <circle cx={cx} cy="9" r="8" fill={fill} stroke="#F0F1F7" strokeWidth="2" />
    );

    if (svgMode) {
        return (
            <svg height="18px" width="18px">
                {circle}
            </svg>
        );
    }

    return circle;
}
TestConditionCircle.defaultProps = {
    fill: "#C5C7CD",
    cx: "9",
    svgMode: false
}

function PackageConditionsIconsForTestComments({ idSuffix, hasTestComment, hasTestStandardComments }) {
    // If they are not boolean then either that data is missing or not connected on purpose (e.g. applications table)
    const showTestCommentNotifications = typeof (hasTestComment) === 'boolean' && typeof (hasTestStandardComments) === 'boolean';

    if (!showTestCommentNotifications) return null;

    const sizeStyle = { height: "18px", width: "27px" }; // based on circle sizes in TestConditionCircle
    let standardCommentsColor, commentColor;
    if (hasTestStandardComments) {
        standardCommentsColor = PackageConditionsColors[PackageConditionValues.LAST_TEST_HAS_STANDARD_COMMENT];
    }
    if (hasTestComment) {
        commentColor = PackageConditionsColors[PackageConditionValues.LAST_TEST_HAS_COMMENT];
    }
    return (
        <Col className="col-auto mr-1">
            <div id={getBaseId(idSuffix) + "-test-comment-icons"} style={sizeStyle}>
                <svg style={sizeStyle}>
                    <TestConditionCircle fill={commentColor} cx="18"></TestConditionCircle>
                    <TestConditionCircle fill={standardCommentsColor}></TestConditionCircle>
                </svg>
            </div>
            <UncontrolledTooltip
                target={getBaseId(idSuffix) + "-test-comment-icons"}
                innerClassName={tooltipInnerClassName}
                arrowClassName={tooltipArrowClassName}
            >
                <span>Viimasele testile <b>{hasTestStandardComments ? "on" : "ei ole"}</b> lisatud <b>tüüpkommentaare</b></span>
                <br></br>
                <span>Viimasele testile <b>{hasTestComment ? "on" : "ei ole"}</b> lisatud <b>kommentaar</b></span>
            </UncontrolledTooltip>
        </Col >
    );
}

export default function PackageClientConditions({ idSuffix, debtAmount, unsubmittedReportsAmount, isUnsynced, hasTestComment, hasTestStandardComments }) {
    const hasDebt = debtAmount > 0;
    const hasUnsubmittedReports = unsubmittedReportsAmount > 0;

    return (
        <Row className="package-conditions-wrapper no-gutters justify-content-end">
            <PackageConditionsIconsForTestComments
                idSuffix={idSuffix}
                hasTestComment={hasTestComment}
                hasTestStandardComments={hasTestStandardComments}
            />
            <Col className="col-auto">
                <SyncStatusIcon
                    id={getBaseId(idSuffix) + "-sync"}
                    className={isUnsynced ? "icon-problem" : ""}
                />
                <UncontrolledTooltip
                    placement="right"
                    target={getBaseId(idSuffix) + "-sync"}
                    innerClassName={tooltipInnerClassName}
                    arrowClassName={tooltipArrowClassName}
                >
                    {isUnsynced ? "Parameetrid sünkroniseerimata" : "Parameetrid sünkroniseeritud"}
                </UncontrolledTooltip>
            </Col>
            <Col className="col-auto mx-2">
                <DebtStatusIcon
                    id={getBaseId(idSuffix) + "-debt"}
                    className={hasDebt ? "icon-problem" : ""}
                />
                <UncontrolledTooltip
                    target={getBaseId(idSuffix) + "-debt"}
                    innerClassName={tooltipInnerClassName}
                    arrowClassName={tooltipArrowClassName}
                >
                    {`Võlgnevus ${currencyFormatter.format(debtAmount)}`}
                </UncontrolledTooltip>
            </Col>
            <Col className="col-auto">
                <ReportsStatusIcon
                    id={getBaseId(idSuffix) + "-reports"}
                    className={hasUnsubmittedReports ? "icon-problem" : ""}
                />
                <UncontrolledTooltip
                    target={getBaseId(idSuffix) + "-reports"}
                    innerClassName={tooltipInnerClassName}
                    arrowClassName={tooltipArrowClassName}
                >
                    {`${unsubmittedReportsAmount} esitamata aruannet`}
                </UncontrolledTooltip>
            </Col>
        </Row>
    );
}

const getBaseId = (idSuffix = "") => {
    return "package-conditions-" + idSuffix;
}

PackageClientConditions.defaultProps = {
    idSuffix: "",
    hasTestComment: undefined,
    hasTestStandardComments: undefined
};
