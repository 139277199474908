import React from "react";
import store from "redux/store";

import { Trans } from "react-i18next";
import { languageTypes } from "util/General";

export function getServerErrorMessage(error) {
    const language = store.getState().language;

    let errorMessage =
        language === languageTypes.EN ? "Unknown error!" : "Tundmatu viga!";
    if (!error) {
        return errorMessage;
    } else if (error.response?.data) {
        const data = error.response.data;
        if (data.errors) {
            return data.errors.map((error) => error.code).join(" ");
        }
        if (data.code) {
            return data.code;
        }

        if (data.detail) {
            return data.detail;
        }
    } else if (error.request) {
        return error.request.type || errorMessage;
    } else {
        return error.message || errorMessage;
    }

    return errorMessage;
}

export function getErrorMessageList(
    errors = [],
    contentFunc,
    topText = "",
    keyFunc
) {
    const message = (
        <>
            {topText && <p>{topText}</p>}
            <ul className="mb-0">
                {errors.map((e, i) => (
                    <li key={keyFunc ? keyFunc(e, i) : i}>{contentFunc(e)}</li>
                ))}
            </ul>
        </>
    );

    return message;
}

export function getIdCardSpecificErrorMessage(error, defaultMessage) {
    const errorMessage = typeof error === "object" && error !== null ? error.message : null;
    if (errorMessage === "no_implementation") {
        //hwcrypto error
        return (
            <Trans i18nKey="alerts.idCard.softwareNotFound">
                ID-kaardi tarkvara ei leitud.
            </Trans>
        );
    } else if (errorMessage === "no_certificates") {
        // hwcrypto error
        return (
            <Trans i18nKey="alerts.idCard.sertificatesNotFound">
                Sertifikaate ei leitud. Palun kontrollige, et ID-kaardi lugeja
                on seadme küljes ja ID-kaart on sisestatud.
            </Trans>
        );
    } else if (errorMessage === "user_cancel") {
        // hwcrypto error
        return (
            <Trans i18nKey="alerts.idCard.authenticationCancelled">
                Sisselogimine ebaõnnestus. Kasutaja lõpetas protsessi.
            </Trans>
        );
    } else if (errorMessage === "login_prepare_not_success") {
        return (
            <Trans i18nKey="alerts.idCard.authenticationPreparationFailed">
                Sisselogimise ettevalmistamine ebaõnnestus
            </Trans>
        );
    } else if (errorMessage === "login_verify_not_success") {
        return (
            <Trans i18nKey="alerts.idCard.authenticationVerificationFailed">
                Sisselogimise kinnitamine ebaõnnestus.
            </Trans>
        );
    } else if ("errorMessage" === "sign_prepare_not_success") {
        return (
            <Trans i18nKey="alerts.idCard.signingPreparationFailed">
                Allkirjastamise ettevalmistamine ebaõnnestus.
            </Trans>
        );
    } else if ("errorMessage" === "sign_verify_not_success") {
        return (
            <Trans i18nKey="alerts.idCard.signingVerificationFailed">
                Allkirjastamise kinnitamine ebaõnnestus
            </Trans>
        );
    }

    return defaultMessage;
}

export function getIdCardErrorMessage(error, defaultMessage) {
    const genericErrorMessage = (
        <Trans i18nKey="alerts.idCard.idCardGenericError">
            Ilmnes viga, palun veenduge, et teie tarkvara on uuendatud ning teie brauser toetab keskkonda. Samuti palume veenduda sertifikaadi kehtivuses.
        </Trans>
    );

    return (
        <div className="text-left">
            <div>
                {getIdCardSpecificErrorMessage(error, defaultMessage)}
            </div>
            <div>
                {genericErrorMessage}
            </div>
        </div>
    );
}
