import React from "react";
import { connect } from "react-redux";
import axios from "../../axios";
import _ from "lodash";

import {
    UncontrolledButtonDropdown,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    UncontrolledTooltip
} from "reactstrap";
import { ReactComponent as PersonLogo } from "assets/icons/person.svg";

import {
    isNotConnectedToClientPartnerOrEpp,
    isClientMode,
    isPartnerMode,
    isAdminMode,
    hasAdminMode,
} from "util/Access";
import { getAuthUser } from "util/Auth";
import { clearAllAlerts } from "util/Alerts";
import {
    CHANGE_ACTIVE_CLIENT,
    CHANGE_ACTIVE_PARTNER,
    CHANGE_ACTIVE_EPP,
} from "config/Api";
import "./UserModeSelection.scss";
import { withTranslation } from "react-i18next";

class UserModeSelection extends React.Component {
    render() {
        const { t, tooltipTargetId, inNavbar, maxWidth } = this.props;

        const selectedRoleText = `${this.getUserFirstName()} - ${this.getUserModeName()}`;

        return (
            <UncontrolledButtonDropdown
                direction={this.props.direction || "down"}
                className={`user-mode-selection regular-14 pointer ${this.props.className}`}
                inNavbar={inNavbar}
            >
                <DropdownToggle tag="div">
                    {inNavbar ?
                        <div>
                            <PersonLogo className="d-lg-none" id={tooltipTargetId} />
                            <span className="user-mode-selection-selected semibold-14 d-none d-lg-block" style={{ maxWidth: maxWidth }}>
                                <PersonLogo className="mr-3" />{selectedRoleText}
                            </span>
                        </div>
                        :
                        <span className="user-mode-selection-selected d-block semibold-14" style={{ maxWidth: maxWidth }}>
                            <PersonLogo className="mr-3" />{selectedRoleText}
                        </span>

                    }
                    {tooltipTargetId &&
                        <UncontrolledTooltip placement="bottom" target={tooltipTargetId}>{t("contracts.iconTooltip", "Rolli valik")}</UncontrolledTooltip>
                    }
                </DropdownToggle>
                <DropdownMenu
                    modifiers={{
                        setMaxHeight: {
                            enabled: !inNavbar,
                            order: 890,
                            fn: (data) => {
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: '400px',
                                    },
                                };
                            },
                        },
                    }}
                >
                    {this.renderAdminOptions()}
                    {this.renderPartnerOptions()}
                    {this.renderClientOptions()}
                </DropdownMenu>
            </UncontrolledButtonDropdown >
        );
    }

    renderAdminOptions() {
        if (!hasAdminMode()) {
            return <></>;
        }

        const { t } = this.props;

        return (
            <>
                <DropdownItem
                    header
                    disabled
                    className="regular-14 border-bottom"
                >
                    {t("contracts.epp", "EPP")}
                </DropdownItem>
                <DropdownItem
                    onClick={this.onChangeActiveEPP}
                    className={`user-selection-option ${
                        isAdminMode() ? "semibold-14" : "regular-14"
                        }`}
                >
                    {this.getAdminName()}
                </DropdownItem>
            </>
        );
    }

    renderPartnerOptions() {
        const partners = this.getPartners();
        if (!partners.length) {
            return <></>;
        }

        const isInPartnerMode = isPartnerMode();

        const partnerOptions = this.getPartners().map((partner, i) => (
            <DropdownItem
                key={"partner-index-" + i}
                onClick={() => this.onChangeActivePartner(partner.id)}
                className="user-selection-option"
            >
                <span
                    className={
                        isInPartnerMode && partner.active_partner
                            ? "semibold-14"
                            : "regular-14"
                    }
                >
                    {partner.name}
                </span>
            </DropdownItem>
        ));

        const { t } = this.props;

        return (
            <>
                <DropdownItem
                    header
                    disabled
                    className="regular-14 border-bottom"
                >
                    {t("contracts.partners", "Partnerid")}
                </DropdownItem>
                {partnerOptions}
            </>
        );
    }

    renderClientOptions() {
        const isInClientMode = isClientMode();

        const clientOptions = this.getClients().map((client, i) => (
            <DropdownItem
                key={"client-" + client.id}
                onClick={() => this.onChangeActiveClient(client.id)}
                className="user-selection-option"
            >
                <span
                    className={
                        isInClientMode && client.active_client
                            ? "semibold-14"
                            : "regular-14"
                    }
                >
                    {client.name}
                </span>
            </DropdownItem>
        ));

        const { t } = this.props;

        return (
            <>
                <DropdownItem
                    header
                    disabled
                    className="regular-14 border-bottom"
                >
                    {t("contracts.clients", "Kliendid")}
                </DropdownItem>
                {clientOptions}
                {
                    <DropdownItem
                        key="client-0"
                        className={`user-selection-option ${
                            isNotConnectedToClientPartnerOrEpp()
                                ? "semibold-14"
                                : "regular-14"
                            }`}
                        onClick={() => this.onChangeActiveClient(0)}
                    >
                        + {t("contracts.createNewClient", "Loo uus klient")}
                    </DropdownItem>
                }
            </>
        );
    }

    onChangeActiveClient = (clientId) => {
        axios
            .put(CHANGE_ACTIVE_CLIENT, { client: clientId })
            .then((response) => {
                clearAllAlerts();
                getAuthUser();
            })
            .catch((error) => { });
    };

    onChangeActivePartner = (partnerId) => {
        axios
            .put(CHANGE_ACTIVE_PARTNER, { partner: partnerId })
            .then((response) => {
                clearAllAlerts();
                getAuthUser();
            })
            .catch((error) => { });
    };

    onChangeActiveEPP = () => {
        axios
            .put(CHANGE_ACTIVE_EPP)
            .then((response) => {
                clearAllAlerts();
                getAuthUser();
            })
            .catch((error) => { });
    };

    onCreateNewClient = () => { };

    getUserFirstName = () => {
        return this.props.user ? this.props.user.first_name : "";
    };

    getUserModeName = () => {
        if (isNotConnectedToClientPartnerOrEpp()) {
            return this.props.t("contracts.userModeNewClient", "Uus klient");
        } else if (isAdminMode()) {
            return this.getAdminName();
        } else if (isPartnerMode()) {
            return this.getPartnerName();
        } else if (isClientMode()) {
            return this.getClientName();
        }

        return "";
    };

    getClientName = () => {
        return _.get(this.props, "user.user_type.client_name", "");
    };

    getPartnerName = () => {
        return _.get(this.props, "user.partner_user_type.partner_name", "");
    };

    getAdminName = () => {
        return _.get(this.props, "user.epp_user_type.profession", "");
    };

    getClients = () => {
        return this.props.user ? this.props.user.clients || [] : [];
    };

    getPartners = () => {
        return this.props.user ? this.props.user.partners || [] : [];
    };
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

export default connect(mapStateToProps)(
    withTranslation("common")(UserModeSelection)
);
