import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { Row, Col, DropdownItem } from "reactstrap";
import moment from "moment";
import urls from "config/urls";

export default function NotificationDropdownItems(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const visibleNotificationsCount = 3;
    const notifications = props.notifications.slice(
        0,
        visibleNotificationsCount
    );

    return notifications.map((notification, i) => (
        <div
            key={"notification-dropdown-index-" + notification.id}
            className="p-0"
        >
            <DropdownItem
                onClick={() =>
                    history.push(history.push(urls.PROFILE_NOTIFICATIONS))
                }
                className="notification-option text-nowrap"
            >
                <Row className="mb-2">
                    <Col>
                        <span className="semibold-12 notification-dropdown-item">
                            {notification.message}
                        </span>
                    </Col>
                </Row>
                <Row>
                    <Col className="semibold-12 color-gray-light">
                        {moment(notification.created_at).format("DD.MM.YYYY")}
                    </Col>
                    <Col className="semibold-12 color-info text-right notification-link">
                        {t("notifications.dropdown.viewButton", "Vaatan")}
                    </Col>
                </Row>
            </DropdownItem>
            <hr key={"hr-index-" + i} />
        </div>
    ));
}

NotificationDropdownItems.propTypes = {
    notifications: PropTypes.array,
};
