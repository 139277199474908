import React, { Component } from "react";
import { fetchAdminSettingsProcessFees } from "util/admin/Settings";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import WrappedLoader from "components/Loader/WrappedLoader";
import moment from "moment";

import { processFeeCostCurrencyFormatter } from "util/General";

export class ProcessFees extends Component {
    componentDidMount() {
        if (this.props.processFees.length === 0) {
            fetchAdminSettingsProcessFees();
        }
    }

    static NoDataIndication(props) {
        let content = "Ei leitud käitlustasusid.";
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    render() {
        const columns = [
            {
                dataField: "process_fee_type.description",
                text: "Kirjeldus",
                sort: true,
                classes: "semibold-14",
            },
            {
                dataField: "process_fee_type.value",
                text: "Kood",
                sort: true,
                classes: "semibold-14",
            },
            {
                dataField: "cost",
                text: "Käitlustasu",
                formatter: (cost) => processFeeCostCurrencyFormatter.format(cost),
                sort: true,
                classes: "semibold-14",
                align: "right",
                headerAlign: "right",
            },
            {
                dataField: "start_at",
                text: "Alguskuupäev",
                sort: true,
                classes: "semibold-14",
                formatter: (date) => {
                    return moment(date).format("YYYY-MM-DD");
                },
                align: "right",
                headerAlign: "right",
            },
        ];

        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <span className="heading">
                                Pakendite käitlustasud
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0">
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="table-layout-auto no-top-border"
                        wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                        keyField="id"
                        data={this.props.processFees}
                        columns={columns}
                        noDataIndication={
                            <ProcessFees.NoDataIndication
                                loading={this.props.loading}
                            />
                        }
                    />
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        processFees: state.admin.settings.processFees,
        loading: state.admin.settings.loading,
    };
};

export default connect(mapStateToProps)(ProcessFees);
