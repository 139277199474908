import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Translation } from "react-i18next";

import { Button } from "reactstrap";
import SmallLoader from "components/Loader/WrappedPulseLoader";

import { clearLocalAlerts } from "util/Alerts";
import { exportExcelSalesReportDetails } from "util/excel/SalesReportDetailsExcel";


class DownloadSalesReportDetailsExcel extends Component {
    static propTypes = {
        data: PropTypes.object.isRequired,
        packagesList: PropTypes.array,
        packagesListFunc: PropTypes.func,
        onError: PropTypes.func,
        loading: PropTypes.bool,
        disabled: PropTypes.bool,
    }

    render() {
        return (
            <Button
                outline
                block
                color="success"
                onClick={this.onExport}
                disabled={this.props.loading || this.props.disabled}
            >
                {this.props.loading ?
                    <SmallLoader />
                    :
                    <Translation>{t => t("reports.printReport", "Prindi aruanne")}</Translation>
                }
            </Button>
        );
    }

    onExport = () => {
        clearLocalAlerts();
        try {
            exportExcelSalesReportDetails(this.props.data)
        } catch (err) {
            if (this.props.onError) {
                this.props.onError(err);
            } else {
                console.error(err);
            }
        }
    }
}

export default DownloadSalesReportDetailsExcel;
