import React from "react";
import { withRouter } from "react-router-dom";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";

import urls from "config/urls";

import LeftText from "components/Misc/LeftText";
import DeleteText from "components/Misc/DeleteText";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import SalesReportDetails from "components/Reports/Sales/Details/SalesReportDetails";

import {
    fetchSalesReport,
    SaleStatus,
} from "util/SalesReports";
import { adminFetchSalesReport, adminDeleteSalesReport } from "util/admin/SalesReports";
import {
    addNewLocalSuccessAlert,
    addNewLocalErrorAlert,
    clearLocalAlerts,
} from "util/Alerts";
import { isAdminMode } from "util/Access";
import { Trans, withTranslation } from "react-i18next";

import {
    parseSalesReportPeriodDate,
    salesReportPeriodListFormat,
} from "util/DateTime";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";

const deleteOriginalAlertGroup = "delete-original-modal";
class OriginalSalesReport extends React.Component {
    state = { salesReportId: null, deleteModalOpen: false };

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Row className="justify-content-between">
                    <Col xs="auto">
                        <LeftText
                            onClick={() =>
                                this.props.history.push(this.getReturnRoute())
                            }
                            text={t("buttons.back", "Tagasi")}
                        />
                    </Col>
                    {this.isDeletable() && (
                        <Col className="col-auto">
                            <DeleteText
                                text="Kustuta aruanne"
                                onClick={this.onOpenDeleteModal}
                                disabled={
                                    this.props.deleteOriginalReportLoading
                                }
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <SalesReportDetails
                            adminMode={isAdminMode()}
                            fetchData={this.fetchData}
                        />
                    </Col>
                </Row>

                {this.renderDeleteModal()}
            </>
        );
    }

    renderDeleteModal() {
        const { t } = this.props;
        let formattedPeriod = parseSalesReportPeriodDate(
            this.props.details.period
        ).format(salesReportPeriodListFormat);

        return (
            <Modal
                isOpen={this.state.deleteModalOpen}
                onToggle={this.onCancelDelete}
                backdrop="static"
                className="original-delete-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">
                                Müügiaruande kustutamine
                            </span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onCancelDelete}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts
                                hideGlobal={true}
                                filterGroups={[deleteOriginalAlertGroup]}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <p className="regular-14">
                                Kas soovite kustutada müügiaruande{" "}
                                <b>{formattedPeriod}</b>?
                            </p>
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                outline
                                block
                                onClick={this.onCancelDelete}
                                disabled={
                                    this.props.deleteOriginalReportLoading
                                }
                            >
                                {t("buttons.cancel", "Tühista")}
                            </Button>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                block
                                onClick={this.onDelete}
                                disabled={
                                    this.props.deleteOriginalReportLoading
                                }
                            >
                                {this.props.deleteOriginalReportLoading ? (
                                    <LoadingAnimation />
                                ) : (
                                    "Kustuta müügiaruanne"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

    getReturnRoute = () => {
        const locationState = this.props.location.state;
        if (locationState && locationState.previousViewRoute) {
            return locationState.previousViewRoute;
        }
        return isAdminMode() ? urls.EPP_REPORTS_SALES : urls.REPORTS_SALES;
    }

    fetchData = () => {
        const id = this.props.match.params.id;

        const fetchEndpoint = isAdminMode()
            ? adminFetchSalesReport
            : fetchSalesReport;
        fetchEndpoint(id).then((result) => {
            if (!result.isSuccess) {
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.alerts.fetchSalesReportFailed">
                        Müügiaruande laadimine ebaõnnestus:{" "}
                        {{ errorMessage: result.errorMessage }}
                    </Trans>,
                    this.props.location.pathname,
                    true
                );
            }
        });
    };

    isDeletable = () => {
        return (
            isAdminMode() &&
            this.props.details.id &&
            [SaleStatus.UNSUBMITTED].includes(this.props.details.status.value)
        );
    };

    onOpenDeleteModal = () => {
        this.setState({
            deleteModalOpen: true,
        });
    };

    onCancelDelete = () => {
        if (this.props.deleteOriginalReportLoading) {
            return;
        }
        this.setState({
            deleteModalOpen: false,
        });
        clearLocalAlerts();
    };

    onDelete = () => {
        if (this.props.deleteOriginalReportLoading) {
            return;
        }
        const id = this.props.details.id;
        clearLocalAlerts();
        adminDeleteSalesReport(id).then((result) => {
            if (result.isSuccess) {
                addNewLocalSuccessAlert(
                    "Müügiaruande kustutamine õnnestus.",
                    urls.REPORTS_SALES,
                    false
                );
                document.body.classList.remove("modal-open");
                this.redirectToSalesReportsList();
            } else {
                const message = `Müügiaruande kustutamine ebaõnnestus: ${result.errorMessage}`;
                addNewLocalErrorAlert(message, deleteOriginalAlertGroup, false);
            }
        });
    };

    redirectToSalesReportsList = () => {
        this.props.history.push(urls.REPORTS_SALES, {
            refreshList: true // admin sales reports list does not refresh by default but it does with this set on mount
        });
    };
}

const mapStateToProps = (state) => {
    return {
        details: state.salesReport.details,
        deleteOriginalReportLoading:
            state.salesReport.deleteOriginalReportLoading,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation()(OriginalSalesReport))
);
