import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory from "react-bootstrap-table2-filter";

// css
import "./SalesTable.scss";

import { ReactComponent as ExpandIcon } from "assets/icons/document-correction.svg";

// search
import SalesSearch from "components/Reports/Sales/Search/SalesSearch";

// cells
import DateCell from "components/Table/Cell/DateCell";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import DocumentCell from "components/Table/Cell/DocumentCell";
import MonthPeriodCell from "../cells/MonthPeriodCell";
import MenuCell from "../cells/MenuCell";
import ImportModal from "components/Reports/Sales/Modal/ImportSalesReport";
import WrappedLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";

import { getSaleStatusType } from "../helpers/Options";

import { fetchSalesList } from "util/SalesReports";
import { isConnectedToClientWithActiveContract } from "util/Access";
import { getSaleReportUrl, getSaleReportFixUrl } from "config/urls";
import { isSalesReportHasRepairReport } from "util/SalesReports";
import { withTranslation } from "react-i18next";

function NoDataIndication(props) {
    let content =
        props.t("reports.sales.noSalesReportsText", "Ei leitud ühtegi müügiaruannet, mis vastaks otsingutingimustele.");
    if (props.loading) {
        content = <WrappedLoader />;
    } else if (props.error) {
        content = <span className="color-error">{props.error}</span>;
    }

    return <div className="text-center">{content}</div>;
}

class SalesTable extends React.Component {
    static propTypes = {
        salesList: PropTypes.array.isRequired,
    };

    componentDidMount() {
        if (this.props.isClientWithActiveContract) {
            this.getSalesList();
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.activeClientId !== this.props.activeClientId) {
            this.getSalesList();
        }
    }

    getSalesList = () => {
        fetchSalesList(
            this.props.filters.fromDate,
            this.props.filters.toDate,
            this.props.location.pathname
        );
    };

    getDefaultSorted = () => {
        return [
            {
                dataField: "period",
                order: "desc",
            },
        ];
    };

    render() {
        return (
            <>
                <SalesSearch />
                {this.renderTable()}
                <ImportModal />
            </>
        );
    }

    renderTable() {
        const { t } = this.props;
        if (this.props.salesListLoading) {
            return (
                <CenteredRowCol>
                    <WrappedLoader />
                </CenteredRowCol>
            );
        }
        if (
            this.props.salesList === null ||
            this.props.salesList === undefined
        ) {
            return <></>;
        }

        const columns = [
            {
                dataField: "period",
                text: t("reports.table.period", "Periood"),
                sort: true,
                classes: "period-col",
                headerClasses: "period-col-header",
                formatter: (period, row) => this.periodColumnFormatter(row),
            },
            {
                dataField: "submitted_at",
                text: t("reports.table.submitted", "Esitatud"),
                sort: true,
                align: "left",
                headerAlign: "left",
                classes: "submitted-col regular-14",
                headerClasses: "submitted-col-header",
                formatter: (value, row) =>
                    this.submittedAtColumnFormatter(value, row),
            },
            {
                dataField: "status.label",
                text: t("reports.table.status", "Staatus"),
                sort: true,
                classes: "status-col",
                headerClasses: "status-col-header",
                formatter: (statusLabel, row) =>
                    this.statusColumnFormatter(row),
            },
            {
                dataField: "invoice",
                text: t("reports.table.invoice", "Arve"),
                sort: true,
                sortValue: invoice => invoice && invoice.length > 0 ? invoice[0].name : "",
                align: "left",
                headerAlign: "left",
                classes: "invoice-col",
                headerClasses: "invoice-col-header",
                formatter: (invoices, row) =>
                    this.invoicesColumnFormatter(row),
            },
            {
                dataField: "id",
                text: "",
                align: "right",
                alignHeader: "right",
                classes: "action-col",
                headerClasses: "action-col-header",
                formatter: (id, salesReport) =>
                    this.actionColumnFormatter(salesReport),
            },
        ];

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onRowClick(e, row);
            },
        };

        return (
            <Card className="sales-card">
                <CardBody className="sales-card-body">
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="sales-table no-top-border"
                        keyField="id"
                        data={this.props.salesList}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="inline"
                        defaultSorted={this.getDefaultSorted()}
                        noDataIndication={
                            <NoDataIndication
                                error={this.props.salesErrorMessage}
                                loading={this.props.salesListLoading}
                                t={t}
                            />
                        }
                        rowClasses={rowClasses}
                        rowEvents={rowEvents}
                    />
                </CardBody>
            </Card>
        );
    }

    periodColumnFormatter = (row) => {
        if (!row.repair_report) {
            return <MonthPeriodCell salesReport={row} />;
        }

        return (
            <>
                <Row>
                    <Col>
                        <MonthPeriodCell salesReport={row} />
                    </Col>
                </Row>
                {row.repair_report && (
                    <Row className="mt-3">
                        <Col>
                            <MonthPeriodCell salesReport={row.repair_report} />
                            <ExpandIcon className="expand-icon ml-2" />
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    onRowClick = (event, row) => {
        let reportUrl = getSaleReportUrl(row.id);
        if (isSalesReportHasRepairReport(row)) {
            reportUrl = getSaleReportFixUrl(row.repair_report.id);
        }
        this.props.history.push(reportUrl);
    };

    submittedAtColumnFormatter = (submitted_at, row) => {
        return (
            <>
                <Row>
                    <Col>
                        <DateCell value={submitted_at} />
                    </Col>
                </Row>
                {row.repair_report && (
                    <Row className="mt-3">
                        <Col>
                            <DateCell value={row.repair_report.submitted_at} />
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    statusColumnFormatter = (row) => {
        return (
            <>
                <Row>
                    <Col>
                        <StatusCell
                            status={row.status.label}
                            type={getSaleStatusType(row.status.value)}
                            className="status-cell"
                        />
                    </Col>
                </Row>
                {row.repair_report && (
                    <Row className="mt-3">
                        <Col>
                            <StatusCell
                                status={row.repair_report.status.label}
                                type={getSaleStatusType(
                                    row.repair_report.status.value
                                )}
                                className="status-cell"
                            />
                        </Col>
                    </Row>
                )}
            </>
        );
    };

    invoicesColumnFormatter = (row) => {
        const invoicesToComponents = (invoices, isRepair) => {
            return (invoices || []).filter(invoice => invoice.name).map((invoice, index) => (
                <Col xs="12" key={`}${isRepair ? "repair-invoice" : "invoice"}-${invoice.name}-${index}`} >
                    <DocumentCell
                        name={invoice.name}
                        url={invoice.url}
                        onClick={() => { }}
                    />
                </Col >
            ))
        }

        const salesReportInvoices = invoicesToComponents(row.invoice, false);
        let salesReportRepairInvoices = [];
        if (row.repair_report && row.repair_report.invoice) {
            salesReportRepairInvoices = invoicesToComponents(row.repair_report.invoice, true);
        }

        return (
            <>
                <Row>
                    {salesReportInvoices}
                    {salesReportRepairInvoices}
                </Row>
            </>
        );
    };

    actionColumnFormatter = (salesReport) => {
        return <MenuCell salesReport={salesReport} repairReport={salesReport.repair_report} />;
    };
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        salesList: state.sales.salesList,
        salesErrorMessage: state.sales.error || "",
        salesListLoading: state.sales.salesListLoading,
        filters: state.sales.filters,
        isClientWithActiveContract: isConnectedToClientWithActiveContract(
            state
        ),
    };
};

export default connect(mapStateToProps)(withTranslation("common")(SalesTable));
