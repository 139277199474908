import React from "react";
import CompanyProfileFormAdmin from "components/Admin/Users/Clients/Client/CompanyProfileFormAdmin";
import WarrantedUserForm from "components/Profile/Profile/WarrantedUserForm/WarrantedUserForm";
import SynchronizeButton from "components/Admin/Users/Clients/Client/SynchronizeButton";
import ContractForm from "components/Admin/Contract/Contract";
import { Card, CardBody, Form } from "reactstrap";

class Profile extends React.Component {
    render() {
        return (
            <Card className="profile-card">
                <CardBody className="">
                    <div className="d-flex justify-content-between heading my-4">
                        <span>Äriregistri andmed</span>
                        <SynchronizeButton clientId={this.props.match.params.id}></SynchronizeButton>
                    </div>
                    <Form className="profile-form">
                        <CompanyProfileFormAdmin />
                    </Form>
                    <hr className="my-2" />
                    <div className="heading my-4">
                        <span>Lepingud</span>
                    </div>
                    <Form className="profile-form">
                        <ContractForm />
                    </Form>
                    <WarrantedUserForm clientId={this.props.match.params.id} />
                </CardBody>
            </Card>
        );
    }
}

export default Profile;
