import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Row, Col } from "reactstrap";
// datatable
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import CustomPagination from "components/Table/Pagination/CustomPagination";

import WrapperLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import Checkbox from "components/Form/Checkbox";
import SelectionHeader from "components/Form/SelectionHeader/SelectionHeader";
import PackageConditionsIcons from "components/Form/PackageConditions/PackageConditionsIcons";

import { DATE_FORMAT } from "util/DateTime";

import "./Table.scss";

class ApplicationsTable extends React.Component {
    static propTypes = {
        packagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,
        sorting: PropTypes.object.isRequired,
        onSort: PropTypes.func.isRequired,
        pagination: PropTypes.object.isRequired,
        onPaginationChange: PropTypes.func.isRequired,
        selectedIds: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onSelectAll: PropTypes.func.isRequired,
        onRowClick: PropTypes.func.isRequired,
    }

    render() {
        if (this.props.packagesListLoading) {
            return (
                <CenteredRowCol>
                    <WrapperLoader />
                </CenteredRowCol>
            );
        }
        if (!this.props.packagesList) {
            return <></>;
        }

        const columns = [
            {
                dataField: "id",
                text: "ID",
                classes: "semibold-14",
                sort: true,
                hidden: true,
            },
            {
                dataField: "ean",
                text: "EAN kood",
                classes: "semibold-14",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order),
            },
            {
                dataField: "name",
                text: "Toote nimetus",
                classes: "semibold-14",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order),
            },
            {
                dataField: "client",
                text: "Ettevõte",
                classes: "semibold-14",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order),
            },
            {
                dataField: "date",
                text: "Esitatud",
                classes: "regular-14",
                sort: true,
                onSort: (field, order) => this.props.onSort(field, order),
                formatter: (date) => moment(date).format(DATE_FORMAT),
                align: "right",
                headerAlign: "right",
            },
            {
                dataField: "",
                text: "Seisund",
                classes: "regular-14 package-condition-col",
                align: "right",
                headerAlign: "right",
                //sort: true,
                //onSort: (field, order) => this.props.onSort(field, order),
                formatter: (cell, row) => this.packageConditionsFormatter(row),
            },
        ];

        const selectRow = {
            mode: "checkbox",
            selected: this.props.selectedIds,
            //clickToSelect: true,
            hideSelectAll: false,
            selectionRenderer: ({ mode, checked, disabled }) => (
                <Checkbox value={checked} onClick={() => { }} className="px-0" />
            ),
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.onSelect(row, isSelect, rowIndex, e);
            },
            onSelectAll: (isSelect, rows) => {
                this.props.onSelectAll(isSelect, rows);
            },
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
                return <SelectionHeader checked={checked} indeterminate={indeterminate} />
            }
        }

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.props.onRowClick(e, row);
            },
        };

        const paginationOptions = {
            custom: true,
            page: this.props.pagination.page,
            sizePerPage: this.props.pagination.sizePerPage || this.props.pagination.dataSize,
            totalSize: this.props.pagination.dataSize,
            sizePerPageList: this.props.pagination.sizePerPageList,
            onSizePerPageChange: (sizePerPage, page) => {
                this.props.onPaginationChange(page, sizePerPage);
            },
            onPageChange: (page, sizePerPage) => {
                this.props.onPaginationChange(page, sizePerPage);
            },

        };

        const defaultSorted = [this.props.sorting];

        const emptyTableIndication = this.renderCenteredContent(<p className="my-3 regular-14">Ükski taotlus ei vastanud otsingutingimustele.</p>);

        return (
            <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                            <>
                                <Row>
                                    <Col>
                                        <BootstrapTable
                                            bootstrap4
                                            bordered={false}
                                            classes="admin-applications-table table-layout-auto"
                                            wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                                            keyField="id"
                                            data={this.props.packagesList}
                                            columns={columns}
                                            noDataIndication={emptyTableIndication}
                                            selectRow={selectRow}
                                            rowClasses={rowClasses}
                                            rowEvents={rowEvents}
                                            defaultSorted={defaultSorted}
                                            {...paginationTableProps}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mx-3">
                                    <Col className="px-3 mr-1">
                                        <CustomPagination
                                            unknownSizePerPageText="Kõik"
                                            {...paginationProps}
                                        />
                                    </Col>
                                </Row>
                            </>
                        )
                }
            </PaginationProvider>
        );
    }

    renderCenteredContent = content => {
        return (
            <CenteredRowCol>
                {content}
            </CenteredRowCol>
        );
    }

    getSelectedIndices = (packages, selectedIds) => {
        let selectedIndices = [];

        for (let idx = 0; idx < packages.length; idx++) {
            const pkgId = packages[idx].id;
            if (selectedIds.includes(pkgId)) {
                selectedIndices.push(idx);
            }
        }
        
        return selectedIndices;
    }

    packageConditionsFormatter = (row) => {
        return (
            <PackageConditionsIcons
                idSuffix={"testing-list-" + row.id}
                debtAmount={Number(row.debt)}
                isUnsynced={row.skip_migration}
                unsubmittedReportsAmount={row.unsubmitted_sales_reports}
            />
        );
    }
}

export default ApplicationsTable;
