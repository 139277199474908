import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import i18n from "i18next";
import defaultsDeep from "lodash/defaultsDeep";
import uniqueId from "lodash/uniqueId";
import moment from "moment";

import axios from "../../axios";
import commentUtils from "./utils";
import { addNewLocalErrorAlert, clearLocalAlerts } from "util/Alerts";
import { getServerErrorMessage } from "util/Errors";

import Alerts from "components/Alerts";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import { ReactComponent as CollapsedIcon } from "./icons/arrow-down.svg";
import { ReactComponent as UncollapsedIcon } from "./icons/arrow-up.svg";
import { ReactComponent as RefreshIcon } from "./icons/refresh.svg";

import CommentList from "./CommentList.jsx";
import CommentCreate from "./CommentCreate.jsx";

import "./Comments.scss";

function CommentsInvalidProps() {
  return (
    <div className="comments__uninitialized">
      <p>
        {i18n.t("comments.properties_not_initialized")}
      </p>
    </div>
  )
}

class Comments extends React.Component {
  static propTypes = {
    contentType: PropTypes.number.isRequired,
    objectId: PropTypes.number.isRequired,
    maxHeight: PropTypes.string,
    hideHeader: PropTypes.bool,
    isCollapsible: PropTypes.bool,
    startCollapsed: PropTypes.bool,
    onCommentCountUpdate: PropTypes.func,
    // automatic
    location: PropTypes.object.isRequired
  }

  static defaultProps = {
    maxHeight: "650px",
    hideHeader: false,
    isCollapsible: true,
    startCollapsed: true,
    onCommentCountUpdate: count => { }
  }

  constructor(props) {
    super(props)
    this.state = {
      groupId: uniqueId("comments__group__"),
      comments: [],
      olderLink: null,
      isLatestCommentsLoading: false,
      isOlderCommentsLoading: false,
      isCreateCommentLoading: false,
      deleteCommentId: null,
      isDeleteLoading: false,
      isCollapsed: props.isCollapsible && props.startCollapsed,
      lastUpdateAt: null // Should only track update of latest comments, not older.
    }
  }

  componentDidMount() {
    this.fetchLatestComments();
  }

  render() {
    if (!this.isValidProps()) {
      return (
        <CommentsInvalidProps />
      );
    }
    const { maxHeight } = this.props;
    return (
      <>
        <div className="comments" style={{ maxHeight }}>
          {this.renderHeader()}
          {this.renderContent()}
        </div>
      </>
    )
  }

  renderContent() {
    const { groupId, comments, isLatestCommentsLoading, isCollapsed } = this.state;
    if (isCollapsed) {
      return null;
    }
    if (isCollapsed) {
      return (
        <div className="comments__collapsed-content">
          {i18n.t("comments.content_collapsed")}
        </div>
      )
    };

    return (
      <>
        <CommentList
          comments={[...comments]}
          loading={isLatestCommentsLoading}
          onDelete={this.confirmDeleteComment}
          listPrependContent={this.renderLoadOlder()}
        ></CommentList>
        <Alerts
          hideGlobal={true}
          filterGroups={[groupId]}
        />
        <hr className="comments__divider" />
        <div className="comments__create">
          <CommentCreate onSubmit={this.createComment} />
        </div>
        {this.renderLastUpdate()}
        {this.renderConfirmDeleteModal()}
      </>
    )
  }

  renderHeader() {
    const { hideHeader, isCollapsible } = this.props;
    const { isCollapsed } = this.state;
    if (hideHeader) {
      return null;
    }
    const toggleCollapse = () => {
      this.setState(prevState => {
        return {
          isCollapsed: !prevState.isCollapsed
        }
      })
    }

    let headerContent;
    if (isCollapsible) {
      headerContent = (
        <div onClick={toggleCollapse} className="pointer">
          <span>
            {i18n.t("comments.default_header")}
          </span>
          {isCollapsed ?
            <CollapsedIcon />
            :
            <UncollapsedIcon />
          }
        </div>
      );
    } else {
      headerContent = (
        <>
          <span>
            {i18n.t("comments.default_header")}
          </span>
        </>
      );
    }

    return (
      <>
        <header className="comments__header">
          {headerContent}
        </header>
        {!isCollapsed &&
          <hr className="comments__divider" />
        }
      </>
    )
  }

  renderLoadOlder() {
    if (!this.canFetchOlder()) return null;

    let content;
    if (this.state.isOlderCommentsLoading) {
      content = <LoadingAnimation />;
    } else {
      content = (
        <button
          onClick={this.fetchOlderComments}
        >
          {i18n.t("comments.load_more_button")}
        </button>
      );
    }

    return (
      <div className="comments__load-more">
        {content}
      </div>
    );
  }

  renderLastUpdate() {
    const { lastUpdateAt, isLatestCommentsLoading } = this.state;
    if (!lastUpdateAt) return null;

    const formattedDate = moment(lastUpdateAt).format("HH:mm:ss");
    const localizedText = i18n.t("comments.last_updated_at", { date: formattedDate });

    const baseClass = "comments__last-update"
    const classNames = [baseClass];
    if (isLatestCommentsLoading) {
      classNames.push(`${baseClass}--loading`)
    }

    return (
      <div className={classNames.join(" ")} onClick={this.fetchLatestComments}>
        <span>
          {localizedText}
        </span>
        <RefreshIcon />
      </div>
    );
  }

  renderConfirmDeleteModal() {
    const { deleteCommentId, isDeleteLoading } = this.state;
    const isOpen = deleteCommentId !== null;
    const onCancel = () => {
      this.setState({
        deleteCommentId: null
      });
      clearLocalAlerts();
    };

    return (
      <ConfirmationModal
        title={i18n.t("comments.delete_comment_confirmation_title")}
        isOpen={isOpen}
        onToggle={onCancel}
        onCancel={onCancel}
        onConfirm={() => this.deleteComment(deleteCommentId)}
        isLoading={isDeleteLoading}
        alertGroups={[]}
      >
        <span className="regular-14">
          {i18n.t("comments.delete_comment_confirmation_text")}
        </span>
      </ConfirmationModal>
    );
  }


  isValidProps() {
    const { contentType, objectId } = this.props;
    return !!contentType && !!objectId;
  }

  latestCreatedAt() {
    const comments = this.sortedComments();
    const latestComment = comments[comments.length - 1];
    return latestComment?.created_at || null;
  }

  oldestCreatedAt() {
    const oldestComment = this.sortedComments()[0];
    if (oldestComment) {
      return oldestComment.created_at;
    } else {
      return new Date().toISOString();
    }
  }

  commentsErrorMessage(error, title) {
    let message = getServerErrorMessage(error);
    if (title) {
      message = `${title}: ${message}`
    }
    addNewLocalErrorAlert(message, this.state.groupId);
  }

  sortedComments() {
    return commentUtils.sortCommentsByCreatedAt(this.state.comments);
  }

  addUniqueComments(newComments) {
    const { comments } = this.state;
    const existingCommentIds = comments.map(comment => comment.id);
    const uniqueNewComments = newComments.filter(comment => !existingCommentIds.includes(comment.id));
    const sortedComments = commentUtils.sortCommentsByCreatedAt([...comments, ...uniqueNewComments]);
    this.setState({ comments: sortedComments });
  }

  fetchComments(config) {
    if (!this.isValidProps()) return;
    clearLocalAlerts();
    config = defaultsDeep(config, {
      latestComment: null,
      link: null,
      loadingField: "isLatestCommentsLoading",
      _isInitial: true // Should not be set manually
    });

    if (config._isInitial) {
      this.setState({
        [config.loadingField]: true
      })
    }

    let request;
    if (config.link) {
      request = axios.get(config.link);
    } else {
      const { contentType, objectId } = this.props;
      request = commentUtils.fetchInstanceComments(contentType, objectId);
    }

    return request
      .then(resp => {
        const newComments = resp.data.results;
        this.addUniqueComments(newComments);
        this.emitCommentCountUpdate()

        if (config.latestComment === null) {
          // If we don't have to reach some previous latest comment we can just return after first fetch.
          this.setState({
            olderLink: resp.data.older
          });
          return resp;
        } else {
          // If new comments include latest comment from before initial fetch it means
          // that we have fetched all the newer comments since last latest fetch.
          // Check needed if there are more newer comments than one fetch returns at a time.
          const isInitialLatestCommentReached = newComments.some(comment => comment.id === config.latestComment.id);
          if (isInitialLatestCommentReached) {
            return resp;
          }
        }

        // check if there is more comments to fetch.
        const olderCommentsLink = resp.data.older;
        if (olderCommentsLink !== null) {
          return this.fetchComments({
            ...config,
            _isInitial: false,
            link: olderCommentsLink
          });
        }

        return resp;
      })
      .catch(error => {
        if (config._isInitial) {
          const title = i18n.t("comments.errors.fetch_comments_error_title");
          this.commentsErrorMessage(error, title);
        }
        throw error;
      })
      .finally(() => {
        if (config._isInitial) {
          this.setState({
            [config.loadingField]: false
          })
        }
        if (!config.link) {
          // If no link given we know we tried to fetch the latest comments
          this.setState({
            lastUpdateAt: new Date()
          })
        }
      })
  }

  fetchLatestComments = () => {
    if (this.state.isLatestCommentsLoading) return;
    this.fetchComments({
      latestComment: commentUtils.getLatestComment(this.state.comments),
      loadingField: "isLatestCommentsLoading"
    })
  }

  fetchOlderComments = () => {
    if (!this.canFetchOlder()) return;
    if (this.state.isOlderCommentsLoading) return;

    this.fetchComments({
      link: this.state.olderLink,
      loadingField: "isOlderCommentsLoading"
    })
  }

  canFetchOlder() {
    return this.state.olderLink !== null;
  }

  createComment = (data) => {
    /*
      Method for creating comment in server.

      Returns a promise, which resolves to server response on success.
      Promise resolves to undefined if comment creation fails for some reason.
    */
    if (!this.isValidProps()) return Promise.resolve(undefined);

    clearLocalAlerts();

    const { contentType, objectId } = this.props;
    const request = commentUtils.createInstanceComment(
      contentType,
      objectId,
      data
    );
    this.setState({ isCreateCommentLoading: true });
    return request
      .then(resp => {
        this.fetchLatestComments();
        return resp;
      })
      .catch(error => {
        const title = i18n.t("comments.errors.create_comment_error_title");
        this.commentsErrorMessage(error, title);
        return undefined;
      })
      .finally(() => {
        this.setState({ isCreateCommentLoading: false });
      });
  }

  confirmDeleteComment = commentId => {
    // Already deleting some comment
    if (this.state.deleteCommentId !== null || !commentId) return;

    this.setState({
      deleteCommentId: commentId
    });
  }

  deleteComment = (commentId) => {
    /*
      Method for creating comment in server.
  
      Returns a promise, which resolves to server response on success.
      Promise resolves to undefined if comment creation fails for some reason.
    */
    if (!this.isValidProps()) return Promise.resolve(undefined);

    clearLocalAlerts();

    const { contentType, objectId } = this.props;
    const request = commentUtils.removeComment(contentType, objectId, commentId);
    return request
      .then(resp => {
        this.setState(prevState => {
          return {
            deleteCommentId: null,
            comments: prevState.comments.filter(c => c.id !== commentId)
          }
        })

        this.fetchLatestComments();

        return resp;
      })
      .catch(error => {
        const title = i18n.t("comments.errors.delete_comment_error_title");
        this.commentsErrorMessage(error, title);
        return undefined;
      })
      .finally(() => {
        this.setState({
          isDeleteLoading: false
        });
      });
  }

  emitCommentCountUpdate = () => {
    if (this.props.onCommentCountUpdate) {
      this.props.onCommentCountUpdate(this.state.comments.length)
    }
  }
}

export default withRouter(Comments);
