import * as actionTypes from 'redux/actions/actionTypes';
import {updateObject} from 'redux/utility';


const initialState = {
    allClients: [],
    allClientsLoading: false,
    
    standardComments: [],
    standardCommentsLoading: false,

    locations: [],
    locationsLoading: false,
};

const allClientsFetchStart = (state) => {
    return updateObject(state, {
        allClients: [],
        allClientsLoading: false,
    });
};

const allClientsFetchSuccess = (state, action) => {
    return updateObject(state, {
        allClients: action.clients,
        allClientsLoading: false,
    });
};

const allClientsFetchFail = (state, action) => {
    return updateObject(state, {
        allClientsLoading: false,
    });
};

const onStandardCommentsFetchStart = (state) => {
    return updateObject(state, {
        standardComments: [],
        standardCommentsLoading: false,
    });
};

const onStandardCommentsFetchSuccess = (state, action) => {
    return updateObject(state, {
        standardComments: action.standardComments,
        standardCommentsLoading: false,
    });
};

const onStandardCommentsFetchFail = (state, action) => {
    return updateObject(state, {
        standardCommentsLoading: false,
    });
};

const onLocationsFetchStart = (state) => {
    return updateObject(state, {
        locations: [],
        locationsLoading: false,
    });
};

const onLocationsFetchSuccess = (state, action) => {
    return updateObject(state, {
        locations: action.locations,
        locationsLoading: false,
    });
};

const onLocationsFetchFail = (state, action) => {
    return updateObject(state, {
        locationsLoading: false,
    });
};

export const general = (state = initialState, action) => {
    switch (action.type) {
        // ALL CLIENTS OPTIONS
        case actionTypes.ALL_CLIENTS_FETCH_START:
            return allClientsFetchStart(state, action);
        case actionTypes.ALL_CLIENTS_FETCH_SUCCESS:
            return allClientsFetchSuccess(state, action);
        case actionTypes.ALL_CLIENTS_FETCH_FAIL:
            return allClientsFetchFail(state, action);

        // STANDARD COMMENTS OPTIONS
        case actionTypes.STANDARD_COMMENTS_FETCH_START:
            return onStandardCommentsFetchStart(state, action);
        case actionTypes.STANDARD_COMMENTS_FETCH_SUCCESS:
            return onStandardCommentsFetchSuccess(state, action);
        case actionTypes.STANDARD_COMMENTS_FETCH_FAIL:
            return onStandardCommentsFetchFail(state, action);

        // LOCATION OPTIONS
        case actionTypes.LOCATIONS_FETCH_START:
            return onLocationsFetchStart(state, action);
        case actionTypes.LOCATIONS_FETCH_SUCCESS:
            return onLocationsFetchSuccess(state, action);
        case actionTypes.LOCATIONS_FETCH_FAIL:
            return onLocationsFetchFail(state, action);
        
        default:
            return state;
    }
};