import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";

import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Row, Col } from 'reactstrap';

import { ReactComponent as MoreVerticalIcon } from 'assets/icons/more-vertical.svg';
// import {ReactComponent as HideActive} from "assets/icons/hide-active.svg";
import { ReactComponent as HideInactive } from "assets/icons/hide-inactive.svg";

import { getSaleReportUrl, getSaleReportFixUrl } from "config/urls";
import {
    createRepairReport,
    fetchSalesReportExportFile,
    isRepairReportClientEditable,
    isRecent,
} from "util/SalesReports";

import "./MenuCell.scss";

import {
    salesReportImportOpen,
} from "redux/actions/reports/importSalesReport";
import { alertsClearLocal } from "redux/actions/alerts";
import { addNewLocalErrorAlert } from "util/Alerts";
import { withTranslation } from "react-i18next";

const StyledDropdownItem = (props) => <DropdownItem {...props} className="semibold-14 text-muted">{props.children}</DropdownItem>;

class ActionsCell extends React.Component {
    static propTypes = {
        isDetailsViewMode: PropTypes.bool, // requires isRepair 
        isRepair: PropTypes.bool, // needed specifically when used in report details view. Specifices which type of report is open
        salesReport: PropTypes.object.isRequired,
        repairReport: PropTypes.object,
        salesReportImportOpen: PropTypes.func.isRequired,
        alertsClearLocal: PropTypes.func.isRequired,
    }

    render() {
        if (this.props.isDetailsViewMode) {
            return this.renderDetailsViewMode();
        }
        if (this.isOldWithoutRepair()) {
            return this.renderViewOriginal();
        }

        return this.renderDropdownMenu();
    }

    renderDetailsViewMode() {
        return (
            <Row>
                {this.renderActions("button-col")}
            </Row>
        )
    }

    renderDropdownMenu() {
        return (
            <UncontrolledButtonDropdown direction="left" className="sales-actions" onClick={e => { e.stopPropagation(); }}>
                <DropdownToggle tag="div">
                    <Button color="link" className="icon-default sales-actions-icon-button">
                        <MoreVerticalIcon />
                    </Button>
                </DropdownToggle>
                <DropdownMenu>
                    {this.renderActions("dropdown")}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }

    renderActions(mode) {
        const renderActionList = [
            this.renderActionViewOriginal,
            this.renderActionUpdateOriginalFromFile,
            this.renderActionCreateRepair,
            this.renderActionViewRepair,
            this.renderActionUpdateRepairFromFile,
            this.renderActionExportExcelTemplate]

        return renderActionList.map((renderAction, index) => renderAction(mode))
    }

    renderItem(text, onClick, options) {
        const config = _.defaultsDeep(options, {
            mode: "dropdown", // "dropdown" or "button-col"
        });
        if (config.mode === "button-col") {
            return (
                <Col key={text} xs="auto" className="px-1 py-1">
                    <Button color="success" outline onClick={() => onClick()}>{text}</Button>
                </Col>
            );
        }

        return (
            <StyledDropdownItem
                onClick={() => onClick()}
                key={text}
            >
                {text}
            </StyledDropdownItem>
        );
    }

    renderActionViewOriginal = (mode) => {
        const { t, isDetailsViewMode, isRepair } = this.props;
        if (isDetailsViewMode && !isRepair) return;

        const createOriginalText = t("reports.sales.menu.openUnsubmittedOriginal", "Lisa aruanne");
        const viewOriginalText = t("reports.sales.menu.openSubmittedOriginalWithRepair", "Vaata originaali");
        const viewOriginalInDetailsText = t("reports.sales.menu.openSubmittedOriginalWithRepairInDetails", "Vaata esialgset aruannet");
        const viewText = t("reports.sales.menu.openSubmittedOriginal", "Vaata");

        let action = this.onView;
        let text = createOriginalText;
        if (isDetailsViewMode) {
            text = viewOriginalInDetailsText;
            action = this.onViewTab;
        }
        else if (this.isOriginalSubmitted()) {
            if (this.hasRepair()) {
                text = viewOriginalText;

            } else {
                text = viewText;
            }
        }

        return this.renderItem(text, action, { mode });
    }

    renderActionViewRepair = (mode) => {
        const { t, isDetailsViewMode, isRepair } = this.props;

        const viewRepairText = t("reports.sales.menu.openSubmittedRepairReport", "Vaata parandust");
        const viewRepairInDetailsText = t("reports.sales.menu.openSubmittedRepairReportInDetails", "Vaata parandusaruannet");
        const editRepairText = t("reports.sales.menu.openEditableRepairReport", "Paranda aruanne");

        let action = this.onViewRepair;
        let text = viewRepairText;
        if (isDetailsViewMode) {
            if (isRepair || !this.hasRepair()) return;
            action = this.onViewRepairTab;
            text = viewRepairInDetailsText;
        } else if (this.hasRepair()) {
            text = this.hasEditableRepair() ? editRepairText : viewRepairText;
        } else {
            return;
        }

        return this.renderItem(text, action, { mode, target: "_blank" });
    }

    renderActionCreateRepair = mode => {
        const { t } = this.props;

        if (!this.isRecentPeriod()) return;
        if (!this.isSubmittedOriginalWithoutRepair()) return;

        const text = t("reports.sales.menu.createRepairReport", "Lisa parandus");
        return this.renderItem(text, this.onCreateRepairReport, { mode });
    }

    renderActionUpdateOriginalFromFile = mode => {
        const { t, isDetailsViewMode } = this.props;

        if (isDetailsViewMode && this.isRepair) return;
        if (!this.isRecentPeriod() || this.isOriginalSubmitted()) return;

        const text = t("reports.sales.menu.importOriginalReport", "Lisa aruanne failist");
        return this.renderItem(text, this.onImportFromFile, { mode });
    }

    renderActionUpdateRepairFromFile = mode => {
        const { t, isDetailsViewMode, isRepair } = this.props;

        if (!isDetailsViewMode) return;
        if (isDetailsViewMode && !isRepair) return;
        if (!this.isRecentPeriod()) return;
        if (!this.hasEditableRepair()) return;

        const text = t("reports.sales.menu.importRepairReport", "Paranda aruanne failist")
        return this.renderItem(text, this.onImportRepairFromFile, { mode });
    }

    renderActionExportExcelTemplate = mode => {
        const { t, isDetailsViewMode, isRepair } = this.props;
        if (!isDetailsViewMode) return;
        if (isDetailsViewMode && !isRepair && this.isOriginalSubmitted()) return;
        if (!this.isRecentPeriod()) return;
        if (this.isOriginalSubmitted() && !this.hasEditableRepair()) return;

        const text = t("reports.sales.menu.exportExcelTemplate", "Ekspordi excel templaat");
        return this.renderItem(text, this.onGetExcelTemplate, { mode });
    }

    renderViewOriginal() {
        return (
            <Button
                color="link"
                className="icon-default sales-actions-icon-button"
                onClick={this.onView}
            >
                <HideInactive className="" />
            </Button>
        );
    }

    onView = (isOpenInTab = false) => {
        const { history, salesReport } = this.props;
        const id = salesReport.id;
        const route = getSaleReportUrl(id);
        if (isOpenInTab) {
            window.open(route, '_blank');
        } else {
            history.push(route);
        }
    }

    onViewTab = () => {
        this.onView(true);
    }

    onViewRepair = (isOpenInTab = false) => {
        const { history, repairReport } = this.props;
        const id = repairReport.id;
        const route = getSaleReportFixUrl(id);
        if (isOpenInTab) {
            window.open(route, '_blank');
        } else {
            history.push(route);
        }
    }

    onViewRepairTab = () => {
        this.onViewRepair(true);
    }

    onCreateRepairReport = () => {
        const { t, location, salesReport, alertsClearLocal } = this.props;
        alertsClearLocal();
        createRepairReport(salesReport.id, this.onCreateRepairReportSuccess).then(
            result => {
                if (!result.isSuccess) {
                    addNewLocalErrorAlert(
                        t("reports.sales.menu.alert.createRepairReportFailedText", "Müügiaruande paranduse loomine ebaõnnestus: ") + result.errorMessage,
                        location.pathname,
                        true
                    );
                }
            }
        );
    }

    onCreateRepairReportSuccess = (repairSalesReportId) => {
        this.props.history.push(getSaleReportFixUrl(repairSalesReportId));
    }

    onImportFromFile = () => {
        this.props.salesReportImportOpen(this.props.salesReport);
    }

    onImportRepairFromFile = () => {
        this.props.salesReportImportOpen(this.props.repairReport);
    }

    onGetExcelTemplate = () => {
        const { t, location, salesReport, repairReport, isDetailsViewMode, isRepair } = this.props;

        let report = salesReport;
        if (isDetailsViewMode) {
            if (isRepair) report = repairReport
            else report = salesReport;
        } else if (this.isSubmittedOriginalWithEditableRepair()) {
            report = repairReport;
        }

        this.props.alertsClearLocal();
        fetchSalesReportExportFile(report.id, report.is_repair, report.period).then(
            result => {
                if (!result.isSuccess) {
                    addNewLocalErrorAlert(
                        t("reports.sales.menu.exportExcelTemplateFailed", "Aruande templaadi allatõmbamine ebaõnnestus: ") + result.errorMessage,
                        location.pathname
                    );
                }
            }
        );
    }

    isRecentPeriod = () => {
        return isRecent(this.props.salesReport.period);
    }

    isOriginalSubmitted = () => {
        return this.props.salesReport.submitted_at != null;
    }

    hasRepair = () => {
        return !!this.props.repairReport;
    }

    isSubmittedOriginalWithEditableRepair = () => {
        return this.isOriginalSubmitted() && this.hasEditableRepair();
    }

    hasEditableRepair = () => {
        return isRepairReportClientEditable(this.props.repairReport);
    }

    isSubmittedOriginalWithoutRepair = () => {
        return this.isOriginalSubmitted() && this.props.repairReport === null;
    }

    isOldWithoutRepair = () => {
        return !this.hasRepair() && !this.isRecentPeriod();
    }
}

const reduxActions = {
    salesReportImportOpen,
    alertsClearLocal,
}

export default withRouter(connect(null, reduxActions)(withTranslation()(ActionsCell)));
