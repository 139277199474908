import { combineReducers } from "redux";
import { packagesInTest } from "./packagesInTest";
import { packagesInRetest } from "./packagesInRetest";
import { packagesUnconfirmed } from "./packagesUnconfirmed";
import { packagesTested } from "./packagesTested";
import { packagesTestingAll } from "./packagesTestingAll";

const partnerReducer = combineReducers({
    packagesInTest,
    packagesInRetest,
    packagesUnconfirmed,
    packagesTested,
    packagesTestingAll,
});

export default partnerReducer;
