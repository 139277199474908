import {updateObject} from "../utility";
import * as actionTypes from "../actions/actionTypes";


const initialCompanyProfile = {
    headerSuffix: "",
};

const initialState = {
    companyProfile: initialCompanyProfile,
};

const updateHeaderSuffix = (state, text) => {
    return updateObject(state, {
        headerSuffix: text ? text : "",
    });
};

export const navigation = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NAVIGATION_UPDATE_HEADER_SUFFIX:
            return updateHeaderSuffix(state, action.text);
        default:
            return state;
    }
};