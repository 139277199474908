import React from "react";
import PropTypes from "prop-types";

import commentUtils from "./utils";

import { ReactComponent as SubmitIcon } from "./icons/submit.svg";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";

import "./CommentCreate.scss";

const getInitialCommentData = () => {
	return {
		body: "",
		visibility: commentUtils.VISIBILITY.PUBLIC
	}
}

class CommentCreate extends React.Component {
	static propTypes = {
		onSubmit: PropTypes.func.isRequired, // We expect it to return a promise
	}

	static defaultProps = {
	}

	constructor(props) {
		super(props)
		this.state = {
			isSubmitLoading: false,
			...getInitialCommentData()
		}
		this.inputRef = React.createRef()
	}

	componentDidMount() {
	}

	componentDidUpdate(prevProps, prevState) {
	}

	render() {
		const { body, isSubmitLoading } = this.state
		return (
			<div className="comment-create">
				<div className="comment-create__input">
					<div className="grow-wrap">
						<textarea
							ref={this.inputRef}
							type="textarea"
							value={body}
							onChange={this.onBodyChange}
							rows="1"
							placeholder="Aa"
							onInput={(e) => this.updateHeight(e.target)}
							onKeyDown={this.handleTextareaKeyDown}
							disabled={isSubmitLoading}
						/>
						<div className="comment-create__submit">
							{isSubmitLoading ?
								<LoadingAnimation />
								:
								<button
									className="comment-create__submit-button"
									onClick={this.submitComment}
									disabled={!this.canSubmit()}
								>
									<SubmitIcon />
								</button>
							}
						</div>
					</div>
				</div>
			</div>
		)
	}

	onBodyChange = event => {
		this.setState({
			body: event.target.value
		})
	}

	updateHeight(el) {
		/* Specific to this component solution for textarea */
		el.parentNode.dataset.replicatedValue = el.value
	}

	pasteIntoInput(el, text) {
		el.focus();
		if (typeof el.selectionStart == "number"
			&& typeof el.selectionEnd == "number") {
			var val = el.value;
			var selStart = el.selectionStart;
			el.value = val.slice(0, selStart) + text + val.slice(el.selectionEnd);
			el.selectionEnd = el.selectionStart = selStart + text.length;
		} else if (typeof document.selection != "undefined") {
			var textRange = document.selection.createRange();
			textRange.text = text;
			textRange.collapse(false);
			textRange.select();
		}
	}

	handleTextareaKeyDown = e => {
		const isEnterDown = e.key === "Enter"
		const isModifierDown = e.altKey || e.shiftKey || e.ctrlKey
		if (isEnterDown && !isModifierDown) {
			e.preventDefault()
			this.submitComment()
		} else if (isEnterDown && isModifierDown) {
			// shift + enter already adds a newline
			if (!e.shiftKey) {
				this.pasteIntoInput(e.target, "\n")
			}
		}
		// Make sure textarea height is up to state with any changes made here.
		this.updateHeight(e.target);
	}

	isBodyValid = () => {
		return commentUtils.ruleTrimmedStringLengthAtleast(this.state.body)
	}

	canSubmit = () => {
		if (!this.props.onSubmit) return false;
		if (this.state.isSubmitLoading) return false;
		return this.isBodyValid()
	}

	submitComment = () => {
		const { onSubmit } = this.props;
		const { body } = this.state
		if (!this.canSubmit()) {
			return;
		}
		this.inputRef.current.blur()
		const newComment = {
			body,
			visibility: commentUtils.VISIBILITY.PUBLIC,
			// type // Users can only create message type, set in endpoint
			// role // set in server
			// created_at // set in server
			// created_by // set in server
		}

		this.setState({
			isSubmitLoading: true
		})
		onSubmit(newComment).then(
			resp => {
				if (resp !== undefined) {
					// Reset comment
					this.setState(getInitialCommentData())
				}
			}
		).finally(() => this.setState({ isSubmitLoading: false }))
	}


}

export default CommentCreate;
