export const invoiceStatuses = {
    UNPAID_STATUS: {
        status: "UNPAID",
        values: ["Tasumata", "Unpaid"],
        type: "WARNING"
    },
    PAID_STATUS: {
        status: "PAID",
        values: ["Tasutud", "Paid"],
        type: "SUCCESS"
    },
    OVERDUE_STATUS: {
        status: "OVERDUE",
        values: ["Üle tähtaja", "Overdue"],
        type: "ERROR"
    },
}

export function getSaleStatusType(statusValue) {
    for (const invoiceStatus of Object.values(invoiceStatuses)) {
        if (invoiceStatus.values.includes(statusValue)) {
            return invoiceStatus.type;
        }
    }

    return "UNKNOWN";
}
