
import React from "react";
import PropTypes from "prop-types";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import { isAdminMode } from "util/Access";

import WrappedLoader from "components/Loader/WrappedLoader";
import Comments from "components/Comments";
import { ReactComponent as ErrorIcon } from 'assets/icons/alert-default.svg';
import { ReactComponent as CircleQuestionIcon } from "assets/icons/circle-question.svg";
import { ReactComponent as AddCommentIcon } from "assets/icons/comment-solid.svg";
import { ReactComponent as ReadCommentsIcon } from "assets/icons/comments-solid.svg";


import "./ReportLineList.scss";

function customTooltipHeaderFormatter(column, colIndex, { sortElement, filterElement }) {
    return (
        <div style={{ minWidth: "max-content" }}>
            {filterElement}
            {column.text} <CircleQuestionIcon className="color-gray-light" />
            {sortElement}
        </div>
    );
}


function NoDataIndication(props) {
    let content =
        <Trans i18nKey="packages.noPackagesText">

        </Trans>
    if (props.loading) {
        content = <WrappedLoader />;
    } else if (props.error) {
        content = <span className="color-error">{props.error}</span>;
    }

    return <div className="text-center">{content}</div>;
}

class ReportLineList extends React.Component {
    static propTypes = {
        lines: PropTypes.array,
        isLoading: PropTypes.bool,
        onCommentCountUpdate: PropTypes.func.isRequired
    }

    static defaultProps = {
        onCommentCountUpdate: (row, count) => { }
    }

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    state = {

    }

    componentDidMount() {
    }

    render() {
        const { t, isLoading, lines } = this.props;
        if (isLoading) {
            return <WrappedLoader />;
        }
        let noLinesText = <></>;
        if (!lines) {
            return <></>;
        }

        return (
            <div className="return-report-line-list">
                {noLinesText}
                {this.renderTable()}
            </div>
        );
    }

    renderTable() {
        const { t, lines, isLoading } = this.props;

        const isAdmin = isAdminMode();
        const data = lines;
        const columns = [
            {
                dataField: "package.ean",
                text: t("reports.filter.ean", "EAN"),
                classes: "semibold-14",
                sort: true,
                footer: "",
                filter: textFilter({
                    placeholder: t("reports.filter.ean", "EAN"),
                    style: {
                        marginLeft: "18px"
                    }
                })
            },
            {
                dataField: "package.name",
                text: t("reports.filter.product"),
                classes: "semibold-14",
                sort: true,
                footer: "",
                filter: textFilter({
                    placeholder: t("reports.filter.product"),
                    style: {
                        minWidth: "150px"
                    }
                })
            },
            {
                dataField: "package.package_type",
                text: t("reports.filter.packageType"),
                classes: "regular-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "package.volume",
                text: t("reports.filter.packageVolume"),
                classes: "regular-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "quantity_sold",
                text: t("reports.filter.quantitySold"),
                classes: "regular-14",
                sort: true,
                footer: "",
                headerFormatter: customTooltipHeaderFormatter,
                headerTitle: () => t("reports.return.tooltip.quantity_sold")
            },
            {
                dataField: "quantity_returned",
                text: t("reports.filter.quantityReturned"),
                classes: "regular-14",
                sort: true,
                footer: "",
                headerFormatter: customTooltipHeaderFormatter,
                headerTitle: () => t("reports.return.tooltip.quantityReturned")
            },
            {
                dataField: "returned_percentage",
                text: t("reports.filter.returnedPercentage"),
                classes: "regular-14",
                sort: true,
                footer: "",
                headerFormatter: customTooltipHeaderFormatter,
                headerTitle: () => t("reports.return.tooltip.returnedPercentage")
            },
            {
                dataField: "client_sale_percentage",
                text: t("reports.filter.clientSalePercentage"),
                classes: "regular-14",
                sort: true,
                footer: "",
                show: isAdmin
            },
            {
                dataField: "returned_more_than_sold",
                text: t("reports.filter.returnedMoreThanSold"),
                classes: "regular-14",
                sort: true,
                footer: "",
                align: "center",
                formatter: (value) => {
                    if (!value) return null;
                    return (
                        <ErrorIcon className="report-line-list__excess-return-icon color-info" />
                    )
                },
                headerFormatter: customTooltipHeaderFormatter,
                headerTitle: () => t("reports.return.tooltip.returnedMoreThanSold")
            },
            {
                dataField: "updated_at",  // updates on import changes and new comments
                text: t("reports.filter.updatedAt"),
                classes: "regular-14",
                sort: true,
                footer: "",
                style: {
                    width: "170px"
                },
                formatter: (value, line) => {
                    return moment(value).format("DD.MM.YYYY HH:mm")
                },
                show: false // Hidden as they do not want it to be shown
            },
            {
                dataField: "created_at", // updates only on import changes
                text: t("reports.filter.createdAt"),
                classes: "regular-14",
                sort: true,
                footer: "",
                style: {
                    width: "170px"
                },
                formatter: (value, line) => {
                    if (!value) return null;
                    return moment(value).format("DD.MM.YYYY HH:mm")
                },
                show: false // Hidden as they wanted such a field on report list level not line
            },
            {
                dataField: "comments_count",
                text: t("comments.column.header"),
                classes: "semibold-14",
                sort: true,
                footer: "",
                align: "center",
                formatter: (value, item) => {
                    const mainClass = "report-line-list__comment-button";
                    const classNames = [mainClass];
                    let Icon, text;
                    if (value > 0) {
                        Icon = ReadCommentsIcon;
                        text = t("comments.column.readComments", { count: value });
                        classNames.push(`${mainClass}--has-comments`)
                    } else {
                        Icon = AddCommentIcon;
                        text = t("comments.column.addComment");
                    };
                    return (
                        <div className={classNames.join(" ")}>
                            <Icon />{" "}
                            {text}
                        </div>
                    )
                },
            },
        ].filter(header => header.show !== false); // Show if explicitly not set to false

        const defaultSorted = [{
            dataField: "created_at",
            order: "desc"
        }]

        const expandRow = {
            parentClassName: "report-line-list__expanded-parent-row",
            renderer: row => (
                <div>
                    <Comments
                        contentType={row.content_type}
                        objectId={row.id}
                        maxHeight="500px"
                        isCollapsible={false}
                        startCollapsed={false}
                        hideHeader={true}
                        onCommentCountUpdate={(count) => this.props.onCommentCountUpdate(row, count)}
                    />
                </div>
            )
        };

        const noDataIndication = <NoDataIndication loading={isLoading} />;

        return (
            <>
                <BootstrapTable
                    bootstrap4
                    hover
                    bordered={false}
                    classes="report-line-list__table table-layout-auto"
                    wrapperClasses="report-line-list__table-wrapper table-responsive flipped-horizontal-scrollbar"
                    rowClasses="cursor-pointer"
                    keyField='id'
                    data={data}
                    columns={columns}
                    expandRow={expandRow}
                    filter={filterFactory()}
                    filterPosition="top"
                    defaultSorted={defaultSorted}
                    noDataIndication={noDataIndication}
                />
            </>
        );
    }
}

export default withTranslation()(ReportLineList);
