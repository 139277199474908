import React from "react";

import { Switch, NavLink, Route } from "react-router-dom";
import urls from "config/urls";
import { withTranslation } from "react-i18next";

class ProfileView extends React.Component {
    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={(props) => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        const { t } = this.props;

        return (
            <>
                <div className="navigation-buttons pb-4">
                    <NavLink
                        to={urls.PROFILE_EDIT}
                        className="mr-1 btn btn-default"
                    >
                        {t("profile.tabs.profile", "Profiil")}
                    </NavLink>
                    <NavLink
                        to={urls.PROFILE_CONTRACT}
                        className="ml-1 mr-1 btn btn-default"
                    >
                        {t("profile.tabs.contract", "Leping")}
                    </NavLink>
                    <NavLink
                        to={urls.PROFILE_USERS}
                        className="btn btn-default ml-1"
                    >
                        {t("profile.tabs.users", "Kasutajad")}
                    </NavLink>
                    <NavLink
                        to={urls.PROFILE_NOTIFICATIONS}
                        className="btn btn-default ml-1"
                    >
                        {t("profile.tabs.notifications", "Teavitused")}
                    </NavLink>
                </div>

                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </>
        );
    }
}

export default withTranslation("common")(ProfileView);
