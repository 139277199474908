import XLSX from "xlsx";
import { DATE_FORMAT_XLSX } from "util/DateTime";

export const exportExcelPartnerTesting = (
    fileName,
    sheetName,
    packages,
    filtersUsed,
    dateField = "tested_at"
) => {
    const wb = XLSX.utils.book_new();

    const packagesSheet = getPackagesSheet(packages, dateField);
    XLSX.utils.book_append_sheet(wb, packagesSheet, sheetName);

    const extraInfoSheet = getExtraInfoSheet(filtersUsed);
    XLSX.utils.book_append_sheet(wb, extraInfoSheet, "Lisainfo");

    /* Trigger Download with `writeFile` */
    XLSX.writeFile(wb, fileName + ".xlsx", { compression: true });
};

function getExtraInfoSheet(filters) {
    const extraInfoRows = [
        ["Koostatud", new Date()],
        [],
        ["Filtrid"],
        ["EAN", filters.ean],
        ["Toote nimetus", filters.name],
        ["Ettevõte", filters.client ? filters.client.label : ""],
        ["Kategooria", filters.category ? filters.category.description : ""],
        ["Pakendi tüüp", filters.type ? filters.type.value : ""],
        ["Maht", filters.volume ? filters.volume.value : ""],
        ["Staatus", filters.testResult ? filters.testResult.label : ""],
        ["Alates", filters.startDate],
        ["Kuni", filters.endDate],
    ];

    const ws = XLSX.utils.aoa_to_sheet(extraInfoRows, {
        dateNF: DATE_FORMAT_XLSX,
    });

    // Set widths
    const colWidths = [25, 25];
    ws["!cols"] = colWidths.map((x) => ({ width: x }));

    return ws;
}

function getPackagesSheet(packages, dateField) {
    let dateColumnHeader = "";
    if (dateField === "tested_at") {
        dateColumnHeader = "Viimati testitud";
    } else if (dateField === "created_at") {
        dateColumnHeader = "Testimisse saadetud";
    }

    const packagesHeaders = [
        "EAN",
        "Tootenimetus",
        "Ettevõte",
        "Kategooria",
        "Pakendi tüüp",
        "Maht",
        dateColumnHeader,
        "Staatus",
    ];
    const packagesDataRows = getPackagesDataRows(packages, dateField);
    const packagesRows = [packagesHeaders, ...packagesDataRows];
    const ws = XLSX.utils.aoa_to_sheet(packagesRows, {
        dateNF: DATE_FORMAT_XLSX,
    });

    if (packagesDataRows.length === 0) {
        XLSX.utils.sheet_add_aoa(
            ws,
            [["Ükski pakend ei vasta otsingutingimustele."]],
            { origin: { r: 1, c: 0 } }
        ); //sheet_add_aoa expects array of arrays.
        ws["!merges"] = [
            { s: { r: 1, c: 0 }, e: { r: 1, c: packagesHeaders.length - 1 } },
        ];
    }
    // Set widths
    const colWidths = [18, 25, 25, 20, 20, 10, 15, 10];
    ws["!cols"] = colWidths.map((x) => ({ width: x }));

    return ws;
}

function getPackagesDataRows(packages, dateField) {
    return packages.map((pkg) => [
        pkg.ean,
        pkg.name,
        pkg.client.label,
        pkg.category.label,
        pkg.type,
        pkg.volume,
        pkg[dateField] ? new Date(pkg[dateField]) : "",
        pkg.status.label,
    ]);
}
