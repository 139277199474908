import React from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Card, CardBody } from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import WrappedLoader from "components/Loader/WrappedLoader";

import { getAdminDebtsClientInvoicesUrl } from "config/urls";

import "./DebtsTable.scss";

//import { fetchDebts } from "util/admin/Debts";
import { currencyFormatter } from "util/General";
import moment from "moment";
import { DATE_FORMAT } from "util/DateTime";

const NoDataIndication = (props) => {
    let content = "Valitud ajavahemikus võlad puuduvad.";
    if (props.loading) {
        content = <WrappedLoader />;
    }
    return <div className="text-center">{content}</div>;
};

class DebtsTable extends React.Component {
    static propTypes = {
        debts: PropTypes.array,
    };

    componentDidMount() {
        if (this.props.debts && this.props.debts.length) {
            return;
        } else {
            /*
            fetchDebts(this.props.filters);
            */
        }

    }

    onRowClick = (e, row) => {
        const url = getAdminDebtsClientInvoicesUrl(row.id);
        this.props.history.push(url);
    };

    render() {
        if (this.props.debts === null) {
            return null;
        }

        if (this.props.loading) {
            return <WrappedLoader />;
        }

        return (
            <Card className="packages-card">
                <CardBody className="p-0 flipped-horizontal-scrollbar">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }

    renderTable() {
        const columns = [
            {
                dataField: "name",
                text: "Klient",
                sort: true,
                classes: "frozen-first-column semibold-14",
                headerClasses: "frozen-first-column",
            },
            {
                dataField: "unsubmitted_sales_reports",
                text: "Esitamata müügiaruanded",
                sort: true,
            },
            {
                dataField: "due_date",
                text: "Tasumise tähtaeg",
                formatter: due_date => {
                    const dueMoment = moment(due_date, "DD-MM-YYYY");
                    return dueMoment.isValid() ? dueMoment.format(DATE_FORMAT) : due_date;
                },
                sortValue: due_date => moment(due_date, "DD-MM-YYYY").format("YYYY-MM-DD"),
                sort: true,
            },
            {
                dataField: "overdue",
                text: "Tähtaeg ületatud",
                formatter: (daysOverdue) => `${daysOverdue} päeva`,
                sort: true,
            },
            {
                dataField: "debt",
                text: "Võlgnevus",
                formatter: (amount) => currencyFormatter.format(amount),
                sort: true,
                sortValue: (cell, row) => Number(cell),
                align: "right",
                headerAlign: "right",
            },
        ];

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onRowClick(e, row);
            },
        };

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="table-layout-auto"
                wrapperClasses="table-responsive"
                keyField="id"
                data={this.props.debts}
                columns={columns}
                rowClasses={rowClasses}
                rowEvents={rowEvents}
                noDataIndication={
                    <NoDataIndication loading={this.props.loading} />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        debts: state.admin.debts.debtsList,
        filters: state.admin.debts.filters,
        loading: state.admin.debts.loading,
    };
};

export default withRouter(connect(mapStateToProps)(DebtsTable));
