import * as actionTypes from './actionTypes';
import {alertContext} from "util/Alerts";

export const alertsAddGlobal = (alertType, content, group=alertContext.GLOBAL) => {
    return {
        type: actionTypes.ALERTS_ADD_GLOBAL,
        context: alertContext.GLOBAL,
        group: group,
        alertType: alertType,
        content: content,
    }
};

export const alertsAddLocal = (alertType, content, group=alertContext.LOCAL) => {
    return {
        type: actionTypes.ALERTS_ADD_LOCAL,
        context: alertContext.LOCAL,
        group: group,
        alertType: alertType,
        content: content,
    }
};


export const alertsClearAll = () => {
    return {
        type: actionTypes.ALERTS_CLEAR_ALL
    }
};

export const alertsClearGlobal = () => {
    return {
        type: actionTypes.ALERTS_CLEAR_GLOBAL
    }
};

export const alertsClearLocal = () => {
    return {
        type: actionTypes.ALERTS_CLEAR_LOCAL
    }
};

export const alertsClearIncludedGroups = (...groups) => {
    return {
        type: actionTypes.ALERTS_CLEAR_INCLUDED_GROUPS,
        groups,
    }
};

export const alertsClearUnincludedGroups = (...groups) => {
    return {
        type: actionTypes.ALERTS_CLEAR_UNINCLUDED_GROUPS,
        groups,
    }
};

export const alertsUpdateActiveGroups = (...groups) => {
    return {
        type: actionTypes.ALERTS_UPDATE_ACTIVE_GROUPS,
        groups,
    }
}