import * as actionTypes from 'redux/actions/actionTypes';
import {updateObject} from 'redux/utility';

const initialState = {
    open: false,
    details: {},
    file: null,
    loading: false, 
    errors: [],
};

const salesReportImportOpen = (state, action) => {
    return {
        ...initialState,
        open: true,
        details: action.details,
    };
};

const salesReportImportFileChange = (state, action) => {
    return updateObject(state, {
        file: action.file,
    });
}

const salesReportImportStart = (state) => {
    return updateObject(state, {
        loading: true,
        errors: [],
    });
};

const salesReportImportSuccess = (state) => {
    return updateObject(state, {
        loading: false,
    });
};

const salesReportImportFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        errors: action.errors,
    });
};

const salesReportImportClose = (state) => {
    return {...initialState};
};

export const importSalesReport = (state = {...initialState}, action) => {
    switch (action.type) {
        case actionTypes.SALES_REPORT_IMPORT_OPEN:
            return salesReportImportOpen(state, action);
        case actionTypes.SALES_REPORT_IMPORT_FILE_CHANGE:
            return salesReportImportFileChange(state, action);
        case actionTypes.SALES_REPORT_IMPORT_START:
            return salesReportImportStart(state, action);
        case actionTypes.SALES_REPORT_IMPORT_SUCCESS:
            return salesReportImportSuccess(state, action);
        case actionTypes.SALES_REPORT_IMPORT_FAIL:
            return salesReportImportFail(state, action);
        case actionTypes.SALES_REPORT_IMPORT_CLOSE:
            return salesReportImportClose(state, action);
        
        default:
            return state;
    }
};