let axios = require('axios');


let baseURL = process.env.REACT_APP_API_HOST;
if (!baseURL) {
    console.error("API Base url (REACT_APP_API_HOST) is not set!");
}

let axiosInstance = axios.create({
    baseURL: baseURL,
    headers: {
        "Accept-Language": "et",
    }
});
/*
axiosInstance.interceptors.response.use((response) => {
    return response
}, function (error) {

    if (error.response.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
        //window.location.href = '/';
        return Promise.reject(error);
    }

    return Promise.reject(error);
});
*/

module.exports = axiosInstance;