import axios from "../../axios";
import { ADMIN_INVOICES, ADMIN_INVOICE_REMINDER, ADMIN_INVOICES_SUMMARY } from "config/Api";

export const fetchAdminInvoices = (clientId) => {
  const endpoint = ADMIN_INVOICES.replace("<client_id>", clientId);
  return axios
    .get(endpoint)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const sendInvoiceReminder = (invoiceId) => {
  const endpoint = ADMIN_INVOICE_REMINDER.replace("<invoice_id>", invoiceId);
  return axios
    .get(endpoint)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};

export const sendInvoicesSummary = (clientId) => {
  const endpoint = ADMIN_INVOICES_SUMMARY.replace("<client_id>", clientId);
  return axios
    .get(endpoint)
    .then((resp) => {
      return resp.data;
    })
    .catch((err) => {
      throw err;
    });
};


