import React from "react";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";
import urls from "config/urls";

const CustomCookieConsent = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Nõustun"
            buttonClasses="btn btn-secondary"
            containerClasses="align-items-center"
            cookieName="cookie_consent"
            style={{ background: "#46a72a" }}
            buttonStyle={{ color: "white", background: "#3DBDB4", fontSize: "13px" }}
            expires={30}
            onAccept={() => console.log("User accepted cookies.")}
            overlay={false}
        >
            <div className="regular-14 color-white">
                See veebileht kasutab parima võimaliku kogemuse kasutamiseks küpsiseid.{" "}
                <span className="regular-12 color-white">
                    Jätkates kodulehe kasutamist, nõustute küpsistega vastavalt küpsiste <Link to={urls.COOKIE_POLICY}>kasutuspoliitikale</Link>.
                </span>
            </div>
        </CookieConsent>
    );
}

export default CustomCookieConsent;
