import * as actionTypes from 'redux/actions/actionTypes';
import {updateObject} from 'redux/utility';

// TODO remove reportFormat functionality as it is not used anymore.

const initialFilters = {
    fromDate: null,
    toDate: null,
}

const HTML_VALUE = "HTML";
const EXCEL_VALUE = "EXCEL";
const formatOptions = [
    {value: HTML_VALUE, label: "HTML"}, 
    {value: EXCEL_VALUE, label: "EXCEL"},
];


const initialFormat = formatOptions[0];
const initialState = {
    filters: initialFilters,
    massList: null,
    massListFromDate: null,
    massListToDate: null,
    massListLoading: false,
    exportFile: null,
    loading: false,
    exportLoading: false,
    error: null,
    formatOptions,
    format: initialFormat,
    htmlValue: HTML_VALUE,
    excelValue: EXCEL_VALUE,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
}

const massReportUpdateOutputFormat = (state, format) => {
    return updateObject(state, {
        format,
    });
};


const massReportSearchStart = (state) => {
    return updateObject(state, {
        loading: true,
        massListLoading: true,
        error: false,
        massList: null,
        massListFromDate: null,
        massListToDate: null,
    });
};

const massReportSearchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        massListLoading: false,
        error: false,
        massList: action.massList,
        massListFromDate: action.fromDate,
        massListToDate: action.toDate,
    });
};

const massReportSearchFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false,
        massListLoading: false,
    });
};

const massReportSearchFilterUpdate = (state, action)  => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        }
    });
};

const massReportExportStart = (state) => {
    return updateObject(state, {
        loading: true,
        exportLoading: true,
        error: false,
    });
};

const massReportExportSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        error: false,
        exportFile: action.exportFile,
    });
};

const massReportExportFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        error: action.err,
    });
};

export const massReport = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.MASS_REPORT_UPDATE_FORMAT:
            return massReportUpdateOutputFormat(state, action.format);
        // SEARCH
        case actionTypes.MASS_REPORT_SEARCH_START:
            return massReportSearchStart(state, action);
        case actionTypes.MASS_REPORT_SEARCH_SUCCESS:
            return massReportSearchSuccess(state, action);
        case actionTypes.MASS_REPORT_SEARCH_FAIL:
            return massReportSearchFail(state, action);
        // UPDATE FILTER
        case actionTypes.MASS_REPORT_SEARCH_FILTER_UPDATE:
            return massReportSearchFilterUpdate(state, action);
        // EXPORT
        case actionTypes.MASS_REPORT_EXPORT_START:
            return massReportExportStart(state, action);
        case actionTypes.MASS_REPORT_EXPORT_SUCCESS:
            return massReportExportSuccess(state, action);
        case actionTypes.MASS_REPORT_EXPORT_FAIL:
            return massReportExportFail(state, action);
        
        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};