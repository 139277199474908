import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";

import { alertsClearUnincludedGroups } from "redux/actions/alerts";

import { updateActiveAlertGroups } from "util/Alerts";
import urls from "../../config/urls";
import { logout } from "../../util/Auth";
import { isClientMode, isPartnerMode, isAdminMode } from "util/Access";

import { ReactComponent as Logo } from "../../assets/icons/logo.svg";
import { ReactComponent as InactiveOverviewLogo } from "../../assets/icons/sidebar/inactive/1. overview.svg";
import { ReactComponent as InactiveTicketsLogo } from "../../assets/icons/sidebar/inactive/2. tickets.svg";
import { ReactComponent as InactiveIdeasLogo } from "../../assets/icons/sidebar/inactive/3. ideas.svg";
import { ReactComponent as InactiveContactsLogo } from "../../assets/icons/sidebar/inactive/4. contacts.svg";
import { ReactComponent as InactiveAgentsLogo } from "../../assets/icons/sidebar/inactive/5. agents.svg";
import { ReactComponent as InactiveArticlesLogo } from "../../assets/icons/sidebar/inactive/6. articles.svg";
import { ReactComponent as InactiveSubscriptionLogo } from "../../assets/icons/sidebar/inactive/a_2. subscription.svg";
import { ReactComponent as InactiveSettingsLogo } from "assets/icons/sidebar/inactive/a_1. settings.svg";
import { ReactComponent as LogOutLogo } from "assets/icons/logout.svg";

import { Nav, NavItem } from "reactstrap";
import TopNavbar from "../Navbar/Navbar";
import Alerts from "components/Alerts/Alerts";
import Sidebar from "react-sidebar";
import Footer from "components/Layout/Footer/Footer";
import UserModeSelection from "components/UserModeSelection/UserModeSelection";
import LanguageMode from "components/Navbar/LanguageMode";
import CookieConsent from "components/Misc/CookieConsent";

import { withTranslation } from "react-i18next";

import "./Layout.scss";

const minimizedSidebarListener = window.matchMedia(`(min-width: 1200px)`);
const mobileSidebarListener = window.matchMedia(`(min-width: 992px)`);

class Layout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sidebarDocked: minimizedSidebarListener.matches,
            sidebarOpen: false,
            minimizedSidebar: false,
        };

        this.mediaQueryChanged = this.mediaQueryChanged.bind(this);
        this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
    }

    componentDidMount() {
        updateActiveAlertGroups(this.props.location.pathname);
    }

    componentWillMount() {
        minimizedSidebarListener.addListener(this.mediaQueryChanged);
        mobileSidebarListener.addListener(this.mediaQueryChanged);
    }

    componentWillUnmount() {
        minimizedSidebarListener.removeListener(this.mediaQueryChanged);
        mobileSidebarListener.removeListener(this.mediaQueryChanged);
    }

    onSetSidebarOpen(open) {
        this.setState({ sidebarOpen: open });
    }

    mediaQueryChanged() {
        this.setState({
            sidebarOpen: false,
            minimizedSidebar: !mobileSidebarListener.matches
                ? false
                : !this.state.minimizedSidebar,
            sidebarDocked: mobileSidebarListener.matches,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            updateActiveAlertGroups(this.props.location.pathname);
            this.props.alertsClearUnincludedGroups(
                this.props.location.pathname
            );
        }
    }

    toggleSidebar = () => {
        this.setState({
            minimizedSidebar: !mobileSidebarListener.matches
                ? false
                : !this.state.minimizedSidebar,
            sidebarOpen: !this.state.sidebarOpen,
        });
    };

    hideMenuOnClick = () => {
        if (this.state.sidebarDocked === false) {
            this.setState({ sidebarOpen: false });
        }
    };

    checkActive = (match) => {
        const { pathname } = this.props.location;
        if (!pathname) return false;
        return pathname === match || pathname.includes(match);
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <Sidebar
                    sidebar={
                        <>
                            <header
                                className={
                                    this.state.minimizedSidebar
                                        ? "p-0 justify-content-center"
                                        : null
                                }
                            >
                                <Logo />
                                {!this.state.minimizedSidebar ? (
                                    <span>
                                        {t("sidebar.title", "Pakendiregister")}
                                    </span>
                                ) : null}
                            </header>

                            {this.renderLinks()}

                            <Nav className="h-auto d-block d-md-none" navbar>
                                <NavItem className="sidebar-link pointer border-top">
                                    <UserModeSelection direction="right" maxWidth="214px" />
                                </NavItem>
                                {isClientMode() && (
                                    <NavItem className="sidebar-link border-top sidebar-language-item">
                                        <LanguageMode />
                                    </NavItem>
                                )}
                                <NavItem className="border-top">
                                    <NavLink
                                        to={urls.DOMAIN}
                                        onClick={logout}
                                        className="pointer sidebar-logout"
                                    >
                                        <LogOutLogo />
                                        <span className="menu-item">
                                            {t("navbar.logout", "Logi välja")}
                                        </span>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </>
                    }
                    open={this.state.sidebarOpen} // open property controls if sidebar is visible or not
                    docked={this.state.sidebarDocked} // docked property controls if sidebar is docked to page or on top of the page
                    onSetOpen={this.onSetSidebarOpen}
                    shadow={false}
                    sidebarClassName={
                        this.state.minimizedSidebar
                            ? "sidebar minimized-sidebar"
                            : "sidebar"
                    }
                    styles={{
                        sidebar: { zIndex: 101 },
                        overlay: { zIndex: 100 },
                        dragHandle: { zIndex: 100 },
                    }}
                >
                    <div id="content">
                        <TopNavbar
                            onSidebarToggle={this.toggleSidebar}
                            menuOpen={this.state.sidebarOpen}
                            routes={this.props.routes}
                        />
                        <div className="container-fluid content pb-5">
                            <Alerts filterGroups={this.props.activeGroups} />

                            {isClientMode() &&
                                this.props.user &&
                                this.props.user.user_type &&
                                this.props.user.user_type.is_verified &&
                                <>
                                    {this.props.children}
                                    <Footer />
                                </>

                            }

                            {isPartnerMode() &&
                                this.props.user &&
                                this.props.user.partner_user_type &&
                                this.props.user.partner_user_type.is_verified &&
                                this.props.children}

                            {isAdminMode() && this.props.children}
                        </div>
                    </div>
                </Sidebar>
                <CookieConsent />
            </div>
        );
    }

    renderLinks() {
        if (isAdminMode()) {
            return this.renderAdminLinks();
        }
        if (isPartnerMode()) {
            return this.renderPartnerLinks();
        }
        if (isClientMode()) {
            return this.renderClientLinks();
        }

        return this.renderClientLinks();
    }

    renderClientLinks() {
        const { t } = this.props;
        return (
            <Nav className="nav-sidebar" navbar>
                <NavItem>
                    <NavLink
                        exact
                        to={urls.DASHBOARD}
                        onClick={this.hideMenuOnClick}
                        className="sidebar-link"
                    >
                        <InactiveOverviewLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t("sidebar.dashboard", "Avaleht")}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to={urls.PACKAGES}
                        onClick={this.hideMenuOnClick}
                        className="sidebar-link"
                    >
                        <InactiveTicketsLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t("sidebar.packages", "Pakendid")}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to={urls.REPORTS_SALES}
                        onClick={this.hideMenuOnClick}
                        isActive={() => this.checkActive(urls.REPORTS)}
                        className="sidebar-link"
                    >
                        <InactiveIdeasLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t("sidebar.reports")}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to={urls.INVOICES}
                        onClick={this.hideMenuOnClick}
                        className="sidebar-link"
                    >
                        <InactiveSubscriptionLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t("sidebar.invoices", "Arved")}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to={urls.PROFILE_EDIT}
                        onClick={this.hideMenuOnClick}
                        isActive={() => this.checkActive(urls.PROFILE)}
                        className="sidebar-link"
                    >
                        <InactiveAgentsLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t("sidebar.profile", "Profiil")}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
            </Nav>
        );
    }

    renderPartnerLinks() {
        const { t } = this.props;
        return (
            <Nav className="nav-sidebar" navbar>
                <NavItem>
                    <NavLink
                        to={urls.PARTNER_PACKAGES_IN_TESTING}
                        onClick={this.hideMenuOnClick}
                        isActive={() => this.checkActive(urls.PARTNER_PACKAGES)}
                        className="sidebar-link"
                    >
                        <InactiveIdeasLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t(
                                    "sidebar.packagesTesting",
                                    "Pakendite testimine"
                                )}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        to={urls.PROFILE_EDIT}
                        onClick={this.hideMenuOnClick}
                        isActive={() => this.checkActive(urls.PROFILE)}
                        className="sidebar-link"
                    >
                        <InactiveAgentsLogo />
                        {this.state.minimizedSidebar ? null : (
                            <span className="menu-item">
                                {t("sidebar.profile", "Profiil")}
                            </span>
                        )}
                    </NavLink>
                </NavItem>
            </Nav>
        );
    }

    renderAdminLinks() {
        const { t } = this.props;
        const adminLinks = [
            {
                toUrl: urls.EPP_REGISTRY_APPLICATIONS,
                isActiveUrl: urls.EPP_REGISTRY,
                text: t("sidebar.packagesRegistry", "Pakendite testimine"),
                logo: <InactiveTicketsLogo />,
            },
            {
                toUrl: urls.EPP_REPORTS_SALES,
                isActiveUrl: urls.EPP_REPORTS,
                text: t("sidebar.reports"),
                logo: <InactiveIdeasLogo />,
            },
            {
                toUrl: urls.EPP_DEBTS,
                isActiveUrl: urls.EPP_DEBTS,
                text: t("sidebar.debts", "Võlgnevused"),
                logo: <InactiveArticlesLogo />,
            },
            {
                toUrl: urls.EPP_USERS_CLIENTS,
                isActiveUrl: urls.EPP_USERS,
                text: t("sidebar.users", "Kasutajad"),
                logo: <InactiveContactsLogo />,
            },
            {
                toUrl: urls.EPP_PROFILE,
                isActiveUrl: urls.EPP_PROFILE,
                text: t("sidebar.profile", "Profiil"),
                logo: <InactiveAgentsLogo />,
            },
            {
                toUrl: urls.EPP_SETTINGS_CATEGORIES,
                isActiveUrl: urls.EPP_SETTINGS,
                text: t("sidebar.settings", "Seaded"),
                logo: <InactiveSettingsLogo />,
            },
        ];
        return (
            <Nav className="nav-sidebar" navbar>
                {adminLinks.map(({ toUrl, isActiveUrl, text, logo }, index) =>
                    this.renderNavItem(toUrl, isActiveUrl, text, logo)
                )}
            </Nav>
        );
    }

    renderNavItem(toUrl, isActiveUrl, text, logo) {
        return (
            <NavItem key={toUrl + text}>
                <NavLink
                    to={toUrl}
                    onClick={this.hideMenuOnClick}
                    isActive={() => this.checkActive(isActiveUrl)}
                    className="sidebar-link"
                >
                    {logo ? logo : <InactiveAgentsLogo />}
                    {this.state.minimizedSidebar ? null : (
                        <span className="menu-item">{text}</span>
                    )}
                </NavLink>
            </NavItem>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activeGroups: state.alerts.activeGroups,
        user: state.auth.user,
    };
};

export default withRouter(
    connect(mapStateToProps, { alertsClearUnincludedGroups })(
        withTranslation("common")(Layout)
    )
);
