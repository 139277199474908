import React from "react";
import PropTypes from "prop-types";

import { ReactComponent as DownloadIcon } from "assets/icons/download.svg";
import "./DocumentCell.scss";


class DocumentCell extends React.Component {
    static propTypes = {
        name: PropTypes.string.isRequired,
        url: PropTypes.string,
        onClick: PropTypes.func,
    };

    render() {
        const { name, url } = this.props;
        if (!name && !url) {
            return null;
        }
        else if (name && !url) {
            return (
                <span className="document-cell semibold-14">{name}</span>
            );
        }


        return (
            <div className="document-cell semibold-14">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className="document-cell__link pr-1"
                    href={url}
                    onClick={e => e.stopPropagation()}
                >
                    {name}
                    <DownloadIcon className="document-cell__download-icon" />
                </a>
            </div>
        );
    }
}

export default DocumentCell;
