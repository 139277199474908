import axios from "../../axios";
import store from "redux/store";

import { getServerErrorMessage } from "util/Errors";
import {
    ADMIN_ALL_CLIENTS,
    ADMIN_ALL_PARTNERS,
    ADMIN_CLIENT_USERS,
    ADMIN_REMOVE_USER,
    ADMIN_ALL_USERS,
    ADMIN_REMOVE_EPP_USER,
    ADMIN_REMOVE_PARTNER_USER,
} from "config/Api";

import {
    adminClientsFetchStart,
    adminClientsFetchSuccess,
    adminClientsFetchFail,
    adminPartnersFetchStart,
    adminPartnersFetchSuccess,
    adminPartnersFetchFail,
    adminEppUsersFetchStart,
    adminEppUsersFetchSuccess,
    adminEppUsersFetchFail,
} from "redux/actions/admin/users";

export const adminfetchAllClients = (fetchOnlyIfNull) => {
    if (!!fetchOnlyIfNull) {
        const state = store.getState();
        if (state.admin.users.clients !== null) {
            return;
        }
    }

    store.dispatch(adminClientsFetchStart());
    return axios
        .get(ADMIN_ALL_CLIENTS)
        .then((response) => {
            store.dispatch(adminClientsFetchSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminClientsFetchFail(errorMessage));
            throw Error(errorMessage);
        });
};

export const adminfetchAllPartners = (allPartners) => {
    if (allPartners && allPartners.length) {
        return;
    }
    store.dispatch(adminPartnersFetchStart());
    return axios
        .get(ADMIN_ALL_PARTNERS)
        .then((response) => {
            store.dispatch(adminPartnersFetchSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminPartnersFetchFail(errorMessage));
            throw Error(errorMessage);
        });
};

export const adminfetchAllUsers = () => {
    return axios
        .get(ADMIN_ALL_CLIENTS)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
};

export const adminfetchUsers = (
    fetchClients = null,
    fetchPartners = null,
    fetchEpp = null
) => {
    if (fetchClients) {
        store.dispatch(adminClientsFetchStart());
    }
    if (fetchPartners) {
        store.dispatch(adminPartnersFetchStart());
    }
    if (fetchEpp) {
        store.dispatch(adminEppUsersFetchStart());
    }
    return axios
        .get(ADMIN_ALL_USERS, {
            params: {
                clients: fetchClients,
                partners: fetchPartners,
                epp: fetchEpp,
            },
        })
        .then((response) => {
            if (fetchClients) {
                store.dispatch(adminClientsFetchSuccess(response.data));
            }
            if (fetchPartners) {
                store.dispatch(adminPartnersFetchSuccess(response.data));
            }
            if (fetchEpp) {
                store.dispatch(adminEppUsersFetchSuccess(response.data));
            }
            return response.data;
        })
        .catch((error) => {
            if (fetchClients) {
                store.dispatch(adminClientsFetchFail());
            }
            if (fetchPartners) {
                store.dispatch(adminPartnersFetchFail());
            }
            if (fetchEpp) {
                store.dispatch(adminEppUsersFetchFail());
            }
        });
};

export const adminfetchClientUsers = (clientId) => {
    const endpoint = ADMIN_CLIENT_USERS.replace("<client_id>", clientId);

    return axios
        .get(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
};

export const adminRemoveClientUser = (userTypeId) => {
    const endpoint = ADMIN_REMOVE_USER.replace("<user_type_id>", userTypeId);

    return axios
        .delete(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
};

export const adminRemoveEppUser = (userTypeId) => {
    const endpoint = ADMIN_REMOVE_EPP_USER.replace(
        "<user_type_id>",
        userTypeId
    );

    return axios
        .delete(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
};

export const adminRemovePartnerUser = (userTypeId) => {
    const endpoint = ADMIN_REMOVE_PARTNER_USER.replace(
        "<user_type_id>",
        userTypeId
    );

    return axios
        .delete(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
};
