import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";

import urls from "config/urls";
import { fetchRepairSalesReport, deleteRepairSalesReport, SaleStatus } from "util/SalesReports";
import { adminFetchRepairSalesReport } from "util/admin/SalesReports";
import { isAdminMode } from "util/Access";

import {
    parseSalesReportPeriodDate,
    salesReportPeriodListFormat
} from "util/DateTime";
import { addNewLocalSuccessAlert, addNewLocalErrorAlert, clearLocalAlerts } from "util/Alerts";

import "views/Reports/RepairSalesReport.scss";

import LeftText from "components/Misc/LeftText";
import DeleteText from "components/Misc/DeleteText";

import SalesReportDetails from "components/Reports/Sales/Details/SalesReportDetails";
import Alerts from "components/Alerts/Alerts";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import { withTranslation, Trans } from "react-i18next";


const deleteRepairAlertGroup = "delete-repair-modal";
class RepairSalesReport extends React.Component {
    state = {
        isInfoModalConfirmed: false,
        isInfoModalCancelled: false,
        infoModalOpen: false,

        deleteModalOpen: false,
    }

    componentDidMount() {
        this.fetchData();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.isInfoModalConfirmed !== this.state.isInfoModalConfirmed ||
            prevProps.is_repair !== this.props.is_repair ||
            prevProps.submitted_at !== this.props.submitted_at) {
            this.setState({
                infoModalOpen: this.isInfoModalOpen(),
            });
        }
    }

    render() {
        const { t } = this.props;
        const adminMode = isAdminMode();
        return (
            <div className="repair-sales-report-view">
                <Row className="justify-content-between">
                    <Col xs="auto">
                        <LeftText
                            onClick={() => this.props.history.push(this.getReturnRoute())}
                            text={t("buttons.back", "Tagasi")}
                        />
                    </Col>
                    {this.isDeletable(adminMode) &&
                        <Col className="col-auto">
                            <DeleteText
                                text={t("reports.deleteRepairReportButton", "Kustuta aruanne")}
                                onClick={this.onOpenDeleteModal}
                                disabled={this.props.deleteRepairReportLoading}
                            />
                        </Col>
                    }
                </Row>
                <Row>
                    <Col>
                        <SalesReportDetails
                            adminMode={adminMode}
                            fetchData={this.fetchData}
                        />
                    </Col>
                </Row>

                {!adminMode && !this.state.isInfoModalConfirmed && this.renderInfoModal()}
                {this.renderDeleteModal()}
            </div>
        );
    }

    renderInfoModal() {
        const { t } = this.props;
        return (
            <Modal
                isOpen={this.state.infoModalOpen}
                onToggle={this.onCancelInfo}
                backdrop="static"
                className="repair-info-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">{t("reports.repairReportInfoModal.title", "Parandusaruanne")}</span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onConfirmInfo}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <p className="regular-14">
                                {t("reports.repairReportInfoModal.content", "Parandusaruandes esitatakse ainult muudatused, mida tehakse kinnitatud aruandele. Kui müügiaruandes kinnitati pakendi müügikogus 1000 tk, tegelik turule lisatud kogus oli 1250 tk, siis paranduses kajastatakse kogus 250 tk.")}
                            </p>
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                block
                                onClick={this.onConfirmInfo}
                            >
                                {t("reports.repairReportInfoModal.confirmButton", "OK")}
                            </Button>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                outline
                                block
                                onClick={this.onCancelInfo}
                            >
                                {t("buttons.cancel", "Tühista")}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

    renderDeleteModal() {
        const { t } = this.props;
        let formattedPeriod = parseSalesReportPeriodDate(this.props.details.period).format(salesReportPeriodListFormat);

        return (
            <Modal
                isOpen={this.state.deleteModalOpen}
                onToggle={this.onCancelDelete}
                backdrop="static"
                className="repair-delete-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">{t("reports.repairReportDeleteModal.title", "Müügiaruande kustutamine")}</span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onCancelDelete}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts alerts={this.props.alertsList} hideGlobal={true} filterGroups={[deleteRepairAlertGroup]} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <p className="regular-14">
                                <Trans i18nKey="reports.repairReportDeleteModal.content">Kas soovite kustutada parandatud müügiaruande <b>{{ formattedPeriod }}</b>?</Trans>
                            </p>
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                outline
                                block
                                onClick={this.onCancelDelete}
                                disabled={this.props.deleteRepairReportLoading}
                            >
                                {t("buttons.cancel", "Tühista")}
                            </Button>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                block
                                onClick={this.onDelete}
                                disabled={this.props.deleteRepairReportLoading}
                            >
                                {this.props.deleteRepairReportLoading ? <LoadingAnimation /> : t("reports.repairReportDeleteModal.confirmButton", "Kustuta müügiaruanne")}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

    getReturnRoute = () => {
        const locationState = this.props.location.state;
        if (locationState && locationState.previousViewRoute) {
            return locationState.previousViewRoute;
        }
        return isAdminMode() ? urls.EPP_REPORTS_SALES : urls.REPORTS_SALES;
    }

    isDeletable = (adminMode) => {
        return !adminMode && this.props.details.is_repair && this.props.details.id && [SaleStatus.UNSUBMITTED, SaleStatus.REJECTED].includes(this.props.details.status.value);
    }

    isInfoModalOpen = () => {
        return !this.state.isInfoModalConfirmed &&
            !this.state.isInfoModalCancelled &&
            this.props.is_repair &&
            (!this.props.submitted_at || this.props.isRejected);
    }

    onConfirmInfo = () => {
        this.setState({
            isInfoModalConfirmed: true,
        });
    }

    onCancelInfo = () => {
        this.setState({
            isInfoModalCancelled: true,
        },
            () => this.redirectToSalesReportsList());
    }

    onOpenDeleteModal = () => {
        this.setState({
            deleteModalOpen: true,
        })
    }

    onDelete = () => {
        const { t } = this.props;
        if (this.props.deleteRepairReportLoading) {
            return;
        }
        const id = this.props.details.id;
        clearLocalAlerts();
        deleteRepairSalesReport(id).then(result => {
            if (result.isSuccess) {
                addNewLocalSuccessAlert(
                    t("reports.alerts.deleteRepairReportSuccess", "Müügiaruande kustutamine õnnestus."),
                    urls.REPORTS_SALES,
                    false
                );
                document.body.classList.remove("modal-open");
                this.redirectToSalesReportsList();
            } else {
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.alerts.deleteRepairReportFailed">Müügiaruande kustutamine ebaõnnestus: {{ errorMessage: result.errorMessage }}</Trans>,
                    deleteRepairAlertGroup,
                    false
                );
            }
        })
    }

    onCancelDelete = () => {
        if (this.props.deleteRepairReportLoading) {
            return;
        }
        this.setState({
            deleteModalOpen: false,
        });
        clearLocalAlerts();
    }

    redirectToSalesReportsList = () => {
        this.props.history.push(urls.REPORTS_SALES);
    }

    fetchData = () => {
        const id = this.props.match.params.id;

        const fetchEndpoint = isAdminMode() ? adminFetchRepairSalesReport : fetchRepairSalesReport;
        fetchEndpoint(id).then(
            result => {
                if (!result.isSuccess) {
                    addNewLocalErrorAlert(
                        <Trans i18nKey="reports.alerts.fetchRepairSalesReportFailed">Müügiaruande laadimine ebaõnnestus: {{ errorMessage: result.errorMessage }}</Trans>,
                        this.props.location.pathname,
                        false
                    );
                }
            }
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.salesReport.details,
        deleteRepairReportLoading: state.salesReport.deleteRepairReportLoading,
        is_repair: state.salesReport.details ? state.salesReport.details.is_repair : null,
        submitted_at: state.salesReport.details ? state.salesReport.details.submitted_at : null,
        isRejected: state.salesReport.details && state.salesReport.details.status && state.salesReport.details.status.value === SaleStatus.REJECTED,
        alertsList: state.alerts.alertsList,
    }
};

export default withRouter(connect(mapStateToProps)(withTranslation()(RepairSalesReport)));
