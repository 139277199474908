import React from "react";

import Invoices from "components/Admin/Debts/Invoices/Invoices";

class AdminInvoicesView extends React.Component {
  render() {
    return (
        <Invoices />
    );
  }
}

export default AdminInvoicesView;