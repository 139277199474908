import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Row,
    Col,
} from "reactstrap";

import { NavLink } from "react-router-dom";

import WrappedLoader from "components/Loader/WrappedLoader";
import { ReactComponent as MoreVerticalIcon } from "assets/icons/more-vertical.svg";
import AddUserModal from "components/Profile/Users/AddUserModal/AddUserModal";
import UserDetailsModal from "components/Profile/Users/UserDetailsModal/UserDetailsModal";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";

import { adminfetchUsers, adminRemovePartnerUser } from "util/admin/Users";
import { adminPartnersUserUpdate } from "redux/actions/admin/users";
import {
    clearLocalAlerts,
    addNewLocalInfoAlert,
    addNewLocalErrorAlert,
} from "util/Alerts";
import { isAdminMode } from "util/Access";

import { sendPasswordResetLink } from "util/Auth";
import moment from "moment";
import urls from "config/urls";
import { DATE_FORMAT } from "util/DateTime";
import StatusCell, {
    statusTypes,
} from "components/Table/Cell/Status/StatusCell";

class Partners extends React.Component {
    state = {
        isAddUserModalOpen: false,
        isUserDetailsModalOpen: false,
        isConfirmationModalOpen: false,
        selectedUser: {},
        user: null,
        clientId: this.props.match.params.id,
    };

    componentDidMount() {
        adminfetchUsers(null, true, null);
    }

    toggleConfirmationModal = (user) => {
        this.setState({
            user: user,
            isConfirmationModalOpen: !this.state.isConfirmationModalOpen,
        });
    };

    static NoDataIndication(props) {
        let content = "Ei leitud kasutajaid.";
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    render() {
        return (
            <>
                <div className="navigation-buttons pb-4">
                    <NavLink
                        to={urls.EPP_USERS_CLIENTS}
                        className="btn btn-default mr-1"
                    >
                        Kliendid
                    </NavLink>
                    <NavLink
                        to={urls.EPP_USERS_PARTNERS}
                        className="btn btn-default ml-1 mr-1"
                    >
                        Partnerid
                    </NavLink>
                    <NavLink
                        to={urls.EPP_USERS_EPP}
                        className="btn btn-default ml-1 mr-1"
                    >
                        EPP
                    </NavLink>
                </div>
                <Card className="users-card">
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col>
                                <span className="heading">Kõik kasutajad</span>
                            </Col>
                            {this.showAddUserButton() && (
                                <Col className="float-right text-right col-auto">
                                    <Button
                                        color="success"
                                        onClick={this.toggleAddUserModal}
                                    >
                                        Lisa kasutaja
                                    </Button>
                                </Col>
                            )}
                        </Row>
                    </CardHeader>
                    <CardBody className="p-0">{this.renderTable()}</CardBody>
                    <ConfirmationModal
                        isOpen={this.state.isConfirmationModalOpen}
                        onToggle={() =>
                            this.toggleConfirmationModal(this.state.user)
                        }
                        onConfirm={() => this.onRemoveUser(this.state.user)}
                        onCancel={() =>
                            this.toggleConfirmationModal(this.state.user)
                        }
                        confirmButtonText={"Jah, kustuta kasutaja"}
                    />
                    <AddUserModal
                        userRole="PARTNER"
                        isOpen={this.state.isAddUserModalOpen}
                        toggle={this.toggleAddUserModal}
                    />
                    <UserDetailsModal
                        userRole="PARTNER"
                        user={this.state.selectedUser}
                        isOpen={this.state.isUserDetailsModalOpen}
                        toggle={this.toggleUserDetailsModal}
                        onSave={(updatedUser) => this.props.adminPartnersUserUpdate(updatedUser)}
                    />
                </Card>
            </>
        );
    }

    renderTable() {
        if (this.props.loadingPartners) {
            return <WrappedLoader />;
        }

        const StyledDropdownItem = (props) => (
            <DropdownItem {...props} className="semibold-14 text-muted">
                {props.children}
            </DropdownItem>
        );

        const dropdownFormatter = (user) => {
            return (
                <UncontrolledButtonDropdown
                    direction="left"
                    className="sales-actions"
                    onClick={(e) => e.stopPropagation()}
                >
                    <DropdownToggle tag="div">
                        <Button
                            color="link"
                            className="icon-default sales-actions-icon-button"
                        >
                            <MoreVerticalIcon />
                        </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                        <StyledDropdownItem
                            onClick={() => this.onOpenUserDetails(user)}
                        >
                            Muuda andmeid
                        </StyledDropdownItem>
                        <StyledDropdownItem
                            onClick={() => this.onSendPasswordReset(user)}
                        >
                            Saada uus parool
                        </StyledDropdownItem>
                        <StyledDropdownItem
                            onClick={() => this.toggleConfirmationModal(user)}
                        >
                            Eemalda kasutaja
                        </StyledDropdownItem>
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            );
        };

        const columns = [
            {
                dataField: "last_name",
                text: "Nimi",
                sort: true,
                classes: "semibold-14",
                formatter: (last_name, user) =>
                    `${user.name} ${user.last_name}`,
            },
            {
                dataField: "phone",
                text: "Telefon",
                sort: true,
            },
            {
                dataField: "email",
                text: "Email",
                sort: true,
            },
            {
                dataField: "personal_code",
                text: "Isikukood",
                sort: true,
            },
            {
                dataField: "date_joined",
                text: "Liitus",
                formatter: (date) => moment(date, "DD.MM.YYYY").format(DATE_FORMAT),
                sort: true,
            },
            {
                dataField: "is_verified",
                text: "Kinnitatud",
                sort: true,
                formatter: (is_verified) => (
                    <StatusCell
                        status={is_verified ? "Kinnitatud" : "Kinnitamata"}
                        type={
                            is_verified
                                ? statusTypes.SUCCESS
                                : statusTypes.DEFAULT
                        }
                    />
                ),
            },
            {
                dataField: "",
                text: "",
                align: "right",
                formatter: (field, row) => dropdownFormatter(row),
            },
        ];

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onOpenUserDetails(row);
            },
        };

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="table-layout-auto no-top-border"
                wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                keyField="user_id"
                data={this.props.partners}
                columns={columns}
                rowEvents={rowEvents}
                rowClasses={rowClasses}
                noDataIndication={
                    <Partners.NoDataIndication
                        loading={this.props.loadingPartners}
                    />
                }
            />
        );
    }

    showAddUserButton = () => {
        return isAdminMode();
    };

    showRemoveUserButton = () => {
        return isAdminMode();
    };

    toggleAddUserModal = () => {
        this.setState((prevState) => {
            return {
                isAddUserModalOpen: !prevState.isAddUserModalOpen,
            };
        });
    };

    toggleUserDetailsModal = () => {
        this.setState((prevState) => {
            return {
                isUserDetailsModalOpen: !prevState.isUserDetailsModalOpen,
            };
        });
    };

    onOpenUserDetails = (user) => {
        clearLocalAlerts();
        this.setState({
            isUserDetailsModalOpen: true,
            selectedUser: user,
        });
    };

    onSendPasswordReset = (user) => {
        sendPasswordResetLink(user.email).then(() => {
            addNewLocalInfoAlert(
                "Parooli taastamise link kasutajale edastatud.",
                this.props.location.pathname
            );
        });
        clearLocalAlerts();
    };

    onRemoveUser = (user) => {
        clearLocalAlerts();
        adminRemovePartnerUser(user.id)
            .then((resp) => {
                this.toggleConfirmationModal(this.state.user);
                adminfetchUsers(null, true, null);
                addNewLocalInfoAlert(
                    "Kasutaja eemaldatud.",
                    this.props.location.pathname
                );
            })
            .catch((error) => {
                addNewLocalErrorAlert(
                    error.message,
                    this.props.location.pathname
                );
            });
    };
}

const mapStateToProps = (state) => {
    return {
        partners: state.admin.users.partners,
        loadingPartners: state.admin.users.loadingPartners,
    };
};

const mapActionsToProps = {
    adminPartnersUserUpdate
}

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Partners));
