import React from 'react';
import PropTypes from "prop-types";
import {Card, CardBody, Row, Col} from "reactstrap";

import {ReactComponent as InfoIcon} from 'assets/icons/info-default.svg';
import {ReactComponent as SuccessIcon} from 'assets/icons/success-default.svg';
import {ReactComponent as ErrorIcon} from 'assets/icons/alert-default.svg';

import {alertType, createAlertClassName} from "util/Alerts";

import "./Alert.scss";

const AlertIcon = props => {
    switch (props.alertType) {
        case alertType.SUCCESS_ICON:
            return <SuccessIcon className={props.className}/>; 
        case alertType.ERROR_ICON:
            return <ErrorIcon className={props.className} />;
        default:
            return <InfoIcon className={props.className} />;
    }
}

class Alert extends React.Component {
    static propTypes = {
        className: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        content: PropTypes.any,
    }

    render() {
        return (
            <>
                {/* eslint-disable-next-line react/jsx-no-duplicate-props */}
                <Card className={`alert-card ${createAlertClassName()} ${this.props.className}`}>
                    <CardBody className="alert-body d-flex align-items-center">
                        <Row className="align-items-center w-100">
                            <Col className="col-auto alert-icon">
                                <AlertIcon alertType={this.props.type} />
                            </Col>
                            <Col className="alert-content">
                                {this.props.content}
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </>
        );
    }
}

export default Alert;