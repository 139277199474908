import * as actionTypes from 'redux/actions/actionTypes';

export const salesSearchFilterUpdate = (key, value) => {
    return {
        type: actionTypes.SALES_SEARCH_FILTER_UPDATE,
        key: key,
        value: value,
    }
}

export const salesClearFilters = () => {
    return {
        type: actionTypes.SALES_CLEAR_FILTERS,
    }
}

export const salesFetchStart = () => {
    return {
        type: actionTypes.SALES_FETCH_START
    }
};

export const salesFetchSuccess = (salesList, fromDate=null, toDate=null) => {
    return {
        type: actionTypes.SALES_FETCH_SUCCESS,
        salesList,fromDate, toDate,
    }
};

export const salesFetchFail = (err) => {
    return {
        type: actionTypes.SALES_FETCH_FAIL,
        error: err
    }
};

export const salesCreateRepairStart = () => {
    return {
        type: actionTypes.SALES_CREATE_REPAIR_START
    }
};

export const salesCreateRepairSuccess = () => {
    return {
        type: actionTypes.SALES_CREATE_REPAIR_SUCCESS,
    }
};

export const salesCreateRepairFail = (err) => {
    return {
        type: actionTypes.SALES_CREATE_REPAIR_FAIL,
        error: err
    }
};