import React, { Component } from "react";
import { fetchAdminSettingsCategories } from "util/admin/Settings";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import WrappedLoader from "components/Loader/WrappedLoader";

export class Categories extends Component {
    componentDidMount() {
        if (this.props.categories.length === 0) {
            fetchAdminSettingsCategories();
        }
    }

    static NoDataIndication(props) {
        let content = "Ei leitud kategooriad.";
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    render() {
        const columns = [
            {
                dataField: "value",
                text: "Tähis",
                sort: true,
                classes: "semibold-14",
            },
            {
                dataField: "description",
                text: "Kirjeldus",
                sort: true,
            },
        ];

        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <span className="heading">
                                Pakendite kategooriad
                            </span>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0">
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="table-layout-auto no-top-border"
                        wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                        keyField="id"
                        data={this.props.categories}
                        columns={columns}
                        noDataIndication={
                            <Categories.NoDataIndication
                                loading={this.props.loading}
                            />
                        }
                    />
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        categories: state.admin.settings.categories,
        loading: state.admin.settings.loading,
    };
};

export default connect(mapStateToProps)(Categories);
