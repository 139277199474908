import React, { Component } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";

import CustomLayout from "./components/Layout/Layout";
import PublicLayout from "./components/Layout/PublicLayout";

import "assets/style.scss";

// urls
import urls, { getDefaultUrl } from "config/urls";
// routes
import client_routes from "./client_routes";
import partner_routes from "./partner_routes";
import admin_routes from "./admin_routes";
import public_routes from "./public_routes";

import { connect } from "react-redux";

import { FadeLoader } from "react-spinners";

import {
    isAuthenticatedWithUser,
    isAuthenticatedWithoutUser,
    isClientMode,
    isPartnerMode,
    isAdminMode,
    getUser,
} from "util/Access";
import { checkAuthState, resetStore } from "./redux/actions/auth";
import { showMissingContractAlert, showNotVerifiedAlert } from "util/User";

import { withTranslation } from "react-i18next";
import i18n from "i18next";
import moment from "moment";
import { languageTypes } from "util/General";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import et from "date-fns/locale/et";
import en from "date-fns/locale/en-GB";
import axios from "./axios";

class App extends Component {
    componentDidMount() {
        this.props.resetStore();
        this.props.checkAuthState();
        this.updateLocalLanguage(this.props.language);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.user !== this.props.user && getUser()) {
            showMissingContractAlert();
            showNotVerifiedAlert();
        }
        if (this.props.language && this.props.language !== prevProps.language) {
            this.updateLocalLanguage(this.props.language);
        }
    }

    updateLocalLanguage = (newLanguage) => {
        // change frontend language
        i18n.changeLanguage(newLanguage);
        // locale language
        moment.locale(newLanguage);
        registerLocale(newLanguage, newLanguage === languageTypes.EN ? en : et);
        setDefaultLocale(newLanguage);
        axios.defaults.headers["Accept-Language"] = newLanguage;
    };

    render() {
        // A special wrapper for <Route> that knows how to
        // handle "sub"-routes by passing them in a `routes`
        // prop to the component it renders.
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={(props) => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        const routes = this.getRoutes(this.props.user);

        return (
            <BrowserRouter>
                {isAuthenticatedWithUser() ? (
                    <CustomLayout {...this.props} routes={routes}>
                        <Switch>
                            {routes.map((route, i) => {
                                return (
                                    <RouteWithSubRoutes key={i} {...route} />
                                );
                            })}
                            <Redirect
                                from={urls.ANY}
                                to={getDefaultUrl(this.props.user)}
                            />
                        </Switch>
                    </CustomLayout>
                ) : isAuthenticatedWithoutUser() ? (
                    <div
                        className="d-flex justify-content-center align-items-center min-vh-100"
                        style={{ backgroundColor: "#F7F8FC" }}
                    >
                        <FadeLoader
                            css=""
                            size={250}
                            color={"#46A72A"}
                            loading={true}
                        />
                    </div>
                ) : (
                    <PublicLayout {...this.props}>
                        <Switch>
                            {public_routes.map((route, i) => {
                                return (
                                    <RouteWithSubRoutes key={i} {...route} />
                                );
                            })}
                        </Switch>
                    </PublicLayout>
                )}
            </BrowserRouter>
        );
    }

    getRoutes() {
        if (isAdminMode()) {
            return admin_routes;
        }
        if (isPartnerMode()) {
            return partner_routes;
        } else if (isClientMode()) {
            return client_routes;
        }
        return [];
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        token: state.auth.token,
        language: state.language,
    };
};

export default connect(mapStateToProps, { checkAuthState, resetStore })(
    withTranslation()(App)
);
