import * as actionTypes from './actionTypes';


export const invoicesFetchStart = () => {
    return {
        type: actionTypes.INVOICES_FETCH_START
    }
};

export const invoicesFetchSuccess = (data) => {
    return {
        type: actionTypes.INVOICES_FETCH_SUCCESS,
        data,
    }
};

export const invoicesFetchFail = (err) => {
    return {
        type: actionTypes.INVOICES_FETCH_FAIL,
        error: err
    }
};

export const invoiceDataFetchStart = () => {
    return {
        type: actionTypes.INVOICE_DATA_FETCH_START,
    }
};

export const invoiceDataFetchSuccess = (invoice) => {
    return {
        type: actionTypes.INVOICE_DATA_FETCH_SUCCESS,
        invoice: invoice,
    }
};

export const invoiceDataFetchFail = (errorMessage) => {
    return {
        type: actionTypes.INVOICE_DATA_FETCH_FAIL,
        errorMessage,
    }
};
