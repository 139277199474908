import * as actionTypes from "redux/actions/actionTypes";

export const packagesTestingAllFetchStart = () => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_FETCH_START,
    };
};

export const packagesTestingAllFetchSuccess = (
    packagesList,
    filters,
    unfilteredPackagesList
) => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_FETCH_SUCCESS,
        packagesList,
        filters,
        unfilteredPackagesList,
    };
};

export const packagesTestingAllFetchFail = (error) => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_FETCH_FAIL,
        error,
    };
};

export const packagesTestingAllFilterUpdate = (key, value) => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const packagesTestingAllFilterClear = (format) => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_FILTER_CLEAR,
        format,
    };
};

export const packagesTestingAllSortingUpdate = (sorting) => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_SORTING_UPDATE,
        sorting,
    };
};

export const packagesTestingAllPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.PACKAGES_TESTING_ALL_PAGINATION_UPDATE,
        pagination,
    };
};
