import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import { withTranslation } from "react-i18next";

import { Card, CardBody, Form, Row, Col, Button } from "reactstrap";

// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// generic
import SmallLoader from "components/Loader/WrappedPulseLoader";
import Select from "components/Form/Select";
import {
    fetchAllClients,
} from "util/General";
import { isAdminMode } from "util/Access";

// module specific
import { actions } from "./redux";
import reportUtils from "./utils";
// css
import "./ListSearch.scss";


class AdditionalDepositReportsListSearch extends React.Component {
    static propTypes = {
        // manual
        onSearch: PropTypes.func.isRequired,
        // automatic
        loading: PropTypes.bool,
        filters: PropTypes.object.isRequired,
        isAdmin: PropTypes.bool,
        // actions
        updateFilters: PropTypes.func.isRequired,
        clearFilters: PropTypes.func.isRequired,
        // Taken from redux
        allClients: PropTypes.array,

    }

    componentDidMount() {
        fetchAllClients(this.props.allClients);
    }

    render() {
        const { t, filters, updateFilters, clearFilters, allClients, isAdmin } = this.props;
        return (
            <Form className="additional-deposit-reports-search__wrapper">
                <Row className="align-items-center mx-0 p-3">
                    {isAdmin ?
                        <Col sm="12" className="search-period-text bold-19 mb-2 px-2">
                            <span className="">{t("reports.additionalDeposit.listSearchHeader")}</span>
                        </Col>
                        :
                        <Col className="search-period-text bold-19 col-md-12 col-lg-auto mb-2 px-2">
                            <span className="">
                                {t("reports.filter.period")}
                            </span>
                        </Col>
                    }
                    {
                        isAdmin &&
                        <Col lg="" md="8" className="px-1">
                            <Select
                                options={allClients}
                                isClearable
                                isSearchable
                                value={filters.client}
                                placeholder="Ettevõte"
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={value => updateFilters('client', value)}
                                className="mb-2"
                            />
                        </Col>
                    }
                    {
                        isAdmin &&
                        <Col lg="" md="4" className="px-1">
                            <Select
                                options={reportUtils.filterNavStatusOptions}
                                value={filters.is_posted}
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                onChange={value => updateFilters('is_posted', value)}
                                className="mb-2"
                            />
                        </Col>
                    }
                    <Col lg="2" md="6" className="mb-2 px-1">
                        <DatePicker
                            placeholderText={t("reports.filter.from")}
                            selected={filters.fromDate}
                            onChange={value => {
                                let updateValue = value || filters.fromDate
                                if (updateValue instanceof Date) {
                                    updateValue = new Date(updateValue.getFullYear(), 0)
                                }
                                updateFilters("fromDate", updateValue)
                            }}
                            maxDate={filters.toDate || new Date()}
                            showYearPicker
                            dateFormat="yyyy"
                        />
                    </Col>
                    <Col lg="2" md="6" className="mb-2 px-1">
                        <DatePicker
                            placeholderText={t("reports.filter.to")}
                            selected={filters.toDate}
                            onChange={value => {
                                let updateValue = value || filters.toDate
                                if (updateValue instanceof Date) {
                                    updateValue = new Date(updateValue.getFullYear(), 11,)
                                }
                                updateFilters("toDate", updateValue)
                            }}
                            minDate={filters.fromDate}
                            maxDate={new Date()}
                            showYearPicker
                            dateFormat="yyyy"
                        />
                    </Col>
                    <Col lg="2" md="6" className="mb-2 px-1">
                        <Button
                            type="submit"
                            block
                            color="success"
                            className="search-button"
                            onClick={(e) => {
                                e.preventDefault();
                                this.onSearch();
                            }}
                            disabled={this.props.loading}
                        >
                            {this.props.loading ? <SmallLoader /> : t("buttons.search")}
                        </Button>
                    </Col>
                    <Col lg="2" md="6" className="ml-md-auto mb-2 px-1">
                        <Button
                            outline block
                            color="success"
                            className="search-button"
                            onClick={clearFilters}
                        >
                            {t("buttons.clearSearch")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    onSearch = () => {
        this.props.onSearch(this.props.filters);
    }

}

const mapStateToProps = state => {
    // Module state reducer
    const listState = state.additionalDepositReports;
    return {
        loading: listState.listLoading,
        filters: listState.listFilters,
        allClients: state.general.allClients,
        isAdmin: isAdminMode()
    }
};

const reduxActions = {
    updateFilters: actions.listUpdateFilters,
    clearFilters: actions.listClearFilters,
}

export default withRouter(connect(mapStateToProps, reduxActions)(withTranslation()(AdditionalDepositReportsListSearch)));
