import _ from "lodash";
import store from "redux/store";

import {
    alertsClearLocal, 
    alertsAddLocal, 
    alertsClearIncludedGroups,
    alertsUpdateActiveGroups,
    alertsAddGlobal,
    alertsClearAll,
} from "redux/actions/alerts";
import {alertsClearGlobal} from "../redux/actions/alerts";


const alertClassNamePrefix = "custom-alert";

export const alertContext = {
    GLOBAL: "GLOBAL",
    LOCAL: "LOCAL",
};

export const alertType = {
    DEFAULT: createAlertClassName("default"),
    DEFAULT_ICON: createAlertClassName("default-icon"),
    SUCCESS: createAlertClassName("success"),
    SUCCESS_ICON: createAlertClassName("success-icon"),
    INFO: createAlertClassName("info"),
    INFO_ICON: createAlertClassName("info-icon"),
    WARNING: createAlertClassName("warning"),
    WARNING_ICON: createAlertClassName("warning-icon"),
    ERROR: createAlertClassName("error"),
    ERROR_ICON: createAlertClassName("error-icon"),
};


export const createAlertObject = (context, type, group, content) => {
    return {
        id: _.uniqueId("alert-"),
        context,
        type,
        group,
        className: type,
        content,
    };
};

export function createAlertClassName(className) {
    if (!className || className === "") {
        return alertClassNamePrefix;
    }
    return `${alertClassNamePrefix}-${className}`;
}

export function isGlobal(alert) {
    return alert.context === alertContext.GLOBAL;
}

export function addNewGlobalAlert(type, message) {
    store.dispatch(alertsAddGlobal(type, message));
}

export function addNewLocalAlert(type, message, group) {
    store.dispatch(alertsAddLocal(type, message, group));
}

export function addNewGlobalSuccessAlert(message, showIcon=false) {
    addNewGlobalAlert(showIcon ? alertType.SUCCESS_ICON : alertType.SUCCESS, message);
}

export function addNewGlobalInfoAlert(message, showIcon=false) {
    addNewGlobalAlert(showIcon ? alertType.INFO_ICON : alertType.INFO, message);
}

export function addNewGlobalWarningAlert(message, showIcon=false) {
    addNewGlobalAlert(showIcon ? alertType.WARNING_ICON : alertType.WARNING, message);
}

export function addNewGlobalErrorAlert(message, showIcon=false) {
    addNewGlobalAlert(showIcon ? alertType.ERROR_ICON : alertType.ERROR, message);
}

export function addNewLocalSuccessAlert(message, group, showIcon=false) {
    addNewLocalAlert(showIcon ? alertType.SUCCESS_ICON : alertType.SUCCESS, message, group);
}

export function addNewLocalInfoAlert(message, group, showIcon=false) {
    addNewLocalAlert(showIcon ? alertType.INFO_ICON : alertType.INFO, message, group);
}

export function addNewLocalWarningAlert(message, group, showIcon=false) {
    addNewLocalAlert(showIcon ? alertType.WARNING_ICON : alertType.WARNING, message, group);
}

export function addNewLocalErrorAlert(message, group, showIcon=false) {
    addNewLocalAlert(showIcon ? alertType.ERROR_ICON : alertType.ERROR, message, group);
}

export function clearLocalAlerts() {
    store.dispatch(alertsClearLocal());
}

export function clearGlobalAlerts() {
    store.dispatch(alertsClearGlobal());
}

export function clearAllAlerts() {
    store.dispatch(alertsClearAll());
}

export function clearIncludedGroups(...groups) {
    store.dispatch(alertsClearIncludedGroups(...groups));
}

export function updateActiveAlertGroups(...groups) {
    store.dispatch(alertsUpdateActiveGroups(...groups));
}