import React from "react";
//import PropTypes from "prop-types";
import { Row, Col, Button, Modal, ModalBody } from "reactstrap";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";

import { Trans } from "react-i18next";

const ConfirmationModal = ({
    children, title, confirmButtonText, cancelButtonText,
    isOpen, onToggle, onCancel, onConfirm,
    isDisabled, isLoading, alertGroups,
}) => {
    return (
        <>
            <Modal
                isOpen={isOpen}
                toggle={onToggle}
                centered
                backdrop="static"
                className="confirmation-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">{title}</span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text={<Trans i18nKey="confirmationModal.closeButton">Sulge</Trans>}
                                onClick={onToggle}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts hideGlobal={true} filterGroups={alertGroups} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {children}
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                outline
                                block
                                onClick={onCancel}
                                disabled={isDisabled}
                            >
                                {cancelButtonText}
                            </Button>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                block
                                onClick={onConfirm}
                                disabled={isDisabled}
                            >
                                {isLoading ? <LoadingAnimation /> : confirmButtonText}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
}

ConfirmationModal.defaultProps = {
    children: <span className="regular-14"><Trans ns="common" i18nKey="confirmationModal.default.contentText">Kas oled kindel?</Trans></span>,
    title: <Trans i18nKey="confirmationModal.default.title">Kinnita</Trans>,
    confirmButtonText: <Trans i18nKey="confirmationModal.default.confirmButton">Kinnita</Trans>,
    cancelButtonText: <Trans i18nKey="confirmationModal.default.cancelButton">Katkesta</Trans>,
    isDisabled: false,
    isLoading: false,
    alertGroups: [],
    //onToggle, onCancel, onConfirm,
};

export default ConfirmationModal;
