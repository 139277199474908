import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardHeader, Row, Col } from "reactstrap";
import URLSearchParams from "@ungap/url-search-params";
import { uniqueId } from "lodash";

import { fetchInvoices } from "util/Invoices";
import { getInvoicePreviewUrl } from "config/urls";

// css
import "./Table.scss";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory from "react-bootstrap-table2-filter";

// cells
import DateCell from "components/Table/Cell/DateCell";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import DocumentCell from "components/Table/Cell/DocumentCell";

import WrappedLoader from "components/Loader/WrappedLoader";
import SmallLoader from "components/Loader/WrappedPulseLoader";

import { getSaleStatusType } from "components/Invoices/Table/Options";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert, addNewLocalAlert, clearIncludedGroups
} from "util/Alerts";
import Alerts from "components/Alerts/Alerts";
import { getServerErrorMessage } from "util/Errors";
import { currencyFormatter } from "util/General";
import Everypay from "util/Everypay";
import { withTranslation } from "react-i18next";

class InvoicesTable extends React.Component {
    static propTypes = {
        invoices: PropTypes.array.isRequired,
        activeAlertGroups: PropTypes.array.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            initiatePaymentLoading: false,
        }

        this.PAYMENT_ALERT_GROUP = uniqueId("InvoicesTablePaymentAlertGroup-");
    }

    componentDidMount() {
        const locationState = this.props.location.state;
        if (!this.props.invoices || !this.props.invoices.length || !locationState || locationState.skipMountFetchInvoices !== true) {
            fetchInvoices();
        }

        if (locationState && locationState.skipMountFetchInvoices === true) {
            // Have to remove state so that invoices would fetch on page refresh.
            window.history.replaceState(
                {
                    ...locationState,
                    skipMountFetchInvoices: false,
                },
                ""
            );
        }
        this.showReturnFromPaymentAlert();
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        const { t } = this.props;
        return (
            <>
                <Alerts
                    filterGroups={[this.PAYMENT_ALERT_GROUP]}

                />
                <Card className="invoices-card mt-3">
                    <CardHeader className="invoices-card-header">
                        <Row className=" align-items-center">
                            <Col xs="auto" className="mb-3">
                                <span className="bold-19">
                                    {t("invoices.title", "Arvete saldo")}
                                </span>
                            </Col>
                            <Col xs="auto" className="ml-auto mb-3">
                                <Row className="align-items-center">
                                    <Col xs="auto" className="text-right">
                                        <span className="bold-19">
                                            {this.props.balance === null
                                                ? "-"
                                                : currencyFormatter.format(this.props.balance)}
                                        </span>
                                    </Col>
                                    <Col xs="auto" className="">
                                        <Button
                                            color="secondary"
                                            onClick={this.onClickPayAll}
                                            className="status-btn d-flex align-items-center"
                                            disabled={
                                                !this.props.balance ||
                                                this.props.balance <= 0
                                            }
                                        >
                                            {this.state.initiatePaymentLoading ?
                                                <SmallLoader />
                                                : t("buttons.payAll", "Maksa kõik")
                                            }
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-0 pb-0">
                        {this.renderTable()}
                    </CardBody>
                </Card>
            </>
        );
    }

    renderTable() {
        const { t } = this.props;
        if (this.props.invoicesLoading) {
            return <WrappedLoader />;
        }

        const ButtonFormatter = (cell, row) => {
            const invoiceStatus = row.status || "";
            if (row.is_payable === false || ["TASUTUD", "PAID"].includes(invoiceStatus.toUpperCase())) {
                return (
                    <Button color="secondary" className="status-btn" disabled>
                        {t("invoices.paid", "Makstud")}
                    </Button>
                );
            } else {
                return (
                    <Button
                        color="success"
                        onClick={() => this.onClickPay(row.id)}
                        className="status-btn"
                    >
                        {t("invoices.pay", "Maksma")}
                    </Button>
                );
            }
        };

        const invoicesEndpointDateFormat = "DD-MM-YYYY";

        const columns = [
            {
                dataField: "number",
                text: t("invoices.invoiceNumber", "Arve number"),
                sort: true,
                sortValue: value => value.name,
                formatter: (value) => (
                    <DocumentCell name={value.name} url={value.url} />
                ),
            },
            {
                dataField: "date",
                text: t("invoices.invoiceDate", "Arve kuupäev"),
                sort: true,
                formatter: (value) => (
                    <DateCell value={value} valueFormat={invoicesEndpointDateFormat} />
                ),
                sortValue: (value) => moment(value, invoicesEndpointDateFormat),
            },
            {
                dataField: "status",
                text: t("invoices.invoiceStatus", "Arve staatus"),
                sort: true,
                headerClasses: "status-col-header",
                classes: "status-col",
                formatter: (value) => (
                    <StatusCell
                        status={value}
                        type={getSaleStatusType(value)}
                    />
                ),
            },
            {
                dataField: "due_date",
                text: t("invoices.invoiceDueDate", "Tasumise tähtaeg"),
                sort: true,
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '150px'*/
                    };
                },
                formatter: (value) => (
                    <DateCell value={value} valueFormat={invoicesEndpointDateFormat} />
                ),
                sortValue: (value) => moment(value, invoicesEndpointDateFormat),
            },
            {
                dataField: "amount",
                text: t("invoices.invoiceAmount", "Arve summa"),
                classes: "regular-14",
                sort: true,
                sortValue: value => Number(value),
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '150px'*/
                    };
                },
                align: "right",
                headerAlign: " right",
                formatter: (value) => currencyFormatter.format(value),
            },
            {
                dataField: "amount_paid",
                text: t("invoices.invoicePaidAmount", "Tasutud"),
                classes: "regular-14",
                sort: true,
                sortValue: value => Number(value),
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '150px'*/
                    };
                },
                align: "right",
                headerAlign: "right",
                formatter: (value) => currencyFormatter.format(value),
            },
            {
                dataField: "",
                text: t("invoices.operation", "Toiming"),
                align: "center",
                headerAlign: "center",
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '200px'*/
                    };
                },
                formatter: ButtonFormatter,
            },
        ];

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="invoices-table"
                keyField="id"
                data={this.props.invoices}
                columns={columns}
                filter={filterFactory()}
                filterPosition="inline"
                noDataIndication={
                    <InvoicesTable.NoDataIndication
                        loading={this.props.invoicesLoading}
                        t={t}
                    />
                }
                defaultSorted={[
                    {
                        dataField: "date",
                        order: "desc",
                    },
                ]}
            />
        );
    }

    static NoDataIndication(props) {
        // const { t } = props;
        // let content = t(
        //     "invoices.noInvoicesMessage",
        //     "Ei leitud ühtegi arvet."
        // );

        let content = props.t("invoices.noInvoicesText", "Ei leitud ühtegi arvet.");
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    onClickPay = (invoiceId) => {
        this.props.history.push(getInvoicePreviewUrl(invoiceId), { skipMountFetchInvoices: true });
    };

    onClickPayAll = () => {
        if (this.state.initiatePaymentLoading) return;
        clearLocalAlerts();
        this.setState({
            initiatePaymentLoading: true
        });
        Everypay.initiatePayment(this.props.balance, this.props.activeClientId)
            .then(payment_link => {
                window.location.href = payment_link;
            })
            .catch(error => {
                addNewLocalErrorAlert(
                    getServerErrorMessage(error),
                    this.props.location.pathname
                );
            })
            .finally(() => {
                this.setState({
                    initiatePaymentLoading: false
                });
            });
    };

    showReturnFromPaymentAlert = () => {
        clearIncludedGroups(this.PAYMENT_ALERT_GROUP);
        const searchParams = new URLSearchParams(window.location.search);
        const paymentReference = searchParams.get("payment_reference");
        if (!paymentReference) {
            return;
        }
        Everypay.getPaymentStateAlertContent(paymentReference)
            .then(data => {
                const { message, type } = data;
                addNewLocalAlert(type, message, this.PAYMENT_ALERT_GROUP);
            })
            .catch(error => {
                addNewLocalErrorAlert(
                    getServerErrorMessage(error),
                    this.PAYMENT_ALERT_GROUP
                );
            })
    }
}

const mapStateToProps = (state) => {
    return {
        invoices: state.invoices.invoices,
        invoicesLoading: state.invoices.invoicesLoading,
        balance: state.invoices.balance,
        activeAlertGroups: state.alerts.activeGroups,
        activeClientId: state.auth?.user?.active_client_id || null,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(InvoicesTable))
);
