import React, {Component} from 'react';
import PropTypes from "prop-types";

import {Button} from "reactstrap";

import {clearLocalAlerts} from "util/Alerts";
import {exportExcelPartnerTesting} from "util/excel/PartnerExcels";


class Download extends Component {
    static propTypes = {
        packagesList: PropTypes.array,
        packagesListFunc: PropTypes.func,
        filtersUsed: PropTypes.object,
        fileName: PropTypes.string.isRequired,
        sheetName: PropTypes.string.isRequired,
        dateField: PropTypes.string.isRequired,
        onError: PropTypes.func,
    }
    
    render() {

        return (
            <Button
                outline
                block
                color="secondary"
                onClick={this.onExport}
            >
                Ekspordi
            </Button>
        );
    }

    onExport = () => {
        const packages = this.getPackages(); // this.props.packagesList || [];
        const filtersUsed = this.props.filtersUsed || {};
        clearLocalAlerts();
        try {
            exportExcelPartnerTesting(this.props.fileName, this.props.sheetName, packages, filtersUsed, this.props.dateField)
        } catch (err) {
            if (this.props.onError) {
                this.props.onError(err);
            } else {
                console.error(err);
            }
        }
    }

    getPackages = () => {
        if (this.props.packagesList) {
            return this.props.packagesList;
        }
        else if (this.props.packagesListFunc) {
            return this.props.packagesListFunc();
        }

        return [];
    }
}

export default Download;