import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";

import { Row, Col, Button } from "reactstrap";

// datepicker
import "react-datepicker/dist/react-datepicker.css";

// generic
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import { isAdminMode } from "util/Access";

// module specific
import { actions } from "./redux";
//import reportUtils from "./utils";
import SyncModal from "./SyncModal";

// css
//import "./ListActions.scss";


class AdditionalDepositReportListActions extends React.Component {
    static propTypes = {
        // manual
        onSyncSuccess: PropTypes.func.isRequired,
        // automatic
        list: PropTypes.array.isRequired,
        selectedIds: PropTypes.array.isRequired,
        selectedRows: PropTypes.array.isRequired,
        isAdmin: PropTypes.bool,
        updateSelected: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            isSyncOpen: false
        }
    }

    componentDidMount() {
    }

    render() {
        const { isAdmin } = this.props;
        if (!isAdmin) return null;
        return (
            <Row className="no-gutters border-top pt-4 pb-3">
                <Col>
                    <Row className="justify-content-end align-items-center mx-3 px-2 mb-2n">
                        <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 3 }} lg={2} className="px-1 mb-2">
                            {this.renderSyncButtonAndModal()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    renderSyncButtonAndModal() {
        const { onSyncSuccess, isAdmin } = this.props;
        const { isSyncOpen } = this.state;

        if (!isAdmin) return null;

        const onToggle = () => {
            this.setState(prevState => { return { isSyncOpen: !prevState.isSyncOpen } });
        };
        const onSuccess = data => {
            this.setState({
                isSyncOpen: false
            })
            if (onSyncSuccess) {
                onSyncSuccess(data);
            }
        }
        const isButtonDisabled = false;
        const isOpen = isSyncOpen;
        return (
            <>
                <Button
                    block
                    color="secondary"
                    disabled={isButtonDisabled}
                    onClick={onToggle}
                >
                    Sünkroniseeri aruanded
                </Button>
                <SyncModal isOpen={isOpen} onToggle={onToggle} onSuccess={onSuccess} details={null} isDetailsView={false} />

            </>
        );
    }

}

const mapStateToProps = state => {
    // Module state reducer
    const listState = state.additionalDepositReports;
    return {
        isAdmin: isAdminMode(),
        list: listState.list,
        selectedIds: listState.listSelectedIds,
        selectedRows: listState.list.filter(row => listState.listSelectedIds.includes(row.id))
    }
};

const reduxActions = {
    updateSelected: actions.listSelectedUpdate
}

export default withRouter(connect(mapStateToProps, reduxActions)(AdditionalDepositReportListActions));
