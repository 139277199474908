import store from "redux/store";

import {
    navigationUpdateHeaderSuffix, 
} from "redux/actions/navigation";


export function updateNavigationHeaderSuffix(text) {
    store.dispatch(navigationUpdateHeaderSuffix(text));
}

export function clearNavigationHeaderSuffix() {
    store.dispatch(navigationUpdateHeaderSuffix(""));
}