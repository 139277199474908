import React from "react";
import moment from "moment";
import { Trans } from "react-i18next";

export const salesReportPeriodServerFormat = "YYYY-MM";
export const YEAR_MONTH_FORMAT = "MMMM YYYY";
export const YEAR_MONTH_FORMAT_XLSX = "MMMM YYYY";
export const YEAR_MONTH_FORMAT_DATE_FN = "MMMM yyyy";

export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_FORMAT_DATE_FN = "dd.MM.yyyy";
export const DATE_FORMAT_XLSX = 'dd"."mm"."yyyy'; // https://github.com/SheetJS/sheetjs/issues/718#issuecomment-313465344

export const DATE_TIME_FORMAT = "DD.MM.YYYY HH:mm";

export const salesReportPeriodDetailsFormat = YEAR_MONTH_FORMAT;
export const salesReportPeriodSortFormat = YEAR_MONTH_FORMAT;
export const salesReportPeriodListFormat = YEAR_MONTH_FORMAT;
export const salesReportPeriodSearchFormat = YEAR_MONTH_FORMAT_DATE_FN; // Datepicker needs yyyy. For date-fn, not for moment

export function parseSalesReportPeriodDate(period) {
    return moment(period, salesReportPeriodServerFormat);
}

export function secondDateIsSameOrAfterFirstDate(
    firstDate,
    secondDate,
    precision = "month"
) {
    const firstMoment = moment(firstDate);
    if (!firstMoment.isValid()) {
        throw Error("First argument is not a valid moment.");
    }
    const secondMoment = moment(secondDate);
    if (!secondMoment.isValid()) {
        throw Error("Second argument is not a valid moment.");
    }

    return secondMoment.isSameOrAfter(firstMoment, precision);
}

export function getFromAndToDatePeriodAsString(
    fromDate,
    toDate,
    format = salesReportPeriodListFormat
) {
    const fromMoment = moment(fromDate);
    const toMoment = moment(toDate);

    const fromStr = fromMoment.isValid() ? (
        fromMoment.format(format)
    ) : (
        <Trans i18nKey="reports.noFromDateFilterDefault">Vanim</Trans>
    );
    const toStr = toMoment.isValid() ? (
        toMoment.format(format)
    ) : (
        <Trans i18nKey="reports.noToDateFilterDefault">Uusim</Trans>
    );

    return (
        <div>
            {fromStr} - {toStr}
        </div>
    );
}
