import React from "react";
import axios from "../../axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import { Button } from "reactstrap";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import { getServerErrorMessage } from "util/Errors";

import {
    clearLocalAlerts,
    addNewLocalSuccessAlert,
    addNewLocalErrorAlert,
} from "util/Alerts";

import {
    PARTNER_TESTING_COMPLETED,
} from "config/Api";


class TestingCompletedConfirmation extends React.Component {
    static propTypes = {
        isRetest: PropTypes.bool,
    }

    static defaultProps = {
        isRetest: false,
    };

    state = { confirmationModalOpen: false, confirmationIsLoading: false };
    static alertGroup = "TestingCompletedConfirmation";

    render() {
        return (
            <>
                <Button
                    outline
                    block
                    color="secondary"
                    onClick={this.onOpenConfirmationModal}
                >
                    {this.props.isRetest ? "Lõpeta kordustestimine" : "Lõpeta testimine"}
                </Button>

                {this.renderConfirmModal()}
            </>
        );
    }

    renderConfirmModal() {
        return (
            <ConfirmationModal
                title={this.props.isRetest ? "Lõpeta kordustestimine" : "Lõpeta testimine"}
                isOpen={this.state.confirmationModalOpen}
                onToggle={this.onCancelConfirmation}
                onCancel={this.onCancelConfirmation}
                onConfirm={this.onConfirm}
                isLoading={this.state.confirmationIsLoading}
                alertGroups={[TestingCompletedConfirmation.alertGroup]}
            >
                <span className="regular-14">{this.props.isRetest ? "Kordustestimise" : "Testimise"} lõpetamise kinnitamisel saadetakse EPP-le teavitus, et esitatud testitulemused on kinnitatud</span>
            </ConfirmationModal>
        );
    }

    onOpenConfirmationModal = () => {
        this.setState({
            confirmationModalOpen: true,
        });
    };

    onCancelConfirmation = () => {
        this.setState({
            confirmationModalOpen: false,
        });
        clearLocalAlerts();
    };

    onConfirm = () => {
        clearLocalAlerts();

        this.setState({
            confirmationIsLoading: true,
        });

        return axios
            .post(PARTNER_TESTING_COMPLETED, { is_retest: this.props.isRetest })
            .then(() => {
                addNewLocalSuccessAlert(
                    "Email saadetud.",
                    this.props.location.pathname
                );
                this.setState({
                    confirmationModalOpen: false,
                });
            })
            .catch((error) => {
                const message = `Emaili saatmine ebaõnnestus: ${getServerErrorMessage(error)}`;
                addNewLocalErrorAlert(message, TestingCompletedConfirmation.alertGroup, false);
            })
            .finally(() => {
                this.setState({
                    confirmationIsLoading: false,
                });
            });
    };
}

export default withRouter(TestingCompletedConfirmation);
