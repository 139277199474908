import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";

const initialState = {
    categories: [],
    types: [],
    colors: [],
    volumes: [],
    prices: [],
    processFees: [],
};

const onAdminCategoriesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        categories: [],
    });
};

const onAdminCategoriesFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        categories: action.categories,
    });
};

const onAdminCategoriesFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        categories: [],
    });
};

const onAdminTypesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        types: [],
    });
};

const onAdminTypesFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        types: action.types,
    });
};

const onAdminTypesFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        types: [],
    });
};

const onAdminColorsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        colors: [],
    });
};

const onAdminColorsFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        colors: action.colors,
    });
};

const onAdminColorsFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        colors: [],
    });
};

const onAdminVolumesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        volumes: [],
    });
};

const onAdminVolumesFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        volumes: action.volumes,
    });
};

const onAdminVolumesFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        volumes: [],
    });
};

const onAdminProcessFeesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        processFees: [],
    });
};

const onAdminProcessFeesFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        processFees: action.processFees,
    });
};

const onAdminProcessFeesFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        processFees: [],
    });
};

export const settings = (state = initialState, action) => {
    switch (action.type) {
        // Categories
        case actionTypes.ADMIN_CATEGORIES_FETCH_START:
            return onAdminCategoriesFetchStart(state, action);
        case actionTypes.ADMIN_CATEGORIES_FETCH_SUCCESS:
            return onAdminCategoriesFetchSuccess(state, action);
        case actionTypes.ADMIN_CATEGORIES_FETCH_FAIL:
            return onAdminCategoriesFetchFail(state, action);

        // Types
        case actionTypes.ADMIN_TYPES_FETCH_START:
            return onAdminTypesFetchStart(state, action);
        case actionTypes.ADMIN_TYPES_FETCH_SUCCESS:
            return onAdminTypesFetchSuccess(state, action);
        case actionTypes.ADMIN_TYPES_FETCH_FAIL:
            return onAdminTypesFetchFail(state, action);

        // Colors
        case actionTypes.ADMIN_COLORS_FETCH_START:
            return onAdminColorsFetchStart(state, action);
        case actionTypes.ADMIN_COLORS_FETCH_SUCCESS:
            return onAdminColorsFetchSuccess(state, action);
        case actionTypes.ADMIN_COLORS_FETCH_FAIL:
            return onAdminColorsFetchFail(state, action);

        // Volumes
        case actionTypes.ADMIN_VOLUMES_FETCH_START:
            return onAdminVolumesFetchStart(state, action);
        case actionTypes.ADMIN_VOLUMES_FETCH_SUCCESS:
            return onAdminVolumesFetchSuccess(state, action);
        case actionTypes.ADMIN_VOLUMES_FETCH_FAIL:
            return onAdminVolumesFetchFail(state, action);

        // Package fees
        case actionTypes.ADMIN_PROCESS_FEES_FETCH_START:
            return onAdminProcessFeesFetchStart(state, action);
        case actionTypes.ADMIN_PROCESS_FEES_FETCH_SUCCESS:
            return onAdminProcessFeesFetchSuccess(state, action);
        case actionTypes.ADMIN_PROCESS_FEES_FETCH_FAIL:
            return onAdminProcessFeesFetchFail(state, action);

        default:
            return state;
    }
};
