import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import onScan from "onscan.js";

import {
    fetchApplications,
    sendToTesting,
    generatePackageApplicationInvoice
} from "util/admin/Applications";
import urls, { getAdminPackageDetailsUrl } from "config/urls";
import {
    clearLocalAlerts, addNewLocalSuccessAlert, addNewLocalErrorAlert, addNewLocalInfoAlert
} from "util/Alerts";
import { getErrorMessageList } from "util/Errors";

import {
    adminApplicationsFilterUpdate,
    adminApplicationsFilterClear,
    adminApplicationsSortingUpdate,
    adminApplicationsPaginationUpdate,
    adminApplicationsUpdateSelected,
} from "redux/actions/admin/applications";

import { Card, CardBody, Row, Col, Button } from "reactstrap";
import Table from "./Table/Table";
import Search from "./Search/Search";
import SelectedApplicationsModal from "./Modal/SelectedApplications";

import { beepSound } from "util/Audio";

class ApplicationsView extends React.Component {
    static propTypes = {
        // redux state
        packagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,
        filters: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        packagesListFiltersUsed: PropTypes.object.isRequired,
        selectedIds: PropTypes.array.isRequired,
        // redux actions
        adminApplicationsFilterUpdate: PropTypes.func.isRequired,
        adminApplicationsFilterClear: PropTypes.func.isRequired,
        adminApplicationsSortingUpdate: PropTypes.func.isRequired,
        adminApplicationsPaginationUpdate: PropTypes.func.isRequired,
        adminApplicationsUpdateSelected: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            isSelectedApplicationsModalOpen: false,
            isGenerateInvoiceLoading: false,
        }
    }

    componentDidMount() {
        onScan.attachTo(document, {
            suffixKeyCodes: [13], // enter-key expected at the end of a scan
            onScan: this.onScan, // Alternative to document.addEventListener('scan')
            onKeyDetect: function (iKeyCode) { // output all potentially relevant key events - great for debugging!
                //console.log('Pressed: ' + iKeyCode);
            }
        })

        fetchApplications(false, this.props.packagesListFiltersUsed, this.props.location.pathname);
    }

    componentWillUnmount() {
        onScan.detachFrom(document);
    }

    render() {
        return (
            <Card className="applications-card">
                <CardBody className="applications-card-body px-0">
                    <Search
                        filters={this.props.filters}
                        onFilterUpdate={this.props.adminApplicationsFilterUpdate}
                        onClearFilters={this.props.adminApplicationsFilterClear}
                        packagesListLoading={this.props.packagesListLoading}
                        onSearch={this.onSearch}
                    />
                    <Row className="no-gutters border-top pt-4 pb-3">
                        <Col>
                            <Row className="justify-content-end align-items-center mx-3 px-2 mb-2n">
                                <Col md={4} lg={{ size: 2, offset: 10 }} className="px-1 mb-2">
                                    <Button
                                        block
                                        color="secondary"
                                        onClick={this.onToggleSelectedApplicationsModal}
                                    >
                                        Valitud näidised {`(${this.props.selectedIds.length})`}
                                    </Button>
                                    <SelectedApplicationsModal
                                        isOpen={this.state.isSelectedApplicationsModalOpen}
                                        packagesList={this.props.unfilteredPackagesList}
                                        selectedIds={this.props.selectedIds}
                                        onSelect={this.onPackageSelect}
                                        onSelectAll={this.onPackageSelectAll}
                                        onCancel={() => this.props.adminApplicationsUpdateSelected([])}
                                        onConfirm={() => this.onSendToTesting(SelectedApplicationsModal.ALERT_GROUP)}
                                        onToggle={this.onToggleSelectedApplicationsModal}
                                        alertGroups={[SelectedApplicationsModal.ALERT_GROUP]}
                                        isDisabled={this.props.sendToTestingLoading || !this.props.selectedIds.length || this.state.isGenerateInvoiceLoading}
                                        isLoading={this.props.sendToTestingLoading}
                                        onGenerateInvoice={() => this.onGenerateInvoice(SelectedApplicationsModal.ALERT_GROUP)}
                                        isGenerateInvoiceDisabled={this.isGenerateInvoiceDisabled()}
                                        isGenerateInvoiceLoading={this.state.isGenerateInvoiceLoading}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                packagesList={this.props.packagesList}
                                packagesListLoading={this.props.packagesListLoading}
                                selectedIds={this.props.selectedIds}
                                onSelect={this.onPackageSelect}
                                onSelectAll={this.onPackageSelectAll}
                                onRowClick={this.onRowClick}
                                sorting={this.props.sorting}
                                onSort={this.onSort}
                                pagination={this.props.pagination}
                                onPaginationChange={this.onPaginationChange}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    onScan = (sCode, iQty) => {
        clearLocalAlerts();
        const ean = sCode;

        const alertGroup = this.state.isSelectedApplicationsModalOpen ? SelectedApplicationsModal.ALERT_GROUP : this.props.location.pathname;
        const allApplications = this.props.unfilteredPackagesList;

        const applicationsWithEan = allApplications.filter(application => application.ean === ean);

        if (applicationsWithEan.length === 0) {
            let message = <div>Taotluste hulgast ei leitud skänneeritud EAN-i <b>"{ean}"</b>.</div>;
            addNewLocalErrorAlert(message, alertGroup);
            beepSound.play();
            return;
        }

        let newSelectedIds = [...this.props.selectedIds];
        const alreadySelectedApplications = [];
        const unselectedApplications = [];
        for (const application of applicationsWithEan) {
            const id = application.id;

            if (newSelectedIds.includes(id)) {
                alreadySelectedApplications.push(application);
            }
            else {
                unselectedApplications.push(application);
            }
        }

        const applicationPlurarityText = unselectedApplications.length === 1 ? "Järgnev näidis" : "Järgnevad näidised";
        const descriptionFunc = application => `${application.name} (${application.client})`;
        if (alreadySelectedApplications.length) {
            const topText = `${applicationPlurarityText} skänneeritud EAN-iga "${ean}" on juba valitud:`;
            const message = getErrorMessageList(alreadySelectedApplications, descriptionFunc, topText);
            addNewLocalInfoAlert(message, alertGroup);
        }

        if (unselectedApplications.length) {
            const topText = `${applicationPlurarityText} skänneeritud EAN-iga "${ean}" on nüüd valitud:`;
            const message = getErrorMessageList(unselectedApplications, descriptionFunc, topText);
            addNewLocalSuccessAlert(message, alertGroup);

            newSelectedIds = [...newSelectedIds, ...unselectedApplications.map(application => application.id)];
            this.props.adminApplicationsUpdateSelected(newSelectedIds);
        }
    }

    onToggleSelectedApplicationsModal = () => {
        this.setState(prevState => { return { isSelectedApplicationsModalOpen: !prevState.isSelectedApplicationsModalOpen } },
            () => {
                if (this.state.isSelectedApplicationsModalOpen) {
                    clearLocalAlerts();
                }
            });
    }

    onSearch = () => {
        clearLocalAlerts();
        fetchApplications(false, this.props.filters, this.props.location.pathname);
    }

    onPaginationChange = (page, sizePerPage) => {
        const currentPagination = this.props.pagination;
        const newPagination = { ...currentPagination, page, sizePerPage };
        this.props.adminApplicationsPaginationUpdate(newPagination);
    }

    onSort = (field, order) => {
        const currentSorting = this.props.sorting;
        if (currentSorting.dataField !== field || currentSorting.order !== order) {
            const newSorting = {
                dataField: field,
                order: order,
            };

            this.props.adminApplicationsSortingUpdate(newSorting);
        }
    }

    onPackageSelect = (row, isSelect, rowIndex, e) => {
        let newSelectedIds = [...this.props.selectedIds];

        if (!isSelect) {
            newSelectedIds = newSelectedIds.filter(id => id !== row.id);
        }
        else if (isSelect && !newSelectedIds.includes(row.id)) {
            newSelectedIds.push(row.id);
        }

        this.props.adminApplicationsUpdateSelected(newSelectedIds);
    }

    onPackageSelectAll = (isSelect, rows) => {
        let newSelectedIds = [...this.props.selectedIds];
        const ids = rows.map(row => row.id);

        if (isSelect) {
            newSelectedIds = [...newSelectedIds, ...ids.filter(id => !newSelectedIds.includes(id))];
        }
        else {
            newSelectedIds = newSelectedIds.filter(id => !ids.includes(id));
        }
        this.props.adminApplicationsUpdateSelected(newSelectedIds);
    }

    onSendToTesting = (alertGroup) => {
        sendToTesting(this.props.selectedIds, alertGroup, this.props.unfilteredPackagesList);
    }

    onRowClick = (event, row) => {
        const packageDetailsUrl = getAdminPackageDetailsUrl(row.id);
        this.props.history.push(packageDetailsUrl, { previousViewRoute: urls.EPP_REGISTRY_APPLICATIONS });
    }

    onGenerateInvoice = (alertGroup) => {
        this.setState({
            isGenerateInvoiceLoading: true,
        })
        clearLocalAlerts();
        generatePackageApplicationInvoice(this.props.selectedIds)
            .then(data => {
                this.setState({
                    isGenerateInvoiceLoading: false,
                })
                clearLocalAlerts();
                addNewLocalSuccessAlert("Pakendite taotluse arve edukalt loodud kui see varem ei eksisteerinud.", alertGroup);
            }).catch(error => {
                this.setState({
                    isGenerateInvoiceLoading: false,
                })
                clearLocalAlerts();
                addNewLocalErrorAlert(error.message, alertGroup);
            });
    }

    isGenerateInvoiceDisabled = () => {
        const selectedIds = this.props.selectedIds;
        if (selectedIds.length === 0 || this.state.isGenerateInvoiceLoading || this.props.sendToTestingLoading) {
            return true;
        }

        const allPackages = this.props.unfilteredPackagesList;
        const selectedPackages = allPackages.filter(pkg => selectedIds.includes(pkg.id));

        if (selectedIds.length !== selectedPackages.length) {
            return true;
        }

        const firstClient = selectedPackages[0].client;
        const isAllSameClient = selectedPackages.every(pkg => pkg.client === firstClient);

        return !isAllSameClient;
    }
}

const mapStateToProps = state => {
    return {
        unfilteredPackagesList: state.admin.applications.unfilteredPackagesList || [],
        packagesList: state.admin.applications.packagesList,
        packagesListLoading: state.admin.applications.packagesListLoading,
        packagesListFiltersUsed: state.admin.applications.packagesListFiltersUsed,
        filters: state.admin.applications.filters,
        sorting: state.admin.applications.sorting,
        pagination: state.admin.applications.pagination,
        selectedIds: state.admin.applications.selectedIds,
        sendToTestingLoading: state.admin.applications.sendToTestingLoading,
    }
};

const mapActionsToProps = {
    adminApplicationsFilterUpdate,
    adminApplicationsFilterClear,
    adminApplicationsUpdateSelected,
    adminApplicationsSortingUpdate,
    adminApplicationsPaginationUpdate,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(ApplicationsView));
