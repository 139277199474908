import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Row, Col } from 'reactstrap';


import { ReactComponent as MoreVerticalIcon } from 'assets/icons/more-vertical.svg';
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";
import { isAdminMode } from "util/Access";

import SyncModal from "./SyncModal";
import reportUtils from "./utils";

import "./ActionsMenu.scss";

const StyledDropdownItem = (props) => <DropdownItem {...props} className="semibold-14 text-muted">{props.children}</DropdownItem>;

class AdditionalDepositReportActionsMenu extends React.Component {
    static propTypes = {
        details: PropTypes.object.isRequired,
        onSyncSuccess: PropTypes.func.isRequired,
        isDetailsViewMode: PropTypes.bool,
        // automatic
        isAdmin: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            isSyncOpen: false
        }
    }

    render() {
        const { isDetailsViewMode } = this.props;

        return (
            <div className="additional-deposit-report-actions">
                {
                    isDetailsViewMode ?
                        this.renderDetailsViewMode()
                        :
                        this.renderDropdownMenu()
                }
                {this.renderSyncModal()}
            </div>
        )
    }

    renderDetailsViewMode() {
        return (
            <Row>
                {this.renderActions("button-col")}
            </Row>
        )
    }

    renderDropdownMenu() {
        return (
            <UncontrolledButtonDropdown direction="left" onClick={e => { e.stopPropagation(); }}>
                <DropdownToggle tag="div">
                    <Button color="link" className="icon-default additional-deposit-report-icon-button">
                        <MoreVerticalIcon />
                    </Button>
                </DropdownToggle>
                <DropdownMenu>
                    {this.renderActions("dropdown")}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }

    renderSyncModal = () => {
        const { details, onSyncSuccess } = this.props;
        const { isSyncOpen } = this.state;

        const onSuccess = (data, details) => {
            this.setState({
                isSyncOpen: false
            })
            if (onSyncSuccess) {
                onSyncSuccess(data, details);
            }
        }
        return (
            <SyncModal details={details} isOpen={isSyncOpen} onToggle={this.onToggleSyncModal} onSuccess={onSuccess} />
        )
    }

    renderActions(mode) {
        const renderActionList = [
            this.renderActionOpenDetails,
            this.renderSyncFile
        ]

        return renderActionList.map((renderAction) => renderAction(mode))
    }

    renderItem(text, onClick, options) {
        const config = _.defaultsDeep(options, {
            mode: "dropdown", // "dropdown" or "button-col"
        });
        if (config.mode === "button-col") {
            return (
                <Col key={text} xs="auto" className="px-1 py-1">
                    <Button color="success" className="details-button" outline onClick={() => onClick()}>{text}</Button>
                </Col>
            );
        }

        return (
            <StyledDropdownItem
                onClick={() => onClick()}
                key={text}
            >
                {text}
            </StyledDropdownItem>
        );
    }

    renderActionOpenDetails = (mode) => {
        const { t, isDetailsViewMode } = this.props;

        if (isDetailsViewMode) return null;
        const label = t("reports.additionalDeposit.menu.openDetails");
        return this.renderItem(label, this.onView, { mode });
    }

    renderSyncFile = mode => {
        const { t, isAdmin } = this.props;
        if (!isAdmin) return;
        const text = t("reports.additionalDeposit.menu.syncLabel")
        return this.renderItem(text, this.onToggleSyncModal, { mode })
    }

    onView = (isOpenInTab = false) => {
        const { history, details } = this.props;
        const route = reportUtils.getDetailsViewUrl(details.id);
        if (isOpenInTab) {
            window.open(route, '_blank');
        } else {
            history.push(route);
        }
    }

    onToggleSyncModal = (value) => {
        this.setState(prevState => ({
            isSyncOpen: !prevState.isSyncOpen
        }));
    }
}

const mapStateToProps = (state) => {
    // Module state reducer
    return {
        isAdmin: isAdminMode()
    };
};

export default withRouter(connect(mapStateToProps, null)(withTranslation()(AdditionalDepositReportActionsMenu)));
