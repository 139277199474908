import React from "react";

import { ReactComponent as CloseIcon } from "assets/icons/diagonal-plus-close.svg";
import "./CloseTextIcon.scss";

export default function CloseTextIcon({ text, onClick }) {

    return (
        <span onClick={onClick} className="close-text-icon">
            <span className="regular-12 color-gray">{text} </span>
            <CloseIcon className="close-icon" />
        </span>
    );
}
