import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";

import { Card, CardBody, Form, Row, Col, Button } from "reactstrap";

// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import SmallLoader from "components/Loader/WrappedPulseLoader";
import Select from "components/Form/Select";

import {
    fetchAllClients,
} from "util/General";
import { YEAR_MONTH_FORMAT_DATE_FN } from "util/DateTime";

// css
import "./Search.scss";


class AdminSalesReportsSearch extends React.Component {
    static propTypes = {
        // Manual
        salesReportsListLoading: PropTypes.bool,
        onSearch: PropTypes.func.isRequired,
        filters: PropTypes.object.isRequired,
        filtersUpdate: PropTypes.func.isRequired,
        filtersClear: PropTypes.func.isRequired,
        // Taken from redux
        allClients: PropTypes.array,

    }

    componentDidMount() {
        fetchAllClients(this.props.allClients);
    }

    render() {
        return (
            <Card className="admin-search-sales-reports-card mb-3">
                <CardBody className="pb-3">
                    <Form>
                        <Row className="align-items-center mx-0">
                            <Col className="search-period-text bold-19 col-md-12 col-lg-auto mb-2 px-2">
                                <span className="">Periood</span>
                            </Col>
                            <Col lg="" md="12" className="px-1">
                                <Select
                                    options={this.props.allClients}
                                    isClearable
                                    isSearchable
                                    value={this.props.filters.client}
                                    placeholder="Ettevõte"
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    onChange={this.updateFilter('client')}
                                    className="mb-2"
                                />
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-1">
                                <DatePicker
                                    placeholderText="Alates"
                                    selected={this.props.filters.startDate}
                                    onChange={value => this.updateFilter("startDate")(value || this.props.filters.startDate)}
                                    showMonthYearPicker
                                    maxDate={this.props.filters.endDate || new Date()}
                                    dateFormat={YEAR_MONTH_FORMAT_DATE_FN}
                                />
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-1">
                                <DatePicker
                                    showMonthYearPicker
                                    placeholderText="Kuni"
                                    selected={this.props.filters.endDate}
                                    onChange={value => this.updateFilter("endDate")(value || this.props.filters.endDate)}
                                    minDate={this.props.filters.startDate}
                                    maxDate={new Date()}
                                    dateFormat={YEAR_MONTH_FORMAT_DATE_FN}
                                />
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-1">
                                <Button
                                    type="submit"
                                    block
                                    color="success"
                                    className="search-button"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.onSearch();
                                    }}
                                    disabled={this.props.salesReportsListLoading}
                                >
                                    {this.props.salesReportsListLoading ? <SmallLoader /> : "Otsi"}
                                </Button>
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-1">
                                <Button
                                    outline block
                                    color="success"
                                    className="search-button"
                                    onClick={this.onClearFilters}
                                >
                                    Tühjenda otsing
                            </Button>
                            </Col>
                        </Row>
                    </Form>
                </CardBody>
            </Card>
        );
    }

    updateFilter = key => value => {
        this.props.filtersUpdate(key, value);
    }

    onClearFilters = () => {
        this.props.filtersClear();
    }

    onSearch = () => {
        this.props.onSearch(this.props.filters);
    }

}

const mapStateToProps = state => {
    return {
        allClients: state.general.allClients,
    }
};

const reduxActions = {
}

export default withRouter(connect(mapStateToProps, reduxActions)(AdminSalesReportsSearch));
