import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Search from "components/Admin/Reports/Search/Search";
import Table from "components/Admin/Reports/Table/Table";

import { fetchUnconfirmedSalesReports } from "util/admin/SalesReports";
import { isSalesReportHasUnconfirmedRepairReport } from "util/SalesReports";

import {
    adminUnconfirmedSalesReportsFilterUpdate,
    adminUnconfirmedSalesReportsFilterClear,
} from "redux/actions/admin/unconfirmedSalesReports";

class AdminUnconfirmedRepairReportsView extends React.Component {
    static propTypes = {
        salesReportsList: PropTypes.array,
        salesReportsListLoading: PropTypes.bool,
        salesReportsListFiltersUsed: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        adminUnconfirmedSalesReportsFilterUpdate: PropTypes.func.isRequired,
        adminUnconfirmedSalesReportsFilterClear: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.getSalesReportsList();
    }

    getSalesReportsList = (filters = null) => {
        if (filters == null) {
            filters = this.props.salesReportsListFiltersUsed;
        }
        fetchUnconfirmedSalesReports(filters, this.props.location.pathname);
    }


    render() {
        return (
            <>
                <Search
                    onSearch={this.getSalesReportsList}
                    filters={this.props.filters}
                    filtersUpdate={this.props.adminUnconfirmedSalesReportsFilterUpdate}
                    filtersClear={this.props.adminUnconfirmedSalesReportsFilterClear}
                />
                <Table
                    salesReportsList={this.filterUnconfirmedSalesReports(this.props.salesReportsList)}
                    salesReportsListLoading={this.props.salesReportsListLoading}
                />
            </>
        );
    }

    filterUnconfirmedSalesReports = (salesReports) => {
        if (!salesReports || !salesReports.length) {
            return salesReports;
        }

        return salesReports.filter(report => isSalesReportHasUnconfirmedRepairReport(report));
    }
}

const reduxActions = {
    adminUnconfirmedSalesReportsFilterUpdate,
    adminUnconfirmedSalesReportsFilterClear,
}

const mapStateToProps = state => {
    return {
        salesReportsList: state.admin.unconfirmedSalesReports.salesReportsList,
        salesReportsListLoading: state.admin.unconfirmedSalesReports.salesReportsListLoading,
        salesReportsListFiltersUsed: state.admin.unconfirmedSalesReports.salesReportsListFiltersUsed,
        filters: state.admin.unconfirmedSalesReports.filters,
    }
};

export default withRouter(connect(mapStateToProps, reduxActions)(AdminUnconfirmedRepairReportsView));
