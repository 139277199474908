import * as actionTypes from "redux/actions/actionTypes";

export const adminRetestingConfirmPackageTestStart = () => {
    return {
        type: actionTypes.ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_START,
    };
};

export const adminRetestingConfirmPackageTestSuccess = (ids) => {
    return {
        type: actionTypes.ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_SUCCESS,
        ids,
    };
};

export const adminRetestingConfirmPackageTestFail = (error) => {
    return {
        type: actionTypes.ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_FAIL,
        error,
    };
};

export const adminRetestingUpdateSelected = (selected) => {
    return {
        type: actionTypes.ADMIN_RETESTING_UPDATE_SELECTED,
        selected,
    };
};

export const adminRetestingPackagesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_FETCH_START,
    };
};

export const adminRetestingPackagesFetchSuccess = (
    unfilteredPackagesList,
    packagesList,
    filters
) => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_FETCH_SUCCESS,
        packagesList,
        filters,
        unfilteredPackagesList,
    };
};

export const adminRetestingPackagesFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_FETCH_FAIL,
        error,
    };
};

export const adminRetestingPackagesFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const adminRetestingPackagesFilterClear = () => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_FILTER_CLEAR,
    };
};

export const adminRetestingPackagesSortingUpdate = (sorting) => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_SORTING_UPDATE,
        sorting,
    };
};

export const adminRetestingPackagesPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.ADMIN_RETESTING_PACKAGES_PAGINATION_UPDATE,
        pagination,
    };
};
