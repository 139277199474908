import React from "react";
import PropTypes from "prop-types";

import ReactSelect from "react-select";

import { FormGroup, Label } from "reactstrap";

import "./Select.scss";

class CustomSelect extends React.Component {
    static propTypes = {
        labelText: PropTypes.string,
        labelHidden: PropTypes.bool,
        disabled: PropTypes.bool,
        className: PropTypes.string,
        required: PropTypes.bool,
        isLoading: PropTypes.bool,
        // validation
        valid: PropTypes.bool,
        validText: PropTypes.string,
        invalid: PropTypes.bool,
        invalidText: PropTypes.string,
    }

    static defaultProps = {
        labelText: "",
        labelHidden: false,
        className: "",
        disabled: false,
        valid: false,
        validText: "",
        invalid: false,
        invalidText: "",
    }

    static LABEL_DISABLED_CSS = "is-disabled";
    static ACTIVE_CSS = "is-active";
    static VALID_CSS = "custom-is-valid";
    static INVALID_CSS = "custom-is-invalid";
    static REQUIRED_CSS = "custom-required";

    constructor(props) {
        super(props);

        this.state = {
            active_css: "",
        };
    }

    onFocus = (event) => {
        this.setState({ active_css: CustomSelect.ACTIVE_CSS });
    }

    onBlur = (event) => {
        this.setState({ active_css: "" });
    };

    getLabelClassName = () => {
        let className = "custom-label";
        className += this.addClassName(this.state.active_css, this.state.active_css);
        className += this.addClassName(CustomSelect.REQUIRED_CSS, this.props.required);
        className += this.addClassName(CustomSelect.VALID_CSS, this.props.valid);
        className += this.addClassName(CustomSelect.INVALID_CSS, this.props.invalid);
        className += this.addClassName(CustomSelect.LABEL_DISABLED_CSS, this.props.disabled);
        return className;
    }

    getSelectClassName = () => {
        let className = "ppe-select-container";
        className += this.addClassName(this.state.active_css, this.state.active_css);
        className += this.addClassName(CustomSelect.VALID_CSS, this.props.valid);
        className += this.addClassName(CustomSelect.INVALID_CSS, this.props.invalid);
        className += this.addClassName(this.props.className, this.props.className);
        return className;
    }

    addClassName = (className, condition) => {
        if (!className || !condition) {
            return "";
        }
        return " " + className;
    }

    render() {
        return (
            <FormGroup className="custom-select-field">
                {this.props.labelText &&
                    <Label
                        className={this.getLabelClassName()}
                        hidden={this.props.labelHidden}
                        disabled={this.props.disabled}
                    >
                        {this.props.labelText}
                    </Label>
                }
                <ReactSelect
                    {...this.props}
                    isDisabled={this.props.disabled}
                    className={this.getSelectClassName()}
                    classNamePrefix="ppe-select"
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                />
                {this.props.valid && this.props.validText && <div className="custom-valid-feedback">{this.props.validText}</div>}
                {this.props.invalid && this.props.invalidText && <div className="custom-invalid-feedback">{this.props.invalidText}</div>}
            </FormGroup>
        );
    }
}


export default CustomSelect;
