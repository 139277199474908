import axios from "../../axios";
import store from "redux/store";

import {
    adminCategoriesFetchStart,
    adminCategoriesFetchSuccess,
    adminCategoriesFetchFail,
    adminTypesFetchStart,
    adminTypesFetchSuccess,
    adminTypesFetchFail,
    adminColorsFetchStart,
    adminColorsFetchSuccess,
    adminColorsFetchFail,
    adminVolumesFetchStart,
    adminVolumesFetchSuccess,
    adminVolumesFetchFail,
    adminProcessFeesFetchStart,
    adminProcessFeesFetchSuccess,
    adminProcessFeesFetchFail,
} from "redux/actions/admin/settings";

import {
    ADMIN_SETTINGS_CATEGORIES,
    ADMIN_SETTINGS_COLORS,
    ADMIN_SETTINGS_PROCESS_FEES,
    ADMIN_SETTINGS_TYPES,
    ADMIN_SETTINGS_VOLUMES,
    ADMIN_SETTINGS_FETCH_NAV,
} from "config/Api";

export const fetchAdminSettingsCategories = () => {
    store.dispatch(adminCategoriesFetchStart());
    axios
        .get(ADMIN_SETTINGS_CATEGORIES)
        .then((resp) => {
            store.dispatch(adminCategoriesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(adminCategoriesFetchFail(err));
        });
};

export const fetchSettingsFromNav = () => {
    return axios
        .get(ADMIN_SETTINGS_FETCH_NAV)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            return err;
        });
};

export const fetchAdminSettingsTypes = () => {
    store.dispatch(adminTypesFetchStart());
    axios
        .get(ADMIN_SETTINGS_TYPES)
        .then((resp) => {
            store.dispatch(adminTypesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(adminTypesFetchFail(err));
        });
};

export const fetchAdminSettingsColors = () => {
    store.dispatch(adminColorsFetchStart());
    axios
        .get(ADMIN_SETTINGS_COLORS)
        .then((resp) => {
            store.dispatch(adminColorsFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(adminColorsFetchFail(err));
        });
};

export const fetchAdminSettingsVolumes = () => {
    store.dispatch(adminVolumesFetchStart());
    axios
        .get(ADMIN_SETTINGS_VOLUMES)
        .then((resp) => {
            store.dispatch(adminVolumesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(adminVolumesFetchFail(err));
        });
};

export const fetchAdminSettingsProcessFees = () => {
    store.dispatch(adminProcessFeesFetchStart());
    axios
        .get(ADMIN_SETTINGS_PROCESS_FEES)
        .then((resp) => {
            store.dispatch(adminProcessFeesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(adminProcessFeesFetchFail(err));
        });
};
