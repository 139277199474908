import * as actionTypes from "./actionTypes";


export const companyProfileFetchStart = () => {
    return {
        type: actionTypes.COMPANY_PROFILE_FETCH_START,
    }
};

export const companyProfileFetchSuccess = (profile) => {
    return {
        type: actionTypes.COMPANY_PROFILE_FETCH_SUCCESS,
        profile: profile
    }
};

export const companyProfileFetchFail = (err) => {
    return {
        type: actionTypes.COMPANY_PROFILE_FETCH_FAIL,
        error: err
    }
};

export const companyProfileUpdate = (key, value) => {
    return {
        type: actionTypes.COMPANY_PROFILE_UPDATE,
        key: key,
        value: value,
    }
};

export const personalProfileFetchStart = () => {
    return {
        type: actionTypes.PERSONAL_PROFILE_FETCH_START,
    }
};

export const personalProfileFetchSuccess = (profile) => {
    return {
        type: actionTypes.PERSONAL_PROFILE_FETCH_SUCCESS,
        profile: profile
    }
};

export const personalProfileFetchFail = (err) => {
    return {
        type: actionTypes.PERSONAL_PROFILE_FETCH_FAIL,
        error: err
    }
};

export const personalProfileUpdate = (key, value) => {
    return {
        type: actionTypes.PERSONAL_PROFILE_UPDATE,
        key: key,
        value: value,
    }
};

export const representationOptionsFetchStart = () => {
    return {
        type: actionTypes.REPRESENTATION_OPTIONS_FETCH_START,
    }
};

export const representationOptionsFetchSuccess = (options) => {
    return {
        type: actionTypes.REPRESENTATION_OPTIONS_FETCH_SUCCESS,
        representationOptions: options
    }
};

export const representationOptionsFetchFail = (err) => {
    return {
        type: actionTypes.REPRESENTATION_OPTIONS_FETCH_FAIL,
        error: err
    }
};

export const contractsFetchStart = () => {
    return {
        type: actionTypes.CONTRACTS_FETCH_START,
    }
};

export const contractsFetchSuccess = (contracts) => {
    return {
        type: actionTypes.CONTRACTS_FETCH_SUCCESS,
        contracts: contracts
    }
};

export const contractsFetchFail = (err) => {
    return {
        type: actionTypes.CONTRACTS_FETCH_FAIL,
        error: err
    }
};

export const usersFetchStart = () => {
    return {
        type: actionTypes.FETCH_USERS_START
    }
};

export const usersFetchSuccess = (users) => {
    return {
        type: actionTypes.FETCH_USERS_SUCCESS,
        users: users
    }
};

export const usersFetchFail = (err) => {
    return {
        type: actionTypes.FETCH_USERS_FAIL,
        error: err
    }
};

export const userUpdateStart = () => {
    return {
        type: actionTypes.UPDATE_USER_START
    }
};

export const userUpdateSuccess = (user) => {
    return {
        type: actionTypes.UPDATE_USER_SUCCESS,
        user: user
    }
};

export const userUpdateFail = (err) => {
    return {
        type: actionTypes.UPDATE_USER_FAIL,
        error: err
    }
};

export const userSettingsFetchStart = () => {
    return {
        type: actionTypes.USER_SETTINGS_FETCH_START,
    }
}
export const userSettingsFetchSuccess = (userSettings) => {
    return {
        type: actionTypes.USER_SETTINGS_FETCH_SUCCESS,
        userSettings,
    }
}
export const userSettingsFetchFail = (error) => {
    return {
        type: actionTypes.USER_SETTINGS_FETCH_FAIL,
        error
    }
}
export const userSettingsPutStart = () => {
    return {
        type: actionTypes.USER_SETTINGS_PUT_START,
    }
}
export const userSettingsPutSuccess = (userSettings) => {
    return {
        type: actionTypes.USER_SETTINGS_PUT_SUCCESS,
        userSettings,
    }
}
export const userSettingsPutFail = (error) => {
    return {
        type: actionTypes.USER_SETTINGS_PUT_FAIL,
        error,
    }
}
