import axios from "../../axios";
import store from "redux/store";

import { getServerErrorMessage, getErrorMessageList } from "util/Errors";

import { secondDateIsSameOrAfterFirstDate } from "util/DateTime";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
} from "util/Alerts";

import {
    ADMIN_APPLICATIONS,
    ADMIN_SEND_TO_TESTING,
    ADMING_GENERATE_PACKAGE_APPLICATION_INVOICE,
} from "config/Api";

import {
    adminApplicationsFetchStart,
    adminApplicationsFetchSuccess,
    adminApplicationsFetchFail,
    adminSendToTestingStart,
    adminSendToTestingSuccess,
    adminSendToTestingFail,
} from "redux/actions/admin/applications";
import { PackageConditionValues } from "components/Form/PackageConditions/options";

export function fetchApplications(fetchOnlyIfNull, filters, alertGroup) {
    if (!!fetchOnlyIfNull) {
        const state = store.getState();
        if (state.admin.applications.packagesList !== null) {
            return;
        }
    }
    const filtersToUse = { ...filters };
    store.dispatch(adminApplicationsFetchStart());
    return axios
        .get(ADMIN_APPLICATIONS)
        .then((response) => {
            const filteredPackages = filterApplications(
                response.data,
                filtersToUse
            );
            store.dispatch(
                adminApplicationsFetchSuccess(
                    response.data,
                    filteredPackages,
                    filtersToUse
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminApplicationsFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function sendToTesting(ids, alertGroup, packagesList) {
    store.dispatch(adminSendToTestingStart());
    const sendToTestingIdsData = {
        ids: ids.map((id) => ({ id: id })),
    };

    return axios
        .put(ADMIN_SEND_TO_TESTING, sendToTestingIdsData)
        .then((response) => {
            clearLocalAlerts();
            const errors = response.data.errors;
            let sentToTestingIds = [...ids];
            let failedIds = [];
            if (errors && errors.length) {
                failedIds = errors.map((error) => error.id);
                sentToTestingIds = sentToTestingIds.filter(
                    (id) => !failedIds.includes(id)
                );
            }

            if (sentToTestingIds.length) {
                let successMessage = `Saadetud testimisse: ${sentToTestingIds
                    .map((id) => findPackageEANById(packagesList, id))
                    .join(", ")}.`;
                addNewLocalSuccessAlert(successMessage, alertGroup);
            }
            if (failedIds.length) {
                const failedMessage = getErrorMessageList(
                    errors,
                    (err) =>
                        `${findPackageEANById(packagesList, err.id)} - ${
                            err.reason
                        }`,
                    "Järgnevaid näidiseid ei õnnestunud saata testimisse:",
                    (err) => err.id
                );
                addNewLocalErrorAlert(failedMessage, alertGroup);
            }
            store.dispatch(adminSendToTestingSuccess(sentToTestingIds));
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminSendToTestingFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
        });
}

export function generatePackageApplicationInvoice(packageIds) {
    const endpoint = ADMING_GENERATE_PACKAGE_APPLICATION_INVOICE;
    const data = { ids: packageIds };

    return axios
        .post(endpoint, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
}

function filterApplications(packages, filters) {
    if (!packages || !packages.length || !filters) {
        return packages;
    }

    return packages.filter((pkg) => {
        if (filters.ean && !filterStringIncludes(pkg.ean, filters.ean)) {
            return false;
        }
        if (filters.eanType && pkg.ean_type.value !== filters.eanType.id) {
            return false;
        }
        if (filters.name && !filterStringIncludes(pkg.name, filters.name)) {
            return false;
        }
        //if (filters.client && pkg.client !== filters.client.label) {return false;}
        if (filters.category && pkg.category.value !== filters.category.id) {
            return false;
        }
        if (filters.type && pkg.type.value !== filters.type.id) {
            return false;
        }
        //if (filters.status && pkg.status.value !== filters.status.id) {return false;}
        if (filters.volume && pkg.volume !== filters.volume.value) {
            return false;
        }
        if (filters.weight && pkg.weight !== Number(filters.weight)) {
            return false;
        }
        //if (filters.testResult && pkg.status.value !== filters.testResult.value) {return false;}
        if (
            filters.startDate &&
            !secondDateIsSameOrAfterFirstDate(
                filters.startDate,
                pkg.date,
                "day"
            )
        ) {
            return false;
        }
        if (
            filters.endDate &&
            !secondDateIsSameOrAfterFirstDate(pkg.date, filters.endDate, "day")
        ) {
            return false;
        }

        if (filters.conditions && filters.conditions.length) {
            const conditions = filters.conditions.map(
                (condition) => condition.value
            );
            if (
                conditions.includes(PackageConditionValues.UNSYNCED) &&
                pkg.skip_migration !== true
            ) {
                return false;
            }
            if (
                conditions.includes(
                    PackageConditionValues.UNSUBMITTED_REPORTS
                ) &&
                pkg.unsubmitted_sales_reports === 0
            ) {
                return false;
            }
            if (
                conditions.includes(PackageConditionValues.DEBT) &&
                Number(pkg.debt) <= 0
            ) {
                return false;
            }
        }

        return true;
    });
}

function filterStringIncludes(str, substr) {
    return str.trim().toLowerCase().includes(substr.trim().toLowerCase());
}

function findPackageEANById(packages, id) {
    for (const pkg of packages) {
        if (pkg.id === id) {
            return pkg.ean;
        }
    }
    return `EAN ei leitud (id: ${id})`;
}
