import store from "redux/store";

const contractStates = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    ACTIVE_OR_PENDING: "EITHER",
    NONE: "NONE",
};

export const userTypes = {
    CLIENT_ADMIN: "ADMIN",
    CLIENT_USER: "KASUTAJA",
    EPP: "EPP",
    PARTNER: "PARTNER",
};

export function isAuthenticatedWithUser() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    if (!state.auth.token) {
        return false;
    }

    return true;
}

export function isAuthenticatedWithoutUser() {
    const state = store.getState();

    const user = getUser(state);

    if (state.auth.token && !user) {
        return true;
    } else {
        return false;
    }
}

export function isConnectedToClientWithActiveContract() {
    return isConnectedToClientWithContractState(contractStates.ACTIVE);
}

export function isConnectedToClientWithPendingOrActiveContract() {
    return isConnectedToClientWithContractState(
        contractStates.ACTIVE_OR_PENDING
    );
}

export function isConnectedToClientWithPendingContract() {
    return isConnectedToClientWithContractState(contractStates.PENDING);
}

export function isVerifiedUser() {
    return isVerified();
}

function isVerified() {
    const state = store.getState();
    const user = getUser(state);

    if (isClientMode()) {
        return user.user_type && user.user_type.is_verified;
    } else if (isPartnerMode()) {
        return user.partner_user_type && user.partner_user_type.is_verified;
    } else if (isAdminMode()) {
        return true;
    }
}

function isConnectedToClientWithContractState(contractState) {
    const state = store.getState();

    if (!isClientMode()) {
        return false;
    }

    const user = getUser(state);
    if (!user) {
        return false;
    }
    const clientId = user.active_client_id;

    if (!clientId || !user.clients || !user.clients.length) {
        return false;
    }

    for (const client of user.clients) {
        if (client.id === clientId && client.active_client) {
            if (contractState === contractStates.ACTIVE) {
                return (
                    client.is_valid_contract && !client.is_unapproved_contract
                );
            } else if (contractState === contractStates.PENDING) {
                return (
                    client.is_unapproved_contract && !client.is_valid_contract
                );
            } else if (contractState === contractStates.ACTIVE_OR_PENDING) {
                return (
                    client.is_unapproved_contract || client.is_valid_contract
                );
            } else if (contractStates === contractStates.NONE) {
                return (
                    !client.is_unapproved_contract && !client.is_valid_contract
                );
            }
        }
    }

    return false;
}

export function isNotConnectedToClientPartnerOrEpp() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return !user.active_client_id && !user.in_partner_mode && !user.in_epp_mode;
}

export function isUserClientAdmin() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return (
        isClientMode() &&
        user.active_client_id &&
        user.user_type &&
        user.user_type.user_type === "ADMIN"
    );
}

export function getUser(state = null) {
    state = state || store.getState();
    if (!state || !state.auth || !state.auth.user || !state.auth.user.user) {
        return null;
    }
    return state.auth.user;
}

export function isClientMode() {
    const state = store.getState();
    const user = getUser(state);
    if (!user) {
        return false;
    }

    return !user.in_partner_mode && !user.in_epp_mode;
}

export function isPartnerMode() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return user.in_partner_mode && !user.in_epp_mode;
}

export function isAdminMode() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return user.in_epp_mode && !user.in_partner_mode;
}

export function hasAdminMode() {
    const state = store.getState();

    const user = getUser(state);
    if (!user) {
        return false;
    }

    return user.epp_user_type !== null;
}
