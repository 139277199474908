import React from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { withRouter, Link, NavLink } from "react-router-dom";
import urls, { getSaleReportUrl } from "config/urls";
import "./Dashboard.scss";
import { ReactComponent as Plus } from "assets/icons/plus.svg";

import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell,
} from "recharts";
import {
    fetchLastPackages,
    fetchLastReports,
    fetchStatistics,
} from "util/Dashboard";
import { isConnectedToClientWithActiveContract } from "util/Access";
import { connect } from "react-redux";
import StatusCell from "../Table/Cell/Status/StatusCell";
import { getSaleStatusType } from "../Reports/Sales/helpers/Options";
import WrappedLoader from "components/Loader/WrappedLoader";
import moment from "moment";
import { DATE_FORMAT } from "util/DateTime";
import { withTranslation } from "react-i18next";
import { currencyFormatter } from "util/General";

class Dashboard extends React.Component {
    componentDidMount() {
        // User has contract
        if (isConnectedToClientWithActiveContract()) {
            fetchLastPackages();
            fetchLastReports();
            fetchStatistics();
        }
    }

    render() {
        const { t } = this.props;

        const isClientWithActiveContract = isConnectedToClientWithActiveContract()

        return (
            <>
                <Row className="mb-4">
                    <Col sm={12} md={6}>
                        <Card className="card-half-page mb-2">
                            {this.props.lastPackagesLoading ? (
                                <WrappedLoader />
                            ) : (
                                    <>
                                        <CardHeader className="card-header pb-0">
                                            <Row>
                                                <Col md={12}>
                                                    <span className="bold-19">
                                                        {t(
                                                            "dashboard.packages.latestPackages",
                                                            "Viimati lisatud pakendid"
                                                        )}
                                                    </span>
                                                    <NavLink
                                                        to={urls.PACKAGES}
                                                        tag={Link}
                                                        className="regular-12 color-info float-right"
                                                    >
                                                        {t(
                                                            "dashboard.packages.allPackages",
                                                            "Kõik pakendid"
                                                        )}{" "}
                                                    -{" "}
                                                        {
                                                            this.props.lastPackages
                                                                .all_packages_count
                                                        }
                                                    </NavLink>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={12}>
                                                    <p className="semibold-12">
                                                        {t(
                                                            "dashboard.packages.notRegisteredPackages",
                                                            "Registreerimata pakendid"
                                                        )}{" "}
                                                    -{" "}
                                                        {
                                                            this.props.lastPackages
                                                                .not_registered_packages
                                                        }
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="card-body pt-0">
                                            {isClientWithActiveContract &&
                                                <Row className="add-new-package">
                                                    <Col>
                                                        <span className="color-gray-light">
                                                            {t(
                                                                "dashboard.packages.registerNewPackage",
                                                                "Registreeri uus pakend"
                                                            )}
                                                        </span>
                                                        <NavLink
                                                            to={urls.ADD_PACKAGES}
                                                            tag={Link}
                                                        >
                                                            <Plus className="float-right" />
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                            }

                                            <div>
                                                {this.props.lastPackages.packages.map(
                                                    (item, index) => {
                                                        return (
                                                            <Row
                                                                key={index}
                                                                className="package-row"
                                                            >
                                                                <Col md={12}>
                                                                    <span className="semibold-14">
                                                                        {item.name}{" "}
                                                                    -{" "}
                                                                        {moment(
                                                                            item.created_at
                                                                        ).format(
                                                                            DATE_FORMAT
                                                                        )}
                                                                    </span>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </CardBody>
                                    </>
                                )}
                        </Card>
                    </Col>
                    <Col sm={12} md={6}>
                        <Card className="card-half-page mb-2">
                            {this.props.lastReportsLoading ? (
                                <WrappedLoader />
                            ) : (
                                    <>
                                        <CardHeader className="card-header pb-0">
                                            <Row>
                                                <Col md={12}>
                                                    <span className="bold-19">
                                                        {t(
                                                            "dashboard.reports.latestSalesReports",
                                                            "Viimati lisatud müügiaruanded"
                                                        )}
                                                    </span>
                                                    <NavLink
                                                        to={urls.REPORTS_SALES}
                                                        tag={Link}
                                                        className="regular-12 color-info float-right"
                                                    >
                                                        {t(
                                                            "dashboard.reports.allSalesReports",
                                                            "Kõik müügiaruanded"
                                                        )}{" "}
                                                    -{" "}
                                                        {
                                                            this.props.lastReports
                                                                .all_reports_count
                                                        }
                                                    </NavLink>
                                                </Col>
                                            </Row>
                                            <Row className="mt-2">
                                                <Col md={12}>
                                                    <p className="semibold-12">
                                                        {t(
                                                            "dashboard.reports.notSubmittedSalesReports",
                                                            "Esitamata müügiaruanded"
                                                        )}{" "}
                                                    -{" "}
                                                        {this.props.lastReports
                                                            .not_submitted_reports_count >
                                                            0 ? (
                                                                <span className="color-error">
                                                                    {
                                                                        this.props
                                                                            .lastReports
                                                                            .not_submitted_reports_count
                                                                    }
                                                                </span>
                                                            ) : (
                                                                <span>
                                                                    {
                                                                        this.props
                                                                            .lastReports
                                                                            .not_submitted_reports_count
                                                                    }
                                                                </span>
                                                            )}
                                                    </p>
                                                </Col>
                                            </Row>
                                        </CardHeader>
                                        <CardBody className="card-body pt-0">
                                            {isClientWithActiveContract &&
                                                <Row className="add-new-package invisible">
                                                    <Col>
                                                        <NavLink
                                                            to={urls.REPORTS_SALES}
                                                            tag={Link}
                                                        >
                                                            <Plus className="float-right" />
                                                        </NavLink>
                                                    </Col>
                                                </Row>
                                            }
                                            <div>
                                                {this.props.lastReports.latest_reports.map(
                                                    (report, i) => {
                                                        return (
                                                            <Row
                                                                key={
                                                                    i +
                                                                    "-" +
                                                                    report.period
                                                                }
                                                                className="latest-report-row"
                                                                onClick={() => this.props.history.push(getSaleReportUrl(report.id))}
                                                            >
                                                                <Col md={12}>
                                                                    <span className="semibold-14">
                                                                        {
                                                                            report.period
                                                                        }
                                                                    </span>
                                                                    <StatusCell
                                                                        status={
                                                                            report.status.label
                                                                        }
                                                                        type={getSaleStatusType(
                                                                            report.status.value
                                                                        )}
                                                                        className="float-right"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </CardBody>
                                    </>
                                )}
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card className="card-full-page">
                            <CardBody className="p-0">
                                {this.props.statisticsLoading ? (
                                    <WrappedLoader />
                                ) : (
                                        <Row>
                                            <Col md={9} className="chart-column">
                                                <ResponsiveContainer className="recharts-container">
                                                    <BarChart
                                                        data={
                                                            this.props.statistics
                                                                .months
                                                        }
                                                        margin={{
                                                            top: 20,
                                                            right: 30,
                                                            left: 20,
                                                            bottom: 5,
                                                        }}
                                                    >
                                                        <CartesianGrid
                                                            vertical={false}
                                                        />
                                                        <XAxis dataKey="date" />
                                                        <YAxis />
                                                        <Tooltip cursor={false} />
                                                        <Legend
                                                            verticalAlign="top"
                                                            height={36}
                                                        />
                                                        <Bar
                                                            name={t(
                                                                "dashboard.statistics.totalDeposit",
                                                                "Tagatisraha kokku"
                                                            )}
                                                            dataKey="deposit_total"
                                                            stackId="a"
                                                            fill="#46A72A"
                                                            barSize={30}
                                                        >
                                                            {this.props.statistics.months.map(
                                                                (entry, index) => (
                                                                    <Cell
                                                                        key={index}
                                                                        fill={
                                                                            entry.current_year
                                                                                ? "#46A72A"
                                                                                : "rgba(70, 167, 42, 0.5)"
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Bar>
                                                        {/* radius={[30, 30, 0, 0]} */}
                                                        <Bar
                                                            name={t(
                                                                "dashboard.statistics.totalProcessFee",
                                                                "Käitlustasu"
                                                            )}
                                                            dataKey="process_fee_total"
                                                            stackId="a"
                                                            fill="#D0EDC9"
                                                            barSize={30}
                                                        >
                                                            {this.props.statistics.months.map(
                                                                (entry, index) => (
                                                                    <Cell
                                                                        key={index}
                                                                        fill={
                                                                            entry.current_year
                                                                                ? "#D0EDC9"
                                                                                : "rgba(181,235,171, 0.5)"
                                                                        }
                                                                    />
                                                                )
                                                            )}
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </Col>
                                            <Col md={3}>
                                                <Row className="statistics-row mx-0 ml-md-n3">
                                                    <Col
                                                        md={12}
                                                        className="flex-column text-center"
                                                    >
                                                        <div>
                                                            <span className="semibold-16 color-gray">
                                                                {t(
                                                                    "dashboard.statistics.overdueInvoices",
                                                                    "Üle tähtaja arved"
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="semibold-19 color-error">
                                                                {
                                                                    this.props.statistics.overdue_invoices === null ?
                                                                        "-"
                                                                        :
                                                                        currencyFormatter.format(this.props.statistics.overdue_invoices)
                                                                }
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        md={12}
                                                        className="flex-column text-center"
                                                    >
                                                        <div>
                                                            <span className="semibold-16 color-gray">
                                                                {t(
                                                                    "dashboard.statistics.invoiceBalance",
                                                                    "Jooksev arvete saldo"
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="semibold-19 color-black">
                                                                {
                                                                    this.props.statistics.invoice_balance === null ?
                                                                        "-"
                                                                        :
                                                                        currencyFormatter.format(this.props.statistics.invoice_balance)
                                                                }
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        md={12}
                                                        className="flex-column text-center"
                                                    >
                                                        <div>
                                                            <span className="semibold-16 color-gray">
                                                                {t(
                                                                    "dashboard.statistics.numberOfPackages",
                                                                    "Aktiivsete pakendite arv"
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="semibold-19 color-black">
                                                                {
                                                                    this.props
                                                                        .statistics
                                                                        .packages_total
                                                                }
                                                            </span>
                                                        </div>
                                                    </Col>
                                                    <Col
                                                        md={12}
                                                        className="flex-column text-center"
                                                    >
                                                        <div>
                                                            <span className="semibold-16 color-gray">
                                                                {t(
                                                                    "dashboard.statistics.biggestSale",
                                                                    "Suurim müük"
                                                                )}
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <span className="semibold-19 color-black">
                                                                {
                                                                    this.props
                                                                        .statistics
                                                                        .best_month
                                                                        .month
                                                                }
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    )}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        contracts: state.profile.contracts,
        lastPackages: state.dashboard.lastPackages,
        lastReports: state.dashboard.lastReports,
        statistics: state.dashboard.statistics,
        lastPackagesLoading: state.dashboard.lastPackagesLoading,
        lastReportsLoading: state.dashboard.lastReportsLoading,
        statisticsLoading: state.dashboard.statisticsLoading,
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation("common")(Dashboard)));
