import axios from "../axios";
import store from "redux/store";

import { getServerErrorMessage } from "util/Errors";
import {
    COMPANIES,
    ALL_CLIENTS,
    STANDARD_COMMENTS,
    LOCATIONS,
    LANGUAGE,
} from "../config/Api";
import {
    allClientsFetchStart,
    allClientsFetchSuccess,
    allClientsFetchFail,
    standardCommentsFetchStart,
    standardCommentsFetchSuccess,
    standardCommentsFetchFail,
    locationsFetchStart,
    locationsFetchSuccess,
    locationsFetchFail,
} from "../redux/actions/general";
import { ENABLE_TEST_VAT_PERCENTAGE } from "config/envVariables";

const numberFormatLocale = "et";
const currencyBaseOptions = { style: "currency", currency: "EUR" };

export const currencyFormatter = Intl.NumberFormat(numberFormatLocale, {
    ...currencyBaseOptions,
    minimumFractionDigits: 2,
});

export const depositCurrencyFormatter = Intl.NumberFormat(numberFormatLocale, {
    ...currencyBaseOptions,
    minimumFractionDigits: 2,
});

export const processFeeCostCurrencyFormatter = Intl.NumberFormat(
    numberFormatLocale,
    { ...currencyBaseOptions, minimumFractionDigits: 4 }
);

export const languageTypes = {
    ET: "et",
    EN: "en",
};

export const testStatuses = {
    OK: { value: 1, label: "OK" },
    NOK: { value: 2, label: "NOK" },
    NA: { value: 3, label: "N/A" },
};


export function isNumeric(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
}

export function normalizeStringNumber(strNum) {
    return strNum.replace(",", ".").replace(/[^0-9.-]/g, "");
}

export function changeLanguage(userTypeId, lngCode) {
    const endpoint = LANGUAGE.replace("<user_type_id>", userTypeId);
    const data = { language: lngCode };
    return axios
        .put(endpoint, data)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export function fetchCompanies(name) {
    return axios
        .get(COMPANIES + name)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export function fetchAllClients(allClients) {
    if (allClients && allClients.length) {
        return;
    }
    store.dispatch(allClientsFetchStart());
    return axios
        .get(ALL_CLIENTS)
        .then((response) => {
            store.dispatch(allClientsFetchSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(allClientsFetchFail(errorMessage));
            throw Error(errorMessage);
        });
}

export function fetchStandardComments(standardComments) {
    if (standardComments && standardComments.length) {
        return;
    }
    store.dispatch(standardCommentsFetchStart());
    return axios
        .get(STANDARD_COMMENTS)
        .then((response) => {
            store.dispatch(standardCommentsFetchSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(standardCommentsFetchFail(errorMessage));
            throw Error(errorMessage);
        });
}

export function fetchLocations(locations) {
    if (locations && locations.length) {
        return;
    }
    store.dispatch(locationsFetchStart());
    return axios
        .get(LOCATIONS)
        .then((response) => {
            store.dispatch(locationsFetchSuccess(response.data));
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(locationsFetchFail(errorMessage));
            throw Error(errorMessage);
        });
}

export function getPageCount(dataSize, sizePerPage) {
    if (sizePerPage === 0) {
        return 1;
    }

    return Math.ceil(dataSize / sizePerPage);
}


export function getVatPercentageFraction(date) {
    // Mirroring backend get_vat_amount dates logic
    if (date >= new Date("2024-01-01")) {
        return 0.22
    }
    // Should not run in production
    if (ENABLE_TEST_VAT_PERCENTAGE) {
        if (date > new Date("2023-10-01")) {
            return 0.5
        }
    }
    return 0.2
}