import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import {
    Row,
    Col,
    Button,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";

import { ReactComponent as LeftIcon } from "assets/icons/arrow-left.svg";
import { ReactComponent as RightIcon } from "assets/icons/arrow-right.svg";

import "./CustomPagination.scss";

class CustomPagination extends React.Component {
    static propTypes = {
        page: PropTypes.number.isRequired,
        sizePerPage: PropTypes.number.isRequired,
        dataSize: PropTypes.number.isRequired,
        sizePerPageList: PropTypes.array.isRequired,
        onSizePerPageChange: PropTypes.func.isRequired,
        onPageChange: PropTypes.func.isRequired,
        unknownSizePerPageText: PropTypes.string,
    };

    render() {
        return (
            <Row className="align-items-center my-1">
                <Col className="text-right pl-0">
                    {this.renderSizePerPageList()}
                </Col>
                <Col className="col-auto px-2">{this.renderTotals()}</Col>
                <Col className="col-auto px-0">
                    <Button
                        color="link"
                        className="m-1 p-0 regular-14 text-muted"
                        onClick={this.onPrevPage}
                        disabled={this.isPrevPageDisabled()}
                    >
                        <LeftIcon className="custom-pagination-icon" />
                    </Button>
                    <Button
                        color="link"
                        className="m-1 p-0 regular-14 text-muted"
                        onClick={this.onNextPage}
                        disabled={this.isNextPageDisabled()}
                    >
                        <RightIcon className="custom-pagination-icon" />
                    </Button>
                </Col>
            </Row>
        );
    }

    renderTotals() {
        const totalSize = this.props.dataSize || 0;
        const firstPageRowIndex = this.getPageFirstIndex();
        const lastPageRowIndex = this.getPageLastIndex();

        return (
            <span className="regular-14 text-muted">
                {`${firstPageRowIndex}-${lastPageRowIndex} of ${totalSize}`}
            </span>
        );
    }

    renderSizePerPageList() {
        const selectedAndDropdown = (
            <UncontrolledDropdown className="size-per-page-dropdown">
                <DropdownToggle
                    caret
                    tag="span"
                    className="selected-size-per-page regular-14"
                >
                    {this.getSizePerPageText()}
                </DropdownToggle>
                <DropdownMenu>
                    {this.props.sizePerPageList.map((option, index) => (
                        <DropdownItem
                            key={index}
                            tag="span"
                            className="regular-14"
                            onClick={() => this.onSizePerPageChange(option)}
                        >
                            {option.text}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        );

        return (
            <>
                <span className="regular-14 text-muted mr-2">
                    {this.props.t("sizePerPage")}:
                </span>
                <div className="float-right">{selectedAndDropdown}</div>
            </>
        );
    }

    getPageFirstIndex = () => {
        const totalSize = this.props.dataSize || 0;
        const currentPage = this.props.page;
        const sizePerPage = this.props.sizePerPage || totalSize;

        const firstPageRowIndex =
            totalSize === 0 ? 0 : 1 + sizePerPage * (currentPage - 1);
        return firstPageRowIndex;
    };

    getPageLastIndex = () => {
        const totalSize = this.props.dataSize || 0;
        const currentPage = this.props.page;
        const sizePerPage = this.props.sizePerPage || totalSize;

        const lastPageRowIndex = Math.min(currentPage * sizePerPage, totalSize);
        return lastPageRowIndex;
    };

    getSizePerPageText = () => {
        const sizePerPage = this.props.sizePerPage;
        for (const option of this.props.sizePerPageList) {
            if (sizePerPage === option.value) {
                return option.text;
            }
        }

        return this.props.unknownSizePerPageText || sizePerPage;
    };

    isPrevPageDisabled = () => {
        return this.props.page <= 1;
    };

    isNextPageDisabled = () => {
        return (
            this.props.sizePerPage === 0 ||
            this.props.dataSize <= this.props.page * this.props.sizePerPage
        );
    };

    onPrevPage = () => {
        this.props.onPageChange(this.props.page - 1, this.props.sizePerPage);
    };

    onNextPage = () => {
        this.props.onPageChange(this.props.page + 1, this.props.sizePerPage);
    };

    onSizePerPageChange = (newSelected) => {
        const newSizePerPage = newSelected.value;
        if (newSizePerPage === 0) {
            this.props.onSizePerPageChange(newSizePerPage, 1);
        } else {
            const currentStartingIndex = this.getPageFirstIndex();
            const newPage = Math.max(
                1,
                Math.ceil(currentStartingIndex / newSizePerPage)
            );
            this.props.onSizePerPageChange(newSelected.value, newPage);
        }
    };
}

export default withTranslation()(CustomPagination);
