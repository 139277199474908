import axios from "../axios";
import store from "redux/store";
import {
    APPLICATION,
    APPLICATION_IN_PROGRESS,
    EAN_TYPES,
    PACKAGE,
    PACKAGE_CATEGORIES,
    PACKAGE_COLORS,
    PACKAGE_LIST,
    PACKAGE_STATUSES,
    PACKAGE_TYPES,
    PACKAGE_VOLUMES,
    PACKAGE_TEST_RESULTS,
} from "../config/Api";
import {
    categoriesFetchFail,
    categoriesFetchStart,
    categoriesFetchSuccess,
    eanTypesFetchFail,
    eanTypesFetchStart,
    eanTypesFetchSuccess,
    packagesFetchFail,
    packagesFetchStart,
    packagesFetchSuccess,
    packageTypesFetchStart,
    packageTypesFetchSuccess,
    packageTypesFetchFail,
    packageColorsFetchStart,
    packageColorsFetchSuccess,
    packageColorsFetchFail,
    packageVolumesFetchStart,
    packageVolumesFetchSuccess,
    packageVolumesFetchFail,
    packageStatusesFetchStart,
    packageStatusesFetchSuccess,
    packageStatusesFetchFail,
    packagePostStart,
    packagePostSuccess,
    packagePostFail,
    packageTestResultsFetchStart,
    packageTestResultsFetchSuccess,
    packageTestResultsFetchFail,
} from "../redux/actions/packages";
import { statusTypes } from "components/Table/Cell/Status/StatusCell";
import { getServerErrorMessage } from "util/Errors";
import { normalizeStringNumber } from "util/General";

export const ADD_PACKAGE_ALERT_GROUP = "addPackageModalGroup";

export const colors = {
    COLORLESS: 1,
};

export const packageStatuses = {
    NOT_APPLIED_STATUS: 1,
    APPLIED_STATUS: 2,
    IN_TESTING_STATUS: 3,
    WAITING_STATUS: 4,
    UNCONFIRMED: 10,
    APPROVED_STATUS: 5,
    NOT_APPROVED: 6,
    TO_BE_DELETED: 7,
    SALE_ENDED: 9,
    PHASE_OUT: 11,
    LOCAL_DELETE: 12
};

export const packageStatusTypes = {
    [packageStatuses.NOT_APPLIED_STATUS]: statusTypes.DEFAULT,
    [packageStatuses.APPLIED_STATUS]: statusTypes.WARNING,
    [packageStatuses.IN_TESTING_STATUS]: statusTypes.INFO,
    [packageStatuses.UNCONFIRMED]: statusTypes.WARNING,
    [packageStatuses.WAITING_STATUS]: statusTypes.ERROR,
    [packageStatuses.APPROVED_STATUS]: statusTypes.SUCCESS,
    [packageStatuses.NOT_APPROVED]: statusTypes.ERROR,
    [packageStatuses.TO_BE_DELETED]: statusTypes.ERROR,
    [packageStatuses.SALE_ENDED]: statusTypes.DEFAULT,
    [packageStatuses.LOCAL_DELETE]: statusTypes.DEFAULT
};

export const packageStatusTypeLists = {
    TESTING_STATUSES: [
        packageStatuses.IN_TESTING_STATUS,
        packageStatuses.UNCONFIRMED,
        packageStatuses.WAITING_STATUS
    ],
    RETESTING_STATUSES: [
        packageStatuses.APPROVED_STATUS,
        packageStatuses.NOT_APPROVED,
        packageStatuses.SALE_ENDED
    ],
}

export function fetchPackagesList(clientId) {
    store.dispatch(packagesFetchStart());
    return axios
        .get(PACKAGE_LIST)
        .then((resp) => {
            store.dispatch(packagesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(packagesFetchFail(err));
        });
}

export function fetchEanTypes(eanTypes) {
    if (eanTypes && eanTypes.length) {
        return;
    }

    store.dispatch(eanTypesFetchStart());
    return axios
        .get(EAN_TYPES)
        .then((resp) => {
            store.dispatch(eanTypesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(eanTypesFetchFail(err));
        });
}

export function fetchCategories(categories) {
    if (categories && categories.length) {
        return;
    }
    store.dispatch(categoriesFetchStart());
    return axios
        .get(PACKAGE_CATEGORIES)
        .then((resp) => {
            store.dispatch(categoriesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(categoriesFetchFail(err));
        });
}

export function fetchPackageTypes(packageTypes) {
    if (packageTypes && packageTypes.length) {
        return;
    }
    store.dispatch(packageTypesFetchStart());
    return axios
        .get(PACKAGE_TYPES)
        .then((resp) => {
            store.dispatch(packageTypesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(packageTypesFetchFail(err));
        });
}

export function fetchColors(colors) {
    if (colors && colors.length) {
        return;
    }
    store.dispatch(packageColorsFetchStart());
    return axios
        .get(PACKAGE_COLORS)
        .then((resp) => {
            store.dispatch(packageColorsFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(packageColorsFetchFail(err));
        });
}

export function fetchPackageVolumes(packageVolumes) {
    if (packageVolumes && packageVolumes.length) {
        return;
    }
    store.dispatch(packageVolumesFetchStart());
    return axios
        .get(PACKAGE_VOLUMES)
        .then((resp) => {
            store.dispatch(packageVolumesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(packageVolumesFetchFail());
        });
}

export function fetchPackageVolume(packageTypeId) {
    return axios
        .get(PACKAGE_VOLUMES + packageTypeId + "/")
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function fetchPackageStatuses(packageStatuses) {
    if (packageStatuses && packageStatuses.length) {
        return;
    }
    store.dispatch(packageStatusesFetchStart());
    return axios
        .get(PACKAGE_STATUSES)
        .then((resp) => {
            store.dispatch(packageStatusesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(packageStatusesFetchFail());
        });
}

export function fetchPackageTestResults(packageTestResults) {
    if (packageTestResults && packageTestResults.length) {
        return;
    }
    store.dispatch(packageTestResultsFetchStart());
    return axios
        .get(PACKAGE_TEST_RESULTS)
        .then((resp) => {
            store.dispatch(packageTestResultsFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(packageTestResultsFetchFail(err));
        });
}

export function addNewPackage(
    client,
    ean,
    eanType,
    packageCategory,
    packageType,
    packageVolume,
    packageWeight,
    packageColor,
    productName,
    comment = ""
) {
    store.dispatch(packagePostStart());
    return axios
        .post(PACKAGE, {
            client: client,
            ean: ean,
            ean_type: eanType || "",
            package_category: packageCategory || "",
            package_type: packageType || "",
            volume: packageVolume || "",
            weight: normalizeStringNumber(packageWeight),
            color: packageColor || "",
            name: productName,
            comment: comment,
        })
        .then(() => {
            store.dispatch(packagePostSuccess());
        })
        .catch((err) => {
            store.dispatch(packagePostFail(err));

            throw err;
        });
}

export function submitAddPackageApplication(packages) {
    return axios
        .post(APPLICATION, {
            packages_list: packages,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export function removePackage(packageId) {
    return axios
        .delete(PACKAGE + packageId + "/", {
            package_id: packageId,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export function fetchPendingApplication() {
    return axios
        .get(APPLICATION_IN_PROGRESS)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export function isValidColorForPackageType(colorId, packageTypeId) {
    const ONLY_COLORLESS_PACKAGE_TYPE_IDS = [3, 4];

    if (
        ONLY_COLORLESS_PACKAGE_TYPE_IDS.includes(packageTypeId) &&
        colorId !== colors.COLORLESS
    ) {
        return false;
    }

    return true;
}
