import axios from "../../axios";
import store from "redux/store";
import moment from "moment";

import { getServerErrorMessage } from "util/Errors";
import { PackageConditionValues } from "components/Form/PackageConditions/options";

import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

import {
    ADMIN_TESTING_PACKAGES,
    ADMIN_SEND_TO_TESTING,
    ADMIN_CONFIRM_PACKAGE_TEST,
    ADMIN_ACTIVATE_PACKAGES,
} from "config/Api";

import {
    adminTestingPackagesFetchStart,
    adminTestingPackagesFetchSuccess,
    adminTestingPackagesFetchFail,
    adminTestingSendToRetestingStart,
    adminTestingSendToRetestingSuccess,
    adminTestingSendToRetestingFail,
    adminTestingActivatePackagesStart,
    adminTestingActivatePackagesSuccess,
    adminTestingActivatePackagesFail,
    adminTestingConfirmPackageTestStart,
    adminTestingConfirmPackageTestSuccess,
    adminTestingConfirmPackageTestFail,
} from "redux/actions/admin/testing";

export function fetchTestingPackages(fetchOnlyIfNull, filters, alertGroup) {
    if (!!fetchOnlyIfNull) {
        const state = store.getState();
        if (state.admin.testing.packagesList !== null) {
            return;
        }
    }
    const filtersToUse = { ...filters };
    store.dispatch(adminTestingPackagesFetchStart());
    return axios
        .get(ADMIN_TESTING_PACKAGES)
        .then((response) => {
            const filteredPackages = filterApplications(
                response.data,
                filtersToUse
            );
            store.dispatch(
                adminTestingPackagesFetchSuccess(
                    response.data,
                    filteredPackages,
                    filtersToUse
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminTestingPackagesFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function sendToRetesting(ids) {
    store.dispatch(adminTestingSendToRetestingStart());
    const sendToTestingIdsData = {
        ids: ids.map((id) => ({ id: id })),
    };

    return axios
        .put(ADMIN_SEND_TO_TESTING, sendToTestingIdsData)
        .then((response) => {
            const errors = response.data.errors;
            let sentToTestingIds = [...ids];
            let failedIds = [];
            if (errors && errors.length) {
                failedIds = errors.map((error) => error.id);
                sentToTestingIds = sentToTestingIds.filter(
                    (id) => !failedIds.includes(id)
                );
            }
            store.dispatch(
                adminTestingSendToRetestingSuccess(sentToTestingIds)
            );
            return response;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminTestingSendToRetestingFail(errorMessage));
            throw Error(errorMessage);
        });
}

export function activatePackages(ids, startDate, newLocation) {
    const data = {
        ids: ids,
        start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
        location: newLocation,
    };

    store.dispatch(adminTestingActivatePackagesStart());
    return axios
        .post(ADMIN_ACTIVATE_PACKAGES, data)
        .then((response) => {
            clearLocalAlerts();

            const successIds = response.data.success.map((x) => x.id);

            store.dispatch(adminTestingActivatePackagesSuccess(successIds));
            return response;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminTestingActivatePackagesFail(errorMessage));

            throw Error(errorMessage);
        });
}

function filterApplications(packages, filters) {
    if (!packages || !packages.length || !filters) {
        return packages;
    }

    return packages.filter((pkg) => {
        if (filters.ean && !filterStringIncludes(pkg.ean, filters.ean)) {
            return false;
        }
        if (filters.name && !filterStringIncludes(pkg.name, filters.name)) {
            return false;
        }
        if (filters.client && pkg.client.id !== filters.client.value) {
            return false;
        }
        if (filters.category && pkg.category.value !== filters.category.id) {
            return false;
        }
        if (filters.type && pkg.type.value !== filters.type.id) {
            return false;
        }
        if (filters.status && pkg.status.value !== filters.status.id) {
            return false;
        }
        if (filters.debt && pkg.debt !== filters.debt.value) {
            return false;
        }
        //if (filters.volume && pkg.volume !== filters.volume.value) { return false; }
        if (
            filters.testResult &&
            pkg.last_test.value !== filters.testResult.value
        )
            return false;
        //if (filters.startDate && !secondDateIsSameOrAfterFirstDate(filters.startDate, pkg.created_at, "day")) { return false; }
        //if (filters.endDate && !secondDateIsSameOrAfterFirstDate(pkg.created_at, filters.endDate, "day")) { return false; }

        if (filters.conditions && filters.conditions.length) {
            const conditions = filters.conditions.map(
                (condition) => condition.value
            );
            if (
                conditions.includes(PackageConditionValues.UNSYNCED) &&
                pkg.skip_migration !== true
            ) {
                return false;
            }
            if (
                conditions.includes(
                    PackageConditionValues.UNSUBMITTED_REPORTS
                ) &&
                pkg.unsubmitted_sales_reports === 0
            ) {
                return false;
            }
            if (
                conditions.includes(PackageConditionValues.DEBT) &&
                Number(pkg.debt) <= 0
            ) {
                return false;
            }
            if (
                conditions.includes(PackageConditionValues.LAST_TEST_HAS_COMMENT) && !pkg.last_test?.has_comment
            ) {
                return false;
            }
            
            if (
                conditions.includes(PackageConditionValues.LAST_TEST_HAS_STANDARD_COMMENT) && !pkg.last_test?.has_standard_comments
            ) {
                return false;
            }
            
        }

        return true;
    });
}

function filterStringIncludes(str, substr) {
    return str.trim().toLowerCase().includes(substr.trim().toLowerCase());
}

export function confirmPackageTests(packageIds, startDate) {
    store.dispatch(adminTestingConfirmPackageTestStart());
    const data = {
        ids: packageIds,
        start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
    };

    return axios
        .post(ADMIN_CONFIRM_PACKAGE_TEST, data)
        .then((response) => {
            const data = response.data;

            const successIds = data
                .filter((result) => result.is_success)
                .map((result) => result.id);
            store.dispatch(adminTestingConfirmPackageTestSuccess(successIds));

            return data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminTestingConfirmPackageTestFail(errorMessage));

            throw Error(errorMessage);
        });
}
