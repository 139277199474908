import XLSX from "xlsx";
import i18n from "i18n";

import {
    parseSalesReportPeriodDate,
    YEAR_MONTH_FORMAT_XLSX,
} from "util/DateTime";
import {
    currencyFormatter,
    depositCurrencyFormatter,
    processFeeCostCurrencyFormatter,
} from "util/General";

export const exportExcelSalesReportDetails = (data) => {
    const { header, details, packages } = data;

    const wb = XLSX.utils.book_new();

    const period = getFormattedSalesReportPeriod(header.period);
    let firstSheetName = period;
    if (details.is_repair) {
        firstSheetName = i18n.t("export.repairSalesReport.firstSheetName", {period});
    }

    const firstSheet = getExtraInfoSheet(header);
    XLSX.utils.sheet_add_aoa(firstSheet, getPackagesRows(packages), {
        origin: "A4",
    });

    if (packages.length === 0) {
        XLSX.utils.sheet_add_aoa(
            firstSheet,
            [[i18n.t("reports.salesReportSubmittedNoDataIndication")]],
            { origin: { r: 4, c: 0 } }
        ); //sheet_add_aoa expects array of arrays.
        firstSheet["!merges"] = [{ s: { r: 4, c: 0 }, e: { r: 4, c: 8 } }]; // e: { r: 4, c: 8 } 8 is headers - 1
    }

    XLSX.utils.book_append_sheet(wb, firstSheet, firstSheetName);

    /* Trigger Download with `writeFile` */
    const fileName = getFileName(header.period, details.is_repair);
    XLSX.writeFile(wb, fileName, {
        compression: true,
    });
};

function getExtraInfoSheet(header) {
    const period = getFormattedSalesReportPeriod(header.period);
    const depositTotal = currencyFormatter.format(header.deposit_total);
    const processFeeTotal = currencyFormatter.format(header.process_fee_total);
    const processFeeTotalVAT = currencyFormatter.format(header.process_fee_vat);
    const toBePaid = currencyFormatter.format(header.to_be_paid);

    const extraInfoRows = [
        [
            i18n.t("reports.clientCode"),
            i18n.t("reports.period"),
            i18n.t("reports.company"),
            i18n.t("reports.packagesTotal"),
            i18n.t("reports.depositFeeTotal"),
            i18n.t("reports.serviceFeeTotal"),
            i18n.t("reports.serviceFeeTaxes"),
            i18n.t("reports.totalAmountToPay"),
        ],
        [
            header.client_code,
            period,
            header.client_name,
            header.packages_total,
            depositTotal,
            processFeeTotal,
            processFeeTotalVAT,
            toBePaid,
        ],
    ];

    const ws = XLSX.utils.aoa_to_sheet(extraInfoRows);

    // Set widths
    const colWidths = [15, 25, 25, 15, 17, 17, 17, 14, 17];
    ws["!cols"] = colWidths.map((x) => ({ width: x }));

    return ws;
}

function getFormattedSalesReportPeriod(period) {
    const periodMoment = parseSalesReportPeriodDate(period);
    if (periodMoment.isValid()) {
        return periodMoment.format(YEAR_MONTH_FORMAT_XLSX);
    }
    return "";
}

function getFileName(period, isRepair) {
    let formattedPeriod = period;
    const periodMoment = parseSalesReportPeriodDate(period);
    if (periodMoment.isValid()) {
        formattedPeriod = periodMoment.format("MM.YYYY");
    }

    if (isRepair) {
        return i18n.t("filename.repairSalesReportDetailsExport", {period: formattedPeriod});
    } else {
        return i18n.t("filename.salesReportDetailsExport", {period: formattedPeriod});
    }
}

function getPackagesRows(packages) {
    const packagesHeaders = [
        i18n.t("reports.filter.ean"),
        i18n.t("reports.filter.product"),
        i18n.t("reports.filter.packageType"),
        i18n.t("reports.filter.packageVolume"),
        i18n.t("reports.filter.deposit"),
        i18n.t("reports.filter.serviceFee"),
        i18n.t("reports.filter.quantity"),
        i18n.t("reports.filter.depositTotal"),
        i18n.t("reports.filter.serviceFeeTotal"),
    ];

    const packagesDataRows = getPackagesDataRows(packages);
    const packagesRows = [packagesHeaders, ...packagesDataRows];
    return packagesRows;
    /*
    const ws = XLSX.utils.aoa_to_sheet(packagesRows, { dateNF: "DD-MM-YYYY" });
    if (packagesDataRows.length === 0) {
        XLSX.utils.sheet_add_aoa(
            ws,
            [["Ei leitud kogustega pakendeid antud perioodile."]],
            { origin: { r: 1, c: 0 } }
        ); //sheet_add_aoa expects array of arrays.
        ws["!merges"] = [
            { s: { r: 1, c: 0 }, e: { r: 1, c: packagesHeaders.length - 1 } },
        ];
    }
    // Set widths
    const colWidths = [18, 25, 25, 20, 20, 10, 15, 10];
    ws["!cols"] = colWidths.map((x) => ({ width: x }));

    return ws;
    */
}

function getPackagesDataRows(packages) {
    return packages.map((pkg) => [
        pkg.EAN,
        pkg.name,
        pkg.package_type,
        pkg.volume,
        depositCurrencyFormatter.format(pkg.deposit),
        processFeeCostCurrencyFormatter.format(pkg.process_fee_base),
        pkg.quantity,
        currencyFormatter.format(pkg.deposit_total),
        currencyFormatter.format(pkg.process_fee),
    ]);
}
