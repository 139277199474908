import axios from "../axios";
import {DETAIL} from "../config/Api";

export function fetchCompanyDetailedInformation(regCode) {
    return axios
        .post(DETAIL, {
            reg_code: regCode
        })
        .then(resp => {
            return resp.data
        })
        .catch(err => {
            throw err;
        });
}