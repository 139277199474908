import * as actionTypes from 'redux/actions/actionTypes';

export const allClientsFetchStart = () => {
    return {
        type: actionTypes.ALL_CLIENTS_FETCH_START,
    }
};

export const allClientsFetchSuccess = (clients) => {
    return {
        type: actionTypes.ALL_CLIENTS_FETCH_SUCCESS,
        clients,
    }
};

export const allClientsFetchFail = (error) => {
    return {
        type: actionTypes.ALL_CLIENTS_FETCH_FAIL,
        error,
    }
};

export const standardCommentsFetchStart = () => {
    return {
        type: actionTypes.STANDARD_COMMENTS_FETCH_START,
    }
};

export const standardCommentsFetchSuccess = (standardComments) => {
    return {
        type: actionTypes.STANDARD_COMMENTS_FETCH_SUCCESS,
        standardComments,
    }
};

export const standardCommentsFetchFail = (error) => {
    return {
        type: actionTypes.STANDARD_COMMENTS_FETCH_FAIL,
        error,
    }
};

export const locationsFetchStart = () => {
    return {
        type: actionTypes.LOCATIONS_FETCH_START,
    }
};

export const locationsFetchSuccess = (locations) => {
    return {
        type: actionTypes.LOCATIONS_FETCH_SUCCESS,
        locations,
    }
};

export const locationsFetchFail = (error) => {
    return {
        type: actionTypes.LOCATIONS_FETCH_FAIL,
        error,
    }
};