import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "components/Packages/PackagesTable/PackagesTable.scss";
import "components/Profile/Users/UsersTable/UsersTable.scss";

import { Card, CardBody, CardHeader } from "reactstrap";
import { connect } from "react-redux";
import { fetchContracts } from "util/Profile";
import DocumentCell from "../../Table/Cell/DocumentCell";
import StatusCell from "../../Table/Cell/Status/StatusCell";
import WrappedLoader from "components/Loader/WrappedLoader";
import { getContractStatusType } from "components/Admin/Contract/helpers/Options";
import { withTranslation } from "react-i18next";

import moment from "moment";
import { DATE_FORMAT } from "util/DateTime";

class Contracts extends React.Component {
    componentDidMount() {
        fetchContracts();
    }

    render() {
        const { t } = this.props;
        return (
            <Card className="users-card">
                <CardHeader className="package-table-card-header">
                    <span className="heading">
                        {t("contracts.title", "Kõik lepingud")}
                    </span>
                </CardHeader>
                <CardBody className="px-0">{this.renderTable()}</CardBody>
            </Card>
        );
    }

    renderTable() {
        const { t } = this.props;
        if (this.props.contractsLoading) {
            return <WrappedLoader />;
        }

        const contractsEndpointDateFormat = "DD-MM-YYYY";

        const columns = [
            {
                dataField: "number",
                classes: "pointer",
                text: t("contracts.number", "Lepingu number"),
                sort: true,
                formatter: (cell, row) => (
                    <DocumentCell
                        name={cell}
                        url={row.bdoc ? row.bdoc : row.url}
                        onClick={() => { }}
                    />
                ),
            },
            {
                dataField: "status",
                text: t("contracts.status", "Staatus"),
                sort: true,
                formatter: (obj) => (
                    <StatusCell
                        status={obj.label}
                        type={getContractStatusType(obj.value)}
                    />
                ),
            },
            {
                dataField: "created_at",
                text: t("contracts.submitted", "Esitatud"),
                sort: true,
                formatter: created_at => created_at ? moment(created_at, contractsEndpointDateFormat).format(DATE_FORMAT) : "",
                sortValue: created_at => moment(created_at, contractsEndpointDateFormat),
            },
            {
                dataField: "approved_at",
                text: t("contracts.contractConcluded", "Lepingu sõlmitud"),
                sort: true,
                formatter: approved_at => approved_at ? moment(approved_at, contractsEndpointDateFormat).format(DATE_FORMAT) : "",
                sortValue: approved_at => moment(approved_at, contractsEndpointDateFormat),
            },
            {
                dataField: "invalidated_at",
                text: t("contracts.contractEnded", "Lepingu lõpetatud"),
                sort: true,
                formatter: invalidated_at => invalidated_at ? moment(invalidated_at, contractsEndpointDateFormat).format(DATE_FORMAT) : "",
                sortValue: invalidated_at => moment(invalidated_at, contractsEndpointDateFormat),
            },
        ];

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="users-table no-top-border"
                keyField="id"
                data={this.props.contracts}
                columns={columns}
                noDataIndication={
                    <Contracts.NoDataIndication
                        loading={this.props.contractsLoading}
                        t={t}
                    />
                }
            />
        );
    }

    static NoDataIndication(props) {
        let content = props.t("contracts.tableNoContractsText", "Ei leitud ühtegi lepingut.");
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        contracts: state.profile.contracts,
        contractsLoading: state.profile.contractsLoading,
    };
};

export default connect(mapStateToProps)(withTranslation("common")(Contracts));
