import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import _ from "lodash";

import { Card, CardBody } from "reactstrap";
// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import WrappedLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";

import "./SummaryTable.scss";
import { parseSalesReportPeriodDate, YEAR_MONTH_FORMAT } from "util/DateTime";
import { depositCurrencyFormatter } from "util/General";
import { withTranslation } from "react-i18next";

class SummaryReportsTable extends React.Component {
    static propTypes = {
        filters: PropTypes.object.isRequired,
        summaryList: PropTypes.array,
        summaryListLoading: PropTypes.bool,
        error: PropTypes.any,
    };

    render() {
        const { t } = this.props;
        let content;
        if (this.props.summaryListLoading) {
            return <WrappedLoader loading={this.props.summaryListLoading} />;
        } else if (!this.props.summaryList) {
            return false;
        } else if (!this.props.summaryList.length) {
            content = this.renderCenteredContent(
                <p className="my-3 regular-14">
                    {t(
                        "reports.massReportNoDataIndication",
                        "Ei leitud ühtegi pakendit, mis vastaks otsingutingimustele."
                    )}
                </p>
            );
        } else {
            content = this.renderTable();
        }

        return (
            <>
                <Card className="summary-report-card">
                    <CardBody className="p-0 flipped-horizontal-scrollbar">
                        {content}
                    </CardBody>
                </Card>
            </>
        );
    }

    renderCenteredContent = (content) => {
        return <CenteredRowCol>{content}</CenteredRowCol>;
    };

    renderTable() {
        const { t } = this.props;
        const data = this.props.summaryList;

        /*  Had to add the first id column with hidden: true, 
            because it didn't work correctly with all other columns being
            negative filter (!filter...) values.
        */
        const staticColumns = [
            {
                dataField: "id",
                text: t("reports.filter.id", "ID"),
                classes: "semibold-14",
                hidden: true,
            },
            {
                dataField: "EAN",
                text: t("reports.filter.ean", "EAN"),
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "EAN_type",
                text: t("reports.filter.eanType", "EAN tüüp"),
                classes: "regular-14",
                sort: true,
                //hidden: !this.props.filters.showEANType,
                hidden: !this.summaryReportItemHasField("EAN_type"),
            },
            {
                dataField: "package_type",
                text: t("reports.filter.packageType", "Pakendi tüüp"),
                classes: "regular-14",
                sort: true,
                //hidden: !this.props.filters.showPackageType,
                hidden: !this.summaryReportItemHasField("package_type"),
            },
            {
                dataField: "name",
                text: t("reports.filter.packageName", "Toote nimetus"),
                classes: "semibold-14  frozen-first-column",
                headerClasses: "frozen-first-column",
                sort: true,
                //hidden: !this.props.filters.showName,
                hidden: !this.summaryReportItemHasField("name"),
            },
            {
                dataField: "package_category",
                text: t("reports.filter.packageCategory", "Toote kategooria"),
                classes: "regular-14",
                sort: true,
                //hidden: !this.props.filters.showPackageCategory,
                hidden: !this.summaryReportItemHasField("package_category"),
            },
            {
                dataField: "volume",
                text: t("reports.filter.packageVolume", "Pakendi maht"),
                classes: "regular-14",
                sort: true,
                //hidden: !this.props.filters.showVolume,
                hidden: !this.summaryReportItemHasField("volume"),
            },
            {
                dataField: "deposit",
                text: t("reports.filter.deposit", "Tagatisraha"),
                classes: "regular-14",
                sort: true,
                align: "right",
                headerAlign: "right",
                formatter: (deposit) => depositCurrencyFormatter.format(deposit),
                //hidden: !this.props.filters.showDeposit,
                hidden: !this.summaryReportItemHasField("deposit"),
            },
        ];
        const dynamicColumns = this.getDynamicPeriodColumns(data);
        const columns = [...staticColumns, ...dynamicColumns];

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="summary-details-table no-top-border"
                keyField="id"
                data={data}
                columns={columns}
                noDataIndication={
                    <this.NoDataIndication
                        loading={this.props.summaryListLoading}
                        error={this.props.error}
                        t={t}
                    />
                }
            />
        );
    }

    getDynamicPeriodColumns = (data) => {
        const dynamicColumns = [];

        if (!data || data.length === 0 || !data[0].periods) {
            return dynamicColumns;
        }
        //const periods = findAndSortPeriods(data, this.props.filters);
        const periods = data[0].periods;
        for (var i = 0; i < periods.length; i++) {
            const periodFormatted = parseSalesReportPeriodDate(
                periods[i].period
            ).format(YEAR_MONTH_FORMAT);
            const column = {
                dataField: `periods[${i}].quantity`,
                text: periodFormatted,
                classes: "regular-14",
                sort: true,
            };
            dynamicColumns.push(column);
        }
        return dynamicColumns;
    };

    NoDataIndication(props) {
        let content = props.t(
            "reports.massReportNoDataIndication",
            "Ei leitud ühtegi pakendit, mis vastaks otsingutingimustele."
        );
        if (props.loading) {
            content = <WrappedLoader />;
        } else if (props.error) {
            content = <span className="color-error">{props.error}</span>;
        }

        return <div>{content}</div>;
    }

    summaryReportItemHasField = (field) => {
        if (!this.props.summaryList || !this.props.summaryList.length) {
            return false;
        }
        return _.has(this.props.summaryList[0], field);
    };
}

const mapStateToProps = (state) => {
    return {
        filters: state.summaryReport.filters,
        summaryList: state.summaryReport.summaryList,
        summaryListLoading: state.summaryReport.summaryListLoading,
        error: state.summaryReport.error,
    };
};

export default connect(mapStateToProps)(
    withTranslation("common")(SummaryReportsTable)
);
