import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PropTypes } from "prop-types";

import { Row, Col, Button } from "reactstrap";

// datepicker
import "react-datepicker/dist/react-datepicker.css";

// generic
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import { isAdminMode } from "util/Access";

// module specific
import { actions } from "./redux";
//import reportUtils from "./utils";
import ConfirmDetailsModal from "./ConfirmDetailsModal";
import ImportModal from "./ImportModal";

// css
//import "./ListActions.scss";


class ReturnReportListActions extends React.Component {
    static propTypes = {
        // manual
        onConfirmDetailsSuccess: PropTypes.func.isRequired,
        onImportSuccess: PropTypes.func.isRequired,
        // automatic
        list: PropTypes.array.isRequired,
        selectedIds: PropTypes.array.isRequired,
        selectedRows: PropTypes.array.isRequired,
        isAdmin: PropTypes.bool,
        updateSelected: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            isConfirmDetailsOpen: false,
            isImportOpen: false
        }
    }

    componentDidMount() {
    }

    render() {
        const { isAdmin, selectedIds, updateSelected } = this.props;
        if (!isAdmin) return null;
        return (
            <Row className="no-gutters border-top pt-4 pb-3">
                <Col>
                    <Row className="justify-content-between align-items-center mx-3 px-2 mb-2n">
                        <Col xs={{ size: 12, order: 4 }} md={{ size: 3, order: 1 }} lg="" className="px-1 mb-2">
                            <Button
                                color="link"
                                disabled={!selectedIds.length}
                                onClick={() => updateSelected([])}
                            >
                                Tühista valik ({selectedIds.length})
                            </Button>
                        </Col>
                        <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 3 }} lg={2} className="px-1 mb-2">
                            {this.renderConfirmReportsButtonAndModal()}
                        </Col>
                        <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 3 }} lg={2} className="px-1 mb-2">
                            {this.renderImportButtonAndModal()}
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    renderConfirmReportsButtonAndModal() {
        const { selectedIds, onConfirmDetailsSuccess, isAdmin } = this.props;
        const { isConfirmDetailsOpen } = this.state;

        if (!isAdmin) return null;

        const onToggle = () => {
            this.setState(prevState => { return { isConfirmDetailsOpen: !prevState.isConfirmDetailsOpen } });
        };
        const onSuccess = details => {
            this.setState({
                isConfirmDetailsOpen: false
            })
            if (onConfirmDetailsSuccess) {
                onConfirmDetailsSuccess(details);
            }
        }
        const isButtonDisabled = !this.isAllSelectedConfirmable();
        return (
            <>
                <Button
                    block
                    color="secondary"
                    disabled={isButtonDisabled}
                    onClick={onToggle}
                >
                    {
                        `Kinnita ${selectedIds.length} ${selectedIds.length === 1 ? "aruanne" : "aruannet"}`
                    }
                </Button>
                <ConfirmDetailsModal ids={selectedIds} isOpen={isConfirmDetailsOpen} onToggle={onToggle} onSuccess={onSuccess} />

            </>
        );
    }

    renderImportButtonAndModal() {
        const { onImportSuccess, isAdmin } = this.props;
        const { isImportOpen } = this.state;

        if (!isAdmin) return null;

        const onToggle = () => {
            this.setState(prevState => { return { isImportOpen: !prevState.isImportOpen } });
        };
        const onSuccess = data => {
            this.setState({
                isImportOpen: false
            })
            if (onImportSuccess) {
                onImportSuccess(data);
            }
        }
        const isButtonDisabled = false;
        const isOpen = isImportOpen;
        return (
            <>
                <Button
                    block
                    color="secondary"
                    disabled={isButtonDisabled}
                    onClick={onToggle}
                >
                    Impordi aruanded
                </Button>
                <ImportModal isOpen={isOpen} onToggle={onToggle} onSuccess={onSuccess} details={null} isDetailsView={false} />

            </>
        );
    }

    isAllSelectedConfirmable = () => {
        const { selectedRows, selectedIds } = this.props;
        if (selectedIds.length === 0) return false;
        // If these two do not match, something has gone wrong somewhere
        if (selectedRows.length !== selectedIds.length) return false;
        return selectedRows.every(row => row.is_confirmable === true);
    }

}

const mapStateToProps = state => {
    // Module state reducer
    const listState = state.returnReports;
    return {
        isAdmin: isAdminMode(),
        list: listState.list,
        selectedIds: listState.listSelectedIds,
        selectedRows: listState.list.filter(row => listState.listSelectedIds.includes(row.id))
    }
};

const reduxActions = {
    updateSelected: actions.listSelectedUpdate
}

export default withRouter(connect(mapStateToProps, reduxActions)(ReturnReportListActions));
