import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";
import moment from "moment";

const initialFilters = {
    client: null,
    startDate: moment().subtract(12, "months").toDate(),
    endDate: new Date(),
};

const initialState = {
    filters: initialFilters,

    unfilteredSalesReportsList: null,
    salesReportsList: null,
    salesReportsListFiltersUsed: initialFilters,
    salesReportsListLoading: false,
    loading: false,
    error: null,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const onAdminSalesReportsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        salesReportsListLoading: true,
        error: null,
        salesReportsList: null,
    });
};

const onAdminSalesReportsFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        salesReportsListLoading: false,
        error: null,
        salesReportsList: action.salesReportsList,
        unfilteredSalesReportsList: action.unfilteredSalesReportsList,
        salesReportsListFiltersUsed: action.filters,
    });
};

const onAdminSalesReportsFetchFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        salesReportsListLoading: false,
    });
};

const onAdminSalesReportsFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
    });
};
const onAdminSalesReportsFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
    });
};

export const salesReports = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.ADMIN_SALES_REPORTS_FETCH_START:
            return onAdminSalesReportsFetchStart(state, action);
        case actionTypes.ADMIN_SALES_REPORTS_FETCH_SUCCESS:
            return onAdminSalesReportsFetchSuccess(state, action);
        case actionTypes.ADMIN_SALES_REPORTS_FETCH_FAIL:
            return onAdminSalesReportsFetchFail(state, action);
        // FILTER
        case actionTypes.ADMIN_SALES_REPORTS_FILTER_UPDATE:
            return onAdminSalesReportsFilterUpdate(state, action);
        case actionTypes.ADMIN_SALES_REPORTS_FILTER_CLEAR:
            return onAdminSalesReportsFilterClear(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
