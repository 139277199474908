import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Card, CardBody, Row, Col } from "reactstrap";
import moment from "moment";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory from "react-bootstrap-table2-filter";

// generic
import DateCell from "components/Table/Cell/DateCell";
import WrappedLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import { isConnectedToClientWithActiveContract, isAdminMode } from "util/Access";
import { YEAR_MONTH_FORMAT } from "util/DateTime";
import { addNewLocalSuccessAlert } from "util/Alerts";
import { getErrorMessageList } from "util/Errors";
import CustomPagination from "components/Table/Pagination/CustomPagination";
// generic - selection
import Checkbox from "components/Form/Checkbox";
import SelectionHeader from "components/Form/SelectionHeader/SelectionHeader";
import DocumentCell from "components/Table/Cell/DocumentCell";

// module specific
import ListSearch from "./ListSearch";
import ListActions from "./ListActions";
import ActionsMenu from "./ActionsMenu";
import reportUtils from "./utils";
import { actions } from "./redux";

// css
import "./ListView.scss";
import { defaultsDeep } from "lodash";

function NoDataIndication(props) {
    let content =
        props.t("reports.additionalDeposit.noDataText");
    if (props.loading) {
        content = <WrappedLoader />;
    } else if (props.error) {
        content = <span className="color-error">{props.error}</span>;
    }

    return <div className="text-center">{content}</div>;
}

class AdditionalDepositReportListView extends React.Component {
    static propTypes = {
        // automatic
        list: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        filters: PropTypes.object.isRequired,
        filtersUsed: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        selectedIds: PropTypes.array.isRequired,
        // general
        user: PropTypes.object.isRequired,
        isClientWithActiveContract: PropTypes.bool,
        isAdmin: PropTypes.bool,
        // actions
        updateFilters: PropTypes.func.isRequired,
        clearFilters: PropTypes.func.isRequired,
        updateSorting: PropTypes.func.isRequired,
        updatePagination: PropTypes.func.isRequired,
        updateSelected: PropTypes.func.isRequired,
    };

    componentDidMount() {
        const { isAdmin, isClientWithActiveContract } = this.props;
        if (isAdmin || isClientWithActiveContract) {
            this.fetchList();
        }
    }

    componentDidUpdate(prevProps) {
        const { isAdmin, isClientWithActiveContract } = this.props;
        const isValidClient = isClientWithActiveContract
        const isAdminNowActive = isAdmin && isAdmin !== prevProps.isAdmin;
        const isValidClientModeNowActive = isValidClient && isValidClient !== prevProps.isClientWithActiveContract;
        if (isAdminNowActive || isValidClientModeNowActive) {
            this.fetchList();
        }
    }

    render() {
        return (
            <Card className="additional-deposit-reports">
                <CardBody className="px-0">
                    <ListSearch onSearch={this.onSearch} />
                    {this.renderActions()}
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }

    renderActions() {
        return (
            <ListActions
                onSyncSuccess={this.onSyncAllSuccess}
            />
        )
    }

    renderTable() {
        const { t, list, loading, isAdmin, pagination, sorting, selectedIds } = this.props;
        if (loading) {
            return (
                <CenteredRowCol>
                    <WrappedLoader />
                </CenteredRowCol>
            );
        }
        if (list === null || list === undefined) {
            return <></>;
        }

        const columns = [
            {
                dataField: "client.name",
                text: "Ettevõte",
                sort: true,
                classes: "client-col semibold-14",
                headerClasses: "client-col-header",
                show: isAdmin
            },
            {
                dataField: "period",
                text: t("reports.table.period"),
                sort: true,
                classes: "period-col",
                headerClasses: "period-col-header",
                formatter: (periodStart, row) => this.periodDateColumnFormatter(periodStart),
                show: true
            },
            {
                dataField: "invoices",
                text: t("reports.table.invoice"),
                sort: false,
                sortValue: invoices => invoices?.length > 0 ? invoices[0].name : "",
                align: "left",
                headerAlign: "left",
                classes: "invoice-col",
                headerClasses: "invoice-col-header",
                formatter: (invoices, row) => this.invoicesColumnFormatter(invoices),
                show: true
            },
            {
                dataField: "is_posted",
                text: t("reports.table.status"),
                align: "center",
                alignHeader: "center",
                sort: true,
                classes: "status-col",
                headerClasses: "status-col-header",
                formatter: (value, row) => this.statusColumnFormatter(row),
                show: isAdmin
            },
            {
                dataField: "last_synced_at", // updates on sync, if not already posted
                text: t("reports.filter.createdAt"),  // name is what they wanted, last_synced_at has the logic they expected for the column
                classes: "regular-14",
                sort: true,
                style: {
                    width: "170px"
                },
                formatter: (value, line) => {
                    if (!value) return null;
                    return moment(value).format("DD.MM.YYYY")
                },
                show: true
            },
            {
                dataField: "id",
                text: "",
                align: "right",
                alignHeader: "right",
                classes: "action-col",
                headerClasses: "action-col-header",
                formatter: (id, row) =>
                    this.actionColumnFormatter(row),
                show: true
            },
        ].filter(col => col.show === true);

        const defaultSorted = [sorting];
        let selectRow;
        /*
        if (isAdmin) {
            selectRow = {
                mode: "checkbox",
                selected: selectedIds,
                hideSelectAll: false,
                selectionRenderer: ({ mode, checked, disabled }) => (
                    <Checkbox value={checked} onClick={() => { }} className="px-0" />
                ),
                onSelect: (row, isSelect, rowIndex, e) => {
                    this.onSelect(row, isSelect, rowIndex, e);
                },
                onSelectAll: (isSelect, rows) => {
                    this.onSelectAll(isSelect, rows);
                },
                selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
                    return <SelectionHeader checked={checked} indeterminate={indeterminate} />
                }
            }
        }
        */

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onRowClick(e, row);
            },
        };

        return (
            <Row>
                <Col>
                    <BootstrapTable
                        remote
                        bootstrap4
                        bordered={false}
                        classes="additional-deposit-reports__table table-layout-auto"
                        wrapperClasses="additional-deposit-reports__table-wrapper table-responsive flipped-horizontal-scrollbar"
                        keyField="id"
                        data={list}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="inline"
                        defaultSorted={defaultSorted}
                        onTableChange={this.onTableChange}
                        selectRow={selectRow}
                        noDataIndication={
                            <NoDataIndication
                                loading={loading}
                                t={t}
                            />
                        }
                        rowClasses={rowClasses}
                        rowEvents={rowEvents}
                    />
                    {!this.props.loading &&
                        <Row className="mx-3">
                            <Col>
                                <CustomPagination
                                    page={pagination.page}
                                    sizePerPage={pagination.sizePerPage}
                                    dataSize={pagination.dataSize}
                                    sizePerPageList={pagination.sizePerPageList}
                                    onSizePerPageChange={(sizePerPage, page) => this.onPaginationChange(page, sizePerPage)}
                                    onPageChange={this.onPaginationChange}
                                />
                            </Col>
                        </Row>
                    }

                </Col>
            </Row>
        );
    }

    periodDateColumnFormatter = (periodDate) => {
        return (
            <>
                <Row>
                    <Col>
                        <DateCell value={periodDate} format={"YYYY"} />
                    </Col>
                </Row>
            </>
        );
    };

    statusColumnFormatter = (row) => {
        return (
            <Row>
                <Col>
                    {
                        <reportUtils.ReportStatus details={row} />
                    }
                </Col>
            </Row>
        );
    }

    invoicesColumnFormatter = (invoices) => {
        return invoices.filter(invoice => invoice.name).map((invoice, index) => (
            <div key={`invoice-${invoice.name}-${index}`}>
                <DocumentCell
                    name={invoice.name}
                    url={invoice.url}
                    onClick={() => { }}
                />
            </div>
        ));
    };

    fetchList = (config) => {
        const { filtersUsed, sorting, pagination, location } = this.props;
        config = defaultsDeep(config, {
            filters: filtersUsed,
            sorting,
            pagination,
            alertGroup: location.pathname
        });

        reportUtils.fetchReportsList(config.filters, config.sorting, config.pagination, config.alertGroup);
    }

    onSearch = () => {
        this.fetchList({
            filters: this.props.filters
        });
    }

    onPaginationChange = (page, sizePerPage) => {
        const { pagination, updatePagination } = this.props;
        if (page !== pagination.page || sizePerPage !== pagination.sizePerPage) {
            const newPagination = { ...pagination, page, sizePerPage };
            updatePagination(newPagination);
            this.fetchList({
                pagination: newPagination
            });
        }
    }

    onTableChange = (type, newState) => {
        const { sorting, updateSorting } = this.props;
        const { sortOrder, sortField } = newState;
        if (sortOrder !== sorting.order || sortField !== sorting.dataField) {
            const newSorting = {
                dataField: sortField,
                order: sortOrder,
            };
            updateSorting(newSorting);
            this.fetchList({
                sorting: newSorting
            });
        }
    }

    onSelect = (row, isSelect, rowIndex, e) => {
        const rows = [row];
        this.onSelectAll(isSelect, rows)
    }

    onSelectAll = (isSelect, rows) => {
        const { selectedIds, updateSelected } = this.props;
        const ids = rows.map(row => row.id);
        let newSelectedIds;
        if (isSelect) {
            const possibleDuplicateIds = [...selectedIds, ...ids];
            const setOfUniqueIds = new Set(possibleDuplicateIds);
            newSelectedIds = [...setOfUniqueIds];
        } else {
            newSelectedIds = selectedIds.filter(id => !ids.includes(id));
        }
        updateSelected(newSelectedIds)
    }

    onRowClick = (event, row) => {
        let url = reportUtils.getDetailsViewUrl(row.id);
        this.props.history.push(url);
    };

    getSyncSuccessMessage(statistics, topText) {
        const stats = [
            `Loodi ${statistics.reports_created} aruannet`,
            `Loodi ${statistics.lines_created} aruanderida`,
            `Uuendati ${statistics.reports_updated} aruannet`,
            `Uuendati ${statistics.lines_updated} aruanderida`,
        ]
        return getErrorMessageList(stats, stat => stat, topText)
    }

    onSyncAllSuccess = statistics => {
        const { pagination, location, t } = this.props;
        this.onPaginationChange(1, pagination.sizePerPage);

        this.props.updatePagination({
            ...pagination,
            page: 1
        });
        this.fetchList();

        const topText = t("reports.additionalDeposit.alerts.syncBulkSuccess");
        const alertMessage = <reportUtils.ReportSyncSuccess statistics={statistics} top-text={topText} />;
        addNewLocalSuccessAlert(alertMessage, location.pathname);
    }

    onSpecificReportSyncSuccess = (statistics, details) => {
        const { pagination, history, t } = this.props;

        this.props.updatePagination({
            ...pagination,
            page: 1
        });
        this.fetchList();

        const topText = t("reports.additionalDeposit.alerts.syncSpecificSuccess");
        const alertMessage = <reportUtils.ReportSyncSuccess statistics={statistics} top-text={topText} />;
        const detailsRoute = reportUtils.getDetailsViewUrl(details.id);
        addNewLocalSuccessAlert(alertMessage, detailsRoute);
        history.push(detailsRoute);
    }

    actionColumnFormatter = (row) => {
        return <ActionsMenu details={row} onSyncSuccess={this.onSpecificReportSyncSuccess} />;
    };
}

const mapStateToProps = (state) => {
    // Module state reducer
    const listState = state.additionalDepositReports;
    return {
        list: listState.list,
        loading: listState.listLoading,
        filters: listState.listFilters,
        filtersUsed: listState.listFiltersUsed,
        sorting: listState.listSorting,
        pagination: listState.listPagination,
        selectedIds: listState.listSelectedIds,
        // general
        user: state.auth.user,
        isClientWithActiveContract: isConnectedToClientWithActiveContract(
            state
        ),
        isAdmin: isAdminMode()
    };
};

const mapActionsToTops = {
    updateFilters: actions.listUpdateFilters,
    clearFilters: actions.listClearFilters,
    updateSorting: actions.listSortingUpdate,
    updatePagination: actions.listPaginationUpdate,
    updateSelected: actions.listSelectedUpdate
}

export default connect(mapStateToProps, mapActionsToTops)(withTranslation()(AdditionalDepositReportListView));
