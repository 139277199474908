import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import accept from "attr-accept";
import { withTranslation, Trans } from "react-i18next";

import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
import FileUpload from "components/Form/Files/FileUpload";
import SmallLoader from "components/Loader/WrappedPulseLoader";
import { ReactComponent as RemoveIcon } from "assets/icons/close-inactive.svg";
import CloseTextIcon from "components/Misc/CloseTextIcon";

import { getSalesReportOrRepairUrl } from "config/urls";
import { importSalesReportFromExcelFile, fetchSalesReportExportFile } from "util/SalesReports";
import { parseSalesReportPeriodDate, salesReportPeriodListFormat } from "util/DateTime";

import {
    salesReportImportClose,
    salesReportImportFileChange,
} from "redux/actions/reports/importSalesReport";
import Alerts from "components/Alerts/Alerts";
import {
    clearIncludedGroups,
    addNewLocalErrorAlert,
    addNewLocalWarningAlert,
    addNewLocalInfoAlert,
    addNewLocalSuccessAlert
} from "util/Alerts";
import { getErrorMessageList } from "util/Errors";

import "./ImportSalesReport.scss";

class ImportSalesReport extends React.Component {
    static propTypes = {
        importFile: PropTypes.object,
        details: PropTypes.object.isRequired,
        isOpen: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool.isRequired,
        salesReportImportClose: PropTypes.func.isRequired,
        salesReportImportFileChange: PropTypes.func.isRequired,
        fileMaxSize: PropTypes.number,
        fileTypes: PropTypes.arrayOf(PropTypes.string), // Only array version because using [].includes
        alertGroup: PropTypes.string,
        isDetailsView: PropTypes.bool, // required fetchData
        fetchData: PropTypes.func // required if isDetailsView is true
    }

    static defaultProps = {
        fileMaxSize: 5e6,
        fileTypes: [".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
        alertGroup: "IMPORT_SALES_REPORT_MODAL",
        isDetailsView: false
    }

    clearModalAlerts = () => {
        clearIncludedGroups(this.props.alertGroup);
    }

    updateSalesReportFromFile = async () => {
        const { t, history, isDetailsView, fetchData } = this.props;
        this.clearModalAlerts();
        const id = this.props.details.id;
        const isRepair = this.props.details.is_repair;
        importSalesReportFromExcelFile(id, isRepair, this.props.importFile).then(
            result => {
                if (result.isSuccess) {
                    const id = this.props.details.id;
                    const isRepair = this.props.details.is_repair;
                    this.props.salesReportImportClose();
                    const route = getSalesReportOrRepairUrl(id, isRepair);
                    addNewLocalSuccessAlert(t("reports.importSalesReportModal.alerts.importSuccess", "Müügiaruande lisamine failist õnnestus."), route, true);
                    if (isDetailsView) {
                        if (typeof fetchData === 'function') {
                            fetchData();
                        }
                    } else {
                        history.push(route);
                    }
                } else {
                    const errorContentFunc = (error) => {
                        let errorMessage = error.code || t("reports.importSalesReportModal.alerts.importFailed.eanErrorDefaultText", "Müügiaruande lisamine ebaõnnestus");
                        if (error.EAN) {
                            errorMessage = <Trans i18nKey="reports.importSalesReportModal.alerts.importFailed.eanError">{{ ean: error.EAN }} - {{ eanErrorText: errorMessage }}</Trans>;
                        }
                        return errorMessage;
                    }
                    const topText = t("reports.importSalesReportModal.alerts.importFailed.text", "Müügiaruande lisamine ebaõnnestus:");
                    const errorMessage = getErrorMessageList(result.errors, errorContentFunc, topText);
                    addNewLocalErrorAlert(errorMessage, this.props.alertGroup);
                }
            }
        );

    };

    onCancel = () => {
        if (this.props.isLoading) {
            return;
        }
        this.clearModalAlerts();
        this.props.salesReportImportClose();
    }

    onDrop = files => {
        this.clearModalAlerts();
    }

    onDropRejected = (rejectedFiles) => {
        const { t } = this.props;
        if (rejectedFiles.length > 1) {
            addNewLocalWarningAlert(t("reports.importSalesReportModal.alerts.warning.canOnlyDragOneFile", "Korraga võib tirida ainult ühe faili."), this.props.alertGroup);
            return;
        }
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile.size > this.props.fileMaxSize) {
            const maxSizeMB = _.round(this.props.fileMaxSize / 1e6, 1);
            const message = <Trans i18nKey="reports.importSalesReportModal.alerts.warning.maxFileSize">Maksimaalne failimaht on {{ maxSizeMB }} MB.</Trans>
            addNewLocalWarningAlert(message, this.props.alertGroup);
        }
        if (!this.props.fileTypes.includes(rejectedFile.type)) {
        }

        const isValidMimeTypeOrExtension = accept(
            {
                name: rejectedFile.name,
                type: rejectedFile.type
            },
            this.props.fileTypes
        );

        if (!isValidMimeTypeOrExtension) {
            addNewLocalWarningAlert(t("reports.importSalesReportModal.alerts.warning.fileTypeMustBeXLSX", "Fail peab olema .xlsx failitüüpi."), this.props.alertGroup);
        }
    }

    onDropAccepted = acceptedFiles => {
        const acceptedFile = acceptedFiles[0];
        this.props.salesReportImportFileChange(acceptedFile);
        const message = <Trans i18nKey="reports.importSalesReportModal.alerts.info.fileAdded">Faili {{ fileName: acceptedFile.name }} lisamine õnnestus.</Trans>
        addNewLocalInfoAlert(message, this.props.alertGroup);

    }

    onRemoveSelectedFile = () => {
        this.clearModalAlerts();
        this.props.salesReportImportFileChange(null);
    }

    onGetExcelTemplate = () => {
        let reportDetails = this.props.details;

        this.clearModalAlerts();
        fetchSalesReportExportFile(reportDetails.id, reportDetails.is_repair, reportDetails.period).then(
            result => {
                if (!result.isSuccess) {
                    const message = <Trans i18nKey="reports.importSalesReportModal.alerts.error.downloadTemplateFailed">Aruande templaadi allatõmbamine ebaõnnestus: {{ errorMessage: result.errorMessage }}</Trans>
                    addNewLocalErrorAlert(message, this.props.alertGroup);
                }
            }
        );
    }

    render() {
        if (!this.props.isOpen) {
            return false;
        }
        const { t } = this.props;
        const formattedPeriod = parseSalesReportPeriodDate(this.props.details.period).format(salesReportPeriodListFormat);
        let title = <Trans i18nKey="reports.importSalesReportModal.title.originalReport">{{ period: formattedPeriod }}</Trans>
        if (this.props.details.is_repair) {
            title = <Trans i18nKey="reports.importSalesReportModal.title.repairReport">{{ period: formattedPeriod }} - Parandus</Trans>
        }

        return (
            <Modal
                isOpen={this.props.isOpen}
                onToggle={this.onCancel}
                backdrop="static"
                className="import-sales-report-modal"
            >
                <ModalBody className="">
                    <Row className="pb-2 header-section">
                        <Col>
                            <span className="modal-title">{title}</span>
                        </Col>
                        <Col className="float-right text-right col-auto">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onCancel}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts alerts={this.props.alertsList} hideGlobal={true} filterGroups={[this.props.alertGroup]} />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                color="link"
                                onClick={this.onGetExcelTemplate}
                                className="px-0"
                            >
                                {t("reports.importSalesReportModal.downloadTemplateButton", "Tõmba aruande templaat")}
                            </Button>
                        </Col>
                    </Row>
                    <FileUpload
                        file={this.props.importFile}
                        onDrop={this.onDrop}
                        onDropRejected={this.onDropRejected}
                        onDropAccepted={this.onDropAccepted}
                        accept={this.props.fileTypes}
                        maxSize={this.props.fileMaxSize}
                        //dropzoneText="Drag 'n' drop a .xlsx sales report file or click to select it"
                        dropzoneText={t("reports.importSalesReportModal.dropzoneText", "Müügiaruande faili (.xlsx) lisamiseks lohista fail hiirega või vajuta kastile.")}

                    />
                    <Row className="selected-file-section">
                        <Col className="col-12 selected-file-header">
                            <span className="">{t("reports.importSalesReportModal.selectedFileHeader", "Valitud fail:")}</span>
                        </Col>

                        <Col >
                            <Row className={`selected-file ${this.props.importFile ? "" : "invisible"}`}>
                                <Col>
                                    <span>{this.props.importFile ? this.props.importFile.path : t("reports.importSalesReportModal.noFilePlaceholder", "Ühtegi faili ei ole valitud.")}</span>
                                </Col>
                                <Col className={`col-auto ${this.props.importFile ? "" : "invisible"}`}>
                                    <RemoveIcon
                                        className="ml-3 remove-file-button"
                                        onClick={this.onRemoveSelectedFile}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Button
                                color="link"
                                disabled={this.props.isLoading}
                                onClick={this.onCancel}
                                className="min-height-40 modal-cancel-button"
                            >
                                {t("reports.importSalesReportModal.cancelButton", "Tühista")}
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button
                                color="success"
                                disabled={this.props.isLoading || !this.props.importFile}
                                onClick={this.updateSalesReportFromFile}
                                className="min-height-40 modal-import-button"
                            >
                                {this.props.isLoading ? <SmallLoader /> : t("reports.importSalesReportModal.importButton", "Import")}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        details: state.importSalesReport.details,
        isLoading: state.importSalesReport.loading,
        isOpen: state.importSalesReport.open,
        importFile: state.importSalesReport.file,
        alertsList: state.alerts.alertsList,
    }
};

const reduxActions = {
    salesReportImportClose,
    salesReportImportFileChange
};

export default withRouter(connect(mapStateToProps, reduxActions)(withTranslation()(ImportSalesReport)));
