import React, { Component } from "react";
import { withRouter } from "react-router";
import { Button, Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import "./ContractModal.scss";
import { connect } from "react-redux";
import { Row } from "react-bootstrap";
import urls from "config/urls";
import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";

import {
    signAdminMobileId,
    digitalSignStatus,
    SIGNING_ALERT_GROUP,
    signAdminSmartId,
    authTypes,
} from "util/Auth";
import { getServerErrorMessage, getIdCardErrorMessage } from "util/Errors";
import {
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
    addNewLocalInfoAlert,
    clearLocalAlerts,
    clearAllAlerts,
} from "util/Alerts";
import { adminfetchAllClients } from "util/admin/Users";

import Alerts from "components/Alerts/Alerts";
import Dokobit from "util/dokobit/Dokobit";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

class ContractModal extends Component {
    state = {
        modal: this.props.modalOpen,
        selectedAuth: null,
        isSigning: false,
        contractId: "",
        isMobileIdDisabled: true,
        isSmartIdDisabled: true,
    };

    componentDidMount() {
        if (this.props.authType === authTypes.MID) {
            this.setState({
                selectedAuth: "1",
                isMobileIdDisabled: false,
            });
        }
        else if (this.props.authType === authTypes.SMART) {
            this.setState({
                selectedAuth: "2",
                isSmartIdDisabled: false,
            });
        }
        else {
            this.setState({
                selectedAuth: "3",
            });
        }
    }

    dokobit = new Dokobit();

    signContract = () => {
        clearLocalAlerts();

        if (!this.state.selectedAuth) {
            addNewLocalErrorAlert(
                "Allkirjastamismeetod on valimata!",
                SIGNING_ALERT_GROUP,
                true
            );
            return;
        }

        if (this.state.selectedAuth === "1") {
            this.signWithMid();
        } else if (this.state.selectedAuth === "2") {
            this.signWithSmart();
        } else if (this.state.selectedAuth === "3") {
            this.signWithIdCard();
        }
    };

    signWithMid = () => {
        this.setIsSigning(true);
        clearLocalAlerts();

        const mobileNumber = this.props.user.phone;
        const personalCode = this.props.user.personal_code;
        const cert = this.props.user.cert;

        signAdminMobileId(
            mobileNumber,
            personalCode,
            cert,
            this.props.contractId
        )
            .then(async (data) => {
                clearLocalAlerts();
                if (data.success === true) {
                    addNewLocalInfoAlert(
                        `Kontrollkood ${data.control_code} saadeti numbrile ${mobileNumber}. Veendu, et telefonile ilmuv kontrollkood ühtib brauseris nähtavaga.`,
                        SIGNING_ALERT_GROUP
                    );
                    this.checkSignStatus(
                        data.token,
                        authTypes.MID,
                        "-",
                        this.props.contractId
                    );
                } else {
                    addNewLocalErrorAlert(
                        "Mobiil-IDga allkirjastamine ebaõnnestus!",
                        SIGNING_ALERT_GROUP,
                        true
                    );
                    this.setIsSigning(false);
                }
            })
            .catch((err) => {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    "Mobiil-IDga allkirjastamisel ilmnes viga: " +
                    getServerErrorMessage(err),
                    SIGNING_ALERT_GROUP,
                    true
                );
                this.setIsSigning(false);
            });
    };

    signWithSmart = () => {
        this.setIsSigning(true);
        clearLocalAlerts();

        const personalCode = this.props.user.personal_code;
        const cert = this.props.user.cert;
        signAdminSmartId(personalCode, cert, this.props.contractId)
            .then(async (data) => {
                clearLocalAlerts();
                if (data.success === true) {
                    addNewLocalInfoAlert(
                        `Kontrollkood ${data.control_code} saadeti teie telefoni. Veendu, et telefonile ilmuv kontrollkood ühtib brauseris nähtavaga.`,
                        SIGNING_ALERT_GROUP
                    );
                    this.checkSignStatus(
                        data.token,
                        authTypes.SMART,
                        "-",
                        this.props.contractId
                    );
                } else {
                    addNewLocalErrorAlert(
                        "Smart-IDga allkirjastamine ebaõnnestus!",
                        SIGNING_ALERT_GROUP,
                        true
                    );
                    this.setIsSigning(false);
                }
            })
            .catch((err) => {
                this.setIsSigning(false);
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    "Smart-IDga allkirjastamisel ilmnes viga!",
                    SIGNING_ALERT_GROUP,
                    true
                );
            });
    };

    signWithIdCard = () => {
        this.setIsSigning(true);
        const personalCode = this.props.user.personal_code;

        const resolve = (data) => {
            this.setIsSigning(false);

            clearLocalAlerts();
            if (data.success === true) {
                addNewLocalSuccessAlert(
                    "Leping edukalt allkirjastatud!",
                    urls.EPP_USERS_CLIENTS
                );
                this.onSignSuccess();
            } else {
                addNewLocalErrorAlert(
                    "Allkirja kinnitamine ebaõnnestus!",
                    SIGNING_ALERT_GROUP,
                    true
                );
            }
        };

        const reject = (error) => {
            this.setIsSigning(false);
            clearLocalAlerts();
            const errorMessage = getIdCardErrorMessage(
                error,
                "Allkirjastamine ebaõnnestus!"
            );
            addNewLocalErrorAlert(errorMessage, SIGNING_ALERT_GROUP, true);
        };

        addNewLocalInfoAlert(
            "Toimub ID-kaardiga allkirjastamine. Kontrollige oma tegumiriba, kui sertifikaadi valimine ei kerki esile.",
            SIGNING_ALERT_GROUP,
            true
        );
        this.dokobit.signAdminWithIdCard(
            personalCode,
            resolve,
            reject,
            this.props.contractId
        );
    };

    checkSignStatus = async (token, mode, identifier, contractId) => {
        await sleep(1000);

        digitalSignStatus(token, mode, identifier, contractId)
            .then((data) => {
                if (data.success === true) {
                    clearLocalAlerts();
                    //this.setIsSigning(false);
                    this.onSignSuccess();
                } else if (data.code === "waiting") {
                    this.checkSignStatus(token, mode, identifier, contractId);
                } else if (data.code === "error") {
                    clearLocalAlerts();
                    this.setIsSigning(false);
                    addNewLocalErrorAlert(
                        "Allkirjastamine ebaõnnestus!",
                        SIGNING_ALERT_GROUP,
                        true
                    );
                }
            })
            .catch((error) => {
                clearLocalAlerts();
                this.setIsSigning(false);
                addNewLocalErrorAlert(
                    "Allkirjastamisel ilmnes viga!",
                    SIGNING_ALERT_GROUP
                );
            });
    };

    setIsSigning = (value) => {
        this.setState({ isSigning: value });
    };

    signMID = () => {
        this.setState({ selectedAuth: "1" });
    };

    onSignSuccess = () => {
        clearAllAlerts();
        const clientDetail = urls.EPP_USERS_CLIENTS_CLIENT_PROFILE.replace(
            ":id",
            this.props.clientId
        );
        addNewLocalSuccessAlert("Leping edukalt allkirjastatud!", clientDetail);
        adminfetchAllClients(false).catch(() => { });
        this.props.history.push(clientDetail, { contractSigned: true });
    };

    changeLoginMode = (authOption) => {
        this.setState({ selectedAuth: authOption });
    };

    isSubmitDisabled = () => {
        return this.state
            .isSigning /* || !this.state.contract || !this.state.contractUrl || !this.state.acceptContractTerms || !this.state.selectedAuth*/;
    };

    render() {
        return (
            <div>
                <Modal
                    isOpen={this.props.modalOpen}
                    toggle={this.props.toggleModal}
                    className="contract-modal"
                >
                    <ModalHeader
                        toggle={this.props.toggleModal}
                        className="modal-header"
                    >
                        <p className="title">Lepingu allkirjastamine</p>
                        <p className="regular-14">
                            Uute pakendite registreerimiseks on vajalik ka
                            Pandipakendi poolne allkiri.
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        <Row className="wrapper">
                            <Col md={12} className="text-center">
                                <p className="bold-16">
                                    Vali allkirjastamise viis
                                </p>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col className="col-auto pr-1">
                                <CheckboxButton
                                    onClick={() => this.changeLoginMode("1")}
                                    isSelected={this.state.selectedAuth === "1"}
                                    labelText="Mobiil-ID"
                                    disabled={this.state.isSigning || this.state.isMobileIdDisabled}
                                    className="auth-option"
                                    invalid={this.state.selectedAuthInvalid}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                            <Col className="col-auto px-1">
                                <CheckboxButton
                                    onClick={() => this.changeLoginMode("2")}
                                    isSelected={this.state.selectedAuth === "2"}
                                    labelText="Smart-ID"
                                    disabled={this.state.isSigning || this.state.isSmartIdDisabled}
                                    className="auth-option"
                                    invalid={this.state.selectedAuthInvalid}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                            <Col className="col-auto px-1">
                                <CheckboxButton
                                    onClick={() => this.changeLoginMode("3")}
                                    isSelected={this.state.selectedAuth === "3"}
                                    labelText="ID-kaart"
                                    disabled={this.state.isSigning}
                                    className="auth-option"
                                    invalid={this.state.selectedAuthInvalid}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                        </Row>
                        <Row className="alert-wrapper">
                            <Col md={12}>
                                <Alerts
                                    hideGlobal={true}
                                    filterGroups={[SIGNING_ALERT_GROUP]}
                                />
                            </Col>
                        </Row>
                        {!this.state.isSigning && (
                            <Row className="wrapper">
                                <Col md={12} className="text-center">
                                    <Button
                                        disabled={this.isSubmitDisabled()}
                                        color="success"
                                        onClick={() => this.signContract()}
                                    >
                                        Allkirjasta
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        authType: state.auth.authType,
    };
};

export default withRouter(connect(mapStateToProps)(ContractModal));
