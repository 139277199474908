import React from "react";
import PropTypes from "prop-types";
import {PulseLoader} from "react-spinners";

function WrapperPulseLoader(props) {
    return (
        <PulseLoader
            color={"#46A72A"}
            size={props.size}
            loading={props.loading}
            {...props.extraProps}
        />
    );
}

WrapperPulseLoader.propTypes = {
    loading: PropTypes.bool,
    //size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    extraProps: PropTypes.object,
};

WrapperPulseLoader.defaultProps = {
    size: 5,
    extraProps: {},
    loading: true,
}

export default WrapperPulseLoader;