import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation, Trans } from "react-i18next";

import { Button, Modal, ModalHeader, ModalBody, Col } from "reactstrap";
import "./ContractModal.scss";
import { Row } from "react-bootstrap";
import urls from "../../config/urls";
import PersonalCollapse from "./PersonalCollapse/PersonalCollapse";
import CompanyCollapse from "./CompanyCollapse/CompanyCollapse";
import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";
import CustomCheckbox from "components/Form/Checkbox";

import { createContract, signContract } from "util/Contracts";
import {
    getAuthUser,
    signMobileId,
    digitalSignStatus,
    SIGNING_ALERT_GROUP,
    signSmartId,
} from "util/Auth";
import { getServerErrorMessage, getIdCardErrorMessage } from "util/Errors";
import { updateCompanyProfile } from "util/Profile";
import {
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
    addNewLocalInfoAlert,
    clearLocalAlerts,
    clearAllAlerts,
} from "util/Alerts";
import { authTypes } from "util/Auth";

import Alerts from "../Alerts/Alerts";
import Dokobit from "../../util/dokobit/Dokobit";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

class ContractModal extends Component {
    state = {
        modal: true,
        selectedAuth: null,
        contractUrl: null,
        contract: null,
        acceptContractTerms: false,
        isSigning: false,
        invalidPersonalFields: [],
        invalidCompanyFields: [],
        acceptContractTermsInvalid: false,
        selectedAuthInvalid: false,
        isValidatedAtleastOnce: false,
    };

    dokobit = new Dokobit();

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.isValidatedAtleastOnce) {
            if (
                this.props !== prevProps ||
                prevState.selectedAuth !== this.state.selectedAuth ||
                prevState.acceptContractTerms !== this.state.acceptContractTerms
            ) {
                this.validateSignContract();
            }
        }
        if (this.props !== prevProps) {
            if (
                this.hasAllProperties(this.props.personalProfile) &&
                this.hasAllProperties(this.props.companyProfile)
            ) {
                clearLocalAlerts();
                createContract(
                    this.props.companyProfile.name,
                    this.props.companyProfile.reg_number,
                    this.props.companyProfile.kmkr,
                    this.props.companyProfile.basis,
                    this.props.personalProfile.phone,
                    this.props.companyProfile.email,
                    this.props.companyProfile.iban,
                    this.props.companyProfile.full_address,
                    this.props.companyProfile.zip_code,
                    this.props.companyProfile.city
                )
                    .then((response) => {
                        const data = response.data;
                        if (response.status === 208) {
                            clearAllAlerts();
                            addNewLocalInfoAlert(
                                data.code,
                                urls.DASHBOARD,
                                true
                            );

                            updateCompanyProfile(this.props.companyProfile).catch((err) =>
                                addNewLocalErrorAlert(
                                    getServerErrorMessage(err),
                                    urls.DASHBOARD,
                                    true
                                )
                            );

                            getAuthUser().catch((err) =>
                                addNewLocalErrorAlert(
                                    getServerErrorMessage(err),
                                    urls.DASHBOARD,
                                    true
                                )
                            );
                            this.props.history.push(urls.DASHBOARD);
                        } else {
                            if (data.url) {
                                this.setState({
                                    contractUrl: data.url,
                                    contract: data,
                                });
                            }
                        }

                    })
                    .catch((err) => {
                        clearLocalAlerts();
                        const message = <Trans i18nKey="contractModal.alerts.createContractFailed">Lepingu koostamine ebaõnnestus: {{ errorMessage: getServerErrorMessage(err) }}</Trans>;

                        addNewLocalErrorAlert(
                            message,
                            SIGNING_ALERT_GROUP,
                            true
                        );
                    });
            }
        }
    }

    validateSignContract() {
        const personalProfile = this.props.personalProfile;
        const companyProfile = this.props.companyProfile;
        const invalidPersonalFields = [];
        const invalidCompanyFields = [];

        if (!personalProfile.first_name) {
            invalidPersonalFields.push("first_name");
        }
        if (!personalProfile.phone) {
            invalidPersonalFields.push("phone");
        }
        if (!personalProfile.profession) {
            invalidPersonalFields.push("profession");
        }
        if (!personalProfile.last_name) {
            invalidPersonalFields.push("last_name");
        }
        if (!personalProfile.email) {
            invalidPersonalFields.push("email");
        }

        if (!companyProfile.name) {
            invalidCompanyFields.push("name");
        }
        if (!companyProfile.reg_number) {
            invalidCompanyFields.push("reg_number");
        }
        if (!companyProfile.kmkr) {
            invalidCompanyFields.push("kmkr");
        }
        if (!companyProfile.basis) {
            invalidCompanyFields.push("basis");
        }
        if (!companyProfile.email) {
            invalidCompanyFields.push("email");
        }
        if (!companyProfile.iban) {
            invalidCompanyFields.push("iban");
        }
        if (!companyProfile.full_address) {
            invalidCompanyFields.push("full_address");
        }
        if (!companyProfile.zip_code) {
            invalidCompanyFields.push("zip_code");
        }
        if (!companyProfile.city) {
            invalidCompanyFields.push("city");
        }

        const acceptContractTermsInvalid = !this.state.acceptContractTerms;

        const selectedAuthInvalid = !this.state.selectedAuth;

        this.setState({
            invalidPersonalFields,
            invalidCompanyFields,
            acceptContractTermsInvalid,
            selectedAuthInvalid,
            isValidatedAtleastOnce: true,
        });

        if (
            invalidPersonalFields.length ||
            invalidCompanyFields.length ||
            acceptContractTermsInvalid ||
            selectedAuthInvalid ||
            !this.state.selectedAuth
        ) {
            return false;
        }

        return true;
    }

    signContract = () => {
        clearLocalAlerts();
        if (!this.validateSignContract()) {
            addNewLocalErrorAlert(
                <Trans i18nKey="contractModal.alerts.validationFailed">Lepingu allkirjastamiseks on vaja täita kõik vormil esitatud väljad ja valida allkirjastamismeetod!</Trans>,
                SIGNING_ALERT_GROUP,
                true
            );
            return;
        }

        if (!this.state.selectedAuth) {
            addNewLocalErrorAlert(
                <Trans i18nKey="contractModal.alerts.signMethodNotChosen">Allkirjastamismeetod on valimata!</Trans>,
                SIGNING_ALERT_GROUP,
                true
            );
            return;
        }

        signContract(
            this.props.companyProfile.name,
            this.props.companyProfile.reg_number,
            this.props.companyProfile.kmkr,
            this.props.companyProfile.basis,
            this.props.personalProfile.phone,
            this.props.companyProfile.email,
            this.props.companyProfile.iban,
            this.props.companyProfile.full_address,
            this.props.companyProfile.zip_code,
            this.props.companyProfile.city,
            this.state.contractUrl
        )
            .then(() => {
                if (this.state.selectedAuth === "1") {
                    this.signWithMid();
                } else if (this.state.selectedAuth === "2") {
                    this.signWithSmart();
                } else if (this.state.selectedAuth === "3") {
                    this.signWithIdCard();
                }
            })
            .catch((error) => {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="contractModal.alerts.signContract.genericFailed">Lepingu allkirjastamine ebaõnnestus: {{ errorMessage: error.message }} </Trans>,
                    SIGNING_ALERT_GROUP
                );
                this.setIsSigning(false);
            });
    };

    signWithMid = () => {
        this.setIsSigning(true);
        clearLocalAlerts();

        const mobileNumber = this.props.phoneNumberMID;
        const personalCode = this.props.user.personal_code;
        const cert = this.props.user.cert;

        signMobileId(mobileNumber, personalCode, cert)
            .then(async (data) => {
                clearLocalAlerts();
                if (data.success === true) {
                    const message = <Trans i18nKey="contractModal.alerts.signContract.mobileId.controlCodeInfo">Kontrollkood {{ controlCode: data.control_code }} saadeti numbrile {{ mobileNumber }}. Veendu, et telefonile ilmuv kontrollkood ühtib brauseris nähtavaga.</Trans>;
                    addNewLocalInfoAlert(
                        message,
                        SIGNING_ALERT_GROUP
                    );
                    this.checkSignStatus(data.token, "mobile_id", "-");
                } else {
                    addNewLocalErrorAlert(
                        <Trans i18nKey="contractModal.alerts.signContract.mobileId.unsuccessful">Mobiil-IDga allkirjastamine ebaõnnestus!</Trans>,
                        SIGNING_ALERT_GROUP,
                        true
                    );
                    this.setIsSigning(false);
                }
            })
            .catch((err) => {
                clearLocalAlerts();
                const errorMessage = getServerErrorMessage(err);
                const message = <Trans i18nKey="contractModal.alerts.signContract.mobileIdError">Mobiil-IDga allkirjastamisel ilmnes viga: {{ errorMessage }}</Trans>;
                addNewLocalErrorAlert(
                    message,
                    SIGNING_ALERT_GROUP,
                    true
                );
                this.setIsSigning(false);
            });
    };

    signWithSmart = () => {
        this.setIsSigning(true);
        clearLocalAlerts();

        const personalCode = this.props.user.personal_code;
        const cert = this.props.user.cert;
        signSmartId(personalCode, cert)
            .then(async (data) => {
                clearLocalAlerts();
                if (data.success === true) {
                    const message = <Trans i18nKey="contractModal.alerts.signContract.smartId.controlCodeInfo">Kontrollkood {{ controlCode: data.control_code }} saadeti teie telefoni. Veendu, et telefonile ilmuv kontrollkood ühtib brauseris nähtavaga.</Trans>;
                    addNewLocalInfoAlert(
                        message,
                        SIGNING_ALERT_GROUP
                    );
                    this.checkSignStatus(data.token, "smart_id", "-");
                } else {
                    addNewLocalErrorAlert(
                        <Trans i18nKey="contractModal.alerts.signContract.smartId.unsuccessful">Smart-IDga allkirjastamine ebaõnnestus!</Trans>,
                        SIGNING_ALERT_GROUP,
                        true
                    );
                    this.setIsSigning(false);
                }
            })
            .catch((err) => {
                this.setIsSigning(false);
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="contractModal.alerts.signContract.smartId.error">Smart-IDga allkirjastamisel ilmnes viga!</Trans>,
                    SIGNING_ALERT_GROUP,
                    true
                );
            });
    };

    signWithIdCard = () => {
        const { t } = this.props;
        this.setIsSigning(true);
        const personalCode = this.props.user.personal_code;

        const resolve = (data) => {
            this.setIsSigning(false);

            clearLocalAlerts();
            if (data.success === true) {
                addNewLocalSuccessAlert(
                    t("contractModal.alerts.signContract.IdCard.successful", "Leping edukalt allkirjastatud!"),
                    urls.DASHBOARD
                );
                this.onSignSuccess();
            } else {
                addNewLocalErrorAlert(
                    t("contractModal.alerts.signContract.IdCard.signatureVerificationUnsuccessful", "Allkirja kinnitamine ebaõnnestus!"),
                    SIGNING_ALERT_GROUP,
                    true
                );
            }
        };

        const reject = (error) => {
            this.setIsSigning(false);
            clearLocalAlerts();
            const errorMessage = getIdCardErrorMessage(
                error,
                t("contractModal.alerts.signContract.defaultErrorMessage", "Allkirjastamine ebaõnnestus!")
            );
            addNewLocalErrorAlert(errorMessage, SIGNING_ALERT_GROUP, true);
        };

        addNewLocalInfoAlert(
            t("contractModal.alerts.signContract.idCard.selectCertificateInfo", "Toimub ID-kaardiga allkirjastamine. Kontrollige oma tegumiriba, kui sertifikaadi valimine ei kerki esile."),
            SIGNING_ALERT_GROUP,
            true
        );
        this.dokobit.signWithIdCard(personalCode, resolve, reject);
    };

    checkSignStatus = async (token, mode, identifier) => {
        const { t } = this.props;
        await sleep(1000);

        digitalSignStatus(token, mode, identifier)
            .then((data) => {
                if (data.success === true) {
                    clearLocalAlerts();
                    //this.setIsSigning(false);
                    addNewLocalSuccessAlert(
                        t("contractModal.alerts.signContract.success", "Leping edukalt allkirjastatud!"),
                        urls.DASHBOARD
                    );
                    this.onSignSuccess();
                } else if (data.code === "waiting") {
                    this.checkSignStatus(token, mode, identifier);
                } else if (data.code === "canceled") {
                    clearLocalAlerts();
                    this.setIsSigning(false);
                    addNewLocalErrorAlert(
                        t("contractModal.alerts.signContract.userCancelledError", "Allkirjastamine katkestati!"),
                        SIGNING_ALERT_GROUP,
                        true
                    );
                } else if (data.code === "error") {
                    clearLocalAlerts();
                    this.setIsSigning(false);
                    addNewLocalErrorAlert(
                        t("contractModal.alerts.signContract.defaultErrorMessage", "Allkirjastamine ebaõnnestus!"),
                        SIGNING_ALERT_GROUP,
                        true
                    );
                } else {
                    clearLocalAlerts();
                    this.setIsSigning(false);
                    addNewLocalErrorAlert(
                        t("contractModal.alerts.signContract.defaultErrorMessage", "Allkirjastamine ebaõnnestus!"),
                        SIGNING_ALERT_GROUP,
                        true
                    );
                }
            })
            .catch((error) => {
                clearLocalAlerts();
                this.setIsSigning(false);
                addNewLocalErrorAlert(
                    error.message,
                    SIGNING_ALERT_GROUP
                );
                /*addNewLocalErrorAlert(
                    t("contractModal.alerts.signContract.defaultErrorMessage", "Allkirjastamine ebaõnnestus!"),
                    SIGNING_ALERT_GROUP
                );*/

            });
    };

    hasAllProperties = (object) => {
        return Object.values(object).every(
            (x) => x !== "" && x !== null && x !== undefined
        );
    };

    setIsSigning = (value) => {
        this.setState({ isSigning: value });
    };

    signMID = () => {
        this.setState({ selectedAuth: "1" });
    };

    onSignSuccess = () => {
        const { t } = this.props;
        clearAllAlerts();
        addNewLocalSuccessAlert(t("contractModal.alerts.signContract.success", "Leping edukalt allkirjastatud."), urls.DASHBOARD, true);

        updateCompanyProfile(this.props.companyProfile).catch((err) =>
            addNewLocalErrorAlert(
                getServerErrorMessage(err),
                urls.DASHBOARD,
                true
            )
        );
        getAuthUser().catch((err) =>
            addNewLocalErrorAlert(
                getServerErrorMessage(err),
                urls.DASHBOARD,
                true
            )
        );
        this.props.history.push(urls.INVOICES);
    };

    toggle = () =>
        this.setState(
            { modal: !this.state.modal },
            this.props.history.push(urls.DASHBOARD)
        );

    changeLoginMode = (authOption) => {
        //clearLocalAlerts();
        this.setState({ selectedAuth: authOption });
    };

    isSignWithSmartIdDisabled = () => {
        if (this.state.isSigning) {
            return true;
        }

        const authType = this.props.authType;
        if (authType !== authTypes.SMART && authType !== authTypes.MID) {
            return true;
        }

        return !this.props.user || !this.props.user.cert;
    };

    isSignWithMIDDisabled = () => {
        if (this.state.isSigning) {
            return true;
        }

        const authType = this.props.authType;
        const phoneNumberMID = this.props.phoneNumberMID;
        if (authType !== authTypes.MID || !phoneNumberMID) {
            return true;
        }

        return !this.props.user || !this.props.user.cert;
    };

    isSubmitDisabled = () => {
        return this.state
            .isSigning /* || !this.state.contract || !this.state.contractUrl || !this.state.acceptContractTerms || !this.state.selectedAuth*/;
    };

    renderContractTermsCheckbox() {
        const { t } = this.props;
        const transLabelText = (
            <Trans i18nKey="contractModal.contractTermsLabel">
                Nõustun <a
                    target="_blank"
                    rel="noopener noreferrer"
                    className={this.state.contractUrl ? null : "disabled"}
                    href={this.state.contractUrl}
                >
                    lepingu
                </a> <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={t("contractModal.contractTermsLink", "https://eestipandipakend.ee/dokumendid-ja-kujundusfailid/")}
                >
                    tingimustega
                </a>
            </Trans>
        );
        return (
            <CustomCheckbox
                value={this.state.acceptContractTerms}
                onClick={() =>
                    this.setState({
                        acceptContractTerms: !this.state.acceptContractTerms,
                    })
                }
                labelText={transLabelText}
                invalid={this.state.acceptContractTermsInvalid}
                required={true}
            />
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="contract-modal"
                >
                    <ModalHeader toggle={this.toggle} className="modal-header">
                        <p className="title">{t("contractModal.title", "Leping")}</p>
                        <p className="regular-14">
                            {t("contractModal.headerInfo", "Uute pakendite registreerimiseks on vajalik Teie ettevõttega sõlmida leping. Täitke/kontrollige allolevad väljad ning allkirjastage leping digitaalselt. Lepingu jõustumisest teavitatakse automaatselt e-maili teel.")}
                        </p>
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <PersonalCollapse
                                invalidFields={this.state.invalidPersonalFields}
                            />
                            <CompanyCollapse
                                invalidFields={this.state.invalidCompanyFields}
                            />
                        </div>

                        <Row className="wrapper">
                            <Col md={12} className="text-center">
                                <p className="bold-16">
                                    {t("contractModal.signingSectionHeader", "Lepingu allkirjastamine")}
                                </p>
                                {this.renderContractTermsCheckbox()}
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col className="col-auto pr-1">
                                <CheckboxButton
                                    onClick={() => this.changeLoginMode("1")}
                                    isSelected={this.state.selectedAuth === "1"}
                                    labelText={t("authSignMethod.mobileId", "Mobiil-ID")}
                                    disabled={this.isSignWithMIDDisabled()}
                                    className="auth-option"
                                    invalid={this.state.selectedAuthInvalid}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                            <Col className="col-auto px-1">
                                <CheckboxButton
                                    onClick={() => this.changeLoginMode("2")}
                                    isSelected={this.state.selectedAuth === "2"}
                                    labelText={t("authSignMethod.smartId", "Smart-ID")}
                                    disabled={this.isSignWithSmartIdDisabled()}
                                    className="auth-option"
                                    invalid={this.state.selectedAuthInvalid}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                            <Col className="col-auto px-1">
                                <CheckboxButton
                                    onClick={() => this.changeLoginMode("3")}
                                    isSelected={this.state.selectedAuth === "3"}
                                    labelText={t("authSignMethod.idCard", "ID-kaart")}
                                    disabled={this.state.isSigning}
                                    className="auth-option"
                                    invalid={this.state.selectedAuthInvalid}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                        </Row>
                        <Row className="alert-wrapper">
                            <Col md={12}>
                                <Alerts
                                    hideGlobal={true}
                                    filterGroups={[SIGNING_ALERT_GROUP]}
                                />
                            </Col>
                        </Row>
                        {!this.state.isSigning && (
                            <Row className="wrapper">
                                <Col md={12} className="text-center">
                                    <Button
                                        disabled={this.isSubmitDisabled()}
                                        color="success"
                                        onClick={() => this.signContract()}
                                    >
                                        {t("contractModal.signContractButton", "Allkirjasta")}
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        authType: state.auth.authType,
        phoneNumberMID: state.auth.phoneNumberMID,
        companyProfile: state.profile.companyProfile,
        personalProfile: state.profile.personalProfile,
    };
};

export default withRouter(connect(mapStateToProps)(withTranslation()(ContractModal)));
