import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import onScan from "onscan.js";
import { DATE_FORMAT_DATE_FN } from "util/DateTime"

import {
    fetchAdminRetestingPackages,
    confirmRetestingPackageTests,
} from "util/admin/Retesting";
import urls, { getAdminPackageDetailsUrl } from "config/urls";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";

import {
    adminRetestingPackagesFilterUpdate,
    adminRetestingPackagesFilterClear,
    adminRetestingPackagesPaginationUpdate,
    adminRetestingPackagesSortingUpdate,
    adminRetestingUpdateSelected,
} from "redux/actions/admin/retesting";

import { packageTestStatuses } from "util/Partner";
import { packageStatuses } from "util/Packages";
import { getErrorMessageList } from "util/Errors";

import DatePicker from "react-datepicker";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";

import { Card, CardBody, Row, Col, Button, Label } from "reactstrap";
import Table from "./Table/Table";
import Search from "./Search/Search";

import { ProblemTypes } from "components/Admin/Registry/Testing/TestingView";

class AdminTestingView extends React.Component {
    static propTypes = {
        // redux state
        packagesList: PropTypes.array,
        unfilteredPackagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,
        filters: PropTypes.object.isRequired,
        packagesListFiltersUsed: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        selectedIds: PropTypes.array.isRequired,
        confirmPackageRetestingLoading: PropTypes.bool,
        // redux actions
        adminRetestingPackagesFilterUpdate: PropTypes.func.isRequired,
        adminRetestingPackagesFilterClear: PropTypes.func.isRequired,
        adminRetestingPackagesPaginationUpdate: PropTypes.func.isRequired,
        adminRetestingPackagesSortingUpdate: PropTypes.func.isRequired,
        adminRetestingUpdateSelected: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            confirmPackageTestsStartDate: null,
            isConfirmPackageTestOpen: false,
            isRefreshList: false,
        }
    }

    componentDidMount() {
        fetchAdminRetestingPackages(false, this.props.packagesListFiltersUsed, this.props.location.pathname);

        onScan.attachTo(document, {
            suffixKeyCodes: [13], // enter-key expected at the end of a scan
            onScan: this.onScan, // Alternative to document.addEventListener('scan')
        });
    }

    componentWillUnmount() {
        onScan.detachFrom(document);
    }

    onScan = (sCode, iQty) => {
        clearLocalAlerts();
        const ean = sCode;

        this.props.adminRetestingPackagesFilterClear();
        this.props.adminRetestingPackagesFilterUpdate("ean", ean);

        let message = <div>Skänneeriti EAN <b>"{ean}"</b>.</div>;
        addNewLocalInfoAlert(message, this.props.location.pathname);
        fetchAdminRetestingPackages(false, this.props.filters, this.props.location.pathname);
    }

    render() {
        return (
            <Card className="admin-retesting-card">
                <CardBody className="admin-retesting-card-body px-0">
                    <Search
                        filters={this.props.filters}
                        onFilterUpdate={this.props.adminRetestingPackagesFilterUpdate}
                        onClearFilters={this.props.adminRetestingPackagesFilterClear}
                        packagesListLoading={this.props.packagesListLoading}
                        onSearch={this.onSearch}
                    />
                    <Row className="no-gutters border-top pt-4 pb-3">
                        <Col>
                            <Row className="justify-content-between align-items-center mx-3 px-2 mb-2n">
                                <Col xs={{ size: 12, order: 4 }} md={{ size: 3, order: 1 }} lg={{ size: 2, order: 1 }} className="px-1 mb-2">
                                    <Button
                                        color="link"
                                        disabled={!this.props.selectedIds.length || this.props.confirmPackageRetestingLoading}
                                        onClick={() => this.props.adminRetestingUpdateSelected([])}
                                    >
                                        Tühista valik ({this.props.selectedIds.length})
                                    </Button>
                                </Col>
                                <Col xs={{ size: 12, order: 2 }} md={{ size: 3, order: 3 }} lg={2} className="px-1 mb-2">
                                    {this.renderConfirmPackageTestsButtonAndConfirmModal()}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Table
                                packagesList={this.props.packagesList}
                                packagesListLoading={this.props.packagesListLoading}
                                selectedIds={this.props.selectedIds}
                                onSelect={this.onPackageSelect}
                                onSelectAll={this.onPackageSelectAll}
                                onRowClick={this.onRowClick}
                                sorting={this.props.sorting}
                                onSort={this.onSort}
                                pagination={this.props.pagination}
                                onPaginationChange={this.onPaginationChange}
                            />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        );
    }

    renderConfirmPackageTestsButtonAndConfirmModal() {
        let alertGroup = "adminRetestingConfirmPackageTestModal";
        const selectedIds = this.props.selectedIds;

        const onToggle = () => {
            clearLocalAlerts();

            if (this.state.isRefreshList) {
                fetchAdminRetestingPackages(false, this.props.packagesListFiltersUsed, this.props.location.pathname);
                this.setState({ isRefreshList: false });
            }
            this.setState(prevState => { return { isConfirmPackageTestOpen: !prevState.isConfirmPackageTestOpen } });
        };

        let onConfirmFunc = () => {
            clearLocalAlerts();

            const startDate = this.state.confirmPackageTestsStartDate;
            if (startDate == null) {
                addNewLocalErrorAlert("Müügi alguskuupäev valimata.", alertGroup);
                return;
            }

            confirmRetestingPackageTests(selectedIds, startDate)
                .then(results => {
                    clearLocalAlerts();

                    const allPackages = this.props.unfilteredPackagesList || [];
                    const successResults = results.filter((x) => x.is_success);
                    const errorResults = results.filter(x => !x.is_success);

                    const errorCount = errorResults.length;

                    const successAlertGroup = errorCount > 0 ? alertGroup : this.props.location.pathname;
                    if (successResults.length) {
                        const successfulEANs = successResults.map(result => this.findPackageEANById(allPackages, result.id))
                        let successMessage = `Testitulemused edukalt kinnitatud: ${successfulEANs.join(", ")}.`;
                        addNewLocalSuccessAlert(successMessage, successAlertGroup);
                        this.setState({ isRefreshList: true });
                    }

                    if (errorCount > 0) {
                        const topText = "Esines probleeme järgnevate pakendite kinnitamisega:"
                        const errorContentFunc = result => `${this.findPackageEANById(allPackages, result.id)} - ${result.error}`;
                        const errorMessage = getErrorMessageList(errorResults, errorContentFunc, topText);
                        addNewLocalErrorAlert(errorMessage, successAlertGroup);
                    } else {
                        this.setState({ isConfirmPackageTestOpen: false, isRefreshList: false });
                        fetchAdminRetestingPackages(false, this.props.packagesListFiltersUsed, this.props.location.pathname);
                    }

                })
                .catch(error => {
                    clearLocalAlerts();
                    addNewLocalErrorAlert(error.message, alertGroup);
                });
        };

        let content = <p className="regular-14">Kinnita <b>{selectedIds.length}</b> testitulemused.</p>
        const problems = this.getPossibleConfirmPackageTestsProblems(this.props.selectedIds);
        if (problems.length) {
            content = (
                <>
                    {content}
                    <p className="regular-14">
                        Võimalikud probleemid:
                    </p>
                    <ul>
                        {problems.map((problem, i) => <li className="regular-14" key={i}>{problem}</li>)}
                    </ul>
                </>
            );
        }

        const selectedIdsConfirmTestResults = this.getConfirmTestResultIds(selectedIds);
        const isButtonDisabled = selectedIdsConfirmTestResults.length !== selectedIds.length || !selectedIds.length || this.props.confirmPackageRetestingLoading;

        return (
            <>
                <Button
                    block
                    color="secondary"
                    disabled={isButtonDisabled}
                    onClick={onToggle}
                >
                    {this.props.confirmPackageRetestingLoading ?
                        <LoadingAnimation />
                        :
                        `Kinnita ${selectedIds.length} ${selectedIds.length === 1 ? "testitulemus" : "testitulemust"}`
                    }
                </Button>
                <ConfirmationModal
                    isOpen={this.state.isConfirmPackageTestOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Kinnita"
                    title="Kinnita testitulemused"
                    isDisabled={this.props.confirmPackageRetestingLoading}
                    isLoading={this.props.confirmPackageRetestingLoading}
                    alertGroups={[alertGroup]}
                >
                    <Row>
                        <Col>
                            {content}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="">
                            <Label className="bold-12 color-gray">Müügi alguskuupäev</Label>
                            <DatePicker
                                placeholderText="Müügi alguskuupäev"
                                className="mb-2"
                                selected={this.state.confirmPackageTestsStartDate}
                                onChange={value => this.setState({ confirmPackageTestsStartDate: value })}
                                dateFormat={DATE_FORMAT_DATE_FN}
                            />
                        </Col>
                    </Row>

                </ConfirmationModal>
            </>
        );
    }

    getPossibleGenericProblems = (ids, problemTypes = null) => {
        const allPackages = this.props.unfilteredPackagesList || [];
        const problems = [];
        if (!ids || ids.length === 0 || !allPackages) {
            return problems;
        }

        if (!problemTypes) {
            problemTypes = [ProblemTypes.NO_PACKAGE, ProblemTypes.UNSYNCED, ProblemTypes.DEBT, ProblemTypes.UNSUBMITTED_REPORTS, ProblemTypes.IN_TESTING, ProblemTypes.TEST_UNCONFIRMED, ProblemTypes.FAILED_TEST];
        }
        const noPackageProblem = [];
        const parametersUnsyncedProblemsEAN = [];
        const debtProblemsEAN = [];
        const unsubmittedReportsProblemsEAN = [];
        const unconfirmedTestProblemsEAN = [];
        const failedTestProblemsEAN = [];
        const inTestingProblemsEAN = [];

        for (const id of ids) {
            const pkg = this.findPackageById(allPackages, id);
            if (!pkg) {
                if (problemTypes.includes(ProblemTypes.NO_PACKAGE)) {
                    noPackageProblem.push(id);
                }
            } else {
                const ean = pkg.ean;
                const statusValue = pkg.status && pkg.status.value;
                const lastTestValue = pkg.last_test && pkg.last_test.value;
                if (problemTypes.includes(ProblemTypes.UNSYNCED) && pkg.skip_migration === true) {
                    parametersUnsyncedProblemsEAN.push(ean);
                }
                if (problemTypes.includes(ProblemTypes.UNSUBMITTED_REPORTS) && pkg.unsubmitted_sales_reports > 0) {
                    unsubmittedReportsProblemsEAN.push(ean);
                }
                if (problemTypes.includes(ProblemTypes.DEBT) && Number(pkg.debt) > 0) {
                    debtProblemsEAN.push(ean);
                }
                if (problemTypes.includes(ProblemTypes.IN_TESTING) && packageTestStatuses.NA.includes(lastTestValue)) {
                    inTestingProblemsEAN.push(ean);
                }
                if (problemTypes.includes(ProblemTypes.TEST_UNCONFIRMED) && packageStatuses.UNCONFIRMED === statusValue) {
                    unconfirmedTestProblemsEAN.push(ean);
                }
                if (problemTypes.includes(ProblemTypes.FAILED_TEST) && packageStatuses.WAITING_STATUS === statusValue && packageTestStatuses.NOK.includes(lastTestValue)) {
                    failedTestProblemsEAN.push(ean);
                }
            }
        }

        if (noPackageProblem.length) {
            problems.push(<span><b>{noPackageProblem.length}</b> pakendi andmeid ei leitud nimekirjast.</span>);
        }
        if (parametersUnsyncedProblemsEAN.length) {
            problems.push(<span>Pakendite (EAN) parameetrid ei ole NAV-iga sünkroniseeritud: <b>{parametersUnsyncedProblemsEAN.join(", ")}</b>.</span>);
        }
        if (unsubmittedReportsProblemsEAN.length) {
            problems.push(<span>Pakendite (EAN) klientidel on esitamata müügiaruandeid: <b>{unsubmittedReportsProblemsEAN.join(", ")}</b>.</span>);
        }
        if (debtProblemsEAN.length) {
            problems.push(<span>Pakendite (EAN) klientidel on võlgnevusi: <b>{debtProblemsEAN.join(", ")}</b>.</span>);
        }
        if (inTestingProblemsEAN.length) {
            problems.push(<span>Pakendid (EAN) on testimises: <b>{inTestingProblemsEAN.join(", ")}</b>.</span>);
        }
        if (unconfirmedTestProblemsEAN.length) {
            problems.push(<span>Pakendite (EAN) testitulemus kinnitamata: <b>{unconfirmedTestProblemsEAN.join(", ")}</b>.</span>);
        }
        if (failedTestProblemsEAN.length) {
            problems.push(<span>Pakendite (EAN) viimane test ebaõnnestus: <b>{failedTestProblemsEAN.join(", ")}</b>.</span>);
        }

        return problems;
    }

    findPackageById = (packagesList, id) => {
        for (const pkg of packagesList) {
            if (pkg.id === id) {
                return pkg;
            }
        }
        return null;
    }

    findPackageEANById = (packagesList, id) => {
        const pkg = this.findPackageById(packagesList, id);
        return pkg ? pkg.ean : `${id} (ID)`;
    }

    getPossibleConfirmPackageTestsProblems(ids) {
        const problems = [ProblemTypes.NO_PACKAGE, ProblemTypes.UNSYNCED, ProblemTypes.DEBT, ProblemTypes.UNSUBMITTED_REPORTS, ProblemTypes.IN_TESTING, ProblemTypes.FAILED_TEST];

        return this.getPossibleGenericProblems(ids, problems);
    }

    getConfirmTestResultIds = (ids) => {
        const allPackages = this.props.unfilteredPackagesList;
        if (!ids || ids.length === 0 || !allPackages) {
            return [];
        }

        const idPackages = allPackages.filter(pkg => ids.includes(pkg.id));
        // Different from the testing view where you can check for package status.
        const confirmTestResultsPackages = idPackages.filter(pkg => pkg.tested_at !== null);

        return confirmTestResultsPackages;
    }

    onSearch = () => {
        clearLocalAlerts();
        fetchAdminRetestingPackages(false, this.props.filters, this.props.location.pathname);
    }

    onPaginationChange = (page, sizePerPage) => {
        const currentPagination = this.props.pagination;
        const newPagination = { ...currentPagination, page, sizePerPage };
        this.props.adminRetestingPackagesPaginationUpdate(newPagination);
    }

    onSort = (field, order) => {
        const currentSorting = this.props.sorting;
        if (currentSorting.dataField !== field || currentSorting.order !== order) {
            const newSorting = {
                dataField: field,
                order: order,
            };

            this.props.adminRetestingPackagesSortingUpdate(newSorting);
        }
    }

    onPackageSelect = (row, isSelect, rowIndex, e) => {
        let newSelectedIds = [...this.props.selectedIds];

        if (!isSelect) {
            newSelectedIds = newSelectedIds.filter(id => id !== row.id);
        }
        else if (isSelect && !newSelectedIds.includes(row.id)) {
            newSelectedIds.push(row.id);
        }

        this.props.adminRetestingUpdateSelected(newSelectedIds);
    }

    onPackageSelectAll = (isSelect, rows) => {
        let newSelectedIds = [...this.props.selectedIds];
        const ids = rows.map(row => row.id);

        if (isSelect) {
            newSelectedIds = [...newSelectedIds, ...ids.filter(id => !newSelectedIds.includes(id))];
        }
        else {
            newSelectedIds = newSelectedIds.filter(id => !ids.includes(id));
        }
        this.props.adminRetestingUpdateSelected(newSelectedIds);
    }

    onRowClick = (event, row) => {
        const packageDetailsUrl = getAdminPackageDetailsUrl(row.id);
        this.props.history.push(packageDetailsUrl, { previousViewRoute: urls.EPP_REGISTRY_RETESTING });
    }
}

const mapStateToProps = state => {
    return {
        packagesList: state.admin.retesting.packagesList,
        unfilteredPackagesList: state.admin.retesting.unfilteredPackagesList,
        packagesListLoading: state.admin.retesting.packagesListLoading,
        packagesListFiltersUsed: state.admin.retesting.packagesListFiltersUsed,
        filters: state.admin.retesting.filters,
        sorting: state.admin.retesting.sorting,
        pagination: state.admin.retesting.pagination,
        selectedIds: state.admin.retesting.selectedIds,
        confirmPackageRetestingLoading: state.admin.retesting.confirmPackageRetestingLoading,
    }
};

const mapActionsToProps = {
    adminRetestingPackagesFilterUpdate,
    adminRetestingPackagesFilterClear,
    adminRetestingPackagesPaginationUpdate,
    adminRetestingPackagesSortingUpdate,
    adminRetestingUpdateSelected,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(AdminTestingView));
