import React from "react";

import SummarySearch from "../../components/Reports/Summary/Search/SummarySearch";
import SummaryTable from "../../components/Reports/Summary/Table/SummaryTable";

class SummaryReport extends React.Component {

    render() {
        return (
            <>
                <SummarySearch />
                <div className="mt-3">
                    <SummaryTable />
                </div>
            </>
        );
    }
}

export default SummaryReport;