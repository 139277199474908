import * as actionTypes from "redux/actions/actionTypes";

export const adminClientsFetchStart = () => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FETCH_START,
    };
};

export const adminClientsFetchSuccess = (clients) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FETCH_SUCCESS,
        clients,
    };
};

export const adminClientsFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FETCH_FAIL,
        error,
    };
};

export const adminClientsPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_PAGINATION_UPDATE,
        pagination,
    };
};

export const adminClientsFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const adminClientsSortingUpdate = (sorting) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_SORTING_UPDATE,
        sorting,
    };
};

export const adminPartnersFetchStart = () => {
    return {
        type: actionTypes.ADMIN_PARTNERS_FETCH_START,
    };
};

export const adminPartnersFetchSuccess = (partners) => {
    return {
        type: actionTypes.ADMIN_PARTNERS_FETCH_SUCCESS,
        partners,
    };
};

export const adminPartnersFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_PARTNERS_FETCH_FAIL,
        error,
    };
};

export const adminPartnersUserUpdate = (user) => {
    return {
        type: actionTypes.ADMIN_PARTNER_USER_UPDATE,
        user,
    };
};

export const adminClientUsersFetchStart = () => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FETCH_START,
    };
};

export const adminlientUsersFetchSuccess = (clients) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FETCH_SUCCESS,
        clients,
    };
};

export const adminlientUsersFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_CLIENTS_FETCH_FAIL,
        error,
    };
};

export const adminEppUsersFetchStart = () => {
    return {
        type: actionTypes.ADMIN_EPP_USERS_FETCH_START,
    };
};

export const adminEppUsersFetchSuccess = (eppUsers) => {
    return {
        type: actionTypes.ADMIN_EPP_USERS_FETCH_SUCCESS,
        eppUsers,
    };
};

export const adminEppUsersFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_EPP_USERS_FETCH_FAIL,
        error,
    };
};

export const adminEppUserUpdate = (user) => {
    return {
        type: actionTypes.ADMIN_EPP_USER_UPDATE,
        user,
    };
};
