export const RESET_STORE = "RESET_STORE";

// AUTHENTICATION
export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const LOG_OUT = "LOG_OUT";

// USER
export const GET_AUTH_USER = "GET_AUTH_USER";
export const FETCH_USERS_START = "FETCH_USERS_START";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAIL = "FETCH_USERS_FAIL";
export const UPDATE_USER_START = "UPDATE_USER_START";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

// GENERAL
export const ALL_CLIENTS_FETCH_START = "ALL_CLIENTS_FETCH_START";
export const ALL_CLIENTS_FETCH_SUCCESS = "ALL_CLIENTS_FETCH_SUCCESS";
export const ALL_CLIENTS_FETCH_FAIL = "ALL_CLIENTS_FETCH_FAIL";

export const STANDARD_COMMENTS_FETCH_START = "STANDARD_COMMENTS_FETCH_START";
export const STANDARD_COMMENTS_FETCH_SUCCESS =
    "STANDARD_COMMENTS_FETCH_SUCCESS";
export const STANDARD_COMMENTS_FETCH_FAIL = "STANDARD_COMMENTS_FETCH_FAIL";

export const LOCATIONS_FETCH_START = "LOCATIONS_FETCH_START";
export const LOCATIONS_FETCH_SUCCESS = "LOCATIONS_FETCH_SUCCESS";
export const LOCATIONS_FETCH_FAIL = "LOCATIONS_FETCH_FAIL";

export const LANGUAGE_UPDATE = "LANGUAGE_UPDATE";

// NAVIGATION
export const NAVIGATION_UPDATE_HEADER_SUFFIX =
    "NAVIGATION_UPDATE_HEADER_SUFFIX";

// ALERTS
export const ALERTS_ADD_GLOBAL = "ALERTS_ADD_GLOBAL";
export const ALERTS_ADD_LOCAL = "ALERTS_ADD_LOCAL";
export const ALERTS_CLEAR_ALL = "ALERTS_CLEAR_ALL";
export const ALERTS_CLEAR_GLOBAL = "ALERTS_CLEAR_GLOBAL";
export const ALERTS_CLEAR_LOCAL = "ALERTS_CLEAR_LOCAL";
export const ALERTS_CLEAR_INCLUDED_GROUPS = "ALERTS_CLEAR_INCLUDED_GROUPS";
export const ALERTS_CLEAR_UNINCLUDED_GROUPS = "ALERTS_CLEAR_UNINCLUDED_GROUPS";
export const ALERTS_UPDATE_ACTIVE_GROUPS = "ALERTS_UPDATE_ACTIVE_GROUPS";

// PACKAGES
export const PACKAGES_FETCH_START = "PACKAGES_FETCH_START";
export const PACKAGES_FETCH_SUCCESS = "PACKAGES_FETCH_SUCCESS";
export const PACKAGES_FETCH_FAIL = "PACKAGES_FETCH_FAIL";

export const EAN_TYPES_FETCH_START = "EAN_TYPES_FETCH_START";
export const EAN_TYPES_FETCH_SUCCESS = "EAN_TYPES_FETCH_SUCCESS";
export const EAN_TYPES_FETCH_FAIL = "EAN_TYPES_FETCH_FAIL";

export const PRODUCT_CATEGORIES_FETCH_START = "PRODUCT_CATEGORIES_FETCH_START";
export const PRODUCT_CATEGORIES_FETCH_SUCCESS =
    "PRODUCT_CATEGORIES_FETCH_SUCCESS";
export const PRODUCT_CATEGORIES_FETCH_FAIL = "PRODUCT_CATEGORIES_FETCH_FAIL";

export const PACKAGE_TYPES_FETCH_START = "PACKAGE_TYPES_FETCH_START";
export const PACKAGE_TYPES_FETCH_SUCCESS = "PACKAGE_TYPES_FETCH_SUCCESS";
export const PACKAGE_TYPES_FETCH_FAIL = "PACKAGE_TYPES_FETCH_FAIL";

export const PACKAGE_COLORS_FETCH_START = "PACKAGE_COLORS_FETCH_START";
export const PACKAGE_COLORS_FETCH_SUCCESS = "PACKAGE_COLORS_FETCH_SUCCESS";
export const PACKAGE_COLORS_FETCH_FAIL = "PACKAGE_COLORS_FETCH_FAIL";

export const PACKAGE_VOLUMES_FETCH_START = "PACKAGE_VOLUMES_FETCH_START";
export const PACKAGE_VOLUMES_FETCH_SUCCESS = "PACKAGE_VOLUMES_FETCH_SUCCESS";
export const PACKAGE_VOLUMES_FETCH_FAIL = "PACKAGE_VOLUMES_FETCH_FAIL";

export const PACKAGE_STATUSES_FETCH_START = "PACKAGE_STATUSES_FETCH_START";
export const PACKAGE_STATUSES_FETCH_SUCCESS = "PACKAGE_STATUSES_FETCH_SUCCESS";
export const PACKAGE_STATUSES_FETCH_FAIL = "PACKAGE_STATUSES_FETCH_FAIL";

export const PACKAGE_TEST_RESULTS_FETCH_START =
    "PACKAGE_TEST_RESULTS_FETCH_START";
export const PACKAGE_TEST_RESULTS_FETCH_SUCCESS =
    "PACKAGE_TEST_RESULTS_FETCH_SUCCESS";
export const PACKAGE_TEST_RESULTS_FETCH_FAIL =
    "PACKAGE_TEST_RESULTS_FETCH_FAIL";

export const PACKAGE_POST_START = "PACKAGE_POST_START";
export const PACKAGE_POST_SUCCESS = "PACKAGE_POST_SUCCESS";
export const PACKAGE_POST_FAIL = "PACKAGE_POST_FAIL";

// INVOICES
export const INVOICES_FETCH_START = "INVOICES_FETCH_START";
export const INVOICES_FETCH_SUCCESS = "INVOICES_FETCH_SUCCESS";
export const INVOICES_FETCH_FAIL = "INVOICES_FETCH_FAIL";

export const INVOICE_DATA_FETCH_START = "INVOICES_DATA_FETCH_START";
export const INVOICE_DATA_FETCH_SUCCESS = "INVOICES_DATA_FETCH_SUCCESS";
export const INVOICE_DATA_FETCH_FAIL = "INVOICES_DATA_FETCH_FAIL";

// REPORTS

// SALES REPORTS
export const SALES_FETCH_START = "SALES_FETCH_START";
export const SALES_FETCH_SUCCESS = "SALES_FETCH_SUCCESS";
export const SALES_FETCH_FAIL = "SALES_FETCH_FAIL";

export const SALES_SEARCH_FILTER_UPDATE = "SALES_SEARCH_FILTER_UPDATE";
export const SALES_CLEAR_FILTERS = "SALES_CLEAR_FILTERS";

export const SALES_CREATE_REPAIR_START = "SALES_CREATE_REPAIR_START";
export const SALES_CREATE_REPAIR_SUCCESS = "SALES_CREATE_REPAIR_SUCCESS";
export const SALES_CREATE_REPAIR_FAIL = "SALES_CREATE_REPAIR_FAIL";

// SALES REPORT DETAILS
export const SALES_REPORT_FETCH_START = "SALES_REPORT_FETCH_START";
export const SALES_REPORT_FETCH_SUCCESS = "SALES_REPORT_FETCH_SUCCESS";
export const SALES_REPORT_FETCH_FAIL = "SALES_REPORT_FETCH_FAIL";
export const SALES_REPORT_PACKAGES_FIELD_CHANGE =
    "SALES_REPORT_PACKAGES_FIELD_CHANGE";
export const SALES_REPORT_HEADER_FIELD_CHANGE =
    "SALES_REPORT_HEADER_FIELD_CHANGE";
export const SALES_REPORT_HEADER_TOTALS_UPDATE =
    "SALES_REPORT_HEADER_TOTALS_UPDATE";
export const SALES_LINE_UPDATE_START = "SALES_LINE_UPDATE_START";
export const SALES_LINE_UPDATE_SUCCESS = "SALES_LINE_UPDATE_SUCCESS";
export const SALES_LINE_UPDATE_FAIL = "SALES_LINE_UPDATE_FAIL";
export const SALES_REPORT_SUBMIT_START = "SALES_REPORT_SUBMIT_START";
export const SALES_REPORT_SUBMIT_SUCCESS = "SALES_REPORT_SUBMIT_SUCCESS";
export const SALES_REPORT_SUBMIT_FAIL = "SALES_REPORT_SUBMIT_FAIL";
export const SALES_REPORT_DELETE_ORIGINAL_START =
    "SALES_REPORT_DELETE_ORIGINAL_START";
export const SALES_REPORT_DELETE_ORIGINAL_SUCCESS =
    "SALES_REPORT_DELETE_ORIGINAL_SUCCESS";
export const SALES_REPORT_DELETE_ORIGINAL_FAIL =
    "SALES_REPORT_DELETE_ORIGINAL_FAIL";
export const SALES_REPORT_DELETE_REPAIR_START =
    "SALES_REPORT_DELETE_REPAIR_START";
export const SALES_REPORT_DELETE_REPAIR_SUCCESS =
    "SALES_REPORT_DELETE_REPAIR_SUCCESS";
export const SALES_REPORT_DELETE_REPAIR_FAIL =
    "SALES_REPORT_DELETE_REPAIR_FAIL";
export const SALES_REPORT_EXPORT_START = "SALES_REPORT_EXPORT_START";
export const SALES_REPORT_EXPORT_SUCCESS = "SALES_REPORT_EXPORT_SUCCESS";
export const SALES_REPORT_EXPORT_FAIL = "SALES_REPORT_EXPORT_FAIL";

// SUMMARY REPORT
export const SUMMARY_REPORT_SEARCH_START = "SUMMARY_REPORT_SEARCH_START";
export const SUMMARY_REPORT_SEARCH_SUCCESS = "SUMMARY_REPORT_SEARCH_SUCCESS";
export const SUMMARY_REPORT_SEARCH_FAIL = "SUMMARY_REPORT_SEARCH_FAIL";

export const SUMMARY_REPORT_SEARCH_FILTER_UPDATE =
    "SUMMARY_REPORT_SEARCH_FILTER_UPDATE";

export const SUMMARY_REPORT_EXPORT_START = "SUMMARY_REPORT_EXPORT_START";
export const SUMMARY_REPORT_EXPORT_SUCCESS = "SUMMARY_REPORT_EXPORT_SUCCESS";
export const SUMMARY_REPORT_EXPORT_FAIL = "SUMMARY_REPORT_EXPORT_FAIL";

export const SALES_REPORT_IMPORT_OPEN = "SALES_REPORT_IMPORT_OPEN";
export const SALES_REPORT_IMPORT_FILE_CHANGE =
    "SALES_REPORT_IMPORT_FILE_CHANGE";
export const SALES_REPORT_IMPORT_START = "SALES_REPORT_IMPORT_START";
export const SALES_REPORT_IMPORT_SUCCESS = "SALES_REPORT_IMPORT_SUCCESS";
export const SALES_REPORT_IMPORT_FAIL = "SALES_REPORT_IMPORT_FAIL";
export const SALES_REPORT_IMPORT_CLOSE = "SALES_REPORT_IMPORT_CLOSE";

// MASS REPORT
export const MASS_REPORT_SEARCH_START = "MASS_REPORT_SEARCH_START";
export const MASS_REPORT_SEARCH_SUCCESS = "MASS_REPORT_SEARCH_SUCCESS";
export const MASS_REPORT_SEARCH_FAIL = "MASS_REPORT_SEARCH_FAIL";

export const MASS_REPORT_SEARCH_FILTER_UPDATE =
    "MASS_REPORT_SEARCH_FILTER_UPDATE";
export const MASS_REPORT_UPDATE_FORMAT = "MASS_REPORT_UPDATE_FORMAT";

export const MASS_REPORT_EXPORT_START = "MASS_REPORT_EXPORT_START";
export const MASS_REPORT_EXPORT_SUCCESS = "MASS_REPORT_EXPORT_SUCCESS";
export const MASS_REPORT_EXPORT_FAIL = "MASS_REPORT_EXPORT_FAIL";

// PROFILE
export const PERSONAL_PROFILE_FETCH_START = "PERSONAL_PROFILE_FETCH_START";
export const PERSONAL_PROFILE_FETCH_SUCCESS = "PERSONAL_PROFILE_FETCH_SUCCESS";
export const PERSONAL_PROFILE_FETCH_FAIL = "PERSONAL_PROFILE_FETCH_FAIL";
export const PERSONAL_PROFILE_UPDATE = "PERSONAL_PROFILE_UPDATE";
export const COMPANY_PROFILE_FETCH_START = "COMPANY_PROFILE_FETCH_START";
export const COMPANY_PROFILE_FETCH_SUCCESS = "COMPANY_PROFILE_FETCH_SUCCESS";
export const COMPANY_PROFILE_FETCH_FAIL = "COMPANY_PROFILE_FETCH_FAIL";
export const COMPANY_PROFILE_UPDATE = "COMPANY_PROFILE_UPDATE";
export const CONTRACTS_FETCH_START = "CONTRACTS_FETCH_START";
export const CONTRACTS_FETCH_SUCCESS = "CONTRACTS_FETCH_SUCCESS";
export const CONTRACTS_FETCH_FAIL = "CONTRACTS_FETCH_FAIL";
export const REPRESENTATION_OPTIONS_FETCH_START =
    "REPRESENTATION_OPTIONS_FETCH_START";
export const REPRESENTATION_OPTIONS_FETCH_SUCCESS =
    "REPRESENTATION_OPTIONS_FETCH_SUCCESS";
export const REPRESENTATION_OPTIONS_FETCH_FAIL =
    "REPRESENTATION_OPTIONS_FETCH_FAIL";

export const USER_SETTINGS_FETCH_START = "USER_SETTINGS_FETCH_START";
export const USER_SETTINGS_FETCH_SUCCESS = "USER_SETTINGS_FETCH_SUCCESS";
export const USER_SETTINGS_FETCH_FAIL = "USER_SETTINGS_FETCH_FAIL";
export const USER_SETTINGS_PUT_START = "USER_SETTINGS_PUT_START";
export const USER_SETTINGS_PUT_SUCCESS = "USER_SETTINGS_PUT_SUCCESS";
export const USER_SETTINGS_PUT_FAIL = "USER_SETTINGS_PUT_FAIL";

//DASHBOARD
export const LAST_PACKAGES_FETCH_START = "LAST_PACKAGES_FETCH_START";
export const LAST_PACKAGES_FETCH_SUCCESS = "LAST_PACKAGES_FETCH_SUCCESS";
export const LAST_PACKAGES_FETCH_FAIL = "LAST_PACKAGES_FETCH_FAIL";
export const LAST_REPORTS_FETCH_START = "LAST_REPORTS_FETCH_START";
export const LAST_REPORTS_FETCH_SUCCESS = "LAST_REPORTS_FETCH_SUCCESS";
export const LAST_REPORTS_FETCH_FAIL = "LAST_REPORTS_FETCH_FAIL";
export const STATISTICS_FETCH_START = "STATISTICS_FETCH_START";
export const STATISTICS_FETCH_SUCCESS = "STATISTICS_FETCH_SUCCESS";
export const STATISTICS_FETCH_FAIL = "STATISTICS_FETCH_FAIL";

// PARTNER
export const PACKAGES_IN_TEST_FETCH_START = "PACKAGES_IN_TEST_FETCH_START";
export const PACKAGES_IN_TEST_FETCH_SUCCESS = "PACKAGES_IN_TEST_FETCH_SUCCESS";
export const PACKAGES_IN_TEST_FETCH_FAIL = "PACKAGES_IN_TEST_FETCH_FAIL";
export const PACKAGES_IN_TEST_FILTER_UPDATE = "PACKAGES_IN_TEST_FILTER_UPDATE";
export const PACKAGES_IN_TEST_FILTER_CLEAR = "PACKAGES_IN_TEST_FILTER_CLEAR";
export const PACKAGES_IN_TEST_SORTING_UPDATE = "PACKAGES_IN_TEST_SORTING_UPDATE";
export const PACKAGES_IN_TEST_PAGINATION_UPDATE = "PACKAGES_IN_TEST_PAGINATION_UPDATE";
// export const PACKAGES_IN_TEST_EXPORT_START = "PACKAGES_IN_TEST_EXPORT_START";
// export const PACKAGES_IN_TEST_EXPORT_SUCCESS = "PACKAGES_IN_TEST_EXPORT_SUCCESS";
// export const PACKAGES_IN_TEST_EXPORT_FAIL = "PACKAGES_IN_TEST_EXPORT_FAIL";

export const PACKAGES_IN_RETEST_FETCH_START = "PACKAGES_IN_RETEST_FETCH_START";
export const PACKAGES_IN_RETEST_FETCH_SUCCESS = "PACKAGES_IN_RETEST_FETCH_SUCCESS";
export const PACKAGES_IN_RETEST_FETCH_FAIL = "PACKAGES_IN_RETEST_FETCH_FAIL";
export const PACKAGES_IN_RETEST_FILTER_UPDATE = "PACKAGES_IN_RETEST_FILTER_UPDATE";
export const PACKAGES_IN_RETEST_FILTER_CLEAR = "PACKAGES_IN_RETEST_FILTER_CLEAR";
export const PACKAGES_IN_RETEST_SORTING_UPDATE = "PACKAGES_IN_RETEST_SORTING_UPDATE";
export const PACKAGES_IN_RETEST_PAGINATION_UPDATE = "PACKAGES_IN_RETEST_PAGINATION_UPDATE";

export const PACKAGES_UNCONFIRMED_FETCH_START =
    "PACKAGES_UNCONFIRMED_FETCH_START";
export const PACKAGES_UNCONFIRMED_FETCH_SUCCESS =
    "PACKAGES_UNCONFIRMED_FETCH_SUCCESS";
export const PACKAGES_UNCONFIRMED_FETCH_FAIL =
    "PACKAGES_UNCONFIRMED_FETCH_FAIL";
export const PACKAGES_UNCONFIRMED_FILTER_UPDATE =
    "PACKAGES_UNCONFIRMED_FILTER_UPDATE";
export const PACKAGES_UNCONFIRMED_FILTER_CLEAR =
    "PACKAGES_UNCONFIRMED_FILTER_CLEAR";
export const PACKAGES_UNCONFIRMED_SORTING_UPDATE = "PACKAGES_UNCONFIRMED_SORTING_UPDATE";
export const PACKAGES_UNCONFIRMED_PAGINATION_UPDATE = "PACKAGES_UNCONFIRMED_PAGINATION_UPDATE";

export const PACKAGES_TESTED_FETCH_START = "PACKAGES_TESTED_FETCH_START";
export const PACKAGES_TESTED_FETCH_SUCCESS = "PACKAGES_TESTED_FETCH_SUCCESS";
export const PACKAGES_TESTED_FETCH_FAIL = "PACKAGES_TESTED_FETCH_FAIL";
export const PACKAGES_TESTED_FILTER_UPDATE = "PACKAGES_TESTED_FILTER_UPDATE";
export const PACKAGES_TESTED_FILTER_CLEAR = "PACKAGES_TESTED_FILTER_CLEAR";
export const PACKAGES_TESTED_SORTING_UPDATE = "PACKAGES_TESTED_SORTING_UPDATE";
export const PACKAGES_TESTED_PAGINATION_UPDATE = "PACKAGES_TESTED_PAGINATION_UPDATE";

export const PACKAGES_TESTING_ALL_FETCH_START =
    "PACKAGES_TESTING_ALL_FETCH_START";
export const PACKAGES_TESTING_ALL_FETCH_SUCCESS =
    "PACKAGES_TESTING_ALL_FETCH_SUCCESS";
export const PACKAGES_TESTING_ALL_FETCH_FAIL =
    "PACKAGES_TESTING_ALL_FETCH_FAIL";
export const PACKAGES_TESTING_ALL_FILTER_UPDATE =
    "PACKAGES_TESTING_ALL_FILTER_UPDATE";
export const PACKAGES_TESTING_ALL_FILTER_CLEAR =
    "PACKAGES_TESTING_ALL_FILTER_CLEAR";
    export const PACKAGES_TESTING_ALL_SORTING_UPDATE = "PACKAGES_TESTING_ALL_SORTING_UPDATE";
    export const PACKAGES_TESTING_ALL_PAGINATION_UPDATE = "PACKAGES_TESTING_ALL_PAGINATION_UPDATE";

// ADMIN
//ADMIN APPLICATIONS
export const ADMIN_APPLICATIONS_FETCH_START = "ADMIN_APPLICATIONS_FETCH_START";
export const ADMIN_APPLICATIONS_FETCH_SUCCESS =
    "ADMIN_APPLICATIONS_FETCH_SUCCESS";
export const ADMIN_APPLICATIONS_FETCH_FAIL = "ADMIN_APPLICATIONS_FETCH_FAIL";

export const ADMIN_APPLICATIONS_UPDATE_SELECTED =
    "ADMIN_APPLICATIONS_UPDATE_SELECTED";

export const ADMIN_APPLICATIONS_FILTER_UPDATE =
    "ADMIN_APPLICATIONS_FILTER_UPDATE";
export const ADMIN_APPLICATIONS_FILTER_CLEAR =
    "ADMIN_APPLICATIONS_FILTER_CLEAR";
export const ADMIN_APPLICATIONS_SORTING_UPDATE =
    "ADMIN_APPLICATIONS_SORTING_UPDATE";
export const ADMIN_APPLICATIONS_PAGINATION_UPDATE =
    "ADMIN_APPLICATIONS_PAGINATION_UPDATE";

export const ADMIN_SEND_TO_TESTING_START = "ADMIN_SEND_TO_TESTING_START";
export const ADMIN_SEND_TO_TESTING_SUCCESS = "ADMIN_SEND_TO_TESTING_SUCCESS";
export const ADMIN_SEND_TO_TESTING_FAIL = "ADMIN_SEND_TO_TESTING_FAIL";

// ADMIN TESTING
export const ADMIN_TESTING_PACKAGES_FETCH_START =
    "ADMIN_TESTING_PACKAGES_FETCH_START";
export const ADMIN_TESTING_PACKAGES_FETCH_SUCCESS =
    "ADMIN_TESTING_PACKAGES_FETCH_SUCCESS";
export const ADMIN_TESTING_PACKAGES_FETCH_FAIL =
    "ADMIN_TESTING_PACKAGES_FETCH_FAIL";

export const ADMIN_TESTING_PACKAGES_FILTER_UPDATE =
    "ADMIN_TESTING_PACKAGES_FILTER_UPDATE";
export const ADMIN_TESTING_PACKAGES_FILTER_CLEAR =
    "ADMIN_TESTING_PACKAGES_FILTER_CLEAR";
export const ADMIN_TESTING_PACKAGES_SORTING_UPDATE =
    "ADMIN_TESTING_PACKAGES_SORTING_UPDATE";
export const ADMIN_TESTING_PACKAGES_PAGINATION_UPDATE =
    "ADMIN_TESTING_PACKAGES_PAGINATION_UPDATE";

export const ADMIN_TESTING_UPDATE_SELECTED = "ADMIN_TESTING_UPDATE_SELECTED";

export const ADMIN_TESTING_SEND_TO_RETESTING_START =
    "ADMIN_TESTING_SEND_TO_RETESTING_START";
export const ADMIN_TESTING_SEND_TO_RETESTING_SUCCESS =
    "ADMIN_TESTING_SEND_TO_RETESTING_SUCCESS";
export const ADMIN_TESTING_SEND_TO_RETESTING_FAIL =
    "ADMIN_TESTING_SEND_TO_RETESTING_FAIL";

export const ADMIN_TESTING_CONFIRM_PACKAGE_TEST_START =
    "ADMIN_TESTING_CONFIRM_PACKAGE_TEST_START";
export const ADMIN_TESTING_CONFIRM_PACKAGE_TEST_SUCCESS =
    "ADMIN_TESTING_CONFIRM_PACKAGE_TEST_SUCCESS";
export const ADMIN_TESTING_CONFIRM_PACKAGE_TEST_FAIL =
    "ADMIN_TESTING_CONFIRM_PACKAGE_TEST_FAIL";

export const ADMIN_TESTING_ACTIVATE_PACKAGES_START =
    "ADMIN_TESTING_ACTIVATE_PACKAGES_START";
export const ADMIN_TESTING_ACTIVATE_PACKAGES_SUCCESS =
    "ADMIN_TESTING_ACTIVATE_PACKAGES_SUCCESS";
export const ADMIN_TESTING_ACTIVATE_PACKAGES_FAIL =
    "ADMIN_TESTING_ACTIVATE_PACKAGES_FAIL";

// ADMIN RETESTING
export const ADMIN_RETESTING_UPDATE_SELECTED = "ADMIN_RETESTING_UPDATE_SELECTED";

export const ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_START =
    "ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_START";
export const ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_SUCCESS =
    "ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_SUCCESS";
export const ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_FAIL =
    "ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_FAIL";

export const ADMIN_RETESTING_PACKAGES_FETCH_START =
    "ADMIN_RETESTING_PACKAGES_FETCH_START";
export const ADMIN_RETESTING_PACKAGES_FETCH_SUCCESS =
    "ADMIN_RETESTING_PACKAGES_FETCH_SUCCESS";
export const ADMIN_RETESTING_PACKAGES_FETCH_FAIL =
    "ADMIN_RETESTING_PACKAGES_FETCH_FAIL";

export const ADMIN_RETESTING_PACKAGES_FILTER_UPDATE =
    "ADMIN_RETESTING_PACKAGES_FILTER_UPDATE";
export const ADMIN_RETESTING_PACKAGES_FILTER_CLEAR =
    "ADMIN_RETESTING_PACKAGES_FILTER_CLEAR";
export const ADMIN_RETESTING_PACKAGES_SORTING_UPDATE =
    "ADMIN_RETESTING_PACKAGES_SORTING_UPDATE";
export const ADMIN_RETESTING_PACKAGES_PAGINATION_UPDATE =
    "ADMIN_RETESTING_PACKAGES_PAGINATION_UPDATE";

// ADMIN ALL PACKAGES
export const ADMIN_ALL_PACKAGES_FETCH_START = "ADMIN_ALL_PACKAGES_FETCH_START";
export const ADMIN_ALL_PACKAGES_FETCH_SUCCESS =
    "ADMIN_ALL_PACKAGES_FETCH_SUCCESS";
export const ADMIN_ALL_PACKAGES_FETCH_FAIL = "ADMIN_ALL_PACKAGES_FETCH_FAIL";

export const ADMIN_ALL_PACKAGES_FILTER_UPDATE =
    "ADMIN_ALL_PACKAGES_FILTER_UPDATE";
export const ADMIN_ALL_PACKAGES_FILTER_CLEAR =
    "ADMIN_ALL_PACKAGES_FILTER_CLEAR";
export const ADMIN_ALL_PACKAGES_SORTING_UPDATE =
    "ADMIN_ALL_PACKAGES_SORTING_UPDATE";
export const ADMIN_ALL_PACKAGES_PAGINATION_UPDATE =
    "ADMIN_ALL_PACKAGES_PAGINATION_UPDATE";

export const ADMIN_DEBTS_FETCH_START = "ADMIN_DEBTS_FETCH_START";
export const ADMIN_DEBTS_FETCH_SUCCESS = "ADMIN_DEBTS_FETCH_SUCCESS";
export const ADMIN_DEBTS_FETCH_FAIL = "ADMIN_DEBTS_FETCH_FAIL";
export const ADMIN_DEBTS_FILTER_UPDATE = "ADMIN_DEBTS_FILTER_UPDATE";
export const ADMIN_DEBTS_FILTER_CLEAR = "ADMIN_DEBTS_FILTER_CLEAR";

export const ADMIN_CLIENTS_FETCH_START = "ADMIN_CLIENTS_FETCH_START";
export const ADMIN_CLIENTS_FETCH_SUCCESS = "ADMIN_CLIENTS_FETCH_SUCCESS";
export const ADMIN_CLIENTS_FETCH_FAIL = "ADMIN_CLIENTS_FETCH_FAIL";
export const ADMIN_CLIENTS_PAGINATION_UPDATE =
    "ADMIN_CLIENTS_PAGINATION_UPDATE";
export const ADMIN_CLIENTS_SORTING_UPDATE = "ADMIN_CLIENTS_SORTING_UPDATE";
export const ADMIN_CLIENTS_FILTER_UPDATE = "ADMIN_CLIENTS_FILTER_UPDATE";

export const ADMIN_PARTNERS_FETCH_START = "ADMIN_PARTNERS_FETCH_START";
export const ADMIN_PARTNERS_FETCH_SUCCESS = "ADMIN_PARTNERS_FETCH_SUCCESS";
export const ADMIN_PARTNERS_FETCH_FAIL = "ADMIN_PARTNERS_FETCH_FAIL";

export const ADMIN_PARTNER_USER_UPDATE = "ADMIN_PARTNER_USER_UPDATE";

export const ADMIN_EPP_USERS_FETCH_START = "ADMIN_EPP_USERS_FETCH_START";
export const ADMIN_EPP_USERS_FETCH_SUCCESS = "ADMIN_EPP_USERS_FETCH_SUCCESS";
export const ADMIN_EPP_USERS_FETCH_FAIL = "ADMIN_EPP_USERS_FETCH_FAIL";

export const ADMIN_EPP_USER_UPDATE = "ADMIN_EPP_USER_UPDATE";

// ADMIN SALES REPORTS

export const ADMIN_SALES_REPORTS_FETCH_START =
    "ADMIN_SALES_REPORTS_FETCH_START";
export const ADMIN_SALES_REPORTS_FETCH_SUCCESS =
    "ADMIN_SALES_REPORTS_FETCH_SUCCESS";
export const ADMIN_SALES_REPORTS_FETCH_FAIL = "ADMIN_SALES_REPORTS_FETCH_FAIL";

export const ADMIN_SALES_REPORTS_FILTER_UPDATE =
    "ADMIN_SALES_REPORTS_FILTER_UPDATE";
export const ADMIN_SALES_REPORTS_FILTER_CLEAR =
    "ADMIN_SALES_REPORTS_FILTER_CLEAR";

export const ADMIN_UNSUBMITTED_SALES_REPORTS_FETCH_START =
    "ADMIN_UNSUBMITTED_SALES_REPORTS_FETCH_START";
export const ADMIN_UNSUBMITTED_SALES_REPORTS_FETCH_SUCCESS =
    "ADMIN_UNSUBMITTED_SALES_REPORTS_FETCH_SUCCESS";
export const ADMIN_UNSUBMITTED_SALES_REPORTS_FETCH_FAIL =
    "ADMIN_UNSUBMITTED_SALES_REPORTS_FETCH_FAIL";

export const ADMIN_UNSUBMITTED_SALES_REPORTS_FILTER_UPDATE =
    "ADMIN_UNSUBMITTED_SALES_REPORTS_FILTER_UPDATE";
export const ADMIN_UNSUBMITTED_SALES_REPORTS_FILTER_CLEAR =
    "ADMIN_UNSUBMITTED_SALES_REPORTS_FILTER_CLEAR";

export const ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_START =
    "ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_START";
export const ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_SUCCESS =
    "ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_SUCCESS";
export const ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_FAIL =
    "ADMIN_UNCONFIRMED_SALES_REPORTS_FETCH_FAIL";

export const ADMIN_UNCONFIRMED_SALES_REPORTS_FILTER_UPDATE =
    "ADMIN_UNCONFIRMED_SALES_REPORTS_FILTER_UPDATE";
export const ADMIN_UNCONFIRMED_SALES_REPORTS_FILTER_CLEAR =
    "ADMIN_UNCONFIRMED_SALES_REPORTS_FILTER_CLEAR";

// ADMIN SETTINGS
export const ADMIN_CATEGORIES_FETCH_START = "ADMIN_CATEGORIES_FETCH_START";
export const ADMIN_CATEGORIES_FETCH_SUCCESS = "ADMIN_CATEGORIES_FETCH_SUCCESS";
export const ADMIN_CATEGORIES_FETCH_FAIL = "ADMIN_CATEGORIES_FETCH_FAIL";

export const ADMIN_COLORS_FETCH_START = "ADMIN_COLORS_FETCH_START";
export const ADMIN_COLORS_FETCH_SUCCESS = "ADMIN_COLORS_FETCH_SUCCESS";
export const ADMIN_COLORS_FETCH_FAIL = "ADMIN_COLORS_FETCH_FAIL";

export const ADMIN_PROCESS_FEES_FETCH_START = "ADMIN_PROCESS_FEES_FETCH_START";
export const ADMIN_PROCESS_FEES_FETCH_SUCCESS =
    "ADMIN_PROCESS_FEES_FETCH_SUCCESS";
export const ADMIN_PROCESS_FEES_FETCH_FAIL = "ADMIN_PROCESS_FEES_FETCH_FAIL";

export const ADMIN_TYPES_FETCH_START = "ADMIN_TYPES_FETCH_START";
export const ADMIN_TYPES_FETCH_SUCCESS = "ADMIN_TYPES_FETCH_SUCCESS";
export const ADMIN_TYPES_FETCH_FAIL = "ADMIN_TYPES_FETCH_FAIL";

export const ADMIN_VOLUMES_FETCH_START = "ADMIN_VOLUMES_FETCH_START";
export const ADMIN_VOLUMES_FETCH_SUCCESS = "ADMIN_VOLUMES_FETCH_SUCCESS";
export const ADMIN_VOLUMES_FETCH_FAIL = "ADMIN_VOLUMES_FETCH_FAIL";

// NOTIFICATIONS
export const NOTIFICATIONS_FETCH_START = "NOTIFICATIONS_FETCH_START";
export const NOTIFICATIONS_FETCH_SUCCESS = "NOTIFICATIONS_FETCH_SUCCESS";
export const NOTIFICATIONS_FETCH_FAIL = "NOTIFICATIONS_FETCH_FAIL";
