import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Row,
    Col,
} from "reactstrap";

import WrappedLoader from "components/Loader/WrappedLoader";
import { ReactComponent as MoreVerticalIcon } from "assets/icons/more-vertical.svg";
import AddUserModal from "components/Profile/Users/AddUserModal/AddUserModal";
import UserDetailsModal from "components/Profile/Users/UserDetailsModal/UserDetailsModal";

import { fetchUsers, removeUser } from "util/Profile";
import {
    clearLocalAlerts,
    addNewLocalInfoAlert,
    addNewLocalErrorAlert,
} from "util/Alerts";
import { isUserClientAdmin, isPartnerMode } from "util/Access";

import "./UsersTable.scss";
import StatusCell, {
    statusTypes,
} from "components/Table/Cell/Status/StatusCell";
import { withTranslation } from "react-i18next";

import moment from "moment";
import { DATE_FORMAT } from "util/DateTime";

class UsersTable extends React.Component {
    state = {
        isAddUserModalOpen: false,
        isUserDetailsModalOpen: false,
        selectedUser: {},
    };

    componentDidMount() {
        fetchUsers();
    }

    static NoDataIndication(props) {
        let content = props.t("users.emptyTableText", "Ei leitud teisi kasutajat.");
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    render() {
        const { t } = this.props;
        return (
            <Card className="users-card">
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <span className="heading">
                                {t("users.allUsers", "Kõik kasutajad")}
                            </span>
                        </Col>
                        {this.showAddUserButton() && (
                            <Col className="float-right text-right col-auto">
                                <Button
                                    color="success"
                                    onClick={this.toggleAddUserModal}
                                >
                                    {t("buttons.addUser", "Lisa kasutaja")}
                                </Button>
                            </Col>
                        )}
                    </Row>
                </CardHeader>
                <CardBody className="p-0">{this.renderTable()}</CardBody>
                <AddUserModal
                    userRole="CLIENT"
                    isOpen={this.state.isAddUserModalOpen}
                    toggle={this.toggleAddUserModal}
                />
                <UserDetailsModal
                    userRole="CLIENT"
                    user={this.state.selectedUser}
                    isOpen={this.state.isUserDetailsModalOpen}
                    toggle={this.toggleUserDetailsModal}
                />
            </Card>
        );
    }

    renderTable() {
        const { t } = this.props;
        if (this.props.usersLoading) {
            return <WrappedLoader />;
        }

        const StyledDropdownItem = (props) => (
            <DropdownItem {...props} className="semibold-14 text-muted">
                {props.children}
            </DropdownItem>
        );

        const dropdownFormatter = (user) => {
            return (
                <UncontrolledButtonDropdown
                    direction="left"
                    className="sales-actions"
                    onClick={(e) => e.stopPropagation()}
                >
                    <DropdownToggle tag="div">
                        <Button
                            color="link"
                            className="icon-default sales-actions-icon-button"
                        >
                            <MoreVerticalIcon />
                        </Button>
                    </DropdownToggle>
                    <DropdownMenu>
                        <StyledDropdownItem
                            onClick={() => this.onOpenUserDetails(user)}
                        >
                            {t("users.look", "Vaata")}
                        </StyledDropdownItem>
                        {this.showRemoveUserButton() && (
                            <StyledDropdownItem
                                onClick={() => this.onRemoveUser(user)}
                            >
                                {t("users.removeUser", "Eemalda kasutaja")}
                            </StyledDropdownItem>
                        )}
                    </DropdownMenu>
                </UncontrolledButtonDropdown>
            );
        };

        const usersEndpointDateFormat = "DD.MM.YYYY";

        const columns = [
            {
                dataField: "last_name",
                text: t("users.name", "Nimi"),
                sort: true,
                classes: "semibold-14",
                formatter: (last_name, user) =>
                    `${user.name} ${user.last_name}`,
            },
            {
                dataField: "type",
                text: t("users.role", "Roll"),
                sort: true,
            },
            {
                dataField: "position",
                text: t("users.profession", "Ametinimetus"),
                sort: true,
            },
            {
                dataField: "date_joined",
                text: t("users.dateJoined", "Liitus"),
                sort: true,
                formatter: invalidated_at => moment(invalidated_at, usersEndpointDateFormat).format(DATE_FORMAT),
                sortValue: invalidated_at => moment(invalidated_at, usersEndpointDateFormat),
            },
            {
                dataField: "email",
                text: t("users.email", "Email"),
                sort: true,
            },
            {
                dataField: "is_verified",
                text: t("users.verified", "Kinnitatud"),
                sort: true,
                formatter: (is_verified) => (
                    <StatusCell
                        status={
                            is_verified
                                ? t("users.verified", "Kinnitatud")
                                : t("users.notVerified", "Kinnitamata")
                        }
                        type={
                            is_verified
                                ? statusTypes.SUCCESS
                                : statusTypes.DEFAULT
                        }
                    />
                ),
            },
            {
                dataField: "",
                text: "",
                align: "right",
                formatter: (field, row) => dropdownFormatter(row),
            },
        ];

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onOpenUserDetails(row);
            },
        };

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="users-table no-top-border"
                keyField="id"
                data={this.props.users}
                columns={columns}
                rowEvents={rowEvents}
                noDataIndication={
                    <UsersTable.NoDataIndication
                        loading={this.props.usersLoading}
                        t={t}
                    />
                }
            />
        );
    }

    showAddUserButton = () => {
        return isUserClientAdmin() || isPartnerMode();
    };

    showRemoveUserButton = () => {
        return isUserClientAdmin() || isPartnerMode();
    };

    toggleAddUserModal = () => {
        this.setState((prevState) => {
            return {
                isAddUserModalOpen: !prevState.isAddUserModalOpen,
            };
        });
    };

    toggleUserDetailsModal = () => {
        this.setState((prevState) => {
            return {
                isUserDetailsModalOpen: !prevState.isUserDetailsModalOpen,
            };
        });
    };

    onOpenUserDetails = (user) => {
        clearLocalAlerts();
        this.setState({
            isUserDetailsModalOpen: true,
            selectedUser: user,
        });
    };

    onRemoveUser = (user) => {
        clearLocalAlerts();
        removeUser(user.user_id)
            .then((resp) => {
                fetchUsers();
                addNewLocalInfoAlert(
                    this.props.t("users.userRemoved", "Kasutaja eemaldatud!"),
                    this.props.location.pathname
                );
            })
            .catch((error) => {
                addNewLocalErrorAlert(
                    error.message,
                    this.props.location.pathname
                );
            });
    };
}

const mapStateToProps = (state) => {
    return {
        users: state.profile.users,
        usersLoading: state.profile.usersLoading,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(UsersTable))
);
