import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

import { Row, Col, Button, Modal, ModalBody, Card, CardHeader, CardBody } from "reactstrap";

import LeftText from "components/Misc/LeftText";
import DeleteText from "components/Misc/DeleteText";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";
import InputField from "components/Form/Input";

import urls from "config/urls";
import {
    addNewLocalSuccessAlert,
    clearLocalAlerts,
} from "util/Alerts";
import { isAdminMode } from "util/Access";
import { withTranslation } from "react-i18next";
import {
} from "util/DateTime";

// Module specific
import reportUtils from "./utils";
import ActionsMenu from "./ActionsMenu";
import ReportLineList from "./ReportLineList";

// css
import "./DetailsView.scss";

const deleteAlertGroup = "delete-original-modal";

class ReturnReportsDetailsView extends React.Component {
    static propTypes = {
        id: PropTypes.number.isRequired
    }

    constructor(props) {
        super(props);

        this.state = {
            details: {},
            isDetailsLoading: false,
            deleteModalOpen: false,
            isDeleteLoading: false
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        const { t } = this.props;
        const { details, isDetailsLoading } = this.state;
        return (
            <div className="return-report-view">
                <Row className="justify-content-between">
                    <Col xs="auto">
                        <LeftText
                            onClick={this.redirectToListView}
                            text={t("buttons.back", "Tagasi")}
                        />
                    </Col>
                    {this.isDeletable() && (
                        <Col className="col-auto">
                            <DeleteText
                                text="Kustuta aruanne"
                                onClick={this.onOpenDeleteModal}
                                disabled={
                                    this.props.isDeleteLoading
                                }
                            />
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col>
                        <Card className="return-report-view__card">
                            <CardHeader>
                                {this.renderHeader()}
                            </CardHeader>
                            <CardBody className="px-0">
                                {this.renderExtraActions()}
                                <ReportLineList lines={details?.lines} isLoading={isDetailsLoading} onCommentCountUpdate={this.onCommentCountUpdate}></ReportLineList>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                {this.renderDeleteModal()}
            </div>
        );
    }

    renderHeader() {
        const { t } = this.props;
        const { details } = this.state;
        const isAdmin = isAdminMode();

        return (
            <Row className="align-items-end px-2">
                <Col md="3" className="px-2">
                    <InputField
                        disabled
                        labelText={t("reports.clientCode")}
                        value={details.client?.client_id}
                    />
                </Col>
                <Col md="3" className="px-2">
                    <InputField
                        disabled
                        labelText={t("reports.period", "Periood")}
                        value={this.getPeriodFormatted()}
                    />
                </Col>
                <Col md={isAdmin ? "4" : ""} className="px-2">
                    <InputField
                        disabled
                        labelText={t("reports.company", "Firma")}
                        value={details.client?.name}
                    />
                </Col>
                {
                    isAdmin &&
                    <Col md="2" className="px-2 pt-2">
                        <reportUtils.ReturnReportStatus details={details} inlineStyle={{
                            height: "40px",
                            marginBottom: "8px",
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            minWidth: "100%"
                        }} />
                    </Col>
                }
            </Row>
        )
    }

    renderDeleteModal() {
        const { t } = this.props;
        const { details, isDeleteLoading } = this.state;

        return (
            <Modal
                isOpen={this.state.deleteModalOpen}
                onToggle={this.onCancelDelete}
                backdrop="static"
                className="return-report-details__delete-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">
                                Tagastusaruande kustutamine
                            </span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onCancelDelete}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts
                                hideGlobal={true}
                                filterGroups={[deleteAlertGroup]}
                            />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            <p className="regular-14">
                                Kas soovite kustutada tagastusaruande{" "}
                                <b>{this.getPeriodFormatted()}</b>?
                            </p>
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                outline
                                block
                                onClick={this.onCancelDelete}
                                disabled={isDeleteLoading}
                            >
                                {t("buttons.cancel", "Tühista")}
                            </Button>
                        </Col>
                        <Col md="6" className="mb-2">
                            <Button
                                color="success"
                                block
                                onClick={this.onDelete}
                                disabled={isDeleteLoading}
                            >
                                {isDeleteLoading ? (
                                    <LoadingAnimation />
                                ) : (
                                    "Kustuta tagastusaruanne"
                                )}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

    renderExtraActions() {
        const { details } = this.state;
        if (!details?.id) {
            return;
        }

        return (
            <div className="border-top py-3 px-4">
                <div className="px-3 mx-1">
                    <ActionsMenu
                        details={details}
                        isDetailsViewMode={true}
                        onConfirmDetailsSuccess={this.onConfirmSuccess}
                        onImportSuccess={this.onImportSuccess}
                        className="px-2"
                    />
                </div>
            </div>
        )
    }

    onConfirmSuccess = (list) => {
        this.fetchData(false);
    }

    onImportSuccess = (statistics) => {
        const content = <reportUtils.ReturnReportImportSuccess statistics={statistics} />
        addNewLocalSuccessAlert(content, this.props.location.pathname)
        this.fetchData(false);
    }

    fetchData = (loading = true) => {
        const { id } = this.props;
        if (!id) {
            return;
        }
        const alertGroup = this.props.location.pathname;
        if (loading) {
            this.setState({ isDetailsLoading: true });
        }
        reportUtils
            .fetchDetails(id, alertGroup)
            .then((result) => {
                if (result.isSuccess) {
                    this.updateDetails(result.details)
                }
            })
            .finally(() => {
                if (loading) {
                    this.setState({ isDetailsLoading: false });
                }
            });
    };

    updateDetails = details => {
        this.setState({ details });
    }

    isDeletable = () => {
        return (
            isAdminMode() && !!this.state.details?.id
        );
    };

    onOpenDeleteModal = () => {
        this.setState({
            deleteModalOpen: true,
        });
    };

    onCancelDelete = () => {
        if (this.state.isDeleteLoading) {
            return;
        }
        this.setState({
            deleteModalOpen: false,
        });
        clearLocalAlerts();
    };

    onDelete = () => {
        const id = this.state.details?.id;
        if (!id || this.state.isDeleteLoading) {
            return;
        }
        this.setState({
            isDeleteLoading: true
        });
        clearLocalAlerts();
        reportUtils.deleteDetails(id, deleteAlertGroup).then((result) => {
            if (result.isSuccess) {
                addNewLocalSuccessAlert(
                    "Tagastusaruande kustutamine õnnestus.",
                    urls.REPORTS_SALES,
                    false
                );
                document.body.classList.remove("modal-open");
                this.redirectToListView();
            }
        })
            .finally(() => {
                this.setState({ isDeleteLoading: false });
            });
    };

    redirectToListView = () => {
        const route = reportUtils.getListViewUrl();
        this.props.history.push(route);
    };

    getPeriodFormatted() {
        // Frontend should show localized months instead of numeric period from backend
        const { details } = this.state;
        if (details?.period_start && details?.period_end) {
            const periodStartFormatted = moment(details.period_start).format("MMMM YYYY");
            const periodEndFormatted = moment(details.period_end).format("MMMM YYYY");
            return `${periodStartFormatted} - ${periodEndFormatted}`;
        } else {
            return null;
        }
    }

    onCommentCountUpdate = (line, count) => {
        this.setState((prevState) => {
            return {
                details: {
                    ...prevState.details,
                    lines: prevState.details.lines.map(currentLine => currentLine.id === line.id ? {
                        ...currentLine,
                        comments_count: count
                    } : currentLine),
                }
            }
        })
    }
}

const mapStateToProps = (state) => {
    return {
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation()(ReturnReportsDetailsView))
);
