import * as actionTypes from "redux/actions/actionTypes";
import _ from "lodash";
import { updateObject } from "redux/utility";

import { testStatuses, getPageCount } from "util/General";

const initialFilters = {
    ean: "",
    name: "",
    client: null,
    category: null,
    type: null,
    conditions: [],
    testResult: null,
    //startDate: null,
    //endDate: null,
};

const initialSorting = {
    dataField: "date",
    order: "desc",
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ],
};

const initialState = {
    filters: initialFilters,
    sorting: initialSorting,
    pagination: initialPagination,

    unfilteredPackagesList: null,
    packagesList: null,
    packagesListFiltersUsed: initialFilters,
    packagesListLoading: false,
    loading: false,
    error: null,
    sendToRetestingLoading: false,
    selectedIds: [],
    activatePackagesLoading: false,
    confirmPackageTestingLoading: false,
};

const filterIdsInList = (ids, packages) => {
    const packageIds = packages.map((pkg) => pkg.id);
    return ids.filter((id) => packageIds.includes(id));
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const onAdminTestingPackagesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        packagesListLoading: true,
        error: null,
        packagesList: null,
    });
};

const onAdminTestingPackagesFetchSuccess = (state, action) => {
    const areFilterSame = _.isEqual(
        state.packagesListFiltersUsed,
        action.filters
    );

    const newDataSize = action.packagesList.length;
    const currentPage = state.pagination.page;
    const sizePerPage = state.pagination.sizePerPage;
    const isCurrentPageValid =
        currentPage <= getPageCount(newDataSize, sizePerPage);

    return updateObject(state, {
        loading: false,
        packagesListLoading: false,
        error: null,
        packagesList: action.packagesList,
        unfilteredPackagesList: action.unfilteredPackagesList,
        packagesListFiltersUsed: action.filters,
        pagination: {
            ...state.pagination,
            page: areFilterSame && isCurrentPageValid ? currentPage : 1,
            dataSize: newDataSize,
        },
        selectedIds: filterIdsInList(
            state.selectedIds,
            action.unfilteredPackagesList
        ),
    });
};

const onAdminTestingPackagesFetchFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        packagesListLoading: false,
        selectedIds: [],
    });
};

const onAdminTestingPackagesFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
    });
};

const onAdminTestingPackagesSortingUpdate = (state, action) => {
    return updateObject(state, {
        sorting: action.sorting,
    });
};

const onAdminTestingPackagesPaginationUpdate = (state, action) => {
    return updateObject(state, {
        pagination: action.pagination,
    });
};

const onAdminTestingPackagesFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
    });
};

const onAdminTestingUpdateSelected = (state, action) => {
    return updateObject(state, {
        selectedIds: action.selected,
    });
};

const updatePackageListFieldValues = (packagesList, ids, field, value) => {
    for (const pkg of packagesList) {
        if (ids.includes(pkg.id)) {
            pkg[field] = value;
        }
    }

    return packagesList;
};

const onAdminSendToTestingSuccess = (state, action) => {
    const idsToRemove = action.ids;
    if (state.packagesList) {
        let newPackagesList = [...state.packagesList];

        return updateObject(state, {
            packagesList: updatePackageListFieldValues(
                newPackagesList,
                idsToRemove,
                "last_test",
                testStatuses.NA
            ),
        });
    } else {
        return state;
    }
};

const onAdminTestingSendToRetestingStart = (state) => {
    return updateObject(state, {
        sendToRetestingLoading: true,
    });
};

const onAdminTestingSendToRetestingSuccess = (state, action) => {
    const idsToRemove = action.ids;
    let newPackagesList = [...state.packagesList];

    return updateObject(state, {
        sendToRetestingLoading: false,
        packagesList: updatePackageListFieldValues(
            newPackagesList,
            idsToRemove,
            "last_test",
            testStatuses.NA
        ),
        selectedIds: state.selectedIds.filter(
            (id) => !idsToRemove.includes(id)
        ),
    });
};

const onAdminTestingSendToRetestingFail = (state, action) => {
    return updateObject(state, {
        sendToRetestingLoading: false,
    });
};

const onAdminTestingConfirmPackageTestStart = (state) => {
    return updateObject(state, {
        confirmPackageTestingLoading: true,
    });
};

const onAdminTestingConfirmPackageTestSuccess = (state, action) => {
    const idsToRemove = action.ids;

    return updateObject(state, {
        confirmPackageTestingLoading: false,
        selectedIds: state.selectedIds.filter(
            (id) => !idsToRemove.includes(id)
        ),
    });
};

const onAdminTestingConfirmPackageTestFail = (state, action) => {
    return updateObject(state, {
        confirmPackageTestingLoading: false,
    });
};

const onAdminTestingActivatePackagesStart = (state) => {
    return updateObject(state, {
        activatePackagesLoading: true,
    });
};

const onAdminTestingActivatePackagesSuccess = (state, action) => {
    const idsToRemove = action.ids;

    let newPackagesList = null;
    let newDataSize = 0;
    if (state.packagesList) {
        newPackagesList = state.packagesList.filter(
            (pkg) => !idsToRemove.includes(pkg.id)
        );
        newDataSize = newPackagesList.length;
    }

    const currentPage = state.pagination.page;
    const sizePerPage = state.pagination.sizePerPage;
    const isCurrentPageValid =
        currentPage <= getPageCount(newDataSize, sizePerPage);

    return updateObject(state, {
        activatePackagesLoading: false,
        packagesList: newPackagesList,
        selectedIds: state.selectedIds.filter(
            (id) => !idsToRemove.includes(id)
        ),
        pagination: {
            ...state.pagination,
            page: isCurrentPageValid ? currentPage : 1,
            dataSize: newDataSize,
        },
    });
};

const onAdminTestingActivatePackagesFail = (state, action) => {
    return updateObject(state, {
        activatePackagesLoading: false,
    });
};

export const testing = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.ADMIN_TESTING_PACKAGES_FETCH_START:
            return onAdminTestingPackagesFetchStart(state, action);
        case actionTypes.ADMIN_TESTING_PACKAGES_FETCH_SUCCESS:
            return onAdminTestingPackagesFetchSuccess(state, action);
        case actionTypes.ADMIN_TESTING_PACKAGES_FETCH_FAIL:
            return onAdminTestingPackagesFetchFail(state, action);
        // FILTER
        case actionTypes.ADMIN_TESTING_PACKAGES_FILTER_UPDATE:
            return onAdminTestingPackagesFilterUpdate(state, action);
        case actionTypes.ADMIN_TESTING_PACKAGES_FILTER_CLEAR:
            return onAdminTestingPackagesFilterClear(state, action);
        // SORT
        case actionTypes.ADMIN_TESTING_PACKAGES_SORTING_UPDATE:
            return onAdminTestingPackagesSortingUpdate(state, action);
        // PAGINATION
        case actionTypes.ADMIN_TESTING_PACKAGES_PAGINATION_UPDATE:
            return onAdminTestingPackagesPaginationUpdate(state, action);
        // UPDATE SELECTED
        case actionTypes.ADMIN_TESTING_UPDATE_SELECTED:
            return onAdminTestingUpdateSelected(state, action);
        // SEND TO TESTING (send to retesting in package details and go back to testing list)
        case actionTypes.ADMIN_SEND_TO_TESTING_SUCCESS:
            return onAdminSendToTestingSuccess(state, action);
        // SEND TO RETESTING
        case actionTypes.ADMIN_TESTING_SEND_TO_RETESTING_START:
            return onAdminTestingSendToRetestingStart(state, action);
        case actionTypes.ADMIN_TESTING_SEND_TO_RETESTING_SUCCESS:
            return onAdminTestingSendToRetestingSuccess(state, action);
        case actionTypes.ADMIN_TESTING_SEND_TO_RETESTING_FAIL:
            return onAdminTestingSendToRetestingFail(state, action);
        // CONFIRM PACKAGE TESTS
        case actionTypes.ADMIN_TESTING_CONFIRM_PACKAGE_TEST_START:
            return onAdminTestingConfirmPackageTestStart(state, action);
        case actionTypes.ADMIN_TESTING_CONFIRM_PACKAGE_TEST_SUCCESS:
            return onAdminTestingConfirmPackageTestSuccess(state, action);
        case actionTypes.ADMIN_TESTING_CONFIRM_PACKAGE_TEST_FAIL:
            return onAdminTestingConfirmPackageTestFail(state, action);
        // ACTIVATE PACKAGES
        case actionTypes.ADMIN_TESTING_ACTIVATE_PACKAGES_START:
            return onAdminTestingActivatePackagesStart(state, action);
        case actionTypes.ADMIN_TESTING_ACTIVATE_PACKAGES_SUCCESS:
            return onAdminTestingActivatePackagesSuccess(state, action);
        case actionTypes.ADMIN_TESTING_ACTIVATE_PACKAGES_FAIL:
            return onAdminTestingActivatePackagesFail(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
