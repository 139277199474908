import axios from "../axios";
import {
    CREATE_CONTRACT,
    SIGN_CONTRACT,
    VERIFY_CONTRACT_SIGNER,
} from "../config/Api";
import { getServerErrorMessage } from "./Errors";

export function createContract(
    name,
    regCode,
    kmkr,
    right,
    phone,
    email,
    iban,
    address,
    zip_code,
    city
) {
    return axios
        .post(CREATE_CONTRACT, {
            new_company: name,
            new_reg_code: regCode,
            new_address: address,
            basis: right,
            new_phone: phone,
            new_email: email,
            new_iban: iban,
            new_kmkr: kmkr,
            zip_code: zip_code,
            city: city,
        })
        .then((resp) => {
            return resp;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signContract(
    name,
    regCode,
    kmkr,
    right,
    phone,
    email,
    iban,
    address,
    zip_code,
    city,
    url
) {
    return axios
        .post(SIGN_CONTRACT, {
            new_company: name,
            new_reg_code: regCode,
            new_address: address,
            basis: right,
            new_phone: phone,
            new_email: email,
            new_iban: iban,
            new_kmkr: kmkr,
            zip_code: zip_code,
            city: city,
            url: url,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function verifyContractSigner(isVerified, userid, clientId) {
    return axios
        .post(VERIFY_CONTRACT_SIGNER, {
            is_verified: isVerified,
            user_id: userid,
            client_id: Number(clientId),
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}
