import * as actionTypes from 'redux/actions/actionTypes';

export const massReportSearchStart = () => {
    return {
        type: actionTypes.MASS_REPORT_SEARCH_START
    }
};

export const massReportSearchSuccess = (massList, fromDate, toDate) => {
    return {
        type: actionTypes.MASS_REPORT_SEARCH_SUCCESS,
        massList, fromDate, toDate,
    }
};

export const massReportSearchFail = (err) => {
    return {
        type: actionTypes.MASS_REPORT_SEARCH_FAIL,
        error: err
    }
};

export const massReportSearchFilterUpdate = (key, value) => {
    return {
        type: actionTypes.MASS_REPORT_SEARCH_FILTER_UPDATE,
        key: key,
        value: value,
    }
}

export const massReportUpdateFormat = (format) => {
    return {
        type: actionTypes.MASS_REPORT_UPDATE_FORMAT,
        format,
    }
}

export const massReportExportStart = () => {
    return {
        type: actionTypes.MASS_REPORT_EXPORT_START
    }
};

export const massReportExportSuccess = (exportFile) => {
    return {
        type: actionTypes.MASS_REPORT_EXPORT_SUCCESS,
        exportFile: exportFile
    }
};

export const massReportExportFail = (err) => {
    return {
        type: actionTypes.MASS_REPORT_EXPORT_FAIL,
        error: err
    }
};