import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Input, Row, Col } from "reactstrap";

import Alert from "components/Alerts/Alert/Alert";
import { alertType } from "util/Alerts";
import urls from "config/urls";


class EmailForm extends Component {
    render() {
        return (
            <>
                <Row>
                    <Col>
                        <Alert
                            type={alertType.INFO_ICON}
                            className={`${alertType.INFO_ICON} mb-3`}
                            content="E-mailiga sisselogimiseks on vaja olemasolevat kasutajat. Uue kasutaja saab luua logides sisse Mobiil-ID, Smart-ID või ID-kaardiga."
                        />
                    </Col>
                </Row>

                <p className="login-credential">KASUTAJATUNNUS</p>
                <Input name="username" onChange={this.props.handleChanged} className="login-input"
                    placeholder="Kasutajatunnus" />
                <Row>
                    <Col>
                        <span className="login-credential">PAROOL</span>
                    </Col>
                    <Col className="float-right text-right col-auto">
                        <Link className="regular-10 color-gray" to={urls.FORGOT_PASSWORD}>Unustasid parooli?</Link>
                    </Col>
                </Row>
                <Input name="password" type="password" onChange={this.props.handleChanged} className="login-input"
                    placeholder="Parool" />
            </>
        );
    }
}

export default EmailForm;
