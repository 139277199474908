import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Card, CardBody, Row, Col, Button } from "reactstrap";
import "./DebtsSearch.scss";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Select from "components/Form/Select";
import Input from "components/Form/Input";

import {
    adminDebtsFilterUpdate,
    adminDebtsFilterClear,
} from "redux/actions/admin/debts";

import { fetchDebts } from "util/admin/Debts";
import { fetchAllClients } from "util/General";
import { clearLocalAlerts } from "util/Alerts";
import { YEAR_MONTH_FORMAT_DATE_FN } from "util/DateTime";

class DebtsSearch extends React.Component {
    componentDidMount() {
        fetchAllClients(this.props.allClients);
    }

    render() {
        return (
            <Card className="sales-search-card mb-3">
                <CardBody className="">
                    <Row className="align-items-center mx-0">
                        <Col className="search-period-text bold-19 col-md-12 col-lg-auto mb-2 px-2">
                            <span className="">Periood</span>
                        </Col>
                        <Col lg="3" md="12">
                            <Row>
                                <Col lg="6" md="6" className="mb-2 px-2">
                                    <DatePicker
                                        className="search-input"
                                        selected={this.props.filters.fromDate}
                                        onChange={(date) =>
                                            this.props.adminDebtsFilterUpdate(
                                                "fromDate",
                                                date
                                            )
                                        }
                                        placeholderText="Alates"
                                        dateFormat={YEAR_MONTH_FORMAT_DATE_FN}
                                        showMonthYearPicker
                                        maxDate={
                                            this.props.filters.toDate
                                                ? moment(
                                                    this.props.filters.toDate
                                                ).toDate()
                                                : new Date()
                                        }
                                    />
                                </Col>
                                <Col lg="6" md="6" className="mb-2 px-2">
                                    <DatePicker
                                        className="search-input"
                                        selected={this.props.filters.toDate}
                                        onChange={(date) =>
                                            this.props.adminDebtsFilterUpdate(
                                                "toDate",
                                                date
                                            )
                                        }
                                        placeholderText="Kuni"
                                        dateFormat={YEAR_MONTH_FORMAT_DATE_FN}
                                        showMonthYearPicker
                                        minDate={
                                            this.props.filters.fromDate &&
                                            moment(this.props.filters.fromDate).toDate()
                                        }
                                        maxDate={new Date()}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="2" md="6" className="px-2">
                            <Select
                                options={this.props.allClients}
                                value={this.props.filters.client}
                                isSearchable
                                isClearable
                                placeholder="Ettevõtte nimi"
                                getOptionLabel={(option) => option.label}
                                getOptionValue={(option) => option.value}
                                className=""
                                onChange={(client) => this.props.adminDebtsFilterUpdate("client", client)}
                            />
                        </Col>
                        <Col lg="3" md="6">
                            <Row>
                                <Col lg="6" xs="6" className="px-2">
                                    <Input
                                        value={this.props.filters.daysOverdueMin}
                                        placeholder="Üle tähtaja algus"
                                        type="number"
                                        onBlur={value => {
                                            this.props.adminDebtsFilterUpdate("daysOverdueMin", value);
                                        }}
                                    />
                                </Col>
                                <Col lg="6" xs="6" className="px-2">
                                    <Input
                                        value={this.props.filters.daysOverdueMax}
                                        placeholder="Üle tähtaja lõpp"
                                        type="number"
                                        onBlur={value => {
                                            this.props.adminDebtsFilterUpdate("daysOverdueMax", value);
                                        }}
                                    />
                                </Col>

                            </Row>
                        </Col>
                        <Col lg="" md="6" className="mb-2 px-2">
                            <Button
                                block
                                color="success"
                                className="search-button"
                                onClick={this.onSearch}
                                disabled={this.props.debtsListLoadng}
                            >
                                Otsi
                                {/* {this.props.debtsListLoading ? <SmallLoader /> : "Otsi"} */}
                            </Button>
                        </Col>
                        <Col lg="" md="6" className="ml-md-auto mb-2 px-2">
                            <Button
                                outline
                                block
                                color="success"
                                className="search-button"
                                onClick={this.props.adminDebtsFilterClear}
                            >
                                Tühjenda otsing
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card >
        );
    }

    onSearch = () => {
        clearLocalAlerts();
        this.getDebtsList();
    };

    getDebtsList = () => {
        fetchDebts(this.props.filters, this.props.location.pathname);
    };
}

const mapStateToProps = (state) => {
    return {
        allClients: state.general.allClients,
        filters: state.admin.debts.filters,
        debtsListLoadng: state.admin.debts.loading,
    };
};

const reduxActions = {
    adminDebtsFilterUpdate,
    adminDebtsFilterClear,
};

export default withRouter(connect(mapStateToProps, reduxActions)(DebtsSearch));
