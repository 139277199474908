import axios from "../axios";
import store from "redux/store";

import { getServerErrorMessage } from "util/Errors";
import {
    PACKAGES_IN_TEST_OR_RETEST,
    PACKAGES_UNCONFIRMED,
    PACKAGES_TESTED,
    PACKAGES_TESTING_ALL,
    PACKAGE_TEST,
} from "config/Api";
import { statusTypes } from "components/Table/Cell/Status/StatusCell";
import {
    packagesInTestFetchStart,
    packagesInTestFetchSuccess,
    packagesInTestFetchFail,
} from "redux/actions/partner/packagesInTest";
import {
    packagesInRetestFetchStart,
    packagesInRetestFetchSuccess,
    packagesInRetestFetchFail,
} from "redux/actions/partner/packagesInRetest";
import {
    packagesUnconfirmedFetchStart,
    packagesUnconfirmedFetchSuccess,
    packagesUnconfirmedFetchFail,
} from "redux/actions/partner/packagesUnconfirmed";
import {
    packagesTestedFetchStart,
    packagesTestedFetchSuccess,
    packagesTestedFetchFail,
} from "redux/actions/partner/packagesTested";
import {
    packagesTestingAllFetchStart,
    packagesTestingAllFetchSuccess,
    packagesTestingAllFetchFail,
} from "redux/actions/partner/packagesTestingAll";
import { secondDateIsSameOrAfterFirstDate } from "util/DateTime";
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

export const packageTestStatuses = {
    OK: [1, "1", "OK"],
    NOK: [2, "2", "NOK"],
    NA: [3, "3", "NA", "N/A"],
};
export function getTestStatusType(status) {
    if (packageTestStatuses.OK.includes(status)) {
        return statusTypes.SUCCESS;
    } else if (packageTestStatuses.NOK.includes(status)) {
        return statusTypes.ERROR;
    } else if (packageTestStatuses.NA.includes(status)) {
        return statusTypes.DEFAULT;
    }

    return statusTypes.DEFAULT;
}

function fetchPackagesInTestAndOrRetest(type="ALL") {
    /**
     * Accepted type values are:
     * "ALL" - for all package tests
     * "TESTING" - for package tests in testing
     * "RETESTING" - for package tests in retesting
     */
    return axios.get(PACKAGES_IN_TEST_OR_RETEST, {params: { type }});
}


export function fetchPackagesInTest(filters, alertGroup) {
    const filtersToUse = { ...filters };
    store.dispatch(packagesInTestFetchStart());
    return fetchPackagesInTestAndOrRetest("TESTING")
        .then((response) => {
            const unfilteredPackages = response.data;
            const filteredPackages = filterPackages(
                unfilteredPackages,
                filtersToUse,
                "created_at"
            );
            store.dispatch(
                packagesInTestFetchSuccess(
                    filteredPackages,
                    filtersToUse,
                    unfilteredPackages
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(packagesInTestFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function fetchPackagesInRetest(filters, alertGroup) {
    const filtersToUse = { ...filters };
    store.dispatch(packagesInRetestFetchStart());
    return fetchPackagesInTestAndOrRetest("RETESTING")
        .then((response) => {
            const unfilteredPackages = response.data;
            const filteredPackages = filterPackages(
                unfilteredPackages,
                filtersToUse,
                "created_at"
            );
            store.dispatch(
                packagesInRetestFetchSuccess(
                    filteredPackages,
                    filtersToUse,
                    unfilteredPackages
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(packagesInRetestFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function fetchPackagesUnconfirmed(filters, alertGroup) {
    const filtersToUse = { ...filters };
    store.dispatch(packagesUnconfirmedFetchStart());
    return axios
        .get(PACKAGES_UNCONFIRMED)
        .then((response) => {
            const unfilteredPackages = response.data;
            const filteredPackages = filterPackages(
                unfilteredPackages,
                filtersToUse,
                "created_at"
            );
            store.dispatch(
                packagesUnconfirmedFetchSuccess(
                    filteredPackages,
                    filtersToUse,
                    unfilteredPackages
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(packagesUnconfirmedFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function fetchPackagesTested(filters, alertGroup) {
    const filtersToUse = { ...filters };
    store.dispatch(packagesTestedFetchStart());
    return axios
        .get(PACKAGES_TESTED)
        .then((response) => {
            const unfilteredPackages = response.data;
            const filteredPackages = filterPackages(
                unfilteredPackages,
                filtersToUse,
                "tested_at"
            );
            store.dispatch(
                packagesTestedFetchSuccess(
                    filteredPackages,
                    filtersToUse,
                    unfilteredPackages
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(packagesTestedFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function fetchPackagesTestingAll(filters, alertGroup) {
    const filtersToUse = { ...filters };
    store.dispatch(packagesTestingAllFetchStart());
    return axios
        .get(PACKAGES_TESTING_ALL)
        .then((response) => {
            const unfilteredPackages = response.data;
            const filteredPackages = filterPackages(
                unfilteredPackages,
                filtersToUse,
                "tested_at"
            );
            store.dispatch(
                packagesTestingAllFetchSuccess(
                    filteredPackages,
                    filtersToUse,
                    unfilteredPackages
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(packagesTestingAllFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

export function fetchPackageTests(packageId, alertGroup) {
    return axios
        .get(PACKAGES_IN_TEST_OR_RETEST + packageId + "/")
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
}

export function submitPackageTest(packageTest) {
    const url = PACKAGE_TEST + packageTest.test_id;

    const data = {
        width: packageTest.width,
        width_percentage: packageTest.width_percentage,
        height: packageTest.height,
        height_percentage: packageTest.height_percentage,
        location: packageTest.location,
        pv: packageTest.pv,
        vv: packageTest.vv,
        is_sticker: packageTest.is_sticker,
        comment: packageTest.comment,
        status: packageTest.status,
        standard_comments: packageTest.standard_comments,
    };

    return axios
        .put(url, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            // returns {errors: [{code}, ...]} instead of {code}
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                return error.response.data;
            }
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
}

function filterPackages(packages, filters, dateFilterField) {
    if (!packages || !packages.length || !filters) {
        return packages;
    }

    return packages.filter((pkg) => {
        if (filters.ean && !filterStringIncludes(pkg.ean, filters.ean))
            return false;
        if (filters.name && !filterStringIncludes(pkg.name, filters.name))
            return false;
        if (filters.client && pkg.client.value !== filters.client.value)
            return false;
        if (filters.category && pkg.category.value !== filters.category.id)
            return false;
        if (filters.type && pkg.type !== filters.type.value) return false;
        if (filters.volume && pkg.volume !== filters.volume.value) return false;
        // TODO: pkg should have value which matches filter(option) value.
        if (filters.testResult && pkg.status.value !== filters.testResult.value)
            return false;
        if (
            filters.startDate &&
            (!pkg[dateFilterField] ||
                !secondDateIsSameOrAfterFirstDate(
                    filters.startDate,
                    pkg[dateFilterField],
                    "day"
                ))
        ) {
            return false;
        }
        if (
            filters.endDate &&
            (!pkg[dateFilterField] ||
                !secondDateIsSameOrAfterFirstDate(
                    pkg[dateFilterField],
                    filters.endDate,
                    "day"
                ))
        ) {
            return false;
        }

        return true;
    });
}

function filterStringIncludes(str, substr) {
    return str.trim().toLowerCase().includes(substr.trim().toLowerCase());
}
