import * as actionTypes from "redux/actions/actionTypes";

export const packagesTestedFetchStart = () => {
    return {
        type: actionTypes.PACKAGES_TESTED_FETCH_START,
    };
};

export const packagesTestedFetchSuccess = (
    packagesList,
    filters,
    unfilteredPackagesList
) => {
    return {
        type: actionTypes.PACKAGES_TESTED_FETCH_SUCCESS,
        packagesList,
        filters,
        unfilteredPackagesList,
    };
};

export const packagesTestedFetchFail = (error) => {
    return {
        type: actionTypes.PACKAGES_TESTED_FETCH_FAIL,
        error,
    };
};

export const packagesTestedFilterUpdate = (key, value) => {
    return {
        type: actionTypes.PACKAGES_TESTED_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const packagesTestedFilterClear = (format) => {
    return {
        type: actionTypes.PACKAGES_TESTED_FILTER_CLEAR,
        format,
    };
};

export const packagesTestedSortingUpdate = (sorting) => {
    return {
        type: actionTypes.PACKAGES_TESTED_SORTING_UPDATE,
        sorting,
    };
};

export const packagesTestedPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.PACKAGES_TESTED_PAGINATION_UPDATE,
        pagination,
    };
};
