import { combineReducers } from "redux";
import * as actionTypes from "redux/actions/actionTypes";
import auth from "./auth";
import { navigation } from "./navigation";
import packages from "./packages";
import invoices from "./invoices";
import { sales } from "./reports/sales";
import { salesReport } from "./reports/salesReport";
import { summaryReport } from "./reports/summaryReport";
import { massReport } from "./reports/massReport";
import { profile } from "./profile";
import { dashboard } from "./dashboard";
import { notifications } from "./notifications";
import { importSalesReport } from "./reports/importSalesReport";
import alerts from "./alerts";
import { general } from "./general";
import partner from "./partner/index";
import admin from "./admin/index";
import { language } from "./language";
import returnReports from "components/ReturnReports/redux";
import additionalDepositReports from "components/AdditionalDepositReports/redux";

const appReducer = combineReducers({
    auth,
    admin,
    partner,
    general,
    language,
    navigation,
    packages,
    invoices,
    sales,
    salesReport,
    summaryReport,
    massReport,
    profile,
    importSalesReport,
    alerts,
    dashboard,
    notifications,
    returnReports,
    additionalDepositReports
});

const rootReducer = (state, action) => {
    if (
        action.type === actionTypes.LOG_OUT ||
        action.type === actionTypes.RESET_STORE
    ) {
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
