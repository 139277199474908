import React from "react";
import PropTypes from "prop-types";

import "./StatusCell.scss";

export const statusTypes = {
    DEFAULT: "DEFAULT",
    INFO: "INFO",
    SUCCESS: "SUCCESS",
    WARNING: "WARNING",
    ERROR: "ERROR",
}

class StatusCell extends React.Component {
    static propTypes = {
        status: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        type: PropTypes.oneOf(Object.keys(statusTypes)).isRequired,
        className: PropTypes.string,
        style: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    };

    static defaultProps = {
        status: "",
        style: {}
    }

    getStatusTypeStyle() {
        const type = this.props.type;
        return `status-type-${String(type).toLowerCase()} bold-11 ${this.props.className}`;
    }

    render() {
        if (!this.props.status) {
            return false;
        }

        return (
            <div className={this.getStatusTypeStyle()} style={this.props.style}>
                <span>
                    {this.props.status}
                </span>
            </div>
        );
    }
}

export default StatusCell;
