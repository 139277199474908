import * as actionTypes from "redux/actions/actionTypes";

export const adminTestingPackagesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_FETCH_START,
    };
};

export const adminTestingPackagesFetchSuccess = (
    unfilteredPackagesList,
    packagesList,
    filters
) => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_FETCH_SUCCESS,
        packagesList,
        filters,
        unfilteredPackagesList,
    };
};

export const adminTestingPackagesFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_FETCH_FAIL,
        error,
    };
};

export const adminTestingPackagesFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const adminTestingPackagesFilterClear = () => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_FILTER_CLEAR,
    };
};

export const adminTestingPackagesSortingUpdate = (sorting) => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_SORTING_UPDATE,
        sorting,
    };
};

export const adminTestingPackagesPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.ADMIN_TESTING_PACKAGES_PAGINATION_UPDATE,
        pagination,
    };
};

export const adminTestingUpdateSelected = (selected) => {
    return {
        type: actionTypes.ADMIN_TESTING_UPDATE_SELECTED,
        selected,
    };
};

export const adminTestingSendToRetestingStart = () => {
    return {
        type: actionTypes.ADMIN_TESTING_SEND_TO_RETESTING_START,
    };
};

export const adminTestingSendToRetestingSuccess = (ids) => {
    return {
        type: actionTypes.ADMIN_TESTING_SEND_TO_RETESTING_SUCCESS,
        ids,
    };
};

export const adminTestingSendToRetestingFail = (error) => {
    return {
        type: actionTypes.ADMIN_TESTING_SEND_TO_RETESTING_FAIL,
        error,
    };
};

export const adminTestingConfirmPackageTestStart = () => {
    return {
        type: actionTypes.ADMIN_TESTING_CONFIRM_PACKAGE_TEST_START,
    };
};

export const adminTestingConfirmPackageTestSuccess = (ids) => {
    return {
        type: actionTypes.ADMIN_TESTING_CONFIRM_PACKAGE_TEST_SUCCESS,
        ids,
    };
};

export const adminTestingConfirmPackageTestFail = (error) => {
    return {
        type: actionTypes.ADMIN_TESTING_CONFIRM_PACKAGE_TEST_FAIL,
        error,
    };
};

export const adminTestingActivatePackagesStart = () => {
    return {
        type: actionTypes.ADMIN_TESTING_ACTIVATE_PACKAGES_START,
    };
};

export const adminTestingActivatePackagesSuccess = (ids) => {
    return {
        type: actionTypes.ADMIN_TESTING_ACTIVATE_PACKAGES_SUCCESS,
        ids,
    };
};

export const adminTestingActivatePackagesFail = (error) => {
    return {
        type: actionTypes.ADMIN_TESTING_ACTIVATE_PACKAGES_FAIL,
        error,
    };
};
