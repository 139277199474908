import React from "react";
import PropTypes from "prop-types";

import { Card, CardBody, Row, Col, Button, Form } from "reactstrap";
import Alerts from "components/Alerts/Alerts";
import {
    getAuthUser,
    setAuthUser,
    login,
    idCardAuthSuccess,
    idCardAuthDokobitTokenVerify,
    mobileIdAuth,
    digitalAuthStatus,
    LOGIN_ALERT_GROUP,
    smartIdAuth,
    authTypes,
} from "util/Auth";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";
import { getIdCardErrorMessage } from "util/Errors";

import BrandLogoText from "components/Misc/BrandLogoText";

import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";

// css
import "./LoginCard.css";
import MidForm from "../MidForm/MidForm";
import SmartIdForm from "../SmartIdForm/SmartIdForm";
import IdCardForm from "../IdCardForm/IdCardForm";
import EmailForm from "../EmailForm/EmailForm";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";

import Dokobit from "../../../util/dokobit/Dokobit";

function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

class LoginCard extends React.Component {
    static propTypes = {
        emailEnabled: PropTypes.bool,
        defaultAuth: PropTypes.string,
    }

    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
            mobileNumber: "",
            selectedAuth: props.defaultAuth,
            personalCode: "",
            isAuthenticating: false,
            idCardAuthenticationKey: 0,
            authenticationFrameLoadedSuccessfully: false,
            controlCode: "" // Used for both m-id and smart-id
        };
    }

    // idCardManager = new IdCardManager("EST");
    dokobit = new Dokobit();

    handleChange = async (event) => {
        const { target } = event;
        const value = target.value;
        const { name } = target;
        await this.setState({ [name]: value });
    };

    setIsAuthenticating = (value) => {
        this.setState({
            isAuthenticating: value,
            controlCode: ""
        });
    };

    authenticateUser = (event) => {
        event.preventDefault();
        clearLocalAlerts();

        // Credentials
        if (this.state.selectedAuth === "4") {
            this.logInCredentials(this.state.username, this.state.password);
        }

        // mid
        if (this.state.selectedAuth === "1") {
            this.logInMid(this.state.mobileNumber, this.state.personalCode);
        }

        // id-card
        if (this.state.selectedAuth === "3") {
            this.logInId();
        }
        // smart-id
        if (this.state.selectedAuth === "2") {
            this.logInSmart(this.state.personalCode, "EE");
        }
    };

    logInCredentials = (username, password) => {
        this.setIsAuthenticating(true);
        login(username, password).then((isSuccess) => {
            if (isSuccess) {
                getAuthUser();
            }

            this.setIsAuthenticating(false);
        });
    };

    logInMid = (mobileNumber, personalCode) => {
        this.setIsAuthenticating(true);
        clearLocalAlerts();

        mobileIdAuth(mobileNumber, personalCode)
            .then(async (resp) => {
                clearLocalAlerts();
                if (!resp || !resp.success || resp.code === "error") {
                    this.showAuthStartErrorMessage();
                    this.setIsAuthenticating(false);
                    return;
                }
                this.setState({
                    controlCode: resp.control_code
                })

                addNewLocalInfoAlert(
                    `Teie kontrollkood on ${resp.control_code}. Veenduge, et näete mobiiltelefoni ekraanil täpselt sama numbrit.
            Seejärel sisestage M-ID PIN 1 kood.`,
                    LOGIN_ALERT_GROUP
                );

                let token = resp.token;
                this.checkAuthStatus(token, authTypes.MID, mobileNumber);
            })
            .catch((err) => {
                clearLocalAlerts();
                this.showAuthStartErrorMessage();
                this.setIsAuthenticating(false);
            });
    };

    checkAuthStatus = async (token, mode, mobileNumber) => {
        await sleep(1000);
        digitalAuthStatus(token, mode, mobileNumber)
            .then((resp) => {
                if (resp.code === "waiting") {
                    this.checkAuthStatus(token, mode, mobileNumber);
                } else if (resp.code === "ok") {
                    if (resp.message === "Not Estonian national") {
                        clearLocalAlerts();
                        addNewLocalErrorAlert(
                            "Sisselogimine ebaõnnestus. Pole Eesti rahvusest.",
                            LOGIN_ALERT_GROUP
                        );
                        this.setIsAuthenticating(false);
                    } else {
                        localStorage.setItem("authType", mode);
                        setAuthUser(resp.user_object);
                    }
                } else if (resp.code === "canceled") {
                    clearLocalAlerts();
                    addNewLocalErrorAlert(
                        "Sisselogimine ebaõnnestus. Kasutaja keeldus.",
                        LOGIN_ALERT_GROUP
                    );
                    this.setIsAuthenticating(false);
                } else {
                    throw Error("Response not waiting or success.");
                }
            })
            .catch((err) => {
                clearLocalAlerts();
                this.setIsAuthenticating(false);
                this.showAuthStatusErrorMessage();
            });
    };

    showAuthStartErrorMessage = () => {
        addNewLocalErrorAlert(
            "Sisselogimine ebaõnnestus. Palun kontrollige andmeid.",
            LOGIN_ALERT_GROUP
        );
    };

    showAuthStatusErrorMessage = () => {
        addNewLocalErrorAlert(
            "Sisselogimine ebaõnnestus. Palun proovige uuesti.",
            LOGIN_ALERT_GROUP
        );
    };

    logInSmart = (personalCode, country) => {
        this.setIsAuthenticating(true);
        clearLocalAlerts();

        smartIdAuth(personalCode, country)
            .then(async (data) => {
                if (data && data.success === true) {
                    clearLocalAlerts();
                    this.setState({
                        controlCode: data.control_code
                    })
                    addNewLocalInfoAlert(
                        `Teie kontrollkood on ${data.control_code}. Veenduge, et näete mobiiltelefoni ekraanil täpselt sama numbrit.
                Seejärel sisestage Smart id pin 1 kood.`,
                        LOGIN_ALERT_GROUP
                    );

                    this.checkAuthStatus(data.token, authTypes.SMART, "-");
                } else {
                    throw Error("Response not a success.");
                }
            })
            .catch((err) => {
                clearLocalAlerts();
                this.showAuthStartErrorMessage();
                this.setIsAuthenticating(false);
            });
    };

    logInId = () => {
        this.setIsAuthenticating(true);
        clearLocalAlerts();

        /*
        // Hardcoded this alert to IdCardForm because some thing keeps clearing it up after certificate selection.
        //addNewLocalInfoAlert("Toimub ID-kaardiga sisselogimine. Kontrollige oma tegumiriba, kui sertifikaadi valimine ei kerki esile.", LOGIN_ALERT_GROUP, true);

        const resolve = (data) => {
            this.setIsAuthenticating(false);

            if (!data.user_object) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    "Kasutajat ei tagastatud.",
                    LOGIN_ALERT_GROUP
                );
                return;
            }

            idCardAuthSuccess(data.user_object);
        };

        const reject = (error) => {
            this.setIsAuthenticating(false);
            clearLocalAlerts();
            const errorMessage = getIdCardErrorMessage(
                error,
                "Sisselogimine ebaõnnestus."
            );
            addNewLocalErrorAlert(errorMessage, LOGIN_ALERT_GROUP);
        };

        this.dokobit.authWithIdCard(resolve, reject);
        */

        this.setState(state => ({
            authenticationFrameLoadedSuccessfully: false,
            idCardAuthenticationStarted: state.idCardAuthenticationKey += 1
        }))
    };

    logInIdCertificateAuthenticatedResolve = response => {

        this.setState({
            authenticationFrameLoadedSuccessfully: true
        })

        if (response.status === "ok") {
            idCardAuthDokobitTokenVerify(response.token)
                .then(data => {
                    if (!data.success) {
                        throw Error("login_verify_not_success");
                    } else {
                        if (!data.user_object) {
                            clearLocalAlerts();
                            addNewLocalErrorAlert(
                                getIdCardErrorMessage(
                                    null,
                                    "Kasutajat ei tagastatud."
                                ),
                                LOGIN_ALERT_GROUP
                            );
                            return;
                        }

                        idCardAuthSuccess(data.user_object);
                    }

                })
                .catch(error => {
                    const errorMessage = getIdCardErrorMessage(
                        error,
                        "Sisselogimine ebaõnnestus."
                    );
                    addNewLocalErrorAlert(errorMessage, LOGIN_ALERT_GROUP);
                })
                .finally(() => {
                    this.setIsAuthenticating(false);
                })
        } else {
            addNewLocalErrorAlert(getIdCardErrorMessage(null, "Sisselogimine ebaõnnestus."), LOGIN_ALERT_GROUP)
            this.setIsAuthenticating(false);
        }
    }

    changeLoginMode = (authOption) => {
        clearLocalAlerts();
        this.setState({ selectedAuth: authOption });
    };

    onAuthenticationFrameLoad = () => {
        const showErrorIfNotSuccess = () => {
            if (!this.state.authenticationFrameLoadedSuccessfully) {
                this.setIsAuthenticating(false);
                clearLocalAlerts();
                addNewLocalErrorAlert(getIdCardErrorMessage(null, "Sisselogimine ebaõnnestus."), LOGIN_ALERT_GROUP);

            }
        }
        setTimeout(showErrorIfNotSuccess, 1000)
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.idCardAuthenticationKey > 0 && prevState.idCardAuthenticationKey === 0) {
            if (typeof window.Isign !== 'undefined') {
                window.Isign.PostMessage.init(this.logInIdCertificateAuthenticatedResolve)
            } else {
                console.warn("Isign for ID-card auth is not setup!")
            }
        }
    }

    render() {
        return (
            <Card className="login-card">
                <CardBody className="login-card-body px-3 px-md-4">
                    <BrandLogoText />
                    <p className="login-select-type">Vali autentimisviis</p>
                    <Row className="justify-content-center mx-n1">
                        <Col className="col-md mb-2 px-1">
                            <CheckboxButton
                                className="login-option"
                                onClick={() => this.changeLoginMode("1")}
                                isSelected={this.state.selectedAuth === "1"}
                                labelText="Mobiil-ID"
                                disabled={this.state.isAuthenticating}
                                extraProps={{ block: true }}
                            />
                        </Col>
                        <Col className="col-md mb-2 px-1">
                            <CheckboxButton
                                className="login-option"
                                onClick={() => this.changeLoginMode("2")}
                                isSelected={this.state.selectedAuth === "2"}
                                labelText="Smart-ID"
                                disabled={this.state.isAuthenticating}
                                extraProps={{ block: true }}
                            />
                        </Col>
                        <Col className=" col-md mb-2 px-1">
                            <CheckboxButton
                                className="login-option"
                                onClick={() => this.changeLoginMode("3")}
                                isSelected={this.state.selectedAuth === "3"}
                                labelText="ID-kaart"
                                disabled={this.state.isAuthenticating}
                                extraProps={{ block: true }}
                            />
                        </Col>
                        {
                            this.props.emailEnabled &&
                            <Col className="col-md mb-2 px-1">
                                <CheckboxButton
                                    className="login-option"
                                    onClick={() => this.changeLoginMode("4")}
                                    isSelected={this.state.selectedAuth === "4"}
                                    labelText="E-mail"
                                    disabled={this.state.isAuthenticating}
                                    extraProps={{ block: true }}
                                />
                            </Col>
                        }
                    </Row>
                    <Form onSubmit={this.authenticateUser}>
                        <div className="login-credentials">
                            {this.state.selectedAuth === "1" ? (
                                <MidForm handleChanged={this.handleChange} />
                            ) : this.state.selectedAuth === "2" ? (
                                <SmartIdForm
                                    handleChanged={this.handleChange}
                                />
                            ) : this.state.selectedAuth === "3" ? (
                                <IdCardForm
                                    handleChanged={this.handleChange}
                                    isAuthenticating={
                                        this.state.isAuthenticating
                                    }
                                />
                            ) : this.state.selectedAuth === "4" ? (
                                <EmailForm handleChanged={this.handleChange} />
                            ) : null}
                        </div>
                        {this.renderControlCode()}
                        <Alerts
                            alerts={this.props.alertsList}
                            hideGlobal={true}
                            filterGroups={[LOGIN_ALERT_GROUP]}
                        />
                        {(!this.state.isAuthenticating || this.state.selectedAuth === "4") && (
                            <div>
                                <Button
                                    block
                                    type="submit"
                                    color="success"
                                    className=""
                                    disabled={this.state.isAuthenticating}
                                //onClick={(event) => this.authenticateUser(event)}
                                >
                                    {this.state.isAuthenticating ? <LoadingAnimation /> : "Sisene"}
                                </Button>
                            </div>
                        )}
                        {(this.state.idCardAuthenticationKey > 0) &&
                            <iframe
                                key={this.state.idCardAuthenticationKey}
                                src="https://auth.dokobit.com"
                                onLoad={this.onAuthenticationFrameLoad}
                                style={{ display: "none" }}
                                id="isign-authentication" // expected in dokobit.authentication.js
                                title="Dokobit ID-card authentication" />
                        }
                    </Form>
                </CardBody>
            </Card>
        );
    }

    renderControlCode() {
        const { controlCode } = this.state;
        if (!controlCode) {
            return;
        }

        return (
            <div class="smart-id__control-code__container">
                <span class="bold-14">Kontrollkood: </span>
                <span class="bold-24">{controlCode}</span>
            </div>
        )
    }
}

export default LoginCard;
