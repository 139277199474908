import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';

import { ReactComponent as MoreVerticalIcon } from 'assets/icons/more-vertical.svg';

import { getSaleReportUrl, getSaleReportFixUrl } from "config/urls";
import { fetchSalesReportExportFile, isSalesReportHasRepairReport } from "util/SalesReports";

import "./MenuCell.scss";

import {
    salesReportImportOpen,
} from "redux/actions/reports/importSalesReport";
import { alertsClearLocal } from "redux/actions/alerts";
import { addNewLocalErrorAlert } from "util/Alerts";


const StyledDropdownItem = (props) => <DropdownItem {...props} className="semibold-14 text-muted">{props.children}</DropdownItem>;

class ActionsCell extends React.Component {
    static propTypes = {
        salesReport: PropTypes.object.isRequired,
        salesReportImportOpen: PropTypes.func.isRequired,
        alertsClearLocal: PropTypes.func.isRequired,
    }

    render() {
        return (
            <UncontrolledButtonDropdown direction="left" className="admin-reports-actions" onClick={e => { e.stopPropagation(); }}>
                <DropdownToggle tag="div">
                    <Button color="link" className="icon-default admin-reports-actions-icon-button">
                        <MoreVerticalIcon />
                    </Button>
                </DropdownToggle>
                <DropdownMenu>
                    <StyledDropdownItem
                        onClick={this.onView}
                    >
                        {this.hasRepair ? "Vaata originaali" : "Vaata"}
                    </StyledDropdownItem>
                    <StyledDropdownItem
                        onClick={() => this.onGetExcelTemplate(false)}
                    >
                        Ekspordi aruanne
                    </StyledDropdownItem>
                    {this.hasRepair() &&
                        <StyledDropdownItem
                            onClick={this.onViewRepair}
                        >
                            Vaata parandust
                        </StyledDropdownItem>
                    }
                    {this.hasRepair() &&
                        <StyledDropdownItem
                            onClick={() => this.onGetExcelTemplate(true)}
                        >
                            Ekspordi parandus
                        </StyledDropdownItem>
                    }
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }

    onView = () => {
        const id = this.props.salesReport.id;
        const route = getSaleReportUrl(id);
        this.props.history.push(route, { previousViewRoute: this.props.location.pathname });
    }

    onViewRepair = () => {
        const id = this.props.salesReport.repair_report.id;
        const route = getSaleReportFixUrl(id);
        this.props.history.push(route, { previousViewRoute: this.props.location.pathname });
    }

    onGetExcelTemplate = (isGetRepair) => {
        let report = this.props.salesReport;

        if (isGetRepair) {
            report = this.props.salesReport.repair_report;
        }

        this.props.alertsClearLocal();
        fetchSalesReportExportFile(report.id, report.is_repair, report.period).then(
            result => {
                if (!result.isSuccess) {
                    addNewLocalErrorAlert(
                        "Aruande templaadi allatõmbamine ebaõnnestus: " + result.errorMessage,
                        this.props.location.pathname
                    );
                }
            }
        );
    }

    hasRepair = () => {
        return isSalesReportHasRepairReport(this.props.salesReport);
    }
}

const reduxActions = {
    salesReportImportOpen,
    alertsClearLocal,
}

export default withRouter(connect(null, reduxActions)(ActionsCell));
