import * as actionTypes from "./actionTypes";

export const lastPackagesFetchStart = () => {
    return {
        type: actionTypes.LAST_PACKAGES_FETCH_START,
        loading: true,
    };
};

export const lastPackagesFetchSuccess = (packages) => {
    return {
        type: actionTypes.LAST_PACKAGES_FETCH_SUCCESS,
        packages: packages,
    };
};

export const lastPackagesFetchFail = (err) => {
    return {
        type: actionTypes.LAST_PACKAGES_FETCH_FAIL,
        error: err,
    };
};

export const lastReportsFetchStart = () => {
    return {
        type: actionTypes.LAST_REPORTS_FETCH_START,
        loading: true,
    };
};

export const lastReportsFetchSuccess = (reports) => {
    return {
        type: actionTypes.LAST_REPORTS_FETCH_SUCCESS,
        reports: reports,
    };
};

export const lastReportsFetchFail = (err) => {
    return {
        type: actionTypes.LAST_REPORTS_FETCH_FAIL,
        error: err,
    };
};

export const statisticsFetchStart = () => {
    return {
        type: actionTypes.STATISTICS_FETCH_START,
        loading: true,
    };
};

export const statisticsFetchSuccess = (statistics) => {
    return {
        type: actionTypes.STATISTICS_FETCH_SUCCESS,
        statistics: statistics,
    };
};

export const statisticsFetchFail = (err) => {
    return {
        type: actionTypes.STATISTICS_FETCH_FAIL,
        error: err,
    };
};
