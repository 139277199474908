// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config

import urls from "config/urls";

import PartnerProfileView from "./views/Partner/ProfileView";
import PartnerPackagesView from "views/Partner/PackagesView";

import UsersTable from "./components/Profile/Users/UsersTable/UsersTable";
import PartnerProfile from "./components/Partner/Profile/Profile";
import PackagesInTestView from "./components/Partner/PackagesInTest/PackagesInTest";
import PackagesInRetestView from "./components/Partner/PackagesInRetest/PackagesInRetest";
import PackagesUnconfirmedView from "./components/Partner/PackagesUnconfirmed/PackagesUnconfirmed";
import PackagesTestedView from "./components/Partner/PackagesTested/PackagesTested";
import PackagesTestingAllView from "./components/Partner/PackagesTestingAll/PackagesTestingAll";
import PrivacyPolicyView from "./views/public/PrivacyPolicy";

import CookiePolicyView from "./views/public/CookiePolicy";

//import RouteWithSubRoutes from "./components/Routing/RouteWithSubRoutes";

const routes = [
    {
        path: urls.PROFILE,
        label: "",
        component: PartnerProfileView,
        routes: [
            {
                path: urls.PROFILE_EDIT,
                label: "Profiil",
                component: PartnerProfile,
            },
            {
                path: urls.PROFILE_USERS,
                label: "Kasutajad",
                component: UsersTable,
            },
        ],
    },
    {
        path: urls.PARTNER_PACKAGES,
        label: "",
        component: PartnerPackagesView,
        routes: [
            {
                path: urls.PARTNER_PACKAGES_IN_TESTING,
                label: "Testimise ootel",
                component: PackagesInTestView,
            },
            {
                path: urls.PARTNER_PACKAGES_IN_RETESTING,
                label: "Kordustestimise ootel",
                component: PackagesInRetestView,
            },
            {
                path: urls.PARTNER_PACKAGES_UNCONFIRMED,
                label: "Kinnitamata pakendid",
                component: PackagesUnconfirmedView,
            },
            {
                path: urls.PARTNER_PACKAGES_CONFIRMED,
                label: "Kinnitatud pakendid",
                component: PackagesTestedView,
            },
            {
                path: urls.PARTNER_PACKAGES,
                label: "Kõik pakendid",
                component: PackagesTestingAllView,
            },
        ],
    },
    {
        path: urls.PRIVACY_POLICY,
        label: "Privaatsuspoliitika",
        component: PrivacyPolicyView,
    },
    {
        path: urls.COOKIE_POLICY,
        label: "Küpsised",
        component: CookiePolicyView,
    },
];

export default routes;
