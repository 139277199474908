import React, { Component } from 'react';
import PropTypes from "prop-types";

import { Input } from "reactstrap";

import "./SmartIdForm.css";

class SmartIdForm extends Component {
    static propTypes = {
        handleChanged: PropTypes.func.isRequired
    }

    render() {
        const { handleChanged } = this.props;

        return (
            <>
                <p className="login-credential">ISIKUKOOD</p>
                <Input
                    required
                    className="login-input"
                    name="personalCode"
                    onChange={handleChanged}
                    placeholder="Isikukood"
                />
            </>
        );
    }
}

export default SmartIdForm;
