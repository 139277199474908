
import React from "react";
import PropTypes from "prop-types";
import { withTranslation, Trans } from "react-i18next";
import moment from "moment";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css"
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";

import { isAdminMode } from "util/Access";
import { currencyFormatter, processFeeCostCurrencyFormatter, depositCurrencyFormatter } from "util/General";

import WrappedLoader from "components/Loader/WrappedLoader";


import "./ReportLineList.scss";

function NoDataIndication(props) {
    let content =
        <Trans i18nKey="packages.noPackagesText">

        </Trans>
    if (props.loading) {
        content = <WrappedLoader />;
    } else if (props.error) {
        content = <span className="color-error">{props.error}</span>;
    }

    return <div className="text-center">{content}</div>;
}

class ReportLineList extends React.Component {
    static propTypes = {
        lines: PropTypes.array,
        isLoading: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {

        };
    }
    state = {

    }

    componentDidMount() {
    }

    render() {
        const { t, isLoading, lines } = this.props;
        if (isLoading) {
            return <WrappedLoader />;
        }
        let noLinesText = <></>;
        if (!lines) {
            return <></>;
        }

        return (
            <div className="additional-deposit-report-line-list">
                {noLinesText}
                {this.renderTable()}
            </div>
        );
    }

    renderTable() {
        const { t, lines, isLoading } = this.props;

        const isAdmin = isAdminMode();
        const data = lines;
        const columns = [
            {
                dataField: "package.ean",
                text: t("reports.filter.ean"),
                classes: "semibold-14",
                sort: true,
                footer: "",
                filter: textFilter({
                    placeholder: t("reports.filter.ean"),
                    style: {
                        marginLeft: "18px"
                    }
                })
            },
            {
                dataField: "package.name",
                text: t("reports.filter.product"),
                classes: "semibold-14",
                sort: true,
                footer: "",
                filter: textFilter({
                    placeholder: t("reports.filter.product"),
                    style: {
                        minWidth: "150px"
                    }
                })
            },
            {
                dataField: "package.package_type",
                text: t("reports.filter.packageType"),
                classes: "regular-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "package.volume",
                text: t("reports.filter.packageVolume"),
                classes: "regular-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "quantity",
                text: t("reports.filter.additionalQuantity"),
                classes: "regular-14",
                sort: true,
                headerAlign: "right",
                align: "right",
                footer: "",
            },
            {
                dataField: "total_fee",
                text: t("reports.additionalDeposit.additionalDepositTotal"),
                classes: "regular-14",
                sort: true,
                headerAlign: "right",
                align: "right",
                formatter: (value, row) => (
                    <div className="text-nowrap">
                        {currencyFormatter.format(row.total_fee)}
                        <br />
                        <span>({depositCurrencyFormatter.format(row.base_fee)})</span>
                    </div>
                ),
                footer: "",
            },
            {
                dataField: "return_threshold_total_fee",
                text: t("reports.additionalDeposit.returnThresholdFeeTotal"),
                classes: "regular-14",
                sort: true,
                headerAlign: "right",
                align: "right",
                formatter: (value, row) => (
                    <div className="text-nowrap">
                        {currencyFormatter.format(row.return_threshold_total_fee)}
                        <br />
                        <span>({processFeeCostCurrencyFormatter.format(row.return_threshold_base_fee)})</span>
                    </div>
                ),
                footer: "",
            }
        ].filter(header => header.show !== false); // Show if explicitly not set to false

        const defaultSorted = [{
            dataField: "created_at",
            order: "desc"
        }]

        const noDataIndication = <NoDataIndication loading={isLoading} />;

        return (
            <>
                <BootstrapTable
                    bootstrap4
                    hover
                    bordered={false}
                    classes="report-line-list__table table-layout-auto"
                    wrapperClasses="report-line-list__table-wrapper table-responsive flipped-horizontal-scrollbar"
                    keyField='id'
                    data={data}
                    columns={columns}
                    filter={filterFactory()}
                    filterPosition="top"
                    defaultSorted={defaultSorted}
                    noDataIndication={noDataIndication}
                />
            </>
        );
    }
}

export default withTranslation()(ReportLineList);
