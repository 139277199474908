import React, { Component } from "react";
import { withRouter } from "react-router";
import PropTypes from "prop-types";
import _ from "lodash";

import {
    Row,
    Col,
    Button,
    FormGroup,
    Label,
    Input as ReactstrapInput,
} from "reactstrap";
import Alerts from "components/Alerts/Alerts";
import Input from "components/Form/Input";
import WrappedLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";


import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
    addNewLocalInfoAlert
} from "util/Alerts";

import {
    fetchContracts,
    uploadContract,
    terminateContract,
} from "util/admin/General";
import { adminfetchAllClients } from "util/admin/Users";

import "./Contract.scss";
import moment from "moment";
import ContractModal from "./ContractModal";
import { adminfetchClientUsers } from "util/admin/Users";
import { verifyContractSigner } from "util/Contracts";
import { DATE_TIME_FORMAT } from "util/DateTime";

import { getServerErrorMessage } from "util/Errors";

class ContractForm extends Component {
    static propTypes = {
        alertGroup: PropTypes.string,
        contractMode: PropTypes.bool,
        invalidFields: PropTypes.array.isRequired,
    };

    static defaultProps = {
        invalidFields: [],
        contractMode: false,
    };

    state = {
        clientId: this.props.match.params.id,
        latestContract: {
            id: "",
            number: "",
        },
        modalOpen: false,
        isDocumentSignerVerified: true,
        isContractsLoading: false,
        isConfirmFileUploadModalOpen: false,
        file: null,
        fileUploadEvent: null,
        fileUploadLoading: false,
        isConfirmTerminateContractOpen: false,
        terminateContractLoading: false,
    };

    componentDidMount() {
        if (
            this.props.location.state &&
            !this.props.location.state.contractSigned
        ) {
            clearLocalAlerts();
        }
        this.setState({
            isContractsLoading: true,
        });
        this.getContracts();
    }

    getContracts = () => {
        fetchContracts(this.state.clientId).then((contracts) => {
            this.setState(
                {
                    signedBy: _.get(contracts, "latest.created_by"),
                    basis: _.get(contracts, "latest.basis"),
                    userVerifiedAt: _.get(contracts, "latest.user_verified_at"),
                    latestContract: contracts.latest,
                    olderContracts: contracts.older_contracts,
                    isContractsLoading: false,
                },
                () => {
                    if (this.state.basis && this.state.basis.value === 2) {
                        this.fetchIsUserVerified(this.state.clientId);
                    }
                }
            );
        })
            .catch(error => {
                this.setState({
                    isContractsLoading: false,
                });

                addNewLocalErrorAlert(
                    "Lepingute laadimisel tekkis viga: " + getServerErrorMessage(error),
                    "contractAlert"
                );
            });

    }

    onVerifyContractSigner = (isVerified, userId, clientId) => {
        verifyContractSigner(isVerified, userId, clientId)
            .then(() => {
                this.setState({
                    userVerifiedAt: moment(),
                });
                addNewLocalSuccessAlert(
                    "Volitus edukalt kinnitatud!",
                    "contractAlert"
                );
            })
            .catch((err) => {
                this.setState({
                    userVerifiedAt: moment(),
                });
                addNewLocalErrorAlert(
                    "Volituse kinnitamisel tekkis viga: " + getServerErrorMessage(err),
                    "contractAlert"
                );
            });
    };

    fetchIsUserVerified = (clientId) => {
        adminfetchClientUsers(clientId).then((users) => {
            // eslint-disable-next-line array-callback-return
            users.map((user) => {
                if (user.user_id === this.state.signedBy && !user.is_verified) {
                    this.setState({
                        isDocumentSignerVerified: false,
                    });
                }
            });
        });
    };

    toggleModal = () => this.setState({ modalOpen: !this.state.modalOpen });

    render() {
        if (this.props.personalProfileLoading) {
            return (
                <CenteredRowCol>
                    <WrappedLoader />
                </CenteredRowCol>
            );
        }

        return (
            <>
                <Row>
                    <Col>
                        {" "}
                        <Alerts
                            hideGlobal={true}
                            filterGroups={["contractAlert"]}
                        />
                    </Col>
                </Row>

                {this.renderLatestContract()}

                {this.state.olderContracts &&
                    this.state.olderContracts.length > 0 && (
                        <div className="heading my-4">
                            <span>Kehtetud lepingud</span>
                        </div>
                    )}
                {this.state.olderContracts &&
                    this.state.olderContracts.length > 0 &&
                    this.state.olderContracts.map((contract) => {
                        return (
                            <Row key={contract.id}>
                                <Col>
                                    <Input
                                        disabled
                                        type="text"
                                        placeholder="Lepingu number"
                                        value={contract.number}
                                        className="input-md"
                                        labelText="Lepingu number"
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        disabled
                                        type="text"
                                        placeholder="Staatus"
                                        value={contract.status}
                                        className="input-md"
                                        labelText="Staatus"
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        disabled
                                        type="text"
                                        placeholder="Esitatud"
                                        value={moment(
                                            contract.created_at
                                        ).format(DATE_TIME_FORMAT)}
                                        className="input-md"
                                        labelText="Esitatud"
                                    />
                                </Col>
                                <Col>
                                    <Input
                                        disabled
                                        type="text"
                                        value={contract.invalidated_at ? moment(
                                            contract.invalidated_at
                                        ).format(DATE_TIME_FORMAT) : ""}
                                        className="input-md"
                                        labelText="Lõpetatud"
                                    />
                                </Col>
                                <Col>
                                    {contract.signed_document ?
                                        <FormGroup className="custom-input-field">
                                            <Label className="custom-label">
                                                Lepingu fail
                                            </Label>
                                            <a
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="form-control contract-link"
                                                href={contract.signed_document}
                                            >
                                                {contract.number}
                                            </a>
                                        </FormGroup>
                                        :
                                        <Input
                                            disabled
                                            type="text"
                                            placeholder="Lepingu fail"
                                            value={contract.number}
                                            className="input-md"
                                            labelText="Lepingu fail"
                                        />
                                    }
                                </Col>
                            </Row>
                        );
                    })}
            </>
        );
    }

    renderLatestContract() {
        if (this.state.isContractsLoading) {
            return (
                <WrappedLoader />
            );
        }

        if (!_.get(this.state, "latestContract.id")) {
            return <p className="regular-14">Lepingut ei leitud.</p>
        }

        const latestContract = this.state.latestContract;
        const latestDocument = latestContract.signed_document || latestContract.document;

        return (
            <>
                <ContractModal
                    modalOpen={this.state.modalOpen}
                    toggleModal={this.toggleModal}
                    clientId={this.state.clientId}
                    contractId={
                        this.state.latestContract &&
                        this.state.latestContract.id
                    }
                />
                <Row>
                    <Col md={3}>
                        <Input
                            disabled
                            type="text"
                            placeholder="Lepingu number"
                            value={
                                this.state.latestContract &&
                                this.state.latestContract.number
                            }
                            className="input-md"
                            labelText="Lepingu number"
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            disabled
                            type="text"
                            placeholder="Staatus"
                            value={
                                this.state.latestContract
                                    ? this.state.latestContract.status
                                    : "Esitamata"
                            }
                            className="input-md"
                            labelText="Staatus"
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            disabled
                            type="text"
                            placeholder="Esitatud"
                            value={
                                this.state.latestContract &&
                                moment(
                                    this.state.latestContract.created_at
                                ).format(DATE_TIME_FORMAT)
                            }
                            className="input-md"
                            labelText="Esitatud"
                        />
                    </Col>
                    <Col md={3}>
                        <Input
                            disabled
                            type="text"
                            placeholder=""
                            value={
                                this.state.latestContract && this.state.latestContract.approved_at &&
                                moment(
                                    this.state.latestContract.approved_at
                                ).format(DATE_TIME_FORMAT)
                            }
                            className="input-md"
                            labelText="Allkirjastatud"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <FormGroup className="custom-input-field">
                            <Label className="custom-label">Lepingu fail</Label>
                            {latestDocument ?
                                <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="form-control contract-link"
                                    href={latestDocument}
                                >
                                    {this.state.latestContract &&
                                        this.state.latestContract.number}
                                </a>
                                :
                                <Input
                                    disabled
                                    type="text"
                                    placeholder="Lepingu fail"
                                    value={this.state.latestContract && this.state.latestContract.number}
                                    className="input-md"
                                />
                            }
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup className="custom-input-field">
                            {this.state.userVerifiedAt ? (
                                <>
                                    <Label className="custom-label">
                                        Volituse staatus
                                    </Label>
                                    <Input
                                        disabled
                                        type="text"
                                        value="Kinnitatud"
                                        className="input-md"
                                    />
                                </>
                            ) : (
                                <>
                                    <Label className="custom-label">
                                        Volituse staatus
                                    </Label>
                                    <div>
                                        <Button
                                            color="success"
                                            className="mr-2"
                                            onClick={() =>
                                                this.onVerifyContractSigner(
                                                    true,
                                                    this.state.signedBy,
                                                    this.state.clientId
                                                )
                                            }
                                        >
                                            Kinnita
                                        </Button>
                                        <Button
                                            color="danger"
                                            className=""
                                            onClick={() =>
                                                this.onVerifyContractSigner(
                                                    false,
                                                    this.state.signedBy,
                                                    this.state.clientId
                                                )
                                            }
                                        >
                                            Lükka tagasi
                                        </Button>
                                    </div>
                                </>
                            )}
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        {this.renderConfirmContractFileUpload()}
                    </Col>
                    <Col md={3}>
                        {this.state.latestContract &&
                            this.state.latestContract.approved_at ? (
                            this.renderConfirmTerminateContract()
                        ) : (
                            <Button
                                disabled={
                                    !this.state.latestContract ||
                                        (this.state.latestContract &&
                                            this.state.latestContract.approved_at)
                                        ? true
                                        : false
                                }
                                block
                                color="success"
                                className="contract-btn"
                                onClick={this.toggleModal}
                            >
                                Allkirjasta kliendileping
                            </Button>
                        )}
                    </Col>
                </Row>
            </>
        )
    }

    renderConfirmContractFileUpload() {
        let alertGroup = "fileUploadModalAlert";

        const onToggle = () => {
            this.setState(prevState => {
                return {
                    isConfirmFileUploadModalOpen: !prevState.isConfirmFileUploadModalOpen,
                }
            });
        };

        const onFileUpload = (e) => {
            console.log("onFileUpload", e);
            if (e.target.files.length !== 1) {
                return;
            }
            e.persist();

            const file = {
                file: e.target.files[0],
            };

            this.setState({
                file,
                fileUploadEvent: e,
            });

            onToggle();
            clearLocalAlerts();
        };

        const onCancelFunc = () => {
            const fileUploadEvent = this.state.fileUploadEvent;
            fileUploadEvent.target.value = "";
            this.setState({
                file: null,
                fileUploadEvent: null,
            });

            onToggle();
        }

        let onConfirmFunc = () => {
            clearLocalAlerts();

            this.setState({
                fileUploadLoading: true
            })

            uploadContract(this.state.latestContract.id, this.state.file)
                .then(() => {
                    addNewLocalSuccessAlert(
                        "Dokument edukalt üles laetud ja leping kinnitatud!",
                        "contractAlert"
                    );

                    const fileUploadEvent = this.state.fileUploadEvent;
                    fileUploadEvent.target.value = "";
                    this.setState({
                        file: null,
                        fileUploadEvent: null,
                    });

                    onToggle();
                    adminfetchAllClients(false).catch(() => { });
                    this.getContracts();
                })
                .catch((err) => {
                    addNewLocalErrorAlert(
                        "Dokumendi üles laadimisel tekkis viga: " + getServerErrorMessage(err),
                        alertGroup
                    );
                })
                .finally(() => {
                    this.setState({
                        fileUploadLoading: false
                    })
                });
        };

        return (
            <>
                <FormGroup className="custom-input-field disabled">
                    <Label
                        className="custom-label"
                        htmlFor="customFileUpload"
                    >
                        Lisa dokument
                    </Label>
                    <ReactstrapInput
                        id="customFileUpload"
                        type="file"
                        onChange={onFileUpload}
                        className="input-md"
                        accept=".asice, .bdoc, .pdf, .ddoc, .edoc"
                    />
                </FormGroup>
                <ConfirmationModal
                    isOpen={this.state.isConfirmFileUploadModalOpen}
                    onToggle={onCancelFunc}
                    onCancel={onCancelFunc}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Kinnita"
                    title="Kinnita leping"
                    isDisabled={this.state.fileUploadLoading}
                    isLoading={this.state.fileUploadLoading}
                    alertGroups={[alertGroup]}
                >
                    <Row>
                        <Col>
                            <p className="regular-14">Soovid kinnitada manuaalselt laetud lepingu?</p>
                        </Col>
                    </Row>
                </ConfirmationModal>
            </>
        );
    }

    renderConfirmTerminateContract() {
        let alertGroup = "terminateContractAlert";

        const onToggle = () => {
            this.setState(prevState => {
                return {
                    isConfirmTerminateContractOpen: !prevState.isConfirmTerminateContractOpen,
                }
            });
        };

        const onCancelFunc = () => {
            onToggle();
        }

        let onConfirmFunc = () => {
            clearLocalAlerts();
            this.setState({
                terminateContractLoading: true
            })

            terminateContract(this.state.latestContract.id).then(() => {
                addNewLocalInfoAlert(
                    "Leping edukalt lõpetatud veebikeskkonnas. NAV-is tuleb seda teha eraldi!",
                    "contractAlert"
                );
                onToggle();
                adminfetchAllClients(false).catch(() => { });
                this.getContracts();
            })
                .catch((err) => {
                    addNewLocalErrorAlert(
                        "Lepingu lõpetamisel tekkis viga: " + getServerErrorMessage(err),
                        "contractAlert"
                    );
                })
                .finally(() => {
                    this.setState({
                        terminateContractLoading: false
                    })
                });
        };

        return (
            <>
                <Button
                    disabled={
                        !this.state.latestContract ||
                            (this.state.latestContract &&
                                this.state.latestContract
                                    .invalidated_at)
                            ? true
                            : false
                    }
                    block
                    color="danger"
                    className="contract-btn"
                    onClick={onToggle}
                >
                    Lõpeta leping
                </Button>
                <ConfirmationModal
                    isOpen={this.state.isConfirmTerminateContractOpen}
                    onToggle={onCancelFunc}
                    onCancel={onCancelFunc}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Kinnita"
                    title="Kinnita leping"
                    isDisabled={this.state.terminateContractLoading}
                    isLoading={this.state.terminateContractLoading}
                    alertGroups={[alertGroup]}
                >
                    <Row>
                        <Col>
                            <p className="regular-14">Soovid lõpetada kehtiva lepingu?</p>
                            <p className="regular-14">Antud tegevus lõpetab lepingu ainult veebis, NAV-is tuleb seda teha eraldi.</p>
                        </Col>
                    </Row>
                </ConfirmationModal>
            </>
        );
    }

    hasAllProperties = (object) => {
        return Object.values(object).every((x) => x !== "");
    };
}

export default withRouter(ContractForm);
