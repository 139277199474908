import React from "react";

import DebtsSearch from "components/Admin/Debts/Search/DebtsSearch";
import DebtsTable from "components/Admin/Debts/Table/DebtsTable";

class AdminDebtsView extends React.Component {
  render() {
    return (
      <>
        <DebtsSearch />
        <DebtsTable />
      </>
    );
  }
}

export default AdminDebtsView;
