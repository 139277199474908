import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import { Trans, withTranslation } from "react-i18next";
import _ from "lodash";
import {
    Card,
    CardHeader,
    CardBody,
    Row,
    Col,
    Button,
    FormGroup,
    UncontrolledTooltip
} from "reactstrap";

// ajax
import {
    updateSalesLine,
    submitSalesReport,
    isActive,
    fetchSalesReportExportFile,
    setSalesReportHeaderSuffix,
    calculateSalesReportPackageTotals,
} from "util/SalesReports";
import { adminRepairSalesReportApproval } from "util/admin/SalesReports";

// css
import "./SalesReportDetails.scss";

// redux
import {
    salesReportHeaderFieldChange,
    updateSalesReportHeaderTotals,
    salesReportPackagesFieldChange,
} from "redux/actions/reports/salesReport";
import { alertsAddLocal } from "redux/actions/alerts";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import MenuCell from "components/Reports/Sales/cells/MenuCell";
import InputField from "components/Form/Input";
import WrappedLoader from "components/Loader/WrappedLoader";
import SmallLoader from "components/Loader/WrappedPulseLoader";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import DetailsExcelDownload from "./DetailsExcelDownload/DetailsExcelDownload";
import ImportModal from "components/Reports/Sales/Modal/ImportSalesReport";
import { ReactComponent as InfoIcon } from "assets/icons/info.svg";

import { clearNavigationHeaderSuffix } from "util/Navigation";
import {
    parseSalesReportPeriodDate,
    salesReportPeriodDetailsFormat,
} from "util/DateTime";
import urls from "config/urls";
import {
    alertType,
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
} from "util/Alerts";
import { currencyFormatter, depositCurrencyFormatter, processFeeCostCurrencyFormatter } from "util/General";
import { saleStatuses } from "components/Reports/Sales/helpers/Options";

const confirmationTypes = {
    SUBMIT_REPORT: "SUBMIT_REPORT",
    APPROVE_REPAIR: "APPROVE_REPAIR",
    DISAPPROVE_REPAIR: "DISAPPROVE_REPAIR",
};
const CONFIRM_MODAL_ALERT_GROUP = "ADMIN_SALES_REPORT_CONFIRMATION_ALERT_GROUP";

class SalesReportDetails extends React.Component {
    static propTypes = {
        // manual props
        adminMode: PropTypes.bool,
        fetchData: PropTypes.func.isRequired,
        // redux state
        user: PropTypes.object,
        menuCellDetails: PropTypes.object,
        details: PropTypes.object,
        header: PropTypes.object.isRequired,
        packagesList: PropTypes.array.isRequired,
        salesReportLoading: PropTypes.bool,
        salesReportErrorMessage: PropTypes.string,
        // redux actions
        salesReportHeaderFieldChange: PropTypes.func.isRequired,
        updateSalesReportHeaderTotals: PropTypes.func.isRequired,
        salesReportPackagesFieldChange: PropTypes.func.isRequired,
        alertsAddLocal: PropTypes.func.isRequired,
        submitLoading: PropTypes.bool,
        exportLoading: PropTypes.bool,
    };

    state = {
        isConfirmationModalOpen: false,
        isConfirmationLoading: false,
        confirmationType: null,
        disapproveComment: "",
        isDisapproveCommentInvalid: false,
    };

    componentDidUpdate(prevProps) {
        // TODO: Remove all functions that are not used anymore
        if (prevProps.details !== this.props.details) {
            setSalesReportHeaderSuffix(this.props.details);
        }
    }

    componentWillUnmount(prevProps) {
        clearNavigationHeaderSuffix();
    }

    updatePackageQuantity = async (row, newQuantity) => {
        const oldquantity = row.quantity;
        if (_.isEqual(oldquantity, newQuantity)) {
            return;
        }

        // Setting it before actual success so that I could set it back to oldQuantity when it fails.
        // This is necessary because the quantity input only synchronizes when quantity prop has a different value.
        this.props.salesReportPackagesFieldChange(
            row.id,
            "quantity",
            newQuantity
        );
        clearLocalAlerts();
        const result = await updateSalesLine(
            this.props.details.id,
            this.props.details.is_repair,
            row,
            newQuantity
        );

        if (result.isSuccess) {
            //this.props.alertsAddLocal(alertType.SUCCESS_ICON, "Kogus edukalt muudetud", this.props.location.pathname);
        } else {
            this.props.salesReportPackagesFieldChange(
                row.id,
                "quantity",
                oldquantity
            );
            this.props.alertsAddLocal(
                alertType.ERROR_ICON,
                <Trans i18nKey="reports.alerts.changeSalesLineQuantityFailed">Koguse muutmine ebaõnnestus: {{ errorMessage: result.errorMessage }}</Trans>,
                this.props.location.pathname
            );
        }
    };

    onSubmit = () => {
        if (this.props.submitLoading) {
            return;
        }
        const details = this.props.details;
        clearLocalAlerts();
        submitSalesReport(details.id, details.is_repair).then((result) => {
            if (result.isSuccess) {
                this.onSubmitSuccess();
            } else {
                this.onSubmitFail(result.errorMessage);
            }
        });
    };

    onSubmitSuccess = () => {
        const { t } = this.props;
        const routeAndGroup = urls.REPORTS_SALES;

        const formattedPeriod = parseSalesReportPeriodDate(
            this.props.details.period
        ).format(salesReportPeriodDetailsFormat);

        let mainMessage = <Trans i18nKey="reports.alerts.submitSalesReportSuccess.originalReportText">
            Teie poolt {{ period: formattedPeriod }} müügiaruanne on EPP-sse edastatud.
        </Trans>;

        if (this.props.details.is_repair) {
            mainMessage = <Trans i18nKey="reports.alerts.submitSalesReportSuccess.repairReportText">
                Teie poolt {{ period: formattedPeriod }} müügiaruande parandus on EPP-sse edastatud.
            </Trans>;
        }

        let message = (
            <div className="align-items-center w-100 d-flex">
                <span>{mainMessage}</span>
                <NavLink
                    to={urls.INVOICES}
                    className="btn-outline-secondary align-items-center color-success-light-bg d-flex ml-auto"
                >
                    {t("reports.alerts.submitSalesReportSuccess.redirectToInvoicesButton", "Arvet tasuma")}
                </NavLink>
            </div>
        );

        this.props.alertsAddLocal(
            alertType.SUCCESS_ICON,
            message,
            routeAndGroup
        );
        this.props.history.push(routeAndGroup);
    };

    onSubmitFail = (errorMessage) => {
        const message = <Trans i18nKey="reports.alerts.submitSalesReportFailed">Müügiaruande esitamine ebaõnnestus: {{ errorMessage }}</Trans>;
        this.props.alertsAddLocal(
            alertType.ERROR_ICON,
            message,
            CONFIRM_MODAL_ALERT_GROUP
        );
    };

    onPrint = () => {
        if (this.props.exportLoading) {
            return;
        }
        clearLocalAlerts();
        fetchSalesReportExportFile(
            this.props.details.id,
            this.props.details.is_repair,
            this.props.details.period
        ).then((result) => {
            if (!result.isSuccess) {
                this.props.alertsAddLocal(
                    alertType.ERROR_ICON,
                    <Trans i18nKey="reports.alerts.exportSalesReportFailed">Aruande printimine ebaõnnestus: {{ errorMessage: result.errorMessage }}</Trans>,
                    this.props.location.pathname
                );
            }
        });
    };

    onOpenConfirmationDialog = (confirmationType) => {
        this.setState({
            isConfirmationModalOpen: true,
            confirmationType: confirmationType,
        });
    };

    isConfirmRepairMode = () => {
        if (!this.props.adminMode || !this.props.details) {
            return false;
        }
        return (
            this.props.details.status &&
            this.props.details.status.value === saleStatuses.UNCONFIRMED
        );
    };

    onToggleAcceptConfirmation = () => {
        this.setState((prevState) => {
            return {
                isConfirmationModalOpen: !prevState.isConfirmationModalOpen,
            };
        });
    };

    onRepairReportApproval = (isApprove, comment = "") => {
        this.setState({ isConfirmationLoading: true });
        clearLocalAlerts();
        adminRepairSalesReportApproval(
            this.props.details.id,
            isApprove,
            comment
        )
            .then((data) => {
                this.setState({
                    isConfirmationModalOpen: false,
                    isConfirmationLoading: false,
                });
                const text = `Parandusaruande ${isApprove ? "kinnitamine" : "tühistamine"
                    } edukalt sooritatud.`;
                addNewLocalSuccessAlert(text, this.props.location.pathname);
            })
            .catch((error) => {
                this.setState({ isConfirmationLoading: false });
                addNewLocalErrorAlert(error.message, CONFIRM_MODAL_ALERT_GROUP);
            });
    };

    onApproveRepairReport = () => {
        this.onRepairReportApproval(true);
    };

    onDisapproveRepairReport = () => {
        clearLocalAlerts();
        const comment = this.state.disapproveComment;
        if (!comment) {
            this.setState({ isDisapproveCommentInvalid: true });
            addNewLocalErrorAlert(
                "Tühistamise põhjendus kliendile on täitmata.",
                CONFIRM_MODAL_ALERT_GROUP
            );
            return;
        } else {
            this.setState({ isDisapproveCommentInvalid: false });
        }

        this.onRepairReportApproval(false, comment);
    };

    NoDataIndication({ loading, error, isSubmitted, t }) {
        let content = <></>;

        if (loading) {
            content = <WrappedLoader />;
        } else if (error) {
            content = <span className="color-error">{error}</span>;
        } else if (isSubmitted) {
            content = t("reports.salesReportSubmittedNoDataIndication");
        } else {
            content = t("reports.salesReportUnsubmittedNoDataIndication");
        }

        return <div>{content}</div>;
    }

    render() {
        return (
            <>
                <Card className="sales-details-card">
                    <CardHeader>{this.renderHeader()}</CardHeader>
                    <CardBody className="p-0">
                        {this.renderExtraActions()}
                        {this.renderTable()}
                    </CardBody>
                </Card>
                <ImportModal isDetailsView={true} fetchData={this.props.fetchData} />
                {this.renderConfirmationModal()}
            </>
        );
    }

    renderExtraActions() {
        const { details, adminMode, menuCellSalesReport, menuCellRepairReport } = this.props;
        if (!details?.id || adminMode) {
            return;
        }

        return (
            <div className="border-top py-3 px-4">
                <div className="px-3 mx-1">
                    <MenuCell
                        isRepair={details.is_repair}
                        salesReport={menuCellSalesReport} repairReport={menuCellRepairReport} isDetailsViewMode={true}
                        className="px-2"
                    />
                </div>
            </div>
        )
    }

    renderButtons() {
        const { t } = this.props;
        if (this.isConfirmRepairMode()) {
            return (
                <>
                    <Col lg="2" className="px-2">
                        <FormGroup className="mb-2">
                            <Button
                                color="danger"
                                block
                                onClick={() =>
                                    this.onOpenConfirmationDialog(
                                        confirmationTypes.DISAPPROVE_REPAIR
                                    )
                                }
                                disabled={
                                    this.props.quantityLoading ||
                                    _.isNil(this.props.details.id) ||
                                    this.props.submitLoading
                                }
                            >
                                {this.props.submitLoading ? (
                                    <SmallLoader />
                                ) : (
                                    t("buttons.cancel", "Tühista")
                                )}
                            </Button>
                        </FormGroup>
                    </Col>
                    <Col lg="2" className="px-2">
                        <FormGroup className="mb-2">
                            <Button
                                color="success"
                                block
                                onClick={() =>
                                    this.onOpenConfirmationDialog(
                                        confirmationTypes.APPROVE_REPAIR
                                    )
                                }
                                disabled={
                                    this.props.quantityLoading ||
                                    _.isNil(this.props.details.id) ||
                                    this.props.submitLoading
                                }
                            >
                                {this.props.submitLoading ? (
                                    <SmallLoader />
                                ) : (
                                    t("buttons.submit", "Kinnita")
                                )}
                            </Button>
                        </FormGroup>
                    </Col>
                </>
            );
        }

        return this.renderButton();
    }

    renderButton() {
        const { t } = this.props;
        if (isActive(this.props.details) && !this.props.adminMode) {
            return (
                <Col lg="4" className="px-2">
                    <FormGroup className="mb-2">
                        <Button
                            color="success"
                            block
                            onClick={() =>
                                this.onOpenConfirmationDialog(
                                    confirmationTypes.SUBMIT_REPORT
                                )
                            }
                            disabled={
                                this.props.quantityLoading ||
                                _.isNil(this.props.details.id) ||
                                this.props.submitLoading
                            }
                        >
                            {this.props.submitLoading ? (
                                <SmallLoader />
                            ) : (
                                t("reports.submit", "Esita")
                            )}
                        </Button>
                    </FormGroup>
                </Col>
            );
        } else {
            return (
                <Col lg="4" className="px-2">
                    <FormGroup className="mb-2">
                        <DetailsExcelDownload
                            loading={this.props.exportLoading}
                            disabled={_.isNil(this.props.details.id)}
                            data={{
                                header: this.props.header,
                                details: this.props.details,
                                packages: this.props.packagesList
                            }}
                            onError={() => this.onSubmitFail("")}
                        />
                    </FormGroup>
                </Col>
            );
        }
    }

    renderHeader() {
        const { t } = this.props;
        const header = this.props.header;
        //const localTotals = this.props.localHeaderTotals;

        return (
            <>
                <Row className="align-items-end px-2">
                    <Col lg="5" className="px-2">
                        <Row className="px-2">
                            <Col className="px-2">
                                <InputField
                                    disabled
                                    labelText={t("reports.clientCode")}
                                    value={header.client_code}
                                />
                            </Col>
                            <Col className="px-2">
                                <InputField
                                    disabled
                                    labelText={t("reports.period")}
                                    value={
                                        header.period
                                            ? parseSalesReportPeriodDate(
                                                header.period
                                            ).format(
                                                salesReportPeriodDetailsFormat
                                            )
                                            : ""
                                    }
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="7" className="px-2">
                        <Row className="align-items-end px-2">
                            <Col lg="4" className="px-2">
                                <InputField
                                    disabled
                                    labelText={t("reports.packagesTotal")}
                                    value={header.packages_total}
                                //value={localTotals.packages_total}
                                />
                            </Col>
                            <Col lg="4" className="px-2">
                                <InputField
                                    disabled
                                    labelText={t("reports.serviceFeeTotal")}
                                    value={currencyFormatter.format(header.process_fee_total)}
                                //value={currencyFormatter.format(localTotals.process_fee_total)}
                                />
                            </Col>
                            <Col lg="4" className="px-2">
                                <InputField
                                    disabled
                                    labelText={t("reports.totalAmountToPay")}
                                    value={currencyFormatter.format(header.to_be_paid)}
                                //value={currencyFormatter.format(localTotals.to_be_paid)}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="align-items-end px-2">
                    <Col lg="5" className="px-2">
                        <InputField
                            disabled
                            labelText={t("reports.company")}
                            value={header.client_name}
                        />
                    </Col>
                    <Col lg="7" className="">
                        <Row className="align-items-end">
                            <Col lg="4" className="px-2">
                                <InputField
                                    disabled
                                    labelText={t(
                                        "reports.depositFeeTotal"
                                    )}
                                    value={currencyFormatter.format(header.deposit_total)}
                                //value={currencyFormatter.format(localTotals.deposit_total)}
                                />
                            </Col>
                            <Col lg="4" className="px-2">
                                <InputField
                                    disabled
                                    labelText={t("reports.serviceFeeTaxes")}
                                    value={currencyFormatter.format(header.process_fee_vat)}
                                //value={currencyFormatter.format(localTotals.process_fee_vat)}
                                />
                            </Col>
                            {this.renderButtons()}
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }

    renderConfirmationModal() {
        const { t } = this.props;
        const confirmationType = this.state.confirmationType;
        let title = "";
        let confirmButtonText = "";
        let onConfirmFunc = () => { };
        let isLoading = this.state.isConfirmationLoading;
        if (confirmationType === confirmationTypes.APPROVE_REPAIR) {
            title = t("reports.confirmRepairReport", "Kinnita parandusaruanne");
            confirmButtonText = t("buttons.confirm", "Kinnita");
            onConfirmFunc = this.onApproveRepairReport;
        }
        if (confirmationType === confirmationTypes.DISAPPROVE_REPAIR) {
            title = t("reports.cancelRepairReport", "Tühista parandusaruanne");
            confirmButtonText = t("buttons.cancel", "Tühista");
            onConfirmFunc = this.onDisapproveRepairReport;
        }
        if (confirmationType === confirmationTypes.SUBMIT_REPORT) {
            title = t("reports.submitReportConfirmationTitle", "Esita aruanne");
            confirmButtonText = t("buttons.confirm", "Kinnita");
            onConfirmFunc = this.onSubmit;
            isLoading = this.state.isConfirmationLoading || this.props.submitLoading;
        }

        return (
            <ConfirmationModal
                isOpen={this.state.isConfirmationModalOpen}
                onToggle={this.onToggleAcceptConfirmation}
                onCancel={this.onToggleAcceptConfirmation}
                onConfirm={onConfirmFunc}
                confirmButtonText={confirmButtonText}
                title={title}
                isDisabled={isLoading}
                isLoading={isLoading}
                alertGroups={[CONFIRM_MODAL_ALERT_GROUP]}
            >
                {confirmationType === confirmationTypes.APPROVE_REPAIR && (
                    <p className="regular-14">
                        {t(
                            "reports.confirmationDialog.repairReportConfirmMessage",
                            "Oled kindel, et soovid parandusaruannet kinnitada?"
                        )}
                    </p>
                )}
                {confirmationType === confirmationTypes.DISAPPROVE_REPAIR && (
                    <>
                        <p className="regular-14">
                            {t(
                                "reports.confirmationDialog.repairReportCancelMessage",
                                "Oled kindel, et soovid parandusaruannet tühistada?"
                            )}
                        </p>
                        <p className="regular-14">
                            {t(
                                "reports.confirmationDialog.repairReportCancelAdditionalMessage",
                                "Tühistamiseks tuleb lisada põhjendus, mida kuvatakse kliendile e-mailis."
                            )}
                        </p>
                        <InputField
                            required
                            labelText={t("reports.confirmationDialog.reason", "Põhjendus")}
                            placeholder={t("reports.confirmationDialog.reason", "Põhjendus")}
                            value={this.state.disapproveComment}
                            onChange={(value) =>
                                this.setState({
                                    disapproveComment: _.trim(value),
                                })
                            }
                            invalid={this.state.isDisapproveCommentInvalid}
                        />
                    </>
                )}
                {confirmationType === confirmationTypes.SUBMIT_REPORT && (
                    <p className="regular-14">
                        {t(
                            "reports.confirmationDialog.submitReportMessage",
                            "Oled kindel, et soovid aruannet esitada?"
                        )}
                    </p>
                )}
            </ConfirmationModal>
        );
    }

    renderTable() {
        const { t } = this.props;
        //const localTotals = this.props.localHeaderTotals;

        const columns = [
            {
                dataField: "EAN",
                text: t("reports.filter.ean"),
                classes: "semibold-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "name",
                text: t("reports.filter.product"),
                classes: "semibold-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "package_type",
                text: t("reports.filter.packageType"),
                classes: "regular-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "volume",
                text: t("reports.filter.packageVolume"),
                classes: "regular-14",
                sort: true,
                footer: "",
            },
            {
                dataField: "deposit",
                text: t("reports.filter.deposit"),
                classes: "regular-14",
                sort: true,
                align: "right",
                headerAlign: "right",
                formatter: (deposit) => depositCurrencyFormatter.format(deposit),
                footer: (columnData) =>
                    !this.props.salesReportLoading ? "Kokku" : "",
                footerClasses: "bold-14",
            },
            {
                dataField: "quantity",
                text: t("reports.filter.quantity"),
                classes: "",
                sort: true,
                formatter: (quantity, row) => {
                    if (row.isQuantityUpdateLoading) {
                        return <SmallLoader />;
                    }
                    return (
                        <InputField
                            value={quantity}
                            onBlur={(value) =>
                                this.updatePackageQuantity(row, value)
                            }
                            disabled={
                                this.props.adminMode ||
                                !isActive(this.props.details)
                            }
                            type="number"
                            className="field-quantity"
                            extraProps={{
                                min: "1",
                                step: "1",
                            }}
                            valid={row.isQuantityUpdated === true}
                            invalid={row.isQuantityUpdated === false}
                        />
                    );
                },
                footer: (columnData) =>
                    !this.props.salesReportLoading
                        ? this.props.header.packages_total
                        : "",
                footerClasses: "bold-14 pl-4",
            },
            {
                dataField: "deposit_total",
                text: t("reports.filter.depositTotal"),
                classes: "regular-14",
                sort: true,
                align: "right",
                headerAlign: "right",
                footerAlign: "right",
                formatter: (deposit_total, row) => currencyFormatter.format(deposit_total),
                footer: (columnData) =>
                    !this.props.salesReportLoading
                        ? currencyFormatter.format(this.props.header.deposit_total)
                        : "",
                footerClasses: "bold-14",
            },
            {
                dataField: "process_fee",
                text: t("reports.filter.serviceFeeTotal"),
                classes: "regular-14",
                sort: true,
                align: "right",
                headerAlign: "right",
                footerAlign: "right",
                formatter: (process_fee, packageRow) => {
                    const extraFeeIconId = `extra-fee-icon-${packageRow.id}`;
                    const hasExtraFee = Number(packageRow.extra_fee) !== 0;
                    const tooltipMessage = <Trans i18nKey="reports.extraFeeTooltipMessage">Summa sisaldab ka eripakenditasu {{ extraFee: processFeeCostCurrencyFormatter.format(packageRow.extra_fee) }} iga deklareeritud pakendi kohta.</Trans>;
                    return (
                        <div >
                            <div className="text-nowrap">
                                {currencyFormatter.format(process_fee)}
                                {
                                    hasExtraFee && (
                                        <span className="ml-1">
                                            < InfoIcon
                                                id={extraFeeIconId}
                                                className="color-info"
                                                style={{ verticalAlign: "sub" }}
                                            />
                                            <UncontrolledTooltip
                                                placement="bottom"
                                                target={extraFeeIconId}
                                                innerClassName="package-conditions-tooltip-inner"
                                                arrowClassName="package-conditions-tooltip-arrow"
                                            >
                                                {tooltipMessage}
                                            </UncontrolledTooltip>
                                        </span>
                                    )
                                }
                            </div>
                            <br />
                            <span>({processFeeCostCurrencyFormatter.format(packageRow.process_fee_base)})</span>
                        </div >
                    )
                },
                footer: (columnData) =>
                    !this.props.salesReportLoading
                        ? currencyFormatter.format(this.props.header.process_fee_total)
                        : "",
                footerClasses: "bold-14",
            },
        ];

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="sales-details-table table-layout-auto"
                wrapperClasses="table-responsive"  // bug in safari: flipped-horizontal-scrollbar
                keyField="id"
                data={this.props.packagesList}
                columns={columns}
                noDataIndication={
                    <this.NoDataIndication
                        error={this.props.salesReportErrorMessage}
                        loading={this.props.salesReportLoading}
                        isSubmitted={
                            this.props.details &&
                            this.props.details.submitted_at
                        }
                        t={t}
                    />
                }
            />
        );
    }
}

const mapStateToProps = (state) => {
    const details = state.salesReport.details;
    const isRepair = details?.is_repair;

    let menuCellSalesReport = isRepair ? details.original_report : details;
    let menuCellRepairReport = isRepair ? details : details.repair_report;

    return {
        user: state.auth.user,
        details,
        menuCellSalesReport,
        menuCellRepairReport,
        header: {
            ...state.salesReport.header,
            ...calculateSalesReportPackageTotals(
                state.salesReport.packages,
                { 
                    is_vat_free: state.salesReport.header.is_vat_free,
                    period: state.salesReport.header.period, 
                }
            )
        },
        packagesList: state.salesReport.packages,
        salesReportLoading: state.salesReport.salesReportLoading,
        salesReportErrorMessage: state.salesReport.error,
        submitLoading: state.salesReport.submitLoading,
        exportLoading: state.salesReport.exportLoading,
        quantityLoading: state.salesReport.quantityLoading,
        localHeaderTotals: calculateSalesReportPackageTotals(
            state.salesReport.packages,
            { 
                is_vat_free: state.salesReport.header.is_vat_free,
                period: state.salesReport.header.period,
            }
        ),
    };
};

const reduxActions = {
    salesReportHeaderFieldChange,
    updateSalesReportHeaderTotals,
    salesReportPackagesFieldChange,
    alertsAddLocal,
};

export default withRouter(
    connect(
        mapStateToProps,
        reduxActions
    )(withTranslation("common")(SalesReportDetails))
);
