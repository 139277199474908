import { createStore, applyMiddleware } from "redux";
import { createLogger } from "redux-logger";
import { persistStore } from "redux-persist";
import rootReducer from "redux/reducers/index";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension/developmentOnly";
import { ENABLE_REDUX_DEVTOOLS } from "config/envVariables";

// import Session from "util/Session";

const logger = createLogger({
    /* https://github.com/evgenyrodionov/redux-logger */
    collapsed: true,
    diff: true,
});

let configureStore;
if (ENABLE_REDUX_DEVTOOLS) {
    configureStore = createStore(
        rootReducer,
        composeWithDevTools(
            /* logger must be the last middleware in chain to log actions */
            applyMiddleware(thunk, logger)
        )
    );
} else {
    configureStore = createStore(rootReducer);
}

persistStore(configureStore);
export default configureStore;

// Session.read();
// store.subscribe(() => {
//     Session.write();
// });
