import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";
import moment from "moment";

const initialFilters = {
    // fromDate: moment("2019-12-31T22:00:00.000Z").toDate(),
    fromDate: moment().subtract(6, "months").toDate(),
    toDate: moment().toDate(),
    client: null,
    daysOverdueMin: "",
    daysOverdueMax: "",
};

const initialState = {
    debtsList: null,
    filters: initialFilters,
    loading: false,
    error: null,
};

const onAdminDebtsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        debtsList: [],
    });
};

const onAdminDebtsFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        debtsList: action.debts,
    });
};

const onAdminDebtsFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        debtsList: null,
    });
};

const onAdminDebtsFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
    });
};

const onAdminDebtsFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
    });
};

export const debts = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_DEBTS_FETCH_START:
            return onAdminDebtsFetchStart(state, action);
        case actionTypes.ADMIN_DEBTS_FETCH_SUCCESS:
            return onAdminDebtsFetchSuccess(state, action);
        case actionTypes.ADMIN_DEBTS_FETCH_FAIL:
            return onAdminDebtsFetchFail(state, action);
        case actionTypes.ADMIN_DEBTS_FILTER_UPDATE:
            return onAdminDebtsFilterUpdate(state, action);
        case actionTypes.ADMIN_DEBTS_FILTER_CLEAR:
            return onAdminDebtsFilterClear(state, action);

        default:
            return state;
    }
};
