import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import LeftText from "components/Misc/LeftText";
import { Button, Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";

import urls from "config/urls";

import {
    fetchAdminInvoices,
    sendInvoiceReminder,
    sendInvoicesSummary,
} from "util/admin/Invoices";

// css
import "./Table.scss";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// cells
import DateCell from "components/Table/Cell/DateCell";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import DocumentCell from "components/Table/Cell/DocumentCell";

import WrappedLoader from "components/Loader/WrappedLoader";

import { getSaleStatusType } from "components/Invoices/Table/Options";
import { fetchClient } from "util/admin/General";
import { currencyFormatter } from "util/General";

import {
    updateNavigationHeaderSuffix,
    clearNavigationHeaderSuffix,
} from "util/Navigation";
import { getServerErrorMessage } from "util/Errors";

class InvoicesTable extends React.Component {
    static propTypes = {
        invoices: PropTypes.array.isRequired,
        activeAlertGroups: PropTypes.array.isRequired,
    };

    state = {
        invoices: [],
        invoicesLoading: true,
        clientId: this.props.match.params.id,
        balance: null,
    };

    componentDidMount() {
        clearLocalAlerts();
        fetchClient(this.state.clientId).then((client) => {
            updateNavigationHeaderSuffix(client.name);
        });
        fetchAdminInvoices(this.state.clientId).then((invoices) => {
            this.setState({
                invoices: invoices.invoices,
                balance: invoices.invoices_balance_nav,
                invoicesLoading: false,
            });
        });
    }

    componentWillUnmount() {
        clearNavigationHeaderSuffix();
    }

    render() {
        return (
            <>
                <Row>
                    <Col xs="auto">
                        <LeftText
                            onClick={() =>
                                this.props.history.push(urls.EPP_DEBTS)
                            }
                            text="Tagasi"
                        />
                    </Col>
                </Row>
                <Card className="invoices-card mt-3">
                    <CardHeader className="invoices-card-header">
                        <Row className=" align-items-center">
                            <Col>
                                <span className="bold-19">Arvete saldo</span>
                            </Col>
                            <Col className="float-right text-right col-auto">
                                <Row className="align-items-center">
                                    <Col className="text-right">
                                        <span className="bold-19">
                                            {this.state.balance === null
                                                ? "-"
                                                : currencyFormatter.format(this.state.balance)}
                                        </span>
                                    </Col>
                                    <Col className="">
                                        <Button
                                            color="secondary"
                                            onClick={
                                                this.onClickSendInvoicesSummary
                                            }
                                            href="#"
                                            className="status-btn d-flex align-items-center"
                                            disabled={
                                                !this.state.balance ||
                                                this.state.balance <= 0
                                            }
                                        >
                                            Saada koondarve
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="px-0 pt-0 pb-0">
                        {this.renderTable()}
                    </CardBody>
                </Card>
            </>
        );
    }

    renderTable() {
        if (this.state.invoicesLoading) {
            return <WrappedLoader />;
        }

        const invoicesEndpointDateFormat = "DD-MM-YYYY";

        const ButtonFormatter = (cell, row) => {
            if (row.status === "Tasutud") {
                return (
                    <Button color="secondary" className="status-btn" disabled>
                        Tasutud
                    </Button>
                );
            } else {
                return (
                    <Button
                        color="success"
                        onClick={() => this.onClickSendInvoiceReminder(row.id)}
                        className="status-btn"
                    >
                        Saada uuesti
                    </Button>
                );
            }
        };

        const columns = [
            {
                dataField: "number",
                text: "Arve number",
                sort: true,
                formatter: (value) => (
                    <DocumentCell name={value.name} url={value.url} />
                ),
                sortValue: (value) => value.name || "",
            },
            {
                dataField: "date",
                text: "Arve kuupäev",
                sort: true,
                formatter: (value) => (
                    <DateCell value={value} valueFormat={invoicesEndpointDateFormat} />
                ),
                sortValue: (value) => moment(value, invoicesEndpointDateFormat),
            },
            {
                dataField: "status",
                text: "Staatus",
                sort: true,
                headerClasses: "status-col-header",
                classes: "status-col",

                formatter: (value) => (
                    <StatusCell
                        status={value}
                        type={getSaleStatusType(value)}
                    />
                ),
            },
            {
                dataField: "due_date",
                text: "Tasumise tähtaeg",
                sort: true,
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '150px'*/
                    };
                },
                formatter: (value) => (
                    <DateCell value={value} valueFormat={invoicesEndpointDateFormat} />
                ),
                sortValue: (value) => moment(value, invoicesEndpointDateFormat),
            },
            {
                dataField: "amount",
                text: "Arve summa",
                classes: "regular-14",
                sort: true,
                sortValue: (cell, row) => Number(cell),
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '150px'*/
                    };
                },
                align: "right",
                headerAlign: "right",
                formatter: (value) => currencyFormatter.format(value),
            },
            {
                dataField: "amount_paid",
                text: "Tasutud",
                classes: "regular-14",
                sort: true,
                sortValue: (cell, row) => Number(cell),
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '150px'*/
                    };
                },
                align: "right",
                headerAlign: "right",
                formatter: (value) => currencyFormatter.format(value),
            },
            {
                dataField: "",
                text: "Toiming",
                align: "center",
                headerAlign: "center",
                headerStyle: (column, colIndex) => {
                    return {
                        /*width: '200px'*/
                    };
                },
                formatter: ButtonFormatter,
            },
        ];

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="table-layout-auto"
                wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                keyField="id"
                data={this.state.invoices}
                columns={columns}
                noDataIndication={
                    <InvoicesTable.NoDataIndication
                        loading={this.props.invoicesLoading}
                    />
                }
            />
        );
    }

    static NoDataIndication(props) {
        let content = "Ei leitud ühtegi arvet.";
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    onClickSendInvoiceReminder = (invoiceId) => {
        clearLocalAlerts();
        const alertUrl = this.props.location.pathname;
        sendInvoiceReminder(invoiceId)
            .then(() => {
                addNewLocalInfoAlert(
                    "Arve meeldetuletus edukalt edastatud!",
                    alertUrl,
                    false
                );
            })
            .catch((err) => {
                addNewLocalErrorAlert(
                    `Arve meeldetuletuse saatmisel esines viga: ${getServerErrorMessage(err)}`,
                    alertUrl,
                    false
                );
            });
    };

    onClickSendInvoicesSummary = () => {
        clearLocalAlerts();
        const alertUrl = this.props.location.pathname;
        sendInvoicesSummary(this.state.clientId)
            .then(() => {
                addNewLocalInfoAlert(
                    "Koondarve meeldetuletus edukalt edastatud!",
                    alertUrl,
                    false
                );
            })
            .catch((err) => {
                addNewLocalErrorAlert(
                    `Koondarve saatmisel esines viga: ${getServerErrorMessage(err)}`,
                    alertUrl,
                    false
                );
            });
    };
}

const mapStateToProps = (state) => {
    return {
        invoices: state.invoices.invoices,
        invoicesLoading: state.invoices.invoicesLoading,
        balance: state.invoices.balance,
        activeAlertGroups: state.alerts.activeGroups,
    };
};

export default withRouter(connect(mapStateToProps)(InvoicesTable));
