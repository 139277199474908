import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Form, Row, Col, Button } from "reactstrap";

// datepicker
//import DatePicker from "react-datepicker";
import Select from "components/Form/Select";
import CustomInput from "components/Form/Input";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import PackageConditionsSelect from "components/Form/PackageConditions/PackageConditionsSelect";

import {
    fetchAllClients,
} from "util/General";
import {
    //fetchEanTypes,
    fetchCategories,
    fetchPackageTypes,
    //fetchPackageVolumes,
    fetchPackageStatuses,
    fetchPackageTestResults,
    packageStatusTypeLists
} from "util/Packages";


class AdminTestingSearch extends React.Component {
    static propTypes = {
        // Taken from redux
        //eanTypes: PropTypes.array,
        allClients: PropTypes.array,
        categories: PropTypes.array,
        packageTypes: PropTypes.array,
        //packageVolumes: PropTypes.array,
        packageStatuses: PropTypes.array,
        packageTestResults: PropTypes.array,
        // manual props
        filters: PropTypes.object.isRequired,
        packagesListLoading: PropTypes.bool,
        onFilterUpdate: PropTypes.func.isRequired,
        onClearFilters: PropTypes.func.isRequired,
        onSearch: PropTypes.func.isRequired,
    }

    componentDidMount() {
        //fetchEanTypes(this.props.eanTypes);
        fetchAllClients(this.props.allClients);
        fetchCategories(this.props.categories);
        fetchPackageTypes(this.props.packageTypes);
        //fetchPackageVolumes(this.props.packageVolumes);
        fetchPackageStatuses(this.props.packageStatuses);
        fetchPackageTestResults(this.props.packageTestResults);
    }

    static DATE_FORMAT = "dd-MM-yyyy";

    render() {
        return (
            <Form>
                <Row className="admin-all-packages-search-row m-0 p-3">
                    <Col className="">
                        <Row className="align-items-center justify-content-between mb-2 px-2">
                            <Col className="mb-2 px-1">
                                <span className="bold-19">Pakendi otsing</span>
                            </Col>
                            {this.props.exportButton &&
                                <Col lg={2} md={4} className="text-right px-1">
                                    {this.props.exportButton}
                                </Col>
                            }
                        </Row>
                        <Row className="align-items-center px-2">
                            <Col md={{ size: 2, order: 1 }} className="px-1">
                                <CustomInput placeholder="EAN"
                                    type="number"
                                    value={this.props.filters.ean}
                                    className="mb-2"
                                    onChange={this.updateFilter("ean")}
                                />
                            </Col>
                            <Col md={{ size: 4, order: 2 }} className="px-1">
                                <CustomInput placeholder="Tootenimetus"
                                    value={this.props.filters.name}
                                    className="mb-2"
                                    onChange={this.updateFilter("name")}
                                />
                            </Col>
                            {/*
                        <Col md={{size: 2, order: 3}} className="px-1">
                            <Select options={this.props.eanTypes}
                                    isClearable
                                    value={this.props.filters.eanType}
                                    placeholder="EAN tüüp"
                                    className="mb-2"
                                    getOptionLabel={(option) => option.value}
                                    onChange={this.updateFilter('eanType')}
                            />
                        </Col>
                        */}
                            <Col md={{ size: 2, order: 3 }} className="px-1">
                                <Select options={this.props.categories}
                                    isClearable
                                    value={this.props.filters.category}
                                    placeholder="Kategooria"
                                    className="mb-2"
                                    getOptionLabel={(option) => option.description}
                                    getOptionValue={(option) => option.value}
                                    onChange={this.updateFilter('category')}
                                />
                            </Col>
                            <Col md={{ size: 2, order: 4 }} className="px-1">
                                <Select options={this.getFilteredPackageStatuses()}
                                    isClearable
                                    value={this.props.filters.status}
                                    getOptionLabel={(option) => option.value}
                                    placeholder="Staatused"
                                    onChange={this.updateFilter('status')}
                                    className="mb-2"
                                />
                            </Col>
                            <Col md={{ size: 4, order: 6 }} className="px-1">
                                <Select
                                    options={this.props.allClients}
                                    isClearable
                                    isSearchable
                                    value={this.props.filters.client}
                                    placeholder="Ettevõte"
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.value}
                                    onChange={this.updateFilter('client')}
                                    className="mb-2"
                                />
                            </Col>
                            <Col md={{ size: 2, order: 7 }} className="px-1">
                                <Select options={this.props.packageTypes}
                                    isClearable
                                    value={this.props.filters.type}
                                    placeholder="Pakenditüüp"
                                    className="mb-2"
                                    getOptionLabel={(option) => option.value}
                                    getOptionValue={(option) => option.value}
                                    onChange={this.updateFilter('type')}
                                />
                            </Col>
                            {/*
                        <Col md={{size: 2, order: 7}} className="pl-1 pr-1">
                            <CustomInput placeholder="Kaal"
                                    type="number"
                                    value={this.props.filters.weight}
                                    className="mb-2"
                                    onChange={this.updateFilter("weight")}
                            />
                        </Col>
                        <Col md={{size: 2, order: 8}} className="px-1">
                            <Select options={this.props.packageVolumes}
                                    isClearable
                                    value={this.props.filters.volume}
                                    placeholder="Maht"
                                    getOptionLabel={(option) => option.value + "cl"}
                                    getOptionValue={(option) => option.value}
                                    className="mb-2"
                                    onChange={this.updateFilter('volume')}
                            />
                        </Col>
                        */}
                            <Col md={{ size: 2, order: 8 }} className="px-1">
                                <PackageConditionsSelect
                                    value={this.props.filters.conditions}
                                    onChange={value => this.updateFilter('conditions')(value || [])}
                                />
                            </Col>
                            <Col md={{ size: 2, order: 9 }} className="px-1">
                                <Select
                                    options={this.props.packageTestResults}
                                    isClearable
                                    value={this.props.filters.testResult}
                                    placeholder="Testitulemus"
                                    onChange={this.updateFilter('testResult')}
                                    className="mb-2"
                                />
                            </Col>
                            {/*
                        <Col md={{size: 2, order: 10}} className="px-1">
                            <DatePicker
                                className="mb-2"
                                placeholderText="Alates"
                                selected={this.props.filters.startDate}
                                onChange={this.updateFilter("startDate")}
                                maxDate={this.props.filters.endDate || new Date()}
                                dateFormat={AdminApplicationsSearch.DATE_FORMAT}
                            />
                        </Col>
                        <Col md={{size: 2, order: 11}} className="px-1">
                            <DatePicker
                                placeholderText="Kuni"
                                className="mb-2"
                                selected={this.props.filters.endDate}
                                minDate={this.props.filters.startDate}
                                onChange={this.updateFilter("endDate")}
                                dateFormat={AdminApplicationsSearch.DATE_FORMAT}
                            />
                        </Col>
                        */}
                            <Col md={{ size: 2, order: 5 }} className="px-1">
                                <Button
                                    outline
                                    block
                                    color="success"
                                    className="mb-2 px-1"
                                    onClick={this.props.onClearFilters}
                                >
                                    Tühjenda otsing
                                </Button>
                            </Col>
                            <Col md={{ size: 2, order: 10 }} className="px-1">
                                <Button
                                    type="submit"
                                    block
                                    color="success"
                                    className="mb-2 px-1"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.onSearch();
                                    }}
                                    disabled={this.props.packagesListLoading}
                                >
                                    {this.props.packagesListLoading ? <LoadingAnimation /> : "Otsi"}
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        );
    }

    updateFilter = key => value => {
        this.props.onFilterUpdate(key, value);
    }

    getFilteredPackageStatuses = () => {
        const statuses = this.props.packageStatuses || [];
        return statuses.filter(status => packageStatusTypeLists.TESTING_STATUSES.includes(status.id));
    }

}

const mapStateToProps = state => {
    return {
        allClients: state.general.allClients,
        //eanTypes: state.packages.eanTypes,
        categories: state.packages.categories,
        packageTypes: state.packages.packageTypes,
        //packageVolumes: state.packages.packageVolumes,
        packageStatuses: state.packages.packageStatuses,
        packageTestResults: state.packages.packageTestResults,
    }
};

export default connect(mapStateToProps)(AdminTestingSearch);
