import * as actionTypes from 'redux/actions/actionTypes';
import {updateObject} from 'redux/utility';

const initialState = {
    packages: [],
    header: {},
    details: {},
    loading: false,
    salesReportLoading: false,
    submitLoading: false,
    exportLoading: false,
    error: null,
    deleteOriginalReportLoading: false,
    deleteOriginalReportError: null,
    deleteRepairReportLoading: false,
    deleteRepairReportError: null,
    quantityLoading: false,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
}

const salesReportFetchStart = (state) => {
    return updateObject(state, {
        ...initialState,
        loading: true,
        salesReportLoading: true,
    })
};

const salesReportFetchSuccess = (state, report) => {
    return updateObject(state, {
        header: report.header,
        packages: report.content,
        details: report.report_details,
        loading: false,
        salesReportLoading: false,
    })
};

const salesReportFetchFail = (state, action) => {
    return updateObject(state, {
        details: {},
        header: {},
        packages: [],
        loading: false,
        salesReportLoading: false,
        error: action.err,
    })
};

const salesLineUpdateStart = (state, action) => {
    return updateObject(state, {
        quantityLoading: true,
        packages: state.packages.map(pkg => pkg.id === action.updatedPackage.id ? action.updatedPackage : pkg),
    })
};

const salesLineUpdateSuccess = (state, action) => {
    return updateObject(state, {
        packages: state.packages.map(pkg => pkg.id === action.updatedPackage.id ? action.updatedPackage : pkg),
        quantityLoading: false,
    })
};

const salesLineUpdateFail = (state, action) => {
    return updateObject(state, {
        quantityLoading: false,
        packages: state.packages.map(pkg => pkg.id === action.updatedPackage.id ? action.updatedPackage : pkg),
        error: action.err,
    })
};

const salesReportSubmitStart = (state) => {
    return updateObject(state, {
        loading: true,
        submitLoading: true,
        submitError: null,
    });
};

const salesReportSubmitSuccess = (state) => {
    return updateObject(state, {
        loading: false,
        submitLoading: false,
    });
};

const salesReportSubmitFail = (state, err) => {
    return updateObject(state, {
        loading: false,
        submitLoading: false,
        submitError: err,
    });
};

const salesReportDeleteOriginalStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        deleteOriginalReportLoading: true,
        deleteOriginalReportError: null,
    });
};

const salesReportDeleteOriginalSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        deleteOriginalReportLoading: false,
    });
};

const salesReportDeleteOriginalFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        deleteOriginalReportLoading: false,
        deleteOriginalReportError: action.error,
    });
};

const salesReportDeleteRepairStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        deleteRepairReportLoading: true,
        deleteRepairReportError: null,
    });
};

const salesReportDeleteRepairSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        deleteRepairReportLoading: false,
    });
};

const salesReportDeleteRepairFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        deleteRepairReportLoading: false,
        deleteRepairReportError: action.error
    });
};

const salesReportExportStart = (state) => {
    return updateObject(state, {
        loading: true,
        exportLoading: true,
        exportError: null,
    });
};

const salesReportExportSuccess = (state) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
    });
};

const salesReportExportFail = (state, err) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        exportError: err,
    });
};

const salesReportPackagesFieldChange = (state, action) => {
    const packages = [...state.packages];
    let newPackage;
    for (const row of packages) {
        if (row.id === action.id) {
            newPackage = {
                ...row,
                [action.field]: action.value,
            }
        }
    }

    return updateObject(state, {
        packages: packages.map(row => row.id === newPackage.id ? newPackage : row)
    });
}

const salesReportHeaderFieldChange = (state, action) => {
    const header = {...state.header};
    header[action.field] = action.value;
    return updateObject(state, {
        header: header,
    });
};

const salesReportHeaderTotalsUpdate = (state, action) => {
    const header = {...state.header};
    header.deposit_total = action.deposit_total;
    header.process_fee_total = action.process_fee_total;
    header.process_fee_vat = action.process_fee_vat;
    header.to_be_paid = action.to_be_paid;
    return updateObject(state, {
        header: header,
    });
};

export const salesReport = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SALES_REPORT_FETCH_START:
            return salesReportFetchStart(state, action);
        case actionTypes.SALES_REPORT_FETCH_SUCCESS:
            return salesReportFetchSuccess(state, action.report);
        case actionTypes.SALES_REPORT_FETCH_FAIL:
            return salesReportFetchFail(state, action);
        case actionTypes.SALES_REPORT_HEADER_FIELD_CHANGE:

            return salesReportHeaderFieldChange(state, action);
        case actionTypes.SALES_REPORT_HEADER_TOTALS_UPDATE:
            return salesReportHeaderTotalsUpdate(state, action);
        case actionTypes.SALES_REPORT_PACKAGES_FIELD_CHANGE:
            return salesReportPackagesFieldChange(state, action);

        case actionTypes.SALES_LINE_UPDATE_START:
            return salesLineUpdateStart(state, action);
        case actionTypes.SALES_LINE_UPDATE_SUCCESS:
            return salesLineUpdateSuccess(state, action);
        case actionTypes.SALES_LINE_UPDATE_FAIL:
            return salesLineUpdateFail(state, action);
        
            case actionTypes.SALES_REPORT_SUBMIT_START:
            return salesReportSubmitStart(state, action);
        case actionTypes.SALES_REPORT_SUBMIT_SUCCESS:
            return salesReportSubmitSuccess(state, action);
        case actionTypes.SALES_REPORT_SUBMIT_FAIL:
            return salesReportSubmitFail(state, action);

        case actionTypes.SALES_REPORT_DELETE_ORIGINAL_START:
            return salesReportDeleteOriginalStart(state, action);
        case actionTypes.SALES_REPORT_DELETE_ORIGINAL_SUCCESS:
            return salesReportDeleteOriginalSuccess(state, action);
        case actionTypes.SALES_REPORT_DELETE_ORIGINAL_FAIL:
            return salesReportDeleteOriginalFail(state, action);

        case actionTypes.SALES_REPORT_DELETE_REPAIR_START:
            return salesReportDeleteRepairStart(state, action);
        case actionTypes.SALES_REPORT_DELETE_REPAIR_SUCCESS:
            return salesReportDeleteRepairSuccess(state, action);
        case actionTypes.SALES_REPORT_DELETE_REPAIR_FAIL:
            return salesReportDeleteRepairFail(state, action);

        case actionTypes.SALES_REPORT_EXPORT_START:
            return salesReportExportStart(state, action);
        case actionTypes.SALES_REPORT_EXPORT_SUCCESS:
            return salesReportExportSuccess(state, action);
        case actionTypes.SALES_REPORT_EXPORT_FAIL:
            return salesReportExportFail(state, action);
        
        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};