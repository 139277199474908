import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { matchPath } from "react-router";

import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Row,
} from "reactstrap";
// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory, {
    selectFilter,
    textFilter,
} from "react-bootstrap-table2-filter";
// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// custom inputs
import Input from "components/Form/Input";
import Select from "components/Form/Select";
import WrappedLoader from "components/Loader/WrappedLoader";

import urls from "config/urls";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import { getTestStatusType } from "util/Partner";
import { isConnectedToClientWithActiveContract } from "util/Access";
import { exportClientPackagesTable } from "util/excel/ExportClientPackagesTable";
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

import "./PackagesTable.scss";

import {
    fetchCategories,
    fetchColors,
    fetchEanTypes,
    fetchPackagesList,
    fetchPackageStatuses,
    fetchPackageTypes,
    fetchPackageVolumes,
    fetchPackageTestResults,
    packageStatusTypes,
} from "util/Packages";
import moment from "moment";
import { withTranslation } from "react-i18next";

function NoDataIndication(props) {
    let content = props.t("packages.noPackagesText", "Ükski pakend ei vasta otsingutingimustele.");
    if (props.loading) {
        content = <WrappedLoader />;
    }

    return <div className="text-center">{content}</div>;
}

class PackagesTable extends React.Component {
    packagesTableNode = null; // Ref into packages bootstrap table.

    constructor(props) {
        super(props);

        this.state = {
            filterEan: "",
            filterProductName: "",
            filterEanType: null,
            filterCategory: "",
            filterPackageType: "",
            filterCapacity: "",
            filterStatus: "",
            filterTestResult: "",
            startDate: null,
            endDate: null,
        };
    }

    componentDidMount() {
        // packages are queried only on packages page
        if (
            matchPath(this.props.location.pathname, {
                path: urls.PACKAGES,
                exact: true,
                strict: true,
            })
        ) {
            fetchPackagesList();
        }

        fetchPackageStatuses(this.props.packageStatuses);
        fetchPackageVolumes(this.props.packageVolumes);
        fetchColors(this.props.packageColors);
        fetchCategories(this.props.categories);
        fetchPackageTypes(this.props.packageTypes);
        fetchEanTypes(this.props.eanTypes);
        fetchPackageTestResults(this.props.packageTestResults);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.filterEan(this.state.filterEan);
        this.filterProductName(this.state.filterProductName);
        this.filterEanType(
            this.state.filterEanType ? this.state.filterEanType.value : ""
        );
        this.filterCategory(
            this.state.filterCategory
                ? this.state.filterCategory.description
                : ""
        );
        this.filterPackageType(
            this.state.filterPackageType
                ? this.state.filterPackageType.value
                : ""
        );
        this.filterCapacity(
            this.state.filterCapacity ? this.state.filterCapacity.value : ""
        );
        this.filterStatus(
            this.state.filterStatus ? this.state.filterStatus.id : ""
        );
        this.filterTestResult(
            this.state.filterTestResult ? this.state.filterTestResult.label : ""
        );
    }

    handleChange = (name) => (value) => {
        this.setState({ [name]: value });
    };

    handleDateChange = (name, date) => {
        this.setState({
            [name]: date,
        });
    };

    handleSelectChange = (name) => (selectedObject) => {
        this.setState({
            [name]: selectedObject ? selectedObject : null,
        });
    };

    filterDates = () => {
        return this.props.packagesList.filter((pkg) => {
            const startMoment = moment(this.state.startDate);
            const endMoment = moment(this.state.endDate);
            const dateFieldMoment = moment(pkg.combined_at, "DD.MM.YYYY");

            if (startMoment.isValid() && endMoment.isValid()) {
                return (
                    dateFieldMoment.isSameOrAfter(startMoment) &&
                    dateFieldMoment.isSameOrBefore(endMoment)
                );
            } else if (startMoment.isValid()) {
                return dateFieldMoment.isSameOrAfter(startMoment);
            } else if (endMoment.isValid()) {
                return dateFieldMoment.isSameOrBefore(endMoment);
            } else {
                return true;
            }
        });
    };

    clearSearchFields = () => {
        this.setState({
            filterEan: "",
            filterProductName: "",
            filterEanType: "",
            filterCategory: "",
            filterPackageType: "",
            filterCapacity: "",
            filterStatus: "",
            filterTestResult: "",
            startDate: null,
            endDate: null,
        });
    };

    onExport = () => {
        let fileName = "Pakendid";
        let client;
        try {
            client = this.props.user.user_type.client_name;
            fileName += " - " + client;
        } catch (err) {
            // No user or user.user_type
            client = "";
        }

        let filteredPackages = [];
        try {
            filteredPackages = this.packagesTableNode.table.props.data; // Currently displayed rows in packages table
        } catch (err) {
            filteredPackages = [];
        }

        try {
            exportClientPackagesTable(
                fileName,
                filteredPackages,
                { ...this.state },
                client
            );
        } catch (err) {
            clearLocalAlerts();
            addNewLocalErrorAlert(
                this.props.t(
                    "packages.filter.excelExportError",
                    "Excel faili loomine ebaõnnestus!"
                ),
                this.props.location.pathname,
                true
            );
        }
    };

    render() {
        const { t } = this.props;
        return (
            <Card className="packages-card">
                <CardHeader className="">
                    <Row className="align-items-center justify-content-between mb-2 px-2">
                        <Col className="mb-2 px-1">
                            <span className="bold-19">
                                {t(
                                    "packages.filter.searchPackage",
                                    "Pakendi otsing"
                                )}
                            </span>
                        </Col>
                        {isConnectedToClientWithActiveContract() && (
                            <Col lg={2} md={4} className="text-right px-1">
                                <Button
                                    block
                                    color="secondary"
                                    onClick={() =>
                                        this.props.history.push(
                                            urls.ADD_PACKAGES
                                        )
                                    }
                                >
                                    {t(
                                        "packages.filter.registerNewPackage",
                                        "Registreeri uus pakend"
                                    )}
                                </Button>
                            </Col>
                        )}
                    </Row>
                    <Row className="align-items-center px-2">
                        <Col lg={6} md={12}>
                            <Row>
                                <Col md={4} className="px-1">
                                    <Input
                                        placeholder={t(
                                            "packages.filter.ean",
                                            "EAN"
                                        )}
                                        name="filterEan"
                                        value={this.state.filterEan}
                                        className="select mb-2"
                                        onChange={this.handleChange(
                                            "filterEan"
                                        )}
                                    />
                                    <Select
                                        options={this.props.eanTypes}
                                        isClearable
                                        value={this.state.filterEanType}
                                        placeholder={t(
                                            "packages.filter.eanType",
                                            "EAN tüüp"
                                        )}
                                        name="filterEanType"
                                        getOptionLabel={(option) =>
                                            option.value
                                        }
                                        getOptionValue={(option) =>
                                            option.value
                                        }
                                        onChange={this.handleSelectChange(
                                            "filterEanType"
                                        )}
                                        className="mb-2"
                                    />
                                </Col>
                                <Col md={4} className="px-1">
                                    <Input
                                        placeholder={t(
                                            "packages.filter.productName",
                                            "Nimetus"
                                        )}
                                        name="filterProductName"
                                        value={this.state.filterProductName}
                                        className="mb-2"
                                        onChange={this.handleChange(
                                            "filterProductName"
                                        )}
                                    />
                                    <Select
                                        options={this.props.categories}
                                        isClearable
                                        name="filterCategory"
                                        value={this.state.filterCategory}
                                        placeholder={t(
                                            "packages.filter.category",
                                            "Kategooria"
                                        )}
                                        className="select mb-2"
                                        getOptionLabel={(option) =>
                                            option.description
                                        }
                                        getOptionValue={(option) =>
                                            option.value
                                        }
                                        onChange={this.handleSelectChange(
                                            "filterCategory"
                                        )}
                                    />
                                </Col>
                                <Col md={4} className="pl-1 pr-1">
                                    <Select
                                        options={this.props.packageTypes}
                                        isClearable
                                        name="filterPackageType"
                                        value={this.state.filterPackageType}
                                        placeholder={t(
                                            "packages.filter.type",
                                            "Tüüp"
                                        )}
                                        className="select mb-2"
                                        getOptionLabel={(option) =>
                                            option.value
                                        }
                                        getOptionValue={(option) =>
                                            option.value
                                        }
                                        onChange={this.handleSelectChange(
                                            "filterPackageType"
                                        )}
                                    />
                                    <Select
                                        options={this.props.packageVolumes}
                                        isClearable
                                        value={this.state.filterCapacity}
                                        name="filterCapacity"
                                        placeholder={t(
                                            "packages.filter.volume",
                                            "Maht"
                                        )}
                                        getOptionLabel={(option) =>
                                            option.value + "cl"
                                        }
                                        getOptionValue={(option) =>
                                            option.value
                                        }
                                        className="select mb-2"
                                        onChange={this.handleSelectChange(
                                            "filterCapacity"
                                        )}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={4} md={8} className="px-1">
                            <Row>
                                <Col md="6" className="pr-md-1">
                                    <Select
                                        options={this.props.packageStatuses}
                                        isClearable
                                        value={this.state.filterStatus}
                                        getOptionLabel={(option) =>
                                            option.value
                                        }
                                        getOptionValue={(option) =>
                                            option.value
                                        }
                                        name="filterStatus"
                                        placeholder={t(
                                            "packages.filter.status",
                                            "Staatus"
                                        )}
                                        onChange={this.handleSelectChange(
                                            "filterStatus"
                                        )}
                                        className="select mb-2"
                                    />
                                </Col>
                                <Col md="6" className="pl-md-1">
                                    <Select
                                        options={this.props.packageTestResults}
                                        isClearable
                                        value={this.state.filterTestResult}
                                        getOptionLabel={(option) =>
                                            option.label
                                        }
                                        getOptionValue={(option) =>
                                            option.label
                                        }
                                        name="filterTestResult"
                                        placeholder={t(
                                            "packages.filter.testResult",
                                            "Testitulemus"
                                        )}
                                        onChange={this.handleSelectChange(
                                            "filterTestResult"
                                        )}
                                        className="select mb-2"
                                    />
                                </Col>
                            </Row>
                            <Row>
                                <Col md="6" className="pr-md-1">
                                    <DatePicker
                                        className="select mb-2"
                                        placeholderText={t(
                                            "packages.filter.from",
                                            "Alates"
                                        )}
                                        selected={this.state.startDate}
                                        onChange={(date) =>
                                            this.handleDateChange(
                                                "startDate",
                                                date
                                            )
                                        }
                                    />
                                </Col>
                                <Col md="6" className="pl-md-1">
                                    <DatePicker
                                        placeholderText={t(
                                            "packages.filter.to",
                                            "Kuni"
                                        )}
                                        className="select mb-2"
                                        selected={this.state.endDate}
                                        minDate={this.state.startDate}
                                        onChange={(date) =>
                                            this.handleDateChange(
                                                "endDate",
                                                date
                                            )
                                        }
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={2} md={4} className="px-1">
                            <Button
                                outline
                                block
                                color="success"
                                className="mb-2 px-1"
                                onClick={this.clearSearchFields}
                            >
                                {t(
                                    "packages.filter.clearSearch",
                                    "Tühjenda otsing"
                                )}
                            </Button>
                            <Button
                                block
                                color="success"
                                className="mb-2 px-1"
                                onClick={this.onExport}
                            >
                                {t("packages.filter.export", "Ekspordi")}
                            </Button>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="packages-card-body border-top p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }

    renderTable() {
        const { t } = this.props;
        const statusFormatter = (statusLabel, row) => {
            return (
                <StatusCell
                    status={row.status.label}
                    type={packageStatusTypes[row.status.value]}
                    className="d-inline-flex"
                />
            );
        };

        const packages = this.filterDates();

        const columns = [
            {
                dataField: "EAN",
                text: t("packages.filter.ean", "EAN"),
                sort: true,
                classes: "frozen-first-column",
                headerClasses: "frozen-first-column",
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterEan = filter;
                    },
                }),
            },
            {
                dataField: "EAN_type",
                text: t("packages.filter.eanType", "EAN tüüp"),
                sort: true,
                filter: selectFilter({
                    className: "d-none",
                    options: this.props.eanTypes,
                    getFilter: (filter) => {
                        this.filterEanType = filter;
                    },
                }),
            },
            {
                dataField: "name",
                text: t("packages.filter.productName", "Nimetus"),
                sort: true,
                filter: textFilter({
                    className: "d-none",
                    getFilter: (filter) => {
                        this.filterProductName = filter;
                    },
                }),
            },
            {
                dataField: "package_category",
                text: t("packages.filter.category", "Kategooria"),
                sort: true,
                filter: selectFilter({
                    className: "d-none",
                    options: this.props.categories,
                    getFilter: (filter) => {
                        this.filterCategory = filter;
                    },
                }),
            },
            {
                dataField: "package_type",
                text: t("packages.filter.type", "Tüüp"),
                sort: true,
                filter: selectFilter({
                    className: "d-none",
                    options: this.props.packageTypes,
                    getFilter: (filter) => {
                        this.filterPackageType = filter;
                    },
                }),
            },
            {
                dataField: "volume",
                text: t("packages.filter.volume", "Maht"),
                sort: true,
                headerStyle: (column, colIndex) => {
                    return { width: "100px" };
                },
                filter: selectFilter({
                    className: "d-none",
                    options: this.props.packageVolumes,
                    getFilter: (filter) => {
                        this.filterCapacity = filter;
                    },
                }),
            },
            {
                dataField: "weight",
                text: t("packages.filter.weight", "Kaal"),
                sort: true,
                headerStyle: (column, colIndex) => {
                    return { width: "100px" };
                }
            },
            {
                dataField: "status.label",
                filterValue: (cell, row) => row.status.value,
                text: t("packages.filter.status", "Staatus"),
                sort: true,
                formatter: statusFormatter,
                filter: selectFilter({
                    className: "d-none",
                    options: this.props.packageStatuses,
                    getFilter: (filter) => {
                        this.filterStatus = filter;
                    },
                }),
            },
            {
                dataField: "combined_at",
                text: t("packages.filter.date", "Kuupäev"),
                sort: true,
                sortValue: (cell) => moment(cell, "DD.MM.YYYY"),
            },
            {
                dataField: "package_test",
                text: t("packages.filter.testResult", "Testitulemus"),
                sort: true,
                filter: selectFilter({
                    className: "d-none",
                    options: this.props.packageTestResults,
                    getFilter: (filter) => {
                        this.filterTestResult = filter;
                    },
                }),
                formatter: (test_status) => {
                    if (!test_status) {
                        return "";
                    } else {
                        return (
                            <StatusCell
                                status={test_status}
                                type={getTestStatusType(test_status)}
                            />
                        );
                    }
                },
            },
        ];

        return (
            <BootstrapTable
                ref={(n) => (this.packagesTableNode = n)}
                bootstrap4
                bordered={false}
                classes="packages-table fixed-header no-top-border header-bottom-shadow-border"
                wrapperClasses="packages-table-wrapper"
                keyField="id"
                data={packages}
                columns={columns}
                filter={filterFactory()}
                filterPosition="inline"
                noDataIndication={
                    <NoDataIndication
                        loading={this.props.packagesListLoading}
                        t={t}
                    />
                }
                defaultSorted={[{
                    dataField: "combined_at",
                    order: "desc",
                }]}
            // pagination={paginationFactory()}
            />
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        activeClientId: state.auth.user
            ? state.auth.user.active_client_id
            : null,
        packagesList: state.packages.packagesList,
        packagesListLoading: state.packages.packagesListLoading,
        packagesErrorMessage: state.packages.error || "",
        eanTypes: state.packages.eanTypes,
        categories: state.packages.categories,
        packageTypes: state.packages.packageTypes,
        packageVolumes: state.packages.packageVolumes,
        packageColors: state.packages.packageColors,
        packageStatuses: state.packages.packageStatuses,
        packageTestResults: state.packages.packageTestResults,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(PackagesTable))
);
