import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";
import { getPageCount } from "util/General";

const filterClient = (client, filters) => {
    if (filters.client && !client.name.includes(filters.client.name)) {
        return false;
    }
    if (
        filters.contractStatus &&
        filters.contractStatus.label &&
        client.contract_status.label.toLowerCase() !==
            filters.contractStatus.label.toLowerCase()
    ) {
        return false;
    }
    return true;
};

const updateClientsFilteredData = ({
    clients,
    clientsFilters,
    clientsFilteredPagination,
}) => {
    if (!clients) {
        return {
            clientsFiltered: null,
            clientsFilteredPagination: {
                ...clientsFilteredPagination,
                dataSize: 0,
                page: 1,
            },
        };
    }

    const clientsFiltered = clients.filter((client) =>
        filterClient(client, clientsFilters)
    );

    const newDataSize = clientsFiltered.length;
    const currentPage = clientsFilteredPagination.page;
    const sizePerPage = clientsFilteredPagination.sizePerPage;
    const isCurrentPageValid =
        currentPage <= getPageCount(newDataSize, sizePerPage);

    return {
        clientsFiltered: clientsFiltered,
        clientsFilteredPagination: {
            ...clientsFilteredPagination,
            page: isCurrentPageValid ? currentPage : 1,
            dataSize: newDataSize,
        },
    };
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ],
};

const initialFilters = {
    client: null,
    contractStatus: null,
};

const initialSorting = {
    dataField: "registered_at",
    order: "desc",
};

const initialState = {
    clients: null,
    partners: [],
    epp: [],
    clientsFilteredPagination: initialPagination,
    clientsFilters: initialFilters,
    clientsSorting: initialSorting,
    clientsFiltered: null,
    loading: true,
    loadingPartners: true,
    loadingEpp: true,
    error: null,
};

const onAdminClientsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        error: null,
        clients: null,
        ...updateClientsFilteredData(state),
    });
};

const onAdminClientsFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: null,
        clients: action.clients,
        ...updateClientsFilteredData({ ...state, clients: action.clients }),
    });
};

const onAdminClientsFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.error,
        clients: null,
        ...updateClientsFilteredData(state),
    });
};

const onAdminClientsPaginationUpdate = (state, action) => {
    return updateObject(state, {
        clientsFilteredPagination: action.pagination,
    });
};

const onAdminClientsFilterUpdate = (state, action) => {
    const clientsFilters = {
        ...state.clientsFilters,
        [action.key]: action.value,
    };

    return updateObject(state, {
        clientsFilters,
        ...updateClientsFilteredData({ ...state, clientsFilters }),
    });
};

const onAdminClientsSortingUpdate = (state, action) => {
    return updateObject(state, {
        clientsSorting: action.sorting,
    });
};

const onAdminPartnersFetchStart = (state) => {
    return updateObject(state, {
        loadingPartners: true,
        error: null,
        partners: [],
    });
};

const onAdminPartnersFetchSuccess = (state, action) => {
    return updateObject(state, {
        loadingPartners: false,
        error: null,
        partners: action.partners,
    });
};

const onAdminPartnersFetchFail = (state, action) => {
    return updateObject(state, {
        loadingPartners: false,
        error: action.error,
        partners: [],
    });
};

const onAdminPartnersUserUpdate = (state, action) => {
    const updatedUser = action.user;
    const updatedPartners = state.partners.map((user) =>
        user.id === updatedUser.id ? updatedUser : user
    );
    return updateObject(state, {
        partners: updatedPartners,
    });
};

const onAdminEppUsersFetchStart = (state) => {
    return updateObject(state, {
        loadingEppUsers: true,
        error: null,
        epp: [],
    });
};

const onAdminEppUsersFetchSuccess = (state, action) => {
    return updateObject(state, {
        loadingPartners: false,
        error: null,
        epp: action.eppUsers,
    });
};

const onAdminEppUsersFetchFail = (state, action) => {
    return updateObject(state, {
        loadingEppUsers: false,
        error: action.error,
        epp: [],
    });
};

const onAdminEppUserUpdate = (state, action) => {
    const updatedUser = action.user;

    return updateObject(state, {
        epp: state.epp.map((user) =>
            user.id === updatedUser.id ? updatedUser : user
        ),
    });
};

export const users = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ADMIN_CLIENTS_FETCH_START:
            return onAdminClientsFetchStart(state, action);
        case actionTypes.ADMIN_CLIENTS_FETCH_SUCCESS:
            return onAdminClientsFetchSuccess(state, action);
        case actionTypes.ADMIN_CLIENTS_FETCH_FAIL:
            return onAdminClientsFetchFail(state, action);
        case actionTypes.ADMIN_CLIENTS_PAGINATION_UPDATE:
            return onAdminClientsPaginationUpdate(state, action);
        case actionTypes.ADMIN_CLIENTS_SORTING_UPDATE:
            return onAdminClientsSortingUpdate(state, action);
        case actionTypes.ADMIN_CLIENTS_FILTER_UPDATE:
            return onAdminClientsFilterUpdate(state, action);

        case actionTypes.ADMIN_PARTNERS_FETCH_START:
            return onAdminPartnersFetchStart(state, action);
        case actionTypes.ADMIN_PARTNERS_FETCH_SUCCESS:
            return onAdminPartnersFetchSuccess(state, action);
        case actionTypes.ADMIN_PARTNERS_FETCH_FAIL:
            return onAdminPartnersFetchFail(state, action);
        case actionTypes.ADMIN_PARTNER_USER_UPDATE:
            return onAdminPartnersUserUpdate(state, action);

        case actionTypes.ADMIN_EPP_USERS_FETCH_START:
            return onAdminEppUsersFetchStart(state, action);
        case actionTypes.ADMIN_EPP_USERS_FETCH_SUCCESS:
            return onAdminEppUsersFetchSuccess(state, action);
        case actionTypes.ADMIN_EPP_USERS_FETCH_FAIL:
            return onAdminEppUsersFetchFail(state, action);
        case actionTypes.ADMIN_EPP_USER_UPDATE:
            return onAdminEppUserUpdate(state, action);

        default:
            return state;
    }
};
