import React from "react";
import { fetchClient } from "util/admin/General";
import {
    updateNavigationHeaderSuffix,
    clearNavigationHeaderSuffix,
} from "util/Navigation";

import LeftText from "components/Misc/LeftText";
import { Row, Col } from "reactstrap";

import { NavLink, Route } from "react-router-dom";
import { Switch } from "react-router";

import urls, {
    getAdminClientProfileUrl,
    getAdminClientUsersUrl,
} from "config/urls";

class Client extends React.Component {
    state = {
        clientId: this.props.match.params.id,
    };

    componentDidMount() {
        fetchClient(this.state.clientId).then((client) => {
            updateNavigationHeaderSuffix(client.name);
        });
    }

    componentWillUnmount() {
        clearNavigationHeaderSuffix();
    }

    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={(props) => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        return (
            <>
                <Row className="pb-4">
                    <Col xs="auto">
                        <LeftText
                            onClick={() =>
                                this.props.history.push(urls.EPP_USERS_CLIENTS)
                            }
                            text="Tagasi"
                        />
                    </Col>
                </Row>
                <div className="navigation-buttons pb-4">
                    <NavLink
                        to={getAdminClientProfileUrl(this.state.clientId)}
                        className="btn btn-default mr-1"
                    >
                        Profiil
                    </NavLink>
                    <NavLink
                        to={getAdminClientUsersUrl(this.state.clientId)}
                        className="btn btn-default ml-1 mr-1"
                    >
                        Kasutajad
                    </NavLink>
                </div>

                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </>
        );
    }
}

export default Client;
