import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Modal, ModalBody, Row, Col, Button, Form } from "reactstrap";
import { withTranslation } from "react-i18next";

import urls from "config/urls";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
    addNewLocalWarningAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";
import { isClientMode, isAdminMode } from "util/Access";
import { addUser, fetchUsers } from "util/Profile";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";
import WrapperLoader from "components/Loader/WrappedLoader";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import Input from "components/Form/Input";
import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";

import "./AddUserModal.scss";
import { adminfetchUsers } from "util/admin/Users";

class AddUserModal extends Component {
    static propTypes = {
        userRole: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,
        toggle: PropTypes.func.isRequired,
    };

    static ALERT_GROUP = "addUserGroup";

    getInitialState = () => {
        return {
            isAdmin: isAdminMode() && this.props.userRole === "CLIENT" ? true : false,
            firstName: "",
            lastName: "",
            personalCode: "",
            phone: "",
            email: "",
            profession: "",
            loading: false,
        };
    };

    state = this.getInitialState();

    componentDidMount() {
        this.setState({
            partnerId: this.props.userRole === "PARTNER" ? 1 : null,
            clientId:
                this.props.userRole === "CLIENT" ? this.props.clientId : null,
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            clearLocalAlerts();
            this.setState(this.getInitialState());
        }
    }

    toggle = () =>
        this.setState({ modal: !this.state.modal }, () => {
            this.props.history.push(urls.INVOICES);
        });

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    centered
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    size="md"
                    className="add-user-modal"
                >
                    <ModalBody className="">
                        <Row className="pb-2 header-section">
                            <Col>
                                <span className="bold-19 modal-title">
                                    {t("users.addUserModal.header", "Lisa uus kasutaja")}
                                </span>
                            </Col>
                            <Col className="float-right text-right col-auto">
                                <CloseTextIcon
                                    text={t("buttons.close", "Sulge")}
                                    onClick={this.props.toggle}
                                />
                            </Col>
                        </Row>
                        <Row className="content-section">
                            <Col>{this.renderContent()}</Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderContent() {
        if (this.props.loading) {
            return <WrapperLoader />;
        }
        const { t } = this.props;

        const isShowUserRoles = this.props.userRole === "CLIENT" && (isClientMode() || isAdminMode());

        return (
            <Form id="add-user-form" onSubmit={this.onClickAddUser}>
                {isShowUserRoles && (
                    <Row>
                        <Col className="mb-2">
                            <CheckboxButton
                                isSelected={!this.state.isAdmin}
                                disabled={false}
                                labelText={t("users.fields.userRoleLabel.user", "Kasutaja")}
                                onClick={() =>
                                    this.setState({ isAdmin: false })
                                }
                                extraProps={{ block: true }}
                            />
                        </Col>
                        <Col className="mb-2">
                            <CheckboxButton
                                isSelected={this.state.isAdmin}
                                disabled={false}
                                labelText={t("users.fields.userRoleLabel.admin", "Admin")}
                                onClick={() => this.setState({ isAdmin: true })}
                                extraProps={{ block: true }}
                            />
                        </Col>
                    </Row>
                )}
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.firstName}
                            labelText={t("users.fields.firstNameLabel", "Eesnimi")}
                            placeholder={t("users.fields.firstNamePlaceholder", "Nimi")}
                            className="user-given-name"
                            onChange={(value) =>
                                this.setState({ firstName: value })
                            }
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.lastName}
                            labelText={t("users.fields.lastNameLabel", "Perekonnanimi")}
                            placeholder={t("users.fields.lastNamePlaceholder", "Perekonnanimi")}
                            className="user-surname"
                            onChange={(value) =>
                                this.setState({ lastName: value })
                            }
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.personalCode}
                            type="number"
                            labelText={t("users.fields.personalCodeLabel", "Isikukood")}
                            placeholder={t("users.fields.personalCodePlaceholder", "Isikukood")}
                            className="user-personal-code"
                            onChange={(value) =>
                                this.setState({ personalCode: value })
                            }
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.phone}
                            type="tel"
                            labelText={t("users.fields.phoneLabel", "Telefoninumber")}
                            placeholder={t("users.fields.phonePlaceholder", "Telefoninumber")}
                            className="user-phone"
                            onChange={(value) =>
                                this.setState({ phone: value })
                            }
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.email}
                            type="email"
                            labelText={t("users.fields.emailLabel", "E-mail")}
                            placeholder={t("users.fields.emailPlaceholder", "E-mail")}
                            className="user-email"
                            onChange={(value) =>
                                this.setState({ email: value })
                            }
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.profession}
                            labelText={t("users.fields.professionLabel", "Ametinimetus")}
                            placeholder={t("users.fields.professionPlaceholder", "Ametinimetus")}
                            className="user-profession"
                            onChange={(value) =>
                                this.setState({ profession: value })
                            }
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Alerts
                    alerts={this.props.alerts}
                    hideGlobal={true}
                    filterGroups={[AddUserModal.ALERT_GROUP]}
                />
                <Row>
                    <Col>
                        <Button
                            block
                            color="success"
                            disabled={this.state.loading}
                        >
                            {this.state.loading ? <LoadingAnimation /> : t("users.addUserModal.addButton", "Lisa")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    onClickAddUser = (e) => {
        e.preventDefault();
        const { t } = this.props;
        clearLocalAlerts();
        this.setState({ loading: true }, () =>
            addUser(this.state)
                .then((response) => {
                    if (response.status === 208) {
                        addNewLocalInfoAlert(
                            t("users.addUserModal.alerts.info.userAlreadyConnectedToClient", "Kasutaja juba kliendiga seotud."),
                            AddUserModal.ALERT_GROUP
                        );
                        this.setState({ loading: false });
                        return;
                    } else if (response.status === 226) {
                        addNewLocalWarningAlert(
                            response.data.code,
                            AddUserModal.ALERT_GROUP
                        );
                        this.setState({ loading: false });
                        this.setState({ email: response.data.email });
                        return;
                    }
                    this.props.toggle();
                    if (isClientMode()) {
                        fetchUsers();
                    } else if (isAdminMode) {
                        adminfetchUsers(null, true, null);
                        adminfetchUsers(null, null, true);
                    }
                    addNewLocalSuccessAlert(
                        t("users.addUserModal.alerts.success.userAdded", "Kasutaja lisatud."),
                        this.props.location.pathname
                    );
                    this.setState({ loading: false });
                })
                .catch((error) => {
                    addNewLocalErrorAlert(
                        error.message,
                        AddUserModal.ALERT_GROUP
                    );
                    this.setState({ loading: false });
                })
        );
    };
}

export default withRouter(withTranslation()(AddUserModal));
