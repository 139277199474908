import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Search from "components/Admin/Reports/Search/Search";
import Table from "components/Admin/Reports/Table/Table";

import { fetchSalesReports } from "util/admin/SalesReports";

import {
    adminSalesReportsFilterUpdate,
    adminSalesReportsFilterClear,
} from "redux/actions/admin/salesReports";


class AdminSalesReportsView extends React.Component {
    static propTypes = {
        salesReportsList: PropTypes.array,
        salesReportsListLoading: PropTypes.bool,
        salesReportsListFiltersUsed: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        adminSalesReportsFilterUpdate: PropTypes.func.isRequired,
        adminSalesReportsFilterClear: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            isRedirectToSpecificClientReports: false,
        }
    }

    componentDidMount() {
        const locationState = this.props.location.state;
        if (locationState && locationState.filterClientOptionObject) {
            this.setState({ isRedirectToSpecificClientReports: true });
            this.props.adminSalesReportsFilterUpdate('client', locationState.filterClientOptionObject);
        }

        // Enable refresh on mount if necessary. Used when redirecting after deleting original sales report
        if (this.props.location.state?.refreshList === true) {
            this.getSalesReportsList();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.isRedirectToSpecificClientReports && prevProps.filters.client !== this.props.filters.client) {
            this.setState({ isRedirectToSpecificClientReports: false });
            this.getSalesReportsList(this.props.filters);
        }
    }

    getSalesReportsList = (filters = null) => {
        if (filters == null) {
            filters = this.props.salesReportsListFiltersUsed;
        }
        fetchSalesReports(filters, this.props.location.pathname);
    }


    render() {
        return (
            <>
                <Search
                    onSearch={this.getSalesReportsList}
                    filters={this.props.filters}
                    filtersUpdate={this.props.adminSalesReportsFilterUpdate}
                    filtersClear={this.props.adminSalesReportsFilterClear}
                />
                <Table
                    salesReportsList={this.props.salesReportsList}
                    salesReportsListLoading={this.props.salesReportsListLoading}
                />
            </>
        );
    }
}

const reduxActions = {
    adminSalesReportsFilterUpdate,
    adminSalesReportsFilterClear,
}

const mapStateToProps = state => {
    return {
        salesReportsList: state.admin.salesReports.salesReportsList,
        salesReportsListLoading: state.admin.salesReports.salesReportsListLoading,
        salesReportsListFiltersUsed: state.admin.salesReports.salesReportsListFiltersUsed,
        filters: state.admin.salesReports.filters,
    }
};

export default withRouter(connect(mapStateToProps, reduxActions)(AdminSalesReportsView));
