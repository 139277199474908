import * as actionTypes from 'redux/actions/actionTypes';

export const salesReportImportOpen = (details) => {
    return {
        type: actionTypes.SALES_REPORT_IMPORT_OPEN,
        details: details
    }
};

export const salesReportImportFileChange = (file) => {
    return {
        type: actionTypes.SALES_REPORT_IMPORT_FILE_CHANGE,
        file: file
    }
};

export const salesReportImportStart = () => {
    return {
        type: actionTypes.SALES_REPORT_IMPORT_START
    }
};

export const salesReportImportSuccess = () => {
    return {
        type: actionTypes.SALES_REPORT_IMPORT_SUCCESS,
    }
};

export const salesReportImportFail = (errors) => {
    return {
        type: actionTypes.SALES_REPORT_IMPORT_FAIL,
        errors: errors
    }
};

export const salesReportImportClose = () => {
    return {
        type: actionTypes.SALES_REPORT_IMPORT_CLOSE
    }
};