import React, { Component } from "react";
import axios from "../../../../axios"
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Row, Col, Button, Form, FormGroup, Label } from "reactstrap";

import {
    CLIENT_WARRANT_SHOW,
    ADMIN_CLIENT_WARRANT_SHOW,
    CLIENT_WARRANT_USERS,
    ADMIN_CLIENT_WARRANT_USERS,
    ADMIN_CLIENT_WARRANT_USER,
    ADMIN_CLIENT_WARRANT_USER_FILE_UPLOAD
} from "config/Api";

import {
    isAdminMode,
} from "util/Access";
import { getServerErrorMessage } from "util/Errors";
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";
// Custom inputs
import Input from "components/Form/Input";
import Alerts from "components/Alerts/Alerts";

import CenteredRowCol from "components/Misc/CenteredRowCol";
import WrapperLoader from "components/Loader/WrappedLoader";
import SmallLoader from "components/Loader/WrappedPulseLoader";
import { withTranslation } from "react-i18next";


class WarrantedUserForm extends Component {
    static propTypes = {
        clientId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Only required if isAdminMode
        alertGroup: PropTypes.string,
        user: PropTypes.object,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
    };

    static ALERT_GROUP = "WarrantedUserFormAlertGroup";

    constructor(props) {
        super(props);

        this.state = {
            showComponent: false,
            invalidFields: {},
            hasLoadedOnce: false,
            fetchLoading: false,
            updateLoading: false,
            createLoading: false,
            uploadFileLoading: false,
            warrantedUser: null
        };
    }

    componentDidMount() {
        this.fetchShowWarrantComponent();
    }

    fetchShowWarrantComponent = () => {
        const { clientId, location } = this.props;
        let endpoint = isAdminMode() ? ADMIN_CLIENT_WARRANT_SHOW : CLIENT_WARRANT_SHOW;
        endpoint = endpoint.replace("<client_id>", clientId);

        clearLocalAlerts();
        return axios
            .get(endpoint)
            .then((resp) => {
                if (resp.data === true) {
                    this.setState({ showComponent: true });
                    this.fetchClientWarrantedUsers();
                }

            })
            .catch((err) => {
                const errorMessage = getServerErrorMessage(err);
                addNewLocalErrorAlert(
                    `Volitatud isiku funktsionaalsuse kontroll ebaõnnestus: ${errorMessage}`,
                    location.pathname,
                    true
                );
            })
    }

    fetchClientWarrantedUsers = () => {
        const { clientId } = this.props;
        let endpoint = isAdminMode() ? ADMIN_CLIENT_WARRANT_USERS : CLIENT_WARRANT_USERS;
        endpoint = endpoint.replace("<client_id>", clientId);

        clearLocalAlerts();
        this.setState({ fetchLoading: true });
        return axios
            .get(endpoint)
            .then((resp) => {
                const warrantedUsers = resp.data;
                if (warrantedUsers && warrantedUsers.length > 0) {
                    const warrantedUser = warrantedUsers[0];
                    this.setState({ warrantedUser });
                }

            })
            .catch((err) => {
                const errorMessage = getServerErrorMessage(err);
                addNewLocalErrorAlert(
                    `Volitatud isiku laadimine ebaõnnestus: ${errorMessage}`,
                    this.getAlertGroupOrDefault(),
                    true
                );
            })
            .finally(() => this.setState({ fetchLoading: false, hasLoadedOnce: true }));
    }

    createWarrantedUser = () => {
        const { clientId } = this.props;
        if (!isAdminMode()) return;
        const endpoint = ADMIN_CLIENT_WARRANT_USERS.replace("<client_id>", clientId);

        clearLocalAlerts();
        this.setState({ createLoading: true });
        return axios.post(endpoint, {})
            .then((resp) => {
                const warrantedUser = resp.data;
                this.setState({
                    warrantedUser
                })
            })
            .catch((err) => {
                const errorMessage = getServerErrorMessage(err);
                addNewLocalErrorAlert(
                    `Volitatud isiku loomine ebaõnnestus: ${errorMessage}`,
                    this.getAlertGroupOrDefault(),
                    true
                );
            })
            .finally(() => this.setState({ createLoading: false }));
    }

    deleteWarrantedUser = () => {
        const { clientId } = this.props;
        const { warrantedUser } = this.state;
        const warrantedUserId = warrantedUser?.id;

        if (!isAdminMode() || !warrantedUserId) return;

        const endpoint = ADMIN_CLIENT_WARRANT_USER.replace("<client_id>", clientId).replace("<warrant_id>", warrantedUserId);

        clearLocalAlerts();
        this.setState({ deleteLoading: true, invalidFields: {} });
        return axios.delete(endpoint)
            .then(() => {
                this.setState(() => {
                    return {
                        warrantedUser: null,
                    }
                })
            })
            .catch((err) => {
                const errorMessage = getServerErrorMessage(err);
                const fieldErrors = this.getFieldErrors(err);

                if (!fieldErrors) {
                    addNewLocalErrorAlert(
                        `Volitatud isiku kustutamine ebaõnnestus: ${errorMessage}`,
                        this.getAlertGroupOrDefault(),
                        true
                    );
                }

                this.setState(() => {
                    return {
                        invalidFields: fieldErrors || {}
                    }
                })
            })
            .finally(() => this.setState({ deleteLoading: false }));
    }

    updateWarrantedUser = (name, value) => {
        const { clientId } = this.props;
        const { warrantedUser } = this.state;
        const warrantedUserId = warrantedUser?.id;

        if (!isAdminMode() || !warrantedUserId) return;

        const previousUserState = { ...warrantedUser };
        const newUserState = { ...warrantedUser, [name]: value };

        const endpoint = ADMIN_CLIENT_WARRANT_USER.replace("<client_id>", clientId).replace("<warrant_id>", warrantedUserId);

        clearLocalAlerts();
        this.setState({ updateLoading: true, warrantedUser: newUserState, invalidFields: {} });
        return axios.put(endpoint, newUserState)
            .then((resp) => {
                this.setState(() => {
                    return {
                        warrantedUser: resp.data,
                    }
                })
            })
            .catch((err) => {
                const errorMessage = getServerErrorMessage(err);
                const fieldErrors = this.getFieldErrors(err);

                if (!fieldErrors) {
                    addNewLocalErrorAlert(
                        `Volitatud isiku uuendamine ebaõnnestus: ${errorMessage}`,
                        this.getAlertGroupOrDefault(),
                        true
                    );
                }

                this.setState(() => {
                    return {
                        warrantedUser: previousUserState,
                        invalidFields: fieldErrors || {}
                    }
                })
            })
            .finally(() => this.setState({ updateLoading: false }));
    }

    uploadWarrantFile = file => {
        const { warrantedUser } = this.state;
        const warrantedUserId = warrantedUser?.id;

        if (!isAdminMode() || !warrantedUserId) return;
        const endpoint = ADMIN_CLIENT_WARRANT_USER_FILE_UPLOAD.replace("<warrant_id>", warrantedUserId);
        const formdata = new FormData();
        formdata.append("file", file);

        clearLocalAlerts();
        this.setState({ uploadFileLoading: true, invalidFields: {} });
        return axios.post(endpoint, formdata)
            .then((resp) => {
                this.setState(() => {
                    return {
                        warrantedUser: resp.data,
                    }
                })
            })
            .catch((err) => {
                const errorMessage = getServerErrorMessage(err);
                let fieldErrors = this.getFieldErrors(err);

                if (!fieldErrors) {
                    addNewLocalErrorAlert(
                        `Volituse dokumendi üleslaadimine ebaõnnestus: ${errorMessage}`,
                        this.getAlertGroupOrDefault(),
                        true
                    );
                }

                this.setState(() => {
                    return {
                        invalidFields: fieldErrors || {}
                    }
                })
            })
            .finally(() => this.setState({ uploadFileLoading: false }));
    }

    getFieldErrors = error => {
        const data = error?.response?.data;
        // If data contains code then it is a BE error not specific to a field.
        if (data && !data.code) {
            return data;
        }
        return null;
    }

    onFileUpload = (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }

        this.uploadWarrantFile(file);
    }

    getAlertGroupOrDefault = () => {
        return this.props.alertGroup || WarrantedUserForm.ALERT_GROUP;
    };


    hasAllProperties = (object) => {
        return Object.values(object).every(
            (x) => x !== "" && x !== undefined && x !== null
        );
    };


    handleInputChange = (name) => (value) => {
        this.updateWarrantedUser(name, value);
    };

    isFieldsDisabled = () => {
        return !isAdminMode() || this.readOnly;
    };

    isInvalid = (field) => {
        return field in this.state.invalidFields;
    };

    getInvalidText = field => {
        return this.state.invalidFields[field];
    }

    create = () => {

    }

    render() {
        const { t } = this.props;
        const { showComponent, fetchLoading, warrantedUser } = this.state;

        if (showComponent === false) {
            return null;
        }

        let mainContent = undefined;
        if (fetchLoading) {
            mainContent = (
                <CenteredRowCol>
                    <WrapperLoader />
                </CenteredRowCol>
            );
        } else if (!isAdminMode()) {
            mainContent = this.renderClientMode();
        } else if (!warrantedUser?.id) {
            mainContent = this.renderCreateWarrantedUserButton();
        } else {
            mainContent = this.renderWarrantedUserForm();
        }

        return (
            <>
                <div className="heading my-4">
                    <span>{t("profile.warrantedUser.title", "Volitatud isik")}</span>
                </div>
                <Alerts hideGlobal={true} filterGroups={[WarrantedUserForm.ALERT_GROUP]} />
                {mainContent}
            </>
        )
    }

    renderClientMode() {
        const { t } = this.props;
        const { warrantedUser } = this.state;

        if (!warrantedUser?.id) {
            return (
                <CenteredRowCol>
                    <p className="my-3 regular-14">
                        {t("profile.warrantedUser.noWarrantedUserText", "Volitatud isik puudub")}
                    </p>
                </CenteredRowCol>
            )
        }

        return this.renderWarrantedUserForm();
    }

    renderCreateWarrantedUserButton() {
        const { t } = this.props;
        const { createLoading } = this.state;

        return (
            <CenteredRowCol>
                <Button color="success" onClick={this.createWarrantedUser} disabled={!isAdminMode()} >
                    {createLoading ? <SmallLoader /> : t("profile.warrantedUser.createButtonText", "Lisa volitatud isik")}

                </Button>
            </CenteredRowCol>
        )
    }

    renderWarrantedUserForm() {
        const { t } = this.props;
        const { invalidFields, deleteLoading, uploadFileLoading } = this.state;
        //const isDisabled = this.isFieldsDisabled();
        const isDisabled = this.isFieldsDisabled();
        const isRequired = false;
        const warrantedUser = this.state.warrantedUser

        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="company_name"
                            value={warrantedUser?.company_name}
                            onBlur={this.handleInputChange("company_name")}
                            placeholder={t("profile.companyName", "Ettevõtte nimi")}
                            labelText={t("profile.companyName", "Ettevõtte nimi")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={invalidFields.Textcompany_name}
                            getInvalidText={invalidFields.company_name}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="regCode"
                            value={warrantedUser?.reg_code}
                            onBlur={this.handleInputChange("reg_code")}
                            placeholder={t("profile.regCode", "Registrikood")}
                            labelText={t("profile.regCode", "Registrikood")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={this.isInvalid("reg_code")}
                            invalidText={this.getInvalidText("reg_code")}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="first_name"
                            value={warrantedUser?.first_name}
                            onBlur={this.handleInputChange("first_name")}
                            placeholder={t("profile.firstName", "Eesnimi")}
                            labelText={t("profile.firstName", "Eesnimi")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={this.isInvalid("first_name")}
                            invalidText={this.getInvalidText("first_name")}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="last_name"
                            value={warrantedUser?.last_name}
                            onBlur={this.handleInputChange("last_name")}
                            placeholder={t("profile.lastName", "Perekonnanimi")}
                            labelText={t("profile.lastName", "Perekonnanimi")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={this.isInvalid("last_name")}
                            invalidText={this.getInvalidText("last_name")}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="personal_code"
                            value={warrantedUser?.personal_code}
                            onBlur={this.handleInputChange("personal_code")}
                            placeholder={t("profile.personalCode", "Isikukood")}
                            labelText={t("profile.personalCode", "Isikukood")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={this.isInvalid("personal_code")}
                            invalidText={this.getInvalidText("personal_code")}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="email"
                            name="email"
                            value={warrantedUser?.email}
                            onBlur={this.handleInputChange("email")}
                            placeholder={t("profile.email", "E-post")}
                            labelText={t("profile.email", "E-post")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={this.isInvalid("email")}
                            invalidText={this.getInvalidText("email")}
                        />
                    </Col>
                    <Col md={6}>
                        <Input
                            type="text"
                            name="phone"
                            value={warrantedUser?.phone}
                            onBlur={this.handleInputChange("phone")}
                            placeholder={t("profile.phone", "Telefoninumber")}
                            labelText={t("profile.phone", "Telefoninumber")}
                            className="input-md"
                            disabled={isDisabled}
                            required={isRequired}
                            invalid={this.isInvalid("phone")}
                            invalidText={this.getInvalidText("phone")}
                        />
                    </Col>
                    <Col md="6">
                        <FormGroup className="custom-input-field disabled">
                            <Label
                                className={`custom-label ${isAdminMode() ? "" : "is-disabled"}`}
                            >
                                {t("profile.warrantedUser.warrantFileLabel", "Volituse fail")}
                            </Label>
                            <Row className="align-items-center">
                                <Col>
                                    {warrantedUser.document ?
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="form-control contract-link"
                                            href={warrantedUser.document}
                                        >
                                            {t("profile.warrantedUser.documentName", "Dokument")}
                                        </a>
                                        :
                                        <Input
                                            disabled
                                            type="text"
                                            placeholder={t("profile.warrantedUser.warrantFileLabel")}
                                            value={""}
                                            className="input-md"
                                        />
                                    }
                                </Col>
                                {
                                    isAdminMode() &&
                                    <Col xs="auto" className="pl-0">
                                        <Button
                                            color="success"
                                            outline={true}
                                            block={true}
                                            disabled={uploadFileLoading}
                                            onClick={() => document.getElementById('warrant-file-upload-input-field').click()}
                                        >
                                            {uploadFileLoading ? <SmallLoader /> : warrantedUser.document ? t("profile.warrantedUser.changeFileButtonText", "Muuda faili") : t("profile.warrantedUser.addFileButtonText", "Lisa fail")}
                                        </Button>
                                        <input
                                            id="warrant-file-upload-input-field"
                                            type="file"
                                            onChange={this.onFileUpload}
                                            className="d-none"
                                            accept=".asice, .bdoc, .pdf, .ddoc, .edoc"
                                        />
                                    </Col>
                                }
                            </Row>
                        </FormGroup>
                    </Col>
                    {
                        isAdminMode() &&
                        <Col md={4} lg={3}>
                            <Button color="danger" block={true} onClick={this.deleteWarrantedUser} disabled={!isAdminMode()} style={{ marginTop: "28px" }}>
                                {deleteLoading ? <SmallLoader /> : t("profile.warrantedUser.deleteButtonText", "Kustuta volitatud isik")}
                            </Button>
                        </Col>
                    }
                </Row>
            </Form >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
    };
};

export default withRouter(
    connect(mapStateToProps, {})(
        withTranslation("common")(WarrantedUserForm)
    )
);
