import React from "react";

import Checkbox from "components/Form/Checkbox";

import "./SelectionHeader.scss";

export default function CustomSelectionHeader({ checked, indeterminate }) {

    if (indeterminate) {
        return <Checkbox value={indeterminate} onClick={() => { return null; }} className="custom-checkbox-indeterminate px-0" />;
    }

    return <Checkbox value={checked} onClick={() => { return checked ? true : false; }} className="px-0" />;
}
