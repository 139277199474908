import * as actionTypes from "redux/actions/actionTypes";

export const adminSalesReportsFetchStart = () => {
    return {
        type: actionTypes.ADMIN_SALES_REPORTS_FETCH_START,
    };
};

export const adminSalesReportsFetchSuccess = (
    unfilteredSalesReportsList,
    salesReportsList,
    filters
) => {
    return {
        type: actionTypes.ADMIN_SALES_REPORTS_FETCH_SUCCESS,
        unfilteredSalesReportsList,
        salesReportsList,
        filters,
    };
};

export const adminSalesReportsFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_SALES_REPORTS_FETCH_FAIL,
        error,
    };
};

export const adminSalesReportsFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_SALES_REPORTS_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const adminSalesReportsFilterClear = () => {
    return {
        type: actionTypes.ADMIN_SALES_REPORTS_FILTER_CLEAR,
    };
};
