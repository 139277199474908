import axios from "../axios";
import store from "redux/store";
import moment from "moment";

import {getServerErrorMessage} from "util/Errors";
import {b64toBlob} from "util/Files";
import FileSaver from "file-saver";

import {
    salesReportPeriodServerFormat,
} from "util/DateTime";
import {
    GET_MASS_REPORT,
    EXPORT_MASS_REPORT
} from "../config/Api";
import {
    massReportSearchStart,
    massReportSearchSuccess,
    massReportSearchFail,
    massReportExportStart,
    massReportExportSuccess,
    massReportExportFail,
} from "../redux/actions/reports/massReport";

export function fetchMassReport(filters) {
    store.dispatch(massReportSearchStart());   
    return axios
        .get(GET_MASS_REPORT, {
            params: createMassReportQueryParameters(filters)            
        })
        .then(resp => {
            store.dispatch(massReportSearchSuccess(resp.data, filters.fromDate, filters.toDate));
            return {isSuccess: true};
        })
        .catch(err => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(massReportSearchFail(errorMessage));

            return {isSuccess: false, errorMessage};
        });
}

export function fetchMassReportExportFile(filters) {
    store.dispatch(massReportExportStart());
    return axios
        .get(EXPORT_MASS_REPORT, {
            params: createMassReportQueryParameters(filters)            
        })
        .then(resp => {
            const blob = b64toBlob(resp.data.file_base64);
            //const file_name = `Massiaruanne (${moment().format("YYYY-MM-DD")}).xlsx`;
            const file_name = resp.data.file_name;
            FileSaver.saveAs(blob, file_name);
            store.dispatch(massReportExportSuccess());

            return {isSuccess: true};
        })
        .catch(err => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(massReportExportFail(errorMessage));

            return {isSuccess: false, errorMessage};
        });
}

function createMassReportQueryParameters(filters) {
    return {
        fromDate: filters.fromDate ? moment(filters.fromDate).format(salesReportPeriodServerFormat)  : "",
        toDate: filters.toDate ? moment(filters.toDate).format(salesReportPeriodServerFormat)  : "",
    }
}
