import axios from "../axios";
import store from "redux/store";

import { NOTIFICATIONS } from "../config/Api";
import {
    notificationsFetchStart,
    notificationsFetchSuccess,
    notificationsFetchFail,
} from "../redux/actions/notifications";
import { getServerErrorMessage } from "util/Errors";

export function fetchNotifications() {
    store.dispatch(notificationsFetchStart());
    return axios
        .get(NOTIFICATIONS)
        .then((resp) => {
            store.dispatch(notificationsFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(notificationsFetchFail(errorMessage));
            throw Error(errorMessage);
        });
}

export function markNotificationSeen(id) {
    return axios
        .put(NOTIFICATIONS + "notification/" + id + "/")
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            throw Error(errorMessage);
        });
}

export function removeNotification(id) {
    return axios
        .delete(NOTIFICATIONS + "notification/" + id + "/")
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            throw Error(errorMessage);
        });
}
