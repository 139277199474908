import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody, Button, Form } from "reactstrap";

import axios from "../../axios";
import { RESET_PASSWORD } from "config/Api";
import urls from "config/urls";

import BrandLogoText from "components/Misc/BrandLogoText";
import CustomInput from "components/Form/Input";
import Alerts from "components/Alerts/Alerts";
import { createAlertObject, alertContext, alertType } from "util/Alerts";

import "./ForgotPassword.scss";
import { getServerErrorMessage } from "util/Errors";

const RESET_PASSWORD_ALERT_GROUP = "resetPasswordGroup";

class ForgotPasswordView extends React.Component {
    state = {
        email: "",
        passwordResetSuccess: false,
        alerts: [],
    };

    render() {
        return (
            <Container className="align-middle">
                <Row className="text-center justify-content-center">
                    <Col>
                        <Card className="forgot-password-card">
                            <CardBody>
                                <div>
                                    <BrandLogoText />
                                    <p className="forgot-password-heading">
                                        Telli uus parool
                                    </p>
                                    <p className="forgot-password-select-type d-none">
                                        Sisesta e-mail
                                    </p>
                                </div>
                                {this.renderContent()}
                                <Row className="text-center">
                                    <Col>
                                        <Link to={urls.LOGIN_WITH_EMAIL}>Logi sisse</Link>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderContent() {
        if (this.state.passwordResetSuccess) {
            return this.renderAlerts();
        }

        return this.renderForm();
    }

    renderAlerts() {
        return (
            <Alerts
                manualAlerts={this.state.alerts}
                hideGlobal={true}
                filterGroups={[RESET_PASSWORD_ALERT_GROUP]}
                className={`text-left ${this.state.alerts.length ? "mb-2" : "mb-0"
                    }`}
            />
        );
    }

    renderForm() {
        return (
            <Form
                onSubmit={this.onSubmit}
                id="forgot-password-form"
                className="text-left"
            >
                <Row className="mb-4">
                    <Col>
                        <CustomInput
                            value={this.state.email}
                            onChange={(value) =>
                                this.setState({ email: value })
                            }
                            type="email"
                            labelText="Email"
                            placeholder="Email"
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                {this.renderAlerts()}
                <Row className="mb-4">
                    <Col>
                        <Button
                            block
                            color="success"
                            form="forgot-password-form"
                        >
                            Saada link e-mailile
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    resetAlerts = () => {
        this.setState({ alerts: [] });
    };

    addAlert = (alert) => {
        this.setState((prevState) => {
            return {
                alerts: [...prevState.alerts, alert],
            };
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.resetAlerts();

        this.resetPassword(this.state.email);
    };

    resetPassword = (email) => {
        const data = { email };

        axios
            .post(RESET_PASSWORD, data)
            .then((response) => {
                this.setState({ loading: false, passwordResetSuccess: true });
                const message = (
                    <span>
                        E-mail lingiga parooli muutmiseks on saadetud. Palun
                        kontrollige oma postkasti.
                    </span>
                );
                this.addAlert(
                    createAlertObject(
                        alertContext.LOCAL,
                        alertType.SUCCESS,
                        RESET_PASSWORD_ALERT_GROUP,
                        message
                    )
                );
            })
            .catch((error) => {
                const errorMessage = getServerErrorMessage(error);
                if (error.response && error.response.data) {
                    const data = error.response.data;
                    if (data.detail) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                RESET_PASSWORD_ALERT_GROUP,
                                data.detail
                            )
                        );
                    }
                    if (data.email) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                RESET_PASSWORD_ALERT_GROUP,
                                data.new_password1.join(" ")
                            )
                        );
                    }
                    if (!data.detail && !data.email) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                RESET_PASSWORD_ALERT_GROUP,
                                errorMessage
                            )
                        );
                    }
                } else {
                    this.addAlert(
                        createAlertObject(
                            alertContext.LOCAL,
                            alertType.ERROR,
                            RESET_PASSWORD_ALERT_GROUP,
                            errorMessage
                        )
                    );
                }
            });
    };
}

export default withRouter(ForgotPasswordView);
