// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config

import urls from "config/urls";

import LoginView from "./views/public/Login";
import ForgotPasswordView from "./views/public/ForgotPassword";
import ChangePasswordView from "./views/public/ChangePassword";
import PrivacyPolicyView from "./views/public/PrivacyPolicy";
import CookiePolicyView from "./views/public/CookiePolicy";

const publicRoutes = [
    {
        exact: true,
        path: urls.FORGOT_PASSWORD,
        label: "Unustasid parooli",
        component: ForgotPasswordView,
    },
    {
        path: urls.CHANGE_PASSWORD,
        label: "Muuda parooli",
        component: ChangePasswordView,
    },
    {
        path: urls.PRIVACY_POLICY,
        label: "Privaatsuspoliitika",
        component: PrivacyPolicyView,
    },
    {
        path: urls.COOKIE_POLICY,
        label: "Küpsised",
        component: CookiePolicyView,
    },
    {
        path: urls.LOGIN,
        label: "Logi sisse",
        component: LoginView,
    },
    {
        path: urls.LOGIN_WITH_EMAIL,
        label: "Logi sisse",
        component: LoginView,
    },
    {
        path: urls.DOMAIN,
        label: "Logi sisse",
        component: LoginView,
    },
];

export default publicRoutes;
