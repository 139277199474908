import React, { Component } from "react";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";
import Input from "../../../Form/Input";
import WrapperLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";

import { personalProfileUpdate } from "redux/actions/profile";
import { fetchPersonalProfile, updatePersonalProfile } from "util/Profile";
import {
    addNewLocalErrorAlert,
    clearLocalAlerts,
} from "../../../../util/Alerts";
import { withTranslation } from "react-i18next";

class PersonalProfileForm extends Component {
    static propTypes = {
        alertGroup: PropTypes.string,
        contractMode: PropTypes.bool,
        invalidFields: PropTypes.array.isRequired,
    };

    static defaultProps = {
        invalidFields: [],
        contractMode: false,
    };

    componentDidMount() {
        fetchPersonalProfile().then((profile) => {
            this.setState({ profile: profile });
        });
    }

    getAlertGroupOrDefault = () => {
        return this.props.alertGroup || this.props.location.pathname;
    };

    handleChange = (name) => async (value) => {
        clearLocalAlerts();
        const oldValue = this.props.personalProfile[name];

        await this.props.personalProfileUpdate(name, value);

        if (!this.hasAllProperties(this.props.personalProfile)) {
            return;
        }

        updatePersonalProfile(this.props.personalProfile).catch((err) => {
            clearLocalAlerts();

            this.props.personalProfileUpdate(name, oldValue);

            addNewLocalErrorAlert(err.message, this.getAlertGroupOrDefault());
        });
    };

    isInvalid = (field) => {
        return this.props.invalidFields.includes(field);
    };

    render() {
        const { t } = this.props;
        if (this.props.personalProfileLoading) {
            return (
                <CenteredRowCol>
                    <WrapperLoader />
                </CenteredRowCol>
            );
        }

        return (
            <Row>
                <Col md={6}>
                    <Input
                        type="text"
                        name="first_name"
                        placeholder={t("profile.firstName", "Eesnimi")}
                        onBlur={this.handleChange("first_name")}
                        value={this.props.personalProfile.first_name}
                        className="input-md"
                        labelText={t("profile.firstName", "Eesnimi")}
                        required={this.props.contractMode}
                        invalid={this.isInvalid("first_name")}
                    />
                    <Input
                        type="text"
                        name="phone"
                        onBlur={this.handleChange("phone")}
                        placeholder={t("profile.phone", "Telefoninumber")}
                        value={this.props.personalProfile.phone}
                        className="input-md"
                        labelText={t("profile.phone", "Telefoninumber")}
                        required={this.props.contractMode}
                        invalid={this.isInvalid("phone")}
                    />
                    <Input
                        type="text"
                        name="profession"
                        onBlur={this.handleChange("profession")}
                        placeholder={t("profile.profession", "Ametinimetus")}
                        value={this.props.personalProfile.profession}
                        className="input-md"
                        labelText={t("profile.profession", "Ametinimetus")}
                        required={this.props.contractMode}
                        invalid={this.isInvalid("profession")}
                    />
                </Col>
                <Col md={6}>
                    <Input
                        type="text"
                        name="last_name"
                        onBlur={this.handleChange("last_name")}
                        placeholder={t("profile.lastName", "Perekonnanimi")}
                        value={this.props.personalProfile.last_name}
                        className="input-md"
                        labelText={t("profile.lastName", "Perekonnanimi")}
                        required={this.props.contractMode}
                        invalid={this.isInvalid("last_name")}
                    />
                    <Input
                        type="email"
                        name="email"
                        onBlur={this.handleChange("email")}
                        placeholder={t("profile.email", "E-post")}
                        value={this.props.personalProfile.email}
                        className="input-md"
                        labelText={t("profile.email", "E-post")}
                        required={this.props.contractMode}
                        invalid={this.isInvalid("email")}
                    />
                </Col>
            </Row>
        );
    }

    hasAllProperties = (object) => {
        return Object.values(object).every((x) => x !== "");
    };
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        personalProfile: state.profile.personalProfile,
        personalProfileLoading: state.profile.personalProfileLoading,
    };
};

export default withRouter(
    connect(mapStateToProps, { personalProfileUpdate })(
        withTranslation("common")(PersonalProfileForm)
    )
);
