import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "redux/store";

import moment from "moment";
import "moment/locale/et";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import et from "date-fns/locale/et";

import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import "assets/style.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

import axiosInstance from "./axios";
import { requestFailed401 } from "./util/Auth";

import { I18nextProvider } from "react-i18next";
import "./i18n";
import i18next from "i18next";
// import common_et from "./i18n/et.json";
// import common_en from "./i18n/en.json";
// import common_ru from "./i18n/ru.json";

moment.locale("et");
registerLocale("et", et);
setDefaultLocale("et");

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        if (!error.response || error.response.status === 401) {
            localStorage.clear();
            axiosInstance.defaults.headers.common["Authorization"] = null;
            requestFailed401();
            //window.location.href = '/';
            return Promise.reject(error);
        }

        return Promise.reject(error);
    }
);

// i18next.init({
//     interpolation: { escapeValue: false },
//     lng: "et",
//     keySeparator: ".",
//     returnEmptyString: false,
//     resources: {
//         et: {
//             common: common_et,
//         },
//         en: {
//             common: common_en,
//         },
//         ru: {
//             common: common_ru,
//         },
//     },
// });

const app = (
    <Provider store={store}>
        <I18nextProvider i18n={i18next}>
            <App />
        </I18nextProvider>
    </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
