import { Nav, Navbar, NavItem, NavLink, UncontrolledTooltip } from "reactstrap";
import React from "react";
import { withRouter, matchPath } from "react-router";
import { connect } from "react-redux";

import urls from "config/urls";
import { logout } from "../../util/Auth";

import { ReactComponent as MenuCollapsed } from "../../assets/icons/menu-collapsed.svg";
import { ReactComponent as MenuOpen } from "../../assets/icons/menu-open.svg";
import { ReactComponent as LogOut } from "../../assets/icons/logout.svg";

import UserModeSelection from "components/UserModeSelection/UserModeSelection";
import LanguageMode from "components/Navbar/LanguageMode";
import NotificationDropdown from "components/Profile/Notifications/NotificationDropdown/NotificationDropdown";
import { withTranslation } from "react-i18next";
import { isClientMode } from "util/Access";

import "./Navbar.scss"

class TopNavbar extends React.Component {
    state = {
        client: this.props.user
            ? this.props.user.clients.map((client) => {
                return client.name
                    ? client.id === this.props.activeClientId
                    : null;
            })
            : null,
    };

    logOut = () => {
        this.props.logOut();
        this.props.history.push(urls.DOMAIN);
    };

    getRouteComponent = (routes) => {
        const pathname = this.props.location.pathname;
        // eslint-disable-next-line array-callback-return
        for (const route of routes) {
            const routeLabelComponentOrText = route.labelComponent ? <route.labelComponent /> : route.label;
            if (route.routes) {
                // eslint-disable-next-line array-callback-return
                for (const subRoute of route.routes) {
                    if (matchPath(pathname, { path: subRoute.path })) {
                        const subrouteLabelComponentOrText = subRoute.labelComponent ? <subRoute.labelComponent /> : subRoute.label;

                        return (
                            <span>
                                {routeLabelComponentOrText}
                                {(routeLabelComponentOrText && subrouteLabelComponentOrText) ? " · " : ""}
                                {subrouteLabelComponentOrText}
                            </span>
                        );
                    }
                }
            }
            if (matchPath(pathname, { path: route.path, exact: true })) {
                return routeLabelComponentOrText;
            }
        }

        return "";
    };

    render() {
        const { t } = this.props;
        let routeComponent = this.getRouteComponent(this.props.routes);
        let header = (<span>
            {routeComponent}
        </span>);
        if (this.props.headerSuffix) {
            header = (<span>
                {routeComponent}
                {" · "}
                {this.props.headerSuffix}
            </span>);
        }

        return (
            <Navbar light expand="md">
                <div className="order-last order-md-first mr-md-3 pointer">
                    {!this.props.menuOpen ? (
                        <MenuOpen
                            className=""
                            onClick={this.props.onSidebarToggle}
                        />
                    ) : (
                            <MenuCollapsed
                                className=""
                                onClick={this.props.onSidebarToggle}
                            />
                        )}
                </div>

                <div className="page-header mr-3">
                    {header}
                </div>
                <Nav className="navbar__items-right ml-md-auto d-none d-md-flex">
                    {isClientMode() && (
                        <NavItem className="mr-3">
                            <NotificationDropdown />
                        </NavItem>
                    )}
                    <NavItem className="">
                        <UserModeSelection inNavbar maxWidth="169px" className="navbar-element navbar-user" tooltipTargetId="navbar-user-mode-icon" />
                    </NavItem>
                    {isClientMode() && (
                        <NavItem className=" navbar-user">
                            <LanguageMode />
                        </NavItem>
                    )}
                    <NavItem>
                        <NavLink
                            to={urls.DOMAIN}
                            onClick={logout}
                            className="semibold-14 navbar-element navbar-log-out pointer"
                        >
                            <LogOut id="navbar-logout-icon" /><span className="ml-1 d-none d-lg-block">{t("navbar.logout", "Logi välja")}</span>
                            <UncontrolledTooltip placement="bottom" target="navbar-logout-icon">{t("navbar.logout", "Logi välja")}</UncontrolledTooltip>
                        </NavLink>
                    </NavItem>
                </Nav>
            </Navbar>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user ? state.auth.user : "",
        activeClientId: state.auth.user
            ? state.auth.user.active_client_id
            : null,
        clientName: state.auth.user
            ? // eslint-disable-next-line array-callback-return
            state.auth.user.clients.map((client) => {
                if (client.active_client) return client.name;
            })
            : null,
        headerSuffix: state.navigation.headerSuffix,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(TopNavbar))
);
