export const contractStatuses = {
    SIGNED: "SIGNED",
    NOT_SIGNED: "NOT_SIGNED",
    INVALID: "INVALID",
};

export const contractStatusTypes = {
    [contractStatuses.INVALID]: {
        client_type: "INFO",
        admin_type: "INFO",
    },
    [contractStatuses.NOT_SIGNED]: {
        client_type: "ERROR",
        admin_type: "WARNING",
    },
    [contractStatuses.SIGNED]: {
        client_type: "SUCCESS",
        admin_type: "SUCCESS",
    },
};

export function getContractStatusType(statusValue, adminMode = false) {
    const typeField = adminMode ? "admin_type" : "client_type";
    return (
        contractStatuses[statusValue] &&
        contractStatusTypes[statusValue][typeField]
    );
}
