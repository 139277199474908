import { updateObject } from "../utility";
import * as actionTypes from "../actions/actionTypes";

const initialState = {
    notifications: [],
    loading: false,
    error: null,
};

const fetchNotificationsStart = (state) => {
    return updateObject(state, {
        loading: true,
        notifications: [],
    });
};

const fetchNotificationsSuccess = (state, notifications) => {
    return updateObject(state, {
        loading: false,
        notifications: notifications,
    });
};

const fetchNotificationsFail = (state, err) => {
    return updateObject(state, {
        loading: false,
        error: err,
    });
};

export const notifications = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.NOTIFICATIONS_FETCH_START:
            return fetchNotificationsStart(state);
        case actionTypes.NOTIFICATIONS_FETCH_SUCCESS:
            return fetchNotificationsSuccess(state, action.data);
        case actionTypes.NOTIFICATIONS_FETCH_FAIL:
            return fetchNotificationsFail(state, action.data);
        default:
            return state;
    }
};
