import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { DATE_FORMAT } from "util/DateTime";

class DateCell extends React.Component {
    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.instanceOf(Date),
            PropTypes.instanceOf(moment)
        ]),
        format: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.exact({
            className: PropTypes.string,
            text: PropTypes.string,
        }),
        onClick: PropTypes.func,
    };

    static defaultProps = {
        value: null,
        format: DATE_FORMAT,
        valueFormat: null,
        className: "regular-14",
        placeholder: {
            className: "regular-14 empty-value",
            text: "00.00.0000",
        },
    }

    render() {
        const momentDate = this.props.valueFormat ? moment(this.props.value, this.props.valueFormat) : moment(this.props.value);
        if (!momentDate.isValid()) {
            return (
                <span
                    className={this.props.placeholder.className}
                    onClick={this.props.onClick}
                >
                    {this.props.placeholder.text}
                </span>
            )
        }

        const formatedDate = momentDate.format(this.props.format);
        return (
            <span
                className={this.props.className}
                onClick={this.props.onClick}
            >
                {formatedDate}
            </span>
        );
    }
}

export default DateCell;
