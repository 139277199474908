import React from "react";
import { withRouter, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { withTranslation } from "react-i18next";

import DatePicker from "react-datepicker";
import { Card, Row, Col, Button, Label } from "reactstrap";
import CustomInput from "components/Form/Input";
import CustomSelect from "components/Form/Select";
import WrappedLoader from "components/Loader/WrappedLoader";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import LeftText from "components/Misc/LeftText";
import DeleteModal from "components/Form/Modals/DeleteModal";
import PackageTestList from "components/PackageTest/PackageTestList";
import Alert from "components/Alerts/Alert/Alert";
import Alerts from "components/Alerts/Alerts";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";

import "./PackageDetails.scss";

import urls, { getAdminDebtsClientInvoicesUrl } from "config/urls";

import { updateNavigationHeaderSuffix, clearNavigationHeaderSuffix } from "util/Navigation";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
    alertType,
    addNewLocalInfoAlert,
} from "util/Alerts";
import {
    fetchEanTypes,
    fetchCategories,
    fetchPackageTypes,
    fetchPackageStatuses,
    fetchPackageVolume,
    packageStatuses,
    fetchColors,
    isValidColorForPackageType,
    removePackage
} from "util/Packages";
import {
    fetchPackageDetails,
    updatePackageDetails,
    deletePackage,
} from "util/admin/PackageDetails";
import {
    generatePackageApplicationInvoice
} from "util/admin/Applications";
import { currencyFormatter, isNumeric } from "util/General";
import { DATE_FORMAT_DATE_FN } from "util/DateTime";

class AdminPackageDetails extends React.Component {
    static propTypes = {
        eanTypes: PropTypes.array,
        categories: PropTypes.array,
        packageTypes: PropTypes.array,
        packageStatuses: PropTypes.array,
        packageColors: PropTypes.array,
    }

    static FIELD_VALIDATION_ALERT_GROUP = "FIELD_VALIDATION_ALERT_GROUP";

    state = {
        packageDetails: {},
        packageTypeVolumeOptions: [],

        packageDetailsLoading: false,
        packageUpdateLoading: false,
        packageApproveLoading: false,

        deleteModalOpen: false,
        deleteLoading: false,
        newPackageTestLoading: false,

        isConfirmUpdateOpen: false,
        isConfirmApproveOpen: false,
        approvalStartDate: null,

        invalidFields: []
    }


    componentDidMount() {
        const id = this.props.match.params.id;
        this.getPackageDetails(id);

        fetchEanTypes(this.props.eanTypes);
        fetchCategories(this.props.categories);
        fetchPackageTypes(this.props.packageTypes);
        fetchPackageStatuses(this.props.packageStatuses);
        fetchColors(this.props.packageColors);
    }

    componentWillUnmount(prevProps) {
        clearNavigationHeaderSuffix();
    }

    componentDidUpdate(prevProps, prevState) {
        this.updateVolumeOptionsAndValue(prevState);
    }

    render() {
        if (this.state.packageDetailsLoading) {
            return <WrappedLoader />
        }
        const packageDetails = this.state.packageDetails;
        return (
            <Card className="package-details-card">
                <Row className="no-gutters align-items-center mb-4 pb-2">
                    <Col>
                        <span className="bold-19">{packageDetails.client ? packageDetails.client.name : null}</span>
                    </Col>
                    {this.renderDeletePackageModal()}
                </Row>
                {this.renderUnsyncedParametersAlert()}
                {this.renderDebtAlert()}
                {this.renderUnsubmittedSalesReportsAlert()}
                <Row className="no-gutters align-items-center border-bottom mb-2 pb-2">
                    <Col>
                        <span className="bold-16">Üldised andmed</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomInput
                            labelText="EAN kood"
                            placeholder="EAN kood"
                            value={packageDetails.ean}
                            onChange={this.onFieldUpdate("ean")}
                            invalid={this.isInvalid("ean")}
                            disabled={this.isFieldDisabled("ean")}
                        />
                    </Col>
                    <Col>
                        <CustomInput
                            labelText="Ettevõte"
                            placeholder="Ettevõte"
                            value={packageDetails.client ? packageDetails.client.name : null}
                            //onChange={this.onFieldUpdate("client")}
                            disabled
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomInput
                            labelText="Tootenimetus"
                            placeholder="Tootenimetus"
                            value={packageDetails.name}
                            onChange={this.onFieldUpdate("name")}
                            invalid={this.isInvalid("name")}
                            disabled={this.isFieldDisabled("name")}
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            labelText="EAN tüüp"
                            placeholder="EAN tüüp"
                            options={this.props.eanTypes}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.id}
                            value={packageDetails.ean_type}
                            onChange={this.onFieldUpdate("ean_type")}
                            invalid={this.isInvalid("ean_type")}
                            disabled={this.isFieldDisabled("ean_type")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomSelect
                            labelText="Toote kategooria"
                            placeholder="Toote kategooria"
                            options={this.props.categories}
                            getOptionLabel={(option) => option.description || option.value}
                            getOptionValue={(option) => option.id}
                            value={packageDetails.package_category}
                            onChange={this.onFieldUpdate("package_category")}
                            invalid={this.isInvalid("package_category")}
                            disabled={this.isFieldDisabled("package_category")}
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            labelText="Pakenditüüp"
                            placeholder="Pakenditüüp"
                            options={this.props.packageTypes}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.id}
                            value={packageDetails.package_type}
                            onChange={this.onFieldUpdate("package_type")}
                            invalid={this.isInvalid("package_type")}
                            disabled={this.isFieldDisabled("package_type")}
                        />
                    </Col>
                    <Col>
                        <CustomInput
                            labelText="Asukohatähis"
                            placeholder="Asukohatähis"
                            value={packageDetails.location}
                            onChange={this.onFieldUpdate("location")}
                            disabled={this.isFieldDisabled("location")}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CustomSelect
                            labelText="Värvus"
                            placeholder="Värvus"
                            options={this.props.packageColors}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.id}
                            isOptionDisabled={(option) => !isValidColorForPackageType(option.id, packageDetails.package_type ? packageDetails.package_type.id : null)}
                            value={packageDetails.color}
                            onChange={this.onFieldUpdate("color")}
                            invalid={this.isInvalid("color")}
                            disabled={this.isFieldDisabled("color")}
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            labelText="Maht"
                            placeholder="Maht"
                            options={this.state.packageTypeVolumeOptions}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.id}
                            value={packageDetails.volume}
                            onChange={this.onFieldUpdate("volume")}
                            invalid={this.isInvalid("volume")}
                            disabled={this.isFieldDisabled("volume")}
                        />
                    </Col>
                    <Col>
                        <CustomInput
                            labelText="Kaal"
                            placeholder="Kaal"
                            value={packageDetails.weight}
                            onChange={this.onFieldUpdate("weight")}
                            invalid={this.isInvalid("weight")}
                            disabled={this.isFieldDisabled("weight")}
                        />
                    </Col>
                    <Col>
                        <CustomSelect
                            labelText="Staatus"
                            placeholder="Staatus"
                            options={this.props.packageStatuses}
                            getOptionLabel={(option) => option.value}
                            getOptionValue={(option) => option.id}
                            value={this.state.packageDetails.status}
                            onChange={this.onFieldUpdate("status")}
                            disabled
                        />
                    </Col>
                    <Col xs="12">
                        <Alerts hideGlobal={true} filterGroups={[AdminPackageDetails.FIELD_VALIDATION_ALERT_GROUP]} />
                    </Col>
                </Row>
                {this.renderTesting()}
                <Row className="align-items-center mt-3">
                    {this.renderGenerateMissingApplicationInvoiceModal()}
                    {this.renderConfirmUpdateButtonAndModal()}
                    {this.renderConfirmApproveButtonAndModal()}
                    <Col md={{ size: "", order: "first" }} className="mb-2">
                        <Row>
                            <Col xs="auto">
                                <LeftText
                                    text="Tagasi loetelusse"
                                    onClick={this.goBack}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        );
    }

    renderDebtAlert() {
        const packageDetails = this.state.packageDetails;
        if (!packageDetails || !packageDetails.client) {
            return <></>;
        }
        const debt = Number(packageDetails.debt);
        if (debt <= 0) {
            return (
                <></>
            );
        }

        let type = alertType.ERROR_ICON;

        const clientInvoicesUrl = getAdminDebtsClientInvoicesUrl(packageDetails.client.id);
        let content = (
            <div className="align-items-center w-100 d-flex">
                <span>{`Kliendil on võlgnevus summas ${currencyFormatter.format(debt)}.`}</span>
                <NavLink to={clientInvoicesUrl} className="btn-outline-secondary align-items-center color-error-light-bg d-flex ml-auto">
                    Halda kliendi võlgnevusi
                </NavLink>
            </div>
        );

        return (
            <Row className="no-gutters mb-3">
                <Col>
                    <Alert
                        type={type}
                        className={type}
                        content={content}
                    />
                </Col>
            </Row>
        );
    }

    renderUnsubmittedSalesReportsAlert() {
        const packageDetails = this.state.packageDetails;
        if (!packageDetails || !packageDetails.client) {
            return <></>;
        }
        const unsubmittedSalesReports = Number(packageDetails.unsubmitted_sales_reports);
        if (unsubmittedSalesReports <= 0) {
            return (
                <></>
            );
        }

        let type = alertType.ERROR_ICON;

        let content = (
            <div className="align-items-center w-100 d-flex">
                <span>{`Kliendil on ${unsubmittedSalesReports} esitamata müügiaruannet.`}</span>
                <NavLink
                    to={{
                        pathname: urls.EPP_REPORTS_SALES,
                        state: {
                            filterClientOptionObject: { value: packageDetails.client.id, label: packageDetails.client.name },
                        }
                    }}
                    className="btn-outline-secondary align-items-center color-error-light-bg d-flex ml-auto">
                    Halda kliendi müügiaruandeid
                </NavLink>
            </div>
        );

        return (
            <Row className="no-gutters mb-3">
                <Col>
                    <Alert
                        type={type}
                        className={type}
                        content={content}
                    />
                </Col>
            </Row>
        );
    }

    renderUnsyncedParametersAlert() {
        const packageDetails = this.state.packageDetails;
        if (!packageDetails || !packageDetails.client) {
            return <></>;
        }
        const isUnsynced = Number(packageDetails.skip_migration);
        if (!isUnsynced) {
            return (
                <></>
            );
        }

        let content = (
            <div className="align-items-center w-100 d-flex">
                <span>
                    Kliendi pakendi parameetrid on NAV-iga sünkroniseerimata. Kontrolli nende valiidsust.
                    <br></br>
                    Andmed sünkroniseeritakse parameetrite salvestamisel või pakendi registreerimisel.
                </span>
            </div>
        );

        return (
            <Row className="no-gutters mb-3">
                <Col>
                    <Alert
                        type={alertType.ERROR_ICON}
                        className={alertType.ERROR_ICON}
                        content={content}
                    />
                </Col>
            </Row>
        );
    }

    renderTesting() {
        if (this.isDisabled()) {
            return <></>;
        }

        return (
            <>
                <Row className="no-gutters align-items-center border-bottom mt-2 pb-2">
                    <Col>
                        <span className="bold-16">Testimine</span>
                    </Col>
                </Row>
                <Row className="no-gutters mb-2">
                    <Col>
                        <PackageTestList
                            key={`packageTestList-${this.state.packageDetails.status && this.state.packageDetails.status.id}`}
                            packageId={this.state.packageDetails.id}
                            onPackageTestSubmitSuccess={this.onPackageTestSubmitSuccess}
                            onPackageTestConfirmSuccess={this.onPackageTestConfirmSuccess}
                            isAdminMode={true}
                            showSendToTestingButton={this.showSendToTestingButton()}
                            onSendToTestingSuccess={this.onSendToTestingSuccess}
                        />
                    </Col>
                </Row>
            </>
        );
    }

    renderDeletePackageModal() {
        if (!this.showDeletePackage()) {
            return <></>;
        }
        const deletePackageAlertGroup = "adminDeletePackageGroup";


        let confirmationText = "Antud tegevus kustutab pakendi ainult veebist. NAV-is tuleb kustutada eraldi.";
        let deleteEndpointFunc = deletePackage;
        if (this.state.packageDetails.status.id === packageStatuses.NOT_APPLIED_STATUS) {
            deleteEndpointFunc = removePackage;
            confirmationText = "Antud pakend on kliendi poolt esitamata ja eksisteerib ainult veebis.";
        }

        const onToggle = () => {
            this.setState(prevState => { return { deleteModalOpen: !prevState.deleteModalOpen } })
            clearLocalAlerts();
        };
        const onDelete = () => {
            if (this.isDisabled() || this.state.deleteLoading) {
                return;
            }
            clearLocalAlerts();

            this.setState({
                deleteLoading: true,
            });

            deleteEndpointFunc(this.state.packageDetails.id)
                .then(() => {
                    this.setState({
                        deleteLoading: false,
                        deleteModalOpen: false,
                    });

                    const route = this.getReturnRoute();
                    clearLocalAlerts();
                    addNewLocalSuccessAlert(`Pakend ${this.state.packageDetails.name} (${this.state.packageDetails.ean}) edukalt kustutatud.`, route);
                    document.body.classList.remove("modal-open");
                    this.goBack();
                })
                .catch(error => {
                    clearLocalAlerts();
                    addNewLocalErrorAlert(error.message, deletePackageAlertGroup);
                    this.setState({
                        deleteLoading: false,
                    });
                });
        }

        return (
            <Col className="col-auto">
                <DeleteModal
                    isOpen={this.state.deleteModalOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onDelete}
                    isDisabled={this.state.deleteLoading}
                    isLoading={this.state.deleteLoading}
                    alertGroups={[deletePackageAlertGroup]}
                    toggleButtonText="Kustuta pakend"
                    confirmButtonText="Kustuta pakend"
                >
                    <p className="regular-14">{confirmationText}</p>
                    <p className="regular-14">Kas olete kindel, et soovite kustutada?</p>
                </DeleteModal>
            </Col>
        );
    }

    renderGenerateMissingApplicationInvoiceModal() {
        let alertGroup = "packageDetailsGenerateMissingApplicationInvoiceModal";
        const onToggle = () => {
            this.setState(prevState => { return { isConfirmGenerateApplicationInvoiceOpen: !prevState.isConfirmGenerateApplicationInvoiceOpen } });
            clearLocalAlerts();
        };
        let onConfirmFunc = () => {
            this.setState({
                generatePackageApplicationInvoiceLoading: true,
            })
            generatePackageApplicationInvoice([this.state.packageDetails.id])
                .then(data => {
                    this.setState(prevState => {
                        return {
                            generatePackageApplicationInvoiceLoading: false,
                            isConfirmGenerateApplicationInvoiceOpen: false,
                            packageDetails: {
                                ...prevState.packageDetails,
                                is_application_invoice: true,
                            },
                        }
                    })
                    clearLocalAlerts();
                    addNewLocalInfoAlert("Pakendi puuduva taotluse arve genereerimine edukalt sooritatud.", this.props.location.pathname);

                }).catch(error => {
                    this.setState({
                        generatePackageApplicationInvoiceLoading: false,
                    })
                    clearLocalAlerts();
                    addNewLocalErrorAlert(error.message, alertGroup);
                });
        };

        const problems = this.onGetPossibleProblems();
        if (this.state.packageDetails.status && this.state.packageDetails.status.id === packageStatuses.APPROVED_STATUS) {
            problems.push("Pakend on registreeritud.");
        }

        let content = <p className="regular-14">Pakendile luuakse taotluse arve kui seda ei ole varem automaatselt genereeritud (Tekkis probleem pakendi taotlemise hetkel).</p>

        return (
            <>
                <Col md="3" className="mb-2">
                    <Button
                        block outline
                        color="secondary"
                        onClick={onToggle}
                        disabled={this.state.packageDetails.is_application_invoice === true}
                    >
                        {this.state.generatePackageApplicationInvoiceLoading ? <LoadingAnimation /> : "Genereeri taotluse arve"}
                    </Button>
                </Col>
                <ConfirmationModal
                    isOpen={this.state.isConfirmGenerateApplicationInvoiceOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Kinnita"
                    title="Kinnita arve loomine"
                    isDisabled={this.state.generatePackageApplicationInvoiceLoading}
                    isLoading={this.state.generatePackageApplicationInvoiceLoading}
                    alertGroups={[alertGroup]}
                >
                    {content}

                </ConfirmationModal>
            </>
        );
    }

    renderConfirmUpdateButtonAndModal() {
        let alertGroup = "packageDetailsConfirmUpdateModal";
        const onToggle = () => {
            if (!this.state.isConfirmUpdateOpen && !this.validatePackage()) {
                return;
            } else {
                this.setState(prevState => { return { isConfirmUpdateOpen: !prevState.isConfirmUpdateOpen } });
                clearLocalAlerts();
            }
        };
        let onConfirmFunc = () => {
            clearLocalAlerts();
            this.onUpdate(false, null, alertGroup)
                .then(isSuccess => {
                    if (isSuccess) {
                        clearLocalAlerts();
                        this.setState({ isConfirmUpdateOpen: false });
                        addNewLocalSuccessAlert("Pakend edukalt salvestatud.", this.props.location.pathname);
                    } else {
                        // this.onUpdate creates an alert on error catch.
                        //addNewLocalErrorAlert("Pakendi salvestamine ebaõnnestus.", alertGroup);
                    }
                });
        };

        const problems = this.onGetPossibleProblems();
        if (this.state.packageDetails.status && this.state.packageDetails.status.id === packageStatuses.APPROVED_STATUS) {
            problems.push("Pakend on registreeritud.");
        }

        let content = <p className="regular-14">Kinnita pakendi parameetrite salvestamine.</p>
        if (problems.length) {
            content = (
                <>
                    <p className="regular-14">
                        Võimalikud probleemid:
                    </p>
                    <ul>
                        {problems.map((problem, i) => <li className="regular-14" key={i}>{problem}</li>)}
                    </ul>
                </>
            );
        }

        return (
            <>
                {this.showUpdateButton() &&
                    <Col md="3" className="mb-2">
                        <Button
                            block outline
                            color="success"
                            onClick={onToggle}
                            disabled={this.isUpdateDisabled()}
                        >
                            {this.state.packageUpdateLoading ? <LoadingAnimation /> : "Salvesta"}
                        </Button>
                    </Col>
                }
                <ConfirmationModal
                    isOpen={this.state.isConfirmUpdateOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Salvesta"
                    title="Kinnita salvestamine"
                    isDisabled={this.state.packageUpdateLoading}
                    isLoading={this.state.packageUpdateLoading}
                    alertGroups={[alertGroup]}
                >
                    {content}

                </ConfirmationModal>
            </>
        );
    }

    renderConfirmApproveButtonAndModal() {
        let alertGroup = "packageDetailsConfirmApproveModal";
        const onToggle = () => {
            if (!this.state.isConfirmApproveOpen && !this.validatePackage()) {
                return;
            } else {
                this.setState(prevState => {
                    return {
                        isConfirmApproveOpen: !prevState.isConfirmApproveOpen,
                        approvalStartDate: null
                    }
                });
                clearLocalAlerts();
            }
        };
        let onConfirmFunc = () => {
            clearLocalAlerts();
            const startDate = this.state.approvalStartDate;

            if (startDate == null) {
                addNewLocalErrorAlert("Müügi alguskuupäev valimata.", alertGroup);
                return;
            }

            this.onUpdate(true, startDate, alertGroup)
                .then(isSuccess => {
                    if (isSuccess) {
                        this.setState({ isConfirmApproveOpen: false });
                        addNewLocalSuccessAlert("Pakend edukalt registreeritud.", this.props.location.pathname);
                    } else {
                        // this.onUpdate creates an alert on error catch.
                        //addNewLocalErrorAlert("Pakendi salvestamine ebaõnnestus.", alertGroup);
                    }
                });
        };

        const problems = this.onGetPossibleProblems();

        let content = <p className="regular-14">Kinnita pakendi registreerimine.</p>
        if (problems.length) {
            content = (
                <>
                    <p className="regular-14">
                        Võimalikud probleemid:
                    </p>
                    <ul>
                        {problems.map((problem, i) => <li className="regular-14" key={i}>{problem}</li>)}
                    </ul>
                </>
            );
        }

        return (
            <>
                {this.showApproveButton() &&
                    <Col md="3" className="mb-2">
                        <Button
                            block
                            color="success"
                            onClick={onToggle}
                            disabled={this.isApproveDisabled()}
                        >
                            {this.state.packagesApprovedLoading ? <LoadingAnimation /> : "Registreeri"}
                        </Button>
                    </Col>
                }
                <ConfirmationModal
                    isOpen={this.state.isConfirmApproveOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Registreeri"
                    title="Kinnita registreerimine"
                    isDisabled={this.state.packageApproveLoading}
                    isLoading={this.state.packageApproveLoading}
                    alertGroups={[alertGroup]}
                >
                    <Row>
                        <Col>
                            {content}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="">
                            <Label className="bold-12 color-gray">Müügi alguskuupäev</Label>
                            <DatePicker
                                placeholderText="Müügi alguskuupäev"
                                className="mb-2"
                                selected={this.state.approvalStartDate}
                                onChange={value => this.setState({ approvalStartDate: value })}
                                dateFormat={DATE_FORMAT_DATE_FN}
                            />
                        </Col>
                    </Row>

                </ConfirmationModal>
            </>
        );
    }

    isInvalid = (field) => {
        return this.state.invalidFields.includes(field);
    };

    validatePackage = () => {
        clearLocalAlerts();
        const obj = this.state.packageDetails;
        const alertGroup = AdminPackageDetails.FIELD_VALIDATION_ALERT_GROUP;
        const inputFields = ["ean", "name", "weight"];
        const selectFields = [
            "ean_type",
            "package_category",
            "volume",
            "color",
            "package_type",
        ];
        const invalidFields = [];

        for (const field of inputFields) {
            if (!obj[field]) {
                invalidFields.push(field);
            }
        }
        for (const field of selectFields) {
            if (!obj[field] || !obj[field].id) {
                invalidFields.push(field);
            }
        }

        // If package weight has value, make sure it is numeric.
        if (!invalidFields.includes("weight")) {
            if (!isNumeric(obj["weight"])) {
                invalidFields.push("weight");
                addNewLocalErrorAlert(
                    this.props.t("packages.addPackageModal.alert.weightNumericValidationText", "Pakendi kaal peab olema number."),
                    alertGroup
                );
            }
        }

        this.setState({
            invalidFields
        });

        if (invalidFields.length === 0) {
            return true;
        } else {
            addNewLocalErrorAlert(
                this.props.t("packages.addPackageModal.alert.genericValidationText", "Kõik punasega märgitud väljad peavad olema täidetud."),
                alertGroup
            );
            return false;
        }
    };

    onGetPossibleProblems = () => {
        const packageDetails = this.state.packageDetails;
        const problems = [];

        const isUnsynced = Number(packageDetails.skip_migration);
        if (isUnsynced) {
            problems.push(`Kliendi pakendi parameetrid on NAV-iga sünkroniseerimata.`);
        }

        const debt = Number(packageDetails.debt);
        if (debt > 0) {
            problems.push(`Kliendil on võlgnevus summas ${currencyFormatter.format(debt)}.`);
        }

        const unsubmittedSalesReports = Number(packageDetails.unsubmitted_sales_reports);
        if (unsubmittedSalesReports > 0) {
            problems.push(`Kliendil on esitamata müügiaruandeid (${unsubmittedSalesReports}).`);
        }

        if (!packageDetails.location) {
            problems.push("Asukohatähis on tühi.");
        }

        return problems;
    }



    onPackageTestSubmitSuccess = packageTest => {
        const newStatus = {
            id: packageTest.package_status.value,
            value: packageTest.package_status.label,
        }
        this.setState(prevState => {
            return {
                packageDetails: {
                    ...prevState.packageDetails,
                    status: newStatus,
                }
            }
        })
        addNewLocalSuccessAlert(`Pakendi test edukalt esitatud ${packageTest.status.label} staatusega.`, this.props.location.pathname);
    }

    goBack = () => {
        this.props.history.push(this.getReturnRoute());
    }

    showDeletePackage = () => {
        if (this.isDisabled()) {
            return false;
        }

        const deleteableStatuses = [packageStatuses.NOT_APPLIED_STATUS, packageStatuses.APPLIED_STATUS, packageStatuses.IN_TESTING_STATUS, packageStatuses.UNCONFIRMED, packageStatuses.WAITING_STATUS, packageStatuses.APPROVED_STATUS];
        return this.state.packageDetails.status && deleteableStatuses.includes(this.state.packageDetails.status.id);
    }

    showUpdateButton = () => {
        return !this.isDisabled() && this.getEditableFields().length > 0;
    }

    showApproveButton = () => {
        const approvableStatuses = [packageStatuses.APPLIED_STATUS, packageStatuses.IN_TESTING_STATUS, packageStatuses.UNCONFIRMED, packageStatuses.WAITING_STATUS];
        return !this.isDisabled() && this.isPackageStatusIdOneOf(approvableStatuses);
    }

    showSendToTestingButton = () => {
        return this.state.packageDetails.is_testable;
    }

    getReturnRoute = () => {
        const locationState = this.props.location.state;
        if (locationState && locationState.previousViewRoute) {
            return locationState.previousViewRoute;
        }
        return urls.EPP_REGISTRY_APPLICATIONS;
    }

    onFieldUpdate = field => value => {
        this.setState((prevState) => {
            return {
                packageDetails: {
                    ...prevState.packageDetails,
                    [field]: value,
                }
            }
        }, () => {
            if (field === "package_type") {
                this.updateColorValueIfNecessary();
            }
        });
    }

    isDisabled = () => {
        return !this.state.packageDetails || !this.state.packageDetails.id;
    }

    isAnythingLoading = () => {
        return this.state.packageApproveLoading || this.state.packageUpdateLoading || this.state.packageDetailsLoading;
    }

    getEditableFields() {
        return get(this.state, "packageDetails.editable_fields", []);
    }

    isUpdateDisabled = () => {
        const editableFields = this.getEditableFields();
        return this.isAnythingLoading() || this.isDisabled() || editableFields.length === 0;
    }

    isFieldDisabled = (field) => {
        if (this.isUpdateDisabled()) return true;
        return !this.getEditableFields().includes(field);
    }

    isApproveDisabled = () => {
        return this.isAnythingLoading() || this.isDisabled();
    }

    isPackageStatusIdOneOf = options => {
        const id = this.state.packageDetails && this.state.packageDetails.status ? this.state.packageDetails.status.id : null;
        return options.includes(id);
    }

    updateVolumeOptionsAndValue = prevState => {
        const prevPackageType = prevState.packageDetails ? prevState.packageDetails.package_type : null;
        const currentPackageType = this.state.packageDetails ? this.state.packageDetails.package_type : null;

        if (!currentPackageType || prevPackageType === currentPackageType) {
            return;
        }

        fetchPackageVolume(currentPackageType.id)
            .then(data => {
                this.setState({
                    packageTypeVolumeOptions: data,
                })

                // Remove current volume if it is not in new options.
                // volume options id and value are the same now and now it is PackageTypeVolume.volume instead of PackageTypeVolume.id 
                const newVolumeOptionsIds = data.map(option => option.value);
                const currentVolumeOption = this.state.packageDetails.volume;
                if (currentVolumeOption && !newVolumeOptionsIds.includes(currentVolumeOption.value)) {
                    this.setState(prevState => {
                        return {
                            packageDetails: {
                                ...prevState.packageDetails,
                                volume: null,
                            }
                        }
                    })
                }
            })
            .catch(error => {
                addNewLocalErrorAlert(error.message, this.props.location.pathname);
            });
    }

    updateColorValueIfNecessary = () => {
        const packageDetails = this.state.packageDetails;

        const currentPackageType = packageDetails ? packageDetails.package_type : null;

        if (!currentPackageType) {
            return;
        }

        const currentPackageTypeId = currentPackageType ? currentPackageType.id : null;
        const currentColorId = packageDetails && packageDetails.color ? packageDetails.color.id : null;

        if (!isValidColorForPackageType(currentColorId, currentPackageTypeId)) {
            this.setState(previousState => {
                return {
                    packageDetails: {
                        ...previousState.packageDetails,
                        color: null,
                    }
                }
            })
        }
    }

    onUpdate = (activateInNav, startDate = null, alertGroup = null) => {
        if (this.isUpdateDisabled()) {
            return;
        }
        if (activateInNav) {
            this.setState({
                packageApproveLoading: true,
            });
        } else {
            this.setState({
                packageUpdateLoading: true,
            });
        }

        if (alertGroup == null) {
            alertGroup = this.props.location.pathname;
        }

        clearLocalAlerts();
        return updatePackageDetails(this.state.packageDetails, activateInNav, startDate)
            .then(data => {
                this.setState({
                    packageDetails: data,
                });
                if (activateInNav) {
                    this.setState({
                        packageApproveLoading: false,
                    });
                    //addNewLocalSuccessAlert("Pakend edukalt registreeritud.", alertGroup);
                } else {
                    this.setState({
                        packageUpdateLoading: false,
                    });
                    //addNewLocalSuccessAlert("Pakend edukalt salvestatud.", alertGroup);
                }
                return true;
            })
            .catch(error => {
                clearLocalAlerts();
                addNewLocalErrorAlert(error.message, alertGroup);
                if (activateInNav) {
                    this.setState({
                        packageApproveLoading: false,
                    });
                } else {
                    this.setState({
                        packageUpdateLoading: false,
                    });
                }
                return false;
            });
    }

    getPackageDetails(packageId) {
        this.setState({
            packageDetailsLoading: true,
        });

        fetchPackageDetails(packageId)
            .then(data => {
                this.setState({
                    packageDetails: data,
                    packageDetailsLoading: false,
                });
                updateNavigationHeaderSuffix(data.ean);
            })
            .catch(error => {
                clearLocalAlerts();
                addNewLocalErrorAlert("Pakendi detailide pärimine ebaõnnestus: " + error.message, this.props.location.pathname);
                this.setState({
                    packageDetailsLoading: false,
                });
            });
    }

    onSendToTestingSuccess = (data) => {
        if (data.errors && data.errors.length) {
            addNewLocalErrorAlert(data.errors[0].reason, this.props.location.pathname);
        } else {
            addNewLocalSuccessAlert("Pakend saadetud testimisse.", this.props.location.pathname);
            this.setState(prevState => {
                return {
                    packageDetails: {
                        ...prevState.packageDetails,
                        status: this.getStatusOptionById(packageStatuses.IN_TESTING_STATUS) || prevState.packageDetails.status,
                    }
                }
            });
        }
    }

    onPackageTestConfirmSuccess = (packageTest) => {
        //addNewLocalSuccessAlert("Pakendi testitulemus kinnitatud.", this.props.location.pathname);
        this.setState(prevState => {
            return {
                packageDetails: {
                    ...prevState.packageDetails,
                    status: this.getStatusOptionById(packageStatuses.WAITING_STATUS) || prevState.packageDetails.status,
                }
            }
        });
    }

    getStatusOptionById = statusId => {
        if (this.props.packageStatuses) {
            for (const option of this.props.packageStatuses) {
                if (option.id === statusId) {
                    return option;
                }
            }
        }

        return null;
    }
}

const mapStateToProps = state => {
    return {
        eanTypes: state.packages.eanTypes,
        categories: state.packages.categories,
        packageTypes: state.packages.packageTypes,
        packageStatuses: state.packages.packageStatuses,
        packageColors: state.packages.packageColors,
    };
}

const mapActionsToProps = {

};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(withTranslation()(AdminPackageDetails)));
