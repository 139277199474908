import React from "react";
import {Row, Col} from "reactstrap";

export default function CenteredRowCol(props) {
    return (
        <Row className="text-center justify-content-center">
            <Col className="col-auto">
                {props.children}
            </Col>
        </Row>
    );
}