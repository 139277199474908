import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";
import { companyProfileUpdate } from "redux/actions/profile";
import {
    isConnectedToClientWithPendingOrActiveContract,
    isPartnerMode,
    isAdminMode,
} from "util/Access";
// Custom inputs
import Input from "../../../../Form/Input";
import Select from "../../../../Form/Select";

import CenteredRowCol from "components/Misc/CenteredRowCol";
import WrapperLoader from "components/Loader/WrappedLoader";
import { fetchClient } from "util/admin/General";

class CompanyProfileFormAdmin extends Component {
    static propTypes = {
        alertGroup: PropTypes.string,
        contractMode: PropTypes.bool,
        invalidFields: PropTypes.array.isRequired,
        user: PropTypes.object,
        isDisabled: PropTypes.bool,
    };

    static defaultProps = {
        contractMode: false,
        invalidFields: [],
    };

    state = {
        companies: [],
        disableBasisOption: true,
        clientId: this.props.match.params.id,
        client: {
            name: "",
            address: {
                full_address: "",
                city: "",
                zip_code: "",
            },
        },
    };

    componentDidMount() {
        fetchClient(this.state.clientId).then((client) => {
            this.setState({
                client: client,
            });
        });
    }

    // handleSelectInputChange = (name) => {
    //     if (name.length > 2) {
    //         fetchCompanies(name).then((companies) => {
    //             this.setState({ companies: companies });
    //         });
    //     }
    // };

    getAlertGroupOrDefault = () => {
        return this.props.alertGroup || this.props.location.pathname;
    };

    hasAllProperties = (object) => {
        return Object.values(object).every(
            (x) => x !== "" && x !== undefined && x !== null
        );
    };

    // handleInputChange = (name) => async (value) => {
    //     await this.props.companyProfileUpdate(name, value);

    //     if (
    //         !this.props.contractMode &&
    //         this.hasAllProperties(this.props.companyProfile)
    //     ) {
    //         clearLocalAlerts();
    //         updateCompanyProfile(this.props.companyProfile).catch((err) => {
    //             addNewLocalErrorAlert(
    //                 getServerErrorMessage(err),
    //                 this.getAlertGroupOrDefault()
    //             );
    //         });
    //     }
    // };

    isFieldsDisabled = () => {
        if (this.props.contractMode) {
            return false;
        }
        return (
            !isConnectedToClientWithPendingOrActiveContract() ||
            isPartnerMode() ||
            isAdminMode()
        );
    };

    isInvalid = (field) => {
        return this.props.invalidFields.includes(field);
    };

    renderSelectBasis() {
        const isNotContractMode = !this.props.contractMode;

        return (
            <Select
                disabled={isNotContractMode}
                labelText="Allkirjaõiguslikkuse alus"
                // options={this.props.profile.representationOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                placeholder="Allkirjaõiguslikkuse alus"
                name="basis"
                value={this.state.client.latest_basis}
                // onChange={this.handleBasisChange}
                className="select"
                required={this.props.contractMode}
                invalid={this.isInvalid("basis")}
                isOptionDisabled={(option) =>
                    option.value === 1 && this.state.disableBasisOption === true
                }
            />
        );
    }

    render() {
        if (this.props.companyProfileLoading) {
            return (
                <CenteredRowCol>
                    <WrapperLoader />
                </CenteredRowCol>
            );
        }

        const isDisabled = this.isFieldsDisabled();
        const isNotPartnerMode = !isPartnerMode();
        const isNotContractMode = !this.props.contractMode;
        return (
            <Row>
                <Col md={6}>
                    <Select
                        disabled={isNotContractMode}
                        labelText="Ettevõte nimi"
                        options={this.state.companies}
                        value={this.state.client}
                        placeholder="Ettevõtte nimi"
                        name="name"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        // onChange={this.handleCompanyChanged}
                        // onInputChange={this.handleSelectInputChange}
                        className="select"
                        noOptionsMessage={() =>
                            "Insert at least three characters"
                        }
                        required={this.props.contractMode}
                        invalid={this.isInvalid("name")}
                    />

                    <Input
                        disabled
                        type="text"
                        name="regCode"
                        value={this.state.client.reg_number}
                        placeholder="Registrikood"
                        className="input-md"
                        required={this.props.contractMode}
                        labelText="Registrikood"
                        invalid={this.isInvalid("reg_number")}
                    />
                    {isNotPartnerMode && (
                        <Input
                            disabled
                            type="text"
                            name="kmkr"
                            value={this.state.client.kmkr}
                            placeholder="Kmkr"
                            className="input-md"
                            required={this.props.contractMode}
                            labelText="Kmkr"
                            invalid={this.isInvalid("kmkr")}
                        />
                    )}
                    {this.renderSelectBasis()}
                </Col>
                <Col md={6}>
                    <Input
                        type="email"
                        name="email"
                        value={this.state.client.invoice_email}
                        placeholder="Arve saatmise email"
                        className="input-md"
                        // onBlur={this.handleInputChange("email")}
                        required={this.props.contractMode}
                        labelText="Arve saatmise email"
                        invalid={this.isInvalid("email")}
                        disabled={isDisabled}
                    />
                    {isNotPartnerMode && (
                        <>
                            <Input
                                type="text"
                                name="iban"
                                value={this.state.client.iban}
                                placeholder="IBAN"
                                className="input-md"
                                // onBlur={this.handleInputChange("iban")}
                                required={this.props.contractMode}
                                labelText="Kontonumber"
                                invalid={this.isInvalid("iban")}
                                disabled={isDisabled}
                            />
                            <Input
                                type="text"
                                name="full_address"
                                value={this.state.client.address.full_address}
                                placeholder="Aadress"
                                className="input-md"
                                // onBlur={this.handleInputChange("full_address")}
                                required={this.props.contractMode}
                                labelText="Aadress"
                                invalid={this.isInvalid("full_address")}
                                disabled={isDisabled}
                            />
                            <Input
                                type="text"
                                name="city"
                                value={this.state.client.address.city}
                                placeholder="Haldusüksus"
                                className="input-md"
                                // onBlur={this.handleInputChange("city")}
                                required={this.props.contractMode}
                                labelText="Haldusüksus"
                                invalid={this.isInvalid("city")}
                                disabled={isDisabled}
                            />
                            <Input
                                type="text"
                                name="zip_code"
                                value={this.state.client.address.zip_code}
                                placeholder="Postiindeks"
                                className="input-md"
                                // onBlur={this.handleInputChange("zip_code")}
                                required={this.props.contractMode}
                                labelText="Postiindeks"
                                invalid={this.isInvalid("zip_code")}
                                disabled={isDisabled}
                            />
                        </>
                    )}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        // companyProfile: state.profile.companyProfile,
        // companyProfileLoading: state.profile.companyProfileLoading,
        // clients: state.auth.user ? state.auth.user.clients : [],
        // profile: state.profile,
        // user: state.auth.user,
    };
};

export default withRouter(
    connect(mapStateToProps, { companyProfileUpdate })(CompanyProfileFormAdmin)
);
