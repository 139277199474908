import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

import { Form, Card, CardBody, Row, Col, Button, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";

import "./MassSearch.scss";
import {
    salesReportPeriodSearchFormat,
    getFromAndToDatePeriodAsString,
} from "util/DateTime";

import { massReportSearchFilterUpdate } from "redux/actions/reports/massReport";
import {
    clearNavigationHeaderSuffix,
    updateNavigationHeaderSuffix,
} from "util/Navigation";
import { fetchMassReport, fetchMassReportExportFile } from "util/MassReport";
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

import SmallLoader from "components/Loader/WrappedPulseLoader";
import { withTranslation } from "react-i18next";

class MassReportsSearch extends React.Component {
    static propTypes = {
        filters: PropTypes.object.isRequired,
        massList: PropTypes.array,
        massListFromDate: PropTypes.object,
        massListToDate: PropTypes.object,
        massReportSearchFilterUpdate: PropTypes.func.isRequired,
        massListLoading: PropTypes.bool,
        exportLoading: PropTypes.bool,
    };

    componentDidMount() {
        if (this.props.massList) {
            updateNavigationHeaderSuffix(
                getFromAndToDatePeriodAsString(
                    this.props.massListFromDate,
                    this.props.massListToDate
                )
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (this.props.massList && this.props.massList !== prevProps.massList) {
            updateNavigationHeaderSuffix(
                getFromAndToDatePeriodAsString(
                    this.props.massListFromDate,
                    this.props.massListToDate
                )
            );
        }
    }

    componentWillUnmount() {
        clearNavigationHeaderSuffix();
    }

    render() {
        const { t } = this.props;
        const filters = this.props.filters;
        return (
            <Form>
                <Card className="mass-search-card">
                    <CardBody>
                        <Row className="align-items-end mx-n2">
                            <Col md="4" lg="3">
                                <FormGroup>
                                    <Label className="bold-12 text-muted">
                                        {t("reports.filter.period")}
                                    </Label>
                                    <DatePicker
                                        selected={filters.fromDate}
                                        onChange={this.handleFilterUpdate(
                                            "fromDate"
                                        )}
                                        placeholderText={t("reports.filter.from")}
                                        dateFormat={salesReportPeriodSearchFormat}
                                        showMonthYearPicker
                                        maxDate={
                                            filters.toDate
                                                ? moment(filters.toDate).toDate()
                                                : new Date()
                                        }
                                        className="date-filter regular-14"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4" lg="3">
                                <FormGroup>
                                    <DatePicker
                                        selected={filters.toDate}
                                        onChange={this.handleFilterUpdate("toDate")}
                                        placeholderText={t("reports.filter.to")}
                                        dateFormat={salesReportPeriodSearchFormat}
                                        showMonthYearPicker
                                        minDate={
                                            filters.fromDate &&
                                            moment(filters.fromDate).toDate()
                                        }
                                        maxDate={new Date()}
                                        className="date-filter regular-14"
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4" lg="3">
                                <FormGroup>
                                    <Button
                                        block
                                        color="success"
                                        className="search-button"
                                        type="submit"
                                        onClick={e => { e.preventDefault(); this.onSearch(); }}
                                        disabled={this.props.massListLoading}
                                    >
                                        {this.props.massListLoading ? (
                                            <SmallLoader />
                                        ) : (
                                                t("buttons.search")
                                            )}
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col md="12" lg="3">
                                <FormGroup>
                                    <Button
                                        outline
                                        block
                                        color="success"
                                        className="export-button"
                                        onClick={this.onExport}
                                        disabled={this.props.exportLoading}
                                    >
                                        {this.props.exportLoading ? (
                                            <SmallLoader />
                                        ) : (
                                                t("buttons.export")
                                            )}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
        );
    }

    onSearch = () => {
        if (this.props.massListLoading) {
            return;
        }
        clearLocalAlerts();
        fetchMassReport(this.props.filters).then((result) => {
            if (!result.isSuccess) {
                addNewLocalErrorAlert(
                    this.props.t(
                        "reports.massReportLoadingFailed",
                        "Massiaruande laadimine ebaõnnestus: "
                    ) + result.errorMessage,
                    this.props.location.pathname,
                    true
                );
            }
        });
    };

    onExport = () => {
        if (this.props.exportLoading) {
            return;
        }
        clearLocalAlerts();
        fetchMassReportExportFile(this.props.filters).then((result) => {
            if (!result.isSuccess) {
                addNewLocalErrorAlert(
                    this.props.t(
                        "reports.massReportExportFailed",
                        "Massiaruande ekportimine ebaõnnestus: "
                    ) + result.errorMessage,
                    this.props.location.pathname,
                    true
                );
            }
        });
    };

    handleFilterUpdate = (name) => (value) => {
        this.props.massReportSearchFilterUpdate(name, value);
    };

    handleSelectChange = (name) => (selectedObject) => {
        this.handleFilterUpdate(name)(selectedObject);
    };

    handleReportFormatSelectChange = (selectedObject) => {
        this.props.massReportUpdateFormat(selectedObject);
    };
}

const mapStateToProps = (state) => {
    return {
        filters: state.massReport.filters,
        massList: state.massReport.massList,
        massListFromDate: state.massReport.massListFromDate,
        massListToDate: state.massReport.massListToDate,
        massListLoading: state.massReport.massListLoading,
        exportLoading: state.massReport.exportLoading,
    };
};

const reduxActions = {
    massReportSearchFilterUpdate,
};

export default withRouter(
    connect(
        mapStateToProps,
        reduxActions
    )(withTranslation("common")(MassReportsSearch))
);
