import * as actionTypes from "redux/actions/actionTypes";

export const adminAllPackagesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_FETCH_START,
    };
};

export const adminAllPackagesFetchSuccess = (
    packagesList,
    dataSize,
    filters
) => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_FETCH_SUCCESS,
        packagesList,
        dataSize,
        filters,
    };
};

export const adminAllPackagesFetchFail = () => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_FETCH_FAIL,
    };
};

export const adminAllPackagesFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const adminAllPackagesFilterClear = () => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_FILTER_CLEAR,
    };
};

export const adminAllPackagesSortingUpdate = (sorting) => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_SORTING_UPDATE,
        sorting,
    };
};

export const adminAllPackagesPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.ADMIN_ALL_PACKAGES_PAGINATION_UPDATE,
        pagination,
    };
};
