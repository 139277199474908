
import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Row, Col, Button, Modal, ModalBody } from "reactstrap";
// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import Checkbox from "components/Form/Checkbox";
import SelectionHeader from "components/Form/SelectionHeader/SelectionHeader";
import PackageConditionsIcons from "components/Form/PackageConditions/PackageConditionsIcons";
import { DATE_FORMAT } from "util/DateTime";
import "./SelectedApplications.scss";


class SelectedApplicationsModal extends Component {
    static propTypes = {
        isOpen: PropTypes.bool,
        packagesList: PropTypes.array.isRequired,
        selectedIds: PropTypes.array.isRequired,
        onSelect: PropTypes.func.isRequired,
        onSelectAll: PropTypes.func.isRequired,
        onCancel: PropTypes.func.isRequired,
        onConfirm: PropTypes.func.isRequired,
        onToggle: PropTypes.func.isRequired,
        alertGroups: PropTypes.array.isRequired,
        isDisabled: PropTypes.bool,
        isLoading: PropTypes.bool,
        onGenerateInvoice: PropTypes.func.isRequired,
        isGenerateInvoiceDisabled: PropTypes.bool,
        isGenerateInvoiceLoading: PropTypes.bool,
    }

    static ALERT_GROUP = "SelectedPackagesAlertGroup";

    render() {
        return (
            <>
                <Modal
                    isOpen={this.props.isOpen}
                    toggle={this.props.onToggle}
                    centered
                    size="lg"
                    className="selected-applications-modal"
                >
                    <ModalBody className="p-4">
                        <Row className="mb-4">
                            <Col>
                                <span className="bold-19">Valitud näidised</span>
                            </Col>
                            <Col className="col-auto float-right text-right">
                                <CloseTextIcon
                                    text="Sulge"
                                    onClick={this.props.onToggle}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Alerts hideGlobal={true} filterGroups={this.props.alertGroups} />
                            </Col>
                        </Row>
                        <Row className="no-gutters mx-n4 mb-4">
                            <Col>
                                {this.renderTable()}
                            </Col>
                        </Row>
                        <Row>
                            <Col md="4" className="mb-2">
                                <Button
                                    color="success"
                                    outline
                                    block
                                    onClick={this.props.onCancel}
                                    disabled={this.props.isDisabled}
                                >
                                    Tühista
                                </Button>
                            </Col>
                            <Col md="4" className="mb-2">
                                <Button
                                    block outline
                                    color="secondary"
                                    onClick={this.props.onGenerateInvoice}
                                    disabled={this.props.isDisabled || this.props.isGenerateInvoiceDisabled}
                                >
                                    {this.props.isGenerateInvoiceLoading ? <LoadingAnimation /> : "Genereeri taotluse arve"}
                                </Button>
                            </Col>
                            <Col md="4" className="mb-2">
                                <Button
                                    color="success"
                                    block
                                    onClick={this.props.onConfirm}
                                    disabled={this.props.isDisabled}
                                >
                                    {this.props.isLoading ? <LoadingAnimation /> : `Kinnita ja esita näidised (${this.props.selectedIds.length}) testimisse`}
                                </Button>
                            </Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </>
        );
    }

    renderTable() {
        const selectedPackages = this.props.packagesList.filter(pkg => this.props.selectedIds.includes(pkg.id));

        const columns = [
            {
                dataField: "id",
                text: "ID",
                classes: "semibold-14",
                sort: true,
                hidden: true,
            },
            {
                dataField: "ean",
                text: "EAN kood",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "name",
                text: "Toote nimetus",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "client",
                text: "Ettevõte",
                classes: "semibold-14",
                sort: true,
            },
            {
                dataField: "date",
                text: "Esitatud",
                classes: "regular-14",
                sort: true,
                formatter: (date) => moment(date).format(DATE_FORMAT),
                align: "right",
                headerAlign: "right",
            },
            {
                dataField: "",
                text: "Staatus",
                classes: "regular-14 package-condition-col",
                align: "right",
                headerAlign: "right",
                //sort: true,
                formatter: (cell, row) => this.packageConditionsFormatter(row),
            },
        ];

        const selectRow = {
            mode: "checkbox",
            selected: this.props.selectedIds,
            hideSelectAll: false,
            selectionRenderer: ({ mode, checked, disabled }) => (
                <Checkbox value={checked} onClick={() => { }} className="px-0" />
            ),
            onSelect: (row, isSelect, rowIndex, e) => {
                this.props.onSelect(row, isSelect, rowIndex, e);
            },
            onSelectAll: (isSelect, rows) => {
                this.props.onSelectAll(isSelect, rows);
            },
            selectionHeaderRenderer: ({ mode, checked, indeterminate }) => {
                return <SelectionHeader checked={checked} indeterminate={indeterminate} />
            }
        }

        const emptyTableIndication = (<p className="text-center regular-14">Ühtegi näidist ei ole valitud.</p>);

        return (
            <>
                <BootstrapTable
                    bootstrap4
                    bordered={false}
                    classes="selected-applications-table table-layout-auto fixed-header"
                    wrapperClasses="selected-applications-table__wrapper table-responsive"
                    keyField="id"
                    data={selectedPackages}
                    columns={columns}
                    noDataIndication={emptyTableIndication}
                    selectRow={selectRow}
                />
            </>
        );
    }

    onToggle = () => {
        this.setState(prevState => {
            return {
                isOpen: !prevState.isOpen,
            }
        });
    }

    packageConditionsFormatter = (row) => {
        return (
            <PackageConditionsIcons
                idSuffix={"selected-applications-list-" + row.id}
                debtAmount={Number(row.debt)}
                isUnsynced={row.skip_migration}
                unsubmittedReportsAmount={row.unsubmitted_sales_reports}
            />
        );
    }

}

export default SelectedApplicationsModal;
/*
= ({
        toggleButtonText,
        children, title, confirmButtonText,
        isOpen, onToggle, onCancel, onConfirm,
        isDisabled, isLoading, alertGroups,
}) => {
    return (
        <>
            <DeleteText
                text={toggleButtonText}
                onClick={onToggle}
                disabled={isDisabled}
            />
            <Modal
                isOpen={isOpen}
                onToggle={onToggle}
                centered
                backdrop="static"
                className="delete-package-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">{title}</span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text="Sulge"
                                onClick={onToggle}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts hideGlobal={true} filterGroups={alertGroups} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {children}
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6">
                            <Button
                                color="success"
                                outline
                                block
                                onClick={onCancel}
                                disabled={isDisabled}
                            >
                                Katkesta
                            </Button>
                        </Col>
                        <Col md="6">
                            <Button
                                color="success"
                                block
                                onClick={onConfirm}
                                disabled={isDisabled}
                            >
                                {isLoading ? <LoadingAnimation /> : confirmButtonText}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
}

DeleteModal.defaultProps = {
    toggleButtonText: "Kustuta",
    children: <span className="regular-14">Kas oled kindel, et tahad kustutada?</span>,
    title: "Kinnita kustutamine",
    confirmButtonText: "Kinnita",
    isDisabled: false,
    isLoading: false,
    alertGroups: [],
    //onToggle, onCancel, onConfirm,
};

export default DeleteModal;

*/
