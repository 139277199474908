import axios from "../../axios";
import store from "redux/store";
import moment from "moment";

import { getServerErrorMessage } from "util/Errors";

import {
    adminSendToTestingStart,
    adminSendToTestingSuccess,
    adminSendToTestingFail,
} from "redux/actions/admin/applications";

/*
import {
    clearLocalAlerts, 
    addNewLocalErrorAlert, 
    addNewLocalSuccessAlert,
} from "util/Alerts";
*/

import { ADMIN_PACKAGE_DETAILS, ADMIN_SEND_TO_TESTING } from "config/Api";

export function fetchPackageDetails(packageId) {
    const endpoint = ADMIN_PACKAGE_DETAILS.replace("<package_id>", packageId);

    return axios
        .get(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
}

export function updatePackageDetails(
    packageDetails,
    activateInNav = false,
    startDate = null
) {
    const endpoint = ADMIN_PACKAGE_DETAILS.replace(
        "<package_id>",
        packageDetails.id
    );
    const data = {
        activate_in_nav: activateInNav,
        start_date: moment(startDate).format("YYYY-MM-DD"),
        name: packageDetails.name,
        ean: packageDetails.ean,
        ean_type: packageDetails.ean_type.id,
        package_status: packageDetails.status.id,
        comment: "",
        package_category: packageDetails.package_category.id,
        volume: packageDetails.volume.id,
        weight: packageDetails.weight,
        color: packageDetails.color.id,
        package_type: packageDetails.package_type.id,
        location: packageDetails.location,
    };

    return axios
        .put(endpoint, data)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
}

export function deletePackage(packageId) {
    const endpoint = ADMIN_PACKAGE_DETAILS.replace("<package_id>", packageId);

    return axios
        .delete(endpoint)
        .then((response) => {
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            throw Error(errorMessage);
        });
}

export function sendToTestingFromDetails(packageId) {
    store.dispatch(adminSendToTestingStart());
    const packageIdsToTest = {
        ids: [{ id: packageId }],
    };

    return axios
        .put(ADMIN_SEND_TO_TESTING, packageIdsToTest)
        .then((response) => {
            const errors = response.data.errors;

            if (errors && errors.length) {
                store.dispatch(adminSendToTestingSuccess([]));
            } else {
                store.dispatch(adminSendToTestingSuccess([packageId]));
            }

            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminSendToTestingFail(errorMessage));

            throw Error(errorMessage);
        });
}
