// Everything about react router (subroutes and so on... ) https://reacttraining.com/react-router/web/example/route-config

import urls from "config/urls";

import DashboardView from "./views/Dashboard";
import PackageView from "./views/Packages";
import ReportsView from "./views/Reports/Reports";
import OriginalSalesReportView from "./views/Reports/OriginalSalesReport";
import RepairSalesReportView from "./views/Reports/RepairSalesReport";
import InvoiceView from "./views/Invoices";
import InvoicePreviewModal from "components/Invoices/Preview/PreviewModal";
import ProfileView from "./views/Profile";
import UsersTable from "./components/Profile/Users/UsersTable/UsersTable";
import Profile from "./components/Profile/Profile/Profile";
import RouteWithSubRoutes from "./components/Routing/RouteWithSubRoutes";
import AddPackageModal from "./components/Packages/AddPackageModal/AddPackageModal";
import ContractModal from "./components/ContractModal/ContractModal";
import SalesTable from "./components/Reports/Sales/SalesTable/SalesTable";
import SummaryReportsView from "./views/Reports/SummaryReport";
import MassReportView from "./views/Reports/MassReport";
import Contracts from "./components/Profile/Contracts/Contracts";
import Notifications from "./components/Profile/Notifications/Notifications";
import PrivacyPolicyView from "./views/public/PrivacyPolicy";
import CookiePolicyView from "./views/public/CookiePolicy";
import ReturnReportListView from "./components/ReturnReports/ListView";
import ReturnReportDetailsView from "./components/ReturnReports/DetailsView";
import AdditionalDepositReportListView from "./components/AdditionalDepositReports/ListView";
import AdditionalDepositReportDetailsView from "./components/AdditionalDepositReports/DetailsView";

import i18n from "i18n";
import React from "react";
import { Translation } from "react-i18next";

const routes = [
    {
        path: urls.DASHBOARD,
        label: i18n.t("routes.dashboard", "Avaleht"),
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.dashboard", "Avaleht")}
                </Translation>
            );
        },
        component: RouteWithSubRoutes,
        routes: [
            {
                path: urls.DASHBOARD,
                label: "",
                component: DashboardView,
            },
            {
                path: urls.CONTRACT,
                label: "",
                component: ContractModal,
            },
        ],
    },
    {
        path: urls.PACKAGES,
        label: i18n.t("routes.packages", "Pakendid"),
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.packages", "Pakendid")}
                </Translation>
            );
        },
        component: RouteWithSubRoutes,
        routes: [
            {
                path: urls.PACKAGES,
                label: "",
                component: PackageView,
            },
            {
                path: urls.ADD_PACKAGES,
                label: "",
                component: AddPackageModal,
            },
        ],
    },
    {
        exact: true,
        path: urls.REPORTS_SALE_FIX,
        label: i18n.t("routes.salesReports", "Müügiaruanne"),
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.salesReports", "Müügiaruanne")}
                </Translation>
            );
        },
        component: RepairSalesReportView,
    },
    {
        exact: true,
        path: urls.REPORTS_SALE,
        label: i18n.t("routes.salesReports", "Müügiaruanne"),
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.salesReports", "Müügiaruanne")}
                </Translation>
            );
        },
        component: OriginalSalesReportView,
    },
    {
        path: urls.REPORTS,
        label: "",
        component: ReportsView,
        routes: [
            {
                path: urls.REPORTS_SALES,
                label: i18n.t("routes.salesReportsSub", "Müügiaruanded"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) =>
                                t("routes.salesReportsSub", "Müügiaruanded")
                            }
                        </Translation>
                    );
                },
                component: SalesTable,
            },
            {
                path: urls.REPORTS_SUMMARY,
                label: i18n.t("routes.summaryReports", "Koondaruanne"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.summaryReports", "Koondaruanne")}
                        </Translation>
                    );
                },
                component: SummaryReportsView,
            },
            {
                path: urls.REPORTS_MASS,
                label: i18n.t("routes.massReports", "Massiaruanne"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.massReports", "Massiaruanne")}
                        </Translation>
                    );
                },
                component: MassReportView,
            },
            {
                exact: true,
                path: urls.REPORTS_RETURN_LIST,
                label: i18n.t("routes.returnReports"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.returnReports")}
                        </Translation>
                    );
                },
                component: ReturnReportListView,
            },
            {
                exact: true,
                path: urls.REPORTS_RETURN_DETAILS,
                label: i18n.t("routes.returnReportsDetails"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.returnReportsDetails")}
                        </Translation>
                    );
                },
                component: ({match}) => <ReturnReportDetailsView id={Number(match.params.id)} />,
            },
            {
                exact: true,
                path: urls.REPORTS_ADDITIONAL_DEPOSIT_LIST,
                label: i18n.t("routes.additionalDepositReports"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.additionalDepositReports")}
                        </Translation>
                    );
                },
                component: AdditionalDepositReportListView,
            },
            {
                exact: true,
                path: urls.REPORTS_ADDITIONAL_DEPOSIT_DETAILS,
                label: i18n.t("routes.additionalDepositReportsDetails"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.additionalDepositReportsDetails")}
                        </Translation>
                    );
                },
                component: ({match}) => <AdditionalDepositReportDetailsView id={Number(match.params.id)} />,
            },
        ],
    },
    {
        path: urls.INVOICES,
        label: i18n.t("routes.invoices", "Arved"),
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.invoices", "Arved")}
                </Translation>
            );
        },
        component: RouteWithSubRoutes,
        routes: [
            {
                path: urls.INVOICES,
                label: "",
                component: InvoiceView,
                key: "invoiceView",
            },
            {
                path: urls.INVOICES_PREVIEW,
                label: "",
                component: InvoicePreviewModal,
                key: "invoicePreviewView",
            },
        ],
    },
    {
        path: urls.PROFILE,
        label: "",
        component: ProfileView,
        routes: [
            {
                path: urls.PROFILE_EDIT,
                label: i18n.t("routes.profile", "Profiil"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.profile", "Profiil")}
                        </Translation>
                    );
                },
                component: Profile,
            },
            {
                path: urls.PROFILE_CONTRACT,
                label: i18n.t("routes.contract", "Lepingud"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.contract", "Lepingud")}
                        </Translation>
                    );
                },
                component: Contracts,
            },
            {
                path: urls.PROFILE_USERS,
                label: i18n.t("routes.users", "Kasutajad"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.users", "Kasutajad")}
                        </Translation>
                    );
                },
                component: UsersTable,
            },
            {
                path: urls.PROFILE_NOTIFICATIONS,
                label: i18n.t("routes.notifications", "Teavitused"),
                labelComponent: () => {
                    return (
                        <Translation>
                            {(t) => t("routes.notifications", "Teavitused")}
                        </Translation>
                    );
                },
                component: Notifications,
            },
        ],
    },
    {
        path: urls.PRIVACY_POLICY,
        label: "Privaatsuspoliitika",
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.privacyPolicy", "Privaatsuspoliitika")}
                </Translation>
            );
        },
        component: PrivacyPolicyView,
    },
    {
        path: urls.COOKIE_POLICY,
        label: "Küpsised",
        labelComponent: () => {
            return (
                <Translation>
                    {(t) => t("routes.cookies", "Küpsised")}
                </Translation>
            );
        },
        component: CookiePolicyView,
    },
];

export default routes;
