import React from 'react';
import Dashboard from "components/Dashboard/Dashboard";

class DashboardView extends React.Component {

    render() {
        return (
            <Dashboard />
        );
    }
}

export default DashboardView;