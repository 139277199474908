import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import moment from "moment";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
} from "react-bootstrap-table2-paginator";
import CustomPagination from "components/Table/Pagination/CustomPagination";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { NavLink } from "react-router-dom";

import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap";

import Select from "components/Form/Select";

import WrappedLoader from "components/Loader/WrappedLoader";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import AddUserModal from "components/Profile/Users/AddUserModal/AddUserModal";
import UserDetailsModal from "components/Profile/Users/UserDetailsModal/UserDetailsModal";

import { fetchUsers, removeUser } from "util/Profile";
import { adminfetchAllClients } from "util/admin/Users";

import { adminClientsPaginationUpdate, adminClientsFilterUpdate, adminClientsSortingUpdate } from "redux/actions/admin/users";

import {
    clearLocalAlerts,
    addNewLocalInfoAlert,
    addNewLocalErrorAlert,
} from "util/Alerts";
import { isUserClientAdmin, isPartnerMode } from "util/Access";

import StatusCell from "components/Table/Cell/Status/StatusCell";
import urls from "config/urls";
import { getContractStatusType } from "components/Admin/Contract/helpers/Options";
import { DATE_FORMAT } from "util/DateTime";

const NoDataIndication = (props) => {
    let content = "Ei leitud sobivaid kliente.";
    if (props.clientsLoading) {
        content = <WrappedLoader />;
    }
    return <div className="text-center">{content}</div>;
};

class Clients extends React.Component {
    state = {
        isAddUserModalOpen: false,
        isUserDetailsModalOpen: false,
        selectedUser: {},
        companyFilter: "",
        contractStatusFilter: "",
        contractStatusOptions: [
            {
                id: "1",
                label: "KINNITAMISEL",
            },
            {
                id: "2",
                label: "KEHTIV",
            },
            {
                id: "3",
                label: "KEHTETU",
            },
        ],
    };

    componentDidMount() {
        adminfetchAllClients(true);
    }

    onRowClick = (e, row) => {
        const url = urls.EPP_USERS_CLIENTS_CLIENT_PROFILE.replace(
            ":id",
            row.id
        );
        this.props.history.push(url);
    };

    onPaginationChange = (page, sizePerPage) => {
        const currentPagination = this.props.pagination;
        const newPagination = { ...currentPagination, page, sizePerPage };
        this.props.adminClientsPaginationUpdate(newPagination);
    };

    render() {
        return (
            <>
                <div className="navigation-buttons pb-4">
                    <NavLink
                        to={urls.EPP_USERS_CLIENTS}
                        className="btn btn-default mr-1"
                    >
                        Kliendid
                    </NavLink>
                    <NavLink
                        to={urls.EPP_USERS_PARTNERS}
                        className="btn btn-default ml-1 mr-1"
                    >
                        Partnerid
                    </NavLink>
                    <NavLink
                        to={urls.EPP_USERS_EPP}
                        className="btn btn-default ml-1 mr-1"
                    >
                        EPP
                    </NavLink>
                </div>
                <Card className="users-card pb-4">
                    <CardHeader>
                        <Row className="align-items-center">
                            <Col className="mb-2 col-12 col-md-auto px-1 px-sm-3">
                                <span className="heading">Otsing</span>
                            </Col>
                            <Col className="col-12 col-md px-1 pl-sm-3 ">
                                <Select
                                    value={this.props.filters.client}
                                    options={this.props.clients || []}
                                    isSearchable
                                    isClearable
                                    placeholder="Ettevõtte nimi"
                                    getOptionLabel={(option) => option.name}
                                    getOptionValue={(option) => option.id}
                                    className="mb-2"
                                    onChange={(client) =>
                                        this.props.adminClientsFilterUpdate("client", client)
                                    }
                                    isLoading={this.props.clientsLoading}
                                    loadingMessage={() => "Laeb..."}
                                />
                            </Col>
                            <Col className="float-right col-12 col-sm-6 col-md-3 px-1 pl-sm-3 pl-md-1">
                                <Select
                                    options={this.state.contractStatusOptions}
                                    isClearable
                                    value={this.props.filters.contractStatus}
                                    getOptionLabel={(option) => option.label}
                                    getOptionValue={(option) => option.id}
                                    placeholder="Lepingu staatus"
                                    onChange={(status) =>
                                        this.props.adminClientsFilterUpdate("contractStatus", status)
                                    }
                                />
                            </Col>
                            <Col className="col-12 col-sm-6 col-md-2 px-1 pr-md-3">
                                <Button
                                    block
                                    color="success"
                                    className="mb-2"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        adminfetchAllClients(false);
                                    }}
                                    disabled={this.props.clientsLoading}
                                    style={{ minWidth: "100px" }}
                                >
                                    {this.props.clientsLoading ? <LoadingAnimation /> : "Uuenda"}
                                </Button>
                            </Col>
                        </Row>
                    </CardHeader>
                    <CardBody className="p-0">{this.renderTable()}</CardBody>
                    <AddUserModal
                        userRole="CLIENT"
                        isOpen={this.state.isAddUserModalOpen}
                        toggle={this.toggleAddUserModal}
                    />
                    <UserDetailsModal
                        userRole="CLIENT"
                        user={this.state.selectedUser}
                        isOpen={this.state.isUserDetailsModalOpen}
                        toggle={this.toggleUserDetailsModal}
                    />
                </Card>
            </>
        );
    }

    renderTable() {
        if (this.props.clientsLoading) {
            return <WrappedLoader />;
        }
        let filteredClients = this.props.filteredClients;

        if (!filteredClients) {
            return null;
        }

        const columns = [
            {
                dataField: "name",
                text: "Klient",
                sort: true,
                onSort: this.onSort,
                classes: "semibold-14",
            },
            {
                dataField: "users",
                text: "Kasutajad",
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: "registered_at",
                text: "Registreeritud",
                formatter: (date) => moment(date).format(DATE_FORMAT),
                sort: true,
                onSort: this.onSort,
            },
            {
                dataField: "contract_status",
                text: "Lepingu staatus",
                sort: true,
                onSort: this.onSort,
                sortValue: (cell, row) => cell.label,
                formatter: (obj) => (
                    <StatusCell
                        status={obj.label}
                        type={getContractStatusType(obj.value)}
                    />
                ),
            },
            {
                dataField: "signed_at",
                text: "Leping allkirjastatud",
                formatter: (date) =>
                    date ? moment(date).format(DATE_FORMAT) : "",
                sort: true,
                onSort: this.onSort,
            },
            // {
            //   dataField: "",
            //   text: "",
            //   align: "right",
            //   formatter: (field, row) => dropdownFormatter(row),
            // },
        ];

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onRowClick(e, row);
            },
        };

        const paginationOptions = {
            custom: true,
            page: this.props.pagination.page,
            sizePerPage:
                this.props.pagination.sizePerPage ||
                this.props.pagination.dataSize,
            totalSize: this.props.pagination.dataSize,
            sizePerPageList: this.props.pagination.sizePerPageList,
            onSizePerPageChange: (sizePerPage, page) => {
                this.onPaginationChange(page, sizePerPage);
            },
            onPageChange: (page, sizePerPage) => {
                this.onPaginationChange(page, sizePerPage);
            },
        };

        const defaultSorted = [
            this.props.sorting,
        ];

        return (
            <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
            >
                {({ paginationProps, paginationTableProps }) => (
                    <>
                        <Row>
                            <Col>
                                <BootstrapTable
                                    bootstrap4
                                    bordered={false}
                                    classes="table-layout-auto no-top-border"
                                    wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                                    keyField="id"
                                    data={filteredClients}
                                    columns={columns}
                                    rowClasses={rowClasses}
                                    rowEvents={rowEvents}
                                    defaultSorted={defaultSorted}
                                    onTableChange={this.onTableChange}
                                    {...paginationTableProps}
                                    noDataIndication={
                                        <NoDataIndication
                                            loading={this.props.clientsLoading}
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="mx-3">
                            <Col className="px-3 mr-1">
                                <CustomPagination
                                    unknownSizePerPageText="Kõik"
                                    {...paginationProps}
                                />
                            </Col>
                        </Row>
                    </>
                )}
            </PaginationProvider>
        );
    }

    onSort = (dataField, order) => {
        this.props.adminClientsSortingUpdate({ dataField, order });
    }

    showAddUserButton = () => {
        return isUserClientAdmin() || isPartnerMode();
    };

    showRemoveUserButton = () => {
        return isUserClientAdmin() || isPartnerMode();
    };

    toggleAddUserModal = () => {
        this.setState((prevState) => {
            return {
                isAddUserModalOpen: !prevState.isAddUserModalOpen,
            };
        });
    };

    toggleUserDetailsModal = () => {
        this.setState((prevState) => {
            return {
                isUserDetailsModalOpen: !prevState.isUserDetailsModalOpen,
            };
        });
    };

    onOpenUserDetails = (user) => {
        clearLocalAlerts();
        this.setState({
            isUserDetailsModalOpen: true,
            selectedUser: user,
        });
    };

    onRemoveUser = (user) => {
        clearLocalAlerts();
        removeUser(user.user_id)
            .then((resp) => {
                fetchUsers();
                addNewLocalInfoAlert(
                    "Kasutaja eemaldatud.",
                    this.props.location.pathname
                );
            })
            .catch((error) => {
                addNewLocalErrorAlert(
                    error.message,
                    this.props.location.pathname
                );
            });
    };
}

const mapStateToProps = (state) => {
    return {
        clients: state.admin.users.clients,
        clientsLoading: state.admin.users.loading,
        filteredClients: state.admin.users.clientsFiltered,
        pagination: state.admin.users.clientsFilteredPagination,
        filters: state.admin.users.clientsFilters,
        sorting: state.admin.users.clientsSorting,
    };
};

const mapActionsToProps = {
    adminClientsPaginationUpdate, adminClientsFilterUpdate, adminClientsSortingUpdate
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(Clients));
