// Must be added to combineReducer in src/redux/reducers/index.js
import React from "react";
import { Trans } from "react-i18next";

import {updateObject} from 'redux/utility';
import * as actionTypes from 'redux/actions/actionTypes';

const localActionTypes = {
    LIST_FETCH_START: "ADDITIONAL_DEPOSIT_REPORT_LIST_FETCH_START",
    LIST_FETCH_SUCCESS: "ADDITIONAL_DEPOSIT_REPORT_LIST_FETCH_SUCCESS",
    LIST_FETCH_FAIL: "ADDITIONAL_DEPOSIT_REPORT_LIST_FETCH_FAIL",
    LIST_SEARCH_FILTER_UPDATE: "ADDITIONAL_DEPOSIT_REPORT_LIST_SEARCH_FILTER_UPDATE",
    LIST_CLEAR_FILTERS: "ADDITIONAL_DEPOSIT_REPORT_LIST_CLEAR_FILTERS",
    LIST_SORTING_UPDATE: "ADDITIONAL_DEPOSIT_REPORT_LIST_SORTING_UPDATE",
    LIST_PAGINATION_UPDATE: "ADDITIONAL_DEPOSIT_REPORT_LIST_PAGINATION_UPDATE",
    LIST_SELECTED_UPDATE: "ADDITIONAL_DEPOSIT_REPORT_LIST_SELECTED_UPDATE"
}

export const STATUSES = {
    POSTED_FALSE: false,  // Sisestatud
    POSTED_TRUE: true // Kinnitatud
}

export const filterNavStatusOptions = [
    {
        value: null, label: <Trans i18nKey="reports.filter.all"></Trans>,
    },
    {
        value: STATUSES.POSTED_FALSE, label: <Trans i18nKey="reports.additionalDeposit.status.postedFalse"></Trans>
    },
    {
        value: STATUSES.POSTED_TRUE, label: <Trans i18nKey="reports.additionalDeposit.status.postedTrue"></Trans>
    },
]


const getInitialListFilters = () => {
    return {
        fromDate: null, // use it for period start from
        toDate: null, // use it for period end to
        client: null, // EPP specific
        // EPP specific filter. True shows only confirmed ones, 
        // False unconfirmed ones and Null shows all.
        is_posted: filterNavStatusOptions[0], 
    }
};

const getInitialSorting = () => {
    return {
        dataField: "period_end",
        order: "desc",
    };
};

const getSizePerPageList = (dataSize) => {
    return [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
    ];
};

const getInitialPagination = () => {
    return {
        page: 1,
        sizePerPage: 25,
        dataSize: 0,
        sizePerPageList: getSizePerPageList(0)
    }
};

const filterIdsInList = (ids, rows) => {
    const listOfIds = rows.map((row) => row.id);
    return ids.filter((id) => listOfIds.includes(id));
};

const initialState = {
    list: [],
    listSorting: getInitialSorting(),
    listPagination: getInitialPagination(),
    listFiltersUsed: getInitialListFilters(), // Filters that were used in latest successful request
    listLoading: false,
    listError: null,
    listFilters: getInitialListFilters(), // component value, on success will overwrite listFiltersUsed,
    listSelectedIds: []
};

const onResetUserSpecificData = (state, action) => {
    return initialState;
}

const onListFetchStart = (state) => {
    return updateObject(state, {
        list: [],
        listLoading: true,
        listError: null
    });
};

const onListFetchSuccess = (state, {list, dataSize, filters}) => {
    if (list) {
        for (const row of list) {
            row.compositeKey = `${row.id}-${row.period_start}-${row.period_end}`;
        }
    }
    return updateObject(state, {
        list,
        listLoading: false,
        listPagination: {
            ...state.listPagination,
            dataSize
        },
        listFiltersUsed: filters,
        listSelectedIds: filterIdsInList(state.listSelectedIds, list)
    });
};

const onListFetchFail = (state, {error}) => {
    return updateObject(state, {
        list: [],
        listLoading: false,
        listError: error,
        ListSelectedIds: []
    });
};

export const onUpdateSearchFilter = (state, key, value)  => {
    return updateObject(state, {
        listFilters: {
            ...state.listFilters,
            [key]: value,
        },
        listPagination: {
            ...state.listPagination,
            page: 1
        }
    });
};

export const onClearFilters = (state) => {
    return updateObject(state, {
        listFilters: {
            ...getInitialListFilters()
        },
        listPagination: {
            ...state.listPagination,
            page: 1
        }
    });
}

const onListSortingUpdate = (state, {sorting}) => {
    return updateObject(state, {
        listSorting: sorting,
    });
};

const onListPaginationUpdate = (state, action) => {
    return updateObject(state, {
        listPagination: action.pagination,
    });
};

const onListSelectedUpdate = (state, {selectedIds}) => {
    return updateObject(state, {
        listSelectedIds: selectedIds,
    });
};

// --- ACTIONS ---
export const listUpdateFilters = (key, value) => {
    return {
        type: localActionTypes.LIST_SEARCH_FILTER_UPDATE,
        key: key,
        value: value,
    }
}

export const listClearFilters = () => {
    return {
        type: localActionTypes.LIST_CLEAR_FILTERS,
    }
}

export const listFetchStart = () => {
    return {
        type: localActionTypes.LIST_FETCH_START
    }
};

export const listFetchSuccess = (list, dataSize, filters) => {
    return {
        type: localActionTypes.LIST_FETCH_SUCCESS,
        list, dataSize, filters
    }
};

export const listFetchFail = (error) => {
    return {
        type: localActionTypes.LIST_FETCH_FAIL,
        error
    }
};

export const listSortingUpdate = (sorting) => {
    return {
        type: localActionTypes.LIST_SORTING_UPDATE,
        sorting,
    };
};

export const listPaginationUpdate = (pagination) => {
    return {
        type: localActionTypes.LIST_PAGINATION_UPDATE,
        pagination,
    };
};

export const listSelectedUpdate = (selectedIds) => {
    return {
        type: localActionTypes.LIST_SELECTED_UPDATE,
        selectedIds,
    };
};

export const actions = {
    listFetchStart,
    listFetchSuccess,
    listFetchFail,
    listUpdateFilters,
    listClearFilters,
    listSortingUpdate,
    listPaginationUpdate,
    listSelectedUpdate
}

// --- REDUCER ---
const reducer = (state = initialState, action) => {
    switch (action.type) {
        // SALES
        case localActionTypes.LIST_FETCH_START:
            return onListFetchStart(state, action);
        case localActionTypes.LIST_FETCH_SUCCESS:
            return onListFetchSuccess(state, action);
        case localActionTypes.LIST_FETCH_FAIL:
            return onListFetchFail(state, action);
        // FILTER
        case localActionTypes.LIST_SEARCH_FILTER_UPDATE:
            return onUpdateSearchFilter(state, action.key, action.value);
        case localActionTypes.LIST_CLEAR_FILTERS:
            return onClearFilters(state);
        // SORT
        case localActionTypes.LIST_SORTING_UPDATE:
            return onListSortingUpdate(state, action);
        // PAGINATION
        case localActionTypes.LIST_PAGINATION_UPDATE:
            return onListPaginationUpdate(state, action);
        // SELECTION
        case localActionTypes.LIST_SELECTED_UPDATE:
            return onListSelectedUpdate(state, action);
        // GENERIC
        case actionTypes.GET_AUTH_USER:
            return onResetUserSpecificData(state, action);
        default:
            return state;
    }
};

export default reducer
