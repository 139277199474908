import React from "react";
import { withRouter } from "react-router-dom";

import { ReactComponent as Logo } from "assets/icons/logo.svg";
import urls from "config/urls";

import "./BrandLogoText.scss";

function BrandLogoText({ history }) {
    return (
        <div
            className="brand-logo-text-wrapper pointer"
            onClick={(e) => {
                e.preventDefault();
                history.push(urls.DOMAIN)
            }}
        >
            <Logo className="brand-logo" />
            <p className="brand-text">Eesti Pandipakend</p>
        </div>
    );
}

export default withRouter(BrandLogoText);
