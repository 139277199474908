import * as actionTypes from './actionTypes';
import {getAuthUser} from "../../util/Auth";
import axios from "../../axios";

export const registerStart = () => {
    return {
        type: actionTypes.REGISTER_START
    }
};

export const registerSuccess = () => {
    return {
        type: actionTypes.REGISTER_SUCCESS
    }
};

export const registerFail = err => {
    return {
        type: actionTypes.REGISTER_FAIL,
        error: err
    }
};

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
};

export const authSuccess = token => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    }
};

export const authFail = error => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
};

export const getUser = user => {
    return {
        type: actionTypes.GET_AUTH_USER,
        user: user
    }
};

export const logOut = () => {
    return {
        type: actionTypes.LOG_OUT,
    }
};

export const checkAuthTimeout = expirationDate => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logOut());
        }, expirationDate * 1000)
    }
};

export const getTokenFromLocalStorage = async () => {
    return localStorage.getItem('token');
};

export const checkAuthState = () => {
        const token = localStorage.getItem('token');
        if (token) {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            axios.defaults.headers.common['Authorization'] = token;

            if (expirationDate <= new Date()) {
                return logOut();
            } else {
                getAuthUser();
                checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000);
                return authSuccess(token);
            }
        } else {
            return authFail()
        }
};

export const resetStore = () => {
    return {
        type: actionTypes.RESET_STORE,
    }
}