import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import uniqueId from "lodash/uniqueId";
import accept from "attr-accept";
import { withTranslation, Trans } from "react-i18next";

import { Row, Col, Button, Input, Modal, ModalBody } from "reactstrap";

// datepicker
import "react-datepicker/dist/react-datepicker.css";

import SmallLoader from "components/Loader/WrappedPulseLoader";
import CloseTextIcon from "components/Misc/CloseTextIcon";

import Alerts from "components/Alerts/Alerts";
import {
    clearIncludedGroups,
    addNewLocalWarningAlert,
    addNewLocalInfoAlert,
    clearLocalAlerts
} from "util/Alerts";

import reportUtils from "./utils";

import "./SyncModal.scss";

class SyncAdditionalDepositReportModal extends React.Component {
    static propTypes = {
        // manual
        isOpen: PropTypes.bool.isRequired,
        details: PropTypes.object, // If given we update a specific report (only client's rows), if not we add/update bulk, need period
        onToggle: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        isDetailsView: PropTypes.bool,
        // defaults exist
        fileMaxSize: PropTypes.number,
        fileTypes: PropTypes.arrayOf(PropTypes.string), // Only array version because using [].includes
    }

    static defaultProps = {
        fileMaxSize: 5e6,
        fileTypes: [".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    }


    constructor(props) {
        super(props);
        this.state = {
            groupId: uniqueId("sync-additional-deposit-report-modal__"),
            isLoading: false,
            reportId: props.details?.id || null
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
            })
        }
    }

    render() {
        const { t, isOpen, details } = this.props;
        const { groupId, isLoading } = this.state;
        if (!isOpen) {
            return false;
        }

        const isUpdateSpecificReport = !!details?.id;
        const title = t("reports.additionalDeposit.sync.title");
        const canSubmit = !isLoading;

        return (
            <Modal
                isOpen={isOpen}
                onToggle={this.onCancel}
                backdrop="static"
                className="sync-additional-deposit-report-modal"
            >
                <ModalBody className="">
                    <Row className="header-section">
                        <Col>
                            <span className="modal-title">{title}</span>
                        </Col>
                        <Col className="float-right text-right col-auto">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onCancel}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts hideGlobal={true} filterGroups={[groupId]} />
                        </Col>
                    </Row>
                    {
                        isUpdateSpecificReport &&
                        <Row className="mb-2">
                            <Col>
                                <Input
                                    disabled
                                    type="text"
                                    value={`${details.client?.name} (${details.period_formatted})`}
                                    className="input-md"
                                />
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <p>
                                {
                                    isUpdateSpecificReport ?
                                        "Sünkronisatsiooni käigus uuendatakse ainult välja toodud täiendava pandi aruande seisu."
                                        :
                                        "Sünkronisatsiooni käigus päritakse NAV-ist täiendava pandi aruanded ja luuakse/uuendatakse nende seisu veebikeskkonnas."
                                }
                            </p>
                            <p>Eelduseks on, et tegu on aktiivse lepinguga kliendiga ning aruanne ei ole Kinnitatud staatuses.</p>

                            <p>Päring võib võtta aega mitu minutit.</p>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Button
                                color="link"
                                disabled={isLoading}
                                onClick={this.onCancel}
                                className="min-height-40 modal-cancel-button"
                            >
                                {t("reports.importSalesReportModal.cancelButton")}
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button
                                color="success"
                                disabled={isLoading || !canSubmit}
                                onClick={this.onConfirm}
                                className="min-height-40 modal-confirm-button"
                            >
                                {isLoading ? <SmallLoader /> : "Sünkroniseeri"}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

    clearModalAlerts = () => {
        clearIncludedGroups(this.state.groupId);
    }

    onConfirm = () => {
        const { onSuccess, details } = this.props;
        const { reportId, groupId } = this.state;
        clearLocalAlerts();
        this.setState({ isLoading: true });
        console.log("on sync confirm:", reportId, groupId)
        reportUtils.syncReports(reportId, groupId)
            .then(
                ({ isSuccess, statistics, errorMessage }) => {
                    if (isSuccess) {
                        onSuccess(statistics, details);
                    }
                }
            )
            .finally(() => {
                this.setState({ isLoading: false });
            });

    };

    onCancel = () => {
        if (this.state.isLoading) {
            return;
        }
        this.clearModalAlerts();
        this.props.onToggle(false);
    }
}

const mapStateToProps = state => {
    return {
    }
};

const reduxActions = {
};

export default withRouter(connect(mapStateToProps, reduxActions)(withTranslation()(SyncAdditionalDepositReportModal)));
