import { combineReducers } from "redux";
import { applications } from "./applications";
import { testing } from "./testing";
import { retesting } from "./retesting";
import { allPackages } from "./allPackages";
import { debts } from "./debts";
import { users } from "./users";
import { salesReports } from "./salesReports";
import { unsubmittedSalesReports } from "./unsubmittedSalesReports";
import { unconfirmedSalesReports } from "./unconfirmedSalesReports";
import { settings } from "./settings";

const adminReducer = combineReducers({
    applications,
    testing,
    retesting,
    allPackages,
    salesReports,
    unsubmittedSalesReports,
    unconfirmedSalesReports,
    debts,
    users,
    settings,
});

export default adminReducer;
