import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import {Row, Col} from "reactstrap";

import Alert from "components/Alerts/Alert/Alert";
import {isGlobal} from "util/Alerts";

import "./Alerts.scss";

const Alerts = (props) => {
    let filteredAlerts = [];
    const alerts = props.manualAlerts ? props.manualAlerts : props.alerts;

    for (const alert of alerts) {
        if (props.hideGlobal && isGlobal(alert)) {
            continue;
        }
        else if (
            props.filterGroups && 
            props.filterGroups.length && 
            !props.filterGroups.includes(alert.group)
        ) {
            continue;
        }
        filteredAlerts.push(alert);
    }

    const filteredAlertsList = filteredAlerts.map((alert, index) => {
        return (
            <Alert
                key={index}
                {...alert}
            />
        );
    }
        
    );

    return (
        <Row>
            <Col className={`alerts-list ${props.className || ""}`}>
                {filteredAlertsList}
            </Col>
        </Row>
    );
};

Alerts.propTypes = {
    alerts: PropTypes.array.isRequired,
    manualAlerts: PropTypes.array,
    hideGlobal: PropTypes.bool,
    filterGroups: PropTypes.array,
    className: PropTypes.string,
}

const mapStateToProps = state => {
    return {
        alerts: state.alerts.alertsList,
    }
}

export default connect(mapStateToProps)(Alerts);