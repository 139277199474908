import axios from "../../axios";
import store from "redux/store";
import moment from "moment";

import { getServerErrorMessage } from "util/Errors";

import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

import { ADMIN_ALL_PACKAGES } from "config/Api";

import {
    adminAllPackagesFetchStart,
    adminAllPackagesFetchSuccess,
    adminAllPackagesFetchFail,
} from "redux/actions/admin/allPackages";

export function fetchAdminAllPackages(
    filters,
    sorting,
    pagination,
    alertGroup
) {
    const endpoint = createAllPackagesEndpoint(filters, sorting, pagination);
    store.dispatch(adminAllPackagesFetchStart());
    return axios
        .get(endpoint, {
            params: {
                ordering: createSortingQueryParameter(sorting),
                ...createFilteringQueryParameters(filters),
            },
        })
        .then((response) => {
            const data = response.data;
            const packages = data.results;
            const dataSize = data.count;
            // const pageSize = data.page_size;

            store.dispatch(
                adminAllPackagesFetchSuccess(packages, dataSize, filters)
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminAllPackagesFetchFail());

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

function createAllPackagesEndpoint(filters, sorting, pagination) {
    return `${ADMIN_ALL_PACKAGES}${pagination.page}/${pagination.sizePerPage}/`;
}

const mapSortingFieldToQuery = {
    package_id: "",
    ean: "ean",
    "ean_type.label": "ean_type",
    name: "name",
    "client.label": "client__name",
    "category.label": "package_category__description",
    "type.label": "package_characteristic__volume__package_type",
    volume: "package_characteristic__volume__volume",
    weight: "package_characteristic__weight",
    created_at: "created_at",
    "status.label": "status",
    "last_test.status": "last_test",
};

const mapSortingOrderToQuery = {
    asc: "",
    desc: "-",
};

const createSortingQueryParameter = (sorting) => {
    return (
        mapSortingOrderToQuery[sorting.order] +
        mapSortingFieldToQuery[sorting.dataField]
    );
};

const mapFilterFieldsToQuery = {
    ean: "ean",
    eanType: "ean_type",
    name: "package_name",
    client: "client",
    category: "package_category",
    type: "package_type",
    volume: "volume",
    weight: "weight",
    status: "package_status",
    testResult: "last_test",
    startDate: "date_from",
    endDate: "date_to",
};

const addFilteringFieldToParams = (params, key, value) => {
    return {
        ...params,
        [mapFilterFieldsToQuery[key]]: value,
    };
};

const createFilteringQueryParameters = (filters) => {
    let params = {};

    if (filters.ean) {
        params = addFilteringFieldToParams(params, "ean", filters.ean);
    }
    if (filters.eanType) {
        params = addFilteringFieldToParams(
            params,
            "eanType",
            filters.eanType.id
        );
    }
    if (filters.name) {
        params = addFilteringFieldToParams(params, "name", filters.name);
    }
    if (filters.client) {
        params = addFilteringFieldToParams(
            params,
            "client",
            filters.client.value
        );
    }
    if (filters.category) {
        params = addFilteringFieldToParams(
            params,
            "category",
            filters.category.id
        );
    }
    if (filters.type) {
        params = addFilteringFieldToParams(params, "type", filters.type.id);
    }
    if (filters.volume) {
        params = addFilteringFieldToParams(
            params,
            "volume",
            filters.volume.value
        );
    }
    if (filters.weight) {
        params = addFilteringFieldToParams(params, "weight", filters.weight);
    }
    if (filters.status) {
        params = addFilteringFieldToParams(params, "status", filters.status.id);
    }
    if (filters.testResult) {
        params = addFilteringFieldToParams(
            params,
            "testResult",
            filters.testResult.label
        );
    }
    if (filters.startDate) {
        params = addFilteringFieldToParams(
            params,
            "startDate",
            moment(filters.startDate).format("YYYY-MM-DD")
        );
    }
    if (filters.endDate) {
        params = addFilteringFieldToParams(
            params,
            "endDate",
            moment(filters.endDate).format("YYYY-MM-DD")
        );
    }

    return params;
};
