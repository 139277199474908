import * as actionTypes from 'redux/actions/actionTypes';
import {updateObject} from 'redux/utility';
import moment from "moment";

const initialFilters = {
    fromDate: null,
    toDate: null
}

const initialState = {
    filters: initialFilters,
    salesList: [],
    salesListFromDate: null,
    salesListToDate: null,
    loading: false,
    salesListLoading: false,
    createRepairLoading: false,
    error: null,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
}

const salesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        salesListLoading: true,
        salesList: [],
        salesListFromDate: null,
        salesListToDate: null,
    });
};

const salesFetchSuccess = (state, sales, fromDate, toDate) => {
    if (sales) {
        for (const salesReport of sales) {
            salesReport.compositeKey = salesReport.id + salesReport.period + salesReport.is_repair;
            salesReport.compositePeriodIsRepair = moment(salesReport.period).format("YYYY-MM") + salesReport.is_repair;
        }
    }
    return updateObject(state, {
        salesList: sales,
        salesListFromDate: fromDate,
        salesListToDate: toDate,
        loading: false,
        salesListLoading: false,
    });
};

const salesFetchFail = (state, action) => {
    return updateObject(state, {
        salesList: [],
        loading: false,
        salesListLoading: false,
        error: action.err,
    });
};

export const updateSearchFilter = (state, key, value)  => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [key]: value,
        }
    });
};

export const clearFilters = (state) => {
    return updateObject(state, {
        filters: {
            ...initialFilters
        }
    });
}

const salesCreateRepairStart = (state, action) => {
    return updateObject(state, {
        createRepairLoading: true,
    });
}
const salesCreateRepairSuccess = (state) => {
    return updateObject(state, {
        createRepairLoading: false,
    });
}
const salesCreateRepairFail = (state) => {
    return updateObject(state, {
        createRepairLoading: false,
    });
}

export const sales = (state = initialState, action) => {
    switch (action.type) {

        // SALES
        case actionTypes.SALES_FETCH_START:
            return salesFetchStart(state, action);
        case actionTypes.SALES_FETCH_SUCCESS:
            return salesFetchSuccess(state, action.salesList, action.fromDate, action.toDate);
        case actionTypes.SALES_FETCH_FAIL:
            return salesFetchFail(state, action);
        // UPDATE FILTER
        case actionTypes.SALES_SEARCH_FILTER_UPDATE:
            return updateSearchFilter(state, action.key, action.value);
        case actionTypes.SALES_CLEAR_FILTERS:
            return clearFilters(state);
        // CREATE REPAIR REPORT
        case actionTypes.SALES_CREATE_REPAIR_START:
            return salesCreateRepairStart(state, action);
        case actionTypes.SALES_CREATE_REPAIR_SUCCESS:
            return salesCreateRepairSuccess(state, action.salesList);
        case actionTypes.SALES_CREATE_REPAIR_FAIL:
            return salesCreateRepairFail(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};