import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";
import _ from "lodash";

import { getPageCount } from "util/General";

const initialFilters = {
    ean: "",
    eanType: null,
    //client: null,
    name: "",
    category: null,
    type: null,
    conditions: [],
    volume: null,
    weight: null,
    //testResult: null,
    startDate: null,
    endDate: null,
};

const initialSorting = {
    dataField: "date",
    order: "desc",
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ],
};

const initialState = {
    filters: initialFilters,
    sorting: initialSorting,
    pagination: initialPagination,

    packagesList: null,
    unfilteredPackagesList: null,
    packagesListFiltersUsed: initialFilters,
    packagesListLoading: false,
    loading: false,
    error: null,
    selectedIds: [],
    sendToTestingLoading: false,
};

const filterIdsInList = (ids, packages) => {
    const packageIds = packages.map((pkg) => pkg.id);
    return ids.filter((id) => packageIds.includes(id));
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const onAdminApplicationsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        packagesListLoading: true,
        error: null,
        packagesList: null,
    });
};

const onAdminApplicationsFetchSuccess = (state, action) => {
    const areFilterSame = _.isEqual(
        state.packagesListFiltersUsed,
        action.filters
    );

    const newDataSize = action.packagesList.length;
    const currentPage = state.pagination.page;
    const sizePerPage = state.pagination.sizePerPage;
    const isCurrentPageValid =
        currentPage <= getPageCount(newDataSize, sizePerPage);

    return updateObject(state, {
        loading: false,
        packagesListLoading: false,
        error: null,
        packagesList: action.packagesList,
        unfilteredPackagesList: action.unfilteredPackagesList,
        packagesListFiltersUsed: action.filters,
        pagination: {
            ...state.pagination,
            page: areFilterSame && isCurrentPageValid ? currentPage : 1,
            dataSize: newDataSize,
        },
        selectedIds: filterIdsInList(
            state.selectedIds,
            action.unfilteredPackagesList
        ),
    });
};

const onAdminApplicationsFetchFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        packagesListLoading: false,
        selectedIds: [],
    });
};

const onAdminApplicationsFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
    });
};

const onAdminApplicationsFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
    });
};

const onAdminApplicationsSortingUpdate = (state, action) => {
    return updateObject(state, {
        sorting: action.sorting,
    });
};

const onAdminApplicationsPaginationUpdate = (state, action) => {
    return updateObject(state, {
        pagination: action.pagination,
    });
};

const onAdminApplicationsUpdateSelected = (state, action) => {
    return updateObject(state, {
        selectedIds: action.selected,
    });
};

const onAdminSendToTestingStart = (state) => {
    return updateObject(state, {
        sendToTestingLoading: true,
    });
};

const onAdminSendToTestingSuccess = (state, action) => {
    const idsToRemove = action.ids;

    let newPackagesList = null;
    let newDataSize = 0;
    if (state.packagesList) {
        newPackagesList = state.packagesList.filter(
            (pkg) => !idsToRemove.includes(pkg.id)
        );
        newDataSize = newPackagesList.length;
    }

    const currentPage = state.pagination.page;
    const sizePerPage = state.pagination.sizePerPage;
    const isCurrentPageValid =
        currentPage <= getPageCount(newDataSize, sizePerPage);

    return updateObject(state, {
        sendToTestingLoading: false,
        packagesList: newPackagesList,
        selectedIds: state.selectedIds.filter(
            (id) => !idsToRemove.includes(id)
        ),
        pagination: {
            ...state.pagination,
            page: isCurrentPageValid ? currentPage : 1,
            dataSize: newDataSize,
        },
    });
};

const onAdminSendToTestingFail = (state, action) => {
    return updateObject(state, {
        sendToTestingLoading: false,
    });
};

export const applications = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.ADMIN_APPLICATIONS_FETCH_START:
            return onAdminApplicationsFetchStart(state, action);
        case actionTypes.ADMIN_APPLICATIONS_FETCH_SUCCESS:
            return onAdminApplicationsFetchSuccess(state, action);
        case actionTypes.ADMIN_APPLICATIONS_FETCH_FAIL:
            return onAdminApplicationsFetchFail(state, action);
        // FILTER
        case actionTypes.ADMIN_APPLICATIONS_FILTER_UPDATE:
            return onAdminApplicationsFilterUpdate(state, action);
        case actionTypes.ADMIN_APPLICATIONS_FILTER_CLEAR:
            return onAdminApplicationsFilterClear(state, action);
        // SORT
        case actionTypes.ADMIN_APPLICATIONS_SORTING_UPDATE:
            return onAdminApplicationsSortingUpdate(state, action);
        // PAGINATION
        case actionTypes.ADMIN_APPLICATIONS_PAGINATION_UPDATE:
            return onAdminApplicationsPaginationUpdate(state, action);
        // UPDATE SELECTED
        case actionTypes.ADMIN_APPLICATIONS_UPDATE_SELECTED:
            return onAdminApplicationsUpdateSelected(state, action);
        // SEND TO TESTING
        case actionTypes.ADMIN_SEND_TO_TESTING_START:
            return onAdminSendToTestingStart(state, action);
        case actionTypes.ADMIN_SEND_TO_TESTING_SUCCESS:
            return onAdminSendToTestingSuccess(state, action);
        case actionTypes.ADMIN_SEND_TO_TESTING_FAIL:
            return onAdminSendToTestingFail(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
