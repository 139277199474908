import axios from '../../axios';

import {
    idCardAuth,
    idCardAuthVerify,
    signIdCard,
    digitalSignStatus,
    signAdminIdCard,
} from "util/Auth";
import IdCardManager from "../signing/IdCardManager";

const axiosFormConfig = {
    headers: {
        //'Content-Type': 'application/x-www-form-urlencoded'
    }
};


export default class Dokobit {
    constructor(kwargs) {
        const data = {
            language: null,

            idEndpoints: {
                auth: null,
                sign: null,
                digitalSignStatus: null
            },

            mobileidEndpoints: {
                auth: null,
                sign: null,
                digitalAuthStatus: null,
                digitalSignStatus: null
            },

            smartidEndpoints: {
                auth: null,
                sign: null,
                digitalAuthStatus: null,
                digitalSignStatus: null
            },

            ...kwargs
        };

        // construct the idCardManager
        this.idCardManager = new IdCardManager("EST");

        this.idEndpoints = data.idEndpoints;
        this.mobileidEndpoints = data.midEndpoints;
        this.smartidEndpoints = data.smartidEndpoints;
    }

    authWithIdCard(resolve, reject) {
        // This is deprecated, now using dokobit server side auth in LoginCard component.
        this.idCardManager.initializeIdCard().then(() => {
            this.idCardManager.getAuthCertificate().then(() => {
                const certificate = this.idCardManager.getCertFromManager();
                // Convert cert to format dokobit expects - base64 encoded PEM cert
                const convertedCert = btoa(unescape(encodeURIComponent(Uint8ToBase64(certificate.encoded)))); 

                idCardAuth(convertedCert)
                .then(response => {
                    if (!response.success) {
                        throw Error("login_prepare_not_success");
                    }

                    const token = response.token;
                    const dtbs_hash = response.dtbs_hash;
                    const userJson = response.user_json;
                    
                    this.idCardManager.signHexData(dtbs_hash)
                    .then((signature) => {
                        const finalizeData = this.idCardManager.finalizeSignatureData;
                        const convertedSignature = hexToBase64(finalizeData.signature);
                        
                        idCardAuthVerify(token, convertedSignature, userJson)
                        .then(response => {
                            if (!response.success) {
                                throw Error("login_verify_not_success");
                            }
                            
                            resolve(response);
                        })
                        .catch(error=> {
                            reject(error);
                        })
                    })
                    .catch(error => reject(error));
                })
                .catch(error => reject(error));
            })
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    }

    signWithIdCard(personalCode, resolve, reject) {
        this.idCardManager.initializeIdCard().then(() => {
            this.idCardManager.getCertificate().then(() => {
                const certificate = this.idCardManager.getCertFromManager();
                // Convert cert to format dokobit expects - base64 encoded PEM cert
                const convertedCert = btoa(unescape(encodeURIComponent(Uint8ToBase64(certificate.encoded)))); 
                signIdCard(personalCode, convertedCert)
                .then(response => {
                    if (!response.success) {
                        throw Error("sign_prepare_not_success");
                    }

                    const token = response.token;
                    const dtbs_hash = response.dtbs_hash;
                    
                    this.idCardManager.signHexData(dtbs_hash)
                    .then((signature) => {
                        const finalizeData = this.idCardManager.finalizeSignatureData;
                        const convertedSignature = hexToBase64(finalizeData.signature);

                        digitalSignStatus(token, "id_card", convertedSignature)
                        .then(response => {
                            if (!response.success) {
                                throw Error("sign_verify_not_success");
                            }
                            resolve(response);
                        })
                        .catch(error=> {
                            reject(error);
                        })
                    })
                    .catch(error => reject(error));
                })
                .catch(error => reject(error));
            })
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    }

    signAdminWithIdCard(personalCode, resolve, reject, contractId) {
        this.idCardManager.initializeIdCard().then(() => {
            this.idCardManager.getCertificate().then(() => {
                const certificate = this.idCardManager.getCertFromManager();
                // Convert cert to format dokobit expects - base64 encoded PEM cert
                const convertedCert = btoa(unescape(encodeURIComponent(Uint8ToBase64(certificate.encoded)))); 
                signAdminIdCard(personalCode, convertedCert, contractId)
                .then(response => {
                    if (!response.success) {
                        throw Error("sign_prepare_not_success");
                    }

                    const token = response.token;
                    const dtbs_hash = response.dtbs_hash;
                    
                    this.idCardManager.signHexData(dtbs_hash)
                    .then((signature) => {
                        const finalizeData = this.idCardManager.finalizeSignatureData;
                        const convertedSignature = hexToBase64(finalizeData.signature);

                        digitalSignStatus(token, "id_card", convertedSignature, contractId)
                        .then(response => {
                            if (!response.success) {
                                throw Error("sign_verify_not_success");
                            }
                            resolve(response);
                        })
                        .catch(error=> {
                            reject(error);
                        })
                    })
                    .catch(error => reject(error));
                })
                .catch(error => reject(error));
            })
            .catch(error => reject(error));
        })
        .catch(error => reject(error));
    }


    checkAuthStatus(endpoint, extraData, resolve, reject) {
        const doRequest = () => {
            // Not sure if superagent request.end converted correctly to axios request.then/catch
            axios
                .post(endpoint, extraData, axiosFormConfig)
                .then(res => {
                    if (res.ok) {
                        if (!res.data.success) {
                            if (res.data.pending) {
                                // Still pending, try again in 1s
                                setTimeout(() => doRequest(), 1000);
                            } else {
                                // Got a failure, lets notify the requester
                                reject(res.data);
                            }
                        } else {
                            // Process complete
                            resolve(res.data);
                        }

                    } else {
                        // Got a failure, lets notify the requester
                        reject(res.data);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        };
        return doRequest;
    };
}

function hexToBase64(str) {
    return btoa(String.fromCharCode.apply(null,
        str.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" "))
    );
}

function Uint8ToBase64(u8Arr){
    var CHUNK_SIZE = 0x8000; //arbitrary number
    var index = 0;
    var length = u8Arr.length;
    var result = '';
    var slice;
    while (index < length) {
        slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length)); 
        result += String.fromCharCode.apply(null, slice);
        index += CHUNK_SIZE;
    }
    return btoa(result);
}
