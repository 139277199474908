import React from "react";
import Footer from "components/Layout/Footer/Footer";


import "./Layout.scss";
import CookieConsent from "components/Misc/CookieConsent";

class PublicLayout extends React.Component {
    render() {
        return (
            <div id="content" className="px-0 px-md-4">
                <div className="container-fluid pb-5 pt-5 px-0 px-md-3">
                    <>
                        {this.props.children}
                        <Footer />
                        <CookieConsent />
                    </>
                </div>
            </div>
        );
    }
}

export default PublicLayout;
