import axios from "../../axios";
import store from "redux/store";
import moment from "moment";

import {
    adminDebtsFetchStart,
    adminDebtsFetchSuccess,
    adminDebtsFetchFail,
} from "redux/actions/admin/debts";

import { ADMIN_DEBTS } from "config/Api";

import { getServerErrorMessage } from "util/Errors";
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

export const fetchDebts = (filters, alertGroup) => {
    let { fromDate, toDate, client, daysOverdueMin, daysOverdueMax } = filters;
    fromDate = moment(fromDate).format("YYYY-MM-DD");
    toDate = moment(toDate).endOf("month").format("YYYY-MM-DD");

    store.dispatch(adminDebtsFetchStart());
    axios
        .get(ADMIN_DEBTS, {
            params: {
                from_date: fromDate,
                to_date: toDate,
            },
        })
        .then((resp) => {
            let data = resp.data;
            if (client) {
                data = data.filter((debt) => debt.id === client.value);
            }
            if (daysOverdueMin !== "") {
                let overdueMinNum = Number(daysOverdueMin);
                data = data.filter((debt) => debt.overdue >= overdueMinNum);
            }
            if (daysOverdueMax !== "") {
                let overdueMaxNum = Number(daysOverdueMax);
                data = data.filter((debt) => debt.overdue <= overdueMaxNum);
            }

            store.dispatch(adminDebtsFetchSuccess(data));
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(adminDebtsFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    `Võlgnevuste laadimine ebaõnnestus: ${errorMessage}`,
                    alertGroup,
                    true
                );
            }
        });
};
