import React from "react";

import {ReactComponent as DeleteIcon} from "assets/icons/delete.svg";

import "./DeleteText.scss";

export default function DeleteText({text, onClick, disabled}) {
    return (
        <div 
            className="cursor-pointer semibold-14"
            onClick={onClick}
            disabled={disabled}
        >
            <span className="align-middle color-error">{text}</span>
            <DeleteIcon className="ml-2 delete-text-icon"/>
        </div>
    );
}