import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import uniqueId from "lodash/uniqueId";
import accept from "attr-accept";
import { withTranslation, Trans } from "react-i18next";

import { Row, Col, Button, Input, Modal, ModalBody } from "reactstrap";

// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import FileUpload from "components/Form/Files/FileUpload";
import SmallLoader from "components/Loader/WrappedPulseLoader";
import { ReactComponent as RemoveIcon } from "assets/icons/close-inactive.svg";
import CloseTextIcon from "components/Misc/CloseTextIcon";

import Alerts from "components/Alerts/Alerts";
import { YEAR_MONTH_FORMAT_DATE_FN } from "util/DateTime";
import {
    clearIncludedGroups,
    addNewLocalWarningAlert,
    addNewLocalInfoAlert,
    clearLocalAlerts
} from "util/Alerts";

import reportUtils from "./utils";

import "./ImportModal.scss";

class ImportReturnReportModal extends React.Component {
    static propTypes = {
        // manual
        isOpen: PropTypes.bool.isRequired,
        details: PropTypes.object, // If given we update a specific report (only client's rows), if not we add/update bulk, need period
        onToggle: PropTypes.func.isRequired,
        onSuccess: PropTypes.func.isRequired,
        isDetailsView: PropTypes.bool,
        // defaults exist
        fileMaxSize: PropTypes.number,
        fileTypes: PropTypes.arrayOf(PropTypes.string), // Only array version because using [].includes
    }

    static defaultProps = {
        fileMaxSize: 5e6,
        fileTypes: [".xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"],
    }


    constructor(props) {
        super(props);
        this.state = {
            groupId: uniqueId("import-return-report-modal__"),
            importFile: null,
            isLoading: false,
            reportId: props.details?.id || null,
            periodStart: null,
            periodEnd: null,
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({
                periodStart: null,
                periodEnd: null,
                importFile: null
            })
        }
    }

    render() {
        const { t, isOpen, details, fileTypes, fileMaxSize } = this.props;
        const { importFile, periodStart, periodEnd, groupId, isLoading } = this.state;
        if (!isOpen) {
            return false;
        }

        const isUpdateSpecificReport = !!details?.id;
        const isPeriodFieldsFilled = !!periodStart && !!periodEnd;
        const isFileSelected = !!importFile;
        const title = t("reports.return.import.title", "Tagastusaruannete import");
        const canSubmit = isFileSelected && (isUpdateSpecificReport || isPeriodFieldsFilled);

        return (
            <Modal
                isOpen={isOpen}
                onToggle={this.onCancel}
                backdrop="static"
                className="import-return-report-modal"
            >
                <ModalBody className="">
                    <Row className="pb-2 header-section">
                        <Col>
                            <span className="modal-title">{title}</span>
                        </Col>
                        <Col className="float-right text-right col-auto">
                            <CloseTextIcon
                                text={t("buttons.close", "Sulge")}
                                onClick={this.onCancel}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts hideGlobal={true} filterGroups={[groupId]} />
                        </Col>
                    </Row>
                    {
                        isUpdateSpecificReport &&
                        <Row className="mb-2">
                            <Col>
                                <Input
                                    disabled
                                    type="text"
                                    value={`${details.client?.name} (${details.period_formatted})`}
                                    className="input-md"
                                />
                            </Col>
                        </Row>
                    }
                    {
                        !isUpdateSpecificReport &&
                        <Row className="mb-2 mx-n1">
                            <Col sm="6" className="mb-2 px-1">
                                <DatePicker
                                    disabled={isUpdateSpecificReport}
                                    placeholderText={t("reports.filter.from")}
                                    selected={periodStart}
                                    onChange={value => this.setState({ periodStart: value })}
                                    showMonthYearPicker
                                    maxDate={periodEnd || new Date()}
                                    dateFormat={YEAR_MONTH_FORMAT_DATE_FN}
                                />
                            </Col>
                            <Col sm="6" className="mb-2 px-1">
                                <DatePicker
                                    disabled={isUpdateSpecificReport}
                                    showMonthYearPicker
                                    placeholderText={t("reports.filter.to")}
                                    selected={periodEnd}
                                    onChange={value => this.setState({ periodEnd: value })}
                                    minDate={periodStart}
                                    maxDate={new Date()}
                                    dateFormat={YEAR_MONTH_FORMAT_DATE_FN}
                                />
                            </Col>
                        </Row>
                    }

                    <FileUpload
                        file={importFile}
                        onDrop={this.onDrop}
                        onDropRejected={this.onDropRejected}
                        onDropAccepted={this.onDropAccepted}
                        accept={fileTypes}
                        maxSize={fileMaxSize}
                        //dropzoneText="Drag 'n' drop a .xlsx sales report file or click to select it"
                        dropzoneText={t("reports.importSalesReportModal.dropzoneText", "Müügiaruande faili (.xlsx) lisamiseks lohista fail hiirega või vajuta kastile.")}

                    />
                    <Row className="selected-file-section">
                        <Col className="col-12 selected-file-header">
                            <span className="">{t("reports.importSalesReportModal.selectedFileHeader", "Valitud fail:")}</span>
                        </Col>

                        <Col >
                            <Row className={`selected-file ${importFile ? "" : "invisible"}`}>
                                <Col>
                                    <span>{importFile ? importFile.path : t("reports.importSalesReportModal.noFilePlaceholder", "Ühtegi faili ei ole valitud.")}</span>
                                </Col>
                                <Col className={`col-auto ${importFile ? "" : "invisible"}`}>
                                    <RemoveIcon
                                        className="ml-3 remove-file-button"
                                        onClick={this.onRemoveSelectedFile}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Button
                                color="link"
                                disabled={isLoading}
                                onClick={this.onCancel}
                                className="min-height-40 modal-cancel-button"
                            >
                                {t("reports.importSalesReportModal.cancelButton", "Tühista")}
                            </Button>
                        </Col>
                        <Col className="text-right">
                            <Button
                                color="success"
                                disabled={isLoading || !canSubmit}
                                onClick={this.importReturnReportsFromFile}
                                className="min-height-40 modal-import-button"
                            >
                                {isLoading ? <SmallLoader /> : t("reports.importSalesReportModal.importButton", "Import")}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }

    clearModalAlerts = () => {
        clearIncludedGroups(this.state.groupId);
    }

    importReturnReportsFromFile = () => {
        const { onSuccess, details } = this.props;
        const { reportId, periodStart, periodEnd, importFile, groupId } = this.state;
        clearLocalAlerts();
        this.setState({ isLoading: true });
        reportUtils.importFile(importFile, reportId, periodStart, periodEnd, groupId)
            .then(
                ({ isSuccess, statistics, errorMessage }) => {
                    if (isSuccess) {
                        onSuccess(statistics, details);
                    }
                }
            )
            .finally(() => {
                this.setState({ isLoading: false });
            });

    };

    onCancel = () => {
        if (this.state.isLoading) {
            return;
        }
        this.clearModalAlerts();
        this.props.onToggle(false);
    }

    onDrop = files => {
        this.clearModalAlerts();
    }

    onDropRejected = (rejectedFiles) => {
        const { t } = this.props;
        if (rejectedFiles.length > 1) {
            addNewLocalWarningAlert(t("reports.importSalesReportModal.alerts.warning.canOnlyDragOneFile", "Korraga võib tirida ainult ühe faili."), this.state.groupId);
            return;
        }
        const rejectedFile = rejectedFiles[0];
        if (rejectedFile.size > this.props.fileMaxSize) {
            const maxSizeMB = _.round(this.props.fileMaxSize / 1e6, 1);
            const message = <Trans i18nKey="reports.importSalesReportModal.alerts.warning.maxFileSize">Maksimaalne failimaht on {{ maxSizeMB }} MB.</Trans>
            addNewLocalWarningAlert(message, this.state.groupId);
        }
        if (!this.props.fileTypes.includes(rejectedFile.type)) {
        }

        const isValidMimeTypeOrExtension = accept(
            {
                name: rejectedFile.name,
                type: rejectedFile.type
            },
            this.props.fileTypes
        );

        if (!isValidMimeTypeOrExtension) {
            addNewLocalWarningAlert(t("reports.importSalesReportModal.alerts.warning.fileTypeMustBeXLSX", "Fail peab olema .xlsx failitüüpi."), this.state.groupId);
        }
    }

    onDropAccepted = acceptedFiles => {
        const acceptedFile = acceptedFiles[0];
        this.setState({
            importFile: acceptedFile
        });
        const message = <Trans i18nKey="reports.importSalesReportModal.alerts.info.fileAdded">Faili {{ fileName: acceptedFile.name }} lisamine õnnestus.</Trans>
        addNewLocalInfoAlert(message, this.state.groupId);

    }

    onRemoveSelectedFile = () => {
        this.clearModalAlerts();
        this.setState({
            importFile: null
        });
    }
}

const mapStateToProps = state => {
    return {
    }
};

const reduxActions = {
};

export default withRouter(connect(mapStateToProps, reduxActions)(withTranslation()(ImportReturnReportModal)));
