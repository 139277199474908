import React from "react";
import axios from "../axios";
import store from "redux/store";
import { Trans } from "react-i18next";
import {
    REST_AUTH_LOGIN,
    REST_AUTH_USER,
    REST_AUTH_LOGOUT,
    AUTH_ID_CARD_V2,
    AUTH_ID_CARD,
    AUTH_ID_CARD_VERIFY,
    AUTH_MOBILE_ID,
    AUTH_SMART_ID,
    SIGN_ID_CARD,
    SIGN_MOBILE_ID,
    SIGN_SMART_ID,
    DIGITAL_AUTH_STATUS,
    DIGITAL_SIGN_STATUS,
    RESET_PASSWORD,
    SIGN_ADMIN_SMART_ID,
    SIGN_ADMIN_MOBILE_ID,
    SIGN_ADMIN_ID_CARD,
} from "config/Api";
import { authFail, authSuccess, getUser, resetStore } from "redux/actions/auth";
import { logOut } from "../redux/actions/auth";
import { addNewLocalErrorAlert, clearLocalAlerts } from "./Alerts";
import { getServerErrorMessage } from "util/Errors";

export const authTypes = {
    ID: "id_card",
    MID: "mobile_id",
    SMART: "smart_id",
    EMAIL: "email",
};
export const LOGIN_ALERT_GROUP = "loginAlert";
export const SIGNING_ALERT_GROUP = "signingAlert";

export function idCardAuth(certificate) {
    // Deprecated
    clearLocalAlerts();

    return axios
        .post(AUTH_ID_CARD, { certificate: certificate })
        .then((resp) => {
            /*if (resp.data.code) {
                throw Error("Did not receive user object");
            }
            */

            return resp.data;
        })
        .catch((err) => {
            let errorMessage = getServerErrorMessage(err);
            throw Error(errorMessage);
        });
}

export function idCardAuthVerify(token, signature, userJson) {
    // Deprecated
    clearLocalAlerts();

    return axios
        .post(AUTH_ID_CARD_VERIFY, {
            token: token,
            signature_value: signature,
            user_json: userJson,
        })
        .then((resp) => {
            /*
            if (resp.data.code) {
                throw Error("Did not receive user object");
            }
            
            const userObject = resp.data;
            clearLocalAlerts();
            axios.defaults.headers.common['Authorization'] = `Token ${userObject.key}`;
            store.dispatch(authSuccess(`Token ${userObject.key}`));
            const expirationDate = new Date();
            expirationDate.setHours(expirationDate.getHours() + 24);
            //localStorage.removeItem('token');
            localStorage.clear();
            localStorage.setItem('token', `Token ${userObject.key}`);
            localStorage.setItem('expirationDate', expirationDate);

            setAuthUser(userObject);
            */

            return resp.data;
        })
        .catch((err) => {
            let errorMessage = getServerErrorMessage(err);
            throw Error(errorMessage);
        });
}

export function idCardAuthDokobitTokenVerify(dokobitAuthToken) {
    clearLocalAlerts();

    return axios
        .post(AUTH_ID_CARD_V2, {
            token: dokobitAuthToken
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            let errorMessage = getServerErrorMessage(err);
            throw Error(errorMessage);
        });
}

export function idCardAuthSuccess(data) {
    const userObject = data;
    clearLocalAlerts();
    axios.defaults.headers.common["Authorization"] = `Token ${userObject.key}`;
    store.dispatch(authSuccess(`Token ${userObject.key}`));
    const expirationDate = new Date();
    expirationDate.setHours(expirationDate.getHours() + 24);
    //localStorage.removeItem('token');
    localStorage.clear();
    localStorage.setItem("token", `Token ${userObject.key}`);
    localStorage.setItem("expirationDate", expirationDate);
    localStorage.setItem("authType", authTypes.ID);

    setAuthUser(userObject);
}

export function smartIdAuth(personal_code, country) {
    return axios
        .post(AUTH_SMART_ID, { personal_code: personal_code, country: country })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function mobileIdAuth(mobileNumber, personal_code) {
    return axios
        .post(AUTH_MOBILE_ID, {
            phone_number: mobileNumber,
            personal_code: personal_code,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}
//
// export function midAuthStart(mobileNumber) {
//     return axios
//         .post(MID_AUTH_START, {phone_nr: mobileNumber})
//         .then(resp => {
//             if (resp.data.success === true) {
//                 clearLocalAlerts()
//                 addNewLocalInfoAlert(`Teie kontrollkood on ${resp.data.challenge}. Veenduge, et näete mobiiltelefoni ekraanil täpselt sama numbrit.
//                 Seejärel sisestage M-ID PIN 1 kood.`, LOGIN_ALERT_GROUP);
//                 return resp.data
//             } else if (resp.data.success === false) {
//                 addNewLocalErrorAlert(`${resp.data.message}`, LOGIN_ALERT_GROUP);
//             }
//         })
//         .catch(err => {
//             throw err;
//         });
// }

export function digitalAuthStatus(token, mode, identifier) {
    return axios
        .get(
            DIGITAL_AUTH_STATUS +
                token +
                "/" +
                mode +
                "/" +
                encodeURIComponent(identifier)
        )
        .then((resp) => {
            if (resp.data.code === "ok") {
                axios.defaults.headers.common[
                    "Authorization"
                ] = `Token ${resp.data.user_object.key}`;
                store.dispatch(
                    authSuccess(`Token ${resp.data.user_object.key}`)
                );
                const expirationDate = new Date();
                expirationDate.setHours(expirationDate.getHours() + 24);

                localStorage.clear();
                localStorage.setItem("cert", resp.data.user_object.cert);
                localStorage.setItem(
                    "personal_code",
                    resp.data.user_object.personal_code
                );
                if (mode === authTypes.MID) {
                    localStorage.setItem("phoneNumberMID", identifier);
                }
                localStorage.setItem(
                    "token",
                    `Token ${resp.data.user_object.key}`
                );
                localStorage.setItem("expirationDate", expirationDate);
            } else if (resp.data.success === false) {
                //addNewLocalErrorAlert("Sisse logimisel ilmnes viga. Palun proovi uuesti.", LOGIN_ALERT_GROUP);
            }
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signMobileId(mobileNumber, personal_code, cert) {
    return axios
        .post(SIGN_MOBILE_ID, {
            phone_number: mobileNumber,
            personal_code: personal_code,
            cert: cert,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signAdminMobileId(
    mobileNumber,
    personal_code,
    cert,
    contract_id
) {
    return axios
        .post(SIGN_ADMIN_MOBILE_ID, {
            phone_number: mobileNumber,
            personal_code: personal_code,
            cert: cert,
            contract_id: contract_id,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signSmartId(personal_code, cert) {
    return axios
        .post(SIGN_SMART_ID, {
            phone_number: "",
            personal_code: personal_code,
            cert: cert,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signAdminSmartId(personal_code, cert, contract_id) {
    return axios
        .post(SIGN_ADMIN_SMART_ID, {
            phone_number: "",
            personal_code: personal_code,
            cert: cert,
            contract_id: contract_id,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signIdCard(personal_code, cert) {
    return axios
        .post(SIGN_ID_CARD, {
            phone_number: "",
            personal_code: personal_code,
            cert: cert,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function signAdminIdCard(personal_code, cert, contract_id) {
    return axios
        .post(SIGN_ADMIN_ID_CARD, {
            phone_number: "",
            personal_code: personal_code,
            cert: cert,
            contract_id: contract_id,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function digitalSignStatus(
    token,
    mode,
    signature_value,
    contractId = ""
) {
    return axios
        .post(DIGITAL_SIGN_STATUS, {
            token,
            mode,
            signature_value: signature_value,
            contract_id: contractId,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function login(username, password) {
    clearLocalAlerts();

    return axios
        .post(
            REST_AUTH_LOGIN,
            {
                email: username,
                password: password,
            },
            {
                headers: {
                    Authorization: ``,
                },
            }
        )
        .then((resp) => {
            axios.defaults.headers.common[
                "Authorization"
            ] = `Token ${resp.data.key}`;
            store.dispatch(authSuccess(`Token ${resp.data.key}`));
            const expirationDate = new Date();
            expirationDate.setHours(expirationDate.getHours() + 24);
            //localStorage.removeItem('token');
            localStorage.clear();
            localStorage.setItem("token", `Token ${resp.data.key}`);
            localStorage.setItem("expirationDate", expirationDate);
            localStorage.setItem("authType", authTypes.EMAIL);
            return true;
        })
        .catch((err) => {
            store.dispatch(authFail(err));
            addNewLocalErrorAlert(
                <Trans i18nKey="alerts.loginFailed">
                    Sisselogimine ebaõnnestus! Palun kontrolli andmeid.
                </Trans>,
                LOGIN_ALERT_GROUP
            );

            return false;
        });
}

export function logout() {
    return axios
        .post(REST_AUTH_LOGOUT)
        .then((resp) => {
            store.dispatch(logOut());
            localStorage.clear();
            axios.defaults.headers.common["Authorization"] = null;
        })
        .catch((err) => {
            throw err;
        });
}

export function requestFailed401() {
    store.dispatch(resetStore());
    localStorage.clear();
    addNewLocalErrorAlert(
        <Trans i18nKey="alerts.unexpectedErrorFromServer">
            Ootamatu viga serverile päringut tehes.
        </Trans>,
        LOGIN_ALERT_GROUP
    );
}

export function errorClearData(errorMessage) {
    store.dispatch(resetStore());
    localStorage.clear();

    if (errorMessage) {
        addNewLocalErrorAlert(errorMessage, LOGIN_ALERT_GROUP);
    }
}

export function setAuthUser(user) {
    store.dispatch(getUser(user));
}

export function getAuthUser() {
    return axios
        .get(REST_AUTH_USER)
        .then((resp) => {
            store.dispatch(getUser(resp.data));
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
}

export const sendPasswordResetLink = (email) => {
    return axios
        .post(RESET_PASSWORD, {
            email,
        })
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
};
