import React from 'react';
import { withRouter, matchPath } from "react-router-dom";
import { connect } from "react-redux";
import { Col, Container, Row } from "reactstrap";

import LoginCard from "components/Login/LoginCard/LoginCard";
import { authFail, authStart, authSuccess } from "../../redux/actions/auth";
import urls from "config/urls";


class LoginView extends React.Component {
    render() {
        let defaultAuth = "3";
        if (matchPath(this.props.location.pathname, {
            path: urls.LOGIN_WITH_EMAIL,
            exact: true,
            strict: true,
        })) {
            defaultAuth = "4";
        }
        return (
            <Container className="align-middle">
                <Row className="text-center justify-content-center">
                    <Col>
                        <LoginCard
                            error={this.props.error}
                            emailEnabled={true}
                            defaultAuth={defaultAuth}
                        />
                    </Col>
                </Row>
            </Container>
        );
    }
}

const mapStateToProps = state => {
    return {
        error: state.auth.error,
    }
};

export default withRouter(connect(mapStateToProps, { authStart, authFail, authSuccess })(LoginView));
