import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";

const initialFilters = {
    ean: "",
    client: null,
    name: "",
    category: null,
    type: null,
    volume: null,
    testResult: null,
    startDate: null,
    endDate: null,
};

const initialSorting = {
    dataField: "tested_at",
    order: "desc",
};

const getSizePerPageList = (dataSize) => {
    return [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ];
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: getSizePerPageList(0),
};

const initialState = {
    filters: initialFilters,
    sorting: initialSorting,
    pagination: initialPagination,
    unfilteredPackagesList: null,
    packagesList: null,
    packagesListFiltersUsed: initialFilters,
    packagesListLoading: false,
    loading: false,
    error: null,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const onPackagesTestedFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        packagesListLoading: true,
        error: null,
        packagesList: null,
        unfilteredPackagesList: null,
    });
};

const onPackagesTestedFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        packagesListLoading: false,
        error: null,
        packagesList: action.packagesList,
        pagination: {
            ...state.pagination,
            dataSize: action.packagesList.length,
        },
        packagesListFiltersUsed: action.filters,
        unfilteredPackagesList: action.unfilteredPackagesList,
    });
};

const onPackagesTestedFetchFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        packagesListLoading: false,
    });
};

const onPackagesTestedFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
        pagination: {
            ...state.pagination,
            page: 1,
        },
    });
};

const onPackagesTestedFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
        pagination: {
            ...state.pagination,
            page: 1,
        },
    });
};

const onPackagesTestedSortingUpdate = (state, action) => {
    return updateObject(state, {
        sorting: action.sorting,
    });
};

const onPackagesTestedPaginationUpdate = (state, action) => {
    return updateObject(state, {
        pagination: action.pagination,
    });
};

export const packagesTested = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.PACKAGES_TESTED_FETCH_START:
            return onPackagesTestedFetchStart(state, action);
        case actionTypes.PACKAGES_TESTED_FETCH_SUCCESS:
            return onPackagesTestedFetchSuccess(state, action);
        case actionTypes.PACKAGES_TESTED_FETCH_FAIL:
            return onPackagesTestedFetchFail(state, action);
        // FILTER
        case actionTypes.PACKAGES_TESTED_FILTER_UPDATE:
            return onPackagesTestedFilterUpdate(state, action);
        case actionTypes.PACKAGES_TESTED_FILTER_CLEAR:
            return onPackagesTestedFilterClear(state, action);
        // SORT
        case actionTypes.PACKAGES_TESTED_SORTING_UPDATE:
            return onPackagesTestedSortingUpdate(state, action);
        // PAGINATION
        case actionTypes.PACKAGES_TESTED_PAGINATION_UPDATE:
            return onPackagesTestedPaginationUpdate(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
