import * as actionTypes from 'redux/actions/actionTypes';

export const salesReportFetchStart = () => {
    return {
        type: actionTypes.SALES_REPORT_FETCH_START
    }
};

export const salesReportFetchSuccess = (report) => {
    return {
        type: actionTypes.SALES_REPORT_FETCH_SUCCESS,
        report: report
    }
};

export const salesReportFetchFail = (err) => {
    return {
        type: actionTypes.SALES_REPORT_FETCH_FAIL,
        error: err
    }
};

export const salesReportHeaderFieldChange = (field, value) => {
    return {
        type: actionTypes.SALES_REPORT_HEADER_FIELD_CHANGE,
        field: field,
        value: value,
    }
};

export const updateSalesReportHeaderTotals = (deposit_total, process_fee_total, process_fee_vat, to_be_paid) => {
    return {
        type: actionTypes.SALES_REPORT_HEADER_TOTALS_UPDATE,
        deposit_total, 
        process_fee_total, 
        process_fee_vat, 
        to_be_paid
    }
};

export const salesReportPackagesFieldChange = (id, field, value) => {
    return {
        type: actionTypes.SALES_REPORT_PACKAGES_FIELD_CHANGE,
        id: id,
        field: field,
        value: value,
    }
};

export const salesLineUpdateStart = (updatedPackage) => {
    return {
        type: actionTypes.SALES_LINE_UPDATE_START,
        updatedPackage,
    }
};

export const salesLineUpdateSuccess = (updatedPackage) => {
    return {
        type: actionTypes.SALES_LINE_UPDATE_SUCCESS,
        updatedPackage,
    }
};

export const salesLineUpdateFail = (updatedPackage, error) => {
    return {
        type: actionTypes.SALES_LINE_UPDATE_FAIL,
        updatedPackage,
        error: error,
    }
};

export const salesReportSubmitStart = () => {
    return {
        type: actionTypes.SALES_REPORT_SUBMIT_START
    }
};

export const salesReportSubmitSuccess = () => {
    return {
        type: actionTypes.SALES_REPORT_SUBMIT_SUCCESS,
    }
};

export const salesReportSubmitFail = (err) => {
    return {
        type: actionTypes.SALES_REPORT_SUBMIT_FAIL,
        error: err
    }
};

export const salesReportDeleteOriginalStart = () => {
    return {
        type: actionTypes.SALES_REPORT_DELETE_ORIGINAL_START,
    }
};

export const salesReportDeleteOriginalSuccess = () => {
    return {
        type: actionTypes.SALES_REPORT_DELETE_ORIGINAL_SUCCESS,
    }
};

export const salesReportDeleteOriginalFail = (err) => {
    return {
        type: actionTypes.SALES_REPORT_DELETE_ORIGINAL_FAIL,
        error: err,
    }
};

export const salesReportDeleteRepairStart = () => {
    return {
        type: actionTypes.SALES_REPORT_DELETE_REPAIR_START,
    }
};

export const salesReportDeleteRepairSuccess = () => {
    return {
        type: actionTypes.SALES_REPORT_DELETE_REPAIR_SUCCESS,
    }
};

export const salesReportDeleteRepairFail = (err) => {
    return {
        type: actionTypes.SALES_REPORT_DELETE_REPAIR_FAIL,
        error: err
    }
};

export const salesReportExportStart = () => {
    return {
        type: actionTypes.SALES_REPORT_EXPORT_START
    }
};

export const salesReportExportSuccess = () => {
    return {
        type: actionTypes.SALES_REPORT_EXPORT_SUCCESS,
    }
};

export const salesReportExportFail = (err) => {
    return {
        type: actionTypes.SALES_REPORT_EXPORT_FAIL,
        error: err
    }
};