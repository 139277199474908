import React, { Component } from 'react';
import PropTypes from "prop-types";
import { Input } from "reactstrap";


class MidForm extends Component {
    static propTypes = {
        handleChanged: PropTypes.func.isRequired
    }
    render() {
        const { handleChanged } = this.props;
        return (
            <>
                <p className="login-credential">MOBIILINUMBER</p>
                <Input
                    required
                    type="tel"
                    className="login-input"
                    name="mobileNumber"
                    onChange={handleChanged}
                    placeholder="Mobiilinumber"
                />
                <p className="login-credential">ISIKUKOOD</p>
                <Input
                    required
                    className="login-input"
                    type="number"
                    name="personalCode"
                    onChange={handleChanged}
                    placeholder="Isikukood"
                />
            </>
        );
    }
}

export default MidForm;
