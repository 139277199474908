import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import Search from "components/Admin/Reports/Search/Search";
import Table from "components/Admin/Reports/Table/Table";

import { fetchUnsubmittedSalesReports } from "util/admin/SalesReports";
import { SaleStatus, isSalesReportOrItsRepairReportHasStatuses } from "util/SalesReports";

import {
    adminUnsubmittedSalesReportsFilterUpdate,
    adminUnsubmittedSalesReportsFilterClear,
} from "redux/actions/admin/unsubmittedSalesReports";

class AdminUnsubmittedOrRejectedRepairReportsView extends React.Component {
    static propTypes = {
        salesReportsList: PropTypes.array,
        salesReportsListLoading: PropTypes.bool,
        salesReportsListFiltersUsed: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        adminUnsubmittedSalesReportsFilterUpdate: PropTypes.func.isRequired,
        adminUnsubmittedSalesReportsFilterClear: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.getSalesReportsList();
    }

    getSalesReportsList = (filters = null) => {
        if (filters == null) {
            filters = this.props.salesReportsListFiltersUsed;
        }
        fetchUnsubmittedSalesReports(filters, this.props.location.pathname);
    }


    render() {
        return (
            <>
                <Search
                    onSearch={this.getSalesReportsList}
                    filters={this.props.filters}
                    filtersUpdate={this.props.adminUnsubmittedSalesReportsFilterUpdate}
                    filtersClear={this.props.adminUnsubmittedSalesReportsFilterClear}
                />
                <Table
                    salesReportsList={this.filterUnconfirmedSalesReports(this.props.salesReportsList)}
                    salesReportsListLoading={this.props.salesReportsListLoading}
                />
            </>
        );
    }

    filterUnconfirmedSalesReports = (salesReports) => {
        if (!salesReports || !salesReports.length) {
            return salesReports;
        }

        return salesReports.filter(report => isSalesReportOrItsRepairReportHasStatuses(report, [SaleStatus.UNSUBMITTED, SaleStatus.REJECTED]));
    }
}

const reduxActions = {
    adminUnsubmittedSalesReportsFilterUpdate,
    adminUnsubmittedSalesReportsFilterClear,
}

const mapStateToProps = state => {
    return {
        salesReportsList: state.admin.unsubmittedSalesReports.salesReportsList,
        salesReportsListLoading: state.admin.unsubmittedSalesReports.salesReportsListLoading,
        salesReportsListFiltersUsed: state.admin.unsubmittedSalesReports.salesReportsListFiltersUsed,
        filters: state.admin.unsubmittedSalesReports.filters,
    }
};

export default withRouter(connect(mapStateToProps, reduxActions)(AdminUnsubmittedOrRejectedRepairReportsView));
