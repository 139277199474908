import React, { useState } from "react";
import { Translation } from "react-i18next";

import { Row, Col, Collapse } from "reactstrap";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up.svg";
import CompanyProfileForm from "components/Profile/Profile/CompanyProfileForm/CompanyProfileForm";
import { SIGNING_ALERT_GROUP } from "util/Auth";
import "../ContractModal.scss";


function CompanyCollapse(props) {
    const [collapse, setCollapse] = useState(true);
    const toggle = () => setCollapse(!collapse);

    return (
        <Row className="collapse-row">
            <Col md={12}>
                <Row onClick={toggle}>
                    <Col md={12}>
                        <span className="bold-19"><Translation>{t => t("contractModal.companyInfoFormHeader", "Äriregistri andmed")}</Translation></span>
                        {collapse ? <ArrowUp className="float-right" /> :
                            <ArrowDown className="float-right" />}
                    </Col>
                </Row>
                <Collapse isOpen={collapse} className="pt-4">
                    <CompanyProfileForm
                        contractMode={true}
                        alertGroup={SIGNING_ALERT_GROUP}
                        invalidFields={props.invalidFields}
                    />
                </Collapse>
            </Col>
        </Row>
    )
}

export default CompanyCollapse;
