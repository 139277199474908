import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import { Row, Col, FormGroup } from "reactstrap";
import CustomCheckbox from "../../../Form/Checkbox";
import WrapperLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import { fetchUserSettings, updateUserSettings } from "util/Profile";

import { addNewLocalErrorAlert, clearLocalAlerts } from "util/Alerts";
import { withTranslation } from "react-i18next";
import { isAdminMode } from "util/Access";

class UserSettingsForm extends Component {
    componentDidMount() {
        clearLocalAlerts();
        fetchUserSettings().catch((error) => {
            addNewLocalErrorAlert(
                "E-maili teavituste seadmete laadimine ebaõnnestus! " +
                error.message,
                this.props.location.pathname
            );
        });
    }

    handleChange = (key) => (value) => {
        clearLocalAlerts();
        const newUserSettings = {
            ...this.props.userSettings,
            [key]: value,
        };
        updateUserSettings(newUserSettings).catch((error) => {
            clearLocalAlerts();
            addNewLocalErrorAlert(
                this.props.t(
                    "profile.failedLoadingEmailNotifications",
                    "E-maili teavituste uuendamine ebaõnnestus! "
                ) + error.message,
                this.props.location.pathname
            );
        });
    };

    render() {
        const { t } = this.props;
        if (this.props.userSettingsLoading) {
            return (
                <CenteredRowCol>
                    <WrapperLoader />
                </CenteredRowCol>
            );
        }

        const isEmailNotifications = this.props.userSettings.is_email_notification;
        return (
            <Row>
                <Col className="col-12 pb-3">
                    {isAdminMode() &&
                        <CustomCheckbox
                            inline
                            value={isEmailNotifications}
                            labelText={`E-maili teavitused on ${isEmailNotifications ? "sisse" : "välja"} lülitatud`}
                            onClick={this.handleChange("is_email_notification")}
                        />
                    }
                </Col>
                <Col>
                    <FormGroup >
                        {isAdminMode() &&
                            <CustomCheckbox
                                inline
                                value={this.props.userSettings.is_contract}
                                labelText="Lepingud"
                                onClick={this.handleChange("is_contract")}
                                disabled={!isEmailNotifications}
                            />
                        }
                        <CustomCheckbox
                            inline
                            value={this.props.userSettings.is_debt}
                            labelText={t(
                                "profile.notifications.settlement",
                                "Arveldus"
                            )}
                            onClick={this.handleChange("is_debt")}
                            disabled={!isEmailNotifications}
                        />
                        <CustomCheckbox
                            inline
                            value={
                                this.props.userSettings.is_package_registration
                            }
                            labelText={t(
                                "profile.notifications.packagesRegistry",
                                "Pakendiregister"
                            )}
                            onClick={this.handleChange(
                                "is_package_registration"
                            )}
                            disabled={!isEmailNotifications}
                        />
                        <CustomCheckbox
                            inline
                            value={this.props.userSettings.is_sales_report}
                            labelText={t(
                                "profile.notifications.salesReports",
                                "Müügiaruanded"
                            )}
                            onClick={this.handleChange("is_sales_report")}
                            disabled={!isEmailNotifications}
                        />
                    </FormGroup>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        userSettings: state.profile.userSettings,
        userSettingsLoading: state.profile.userSettingsLoading,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(UserSettingsForm))
);
