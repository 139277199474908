import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';

const initialState = {
    invoices: [],
    invoicesLoading: false,
    balance: null,
    // Invoice preview
    invoice: null,
    invoiceLoading: false,
    invoiceErrorMessage: null,
};

const resetUserSpecificData = (state, action) => {
    return updateObject(state, {
        invoices: [],
        balance: null,
        invoice: null,
    });
}

const invoicesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        invoices: [],
        invoicesLoading: true,
        balance: null,
    })
};

const invoicesFetchSuccess = (state, invoicesData) => {
    return updateObject(state, {
        loading: false,
        invoices: invoicesData.invoices, 
        invoicesLoading: false,
        balance: invoicesData.invoices_balance,
    })
};

const invoicesFetchFail = (state, err) => {
    return updateObject(state, {
        loading: false,
        invoicesLoading: false,
        error: err
    })
};

const invoiceDataFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        invoice: null,
        invoiceLoading: true,
        invoiceErrorMessage: null,
    })
};

const invoiceDataFetchSuccess = (state, invoice) => {
    return updateObject(state, {
        loading: false,
        invoice: invoice,
        invoiceLoading: false,
    })
};

const invoiceDataFetchFail = (state, errorMessage) => {
    return updateObject(state, {
        loading: false,
        invoiceLoading: false,
        invoiceErrorMessage: errorMessage,
    })
};

const invoices = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INVOICES_FETCH_START:
            return invoicesFetchStart(state, action);
        case actionTypes.INVOICES_FETCH_SUCCESS:
            return invoicesFetchSuccess(state, action.data);
        case actionTypes.INVOICES_FETCH_FAIL:
            return invoicesFetchFail(state, action.error);

        case actionTypes.INVOICE_DATA_FETCH_START:
            return invoiceDataFetchStart(state);
        case actionTypes.INVOICE_DATA_FETCH_SUCCESS:
            return invoiceDataFetchSuccess(state, action.invoice);
        case actionTypes.INVOICE_DATA_FETCH_FAIL:
            return invoiceDataFetchFail(state, action.errorMesage);
        
            case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};

export default invoices;