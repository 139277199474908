import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import uniqueId from "lodash/uniqueId";

import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import { clearIncludedGroups } from "util/Alerts";

import reportUtils from "./utils";

class ConfirmReturnReportModal extends React.Component {
    static propTypes = {
        ids: PropTypes.array.isRequired,
        onSuccess: PropTypes.func,
        isOpen: PropTypes.bool,
        onToggle: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.state = {
            groupId: uniqueId("confirm-return-report-modal__"),
            isLoading: false,
        };
    }

    render() {
        const { t, isOpen, ids } = this.props;
        const { isLoading, groupId } = this.state;

        return (
            <>
                <ConfirmationModal
                    isOpen={isOpen}
                    onToggle={this.toggle}
                    onCancel={this.toggle}
                    onConfirm={this.onConfirm}
                    confirmButtonText={t("buttons.confirm", "Kinnita")}
                    title={t("reports.return.confirmReport", "Kinnita tagastusaruanne")}
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    alertGroups={[groupId]}
                >
                    <p className="regular-14">
                        {t(
                            "reports.return.confirmationDialog.confirmText",
                            "Oled kindel, et soovid {{ count }} tagastusaruannet kinnitada?",
                            { count: ids.length }
                        )}
                    </p>

                </ConfirmationModal>
            </>
        );
    }

    toggle = () => {
        const { isOpen, onToggle, isLoading } = this.props;
        if (isLoading) return;
        clearIncludedGroups(this.state.groupId);
        onToggle(!isOpen);
    }

    onConfirm = () => {
        const { ids, onSuccess } = this.props;
        const { groupId } = this.state;
        clearIncludedGroups(groupId);
        this.setState({ isLoading: true });
        reportUtils.confirmDetailsBulk(ids, groupId)
            .then(({ isSuccess, list }) => {
                if (isSuccess && !!onSuccess) {
                    onSuccess(list);
                }
            })
            .finally(() => {
                this.setState({ isLoading: false })
            });
    }
}

export default withTranslation()(ConfirmReturnReportModal)
