import * as actionTypes from "redux/actions/actionTypes";

// CATEGORIES
export const adminCategoriesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_CATEGORIES_FETCH_START,
    };
};

export const adminCategoriesFetchSuccess = (categories) => {
    return {
        type: actionTypes.ADMIN_CATEGORIES_FETCH_SUCCESS,
        categories,
    };
};

export const adminCategoriesFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_CATEGORIES_FETCH_FAIL,
        error,
    };
};

// MATERIALS
export const adminTypesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_TYPES_FETCH_START,
    };
};

export const adminTypesFetchSuccess = (types) => {
    return {
        type: actionTypes.ADMIN_TYPES_FETCH_SUCCESS,
        types,
    };
};

export const adminTypesFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_TYPES_FETCH_FAIL,
        error,
    };
};

// COLORS
export const adminColorsFetchStart = () => {
    return {
        type: actionTypes.ADMIN_COLORS_FETCH_START,
    };
};

export const adminColorsFetchSuccess = (colors) => {
    return {
        type: actionTypes.ADMIN_COLORS_FETCH_SUCCESS,
        colors,
    };
};

export const adminColorsFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_COLORS_FETCH_FAIL,
        error,
    };
};

// VOLUMES
export const adminVolumesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_VOLUMES_FETCH_START,
    };
};

export const adminVolumesFetchSuccess = (volumes) => {
    return {
        type: actionTypes.ADMIN_VOLUMES_FETCH_SUCCESS,
        volumes,
    };
};

export const adminVolumesFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_VOLUMES_FETCH_FAIL,
        error,
    };
};

// PROCESS FEES
export const adminProcessFeesFetchStart = () => {
    return {
        type: actionTypes.ADMIN_PROCESS_FEES_FETCH_START,
    };
};

export const adminProcessFeesFetchSuccess = (processFees) => {
    return {
        type: actionTypes.ADMIN_PROCESS_FEES_FETCH_SUCCESS,
        processFees,
    };
};

export const adminProcessFeesFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_PROCESS_FEES_FETCH_FAIL,
        error,
    };
};
