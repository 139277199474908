import React, {Component} from 'react';

import Alert from "components/Alerts/Alert/Alert";
import {alertType} from "util/Alerts";

class IdCardForm extends Component {   
    render() {
        return (
            <>
                {this.props.isAuthenticating &&
                    <Alert
                        type={alertType.INFO_ICON}
                        className={alertType.INFO_ICON}
                        content="Toimub ID-kaardiga sisselogimine. Kontrollige oma tegumiriba, kui sertifikaadi valimine ei kerki esile."
                    />
                }
            </>
        );
    }
}

export default IdCardForm;