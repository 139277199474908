import React from "react";
import PropTypes from "prop-types";
import i18n from "i18next";

import commentUtils from "./utils";

import Comment from "./Comment.jsx";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import { ReactComponent as ScrollBottomIcon } from "./icons/arrow-down-circle.svg";

import "./CommentList.scss";

function CommentsListEmpty({ comments, loading }) {
  if (loading || comments.length > 0) {
    return null;
  }
  return (
    <div className="comment-list__empty">
      {
        i18n.t("comments.no_comments_text", "Kommentaarid puuduvad")
      }
    </div>
  )
}

function CommentsListLoading({ loading }) {
  if (!loading) {
    return null;
  }
  return (
    <div className="comment-list__loading">
      <LoadingAnimation />
    </div>
  )
}

class CommentList extends React.Component {
  static propTypes = {
    comments: PropTypes.array.isRequired, // convert to sorted state.comments
    onDelete: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    listPrependContent: PropTypes.node
  }

  static defaultProps = {
    comments: [],
    loading: false,
    listPrependContent: null
  }

  constructor(props) {
    super(props);
    this.state = {
      isShowScrollBottom: false
    }

    this.listWrapperRef = React.createRef();
    this.delayedScrollTimeoutId = null;
  }

  componentDidMount() {
    this.listWrapperRef.current.addEventListener("scroll", this.handleScroll);
    this.scrollToBottom();
  }

  componentWillUnmount() {
    clearTimeout(this.delayedScrollTimeoutId);
    this.listWrapperRef.current.removeEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    // We should scroll to bottom when there is a newer comment loaded
    const prevLatestComment = commentUtils.getLatestComment(prevProps.comments)
    const currentLatestComment = commentUtils.getLatestComment(this.props.comments)
    // Make sure to default to empty string for str comparison sake.
    // Otherwise check would be "2022-..." > undefined which would be false.
    const prevLatestCreatedAt = prevLatestComment?.created_at || "";
    const currentLatestCreatedAt = currentLatestComment?.created_at || "";
    if (currentLatestCreatedAt > prevLatestCreatedAt) {
      this.delayScrollBottom();
    }

    if (this.props.loading && !prevProps.loading) {
      this.scrollToBottom();
    }
  }

  render() {
    return (
      <>
        <div
          ref={this.listWrapperRef}
          className="comment-list"
        >
          <div className="comment-list__content">
            {this.renderList()}
          </div>
          {this.renderScrollBottom()}
        </div>
      </>
    )
  }

  renderList() {
    const { comments, loading, onDelete } = this.props;

    const sortedComments = commentUtils.sortCommentsByCreatedAt(comments);
    return (
      <>
        {this.props.listPrependContent}
        {
          sortedComments.map(comment =>
            <Comment
              key={comment.id}
              onDelete={onDelete}
              {...comment}
            ></Comment>
          )
        }
        <CommentsListEmpty comments={sortedComments} loading={loading} />
        <CommentsListLoading loading={loading} />
      </>
    )
  }

  renderScrollBottom() {
    if (!this.state.isShowScrollBottom) return null;

    return (
      <div className="comment-list__scroll-bottom">
        <button
          onClick={this.scrollToBottom}
        >
          <ScrollBottomIcon />
        </button>

      </div>
    );
  }

  scrollToBottom = () => {
    const scrollHeight = this.listWrapperRef.current.scrollHeight;
    this.listWrapperRef.current.scrollTo({ top: scrollHeight, behavior: "smooth" });
  }

  delayScrollBottom() {
    // Calling from componentDidUpdate did not scroll down without delay.
    clearTimeout(this.delayedScrollTimeoutId);
    this.delayedScrollTimeoutId = setTimeout(() => this.scrollToBottom(), 500);
  }

  handleScroll = (event) => {
    const element = event.target;
    if (!element) return false;

    const { clientHeight, scrollHeight, scrollTop } = element;
    const hiddenHeight = scrollHeight - clientHeight;
    // Minimum pixels from bottom that need to be scrolled up.
    // Used to fix some calculation edge cases when there would be no minimum scrolled set.
    // Also helps with not showing the element when some pixels scrolled only.
    const scrollHeightDelay = 50;
    const hasScrolledUp = (scrollTop + scrollHeightDelay) < hiddenHeight;
    this.setState({ isShowScrollBottom: hasScrolledUp });
  }
}

export default CommentList;
