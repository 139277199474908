import React from "react";
import PropTypes from "prop-types";

import "./CheckboxButton.scss";
import {Button} from "reactstrap";

import {ReactComponent as Selected} from 'assets/icons/active.svg';
import {ReactComponent as Unselected} from 'assets/icons/inactive.svg';
import {ReactComponent as DisabledUnselected} from 'assets/icons/delete-row.svg';



class CustomCheckbox extends React.Component {
    static propTypes = {
        isSelected: PropTypes.any,
        labelText: PropTypes.any,
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
        disabled: PropTypes.bool,
        invalid: PropTypes.bool,
        extraProps: PropTypes.object,
    }

    static defaultProps = {
        isSelected: false,
        labelText: "",
        onClick: () => {},
        className: "",
        disabled: false,
        extraProps: {},
    }

    onClick = (event) => {
        this.props.onClick(!this.props.value);
    }

    render() {
        return (
            <Button
                {...this.props.extraProps}
                color=""
                className={`checkbox-button${this.props.isSelected ? " checkbox-button-selected" : ""}${this.props.invalid ? " custom-is-invalid" : ""} ${this.props.className}`}
                onClick={this.onClick}
                disabled={this.props.disabled}
            >
                {this.props.labelText}
                {this.props.isSelected ? 
                    <Selected className="selected-svg"/>
                    : 
                    this.props.disabled ? 
                        <DisabledUnselected className="disabled-unselected-svg" /> 
                        : 
                        <Unselected className="unselected-svg" />
                }
            </Button>
        )
    }
}

export default CustomCheckbox;

