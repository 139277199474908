import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { NavLink } from "reactstrap";
import { withTranslation } from "react-i18next";
import { changeLanguage } from "util/General";
import { languageTypes } from "util/General";
import { languageUpdate } from "redux/actions/language";
import _ from "lodash";

class LanguageMode extends React.Component {
    static propTypes = {
        userTypeId: PropTypes.number.isRequired,
        languageUpdate: PropTypes.func.isRequired,
    }

    updateLanguage = (languageCode) => {
        if (!this.props.userTypeId) {
            return;
        }
        // change backend language
        changeLanguage(this.props.userTypeId, languageCode);

        // change redux language
        this.props.languageUpdate(languageCode);
    }

    render() {
        const activeLanguage = this.props.language;
        return (
            <>
                <NavLink className="pointer pl-0 pr-2 py-0">
                    <span
                        className={
                            activeLanguage === languageTypes.ET
                                ? "semibold-14 active"
                                : "semibold-14"
                        }
                        onClick={() => this.updateLanguage(languageTypes.ET)}
                    >
                        ET
                    </span>
                </NavLink>
                <NavLink className="pointer pl-2 pr-0 py-0">
                    <span
                        className={
                            activeLanguage === languageTypes.EN
                                ? "semibold-14 active"
                                : "semibold-14"
                        }
                        onClick={() => this.updateLanguage(languageTypes.EN)}
                    >
                        ENG
                    </span>
                </NavLink>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userTypeId: _.get(state, "auth.user.active_profile_user_type_id", null),
        language: state.language,
    };
};

const mapActionsToProps = {
    languageUpdate,
}

export default connect(mapStateToProps, mapActionsToProps)(withTranslation()(LanguageMode));
