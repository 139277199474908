import * as actionTypes from 'redux/actions/actionTypes';

export const summaryReportSearchStart = () => {
    return {
        type: actionTypes.SUMMARY_REPORT_SEARCH_START
    }
};

export const summaryReportSearchSuccess = (summaryList, fromDate, toDate) => {
    return {
        type: actionTypes.SUMMARY_REPORT_SEARCH_SUCCESS,
        summaryList, fromDate, toDate,
    }
};

export const summaryReportSearchFail = (err) => {
    return {
        type: actionTypes.SUMMARY_REPORT_SEARCH_FAIL,
        error: err
    }
};

export const summaryReportSearchFilterUpdate = (key, value) => {
    return {
        type: actionTypes.SUMMARY_REPORT_SEARCH_FILTER_UPDATE,
        key: key,
        value: value,
    }
}

export const summaryReportExportStart = () => {
    return {
        type: actionTypes.SUMMARY_REPORT_EXPORT_START
    }
};

export const summaryReportExportSuccess = (exportFile) => {
    return {
        type: actionTypes.SUMMARY_REPORT_EXPORT_SUCCESS,
        exportFile: exportFile
    }
};

export const summaryReportExportFail = (err) => {
    return {
        type: actionTypes.SUMMARY_REPORT_EXPORT_FAIL,
        error: err
    }
};