import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col } from "reactstrap";
import { withTranslation } from "react-i18next";

import { ReactComponent as InactiveOverviewLogo } from "assets/icons/sidebar/inactive/1. overview.svg";
import { ReactComponent as InactiveTicketsLogo } from "assets/icons/sidebar/inactive/2. tickets.svg";

import urls from "config/urls";

import "components/Layout/Layout.scss";

function Footer({ children, t }) {
    const content = (
        <Row className="justify-content-sm-center regular-14 px-3">
            <Col xs="auto" className="my-1">
                <a
                    href={t("footer.termsAndConditions.link", "https://eestipandipakend.ee/dokumendid-ja-kujundusfailid/")}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <InactiveOverviewLogo />
                    {t("footer.termsAndConditions.text", "Pakendiettevõtja tingimused")}
                </a>
            </Col>
            <Col xs="auto" className="my-1">
                <NavLink to={urls.PRIVACY_POLICY}><InactiveTicketsLogo />{t("footer.privacyPolicy.text", "Privaatsuspoliitika")}</NavLink>
            </Col>
        </Row>
    );

    // invisible part quick hack to reduce overlap with content.

    return (
        <>
            <footer className="invisible mt-3">
                {content}
            </footer>
            <footer className="custom-footer">
                {content}
            </footer>
        </>
    )
}

export default withTranslation()(Footer);
