import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import onScan from "onscan.js";

import {
    packagesTestingAllFilterUpdate,
    packagesTestingAllFilterClear,
    packagesTestingAllSortingUpdate,
    packagesTestingAllPaginationUpdate,
} from "redux/actions/partner/packagesTestingAll";

import {
    fetchPackagesTestingAll,
} from "util/Partner";
import {
    clearLocalAlerts,
    addNewLocalSuccessAlert,
    addNewLocalErrorAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";

import Search from "components/Partner/Search/Search";
import Table from "components/Partner/Table/Table";
import ExcelDownload from "components/Partner/ExcelDownload/ExcelDownload";

import "./PackagesTestingAll.scss";


class PackagesTestingAllView extends React.Component {
    static propTypes = {
        // redux state
        packagesListLoading: PropTypes.bool,
        filters: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        packagesListFiltersUsed: PropTypes.object.isRequired,
        // redux actions
        packagesTestingAllFilterUpdate: PropTypes.func.isRequired,
        packagesTestingAllFilterClear: PropTypes.func.isRequired,
        packagesTestingAllSortingUpdate: PropTypes.func.isRequired,
        packagesTestingAllPaginationUpdate: PropTypes.func.isRequired,
    }

    datatableNodeRef = null;

    componentDidMount() {
        fetchPackagesTestingAll(this.props.packagesListFiltersUsed, this.props.location.pathname);

        onScan.attachTo(document, {
            suffixKeyCodes: [13], // enter-key expected at the end of a scan
            onScan: this.onScan, // Alternative to document.addEventListener('scan')
        });
    }

    componentWillUnmount() {
        onScan.detachFrom(document);
    }

    onScan = (sCode, iQty) => {
        clearLocalAlerts();
        const ean = sCode;

        this.props.packagesTestingAllFilterClear();
        this.props.packagesTestingAllFilterUpdate("ean", ean);

        let message = <div>Skänneeriti EAN <b>"{ean}"</b>.</div>;
        addNewLocalInfoAlert(message, this.props.location.pathname);
        fetchPackagesTestingAll(this.props.filters, this.props.location.pathname);
    }

    render() {
        return (
            <>
                <Search
                    filters={this.props.filters}
                    packagesListLoading={this.props.packagesListLoading}
                    onFilterUpdate={this.onFilterUpdate}
                    onClearFilters={this.onClearFilters}
                    onSearch={this.onSearch}
                    exportButton={
                        <ExcelDownload
                            packagesListFunc={this.getPackagesForExport}
                            filtersUsed={this.props.packagesListFiltersUsed}
                            fileName="EPP Partner - testitulemused"
                            sheetName="Testitulemused"
                            onError={this.onExcelError}
                            dateField="tested_at"
                        />
                    }

                />

                <Table
                    readOnly
                    packagesList={this.props.packagesList}
                    packagesListLoading={this.props.packagesListLoading}
                    onPackageTestSubmitSuccess={this.onPackageTestSubmitSuccess}
                    getDatatableNode={this.updateDatatableNode}
                    dateField="tested_at"
                    sorting={this.props.sorting}
                    pagination={this.props.pagination}
                    onPaginationChange={this.onPaginationChange}
                    onSort={this.onSort}
                />
            </>
        );
    }

    updateDatatableNode = node => {
        this.datatableNodeRef = node;
    }

    getPackagesForExport = () => {
        try {
            //return this.datatableNodeRef.table.props.data; // Currently displayed sorted rows in datatable.
            return this.datatableNodeRef.paginationContext.props.data // Sorted, also cointains hidden rows hidden by pagination.
        } catch (err) {
            return [];
        }
    }

    onExcelError = () => {
        clearLocalAlerts();
        addNewLocalErrorAlert("Excel faili loomine ebaõnnestus!", this.props.location.pathname, true)
    }

    onPackageTestSubmitSuccess = packageTest => {
        clearLocalAlerts();
        addNewLocalSuccessAlert(`Pakendi test edukalt esitatud ${packageTest.status.label} staatusega.`, this.props.location.pathname);
        fetchPackagesTestingAll(this.props.packagesListFiltersUsed, this.props.location.pathname);
    }

    onFilterUpdate = (key, value) => {
        this.props.packagesTestingAllFilterUpdate(key, value);
    }

    onClearFilters = () => {
        //clearLocalAlerts();
        this.props.packagesTestingAllFilterClear();
    }

    onSearch = () => {
        clearLocalAlerts();
        fetchPackagesTestingAll(this.props.filters, this.props.location.pathname);
    }

    onPaginationChange = (page, sizePerPage) => {
        const currentPagination = this.props.pagination;
        const newPagination = { ...currentPagination, page, sizePerPage };
        this.props.packagesTestingAllPaginationUpdate(newPagination);

    }

    onSort = (dataField, order) => this.props.packagesTestingAllSortingUpdate({ dataField, order });
}

const mapStateToProps = state => {
    return {
        packagesList: state.partner.packagesTestingAll.packagesList,
        packagesListLoading: state.partner.packagesTestingAll.packagesListLoading,
        packagesListFiltersUsed: state.partner.packagesTestingAll.packagesListFiltersUsed,
        filters: state.partner.packagesTestingAll.filters,
        sorting: state.partner.packagesTestingAll.sorting,
        pagination: state.partner.packagesTestingAll.pagination,
    }
}

const reduxActions = {
    packagesTestingAllFilterUpdate,
    packagesTestingAllFilterClear,
    packagesTestingAllSortingUpdate,
    packagesTestingAllPaginationUpdate,
}

export default connect(mapStateToProps, reduxActions)(PackagesTestingAllView);
