import React from "react";
import { withRouter } from "react-router-dom";

import DateCell from "components/Table/Cell/DateCell";
import "./MonthPeriodCell.scss";
import { getSaleReportUrl, getSaleReportFixUrl } from "config/urls";
import { parseSalesReportPeriodDate, salesReportPeriodListFormat } from "util/DateTime";

function MonthPeriodCell(props) {
    const id = props.salesReport.id;
    let route;
    if (props.salesReport.is_repair) {
        route = getSaleReportFixUrl(id);
    } else {
        route = getSaleReportUrl(id);
    }

    return (
        <>
            <DateCell
                format={salesReportPeriodListFormat}
                className="sales-month-period-cell semibold-14"
                onClick={
                    (e) => {
                        e.stopPropagation();
                        props.history.push(route);
                    }
                }
                value={parseSalesReportPeriodDate(props.salesReport.period)}
            />
        </>
    );
}

export default withRouter(MonthPeriodCell);
