import React from "react";
import axios from "../../axios";
import store from "redux/store";
import moment from "moment";
import { Trans } from "react-i18next";

import urls, {ID_PLACEHOLDER} from "config/urls";
import { getServerErrorMessage, getErrorMessageList } from "util/Errors";
import { addNewLocalErrorAlert, addNewLocalSuccessAlert, clearLocalAlerts } from "util/Alerts";

import { isAdminMode } from "util/Access";

import StatusCell, {statusTypes} from "components/Table/Cell/Status/StatusCell";

import {
    actions, STATUSES, filterNavStatusOptions
} from "./redux";

const _COMMON_PATH = "additional-deposit-reports";

export function getDetailsViewUrl(id) {
    let path;
    if (isAdminMode()) {
        path = urls.EPP_REPORTS_ADDITIONAL_DEPOSIT_DETAILS;
    } else {
        path = urls.REPORTS_ADDITIONAL_DEPOSIT_DETAILS;
    }
    return path.replace(ID_PLACEHOLDER, id);
}

export function getListViewUrl() {
    if (isAdminMode()) {
        return urls.EPP_REPORTS_ADDITIONAL_DEPOSIT_LIST;
    } else {
        return urls.REPORTS_ADDITIONAL_DEPOSIT_LIST;
    }
}

export function fetchReportsList(filters, sorting, pagination, alertGroup = null) {
    store.dispatch(actions.listFetchStart());
    const params = {
        ordering: createSortingQueryParameter(sorting),
        page: pagination.page,
        page_size: pagination.sizePerPage,
        ...createFilteringQueryParameters(filters),
    };
    return axios
        .get(`${_COMMON_PATH}/`, {params})
        .then((resp) => {
            const {data} = resp;
            const list = data.results;
            const dataSize = data.count;
            
            store.dispatch(
                actions.listFetchSuccess(list, dataSize, filters)
            );

            return { isSuccess: true, list };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(actions.listFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.additionalDeposit.alerts.fetchListFailed">
                        Aruannete laadimine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function fetchDetails(id, alertGroup = null) {
    // Details view data managed in component instead of through redux.
    return axios
        .get(`${_COMMON_PATH}/${id}/`)
        .then((resp) => {
            return { isSuccess: true, details: resp.data };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.additionalDeposit.alerts.fetchListFailed">
                        Aruannete laadimine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function deleteDetails(id, alertGroup = null) {
    return axios
        .delete(`${_COMMON_PATH}/delete/${id}/`)
        .then((resp) => {
            return { isSuccess: true, list: resp.data };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.additionalDeposit.alerts.deleteListFailed">
                        Aruannete kustutamine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function syncReports(id, alertGroup = null) {
    let endpoint;
    if (id) {
        endpoint = `${_COMMON_PATH}/sync/${id}/`;
    } else {
        endpoint = `${_COMMON_PATH}/sync/`;
    }
    
    return axios
        .post(endpoint)
        .then((resp) => {
            return { isSuccess: true, statistics: resp.data, errorMessage: undefined };
        })
        .catch((err) => {
            let errorMessage, errorContent;
            
            //Can expect a list of validation errors if failing to parse specific rows.
            if (err?.response?.status === 400 && Array.isArray(err?.response?.data)) {
                const errorContentFunc = (error) => {
                    return error?.code || "Unexpected error (Ootamatu viga)"
                }
                const topText = <Trans i18nKey="reports.additionalDeposit.alerts.syncFailedList">Aruande sünkroniseerimine ebaõnnestus:</Trans>
                errorMessage = getErrorMessageList(err.response.data, errorContentFunc, topText);
                errorContent = errorMessage;
            } else {
                errorMessage = getServerErrorMessage(err);
                errorContent = (
                    <Trans i18nKey="reports.additionalDeposit.alerts.syncFailed">
                        Aruande sünkroniseerimine ebaõnnestus: {{ errorMessage }}
                    </Trans>
                );
                
            }
            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    errorContent,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage, statistics: undefined };
        });
}

const createSortingQueryParameter = (sorting) => {
    const {order, dataField} = sorting;
    const orderMapping = {
        asc: "",
        desc: "-"
    };
    const fieldMapping = {
        "client.name": "client__name",
        "period": "period",
        "is_posted": "is_posted"
    };

    return `${orderMapping[order]}${fieldMapping[dataField] || dataField}`;
};

function ReportStatus(props) {
    const {details, inlineStyle} = props;
    let type, label;
    if (!details?.id) return null;
    if (details.is_posted === STATUSES.POSTED_FALSE) {
        type = statusTypes.WARNING;
        label = <Trans i18nKey="reports.additionalDeposit.status.postedFalse"></Trans>;
    } 
    else if (details.is_posted === STATUSES.POSTED_TRUE) {
        type = statusTypes.SUCCESS;
        label = <Trans i18nKey="reports.additionalDeposit.status.postedTrue"></Trans>;
    } else {
        // Shouldn't happen
        type = statusTypes.DEFAULT;
        label = "-";
    }
    return (
        <StatusCell
            style={inlineStyle}
            status={label}
            type={type}
            className="status-cell"
        />
    )
}

function ReportSyncSuccess({statistics, topText}) {
    if (!topText) {
        topText = (
        <Trans i18nKey="reports.additionalDeposit.alerts.syncBulkSuccess">
            Aruannete importimine õnnestus
        </Trans>
        );
    }
    const statisticsMessage = [
        `${statistics.client_count} klienti läbiti kokku`,
        `${statistics.filter_historic} aruannet jäeti vahele, sest need olid liiga vanad`,
        `${statistics.report_count} aruannet läbiti klientidel kokku`,
        `${statistics.invalid_contract} klienti jäeti vahele kuna leping ei ole aktiivne`,
        `${statistics.client_request_error} kliendi aruanded jäid uuendamata, sest kliendi aruannete nimekirja päring NAV-ist ebaõnnestus`,
        `${statistics.report_request_error} aruannet jäi uuendamata, sest aruande päring NAV-ist ebaõnnestus`,
        `${statistics.report_unexpected} aruannet jäi uuendamata aruande päringu ootamatute väljaväärtuste tõttu`,
        `${statistics.report_is_posted} aruannet jäi sünkroniseerimata, sest juba kinnitatud`,
        //`${statistics.report_invoice}`,
        `${statistics.lines} aruanderida jäeti vahele, sest pakendit ei leitud (nav_id + client_id)`
    ]
    return getErrorMessageList(statisticsMessage, message => message, topText);
}

const createFilteringQueryParameters = (filters) => {
    const addFilteringFieldToParams = (params, queryParameter, value) => {
        return {
            ...params,
            [queryParameter]: value,
        };
    };
    let params = {};
    if (filters.client) {
        const value = filters.client.value;
        params = addFilteringFieldToParams(params, "client", value);
    }
    if (filters.fromDate) {
        const value = moment(filters.fromDate).format("YYYY-MM")
        params = addFilteringFieldToParams(params, "period_start", value);
    }
    if (filters.toDate) {
        const value = moment(filters.toDate).format("YYYY-MM")
        params = addFilteringFieldToParams(params, "period_end", value);
    }
    if (filters.is_posted) {
        const selectedValue = filters.is_posted.value;
        if (Object.values(STATUSES).includes(selectedValue))
            params = addFilteringFieldToParams(params, "is_posted", selectedValue);
    }

    return params;
};

export default {
    //endpoints
    getDetailsViewUrl,
    getListViewUrl,
    fetchReportsList,
    fetchDetails,
    deleteDetails,
    syncReports,
    // components
    ReportStatus,
    ReportSyncSuccess,
    // other
    STATUSES,
    actions,
    filterNavStatusOptions,
}
