import React, { Component } from "react";
import { connect } from "react-redux";

import { fetchAdminSettingsTypes } from "util/admin/Settings";
import { currencyFormatter } from "util/General";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import WrappedLoader from "components/Loader/WrappedLoader";

export class Types extends Component {
    componentDidMount() {
        if (this.props.types.length === 0) {
            fetchAdminSettingsTypes();
        }
    }

    static NoDataIndication(props) {
        let content = "Ei leitud kasutajaid.";
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    render() {
        const columns = [
            {
                dataField: "value",
                text: "Tähis",
                sort: true,
                classes: "semibold-14",
            },
            {
                dataField: "cost",
                text: "Hind",
                sort: true,
                align: "right",
                headerAlign: "right",
                formatter: (value) => currencyFormatter.format(value),
            },
        ];

        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <span className="heading">Pakendite tüübid</span>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0">
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="table-layout-auto no-top-border"
                        wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                        keyField="id"
                        data={this.props.types}
                        columns={columns}
                        noDataIndication={
                            <Types.NoDataIndication
                                loading={this.props.loading}
                            />
                        }
                    />
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        types: state.admin.settings.types,
        loading: state.admin.settings.loading,
    };
};

export default connect(mapStateToProps)(Types);
