import React from "react";
import axios from "../../axios";
import store from "redux/store";
import moment from "moment";
import { Trans } from "react-i18next";

import urls, {ID_PLACEHOLDER} from "config/urls";
import { getServerErrorMessage, getErrorMessageList } from "util/Errors";
import { addNewLocalErrorAlert, addNewLocalSuccessAlert, clearLocalAlerts } from "util/Alerts";

import { downloadFileBase64 } from "util/Files";
import { isAdminMode } from "util/Access";

import StatusCell, {statusTypes} from "components/Table/Cell/Status/StatusCell";

import {
    actions, filterConfirmedOptions
} from "./redux";

export function getDetailsViewUrl(id) {
    let path;
    if (isAdminMode()) {
        path = urls.EPP_REPORTS_RETURN_DETAILS;
    } else {
        path = urls.REPORTS_RETURN_DETAILS;
    }
    return path.replace(ID_PLACEHOLDER, id);
}

export function getListViewUrl() {
    if (isAdminMode()) {
        return urls.EPP_REPORTS_RETURN_LIST;
    } else {
        return urls.REPORTS_RETURN_LIST;
    }
}

export function fetchReportsList(filters, sorting, pagination, alertGroup = null) {
    store.dispatch(actions.listFetchStart());
    const params = {
        ordering: createSortingQueryParameter(sorting),
        page: pagination.page,
        page_size: pagination.sizePerPage,
        ...createFilteringQueryParameters(filters),
    };
    return axios
        .get("return-reports/", {params})
        .then((resp) => {
            const {data} = resp;
            const list = data.results;
            const dataSize = data.count;
            
            store.dispatch(
                actions.listFetchSuccess(list, dataSize, filters)
            );

            return { isSuccess: true, list };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(actions.listFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.return.alerts.fetchListFailed">
                        Aruannete laadimine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function fetchDetails(id, alertGroup = null) {
    // Details view data managed in component instead of through redux.
    return axios
        .get(`return-reports/${id}/`)
        .then((resp) => {
            return { isSuccess: true, details: resp.data };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.return.alerts.fetchListFailed">
                        Aruannete laadimine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function confirmDetailsBulk(ids, alertGroup = null) {
    const body = {
        ids
    };
    return axios
        .post("return-reports/bulk-confirm/", body).then((resp) => {
            return { isSuccess: true, list: resp.data };
        })
        .catch((err) => {
            let errorMessage, errorContent;
            const isListOfValidationErrors = err?.response?.status === 400 && Array.isArray(err?.response?.data);
            if (isListOfValidationErrors) {
                const errorContentFunc = (error) => {
                    return error?.code || "Unexpected error (Ootamatu viga)";
                }
                const topText = <Trans i18nKey="reports.return.alerts.confirmBulkFailed">Tagastuaruannete kinnitamine ebaõnnestus:</Trans>
                errorMessage = getErrorMessageList(err.response.data, errorContentFunc, topText);
                errorContent = errorMessage;
            } else {
                errorMessage = getServerErrorMessage(err);
                    errorContent = 
                    <Trans i18nKey="reports.return.alerts.confirmBulkFailed">
                        Tagastusaruannete kinnitamine ebaõnnestus: {{ errorMessage }}
                    </Trans>;
            }
            if (alertGroup) {
                clearLocalAlerts();
                
                addNewLocalErrorAlert(
                    errorContent,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function deleteDetails(id, alertGroup = null) {
    return axios
        .delete(`return-reports/delete/${id}/`)
        .then((resp) => {
            return { isSuccess: true, list: resp.data };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.return.alerts.deleteListFailed">
                        Aruannete kustutamine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function importFile(file, id, periodStart, periodEnd, alertGroup = null) {
    let endpoint;
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("name", file.name);
    if (id) {
        endpoint = `return-reports/import/${id}/`;
    } else {
        endpoint = "return-reports/import/";
        // toJSON would timezone it to last month
        formdata.append("period_start", moment(periodStart).startOf("month").format("YYYY-MM-DD"));
        formdata.append("period_end", moment(periodEnd).startOf("month").format("YYYY-MM-DD"));
    }
    
    return axios
        .post(endpoint, formdata)
        .then((resp) => {
            return { isSuccess: true, statistics: resp.data, errorMessage: undefined };
        })
        .catch((err) => {
            let errorMessage, errorContent;
            
            //Can expect a list of validation errors if failing to parse specific rows.
            if (err?.response?.status === 400 && Array.isArray(err?.response?.data)) {
                const errorContentFunc = (error) => {
                    return error?.code || "Unexpected error (Ootamatu viga)"
                }
                const topText = <Trans i18nKey="reports.return.alerts.importFailedList">Tagastusaruande importimine ebaõnnestus:</Trans>
                errorMessage = getErrorMessageList(err.response.data, errorContentFunc, topText);
                errorContent = errorMessage;
            } else {
                errorMessage = getServerErrorMessage(err);
                errorContent = (
                    <Trans i18nKey="reports.return.alerts.importFailed">
                        Tagastusaruande importimine ebaõnnestus: {{ errorMessage }}
                    </Trans>
                );
                
            }
            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    errorContent,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage, statistics: undefined };
        });
}

export function fetchExportFile(id) {
    const url = `return-reports/export/${id}/`;
    return axios
        .get(url)
        .then((resp) => {
            const {filename, base64} = resp.data;
            downloadFileBase64(base64, filename);
            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            throw Error(errorMessage);
        });
}

const createSortingQueryParameter = (sorting) => {
    const {order, dataField} = sorting;
    const orderMapping = {
        asc: "",
        desc: "-"
    };
    const fieldMapping = {
        "client.name": "client__name",
        "period_start": "period_start",
        "period_end": "period_end",
        "confirmed_at": "confirmed_at"
    };

    return `${orderMapping[order]}${fieldMapping[dataField] || dataField}`;
};

function ReturnReportImportSuccess({statistics, topText}) {
    if (!topText) {
        topText = (
        <Trans i18nKey="reports.return.alerts.importBulkSuccess">
            Tagastusaruannete importimine õnnestus
        </Trans>
        );
    }
    const statisticsMessage = [
        `Loodi ${statistics.reports_created} tagastusaruannet`,
        `Loodi ${statistics.lines_created} aruanderida`,
        `Uuendati ${statistics.reports_updated} tagastusaruannet`,
        `Uuendati ${statistics.lines_updated} aruanderida`,
        `Kustutati ${statistics.lines_deleted} aruanderida.`,
    ]
    return getErrorMessageList(statisticsMessage, message => message, topText);
}

function ReturnReportStatus(props) {
    const {details, inlineStyle} = props;
    let type, label;
    if (!details?.id) return null;
    if (details.confirmed_at === null) {
        type = statusTypes.WARNING;
        label = <Trans i18nKey="reports.return.status.unconfirmed">Kinnitamata</Trans>;
    } else {
        type = statusTypes.SUCCESS;
        label = <Trans i18nKey="reports.return.status.confirmed">Kinnitatud</Trans>;
    }
    return (
        <StatusCell
            style={inlineStyle}
            status={label}
            type={type}
            className="status-cell"
        />
    )
}

const createFilteringQueryParameters = (filters) => {
    const addFilteringFieldToParams = (params, queryParameter, value) => {
        return {
            ...params,
            [queryParameter]: value,
        };
    };
    let params = {};
    if (filters.client) {
        const value = filters.client.value;
        params = addFilteringFieldToParams(params, "client", value);
    }
    if (filters.fromDate) {
        const value = moment(filters.fromDate).format("YYYY-MM")
        params = addFilteringFieldToParams(params, "period_start", value);
    }
    if (filters.toDate) {
        const value = moment(filters.toDate).format("YYYY-MM")
        params = addFilteringFieldToParams(params, "period_end", value);
    }
    if (filters.confirmed) {
        const selectedValue = filters.confirmed.value;
        let filterValue;
        if (selectedValue === true) filterValue = "true";
        else if (selectedValue === false) filterValue = "false";
        else filterValue = "all";
        params = addFilteringFieldToParams(params, "confirmed", filterValue);
    }

    return params;
};

export default {
    //endpoints
    getDetailsViewUrl,
    getListViewUrl,
    fetchReportsList,
    fetchDetails,
    confirmDetailsBulk,
    deleteDetails,
    importFile,
    fetchExportFile,
    // components
    ReturnReportStatus,
    ReturnReportImportSuccess,
    // other
    actions,
    filterConfirmedOptions,
}
