import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../utility';
import {alertContext, createAlertObject} from "util/Alerts";
// import {alertType} from "util/Alerts";
const initialState = {
  alertsList: [
      /*
    createAlertObject(alertContext.GLOBAL, alertType.DEFAULT, "TEST_GROUP", "GLOBAL - Test DEFAULT"),
    createAlertObject(alertContext.GLOBAL, alertType.DEFAULT_ICON, "TEST_GROUP_ICON", "GLOBAL - Test DEFAULT"),
    createAlertObject(alertContext.LOCAL, alertType.SUCCESS, "/reports/sales", "LOCAL - Test SUCCESS, group /reports/sales"),
    createAlertObject(alertContext.LOCAL, alertType.SUCCESS_ICON, "TEST_GROUP_ICON", "LOCAL - Test SUCCESS"),
    createAlertObject(alertContext.LOCAL, alertType.INFO, "TEST_GROUP", "LOCAL - Test INFO"),
    createAlertObject(alertContext.LOCAL, alertType.INFO_ICON, "TEST_GROUP_ICON", "LOCAL - Test INFO"),
    createAlertObject(alertContext.LOCAL, alertType.WARNING, "TEST_GROUP", "LOCAL - Test WARNING"),
    createAlertObject(alertContext.LOCAL, alertType.WARNING_ICON, "TEST_GROUP_ICON", "LOCAL - Test WARNING"),
    createAlertObject(alertContext.LOCAL, alertType.ERROR, "TEST_GROUP", "LOCAL - Test ERROR"),
    createAlertObject(alertContext.LOCAL, alertType.ERROR_ICON, "TEST_GROUP_ICON", "LOCAL - Test ERROR"),
    */
  ],
  activeGroups: [alertContext.GLOBAL],
};

const alertsUpdateActiveGroups = (state, groups) => {
    return updateObject(state, {
        activeGroups: [alertContext.GLOBAL, ...groups],
    })
};

const alertsAddNew = (state, action) => {
    const newAlert = createAlertObject(action.context, action.alertType, action.group, action.content);
    return updateObject(state, {
        alertsList: [...state.alertsList, newAlert]
    })
};
/*
const alertsAddLocal = (state, action) => {
    const newAlert = createAlertObject(action.alertContext, action.alertType, action.content);
    return updateObject(state, {
        local: [...state.global, newAlert]
    })
};
*/
const alertsClearAll = (state, action) => {
    return updateObject(state, {
        alertsList: [],
    })
};

const alertsClearLocal = (state) => {
    const filteredList = state.alertsList
        .filter(alert => alert.context === alertContext.GLOBAL);
    return updateObject(state, {
        alertsList: filteredList,
    })
};

const alertsClearIncludedGroups = (state, groups) => {
    const filteredList = state.alertsList
        .filter(alert => alert.context === alertContext.GLOBAL || !groups.includes(alert.group));
    return updateObject(state, {
        alertsList: filteredList,
    })
};

const alertsClearUnincludedGroups = (state, groups) => {
    const filteredList = state.alertsList
        .filter(alert => alert.context === alertContext.GLOBAL || groups.includes(alert.group));
    return updateObject(state, {
        alertsList: filteredList,
    })
};


const alerts = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.ALERTS_ADD_GLOBAL:
        case actionTypes.ALERTS_ADD_LOCAL:
            return alertsAddNew(state, action);
        case actionTypes.ALERTS_CLEAR_ALL:
            return alertsClearAll(state);
        case actionTypes.ALERTS_CLEAR_LOCAL:
            return alertsClearLocal(state);
        case actionTypes.ALERTS_CLEAR_INCLUDED_GROUPS:
            return alertsClearIncludedGroups(state, action.groups);
        case actionTypes.ALERTS_CLEAR_UNINCLUDED_GROUPS:
            return alertsClearUnincludedGroups(state, action.groups);
        case actionTypes.ALERTS_UPDATE_ACTIVE_GROUPS:
            return alertsUpdateActiveGroups(state, action.groups);

        default:
            return state;
    }
};

export default alerts;