import * as actionTypes from 'redux/actions/actionTypes';

export const adminDebtsFetchStart = () => {
    return {
        type: actionTypes.ADMIN_DEBTS_FETCH_START
    }
};

export const adminDebtsFetchSuccess = (debts) => {
    return {
        type: actionTypes.ADMIN_DEBTS_FETCH_SUCCESS,
        debts
    }
};

export const adminDebtsFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_DEBTS_FETCH_FAIL,
        error,
    }
};

export const adminDebtsFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_DEBTS_FILTER_UPDATE,
        key: key,
        value: value,
    }
}

export const adminDebtsFilterClear = () => {
    return {
        type: actionTypes.ADMIN_DEBTS_FILTER_CLEAR,
    }
}