import * as actionTypes from "redux/actions/actionTypes";
import { updateObject } from "redux/utility";

const initialFilters = {
    ean: "",
    client: null,
    name: "",
    category: null,
    type: null,
    volume: null,
    testResult: null,
    startDate: null,
    endDate: null,
};

const initialSorting = {
    dataField: "tested_at",
    order: "desc",
};

const getSizePerPageList = (dataSize) => {
    return [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ];
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: getSizePerPageList(0),
};

const initialState = {
    filters: initialFilters,
    sorting: initialSorting,
    pagination: initialPagination,
    unfilteredPackagesList: null,
    packagesList: null,
    packagesListFiltersUsed: initialFilters,
    packagesListLoading: false,
    loading: false,
    error: null,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const onPackagesInTestFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        packagesListLoading: true,
        error: null,
        packagesList: null,
        unfilteredPackagesList: null,
    });
};

const onPackagesInTestFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        packagesListLoading: false,
        error: null,
        packagesList: action.packagesList,
        pagination: {
            ...state.pagination,
            dataSize: action.packagesList.length,
        },
        packagesListFiltersUsed: action.filters,
        unfilteredPackagesList: action.unfilteredPackagesList,
    });
};

const onPackagesInTestFetchFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        packagesListLoading: false,
    });
};

const onPackagesInTestFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
        pagination: {
            ...state.pagination,
            page: 1,
        },
    });
};

const onPackagesInTestFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
        pagination: {
            ...state.pagination,
            page: 1,
        },
    });
};

const onPackagesInTestSortingUpdate = (state, action) => {
    return updateObject(state, {
        sorting: action.sorting,
    });
};

const onPackagesInTestPaginationUpdate = (state, action) => {
    return updateObject(state, {
        pagination: action.pagination,
    });
};

/*
export const onPackagesInTestExportStart = (state) => {
    return updateObject(state, {
        loading: true,
        exportLoading: true,
        error: null,
    });
};

export const onPackagesInTestExportSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        error: null,
    });
};

export const onPackagesInTestExportFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        error: action.error,
    });
};
*/

export const packagesInTest = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.PACKAGES_IN_TEST_FETCH_START:
            return onPackagesInTestFetchStart(state, action);
        case actionTypes.PACKAGES_IN_TEST_FETCH_SUCCESS:
            return onPackagesInTestFetchSuccess(state, action);
        case actionTypes.PACKAGES_IN_TEST_FETCH_FAIL:
            return onPackagesInTestFetchFail(state, action);
        // FILTER
        case actionTypes.PACKAGES_IN_TEST_FILTER_UPDATE:
            return onPackagesInTestFilterUpdate(state, action);
        case actionTypes.PACKAGES_IN_TEST_FILTER_CLEAR:
            return onPackagesInTestFilterClear(state, action);
        // SORT
        case actionTypes.PACKAGES_IN_TEST_SORTING_UPDATE:
            return onPackagesInTestSortingUpdate(state, action);
        // PAGINATION
        case actionTypes.PACKAGES_IN_TEST_PAGINATION_UPDATE:
            return onPackagesInTestPaginationUpdate(state, action);
        /*
        // EXPORT
        case actionTypes.PACKAGES_IN_TEST_EXPORT_START:
            return onPackagesInTestExportStart(state, action);
        case actionTypes.PACKAGES_IN_TEST_EXPORT_SUCCESS:
            return onPackagesInTestExportSuccess(state, action);
        case actionTypes.PACKAGES_IN_TEST_EXPORT_FAIL:
            return onPackagesInTestExportFail(state, action);
        */

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
