import React, { Component } from "react";
import { fetchAdminSettingsVolumes } from "util/admin/Settings";
import { connect } from "react-redux";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import { Card, CardBody, CardHeader, Row, Col } from "reactstrap";

import WrappedLoader from "components/Loader/WrappedLoader";

export class Volumes extends Component {
    componentDidMount() {
        if (this.props.volumes.length === 0) {
            fetchAdminSettingsVolumes();
        }
    }

    static NoDataIndication(props) {
        let content = "Ei leitud pakendite mahte.";
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }

    render() {
        const columns = [
            {
                dataField: "value",
                text: "Mahud",
                sort: true,
                classes: "semibold-14",
                formatter: (value) => {
                    return value + " cl";
                },
            },
        ];

        return (
            <Card>
                <CardHeader>
                    <Row className="align-items-center">
                        <Col>
                            <span className="heading">Pakendite mahud</span>
                        </Col>
                    </Row>
                </CardHeader>
                <CardBody className="p-0">
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="table-layout-auto no-top-border"
                        wrapperClasses="table-responsive flipped-horizontal-scrollbar"
                        keyField="value"
                        data={this.props.volumes}
                        columns={columns}
                        noDataIndication={
                            <Volumes.NoDataIndication
                                loading={this.props.loading}
                            />
                        }
                    />
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        volumes: state.admin.settings.volumes,
        loading: state.admin.settings.loading,
    };
};

export default connect(mapStateToProps)(Volumes);
