import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import './Profile.scss';
import PersonalProfileForm from "components/Profile/Profile/PersonalProfileForm/PersonalProfileForm";
import { fetchUserSettings } from "util/Profile";
import { addNewLocalErrorAlert, clearLocalAlerts } from "util/Alerts";

import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";

class PartnerProfile extends React.Component {
    componentDidMount() {
        clearLocalAlerts();
        fetchUserSettings().catch(error => {
            addNewLocalErrorAlert("Kasutaja seadmete laadimine ebaõnnestus!", this.props.location.pathname);
        });
    }

    render() {
        return (
            <Card className="profile-card">
                <CardBody className="">
                    <Row className="justify-content-between mb-2">
                        <Col>
                            <span className="heading">Isiklikud andmed</span>
                        </Col>
                        <Col className="float-right text-right col-auto mb-3">
                            {this.props.userRole &&
                                <CheckboxButton
                                    isSelected
                                    disabled
                                    labelText={this.props.userRole}
                                    className="checkbox-button-user-role"
                                />
                            }
                        </Col>
                    </Row>
                    <Form className="profile-form">
                        <PersonalProfileForm />
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

const mapStateToProps = state => {
    return {
        userRole: state.profile && state.profile.userSettings && state.profile.userSettings.role,
    }
}

export default connect(mapStateToProps)(PartnerProfile);
