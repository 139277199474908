import axios from "../../axios";
import store from "redux/store";
import moment from "moment";

import { getServerErrorMessage } from "util/Errors";
import { PackageConditionValues } from "components/Form/PackageConditions/options";

import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";

import {
    ADMIN_RETESTING_PACKAGES,
    ADMIN_CONFIRM_PACKAGE_TEST
} from "config/Api";

import {
    adminRetestingPackagesFetchStart,
    adminRetestingPackagesFetchSuccess,
    adminRetestingPackagesFetchFail,
    adminRetestingConfirmPackageTestStart,
    adminRetestingConfirmPackageTestSuccess,
    adminRetestingConfirmPackageTestFail,
} from "redux/actions/admin/retesting";

export function fetchAdminRetestingPackages(fetchOnlyIfNull, filters, alertGroup) {
    // Meant for packages sent to testing outside of usual application -> testing or waiting -> testing cycle.
    if (!!fetchOnlyIfNull) {
        const state = store.getState();
        if (state.admin.retesting.packagesList !== null) {
            return;
        }
    }
    const filtersToUse = { ...filters };
    store.dispatch(adminRetestingPackagesFetchStart());
    return axios
        .get(ADMIN_RETESTING_PACKAGES)
        .then((response) => {
            const filteredPackages = filterApplications(
                response.data,
                filtersToUse
            );
            store.dispatch(
                adminRetestingPackagesFetchSuccess(
                    response.data,
                    filteredPackages,
                    filtersToUse
                )
            );
            return response.data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminRetestingPackagesFetchFail(errorMessage));

            clearLocalAlerts();
            addNewLocalErrorAlert(errorMessage, alertGroup);
            //throw Error(errorMessage);
        });
}

function filterApplications(packages, filters) {
    if (!packages || !packages.length || !filters) {
        return packages;
    }

    return packages.filter((pkg) => {
        if (filters.ean && !filterStringIncludes(pkg.ean, filters.ean)) {
            return false;
        }
        if (filters.name && !filterStringIncludes(pkg.name, filters.name)) {
            return false;
        }
        if (filters.client && pkg.client.id !== filters.client.value) {
            return false;
        }
        if (filters.category && pkg.category.value !== filters.category.id) {
            return false;
        }
        if (filters.type && pkg.type.value !== filters.type.id) {
            return false;
        }
        if (filters.status && pkg.status.value !== filters.status.id) {
            return false;
        }
        if (filters.debt && pkg.debt !== filters.debt.value) {
            return false;
        }
        //if (filters.volume && pkg.volume !== filters.volume.value) { return false; }
        if (
            filters.testResult &&
            pkg.last_test.value !== filters.testResult.value
        )
            return false;
        //if (filters.startDate && !secondDateIsSameOrAfterFirstDate(filters.startDate, pkg.created_at, "day")) { return false; }
        //if (filters.endDate && !secondDateIsSameOrAfterFirstDate(pkg.created_at, filters.endDate, "day")) { return false; }

        if (filters.conditions && filters.conditions.length) {
            const conditions = filters.conditions.map(
                (condition) => condition.value
            );
            if (
                conditions.includes(PackageConditionValues.UNSYNCED) &&
                pkg.skip_migration !== true
            ) {
                return false;
            }
            if (
                conditions.includes(
                    PackageConditionValues.UNSUBMITTED_REPORTS
                ) &&
                pkg.unsubmitted_sales_reports === 0
            ) {
                return false;
            }
            if (
                conditions.includes(PackageConditionValues.DEBT) &&
                Number(pkg.debt) <= 0
            ) {
                return false;
            }
            if (
                conditions.includes(PackageConditionValues.LAST_TEST_HAS_COMMENT) && !pkg.last_test?.has_comment
            ) {
                return false;
            }
            
            if (
                conditions.includes(PackageConditionValues.LAST_TEST_HAS_STANDARD_COMMENT) && !pkg.last_test?.has_standard_comments
            ) {
                return false;
            }
        }

        return true;
    });
}

function filterStringIncludes(str, substr) {
    return str.trim().toLowerCase().includes(substr.trim().toLowerCase());
}

export function confirmRetestingPackageTests(packageIds, startDate) {
    store.dispatch(adminRetestingConfirmPackageTestStart());
    const data = {
        ids: packageIds,
        start_date: startDate && moment(startDate).format("YYYY-MM-DD"),
    };

    return axios
        .post(ADMIN_CONFIRM_PACKAGE_TEST, data)
        .then((response) => {
            const data = response.data;

            const successIds = data
                .filter((result) => result.is_success)
                .map((result) => result.id);
            store.dispatch(adminRetestingConfirmPackageTestSuccess(successIds));

            return data;
        })
        .catch((error) => {
            const errorMessage = getServerErrorMessage(error);
            store.dispatch(adminRetestingConfirmPackageTestFail(errorMessage));

            throw Error(errorMessage);
        });
}
