import React from "react";

import { NavLink, Route } from "react-router-dom";
import { Switch } from "react-router";

import urls from "config/urls";
import { withTranslation } from "react-i18next";

class AdminReportsView extends React.Component {
    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={(props) => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        const { t } = this.props;

        return (
            <>
                <div className="navigation-buttons pb-4">
                    <NavLink
                        to={urls.EPP_REPORTS_SALES}
                        className="btn btn-default mb-2 mr-1"
                    >
                        {t("reports.tabs.salesReports", "Müügiaruanded")}
                    </NavLink>
                    <NavLink
                        to={urls.EPP_REPORTS_SALES_UNSUBMITTED}
                        className="btn btn-default mb-2 ml-1 mr-1"
                    >
                        {t("reports.tabs.notSubmitted", "Esitamata")}
                    </NavLink>
                    <NavLink
                        to={urls.EPP_UNCONFIRMED_REPAIR_REPORTS}
                        className="btn btn-default mb-2 ml-1 mr-1"
                    >
                        {t("reports.tabs.notConfirmed", "Kinnitamata")}
                    </NavLink>
                    <NavLink
                        to={urls.EPP_REPORTS_RETURN_LIST}
                        className="btn btn-default mb-2 ml-1 mr-1"
                    >
                        {t("routes.returnReports")}
                    </NavLink>
                    <NavLink
                        to={urls.EPP_REPORTS_ADDITIONAL_DEPOSIT_LIST}
                        className="btn btn-default mb-2 ml-1"
                    >
                        {t("reports.tabs.additionalDeposit")}
                    </NavLink>
                </div>

                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </>
        );
    }
}

export default withTranslation("common")(AdminReportsView);
