import React from "react";
import axios from "../axios";
import store from "redux/store";
import moment from "moment";
import _ from "lodash";
import i18n from "i18n";

import { getServerErrorMessage } from "util/Errors";
import { addNewLocalErrorAlert, clearLocalAlerts } from "util/Alerts";

import {
    SALES_LIST,
    GET_SALES_REPORT,
    CREATE_REPAIR_SALES_REPORT,
    GET_REPAIR_SALES_REPORT,
    DELETE_REPAIR_SALES_REPORT,
    UPDATE_SALES_REPORT_LINE,
    UPDATE_REPAIR_SALES_REPORT_LINE,
    SUBMIT_SALES_REPORT,
    EXPORT_SALES_REPORT,
    EXPORT_SALES_REPORT_REPAIR,
    IMPORT_SALES_REPORT,
    IMPORT_SALES_REPORT_REPAIR,
} from "config/Api";
import { navigationUpdateHeaderSuffix } from "redux/actions/navigation";
import {
    salesFetchStart,
    salesFetchFail,
    salesFetchSuccess,
    salesCreateRepairStart,
    salesCreateRepairSuccess,
    salesCreateRepairFail,
} from "redux/actions/reports/sales";
import {
    salesReportFetchStart,
    salesReportFetchFail,
    salesReportFetchSuccess,
    salesLineUpdateStart,
    salesLineUpdateSuccess,
    salesLineUpdateFail,
    salesReportSubmitStart,
    salesReportSubmitSuccess,
    salesReportSubmitFail,
    salesReportExportStart,
    salesReportExportSuccess,
    salesReportExportFail,
    salesReportDeleteRepairStart,
    salesReportDeleteRepairSuccess,
    salesReportDeleteRepairFail,
} from "redux/actions/reports/salesReport";

import {
    salesReportImportStart,
    salesReportImportSuccess,
    salesReportImportFail,
} from "redux/actions/reports/importSalesReport";

import { saleStatuses } from "components/Reports/Sales/helpers/Options";

import {
    parseSalesReportPeriodDate,
    salesReportPeriodListFormat,
} from "util/DateTime";
import { b64toBlob } from "util/Files";
import FileSaver from "file-saver";
import { Trans } from "react-i18next";
import { getVatPercentageFraction } from "./General";

export const SaleStatus = saleStatuses;

export function fetchSalesList(fromDate, toDate, alertGroup = null) {
    store.dispatch(salesFetchStart());
    // TODO implement endpoint
    return axios
        .get(SALES_LIST)
        .then((resp) => {
            // TODO remove mock data part.
            //const unfilteredData = [...mockSales, ...resp.data];
            const filteredData = filterByFromDate(resp.data, fromDate);
            const salesReports = filterByToDate(filteredData, toDate);
            store.dispatch(salesFetchSuccess(salesReports, fromDate, toDate));

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    <Trans i18nKey="reports.sales.alert.fetchSalesReportsListFailed">
                        Müügiaruannete laadimine ebaõnnestus: {{ errorMessage }}
                    </Trans>,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function createRepairReport(id, onSuccess) {
    store.dispatch(salesCreateRepairStart());
    const data = {
        sales_report_id: id,
    };
    return axios
        .post(CREATE_REPAIR_SALES_REPORT, data)
        .then((resp) => {
            store.dispatch(salesCreateRepairSuccess());
            onSuccess(resp.data.id);

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesCreateRepairFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function fetchSalesReport(id) {
    store.dispatch(salesReportFetchStart());
    return axios
        .get(`${GET_SALES_REPORT}${id}/`)
        .then((resp) => {
            store.dispatch(salesReportFetchSuccess(resp.data));

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportFetchFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function fetchRepairSalesReport(id) {
    store.dispatch(salesReportFetchStart());
    return axios
        .get(`${GET_REPAIR_SALES_REPORT}${id}/`)
        .then((resp) => {
            store.dispatch(salesReportFetchSuccess(resp.data));

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportFetchFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function updateSalesLine(
    salesReportId,
    isRepair,
    oldPackage,
    newQuantity
) {
    const data = {
        package: oldPackage.id,
        quantity: newQuantity ? Number(newQuantity) : 0,
    };
    const urlBase = isRepair
        ? UPDATE_REPAIR_SALES_REPORT_LINE
        : UPDATE_SALES_REPORT_LINE;
    const url = `${urlBase}${salesReportId}/`;
    store.dispatch(
        salesLineUpdateStart({
            ...oldPackage,
            isQuantityUpdateLoading: true,
            isQuantityUpdated: null,
        })
    );
    return axios
        .post(url, data)
        .then((resp) => {
            store.dispatch(
                salesLineUpdateSuccess({
                    ...resp.data,
                    isQuantityUpdateLoading: false,
                    isQuantityUpdated: true,
                })
            );

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(
                salesLineUpdateFail(
                    {
                        ...oldPackage,
                        isQuantityUpdateLoading: false,
                        isQuantityUpdated: false,
                    },
                    errorMessage
                )
            );

            return { isSuccess: false, errorMessage };
        });
}

export function submitSalesReport(id, isRepair, onSuccess, onError) {
    const data = {
        is_repair: isRepair,
        sales_report_id: id,
    };

    store.dispatch(salesReportSubmitStart());
    return axios
        .put(SUBMIT_SALES_REPORT, data)
        .then((resp) => {
            store.dispatch(salesReportSubmitSuccess());

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportSubmitFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function deleteRepairSalesReport(id) {
    store.dispatch(salesReportDeleteRepairStart());
    return axios
        .delete(`${DELETE_REPAIR_SALES_REPORT}${id}/`)
        .then((resp) => {
            store.dispatch(salesReportDeleteRepairSuccess());

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportDeleteRepairFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function fetchSalesReportExportFile(id, isRepair, period) {
    const url = isRepair
        ? `${EXPORT_SALES_REPORT_REPAIR}${id}/`
        : `${EXPORT_SALES_REPORT}${id}/`;
    store.dispatch(salesReportExportStart());
    return axios
        .get(url, {
            //reponseType: "arraybuffer",
            //headers: {'Content-Type': "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"},
        })
        .then((resp) => {
            const blob = b64toBlob(resp.data);
            let filename;
            if (isRepair) {
                filename = i18n.t("filename.repairSalesReportTemplateExport", {period});
            } else {
                filename = i18n.t("filename.salesReportTemplateExport", {period})
            }
            FileSaver.saveAs(blob, filename);
            store.dispatch(salesReportExportSuccess());

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportExportFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export const importSalesReportFromExcelFile = async (id, isRepair, file) => {
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("name", file.name);

    const endpointStatic = isRepair
        ? IMPORT_SALES_REPORT_REPAIR
        : IMPORT_SALES_REPORT;
    store.dispatch(salesReportImportStart());
    return axios
        .put(`${endpointStatic}${id}/`, formdata)
        .then((response) => {
            store.dispatch(salesReportImportSuccess());
            return { isSuccess: true };
        })
        .catch((err) => {
            let errors = [];
            if (err.response && err.response.data) {
                const data = err.response.data;
                if (_.isArray(data)) {
                    errors = data;
                } else {
                    errors = [data];
                }
            }

            // const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportImportFail(errors));

            return { isSuccess: false, errors };
        });
};

export function isRecent(period) {
    const SALES_REPORTS_RECENT_MONTHS_EDITABLE = 12
    if (_.isNil(period)) {
        return false;
    }
    const periodDate = moment(period);
    return (
        periodDate.isValid() &&
        periodDate.isAfter(moment().subtract(SALES_REPORTS_RECENT_MONTHS_EDITABLE, "months"), "months")
    );
}

export function isActive(reportDetails) {
    if (!reportDetails) {
        return false;
    }
    const reportPeriod = parseSalesReportPeriodDate(reportDetails.period);
    return (
        isRecent(reportPeriod) &&
        [SaleStatus.UNSUBMITTED, SaleStatus.REJECTED].includes(
            reportDetails.status.value
        )
    );
}

export function calculateSalesReportPackageTotals(packages, { is_vat_free, period }) {
    if (!packages) {
        packages = [];
    }

    let totalPackages = 0;
    let totalDeposits = 0;
    let totalProcessFeesByType = {};
    let totalProcessFees = 0;

    for (const pkg of packages) {
        if (!pkg.quantity) {
            continue;
        }
        totalPackages += Number(pkg.quantity);
        totalDeposits += Number(pkg.deposit_total);
        const processFeeType = pkg.process_fee_type;
        const previousProcessFeeTotal = totalProcessFeesByType[processFeeType] || 0;
        totalProcessFeesByType[processFeeType] = previousProcessFeeTotal + Number(pkg.process_fee);
    }
    totalProcessFees = Object
        .values(totalProcessFeesByType)
        .map(x => _.round(x, 2))
        .reduce((total, value) => total + value, 0);
    
    const process_fee_vat = is_vat_free ? 0 : totalProcessFees * getVatPercentageFraction(new Date(period));

    const rounded_process_fee_total = _.round(totalProcessFees, 2);
    const rounded_process_fee_vat = _.round(process_fee_vat, 2);
    const rounded_deposit_total = _.round(totalDeposits, 2);
    const rounded_to_be_paid =
        rounded_process_fee_total +
        rounded_process_fee_vat +
        rounded_deposit_total;

    const totals = {
        packages_total: totalPackages,
        process_fee_total: rounded_process_fee_total,
        process_fee_vat: rounded_process_fee_vat,
        deposit_total: rounded_deposit_total,
        to_be_paid: rounded_to_be_paid,
    };
    return totals;
}

export function setSalesReportHeaderSuffix(details) {
    if (!details || !details.period) {
        return;
    }
    const period = details.period;
    const isRepair = details.is_repair;

    const periodFormatted = parseSalesReportPeriodDate(period).format(
        salesReportPeriodListFormat
    );
    let text = (
        <Trans i18nKey="reports.routeSuffix.originalReport">
            {{ periodFormatted }}
        </Trans>
    );
    if (isRepair) {
        text = (
            <Trans i18nKey="reports.routeSuffix.repairReport">
                {{ periodFormatted }} - Parandus
            </Trans>
        );
    }
    store.dispatch(navigationUpdateHeaderSuffix(text));
}

function filterByFromDate(data, fromDate = null) {
    if (!data) {
        return data;
    }
    const fromDateMoment = moment(fromDate);
    if (data && fromDateMoment.isValid()) {
        return data.filter((d) =>
            fromDateMoment.isSameOrBefore(new moment(d.period), "month")
        );
    }
    return data;
}

function filterByToDate(data, toDate = null) {
    if (!data) {
        return data;
    }
    const toDateMoment = moment(toDate);
    if (data && toDateMoment.isValid()) {
        return data.filter((d) =>
            toDateMoment.isSameOrAfter(new moment(d.period), "month")
        );
    }
    return data;
}

export function isSalesReportHasRepairReport(salesReport) {
    return salesReport && !!salesReport.repair_report;
}

export function isSalesReportHasUnconfirmedRepairReport(salesReport) {
    return (
        isSalesReportHasRepairReport(salesReport) &&
        salesReport.repair_report.status.value === SaleStatus.UNCONFIRMED
    );
}

export function isSalesReportOrItsRepairReportHasStatuses(
    salesReport,
    statuses
) {
    if (!salesReport || !statuses || !statuses.length) {
        return false;
    }

    if (statuses.includes(salesReport.status.value)) {
        return true;
    }

    if (isSalesReportHasRepairReport(salesReport)) {
        const repairReport = salesReport.repair_report;
        if (statuses.includes(repairReport.status.value)) {
            return true;
        }
    }

    return false;
}

export function isSalesReportHasClientEditableRepair(salesReport) {
    return (
        isSalesReportHasRepairReport(salesReport) &&
        isRecent(salesReport.period) &&
        [SaleStatus.UNSUBMITTED, SaleStatus.REJECTED].includes(
            salesReport.repair_report.status.value
        )
    );
}

export function isRepairReportClientEditable(repairReport) {
    if (!repairReport) return false;

    return (
        isRecent(repairReport.period) && 
        [SaleStatus.UNSUBMITTED, SaleStatus.REJECTED].includes(
            repairReport.status?.value
        )
    )
}
