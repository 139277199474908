import React from "react";
import { withRouter } from "react-router-dom";
import { Col, Container, Row, Card, CardBody } from "reactstrap";

import BrandLogoText from "components/Misc/BrandLogoText";

function PrivacyPolicyContent(props) {
    return (
        <article className="regular-14 text-left">
            <h3 className="heading text-center">Privaatsuspoliitika</h3>
            <p className="semibold-14">1. Üldsätted</p>
            <p>1.1. Käesolev privaatsuspoliitika reguleerib isikuandmete kogumist, töötlemist ja säilitamist käsitlevaid põhimõtteid. Isikuandmeid kogub, töötleb ja säilitab isikuandmete vastutav töötleja Eesti Pandipakend OÜ (edaspidi andmetöötleja).</p>
            <p>1.2. Andmesubjekt privaatsuspoliitika tähenduses on klient või muu füüsiline isik, kelle isikuandmeid andmetöötleja töötleb.</p>
            <p>1.3. Klient privaatsuspoliitika tähenduses on igaüks, kes ostab andmetöötleja kodulehelt kaupu või teenuseid.</p>
            <p>1.4. Andmetöötleja järgib õigusaktides sätestatud andmete töötlemise põhimõtteid, muuhulgas töötleb andmetöötleja isikuandmeid seaduslikult, õiglaselt ja turvaliselt. Andmetöötleja on võimeline kinnitama, et isikuandmeid on töödeldud vastavalt õigusaktides sätestatule.</p>

            <p className="semibold-14">2. Isikuandmete kogumine, töötlemine ja säilitamine</p>
            <p>2.1. Isikuandmed, mida andmetöötleja kogub, töötleb ja säilitab, on kogutud elektrooniliselt, peamiselt kodulehe ja e-posti vahendusel.</p>
            <p>2.2. Oma isikuandmete jagamisega annab andmesubjekt andmetöötlejale õiguse koguda, korraldada, kasutada ja hallata privaatsuspoliitikas määratletud eesmärgil isikuandmeid, mida andmesubjekt otse või kaudselt kodulehel kaupu või teenuseid ostes andmetöötlejale jagab.</p>
            <p>2.3. Andmesubjekt vastutab selle eest, et tema poolt esitatud andmed oleksid täpsed, õiged ja terviklikud. Teadlikult valeandmete esitamist peetakse privaatsuspoliitika rikkumiseks. Andmesubjekt on kohustatud andmetöötlejat viivitamatult teavitama esitatud andmete muutumisest.</p>
            <p>2.4. Andmetöötleja ei vastuta andmesubjekti poolt valeandmete esitamisest põhjustatud kahju eest andmesubjektile või kolmandatele osapooltele.</p>

            <p className="semibold-14">3. Klientide isikuandmete töötlemine</p>
            <p>3.1. Andmetöötleja võib töödelda järgnevaid andmesubjekti isikuandmeid:</p>
            <p>3.1.1. Ees- ja perekonnanimi;</p>
            <p>3.1.2. Sünnikuupäev;</p>
            <p>3.1.3. Telefoninumber;</p>
            <p>3.1.4. E-posti aadress;</p>
            <p>3.1.5. Kontaktaadress;</p>
            <p>3.1.6. Arvelduskonto number;</p>
            <p>3.1.7. Maksekaardi detailid;</p>
            <p>3.2. Lisaks eeltoodule on andmetöötlejal õigus koguda kliendi kohta andmeid, mis on kättesaadavad avalikes registrites.</p>
            <p>3.3. Isikuandmete töötlemise õiguslik alus on isikuandmete kaitse üldmääruse paragrahv 6 lg 1 p-d a), b), c) ja f):</p>
            <p>a) andmesubjekt on andnud nõusoleku töödelda oma isikuandmeid ühel või mitmel konkreetsel eesmärgil;</p>
            <p>b) isikuandmete töötlemine on vajalik andmesubjekti osalusel sõlmitud lepingu täitmiseks või lepingu sõlmimisele eelnevate meetmete võtmiseks vastavalt andmesubjekti taotlusele;</p>
            <p>c) isikuandmete töötlemine on vajalik vastutava töötleja juriidilise kohustuse täitmiseks;</p>
            <p>f) isikuandmete töötlemine on vajalik vastutava töötleja või kolmanda isiku õigustatud huvi korral, välja arvatud juhul, kui sellise huvi kaaluvad üles andmesubjekti huvid või põhiõigused ja -vabadused, mille nimel tuleb kaitsta isikuandmeid, eriti juhul kui andmesubjekt on laps.</p>
            <p>3.4. Isikuandmete säilitamine</p>
            <p>E-keskkonna kliendikonto sulgemisel kustutatakse isikuandmed, v.a juhul kui selliseid andmeid on vaja säilitada raamatupidamise jaoks või vaidluste lahendamiseks.</p>
            <p>Maksetega seotud vaidluste korral säilitatakse isikuandmed kuni nõude täitmiseni või aegumistähtaja lõpuni (kolm aastat).</p>
            <p>Raamatupidamise jaoks vajalikud isikuandmed säilitatakse vastavalt seaduses nimetatud tähtaegadele.</p>
            <p>3.5. Andmetöötlejal on õigus klientide isikuandmeid jagada kolmandate isikutega, kelleks on näiteks volitatud andmetöötlejad, raamatupidajad, transpordi- ja kullerettevõtted, ülekandeteenuseid pakkuvad ettevõtted Andmetöötleja on isikuandmete vastutav töötleja. Andmetöötleja edastab maksete teostamiseks vajalikud isikuandmed volitatud töötleja EveryPay AS-ile.</p>
            <p>3.6. Andmesubjekti isikuandmete töötlemisel ja säilitamisel rakendab andmetöötleja organisatoorseid ja tehnilisi meetmeid, mis tagavad isikuandmete kaitse juhusliku või ebaseadusliku hävitamise, muutmise, avalikustamise ja mis tahes muu ebaseadusliku töötlemise eest.</p>
            <p>3.7. Andmetöötleja säilitab andmesubjektide andmeid sõltuvalt töötlemise eesmärgist, kuid mitte kauem kui 7 aastat.</p>

            <p className="semibold-14">4. Andmesubjekti õigused</p>
            <p>4.1. Andmesubjektil on õigus saada ligipääs oma isikuandmetele ning nendega tutvuda.</p>
            <p>4.2. Andmesubjektil on õigus saada informatsiooni tema isikuandmete töötlemise kohta.</p>
            <p>4.3. Andmesubjektil on õigus täiendada või parandada ebatäpseid andmeid.</p>
            <p>4.4. Kui andmetöötleja töötleb andmesubjekti isikuandmeid andmesubjekti nõusoleku alusel, siis on andmesubjektil õigus igal ajal nõusolek tagasi võtta.</p>
            <p>4.5. Andmesubjekt saab õiguste teostamiseks pöörduda e-poe klienditoe poole aadressil info@eestipandipakend.ee.</p>
            <p>4.6. Andmesubjektil on oma õiguste kaitseks võimalik esitada kaebus Andmekaitse Inspektsioonile.</p>

            <p className="semibold-14">5. Lõppsätted</p>
            <p>5.1. Käesolevad andmekaitsetingimused on koostatud kooskõlas Euroopa Parlamendi ja nõukogu määrusega (EL) nr 2016/679 füüsiliste isikute kaitse kohta isikuandmete töötlemisel ja selliste andmete vaba liikumise ning direktiivi 95/46 / EÜ kehtetuks tunnistamise kohta / EÜ (isikuandmete kaitse üldmäärus), Eesti Vabariigi isikuandmete kaitse seadusega ning Eesti Vabariigi ja Euroopa Liidu õigusaktidega.</p>
            <p>5.2. Andmetöötlejal on õigus andmekaitsetingimusi osaliselt või täielikult muuta, teavitades andmesubjekte muudatustest kodulehe www.ekeskkond.eestipandipakend.ee kaudu.</p>
        </article>
    );
}

function PrivacyPolicy(props) {
    return (
        <Container className="align-middle">
            <Row className="text-center justify-content-center">
                <Col>
                    <Card>
                        <CardBody className="px-3 px-md-4" style={{ "paddingTop": "32px" }}>
                            <BrandLogoText />
                            <Row>
                                <Col>
                                    <PrivacyPolicyContent />
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default withRouter(PrivacyPolicy);
