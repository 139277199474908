import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import { components } from "react-select";
import { Row, Col, Collapse, Button, Label } from "reactstrap";
import DatePicker from "react-datepicker";

import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import { ReactComponent as ArrowUp } from "assets/icons/arrow-up.svg";
import { ReactComponent as RemoveIcon } from 'assets/icons/delete-row.svg';

import WrapperLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import Select from "components/Form/Select";
import CustomInput from "components/Form/Input";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import Alerts from "components/Alerts/Alerts";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import Comments from "components/Comments";

import { getErrorMessageList } from "util/Errors";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalSuccessAlert,
} from "util/Alerts";
import {
    getTestStatusType,
    submitPackageTest,
    packageTestStatuses,
} from "util/Partner";
import {
    confirmPackageTests
} from "util/admin/Testing";
import { DATE_FORMAT, DATE_FORMAT_DATE_FN } from "util/DateTime";

import "./PackageTest.scss";


const ValueContainer = ({ children, getValue, ...props }) => {
    var length = getValue().length;
    let textMessage = "";
    if (length === 0) {
        textMessage = "Vali kuni 3";
    }
    else if (length === 3) {
        textMessage = "Rohkem ei saa valida";
    }
    else if (length === 2 || length === 1) {
        textMessage = `Vali kuni ${3 - length} veel`;
    }

    return (
        <components.ValueContainer {...props}>
            {!props.selectProps.inputValue &&
                textMessage
            }
            {React.Children.map(children, child => {
                return child && child.type === components.Input ? child : null;
            })}
        </components.ValueContainer>
    );
};

class PackageTest extends React.Component {
    static propTypes = {
        packageTest: PropTypes.object.isRequired,
        onSubmitSuccess: PropTypes.func.isRequired,
        onConfirmSuccess: PropTypes.func,
        readOnly: PropTypes.bool,
        isAdminMode: PropTypes.bool,
        // select options
        locations: PropTypes.array,
        standardComments: PropTypes.array,
    }

    static MIN_WIDTH_HORIZONTAL_SCALE_PERCENTAGE = 100;
    static MIN_WIDTH_VERTICAL_SCALE_PERCENTAGE = 80;
    static MAX_WIDTH_SCALE_PERCENTAGE = 130;
    static MIN_HEIGHT_SCALE_PERCENTAGE = 80;
    static MAX_HEIGHT_SCALE_PERCENTAGE = 115;
    static EAN8_WIDTH_AT_100 = 22.11;
    static EAN8_HEIGHT_AT_100 = 21.31;

    static EAN13_WIDTH_AT_100 = 31.35;
    static EAN13_HEIGHT_AT_100 = 25.91;
    static EAN13_MIN_HEIGHT_VERTICAL = 15.00;
    static EAN13_MIN_HEIGHT_HORIZONTAL = 20.00;
    static HORIZONTAL_LOCATION_VALUE = 1;
    static VERTICAL_LOCATION_VALUE = 2;

    state = {
        packageTest: null,
        packageTestLoading: false,
        collapsed: true,
        confirmLoading: false,
        confirmPackageTestStartDate: null,
        isConfirmPackageTestOpen: false,
    }

    componentDidMount() {
        this.updateStatePackageTest(this.props.packageTest);
        this.setState({
            collapsed: this.isCollapsedOnMount(),
        })
        this.setDefaultLocation();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.packageTest !== this.props.packageTest) {
            this.updateStatePackageTest(this.props.packageTest);
        }

        if (prevState.packageTest !== this.state.packageTest && this.state.packageTest) {
            if (prevState.packageTest && prevState.packageTest.width !== this.state.packageTest.width) {
                this.onChange("width_percentage")(this.calculateWidthPercentage());
            }
            if (prevState.packageTest && prevState.packageTest.height !== this.state.packageTest.height) {
                this.onChange("height_percentage")(this.calculateHeightPercentage());
            }
        }
        if (this.state.packageTest && this.state.packageTest.location === null && this.props.locations && this.props.locations.length) {
            this.setDefaultLocation();
        }
    }

    render() {
        if (this.state.packageTestLoading) {
            return (
                <WrapperLoader />
            );
        }
        if (!this.state.packageTest) {
            return <></>;
        }

        if (this.isReadOnly()) {
        }
        return this.renderTested();

        //return this.renderContent();
    }

    renderTested() {

        return (
            <Row className="mx-0 package-test-wrapper">
                <Col className="px-0">
                    <Row onClick={this.toggleCollapsed} className="pointer mx-3 p-3 align-items-center">
                        <Col className="px-0">
                            <span className="semibold-14 color-info">Testitulemus {this.getTestedAt()}{this.showUnconfirmedText() && " (Kinnitamata)"}</span>
                            {this.state.collapsed ?
                                <ArrowDown className="package-test-collapse-arrow" />
                                :
                                <ArrowUp className="package-test-collapse-arrow" />
                            }
                        </Col>
                        {this.showConfirmButton() &&
                            <Col className="col-auto">
                                {this.renderConfirmPackageTestButtonAndModal()}
                            </Col>
                        }
                        <Col className="col-auto px-0">
                            {this.renderStatus()}
                        </Col>
                    </Row>
                    <Collapse isOpen={!this.state.collapsed} className="">
                        {this.renderContent()}
                    </Collapse>
                </Col>
            </Row>
        )
    }

    renderStatus() {
        const status = this.props.packageTest.status;
        if (status) {
            return (
                <StatusCell
                    status={status.label}
                    type={getTestStatusType(status.value)}
                    className="float-right"
                />
            );
        }

        return <></>;
    }

    renderContent() {
        const isReadOnly = this.isReadOnly();

        return (
            <div className="package-test-item">
                <Row className="align-items-end mx-0 mb-2 px-2">
                    <Col lg="9">
                        <Row className="align-items-end">
                            <Col sm="6" md={true} className="px-2">
                                <CustomInput
                                    type="text"
                                    labelText="Laius (MM)"
                                    placeholder="Lisa"
                                    className="mb-2"
                                    value={this.state.packageTest.width}
                                    onChange={this.onChangeNumber("width")}
                                    disabled={isReadOnly}
                                    valid={this.isWidthRequirementsFilled() && this.isWidthValid()}
                                    invalid={this.isWidthRequirementsFilled() && !this.isWidthValid()}
                                    required={!isReadOnly}
                                />
                            </Col>
                            <Col sm="3" md={true} className="px-2">
                                <CustomInput
                                    disabled
                                    type="number"
                                    labelText="Laius (%)"
                                    placeholder=""
                                    className="mb-2"
                                    value={this.state.packageTest.width_percentage}
                                    onChange={this.onChange("width_percentage")}
                                    valid={this.isWidthPercentageValidationRequirementsFilled() && this.isWidthPercentageValid()}
                                    invalid={this.isWidthPercentageValidationRequirementsFilled() && !this.isWidthPercentageValid()}
                                    required={!isReadOnly}
                                />
                            </Col>
                            <Col sm="3" md={true} className="px-2">
                                <CustomInput
                                    type="number"
                                    labelText="Kõrgus (%)"
                                    placeholder=""
                                    className="mb-2"
                                    value={this.state.packageTest.height_percentage}
                                    onChange={this.onChange("height_percentage")}
                                    disabled
                                    valid={this.isHeightPercentageValidationRequirementsFilled() && this.isHeightPercentageValid()}
                                    invalid={this.isHeightPercentageValidationRequirementsFilled() && !this.isHeightPercentageValid()}
                                    required={!isReadOnly}
                                />
                            </Col>
                            <Col sm="6" md={true} className="px-2">
                                <CustomInput
                                    type="text"
                                    labelText="VV (MM)"
                                    placeholder="Lisa"
                                    className="mb-2"
                                    value={this.state.packageTest.vv}
                                    onChange={this.onChangeNumber("vv")}
                                    disabled={isReadOnly}
                                    required={!isReadOnly}
                                />
                            </Col>
                            <Col sm="6" md={true} className="px-2">
                                <CustomInput
                                    type="text"
                                    labelText="PV (MM)"
                                    placeholder="Lisa"
                                    className="mb-2"
                                    value={this.state.packageTest.pv}
                                    onChange={this.onChangeNumber("pv")}
                                    disabled={isReadOnly}
                                    required={!isReadOnly}
                                />
                            </Col>
                            <Col sm="6" md={true} className="px-2">
                                <CustomInput
                                    type="text"
                                    labelText="Kõrgus (MM)"
                                    placeholder="Lisa"
                                    className="mb-2"
                                    value={this.state.packageTest.height}
                                    onChange={this.onChangeNumber("height")}
                                    disabled={isReadOnly}
                                    valid={this.isHeightValidationRequirementsFilled() && this.isHeightValid()}
                                    invalid={this.isHeightValidationRequirementsFilled() && !this.isHeightValid()}
                                    required={!isReadOnly}
                                />
                            </Col>
                            <Col sm="3" md={true} className="px-2">
                                <Select
                                    labelText="Asend pudelil"
                                    placeholder="Vali"
                                    className="mb-2"
                                    options={this.props.locations}
                                    value={this.state.packageTest.location}
                                    onChange={this.onChange("location")}
                                    disabled={isReadOnly}
                                    required={!isReadOnly}
                                    tabSelectsValue={true}
                                />
                            </Col>
                            <Col sm="3" md={true} className="px-2">
                                <Select
                                    labelText="Kleebis"
                                    placeholder="Vali"
                                    className="mb-2"
                                    value={this.getStickerSelectValue(this.state.packageTest.is_sticker)}
                                    options={[{ value: true }, { value: false }]}
                                    getOptionLabel={option => option.value ? "Jah" : "Ei"}
                                    onChange={selectedOption => this.onChange("is_sticker")(selectedOption ? selectedOption.value : null)}
                                    disabled={isReadOnly}
                                    required={!isReadOnly}
                                    tabSelectsValue={true}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col lg="3" className="px-2">
                        <Select
                            isMulti
                            labelText="Tüüpkommentaar(id)"
                            placeholder="Vali kuni 3"
                            className="mb-2"
                            options={this.props.standardComments}
                            value={this.state.packageTest.standard_comments}
                            onChange={this.onChange("standard_comments")}
                            disabled={isReadOnly}
                            closeMenuOnSelect={false}
                            hideSelectedOptions={false}
                            isOptionDisabled={option => (
                                this.state.packageTest.standard_comments.length >= 3
                                &&
                                !this.state.packageTest.standard_comments.includes(option)
                            )}
                            styles={{
                                multiValue: base => ({
                                    //...base,
                                    display: "none",
                                }),
                            }}
                            isClearable={false}
                            components={{ ValueContainer }}
                            tabSelectsValue={true}
                        />
                    </Col>
                </Row>
                <Row className="mx-0">
                    {this.state.packageTest.standard_comments.length > 0 &&
                        <Col lg="3" className="selected-sc-list order-lg-last mb-2">
                            {this.state.packageTest.standard_comments.map((sc, i) => (
                                <Row key={sc.value} className="selected-sc-item">
                                    <Col className="selected-sc-label-col pl-2">
                                        <span className="regular-14">{sc.label}</span>

                                    </Col>
                                    {!isReadOnly &&
                                        <Col className="selected-sc-remove-col col-auto">
                                            <RemoveIcon
                                                className="selected-sc-remove-icon"
                                                onClick={() => this.onRemoveStandardComment(sc)}
                                            />
                                        </Col>
                                    }
                                </Row>
                            ))}
                        </Col>
                    }
                    <Col className="mb-2">
                        <CustomInput
                            labelText="Kommentaarid ja mitteloetavuse põhjus"
                            placeholder=""
                            className="mb-2"
                            value={this.state.packageTest.comment}
                            onChange={this.onChange("comment")}
                            disabled={isReadOnly}
                        />
                    </Col>
                </Row>
                <Row className="mx-0">
                    <Col>
                        <Alerts
                            hideGlobal
                            filterGroups={[this.getAlertGroup()]}
                        />
                    </Col>
                </Row>
                {false && !isReadOnly &&
                    <Row className="color-bg-bg mx-n3 mt-3 py-3">
                        <Col>
                            <Button
                                color="danger"
                                className="float-right"
                                onClick={() => this.onSubmit(2)}
                            >
                                Esita NOK
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                color="success"
                                onClick={() => this.onSubmit(1)}
                            >
                                Esita OK
                            </Button>
                        </Col>
                    </Row>
                }
                {this.showSubmitButton() &&
                    <Row className="color-bg-bg mx-n3 mt-3 py-3 justify-content-center">
                        <Col className="mb-2 col-auto">
                            <Select
                                placeholder="Staatus"
                                className="package-test-status-select"
                                options={[
                                    { value: 1, label: "OK" },
                                    { value: 2, label: "NOK" }
                                ]}
                                value={this.state.packageTest.status}
                                onChange={this.onChange("status")}
                                required
                            />
                        </Col>
                        <Col className="col-auto">
                            <Button
                                color="success"
                                className=""
                                onClick={() => this.onSubmit()}
                            >
                                {this.getSubmitButtonText()}
                            </Button>
                        </Col>
                    </Row>
                }
                {this.renderComments()}
            </div>
        );
    }

    renderComments() {
        if (this.state.collapsed) return null;
        return (
            <div className="mx-n3">
                <Comments
                    contentType={this.state.packageTest.content_type}
                    objectId={this.state.packageTest.test_id}
                    maxHeight="500px"
                    isCollapsible={true}
                    startCollapsed={true}
                />
            </div>
        )
    }

    renderConfirmPackageTestButtonAndModal() {
        let alertGroup = "adminPackageTestConfirmModal";

        const onToggle = () => {
            clearLocalAlerts();
            this.setState(prevState => { return { isConfirmPackageTestOpen: !prevState.isConfirmPackageTestOpen, confirmPackageTestStartDate: null } });
        };
        let onConfirmFunc = () => {
            clearLocalAlerts();

            const startDate = this.state.confirmPackageTestStartDate;
            if (startDate == null) {
                addNewLocalErrorAlert("Müügi alguskuupäev valimata.", alertGroup);
                return;
            }

            const packageId = this.state.packageTest.package_id;
            this.setState(prevState => {
                return {
                    confirmLoading: true
                }
            });
            confirmPackageTests([packageId], startDate)
                .then(data => {
                    //this.uncollapse();
                    clearLocalAlerts();

                    const packageResult = data.find(result => result.id === packageId);
                    if (packageResult && packageResult.is_success) {
                        addNewLocalSuccessAlert("Testitulemus edukalt kinnitatud.", this.getAlertGroup());
                        const updatedPackageTest = {
                            ...this.state.packageTest,
                            confirmed_at: packageResult.confirmed_at,
                        }
                        this.setState({ packageTest: updatedPackageTest, isConfirmPackageTestOpen: false });
                        if (this.props.onConfirmSuccess) {
                            this.props.onConfirmSuccess(updatedPackageTest);
                        }
                    }
                    else {
                        addNewLocalErrorAlert("Testitulemuse kinnitamine ebaõnnestus.", alertGroup);
                    }
                })
                .catch(error => {
                    //this.uncollapse();
                    clearLocalAlerts();
                    addNewLocalErrorAlert(error.message, alertGroup);
                })
                .finally(() => {
                    this.setState(prevState => {
                        return {
                            confirmLoading: false
                        }
                    });
                });
        }

        return (
            <>
                <Button
                    outline
                    color="primary"
                    onClick={(e) => {
                        e.stopPropagation();
                        onToggle();
                    }}
                    disabled={this.state.confirmLoading}
                >
                    {this.state.confirmLoading ? <LoadingAnimation /> : "Kinnita"}
                </Button>
                <ConfirmationModal
                    isOpen={this.state.isConfirmPackageTestOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onConfirmFunc}
                    confirmButtonText="Kinnita"
                    title="Kinnita testitulemus"
                    isDisabled={this.state.confirmLoading}
                    isLoading={this.state.confirmLoading}
                    alertGroups={[alertGroup]}
                >
                    <Row>
                        <Col className="">
                            <Label className="bold-12 color-gray">Müügi alguskuupäev</Label>
                            <DatePicker
                                placeholderText="Müügi alguskuupäev"
                                className="mb-2"
                                selected={this.state.confirmPackageTestStartDate}
                                onChange={value => this.setState({ confirmPackageTestStartDate: value })}
                                dateFormat={DATE_FORMAT_DATE_FN}
                            />
                        </Col>
                    </Row>
                </ConfirmationModal>
            </>
        );
    }

    getStickerSelectValue = (is_sticker) => {
        if (is_sticker === true) {
            return { value: true };
        }
        else if (is_sticker === false) {
            return { value: false };
        }
        return null;
    }

    showConfirmButton = () => {
        return !this.isReadOnly() && this.props.isAdminMode && this.props.packageTest.tested_at;
    }

    showUnconfirmedText = () => {
        return this.props.packageTest.tested_at && !this.props.packageTest.confirmed_at;
    }

    showSubmitButton = () => {
        return !this.isReadOnly();
    }

    getSubmitButtonText = () => {
        let text = "Esita testitulemus";

        if (this.props.packageTest.tested_at) {
            text = "Uuenda testitulemust";
        }

        return text;
    }

    getTestedAt = () => {
        const testedAtMoment = moment(this.state.packageTest.tested_at);
        return testedAtMoment.isValid() ? testedAtMoment.format(DATE_FORMAT) : "esitamata";
    }

    uncollapse = () => {
        this.setState({ collapsed: false });
    }

    updateStatePackageTest = (propsPackageTest) => {
        this.setState({
            packageTest: { ...propsPackageTest },
        });
    }

    onChange = name => value => {
        if (name === "standard_comments" && value === null) {
            value = [];
        }
        this.setState((prevState) => {
            return {
                packageTest: {
                    ...prevState.packageTest,
                    [name]: value,
                }
            }
        });
    }

    onChangeNumber = name => value => {
        value = value.replace(",", ".").replace(/[^0-9.-]/g, "");
        //value = this.removePeriodsAfterFirst(value);

        if (this.state.packageTest[name] === value) {
            return false;
        }

        this.onChange(name)(value);
    }

    removePeriodsAfterFirst = (str) => {
        const first = str.indexOf(".");
        return str.substring(0, first + 1) + str.substring(first + 1).replace(/\./g, "");
    }

    onRemoveStandardComment = commentOption => {
        const standardComments = this.state.packageTest.standard_comments.filter(
            sc => sc.value !== commentOption.value
        );

        this.onChange("standard_comments")(standardComments);
    }

    validateOnSubmit = () => {
        clearLocalAlerts();

        const errors = [];
        const packageTest = this.state.packageTest;

        const status = packageTest.status;
        if (!status) {
            errors.push({ code: "Staatus on puudu." });
        }
        else if (!packageTestStatuses.OK.includes(status.value) && !packageTestStatuses.NOK.includes(status.value)) {
            errors.push({ code: "Staatus peab olema kas OK või NOK." });
        }

        const notEmptyFields = [
            { field: "width", label: "Laius" },
            { field: "height", label: "Kõrgus" },
            { field: "width_percentage", label: "Laiuse %" },
            { field: "height_percentage", label: "Kõrguse %" },
            { field: "location", label: "Asend pudelil" },
            { field: "pv", label: "PV" },
            { field: "vv", label: "VV" },
            { field: "is_sticker", label: "Kleebis" },
        ];

        for (const x of notEmptyFields) {
            const value = packageTest[x.field];
            if (value === undefined || value === null || value === "") {
                errors.push({ field: x.field, code: `${x.label} on puudu.` });
            }
        }

        const validNumberFields = [
            { field: "width", label: "Laius" },
            { field: "height", label: "Kõrgus" },
            { field: "pv", label: "PV" },
            { field: "vv", label: "VV" },
        ];

        const emptyFields = errors.map(error => error.field);
        const notEmptyValidNumberFields = validNumberFields.filter(x => !emptyFields.includes(x.field));

        for (const x of notEmptyValidNumberFields) {
            const value = Number(packageTest[x.field]);
            if (!isFinite(value)) {
                errors.push({ field: x.field, code: `${x.label} ei ole korrektne number.` });
            }
        }

        if (errors.length > 0) {
            const message = getErrorMessageList(errors, (e) => e.code);
            addNewLocalErrorAlert(message, this.getAlertGroup());
            return false;
        }
        else {
            return true;
        }

    }

    onSubmit = () => {
        if (!this.validateOnSubmit()) {
            return;
        }
        clearLocalAlerts();
        const packageTest = {
            ...this.state.packageTest,
            status: this.state.packageTest.status.value,
            standard_comments: this.state.packageTest.standard_comments.map(sc => sc.value),
            location: this.state.packageTest.location ? this.state.packageTest.location.value : null,
        }
        submitPackageTest(packageTest)
            .then(data => {
                clearLocalAlerts();
                if (data.errors && data.errors.length) {
                    const message = getErrorMessageList(data.errors, (e) => e.code);
                    addNewLocalErrorAlert(message, this.getAlertGroup());
                } else {
                    let successMessage = "Testitulemus edukalt esitatud.";
                    if (packageTest.tested_at) {
                        // Already submitted so now updating.
                        successMessage = "Testitulemus edukalt uuendatud.";
                    }
                    addNewLocalSuccessAlert(successMessage, this.getAlertGroup());
                    this.setState({
                        packageTest: data.data,
                    });
                    this.props.onSubmitSuccess(data.data);
                }
            })
            .catch(error => {
                clearLocalAlerts();
                addNewLocalErrorAlert(error.message, this.getAlertGroup());
            })
    }

    toggleCollapsed = () => {
        this.setState((prevState) => {
            return {
                collapsed: !prevState.collapsed,
            }
        });
    }

    getAlertGroup() {
        return "package-test-" + this.state.packageTest.test_id + "-alert-group";
    }

    isReadOnly = () => {
        return this.props.readOnly === true || this.props.packageTest.confirmed_at != null;
    }

    isCollapsedOnMount = () => {
        // check props because state is not set from props yet.
        return this.props.packageTest.tested_at && this.props.packageTest.confirmed_at;
    }

    isWidthRequirementsFilled = () => {
        // Only works because it is a string field, so when value is 0 it is "0" which is not an empty string.
        return !!this.state.packageTest.width && this.state.packageTest.location;
    }

    getIsEAN8 = () => {
        return this.state.packageTest && this.state.packageTest.ean.length === 8;
    }

    getDefaultWidth = () => {
        return this.getIsEAN8() ? PackageTest.EAN8_WIDTH_AT_100 : PackageTest.EAN13_WIDTH_AT_100;
    }

    getDefaultHeight = () => {
        return this.getIsEAN8() ? PackageTest.EAN8_HEIGHT_AT_100 : PackageTest.EAN13_HEIGHT_AT_100;
    }

    getMinValidWidth = (vertical = true) => {
        const percentage = vertical ? PackageTest.MIN_WIDTH_VERTICAL_SCALE_PERCENTAGE : PackageTest.MIN_WIDTH_HORIZONTAL_SCALE_PERCENTAGE;
        return this.getDefaultWidth() * percentage / 100;
    }

    getMinValidHeight = () => {
        return this.getDefaultHeight() * PackageTest.MIN_HEIGHT_SCALE_PERCENTAGE / 100;
    }

    getMaxValidWidth = () => {
        return this.getDefaultWidth() * PackageTest.MAX_WIDTH_SCALE_PERCENTAGE / 100;
    }

    getMaxValidHeight = () => {
        return this.getDefaultHeight() * PackageTest.MAX_HEIGHT_SCALE_PERCENTAGE / 100;
    }

    isWidthValid = () => {
        if (!this.isWidthRequirementsFilled()) {
            return false;
        }
        const width = Number(this.state.packageTest.width);
        const location = this.state.packageTest.location;
        if (location.value === PackageTest.HORIZONTAL_LOCATION_VALUE) {
            return width >= this.getMinValidWidth(false);
        }

        if (location.value === PackageTest.VERTICAL_LOCATION_VALUE) {
            return width >= this.getMinValidWidth(true);
        }

        return false;
    }

    calculateWidthPercentage = () => {
        if (!this.isWidthRequirementsFilled()) {
            return "";
        }
        const width = this.state.packageTest.width;
        const percentage = Number(width) * 100 / this.getDefaultWidth();
        return _.round(percentage, 2).toString();
    }

    isWidthPercentageValidationRequirementsFilled = () => {
        return !!this.state.packageTest.width_percentage && this.state.packageTest.location;
    }

    isWidthPercentageValid = () => {
        if (!this.isWidthPercentageValidationRequirementsFilled()) {
            return false;
        }
        const widthPercentage = Number(this.state.packageTest.width_percentage);
        const location = this.state.packageTest.location;
        if (location.value === PackageTest.HORIZONTAL_LOCATION_VALUE) {
            return PackageTest.MIN_WIDTH_HORIZONTAL_SCALE_PERCENTAGE <= widthPercentage && widthPercentage <= PackageTest.MAX_WIDTH_SCALE_PERCENTAGE;
        }

        if (location.value === PackageTest.VERTICAL_LOCATION_VALUE) {
            return PackageTest.MIN_WIDTH_VERTICAL_SCALE_PERCENTAGE <= widthPercentage && widthPercentage <= PackageTest.MAX_WIDTH_SCALE_PERCENTAGE;
        }

        return false;
    }

    isHeightValidationRequirementsFilled = () => {
        // Only works because it is a string field, so when value is 0 it is "0" which is not an empty string.
        return !!this.state.packageTest.height && this.state.packageTest.location;
    }

    isHeightValid = () => {
        if (!this.isHeightValidationRequirementsFilled()) {
            return false;
        }

        const height = Number(this.state.packageTest.height);
        if (this.state.packageTest.location.value === PackageTest.VERTICAL_LOCATION_VALUE) {
            return height >= PackageTest.EAN13_MIN_HEIGHT_VERTICAL;
        } else {
            return height >= PackageTest.EAN13_MIN_HEIGHT_HORIZONTAL;
        }

    }

    calculateHeightPercentage = () => {
        const height = this.state.packageTest.height;
        if (!height) {
            return "";
        }
        const percentage = Number(height) * 100 / this.getDefaultHeight();
        return _.round(percentage, 2).toString();
    }

    isHeightPercentageValidationRequirementsFilled = () => {
        return !!this.state.packageTest.height_percentage && this.state.packageTest.location;
    }

    isHeightPercentageValid = () => {
        if (!this.isHeightPercentageValidationRequirementsFilled()) {
            return false;
        }
        const height = Number(this.state.packageTest.height);
        const heightPercentage = Number(this.state.packageTest.height_percentage);
        const location = this.state.packageTest.location;

        if (location.value === PackageTest.HORIZONTAL_LOCATION_VALUE) {
            return height >= PackageTest.EAN13_MIN_HEIGHT_HORIZONTAL && heightPercentage <= PackageTest.MAX_HEIGHT_SCALE_PERCENTAGE;
        }

        if (location.value === PackageTest.VERTICAL_LOCATION_VALUE) {
            return height >= PackageTest.EAN13_MIN_HEIGHT_VERTICAL && heightPercentage <= 100;
        }

        return false;
    }

    renderCenteredContent = content => {
        return (
            <CenteredRowCol>
                {content}
            </CenteredRowCol>
        );
    }

    setDefaultLocation = () => {
        if (this.state.packageTest && this.state.packageTest.location === null) {
            const locations = this.props.locations || [];
            for (const location of locations) {
                if (location.value === PackageTest.VERTICAL_LOCATION_VALUE) {
                    this.onChange("location")(location);
                    return;
                }
            }
        }
    }
}

export default withRouter(PackageTest);
