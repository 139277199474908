import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import onScan from "onscan.js";

import { Row, Col } from "reactstrap";
import TestingCompletedConfirmation from "../TestingCompletedConfirmation";

import {
    packagesInTestFilterUpdate,
    packagesInTestFilterClear,
    packagesInTestSortingUpdate,
    packagesInTestPaginationUpdate,
} from "redux/actions/partner/packagesInTest";

import {
    fetchPackagesInTest,
} from "util/Partner";
import {
    clearLocalAlerts,
    addNewLocalSuccessAlert,
    addNewLocalErrorAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";

import Search from "components/Partner/Search/Search";
import Table from "components/Partner/Table/Table";
import ExcelDownload from "components/Partner/ExcelDownload/ExcelDownload";

import "./PackagesInTest.scss";


class PackagesInTestView extends React.Component {
    static propTypes = {
        // redux state
        packagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,
        filters: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,
        packagesListFiltersUsed: PropTypes.object.isRequired,
        // redux actions
        packagesInTestFilterUpdate: PropTypes.func.isRequired,
        packagesInTestFilterClear: PropTypes.func.isRequired,
        packagesInTestSortingUpdate: PropTypes.func.isRequired,
        packagesInTestPaginationUpdate: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props);

        this.datatableNodeRef = null;

        this.state = {
            expanded: [],
        }
    }

    componentDidMount() {
        fetchPackagesInTest(this.props.packagesListFiltersUsed, this.props.location.pathname);

        onScan.attachTo(document, {
            suffixKeyCodes: [13], // enter-key expected at the end of a scan
            onScan: this.onScan, // Alternative to document.addEventListener('scan')
        });
    }

    componentWillUnmount() {
        onScan.detachFrom(document);
    }

    onScan = (sCode, iQty) => {
        clearLocalAlerts();
        const ean = sCode;

        this.props.packagesInTestFilterClear();
        this.props.packagesInTestFilterUpdate("ean", ean);
        fetchPackagesInTest(this.props.filters, this.props.location.pathname)
            .then(data => {
                const packages = this.props.packagesList;

                this.setState({
                    expanded: packages.map(pkg => pkg.package_id),
                })

                if (packages.length > 0) {

                }
            });

        let message = <div>Skänneeriti EAN <b>"{ean}"</b>.</div>;
        addNewLocalInfoAlert(message, this.props.location.pathname);
    }

    render() {
        return (
            <>
                <Search
                    filters={this.props.filters}
                    packagesListLoading={this.props.packagesListLoading}
                    onFilterUpdate={this.onFilterUpdate}
                    onClearFilters={this.onClearFilters}
                    onSearch={this.onSearch}
                    exportButton={
                        <ExcelDownload
                            packagesListFunc={this.getPackagesForExport}
                            filtersUsed={this.props.packagesListFiltersUsed}
                            fileName="EPP Partner - testimise ootel"
                            sheetName="Testimises"
                            onError={this.onExcelError}
                            dateField="created_at"
                        />
                    }
                />

                <Row className="justify-content-end align-items-center mx-3 px-2 mb-3">
                    <Col lg={2} md={4} className="text-right px-1">
                        <TestingCompletedConfirmation isRetest={false}></TestingCompletedConfirmation>
                    </Col>
                </Row>

                <Table
                    packagesList={this.props.packagesList}
                    packagesListLoading={this.props.packagesListLoading}
                    onPackageTestSubmitSuccess={this.onPackageTestSubmitSuccess}
                    getDatatableNode={this.updateDatatableNode}
                    dateField="created_at"
                    expanded={this.state.expanded}
                    onExpand={this.onExpand}
                    sorting={this.props.sorting}
                    pagination={this.props.pagination}
                    onPaginationChange={this.onPaginationChange}
                    onSort={this.onSort}
                />
            </>
        );
    }

    updateDatatableNode = node => {
        this.datatableNodeRef = node;
    }

    getPackagesForExport = () => {
        try {
            //return this.datatableNodeRef.table.props.data; // Currently displayed sorted rows in datatable.
            return this.datatableNodeRef.paginationContext.props.data // Sorted, also cointains hidden rows hidden by pagination.
        } catch (err) {
            return [];
        }
    }

    onExcelError = () => {
        clearLocalAlerts();
        addNewLocalErrorAlert("Excel faili loomine ebaõnnestus!", this.props.location.pathname, true)
    }

    onPackageTestSubmitSuccess = packageTest => {
        clearLocalAlerts();
        addNewLocalSuccessAlert(`Pakendi test edukalt esitatud ${packageTest.status.label} staatusega.`, this.props.location.pathname);
        fetchPackagesInTest(this.props.packagesListFiltersUsed, this.props.location.pathname);
    }

    onFilterUpdate = (key, value) => {
        this.props.packagesInTestFilterUpdate(key, value);
    }

    onClearFilters = () => {
        //clearLocalAlerts();
        this.props.packagesInTestFilterClear();
    }

    onSearch = () => {
        clearLocalAlerts();
        fetchPackagesInTest(this.props.filters, this.props.location.pathname);
    }

    onExpand = (row, isExpand, rowIndex, e) => {
        if (isExpand) {
            this.setState(() => ({
                expanded: [...this.state.expanded, row.package_id]
            }));
        } else {
            this.setState(() => ({
                expanded: this.state.expanded.filter(x => x !== row.package_id)
            }));
        }
    }

    onPaginationChange = (page, sizePerPage) => {
        const currentPagination = this.props.pagination;
        const newPagination = { ...currentPagination, page, sizePerPage };
        this.props.packagesInTestPaginationUpdate(newPagination);
    }

    onSort = (dataField, order) => this.props.packagesInTestSortingUpdate({ dataField, order });
}

const mapStateToProps = state => {
    return {
        packagesList: state.partner.packagesInTest.packagesList,
        packagesListLoading: state.partner.packagesInTest.packagesListLoading,
        packagesListFiltersUsed: state.partner.packagesInTest.packagesListFiltersUsed,
        filters: state.partner.packagesInTest.filters,
        sorting: state.partner.packagesInTest.sorting,
        pagination: state.partner.packagesInTest.pagination,
    }
}

const reduxActions = {
    packagesInTestFilterUpdate,
    packagesInTestFilterClear,
    packagesInTestSortingUpdate,
    packagesInTestPaginationUpdate,
}

export default withRouter(connect(mapStateToProps, reduxActions)(PackagesInTestView));
