import React from "react";
import { withRouter, Link } from "react-router-dom";
import { Col, Container, Row, Card, CardBody, Button, Form } from "reactstrap";

import axios from "../../axios";
import { CHANGE_PASSWORD_TOKEN, CHANGE_PASSWORD } from "config/Api";
import urls from "config/urls";

import BrandLogoText from "components/Misc/BrandLogoText";
import CustomInput from "components/Form/Input";
import Alerts from "components/Alerts/Alerts";
import { createAlertObject, alertContext, alertType } from "util/Alerts";

import "./ChangePassword.scss";
import { getServerErrorMessage } from "util/Errors";

const CHANGE_PASSWORD_ALERT_GROUP = "changePasswordGroup";

class ChangePasswordView extends React.Component {
    state = {
        passwordOne: "",
        passwordTwo: "",
        key: null,
        alerts: [],
        passwordChangeSuccess: false,
        fetchKeyFailed: false,
    };

    componentDidMount() {
        const uid = this.props.match.params.uid;
        const token = this.props.match.params.token;

        this.getKey(uid, token);
    }

    render() {
        return (
            <Container className="align-middle">
                <Row className="text-center justify-content-center">
                    <Col>
                        <Card className="change-password-card">
                            <CardBody>
                                <div>
                                    <BrandLogoText />
                                    <p className="change-password-heading">
                                        Muuda parooli
                                    </p>
                                    <p className="change-password-select-type d-none">
                                        Sisesta uus parool
                                    </p>
                                </div>
                                {this.renderContent()}
                                <Row className="text-center">
                                    <Col>
                                        <Link to={urls.LOGIN}>Logi sisse</Link>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        );
    }

    renderContent() {
        if (this.state.fetchKeyFailed || this.state.passwordChangeSuccess) {
            return this.renderAlerts();
        }

        return this.renderForm();
    }

    renderAlerts() {
        return (
            <Alerts
                manualAlerts={this.state.alerts}
                hideGlobal={true}
                filterGroups={[CHANGE_PASSWORD_ALERT_GROUP]}
                className={`text-left ${this.state.alerts.length ? "mb-2" : "mb-0"
                    }`}
            />
        );
    }

    renderForm() {
        return (
            <Form
                onSubmit={this.onSubmit}
                id="change-password-form"
                className="text-left"
            >
                <Row className="mb-2">
                    <Col>
                        <CustomInput
                            value={this.state.passwordOne}
                            onChange={(value) =>
                                this.setState({ passwordOne: value })
                            }
                            type="password"
                            labelText="Uus parool"
                            placeholder="Uus parool"
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                        <CustomInput
                            value={this.state.passwordTwo}
                            onChange={(value) =>
                                this.setState({ passwordTwo: value })
                            }
                            type="password"
                            labelText="Korda parooli"
                            placeholder="Korda parooli"
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                {this.renderAlerts()}
                <Row className="mb-4">
                    <Col>
                        <Button
                            block
                            color="success"
                            form="change-password-form"
                        >
                            Muuda
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    resetAlerts = () => {
        this.setState({ alerts: [] });
    };

    addAlert = (alert) => {
        this.setState((prevState) => {
            return {
                alerts: [...prevState.alerts, alert],
            };
        });
    };

    onSubmit = (e) => {
        e.preventDefault();
        this.resetAlerts();

        if (this.state.passwordOne !== this.state.passwordTwo) {
            this.addAlert(
                createAlertObject(
                    alertContext.LOCAL,
                    alertType.ERROR,
                    CHANGE_PASSWORD_ALERT_GROUP,
                    "Paroolid on erinevad"
                )
            );
            return;
        }

        this.changePassword(
            this.state.key,
            this.state.passwordOne,
            this.state.passwordTwo
        );
    };

    getKey = (uid, token) => {
        const endpoint = `${CHANGE_PASSWORD_TOKEN}${uid}/${token}`;
        this.setState({ loading: true });
        axios
            .get(endpoint)
            .then((response) => {
                this.setState({
                    loading: false,
                    key: response.data.key,
                });
            })
            .catch((error) => {
                //const errorMessage = getServerErrorMessage(error);
                this.setState({
                    loading: false,
                    fetchKeyFailed: true,
                });
                const message = "Antud link ei ole aktiivne";
                this.addAlert(
                    createAlertObject(
                        alertContext.LOCAL,
                        alertType.ERROR,
                        CHANGE_PASSWORD_ALERT_GROUP,
                        message
                    )
                );
            });
    };

    changePassword = (key, new_password1, new_password2) => {
        const data = { new_password1, new_password2 };
        const config = {
            headers: {
                Authorization: `Token ${key}`,
            },
        };

        axios
            .post(CHANGE_PASSWORD, data, config)
            .then((response) => {
                this.setState({ loading: false, passwordChangeSuccess: true });
                const message = (
                    <span>
                        Parool edukalt muudetud. Pöördu tagasi{" "}
                        <Link to={urls.LOGIN_WITH_EMAIL}>esilehele</Link>, et sisse logida.
                    </span>
                );
                this.addAlert(
                    createAlertObject(
                        alertContext.LOCAL,
                        alertType.SUCCESS,
                        CHANGE_PASSWORD_ALERT_GROUP,
                        message
                    )
                );
            })
            .catch((error) => {
                const errorMessage = getServerErrorMessage(error);
                if (error.response && error.response.data) {
                    const data = error.response.data;
                    if (data.detail) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                CHANGE_PASSWORD_ALERT_GROUP,
                                data.detail
                            )
                        );
                    }
                    if (data.new_password1) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                CHANGE_PASSWORD_ALERT_GROUP,
                                `Parool: ${data.new_password1.join(" ")}`
                            )
                        );
                    }
                    if (data.new_password2) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                CHANGE_PASSWORD_ALERT_GROUP,
                                `Kordusparool: ${data.new_password2.join(" ")}`
                            )
                        );
                    }
                    if (
                        !data.detail &&
                        !data.new_password1 &&
                        !data.new_password2
                    ) {
                        this.addAlert(
                            createAlertObject(
                                alertContext.LOCAL,
                                alertType.ERROR,
                                CHANGE_PASSWORD_ALERT_GROUP,
                                errorMessage
                            )
                        );
                    }
                } else {
                    this.addAlert(
                        createAlertObject(
                            alertContext.LOCAL,
                            alertType.ERROR,
                            CHANGE_PASSWORD_ALERT_GROUP,
                            errorMessage
                        )
                    );
                }
            });
    };
}

export default withRouter(ChangePasswordView);
