import axios from "../axios";
import {
    COMPANY_DATA,
    CONTRACTS,
    REPRESENTATION_OPTIONS,
    USER_DATA,
    USER_SETTINGS,
    USERS,
    ADD_USER,
    REMOVE_USER,
} from "../config/Api";
import store from "../redux/store";
import {
    companyProfileFetchFail,
    companyProfileFetchStart,
    companyProfileFetchSuccess,
    personalProfileFetchFail,
    personalProfileFetchStart,
    personalProfileFetchSuccess,
    contractsFetchFail,
    contractsFetchStart,
    contractsFetchSuccess,
    usersFetchFail,
    usersFetchStart,
    usersFetchSuccess,
    userUpdateFail,
    userUpdateStart,
    userUpdateSuccess,
    userSettingsFetchStart,
    userSettingsFetchSuccess,
    userSettingsFetchFail,
    userSettingsPutStart,
    userSettingsPutSuccess,
    userSettingsPutFail,
} from "redux/actions/profile";
import {
    representationOptionsFetchFail,
    representationOptionsFetchStart,
    representationOptionsFetchSuccess,
} from "../redux/actions/profile";
import { getServerErrorMessage } from "util/Errors";

export function fetchPersonalProfile() {
    store.dispatch(personalProfileFetchStart());
    return axios
        .get(USER_DATA)
        .then((resp) => {
            store.dispatch(personalProfileFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(personalProfileFetchFail(errorMessage));
            //throw Error(errorMessage); // Not handled yet
        });
}

export function fetchCompanyProfile() {
    store.dispatch(companyProfileFetchStart());
    return axios
        .get(COMPANY_DATA)
        .then((resp) => {
            store.dispatch(companyProfileFetchSuccess(resp.data));
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(companyProfileFetchFail(errorMessage));
            // throw Error(errorMessage); // Not handled yet
        });
}

export function fetchRepresentationOptions() {
    store.dispatch(representationOptionsFetchStart());
    return axios
        .get(REPRESENTATION_OPTIONS)
        .then((resp) => {
            store.dispatch(representationOptionsFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(representationOptionsFetchFail(err));
        });
}

export function fetchContracts() {
    store.dispatch(contractsFetchStart());
    return axios
        .get(CONTRACTS)
        .then((resp) => {
            store.dispatch(contractsFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(contractsFetchFail(err));
        });
}

export function updateCompanyProfile(profile) {
    return axios
        .put(COMPANY_DATA, profile)
        .then((resp) => {
            store.dispatch(companyProfileFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function updatePersonalProfile(profile) {
    return axios
        .put(USER_DATA, profile)
        .then((resp) => {
            store.dispatch(personalProfileFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function fetchUsers() {
    store.dispatch(usersFetchStart());
    return axios
        .get(USERS)
        .then((resp) => {
            store.dispatch(usersFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(usersFetchFail(err));
        });
}

export function updateUserInfo(user) {
    store.dispatch(userUpdateStart());
    return axios
        .put(USERS, {
            user_type_id: user.id,
            type: user.type,
            name: user.name,
            position: user.position,
            email: user.email,
            last_name: user.last_name,
            personal_code: user.personal_code,
            phone: user.phone,
            is_verified: user.is_verified,
        })
        .then((resp) => {
            store.dispatch(userUpdateSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            store.dispatch(userUpdateFail(err));
            throw Error(getServerErrorMessage(err));
        });
}

export function fetchUserSettings() {
    store.dispatch(userSettingsFetchStart());
    return axios
        .get(USER_SETTINGS)
        .then((resp) => {
            store.dispatch(userSettingsFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            store.dispatch(userSettingsFetchFail(err));
            throw Error(getServerErrorMessage(err));
        });
}

export function updateUserSettings(userSettings) {
    store.dispatch(userSettingsPutStart());
    return axios
        .put(`${USER_SETTINGS}${userSettings.id}/`, userSettings)
        .then((resp) => {
            store.dispatch(userSettingsPutSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            store.dispatch(userSettingsPutFail(err));
            throw Error(getServerErrorMessage(err));
        });
}

export function addUser(userData) {
    const user = {
        is_client_admin: userData.isAdmin,
        first_name: userData.firstName,
        last_name: userData.lastName,
        personal_code: userData.personalCode,
        phone: userData.phone,
        email: userData.email,
        profession: userData.profession,
        partner_id: userData.partnerId,
        client_id: userData.clientId,
    };

    return axios
        .post(ADD_USER, user)
        .then((resp) => {
            return resp;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}

export function removeUser(id) {
    return axios
        .delete(REMOVE_USER + id + "/")
        .then((resp) => {
            return resp;
        })
        .catch((err) => {
            throw Error(getServerErrorMessage(err));
        });
}
