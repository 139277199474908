export const BASE_URL_API_CLIENT_CERTIFICATE =
    "https://epp-test-id-auth.flowit.ee/api/v1/";

// User
export const REST_AUTH_LOGIN = "rest-auth/login/";
export const REST_AUTH_LOGOUT = "rest-auth/logout/";
export const REST_AUTH_USER = "rest-auth/user/";
export const USERS = "users/";
export const CHANGE_PASSWORD_TOKEN = "token/"; // "token/<uid>/<token>/"
export const CHANGE_PASSWORD = "rest-auth/password/change/";
export const RESET_PASSWORD = "rest-auth/password/reset/";
export const CHANGE_ACTIVE_CLIENT = "active-client/";
export const CHANGE_ACTIVE_PARTNER = "active-partner/";
export const CHANGE_ACTIVE_EPP = "active-epp/";

// General
export const ALL_CLIENTS = "all-clients/";
export const STANDARD_COMMENTS = "partner/standard-comment/";
export const LOCATIONS = "partner/location/";
export const LANGUAGE = "profile/language/<user_type_id>/";

// Profile
export const USER_DATA = "profile/user-data/";
export const USER_SETTINGS = "/profile/user-settings/"; // PUT: /profile/user-settings/{usertype.id}
export const COMPANY_DATA = "profile/business-data/";
export const CLIENT_WARRANT_SHOW = "profile/show-warranted-info-to-client/";
export const CLIENT_WARRANT_USERS = "profile/warrant-users/"; // GET
export const ADD_USER = "profile/add-user/";
export const REMOVE_USER = "users/"; // DELETE "users/<user_id>"

// Contracts
export const CONTRACTS = "document/contracts/";

// Authentication
export const ID_CARD_AUTH = "authentication/id/";
export const MID_AUTH_START = "authentication/mid/start/";
export const MID_AUTH_STATUS = "authentication/mid/status/";

// Signing
export const MID_SIGN_START = "sign/mid/start/";
export const MID_SIGN_STATUS = "sign/mid/status/";
export const ID_SIGN_START = "sign/id/start/";
export const ID_SIGN_FINISH = "sign/id/finish/";

//dokobit
export const AUTH_ID_CARD_V2 = "/dokobit/auth/id-card-v2/";
export const AUTH_ID_CARD = "/dokobit/auth/id-card/"; // Deprecated
export const AUTH_ID_CARD_VERIFY = "/dokobit/id-card-auth/status/"; // Deprecated
export const AUTH_MOBILE_ID = "/dokobit/auth/mobile/";
export const AUTH_SMART_ID = "/dokobit/auth/smart/";
export const DIGITAL_AUTH_STATUS = "/dokobit/digital-auth/status/";

export const SIGN_ID_CARD = "/dokobit/sign/id-card";
export const SIGN_MOBILE_ID = "/dokobit/sign/mobile";
export const SIGN_SMART_ID = "/dokobit/sign/smart";

export const SIGN_ADMIN_ID_CARD = "/dokobit/sign-container/id-card";
export const SIGN_ADMIN_MOBILE_ID = "/dokobit/sign-container/mobile";
export const SIGN_ADMIN_SMART_ID = "/dokobit/sign-container/smart";

export const DIGITAL_SIGN_STATUS = "/dokobit/digital-sign/status/";

// Package
export const PACKAGE_LIST = "package/list/"; // + client_id
export const EAN_TYPES = "package/ean-types/";
export const PACKAGE_CATEGORIES = "package/categories/";
export const PACKAGE_COLORS = "package/colors/";
export const PACKAGE_TYPES = "package/types/";
export const PACKAGE_VOLUMES = "package/volumes/";
export const PACKAGE_STATUSES = "package/statuses/";
export const PACKAGE_TEST_RESULTS = "partner/test-status/";
export const PACKAGE = "package/";

// Invoices
export const INVOICES = "invoices/";

// Application
export const APPLICATION = "application/";
export const APPLICATION_IN_PROGRESS = "application-in-progress/";

// Reports
export const SALES_LIST = "sales-reports/";
export const GET_SALES_REPORT = "sales-reports/package-list/"; // "sales-reports/package-list/sales_report_id}/"
export const GET_REPAIR_SALES_REPORT = "sales-reports/package-list-repair/"; // "sales-reports/package-list-repair/{sales_report_id}/""
export const CREATE_REPAIR_SALES_REPORT = "sales-reports/repair/";
export const SUBMIT_SALES_REPORT = "sales-reports/submit/";
export const DELETE_REPAIR_SALES_REPORT = "sales-reports/delete-repair-report/"; // delete-repair-report/<sales_report_id>/

export const EXPORT_SALES_REPORT = "sales-reports/export/"; // `sales-reports/export/${id}/`
export const EXPORT_SALES_REPORT_REPAIR = "/sales-reports/export-repair/"; // `/sales-report/export-repair/${id}/`
export const IMPORT_SALES_REPORT = "sales-reports/import/"; // '"sales-reports/import/<sales_report_id>/'
export const IMPORT_SALES_REPORT_REPAIR = "sales-reports/import-repair/"; // 'sales-reports/import-repair/<sales_report_id>/'

export const UPDATE_SALES_REPORT_LINE = "sales-line/"; // `sale-line/${sale_report_id}`
export const UPDATE_REPAIR_SALES_REPORT_LINE = "sales-line/repair-report/"; // `sales-line/repair-report/{sale_report_id}/`

// Summary report
export const GET_SUMMARY_REPORT = "sales-reports/summary/"; // "sales-reports/summary/&..."
export const EXPORT_SUMMARY_REPORT = "sales-reports/summary-export/"; // "sales-reports/summary-export/&..."

// Mass report
export const GET_MASS_REPORT = "sales-reports/mass/"; // "sales-reports/summary/&..."
export const EXPORT_MASS_REPORT = "sales-reports/mass-export/"; // "sales-reports/mass-export/&..."

// Companies
export const COMPANIES = "companies/"; // {name}

// Business register
export const DETAIL = "/business-register/company-details/";

// Contracts
export const CREATE_CONTRACT = "document/create-contract/";
export const SIGN_CONTRACT = "document/sign-contract/";
export const TERMINATE_CONTRACT = "document/end-contract/<contract_id>/";
export const REPRESENTATION_OPTIONS = "document/basis/";
export const VERIFY_CONTRACT_SIGNER = "administrator/client-user-connection/";

// Dashboard
export const LATEST_PACKAGES = "dashboard/latest-packages/";
export const LATEST_REPORTS = "dashboard/latest-reports/";
export const STATISTICS = "dashboard/statistics/";

// Notifications
export const NOTIFICATIONS = "notifications/";

// Everypay
// Defined directly in src/util/Everypay

// Partner
export const PACKAGES_IN_TEST_OR_RETEST = "partner/packages-in-test/"; // Used also for retesting view. suffix "<package_id>/" to get specific instead of the overall list.
export const PACKAGES_UNCONFIRMED = "partner/packages-unconfirmed/";
export const PACKAGE_TEST = "partner/test/"; // PUT "partner/test/<test_id>";
export const PACKAGES_TESTED = "partner/packages-confirmed/";
export const PACKAGES_TESTING_ALL = "partner/packages-all/";
export const PARTNER_TESTING_COMPLETED = "partner/confirm-testing-completed/";

// Admin
export const ADMIN_APPLICATIONS = "administrator/applications/";
export const ADMIN_SEND_TO_TESTING = "administrator/send-to-testing/";
export const ADMIN_ALL_PACKAGES = "administrator/packages/";
export const ADMIN_TESTING_PACKAGES = "administrator/packages-in-test/";
export const ADMIN_RETESTING_PACKAGES = "administrator/packages-in-retest/";
export const ADMIN_ACTIVATE_PACKAGES = "administrator/activate/";
export const ADMIN_PACKAGE_DETAILS = "administrator/package/<package_id>/";
export const ADMIN_CONFIRM_PACKAGE_TEST = "administrator/confirm-tests/";
export const ADMIN_DEBTS = "administrator/debts/";
export const ADMIN_INVOICES = "administrator/client-invoices/<client_id>/";
export const ADMIN_INVOICE_REMINDER =
    "administrator/send-reminder/<invoice_id>/";
export const ADMIN_INVOICES_SUMMARY = "administrator/send-summary/<client_id>/";
export const ADMIN_CLIENT = "administrator/client/<client_id>/";
export const ADMIN_CLIENT_PACKAGES_SYNCHRONIZE = "administrator/client/<client_id>/sync-packages/";
export const ADMIN_ALL_CLIENTS = "administrator/all-clients/";
export const ADMIN_ALL_PARTNERS = "administrator/all-partners/";
export const ADMIN_ALL_USERS = "administrator/users/";
export const ADMIN_CLIENT_USERS = "administrator/users/<client_id>/";
export const ADMIN_REMOVE_USER = "administrator/user/<user_type_id>/";
export const ADMIN_REMOVE_EPP_USER = "administrator/epp/<user_type_id>/";
export const ADMIN_REMOVE_PARTNER_USER =
    "administrator/partner/<user_type_id>/";
export const ADMIN_CLIENT_CONTRACTS =
    "administrator/client-contracts/<client_id>/";
export const ADMIN_CONTRACT_UPLOAD = "administrator/file-upload/<contract_id>/";
export const ADMIN_CLIENT_WARRANT_SHOW = "administrator/show-warranted-info-to-client/<client_id>/";
export const ADMIN_CLIENT_WARRANT_USERS = "administrator/client/<client_id>/warranted-users/"; // GET list, POST create
export const ADMIN_CLIENT_WARRANT_USER = "administrator/client/<client_id>/warranted-users/<warrant_id>/"; // GET, PUT, DELETE
export const ADMIN_CLIENT_WARRANT_USER_FILE_UPLOAD = "administrator/warrant-file-upload/<warrant_id>/";
// Admin - sales reports
export const ADMIN_ALL_SALES_REPORTS =
    "administrator/sales-reports/<from_date>/<to_date>/"; // YYYY-MM-DD ?client_id=<client_id>
export const ADMIN_UNSUBMITTED_SALES_REPORTS =
    "administrator/not-submitted-sales-reports/<from_date>/<to_date>/"; // YYYY-MM-DD ?client_id=<client_id>
export const ADMIN_UNCONFIRMED_SALES_REPORTS =
    "administrator/not-confirmed-sales-reports/<from_date>/<to_date>/"; // YYYY-MM-DD ?client_id=<client_id>
export const ADMIN_CLIENT_SALES_REPORTS =
    "administrator/sales-reports/<client_id>/";
export const ADMIN_GET_SALES_REPORT =
    "administrator/package-list/<sales_report_id>/";
export const ADMIN_GET_REPAIR_SALES_REPORT =
    "administrator/package-list-repair/<sales_report_id>/";
export const ADMIN_APPROVE_REPAIR_SALES_REPORT = "administrator/unapprove/"; // PUT {sales_report_id,}
export const ADMIN_REPAIR_SALES_REPORT_APPROVAL =
    "administrator/repair-report-approval/"; // PUT {repair_report_id, is_approve}
export const ADMIN_DELETE_SALES_REPORT =
    "administrator/delete-sales-report/"; // delete-sales-report/<sales_report_id>/
//
export const ADMING_GENERATE_PACKAGE_APPLICATION_INVOICE =
    "administrator/generate-invoice-in-nav/"; // POST {ids: []}
// Admin - settings
export const ADMIN_SETTINGS_FETCH_NAV = "administrator/sync-classifiers/";
export const ADMIN_SETTINGS_CATEGORIES = "package/categories/";
export const ADMIN_SETTINGS_TYPES = "package/types/";
export const ADMIN_SETTINGS_COLORS = "package/colors/";
export const ADMIN_SETTINGS_VOLUMES = "package/volumes/";
export const ADMIN_SETTINGS_PROCESS_FEES = "package/process-fees/";
