// This one is directly used in src/axios.js because importing threw errors.
//export const API_BASE_URL = process.env.REACT_APP_API_HOST;
if (!process.env.REACT_APP_API_HOST) {
    throw Error("Environment variable REACT_APP_API_HOST is not defined!");
}

export const ENABLE_REDUX_DEVTOOLS =
    process.env.REACT_APP_ENABLE_REDUX_DEVTOOLS === "true";

export const ENABLE_TEST_VAT_PERCENTAGE = 
process.env.REACT_APP_TEST_VAT_PERCENTAGE === "true";