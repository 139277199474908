import _ from "lodash";
import * as actionTypes from "redux/actions/actionTypes";
//import { updateObject } from "redux/utility";
import { userTypes } from "util/Access";
import { languageTypes } from "util/General";

const initialState = languageTypes.ET;

const updateLanguage = (state, newLanguage) => {
    return newLanguage;
};

const updateLanguageFromUserObject = (state, user) => {
    if (!user) {
        return state;
    }

    const currentUserType = user.active_profile_user_type;

    let languageObjectKey = "user_settings";
    if (currentUserType === userTypes.EPP) {
        languageObjectKey = "epp_user_settings";
    } else if (currentUserType === userTypes.PARTNER) {
        languageObjectKey = "partner_user_settings";
    }

    let activeLanguage = _.get(
        user,
        `${languageObjectKey}.language`,
        languageTypes.ET
    );

    activeLanguage = activeLanguage.toLowerCase();

    if (![languageTypes.ET, languageTypes.EN].includes(activeLanguage)) {
        activeLanguage = languageTypes.ET;
    }

    return updateLanguage(state, activeLanguage);
};

export const language = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LANGUAGE_UPDATE:
            return updateLanguage(state, action.language);
        case actionTypes.GET_AUTH_USER:
            return updateLanguageFromUserObject(state, action.user);
        case actionTypes.LOG_OUT:
            return initialState;
        default:
            return state;
    }
};
