import axios from "../../axios";

import {
    ADMIN_CLIENT,
    ADMIN_CLIENT_CONTRACTS,
    ADMIN_CONTRACT_UPLOAD,
    TERMINATE_CONTRACT,
} from "config/Api";

export const fetchClient = (clientId) => {
    const endpoint = ADMIN_CLIENT.replace("<client_id>", clientId);
    return axios
        .get(endpoint)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const fetchContracts = (clientId) => {
    const endpoint = ADMIN_CLIENT_CONTRACTS.replace("<client_id>", clientId);
    return axios
        .get(endpoint)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const uploadContract = (contractId, contract) => {
    const endpoint = ADMIN_CONTRACT_UPLOAD.replace("<contract_id>", contractId);

    let formdata = new FormData();
    formdata.append("file", contract.file);

    return axios
        .post(endpoint, formdata)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
};

export const terminateContract = (contractId) => {
    const endpoint = TERMINATE_CONTRACT.replace("<contract_id>", contractId);
    return axios
        .put(endpoint)
        .then((resp) => {
            return resp.data;
        })
        .catch((err) => {
            throw err;
        });
};
