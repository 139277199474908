import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { Card, CardHeader, CardBody, Row, Col } from "reactstrap";

import "./NotificationDropdown/NotificationDropdown.scss";
import { fetchNotifications } from "util/Notifications";
import { useSelector } from "react-redux";
import moment from "moment";
import { markNotificationSeen, removeNotification } from "util/Notifications";
import WrappedLoader from "components/Loader/WrappedLoader";
import { ReactComponent as RemoveNotificationIcon } from "assets/icons/close.svg";
import CustomCheckbox from "components/Form/Checkbox";
import { addNewLocalErrorAlert } from "util/Alerts";
import urls from "config/urls";

export default function Notifications(props) {
    const { t } = useTranslation();

    const [showUnreadNotifications, setShowUnreadNotifications] = useState(
        false
    );
    const notificationsToggle = useRef(showUnreadNotifications);

    let notifications = useSelector(
        (state) => state.notifications.notifications
    );
    let loading = useSelector((state) => state.notifications.loading);

    if (showUnreadNotifications) {
        notifications = notifications.filter(
            (notification) => notification.is_seen === !showUnreadNotifications
        );
    }

    useEffect(() => {
        fetchNotifications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onNotificationRead = (notification) => {
        markNotificationSeen(notification.id)
            .then(() => {
                fetchNotifications();
            })
            .catch((err) => {
                addNewLocalErrorAlert(
                    t("notifications.alerts.error.markNotificationReadFailed", "Tekkis viga! Proovi hiljem uuesti."),
                    urls.PROFILE_NOTIFICATIONS
                );
            });
    };

    const onRemoveNotification = (notification) => {
        removeNotification(notification.id)
            .then(() => {
                fetchNotifications();
            })
            .catch((err) => {
                addNewLocalErrorAlert(
                    t("notifications.alerts.error.removeNotificationFailed", "Tekkis viga! Proovi hiljem uuesti."),
                    urls.PROFILE_NOTIFICATIONS
                );
            });
    };

    const onNotificationFilterClick = () => {
        setShowUnreadNotifications(!showUnreadNotifications);
    };

    if (loading) {
        return <WrappedLoader />;
    }

    return (
        <Card className="users-card">
            <CardHeader>
                <Row className="align-items-center">
                    <Col>
                        <span className="heading">{t("notifications.header", "Teavitused")}</span>
                    </Col>
                    <Col className="float-right text-right col-auto">
                        <CustomCheckbox
                            inline
                            value={showUnreadNotifications}
                            labelText={t("notifications.unreadCheckbox", "Lugemata")}
                            ref={notificationsToggle}
                            onClick={onNotificationFilterClick}
                        />
                    </Col>
                </Row>
            </CardHeader>
            <CardBody className="pt-0">
                {notifications.length === 0 && (
                    <div className="ml-2">{t("notifications.noNotificationsText", "Teavitused puuduvad!")}</div>
                )}
                {notifications.map((notification) => {
                    return (
                        <div key={"notification-" + notification.id}>
                            <hr />
                            <Row className="pl-2 pr-2">
                                <Col>
                                    <Row className="mb-2">
                                        <Col>
                                            <span className="semibold-12 color-dark-grey">
                                                {notification.message}
                                            </span>
                                            {!notification.is_seen && (
                                                <span
                                                    className="semibold-12 color-info pl-2 btn"
                                                    onClick={() =>
                                                        onNotificationRead(
                                                            notification
                                                        )
                                                    }
                                                >
                                                    {t("notifications.markAsReadButton", "Märgi loetuks")}
                                                </span>
                                            )}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col className="semibold-12 color-gray-light">
                                            {moment(
                                                notification.created_at
                                            ).format("DD.MM.YYYY")}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col
                                    xs="auto"
                                    className="text-right d-flex justify-content-center align-items-center"
                                >
                                    <span
                                        className="btn"
                                        onClick={() =>
                                            onRemoveNotification(notification)
                                        }
                                    >
                                        <RemoveNotificationIcon />
                                    </span>
                                </Col>
                            </Row>
                        </div>
                    );
                })}
            </CardBody>
        </Card>
    );
}
