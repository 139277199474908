import * as actionTypes from "redux/actions/actionTypes";

export const packagesInRetestFetchStart = () => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_FETCH_START,
    };
};

export const packagesInRetestFetchSuccess = (
    packagesList,
    filters,
    unfilteredPackagesList
) => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_FETCH_SUCCESS,
        packagesList,
        filters,
        unfilteredPackagesList,
    };
};

export const packagesInRetestFetchFail = (error) => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_FETCH_FAIL,
        error,
    };
};

export const packagesInRetestFilterUpdate = (key, value) => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const packagesInRetestFilterClear = (format) => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_FILTER_CLEAR,
        format,
    };
};

export const packagesInRetestSortingUpdate = (sorting) => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_SORTING_UPDATE,
        sorting,
    };
};

export const packagesInRetestPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.PACKAGES_IN_RETEST_PAGINATION_UPDATE,
        pagination,
    };
};