import React, { Component } from "react";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import _ from "lodash";
import {
    Form,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    CardHeader,
    Row,
    Col,
    CardBody,
    Card,
    FormGroup,
    CardFooter,
} from "reactstrap";

import Input from "../../Form/Input";
import Select from "../../Form/Select";

import BootstrapTable from "react-bootstrap-table-next";

import { ReactComponent as DeleteIcon } from "assets/icons/delete-row.svg";
import WrappedLoader from "components/Loader/WrappedLoader";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import ConfirmationModal from "components/Form/Modals/ConfirmationModal";
import Alert from "components/Alerts/Alert/Alert";

import "./AddPackageModal.scss";
import { connect } from "react-redux";
import {
    addNewPackage,
    fetchPackageVolume,
    fetchPendingApplication,
    removePackage,
    submitAddPackageApplication,
    ADD_PACKAGE_ALERT_GROUP,
} from "util/Packages";
import urls from "config/urls";

import Alerts from "components/Alerts/Alerts";
import {
    clearLocalAlerts,
    addNewLocalSuccessAlert,
    addNewLocalErrorAlert,
    alertType,
} from "util/Alerts";
import { getServerErrorMessage, getErrorMessageList } from "util/Errors";
import { currencyFormatter, isNumeric } from "util/General";
import { withTranslation, Trans } from "react-i18next";

class AddPackageModal extends Component {
    static addPackageInputFields = ["ean", "packageName", "packageWeight"];
    static addPackageSelectFields = [
        "eanType",
        "packageCategory",
        "packageVolume",
        "packageColor",
        "packageType",
    ];
    static ONLY_COLORLESS_PACKAGE_TYPE_IDS = [3, 4];
    static COLOR_COLORLESS_ID = 1;
    static INTERNATIONAL_EAN_TYPE_ID = 2;

    constructor(props) {
        super(props);
        this.state = {
            modal: true,
            pendingApplication: [],
            pendingApplicationLoading: [],
            packagesList: [],
            packageVolumes: [],
            ean: "",
            eanType: this.getDefaultEanType(),
            packageWeight: "",
            packageCategory: null,
            packageVolume: null,
            packageColor: null,
            packageName: "",
            addPackageLoading: false,
            isConfirmApplicationOpen: false,
            submitApplicationLoading: false,
            invalidFields: [],
            validatedOnce: false,
        };
    }

    componentDidMount() {
        clearLocalAlerts();
        this.setState({ pendingApplicationLoading: true });
        fetchPendingApplication()
            .then((application) => {
                this.setState({
                    pendingApplication: application,
                    pendingApplicationLoading: false,
                });
            })
            .catch((err) => {
                this.setState({ pendingApplicationLoading: false });
                addNewLocalErrorAlert(
                    getServerErrorMessage(err),
                    ADD_PACKAGE_ALERT_GROUP
                );
            });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.validatedOnce) {
            let addPackageFieldsChanged = false;
            const addPackageFields = [
                ...AddPackageModal.addPackageInputFields,
                ...AddPackageModal.addPackageSelectFields,
            ];
            for (const field of addPackageFields) {
                if (prevState[field] !== this.state[field]) {
                    addPackageFieldsChanged = true;
                    break;
                }
            }
            if (addPackageFieldsChanged) {
                this.validateAddPackage();
            }
        }

        this.checkIfPackageVolumeValidOption(prevState);
        this.checkIfPackageColorValidOption(prevState);
        this.checkIfEanTypeNeedsDefaultValue(prevProps);
    }

    toggle = () =>
        this.setState(
            { modal: !this.state.modal },
            this.props.history.push(urls.PACKAGES)
        );

    handleChange = (name) => (value) => {
        this.setState({ [name]: value });
    };

    handleSelectChange = (name) => (selectedObject) => {
        this.setState(
            {
                [name]: selectedObject,
            },
            () => {
                // Update volume options when package type is selected
                if (name === "packageType") {
                    //clearLocalAlerts();
                    fetchPackageVolume(selectedObject.id)
                        .then((packageVolumes) => {
                            this.setState({
                                packageVolumes: packageVolumes,
                            });
                        })
                        .catch((err) => {
                            addNewLocalErrorAlert(
                                err.message,
                                ADD_PACKAGE_ALERT_GROUP
                            );
                        });
                }
            }
        );
    };

    getDefaultEanType = () => {
        const { eanTypes } = this.props;
        if (!eanTypes) return null;

        return eanTypes.find(type => type.id === AddPackageModal.INTERNATIONAL_EAN_TYPE_ID) || null;
    }

    checkIfEanTypeNeedsDefaultValue = (prevProps) => {
        const { eanTypes } = this.props;
        const currentEanType = this.state.eanType;

        if (!eanTypes || eanTypes === prevProps.eanTypes) return;
        if (![null, undefined, AddPackageModal.INTERNATIONAL_EAN_TYPE_ID].includes(currentEanType?.id)) return;

        const defaultEanType = this.getDefaultEanType();
        if (!defaultEanType) return;

        this.setState({ eanType: defaultEanType });
    }

    checkIfPackageVolumeValidOption = (prevState) => {
        if (
            !this.state.packageVolume ||
            prevState.packageVolumes === this.state.packageVolumes
        ) {
            return;
        }

        const selectedVolumeId = this.state.packageVolume.id;

        if (this.state.packageVolumes || this.state.packageVolumes.length > 0) {
            for (const option of this.state.packageVolumes) {
                if (option.id === selectedVolumeId) {
                    return;
                }
            }
        }

        this.setState({ packageVolume: null });
    };

    checkIfPackageColorValidOption(prevState) {
        const packageColor = this.state.packageColor;
        if (!packageColor || prevState.packageType === this.state.packageType) {
            return;
        }

        if (this.isColorOptionDisabled(packageColor)) {
            let newPackageColor = null;
            if (packageColor.id !== AddPackageModal.COLOR_COLORLESS_ID) {
                newPackageColor = this.getColorOptionById(AddPackageModal.COLOR_COLORLESS_ID);
            }

            this.setState({
                packageColor: newPackageColor,
            });
        }
    }

    getColorOptionById = (id) => {
        const colorOptions = this.props.packageColors || [];

        for (const option of colorOptions) {
            if (option.id === id) {
                return option;
            }
        }

        return null;
    };

    isColorOptionDisabled = (option) => {
        const packageType = this.state.packageType;
        if (!packageType) {
            return false;
        }
        const onlyColorlessValid = AddPackageModal.ONLY_COLORLESS_PACKAGE_TYPE_IDS.includes(
            packageType.id
        );

        if (onlyColorlessValid) {
            return option.id !== AddPackageModal.COLOR_COLORLESS_ID;
        } else {
            return option.id === AddPackageModal.COLOR_COLORLESS_ID;
        }
    };

    validateAddPackage = (clearPreviousAlerts = true) => {
        if (clearPreviousAlerts) {
            clearLocalAlerts();
        }
        const inputFields = AddPackageModal.addPackageInputFields;
        const selectFields = AddPackageModal.addPackageSelectFields;
        const invalidFields = [];

        for (const field of inputFields) {
            if (!this.state[field]) {
                invalidFields.push(field);
            }
        }
        for (const field of selectFields) {
            if (!this.state[field] || !this.state[field].id) {
                invalidFields.push(field);
            }
        }

        // If package weight has value, make sure it is numeric.
        if (!invalidFields.includes("packageWeight")) {
            if (!isNumeric(this.state["packageWeight"])) {
                invalidFields.push("packageWeight");
                addNewLocalErrorAlert(
                    this.props.t("packages.addPackageModal.alert.weightNumericValidationText", "Pakendi kaal peab olema number."),
                    ADD_PACKAGE_ALERT_GROUP
                );
            }
        }

        this.setState({
            invalidFields,
            validatedOnce: true,
        });

        if (invalidFields.length === 0) {
            return true;
        } else {
            addNewLocalErrorAlert(
                this.props.t("packages.addPackageModal.alert.genericValidationText", "Kõik punasega märgitud väljad peavad olema täidetud."),
                ADD_PACKAGE_ALERT_GROUP
            );
            return false;
        }

        //return invalidFields.length === 0;
    };

    isInvalid = (field) => {
        return this.state.invalidFields.includes(field);
    };

    addPackage = () => {
        clearLocalAlerts();
        if (!this.validateAddPackage()) {
            return;
        }
        this.setState({ addPackageLoading: true });
        addNewPackage(
            this.props.activeClientId,
            this.state.ean,
            this.state.eanType.id,
            this.state.packageCategory.id,
            this.state.packageType.id,
            this.state.packageVolume.id,
            this.state.packageWeight,
            this.state.packageColor.id,
            this.state.packageName
        )
            .then(() => {
                // Update pending packages list
                this.clearFormFields();
                this.setState({
                    pendingApplicationLoading: true,
                    addPackageLoading: false,
                });
                fetchPendingApplication()
                    .then((application) => {
                        this.setState({
                            pendingApplication: application,
                            pendingApplicationLoading: false,
                        });
                    })
                    .then(() => {
                        // Update packageList in store
                        //fetchPackagesList(this.props.activeClientId);
                    });
            })
            .catch((err) => {
                this.setState({
                    pendingApplicationLoading: false,
                    addPackageLoading: false,
                });
                addNewLocalErrorAlert(
                    getServerErrorMessage(err),
                    ADD_PACKAGE_ALERT_GROUP
                );
            });
    };

    clearFormFields = () => {
        this.setState({
            ean: "",
            eanType: this.getDefaultEanType(),
            packageType: null,
            packageWeight: "",
            packageCategory: null,
            packageVolume: null,
            packageColor: null,
            packageName: "",
            invalidFields: [],
            validatedOnce: false,
        });
    };

    onRemovePackage = (packageId) => {
        clearLocalAlerts();
        removePackage(packageId)
            .then(() => {
                // Remove package from database
                this.setState({ pendingApplicationLoading: true });
                fetchPendingApplication().then((application) => {
                    this.setState({
                        pendingApplication: application,
                        pendingApplicationLoading: false,
                    });
                });
            })
            .catch((err) => {
                this.setState({ pendingApplicationLoading: true });
                addNewLocalErrorAlert(
                    getServerErrorMessage(err),
                    ADD_PACKAGE_ALERT_GROUP
                );
            });
    };

    submitPackageApplication = (errorAlertGroup) => {
        errorAlertGroup = ADD_PACKAGE_ALERT_GROUP; // errorAlertGroup || ADD_PACKAGE_ALERT_GROUP;
        clearLocalAlerts();
        const { t } = this.props;
        let content = (
            <div className="align-items-center w-100 d-flex">
                <span>{t("packages.addPackageModal.alert.submitApplicationSuccess.text", "Taotlus edukalt esitatud! Palun tasuge loodud arve 5 päeva jooksul.")}</span>
                <NavLink
                    to={urls.INVOICES}
                    className="btn-outline-secondary align-items-center color-success-light-bg d-flex ml-auto"
                >
                    {t("packages.addPackageModal.alert.submitApplicationSuccess.redirectToInvoicesButton", "Arvet tasuma")}
                </NavLink>
            </div>
        );

        this.setState({ submitApplicationLoading: true });
        submitAddPackageApplication(
            this.state.pendingApplication.map((pendingPackage) => {
                return pendingPackage.id;
            })
        )
            .then((resp) => {
                clearLocalAlerts();

                if (resp.failed_products && resp.failed_products.length > 0) {
                    if (resp.failed_products.length < this.state.pendingApplication.length) {
                        const someSuccessContent = (
                            <div className="align-items-center w-100 d-flex">
                                <span>{t("packages.addPackageModal.alert.submitApplicationPartialSuccess.text", "Taotlus loodi osade pakenditega! Palun tasuge loodud arve 5 päeva jooksul.")}</span>
                                <NavLink
                                    to={urls.INVOICES}
                                    className="btn-outline-secondary align-items-center color-success-light-bg d-flex ml-auto"
                                >
                                    {t("packages.addPackageModal.alert.submitApplicationPartialSuccess.redirectToInvoicesButton", "Arvet tasuma")}
                                </NavLink>
                            </div>
                        );
                        addNewLocalSuccessAlert(someSuccessContent, errorAlertGroup, false);
                    }
                    // const allProductsFailed = resp.failed_products.length === this.state.pendingApplication.length;
                    const topText = t("packages.addPackageModal.alert.submitApplicationFailure.textBeforeList", "Osad pakendid jäid registreerimata järgnevatel põhjustel:");
                    const errorMessage = getErrorMessageList(
                        resp.failed_products,
                        (fp) => `EAN: ${fp.ean} - ${fp.response || ""}`,
                        topText
                    );
                    addNewLocalErrorAlert(
                        errorMessage,
                        errorAlertGroup
                    );

                    const failedIds = resp.failed_products.map((fp) => fp.id);
                    this.setState((prevState) => {
                        return {
                            pendingApplication: prevState.pendingApplication.filter(
                                (application) =>
                                    failedIds.includes(application.id)
                            ),
                            submitApplicationLoading: false,
                            isConfirmApplicationOpen: false,
                        };
                    });
                } else {
                    addNewLocalSuccessAlert(content, urls.PACKAGES, false);
                    this.toggle();
                }
            })
            .catch((err) => {
                clearLocalAlerts();

                this.setState({ submitApplicationLoading: false, isConfirmApplicationOpen: false });
                addNewLocalErrorAlert(
                    getServerErrorMessage(err),
                    errorAlertGroup
                );
            });
    };

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    centered
                    size="xl"
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    className="add-package-modal"
                >
                    <ModalHeader toggle={this.toggle} className="modal-header">
                        {t("packages.addPackageTitle", "Toote lisamine")}
                    </ModalHeader>
                    <ModalBody className="package-modal-body">
                        <Card className="package-card">
                            <CardHeader className="package-table-card-header">
                                <Alerts
                                    hideGlobal
                                    filterGroups={[ADD_PACKAGE_ALERT_GROUP]}
                                />
                                {this.renderInvoiceInfoAlert()}
                                {this.renderForm()}
                            </CardHeader>
                            <CardBody className="p-0">
                                {this.renderTable()}
                            </CardBody>
                            <CardFooter className="card-footer">
                                {this.renderConfirmApplicationModal()}
                            </CardFooter>
                        </Card>
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderForm() {
        const { t } = this.props;
        return (
            <Form>
                <Row className="pl-4 pr-4">
                    <Col xs={6} lg={2} className="pl-1 pr-1">
                        <Input
                            value={this.state.ean}
                            placeholder={t(
                                "packages.filter.add",
                                "Lisa"
                            )}
                            labelText={t(
                                "packages.filter.ean",
                                "EAN"
                            )}
                            name="ean"
                            onBlur={this.handleChange(
                                "ean"
                            )}
                            trimOnBlur
                            required
                            invalid={this.isInvalid(
                                "ean"
                            )}
                        />
                    </Col>
                    <Col xs={6} lg={2} className="pl-1 pr-1">
                        <Select
                            value={this.state.eanType}
                            options={
                                this.props.eanTypes
                            }
                            placeholder={t(
                                "packages.filter.select",
                                "Vali"
                            )}
                            labelText={t(
                                "packages.filter.eanType",
                                "EAN koodi tüüp"
                            )}
                            name="eanType"
                            getOptionLabel={(option) =>
                                option.value
                            }
                            getOptionValue={(option) =>
                                option.value
                            }
                            onChange={this.handleSelectChange(
                                "eanType"
                            )}
                            required
                            invalid={this.isInvalid(
                                "eanType"
                            )}
                        />
                    </Col>
                    <Col sm={6} lg={5} className="pl-1 pr-1">
                        <Input
                            value={
                                this.state
                                    .packageName
                            }
                            placeholder={t(
                                "packages.filter.select",
                                "Lisa"
                            )}
                            labelText={t(
                                "packages.filter.productName",
                                "Toote nimetus"
                            )}
                            name="packageName"
                            onBlur={this.handleChange(
                                "packageName"
                            )}
                            trimOnBlur
                            required
                            invalid={this.isInvalid(
                                "packageName"
                            )}
                        />
                    </Col>
                    <Col sm={6} lg={3} className="pl-1 pr-1">
                        <Select
                            value={
                                this.state
                                    .packageType
                            }
                            options={
                                this.props
                                    .packageTypes
                            }
                            labelText={t(
                                "packages.filter.type",
                                "Pakendi tüüp"
                            )}
                            placeholder={t(
                                "packages.filter.select",
                                "Vali"
                            )}
                            name="packageType"
                            getOptionLabel={(
                                option
                            ) => option.value}
                            getOptionValue={(
                                option
                            ) => option.value}
                            onChange={this.handleSelectChange(
                                "packageType"
                            )}
                            required
                            invalid={this.isInvalid(
                                "packageType"
                            )}
                        />
                    </Col>
                    <Col xs={6} lg={2} className="pl-1 pr-1">
                        <Select
                            value={
                                this.state
                                    .packageVolume
                            }
                            options={
                                this.state
                                    .packageVolumes
                            }
                            labelText={t(
                                "packages.filter.volume",
                                "Maht"
                            )}
                            placeholder={t(
                                "packages.filter.select",
                                "Vali"
                            )}
                            isDisabled={
                                this.state
                                    .packageVolumes
                                    .length ===
                                0
                            }
                            name="packageVolume"
                            getOptionLabel={(
                                option
                            ) =>
                                option.value +
                                "cl"
                            }
                            getOptionValue={(
                                option
                            ) => option.value}
                            onChange={this.handleSelectChange(
                                "packageVolume"
                            )}
                            required
                            invalid={this.isInvalid(
                                "packageVolume"
                            )}
                        />
                    </Col>
                    <Col xs={6} lg={2} className="pl-1 pr-1">
                        <Select
                            value={
                                this.state
                                    .packageColor
                            }
                            options={
                                this.props
                                    .packageColors
                            }
                            labelText={t(
                                "packages.color",
                                "Värv"
                            )}
                            placeholder={t(
                                "packages.filter.select",
                                "Vali"
                            )}
                            name="packageColor"
                            getOptionLabel={(
                                option
                            ) => option.value}
                            getOptionValue={(
                                option
                            ) => option.value}
                            isOptionDisabled={(
                                option
                            ) =>
                                this.isColorOptionDisabled(
                                    option
                                )
                            }
                            onChange={this.handleSelectChange(
                                "packageColor"
                            )}
                            required
                            invalid={this.isInvalid(
                                "packageColor"
                            )}
                        />
                    </Col>
                    <Col sm={6} lg={3} className="pl-1 pr-1">
                        <Select
                            value={
                                this.state
                                    .packageCategory
                            }
                            options={
                                this.props
                                    .categories
                            }
                            placeholder={t(
                                "packages.filter.select",
                                "Vali"
                            )}
                            labelText={t(
                                "packages.filter.category",
                                "Toote kategooria"
                            )}
                            name="packageCategory"
                            getOptionLabel={(
                                option
                            ) =>
                                option.description
                            }
                            getOptionValue={(
                                option
                            ) => option.value}
                            onChange={this.handleSelectChange(
                                "packageCategory"
                            )}
                            required
                            invalid={this.isInvalid(
                                "packageCategory"
                            )}
                        />
                    </Col>
                    <Col sm={6} lg={2} className="pl-1 pr-1">
                        <Input
                            value={
                                this.state
                                    .packageWeight
                            }
                            placeholder={t(
                                "packages.filter.add",
                                "Lisa"
                            )}
                            labelText={t(
                                "packages.filter.weightInGrams",
                                "Kaal (g)"
                            )}
                            name="packageWeight"
                            onBlur={this.handleChange(
                                "packageWeight"
                            )}
                            trimOnBlur
                            required
                            invalid={this.isInvalid(
                                "packageWeight"
                            )}
                        />
                    </Col>
                    <Col sm={12} lg={3} className="pl-1 pr-1">
                        <FormGroup className="mb-2">
                            <Button
                                block
                                type="submit"
                                onClick={e => { e.preventDefault(); this.addPackage(); }}
                                color="success"
                                className="add-package-btn"
                                disabled={
                                    this.state
                                        .addPackageLoading
                                }
                            >
                                {this.state
                                    .addPackageLoading ? (
                                    <LoadingAnimation />
                                ) : (
                                    t(
                                        "packages.add",
                                        "Lisa"
                                    )
                                )}
                            </Button>
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        )
    }

    renderTable() {
        const { t } = this.props;
        if (this.state.pendingApplicationLoading) {
            return <WrappedLoader />;
        }

        const renderDeleteIcon = (packageId, row) => {
            return (
                <div className="d-flex align-items-center pointer">
                    <DeleteIcon
                        onClick={() => this.onRemovePackage(packageId)}
                    />
                </div>
            );
        };

        const columns = [
            {
                dataField: "EAN",
                text: t("packages.filter.ean", "EAN"),
            },
            {
                dataField: "EAN_type",
                text: t("packages.filter.eanType", "EAN tüüp"),
            },
            {
                dataField: "name",
                text: t("packages.filter.productName", "Toote nimetus"),
            },
            {
                dataField: "package_category",
                text: t("packages.filter.category", "Toote kategooria"),
            },
            {
                dataField: "package_type",
                text: t("packages.filter.type", "Pakendi tüüp"),
            },
            {
                dataField: "volume",
                text: t("packages.filter.volume", "Maht"),
                headerStyle: (column, colIndex) => {
                    return { width: "70px" };
                },
            },
            {
                dataField: "weight",
                text: t("packages.filter.weight", "Kaal"),
                headerStyle: (column, colIndex) => {
                    return { width: "70px" };
                },
            },
            {
                dataField: "color",
                text: t("packages.color", "Värv"),
            },
            {
                dataField: "id",
                text: "",
                formatter: renderDeleteIcon,
                headerStyle: (column, colIndex) => {
                    return { width: "60px" };
                },
            },
        ];

        return (
            <BootstrapTable
                bootstrap4
                bordered={false}
                classes="add-packages-table"
                keyField="EAN"
                data={this.state.pendingApplication}
                columns={columns}
                filterPosition="inline"
                noDataIndication={
                    <AddPackageModal.NoDataIndication
                        loading={this.state.pendingApplicationLoading}
                        t={t}
                    />
                }
            // pagination={paginationFactory()}
            />
        );
    }

    renderInvoiceInfoAlert() {
        const { t } = this.props;
        let type = alertType.INFO_ICON;
        let content = (
            <div className="align-items-center w-100 d-flex">
                <span className="regular-14">{t("packages.addPackageModal.invoiceAlertText", "Taotluse esitamise kinnitamisel esitatakse arve ning ühe pakendi registreerimistasu on 52 € + KM.")}</span>
            </div>
        );

        return (
            <Row className="no-gutters mb-3">
                <Col>
                    <Alert
                        type={type}
                        className={type}
                        content={content}
                    />
                </Col>
            </Row>
        );
    }

    renderConfirmApplicationModal() {
        const { t } = this.props;
        let alertGroup = "confirmApplicationModalAlertGroup";

        const onToggle = () => {
            clearLocalAlerts();
            this.setState(prevState => { return { isConfirmApplicationOpen: !prevState.isConfirmApplicationOpen } });
        };
        let onConfirmFunc = () => {
            this.submitPackageApplication(alertGroup);
        };

        const packageCount = this.state.pendingApplication.length;
        const costPerApplication = 52;
        let invoiceSum = _.round((packageCount * costPerApplication), 2);
        const formattedInvoiceSum = currencyFormatter.format(invoiceSum);
        let content = (
            <div>
                <p className="regular-14">{t("packages.addPackageModal.submitApplicationConfirmationModal.questionText", "Kas olete veendunud esitatud andmete õigsuses ja soovite esitada taotluse?")}</p>
                <p className="regular-14">
                    <Trans t={t} i18nKey="packages.addPackageModal.submitApplicationConfirmationModal.invoiceSumText" count={packageCount}>
                        {{ packageCount: packageCount }} pakendi taotluse esitamise registreerimistasu on {{ sum: formattedInvoiceSum }} + KM.
                    </Trans>
                </p>
                <p>
                </p>
            </div>
        );

        return (
            <>
                <Button
                    onClick={onToggle}
                    color="success"
                    disabled={
                        this.state.submitApplicationLoading ||
                        this.state.pendingApplication.length === 0
                    }
                    className="footer-button"
                >
                    {
                        t(
                            "packages.submitPackagesApplication",
                            "Esita pakendi(te) taotlus"
                        )
                    }
                </Button>
                <ConfirmationModal
                    isOpen={this.state.isConfirmApplicationOpen}
                    onToggle={onToggle}
                    onCancel={onToggle}
                    onConfirm={onConfirmFunc}
                    confirmButtonText={t("packages.addPackageModal.submitApplicationConfirmationModal.confirmButton", "Kinnita")}
                    title={t("packages.addPackageModal.submitApplicationConfirmationModal.title", "Kinnita taotlus")}
                    isDisabled={this.state.submitApplicationLoading}
                    isLoading={this.state.submitApplicationLoading}
                    alertGroups={[alertGroup]}
                >
                    {content}

                </ConfirmationModal>
            </>
        );
    }

    static NoDataIndication(props) {
        let content = props.t("packages.addPackageModal.tableNoPackages", "Ei leitud ühtegi esitamat pakendit.");
        if (props.loading) {
            content = <WrappedLoader />;
        }

        return <div className="text-center">{content}</div>;
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.auth.user,
        activeClientId: state.auth.user
            ? state.auth.user.active_client_id
            : null,
        eanTypes: state.packages.eanTypes,
        categories: state.packages.categories,
        packageTypes: state.packages.packageTypes,
        packageVolumes: state.packages.packageVolumes,
        packageColors: state.packages.packageColors,
        error: state.packages.error,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(AddPackageModal))
);
