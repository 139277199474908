import React from 'react';

import { NavLink, Route } from "react-router-dom";
import { Switch } from "react-router";

import urls from "config/urls";

class ReportsView extends React.Component {
    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={props => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        return (
            <>
                <div className="navigation-buttons pb-4">
                    <NavLink to={urls.PARTNER_PACKAGES_IN_TESTING} className="btn btn-default mr-1">Testimise ootel</NavLink>
                    <NavLink to={urls.PARTNER_PACKAGES_IN_RETESTING} className="btn btn-default mr-1">Kordustestimise ootel</NavLink>
                    <NavLink to={urls.PARTNER_PACKAGES_UNCONFIRMED} className="btn btn-default mr-1">Kinnitamata</NavLink>
                    <NavLink to={urls.PARTNER_PACKAGES_CONFIRMED} className="btn btn-default ml-1 mr-1">Kinnitatud</NavLink>
                    <NavLink exact to={urls.PARTNER_PACKAGES} className="btn btn-default ml-1">Testitulemused</NavLink>
                </div>

                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>

            </>
        );
    }
}

export default ReportsView;
