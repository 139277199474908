import defaultsDeep from "lodash/defaultsDeep";
import axios from "../../axios";

const API_COMMENT_LIST_GET = "comments/{content_type_id}/{object_id}/";
const API_COMMENT_CREATE_POST = API_COMMENT_LIST_GET; // same endpoint, different method
const API_COMMENT_REMOVE_DELETE = "comments/{content_type_id}/{object_id}/{id}/";

export const VISIBILITY = {
  PUBLIC: "PUBLIC",
  EPP: "EPP"
};

export const ROLE = {
  EPP: "EPP",
  PARTNER: "PARTNER",
  CLIENT: "CLIENT",
  SYSTEM: "SYSTEM"
};

export const TYPE = {
  MESSAGE: "MESSAGE",
  NOTIFICATION: "NOTIFICATION"
};

export function fetchInstanceComments(
  contentTypeId,
  objectId
) {
  /**
   * Endpoint returns latest comments in a descending order by created at date.
   * By default it returns last 10 comments but it can be changed with page_size query parameter.
   * 
   * Response is { older, newer, results } format, where:
   * - older: Link to older comments or null if none 
   * - newer: Link to newer comments or null if none
   * - results: Comment objects
   * 
   * "older" link must be chosen to fetch such comments.
   */
  // By default fetches 10 latest comments
  const params = { page_size: 10 };

  const endpoint = API_COMMENT_LIST_GET
    .replace("{content_type_id}", contentTypeId)
    .replace("{object_id}", objectId);

  return axios({
    method: "get",
    url: endpoint,
    params: params
  });
}

export function createInstanceComment(
  contentTypeId,
  objectId,
  data
) {
  data = defaultsDeep(data, {
    body: "",
    type: TYPE.MESSAGE,
    visibility: VISIBILITY.PUBLIC
    // created_by: server adds request user as creator
    // created_at: Server adds current moment
  });

  const endpoint = API_COMMENT_CREATE_POST
    .replace("{content_type_id}", contentTypeId)
    .replace("{object_id}", objectId);

  return axios({
    method: "post",
    url: endpoint,
    data
  });
}

export function removeComment(
  contentTypeId,
  objectId,
  commentId
) {

  const endpoint = API_COMMENT_REMOVE_DELETE
    .replace("{content_type_id}", contentTypeId)
    .replace("{object_id}", objectId)
    .replace("{id}", commentId);

  return axios({
    method: "delete",
    url: endpoint,
  });
}

export function sortCommentsByCreatedAt(comments) {
  const returnComments = [...comments];
  return returnComments.sort((comment1, comment2) => {
    if (comment1.created_at > comment2.created_at) {
      return 1;
    } else if (comment1.created_at === comment2.created_at) {
      if (comment1.id > comment2.id) {
        return 1;
      } else if (comment1.id === comment2.id) {
        return 0;
      }
      return -1;
    }
    return -1;
  });
}

export function getLatestComment(comments) {
  comments = comments || [];
  let latestComment = comments[0] || null;
  for (const comment of comments) {
    if (comment.created_at > latestComment.created_at) {
      latestComment = comment;
    }
  }
  return latestComment;
}

export const ruleTrimmedStringLengthAtleast = (value, minLength = 1) => {
  if (typeof value === "string") {
    return value.trim().length >= minLength;
  } else {
    return false;
  }
};

export default {
  VISIBILITY,
  ROLE,
  TYPE,
  ruleTrimmedStringLengthAtleast,
  sortCommentsByCreatedAt,
  getLatestComment,
  fetchInstanceComments,
  createInstanceComment,
  removeComment
};
