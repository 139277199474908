export const saleStatuses = {
    UNSUBMITTED: "UNSUBMITTED",
    SUBMITTED: "SUBMITTED",
    UNCONFIRMED: "UNCONFIRMED",
    CONFIRMED: "CONFIRMED",
    REJECTED: "REJECTED",
}

export const saleStatusTypes = {
    [saleStatuses.UNSUBMITTED]: {
        client_type: "WARNING",
        admin_type: "INFO",
    },
    [saleStatuses.SUBMITTED]: {
        client_type: "SUCCESS",
        admin_type: "SUCCESS",
    },
    [saleStatuses.UNCONFIRMED]: {
        client_type: "INFO",
        admin_type: "WARNING",
    },
    [saleStatuses.CONFIRMED]: {
        client_type: "SUCCESS",
        admin_type: "SUCCESS",
    },
    [saleStatuses.REJECTED]: {
        client_type: "ERROR",
        admin_type: "INFO",
    },
}

export function getSaleStatusType(statusValue, adminMode = false) {
    const typeField = adminMode ? "admin_type" : "client_type";
    return saleStatusTypes[statusValue] && saleStatusTypes[statusValue][typeField];
}
