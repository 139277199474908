import React from "react";

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import onScan from "onscan.js";

import {
    fetchAdminAllPackages,
} from "util/admin/AllPackages";
import urls, { getAdminPackageDetailsUrl } from "config/urls";

import {
    adminAllPackagesFilterUpdate,
    adminAllPackagesFilterClear,
    adminAllPackagesSortingUpdate,
    adminAllPackagesPaginationUpdate,
} from "redux/actions/admin/allPackages";

import { Card, CardBody, Row, Col } from "reactstrap";
import Search from "./Search/Search";
import Table from "./Table/Table";
import CustomPagination from "components/Table/Pagination/CustomPagination";

import { clearLocalAlerts, addNewLocalInfoAlert } from "util/Alerts";


class PackagesView extends React.Component {
    static propTypes = {
        // redux state
        packagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,
        packagesListFiltersUsed: PropTypes.object.isRequired,
        filters: PropTypes.object.isRequired,
        sorting: PropTypes.object.isRequired,
        pagination: PropTypes.object.isRequired,

        // redux actions
        adminAllPackagesFilterUpdate: PropTypes.func.isRequired,
        adminAllPackagesFilterClear: PropTypes.func.isRequired,
        adminAllPackagesSortingUpdate: PropTypes.func.isRequired,
        adminAllPackagesPaginationUpdate: PropTypes.func.isRequired,
    }

    componentDidMount() {
        fetchAdminAllPackages(this.props.packagesListFiltersUsed, this.props.sorting, this.props.pagination, this.props.location.pathname);

        onScan.attachTo(document, {
            suffixKeyCodes: [13], // enter-key expected at the end of a scan
            onScan: this.onScan, // Alternative to document.addEventListener('scan')
        });
    }

    componentWillUnmount() {
        onScan.detachFrom(document);
    }

    onScan = (sCode, iQty) => {
        clearLocalAlerts();
        const ean = sCode;

        this.props.adminAllPackagesFilterClear();
        this.props.adminAllPackagesFilterUpdate("ean", ean);

        let message = <div>Skänneeriti EAN <b>"{ean}"</b>.</div>;
        addNewLocalInfoAlert(message, this.props.location.pathname);
        fetchAdminAllPackages(this.props.filters, this.props.sorting, this.props.pagination, this.props.location.pathname);
    }

    /*
    componentDidUpdate(prevProps) {
        if (prevProps.filters !== this.props.filters) {
            fetchAdminAllPackages(this.props.filters, this.props.sorting, this.props.pagination, this.props.location.pathname);
        }
    }
    */

    render() {
        const pagination = this.props.pagination;
        return (
            <Card className="applications-card">
                <CardBody className="applications-card-body p-0 pt-3">
                    <Search
                        filters={this.props.filters}
                        onFilterUpdate={this.props.adminAllPackagesFilterUpdate}
                        onClearFilters={this.props.adminAllPackagesFilterClear}
                        packagesListLoading={this.props.packagesListLoading}
                        onSearch={this.onSearch}

                    />
                    <Row>
                        <Col>
                            <Table
                                packagesList={this.props.packagesList}
                                packagesListLoading={this.props.packagesListLoading}
                                sorting={this.props.sorting}
                                onTableChange={this.onTableChange}
                                className="flipped-horizontal-scrollbar"
                                onRowClick={this.onRowClick}
                            />
                        </Col>
                    </Row>
                    {!this.props.packagesListLoading &&
                        <Row className="mx-3">
                            <Col>
                                <CustomPagination
                                    page={pagination.page}
                                    sizePerPage={pagination.sizePerPage}
                                    dataSize={pagination.dataSize}
                                    sizePerPageList={pagination.sizePerPageList}
                                    //unknownSizePerPageText="Kõik"
                                    onSizePerPageChange={(sizePerPage, page) => this.onPaginationChange(page, sizePerPage)}
                                    onPageChange={this.onPaginationChange}
                                />
                            </Col>
                        </Row>
                    }
                </CardBody>
            </Card>
        );
    }

    onSearch = () => {
        fetchAdminAllPackages(this.props.filters, this.props.sorting, this.props.pagination, this.props.location.pathname);
    }

    onPaginationChange = (page, sizePerPage) => {
        const currentPagination = this.props.pagination;
        if (page !== currentPagination.page || sizePerPage !== currentPagination.sizePerPage) {
            const newPagination = { ...currentPagination, page, sizePerPage };
            this.props.adminAllPackagesPaginationUpdate(newPagination);
            fetchAdminAllPackages(this.props.packagesListFiltersUsed, this.props.sorting, newPagination, this.props.location.pathname);
        }
    }

    onTableChange = (type, newState) => {
        const { sortOrder, sortField } = newState;
        const sorting = this.props.sorting;
        if (sortOrder !== sorting.order || sortField !== sorting.dataField) {
            const newSorting = {
                dataField: sortField,
                order: sortOrder,
            };
            this.props.adminAllPackagesSortingUpdate(newSorting);
            fetchAdminAllPackages(this.props.packagesListFiltersUsed, newSorting, this.props.pagination, this.props.location.pathname);
        }
    }

    onRowClick = (event, row) => {
        const packageDetailsUrl = getAdminPackageDetailsUrl(row.package_id);
        this.props.history.push(packageDetailsUrl, { previousViewRoute: urls.EPP_REGISTRY_PACKAGES });
    }
}

const mapStateToProps = state => {
    return {
        packagesList: state.admin.allPackages.packagesList,
        packagesListLoading: state.admin.allPackages.packagesListLoading,
        packagesListFiltersUsed: state.admin.allPackages.packagesListFiltersUsed,
        filters: state.admin.allPackages.filters,
        sorting: state.admin.allPackages.sorting,
        pagination: state.admin.allPackages.pagination,
    }
};

const mapActionsToProps = {
    adminAllPackagesFilterUpdate,
    adminAllPackagesFilterClear,
    adminAllPackagesSortingUpdate,
    adminAllPackagesPaginationUpdate,
};

export default withRouter(connect(mapStateToProps, mapActionsToProps)(PackagesView));
