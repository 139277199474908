import React from "react";
//import PropTypes from "prop-types";
import {Row, Col, Button, Modal, ModalBody} from "reactstrap";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import DeleteText from "components/Misc/DeleteText";


const DeleteModal = ({
        toggleButtonText, 
        children, title, confirmButtonText, 
        isOpen, onToggle, onCancel, onConfirm, 
        isDisabled, isLoading, alertGroups,
}) => {
    return (
        <>
            <DeleteText
                text={toggleButtonText}
                onClick={onToggle}
                disabled={isDisabled}
            />
            <Modal 
                isOpen={isOpen} 
                toggle={onToggle}
                centered
                backdrop="static"
                className="delete-package-modal"
            >
                <ModalBody className="p-4">
                    <Row className="mb-4">
                        <Col>
                            <span className="bold-19">{title}</span>
                        </Col>
                        <Col className="col-auto float-right text-right">
                            <CloseTextIcon
                                text="Sulge"
                                onClick={onToggle}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Alerts hideGlobal={true} filterGroups={alertGroups} />
                        </Col>
                    </Row>
                    <Row className="mb-4">
                        <Col>
                            {children}
                        </Col>
                    </Row>
                    <Row className="regular-14">
                        <Col md="6">
                            <Button 
                                color="success" 
                                outline
                                block
                                onClick={onCancel}
                                disabled={isDisabled}
                            >
                                Katkesta
                            </Button>
                        </Col>
                        <Col md="6">
                            <Button 
                                color="success"
                                block
                                onClick={onConfirm}
                                disabled={isDisabled}
                            >
                                {isLoading ? <LoadingAnimation /> : confirmButtonText}
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    );
}

DeleteModal.defaultProps = {
    toggleButtonText: "Kustuta",
    children: <span className="regular-14">Kas oled kindel, et tahad kustutada?</span>, 
    title: "Kinnita kustutamine",
    confirmButtonText: "Kinnita", 
    isDisabled: false,
    isLoading: false,
    alertGroups: [],
    //onToggle, onCancel, onConfirm,
};

export default DeleteModal;