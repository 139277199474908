import * as actionTypes from "redux/actions/actionTypes";

export const packagesInTestFetchStart = () => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_FETCH_START,
    };
};

export const packagesInTestFetchSuccess = (
    packagesList,
    filters,
    unfilteredPackagesList
) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_FETCH_SUCCESS,
        packagesList,
        filters,
        unfilteredPackagesList,
    };
};

export const packagesInTestFetchFail = (error) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_FETCH_FAIL,
        error,
    };
};

export const packagesInTestFilterUpdate = (key, value) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_FILTER_UPDATE,
        key: key,
        value: value,
    };
};

export const packagesInTestFilterClear = (format) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_FILTER_CLEAR,
        format,
    };
};

export const packagesInTestSortingUpdate = (sorting) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_SORTING_UPDATE,
        sorting,
    };
};

export const packagesInTestPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_PAGINATION_UPDATE,
        pagination,
    };
};

/*
export const packagesInTestExportStart = () => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_EXPORT_START
    }
};

export const packagesInTestExportSuccess = () => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_EXPORT_SUCCESS,
    }
};

export const packagesInTestExportFail = (error) => {
    return {
        type: actionTypes.PACKAGES_IN_TEST_EXPORT_FAIL,
        error,
    }
};
*/
