import { updateObject } from "../utility";
import * as actionTypes from "../actions/actionTypes";

const lastPackagesInitialState = {
    not_registered_packages: 0,
    all_packages_count: 0,
    packages: [],
};

const lastReportsInitialState = {
    not_submitted_reports_count: 0,
    all_reports_count: 0,
    latest_reports: [],
};

const statisticsInitialState = {
    months: [],
    packages_total: "-",
    best_month: {
        month: "-",
    },
    overdue_invoices: null,
    invoice_balance: null,
};

const initialState = {
    lastPackages: lastPackagesInitialState,
    lastReports: lastReportsInitialState,
    statistics: statisticsInitialState,
    lastPackagesLoading: false,
    lastReportsLoading: false,
    statisticsLoading: false,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
};

const lastPackagesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        lastPackagesLoading: true,
    });
};

const lastPackagesFetchSuccess = (state, packages) => {
    return updateObject(state, {
        lastPackages: packages,
        loading: false,
        lastPackagesLoading: false,
    });
};

const lastPackagesFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false,
        lastPackagesLoading: false,
    });
};

const lastReportsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        lastReportsLoading: true,
    });
};

const lastReportsFetchSuccess = (state, reports) => {
    return updateObject(state, {
        lastReports: reports,
        loading: false,
        lastReportsLoading: false,
    });
};

const lastReportsFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false,
        lastReportsLoading: false,
    });
};

const statisticsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        statisticsLoading: true,
    });
};

const statisticsFetchSuccess = (state, statistics) => {
    return updateObject(state, {
        statistics: statistics,
        loading: false,
        statisticsLoading: false,
    });
};

const statisticsFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false,
        statisticsLoading: false,
    });
};

export const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.LAST_PACKAGES_FETCH_START:
            return lastPackagesFetchStart(state);
        case actionTypes.LAST_PACKAGES_FETCH_SUCCESS:
            return lastPackagesFetchSuccess(state, action.packages);
        case actionTypes.LAST_PACKAGES_FETCH_FAIL:
            return lastPackagesFetchFail(state, action);
        case actionTypes.LAST_REPORTS_FETCH_START:
            return lastReportsFetchStart(state);
        case actionTypes.LAST_REPORTS_FETCH_SUCCESS:
            return lastReportsFetchSuccess(state, action.reports);
        case actionTypes.LAST_REPORTS_FETCH_FAIL:
            return lastReportsFetchFail(state, action);
        case actionTypes.STATISTICS_FETCH_START:
            return statisticsFetchStart(state);
        case actionTypes.STATISTICS_FETCH_SUCCESS:
            return statisticsFetchSuccess(state, action.statistics);
        case actionTypes.STATISTICS_FETCH_FAIL:
            return statisticsFetchFail(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
