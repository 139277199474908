import * as actionTypes from 'redux/actions/actionTypes';
import {updateObject} from 'redux/utility';
import moment from "moment";

const initialFilters = {
    fromDate: moment().subtract(1, "year").startOf("month").toDate(), // react-datepicker wants native date
    toDate: moment().startOf("month").toDate(),
    packageType: null,
    packageCategory: null,
    showEANCode: true,
    showEANType: true,
    showPackageType: true,
    showName: true,
    showPackageCategory: true,
    showVolume: true,
    showDeposit: true,
}

const initialState = {
    filters: initialFilters,
    summaryList: null,
    summaryListFromDate: null, // filter.fromDate of last successful summary list update
    summaryListToDate: null, // filter.toDate of last successful summary list update
    summaryListLoading: false,
    exportFile: null,
    exportLoading: false,
    loading: false,
    error: null,
};

const resetUserSpecificData = (state, action) => {
    return initialState;
}

const summaryReportSearchStart = (state) => {
    return updateObject(state, {
        loading: true,
        summaryListLoading: true,
        summaryListFromDate: null,
        summaryListToDate: null,
        error: false,
        summaryList: null,
    });
};

const summaryReportSearchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        summaryListLoading: false,
        error: false,
        summaryList: action.summaryList,
        summaryListFromDate: action.fromDate,
        summaryListToDate: action.toDate,
    });
};

const summaryReportSearchFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false,
        summaryListLoading: false,
    });
};

const summaryReportSearchFilterUpdate = (state, action)  => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        }
    });
};

const summaryReportExportStart = (state) => {
    return updateObject(state, {
        loading: true,
        exportLoading: true,
        error: false,
    });
};

const summaryReportExportSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        error: false,
        exportFile: action.exportFile,
    });
};

const summaryReportExportFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        exportLoading: false,
        error: action.err,
    });
};

export const summaryReport = (state = initialState, action) => {
    switch (action.type) {
        // SEARCH
        case actionTypes.SUMMARY_REPORT_SEARCH_START:
            return summaryReportSearchStart(state, action);
        case actionTypes.SUMMARY_REPORT_SEARCH_SUCCESS:
            return summaryReportSearchSuccess(state, action);
        case actionTypes.SUMMARY_REPORT_SEARCH_FAIL:
            return summaryReportSearchFail(state, action);
        // UPDATE FILTER
        case actionTypes.SUMMARY_REPORT_SEARCH_FILTER_UPDATE:
            return summaryReportSearchFilterUpdate(state, action);
        // EXPORT
        case actionTypes.SUMMARY_REPORT_EXPORT_START:
            return summaryReportExportStart(state, action);
        case actionTypes.SUMMARY_REPORT_EXPORT_SUCCESS:
            return summaryReportExportSuccess(state, action);
        case actionTypes.SUMMARY_REPORT_EXPORT_FAIL:
            return summaryReportExportFail(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};