import React from 'react';
import Invoices from "../components/Invoices/Table/Table";

class InvoiceView extends React.Component {
    render() {
        return (
            <Invoices />
        );
    }
}

export default InvoiceView;