import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";

import { Form, Card, CardBody, Row, Col, Button, FormGroup, Label } from "reactstrap";
import DatePicker from "react-datepicker";
import Select from "components/Form/Select";
import SmallLoader from "components/Loader/WrappedPulseLoader";
import "./SummarySearch.scss";
import {
    salesReportPeriodSearchFormat,
    getFromAndToDatePeriodAsString,
} from "util/DateTime";
import CustomCheckbox from "components/Form/Checkbox";

import { summaryReportSearchFilterUpdate } from "redux/actions/reports/summaryReport";
import {
    fetchSummaryReport,
    fetchSummaryReportExportFile,
} from "util/SummaryReport";
import {
    clearNavigationHeaderSuffix,
    updateNavigationHeaderSuffix,
} from "util/Navigation";
import { fetchCategories, fetchPackageTypes } from "util/Packages";
import { addNewLocalErrorAlert } from "util/Alerts";
import { alertsClearLocal } from "redux/actions/alerts";
import { withTranslation } from "react-i18next";

class SummaryReportsSearch extends React.Component {
    static propTypes = {
        filters: PropTypes.object.isRequired,
        summaryList: PropTypes.array,
        summaryListFromDate: PropTypes.object,
        summaryListToDate: PropTypes.object,
        summaryListLoading: PropTypes.bool,
        exportLoading: PropTypes.bool,
        summaryReportSearchFilterUpdate: PropTypes.func.isRequired,
        packageTypes: PropTypes.array.isRequired,
        packageCategories: PropTypes.array.isRequired,
        alertsClearLocal: PropTypes.func.isRequired,
    };

    componentDidMount() {
        if (this.props.summaryList) {
            updateNavigationHeaderSuffix(
                getFromAndToDatePeriodAsString(
                    this.props.summaryListFromDate,
                    this.props.summaryListToDate
                )
            );
        }

        fetchPackageTypes(this.props.packageTypes);
        fetchCategories(this.props.packageCategories);
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.summaryList &&
            this.props.summaryList !== prevProps.summaryList
        ) {
            updateNavigationHeaderSuffix(
                getFromAndToDatePeriodAsString(
                    this.props.summaryListFromDate,
                    this.props.summaryListToDate
                )
            );
        }
    }

    componentWillUnmount() {
        clearNavigationHeaderSuffix();
    }

    render() {
        const { t } = this.props;
        const filters = this.props.filters;
        return (
            <Form>
                <Card className="summary-search-card">
                    <CardBody>
                        <Row className="align-items-end mx-n2">
                            <Col lg="10" className="order-1">
                                <Row className="align-items-end">
                                    <Col md="6" lg="3">
                                        <FormGroup>
                                            <Label className="bold-12 text-muted">
                                                {t(
                                                    "reports.filter.period",
                                                    "Periood"
                                                )}
                                            </Label>
                                            <DatePicker
                                                selected={filters.fromDate}
                                                onChange={this.handleFilterUpdate(
                                                    "fromDate"
                                                )}
                                                placeholderText={t(
                                                    "reports.filter.from",
                                                    "Alates"
                                                )}
                                                dateFormat={
                                                    salesReportPeriodSearchFormat
                                                }
                                                showMonthYearPicker
                                                maxDate={
                                                    filters.toDate
                                                        ? moment(
                                                            filters.toDate
                                                        ).toDate()
                                                        : new Date()
                                                }
                                                className="date-filter regular-14"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <FormGroup>
                                            <DatePicker
                                                selected={filters.toDate}
                                                onChange={this.handleFilterUpdate(
                                                    "toDate"
                                                )}
                                                placeholderText={t(
                                                    "reports.filter.to",
                                                    "Kuni"
                                                )}
                                                dateFormat={
                                                    salesReportPeriodSearchFormat
                                                }
                                                showMonthYearPicker
                                                minDate={
                                                    filters.fromDate &&
                                                    moment(
                                                        filters.fromDate
                                                    ).toDate()
                                                }
                                                maxDate={new Date()}
                                                className="date-filter regular-14"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <FormGroup>
                                            <Label className="bold-12 text-muted">
                                                {t(
                                                    "reports.filter.packageCategory",
                                                    "Tootekategooria"
                                                )}
                                            </Label>
                                            <Select
                                                value={filters.packageCategory}
                                                placeholder={t(
                                                    "reports.filter.all",
                                                    "Kõik"
                                                )}
                                                name="searchPackageCategory"
                                                onChange={this.handleSelectChange(
                                                    "packageCategory"
                                                )}
                                                options={
                                                    this.props.packageCategories
                                                }
                                                getOptionValue={(option) =>
                                                    option.value
                                                }
                                                getOptionLabel={(option) =>
                                                    option.description
                                                }
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md="6" lg="3">
                                        <FormGroup>
                                            <Label className="bold-12 text-muted">
                                                {t(
                                                    "reports.filter.packageType",
                                                    "Pakenditüüp"
                                                )}
                                            </Label>
                                            <Select
                                                value={filters.packageType}
                                                placeholder={t(
                                                    "reports.filter.all",
                                                    "Kõik"
                                                )}
                                                name="searchPackageType"
                                                onChange={this.handleSelectChange(
                                                    "packageType"
                                                )}
                                                options={this.props.packageTypes}
                                                getOptionValue={(option) =>
                                                    option.value
                                                }
                                                getOptionLabel={(option) =>
                                                    option.value
                                                }
                                                isClearable
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md="6" lg="2" className="order-3 order-lg-2">
                                <FormGroup>
                                    <Button
                                        block
                                        color="success"
                                        className="search-button"
                                        type="submit"
                                        onClick={e => { e.preventDefault(); this.onSearch(); }}
                                        disabled={this.props.summaryListLoading}
                                    >
                                        {this.props.summaryListLoading ? (
                                            <SmallLoader />
                                        ) : (
                                            t("buttons.search", "Otsi")
                                        )}
                                    </Button>
                                </FormGroup>
                            </Col>
                            <Col lg="10" className="order-2 order-lg-3">
                                <FormGroup>
                                    {/*
                                <CustomCheckbox
                                    inline
                                    value={filters.showEANCode}
                                    labelText="EAN kood"
                                    onClick={this.handleFilterUpdate("showEANCode")}
                                />
                                */}
                                    <CustomCheckbox
                                        inline
                                        value={filters.showEANType}
                                        labelText={t(
                                            "reports.filter.eanType",
                                            "EAN Tüüp"
                                        )}
                                        onClick={this.handleFilterUpdate(
                                            "showEANType"
                                        )}
                                    />
                                    <CustomCheckbox
                                        inline
                                        value={filters.showPackageType}
                                        labelText={t(
                                            "reports.filter.packageType",
                                            "Pakendi tüüp"
                                        )}
                                        onClick={this.handleFilterUpdate(
                                            "showPackageType"
                                        )}
                                    />
                                    <CustomCheckbox
                                        inline
                                        value={filters.showName}
                                        labelText={t(
                                            "reports.filter.packageName",
                                            "Toote nimetus"
                                        )}
                                        onClick={this.handleFilterUpdate(
                                            "showName"
                                        )}
                                    />
                                    <CustomCheckbox
                                        inline
                                        value={filters.showPackageCategory}
                                        labelText={t(
                                            "reports.filter.packageCategory",
                                            "Toote kategooria"
                                        )}
                                        onClick={this.handleFilterUpdate(
                                            "showPackageCategory"
                                        )}
                                    />
                                    <CustomCheckbox
                                        inline
                                        value={filters.showVolume}
                                        labelText={t(
                                            "reports.filter.packageVolume",
                                            "Pakendi maht"
                                        )}
                                        onClick={this.handleFilterUpdate(
                                            "showVolume"
                                        )}
                                    />
                                    <CustomCheckbox
                                        inline
                                        value={filters.showDeposit}
                                        labelText={t(
                                            "reports.filter.deposit",
                                            "Tagatisraha"
                                        )}
                                        onClick={this.handleFilterUpdate(
                                            "showDeposit"
                                        )}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="6" lg="2" className="order-4">
                                <FormGroup>
                                    <Button
                                        outline
                                        block
                                        color="success"
                                        className="export-button"
                                        onClick={this.onExport}
                                        disabled={this.props.exportLoading}
                                    >
                                        {this.props.exportLoading ? (
                                            <SmallLoader />
                                        ) : (
                                            t("buttons.export", "Ekspordi")
                                        )}
                                    </Button>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
        );
    }

    onSearch = () => {
        if (this.props.summaryListLoading) {
            return;
        }
        this.props.alertsClearLocal();
        clearNavigationHeaderSuffix();
        fetchSummaryReport(this.props.filters).then((result) => {
            if (!result.isSuccess) {
                addNewLocalErrorAlert(
                    this.props.t(
                        "reports.massReportLoadingErrorMessage",
                        "Koondaruande laadimine ebaõnnestus: "
                    ) + result.errorMessage,
                    this.props.location.pathname,
                    true
                );
            }
        });
    };

    onExport = () => {
        if (this.props.exportLoading) {
            return;
        }
        this.props.alertsClearLocal();
        fetchSummaryReportExportFile(this.props.filters).then((result) => {
            if (!result.isSuccess) {
                addNewLocalErrorAlert(
                    this.props.t(
                        "reports.massReportExportErrorMessage",
                        "Koondaruande eksportimine ebaõnnestus: "
                    ) + result.errorMessage,
                    this.props.location.pathname,
                    true
                );
            }
        });
    };

    handleFilterUpdate = (name) => (value) => {
        this.props.summaryReportSearchFilterUpdate(name, value);
    };

    handleSelectChange = (name) => (selectedObject) => {
        this.handleFilterUpdate(name)(selectedObject);
    };
}

const mapStateToProps = (state) => {
    return {
        filters: state.summaryReport.filters,
        summaryList: state.summaryReport.summaryList,
        summaryListFromDate: state.summaryReport.summaryListFromDate,
        summaryListToDate: state.summaryReport.summaryListToDate,
        summaryListLoading: state.summaryReport.summaryListLoading,
        exportLoading: state.summaryReport.exportLoading,
        packageTypes: state.packages.packageTypes,
        packageCategories: state.packages.categories,
    };
};

export default withRouter(
    connect(mapStateToProps, {
        summaryReportSearchFilterUpdate,
        alertsClearLocal,
    })(withTranslation("common")(SummaryReportsSearch))
);
