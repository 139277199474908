
import React from "react";
import { Translation } from "react-i18next";

import axios from "../axios";
import {alertType} from "util/Alerts";

/*
    Based on Everypay APIv4
    https://support.every-pay.com/downloads/everypay_apiv4_integration_documentation.pdf
*/

// Exhaustive list of statuses in api documentation (2.7 Payment Statuses)
const STATUS = {
    INITIAL: "initial",
    WAITING_FOR_SCA: "waiting_for_sca",
    SENT_FOR_PROCESSING: "sent_for_processing", // Confirmed by user but not yet by bank. Usually goes to "settled" final status
    WAITING_FOR_3DS_RESPONSE: 'waiting_for_3ds_response',
    SETTLED: "settled", // FINAL STATUS, Success
    FAILED: "failed", // FINAL STATUS, failed for technical reasons
    ABANDONED: "abandoned", // FINAL STATUS, happens when customer does not complete payment
    VOIDED: "voided", // FINAL STATUS, authorization cancelled
    REFUNDED: "refunded",
    CHARGEBACKED: "chargebacked"
}

const STATUS_LIST_FINAL_SUCCESS = [
    STATUS.SETTLED,
];

const STATUS_LIST_FINAL_FAILED = [
    STATUS.ABANDONED,
    STATUS.FAILED,
    STATUS.VOIDED
];

export const STATUS_LISTS = {
    FINAL_SUCCESS: STATUS_LIST_FINAL_SUCCESS,
    FINAL_FAILED: STATUS_LIST_FINAL_FAILED,
    FINAL: [...STATUS_LIST_FINAL_SUCCESS, ...STATUS_LIST_FINAL_FAILED]
    // IN_PROGRESS: Everything that is not a FINAL status?
}

export function initiatePayment(amount, clientId, customer_url=null, order_reference=null, invoiceId=null) {
    return axios.post("everypay/payment/", {
        amount,
        client_id: clientId,
        customer_url,
        order_reference,
        invoice_id: invoiceId

    }).then(resp => resp.data)
}

function checkPaymentState(paymentReference) {
    return axios.get(`everypay/payment/${paymentReference}/`).then(resp => resp.data)
}

export function getPaymentStateAlertContent(paymentReference) {
    /**
     * Should be used only after customer is redirected back from payment gateway which usually happens in a final status.
     * 
     * @Returns Promise that resolves into {message: Component, type: alertType} on successful call.
     */

    return checkPaymentState(paymentReference)
    .then(paymentState => {
        let message, type;
        if (STATUS_LIST_FINAL_SUCCESS.includes(paymentState)) {
            message = (
                <Translation>
                    {(t) => t("invoices.paymentSuccessful", "Makse sooritatud. Lõplik arve saadetakse Teie e-mailile 24 tunni jooksul!")}
                </Translation>
            )
            type = alertType.SUCCESS;
            
        } else if (STATUS_LIST_FINAL_FAILED.includes(paymentState)) {
            message = (
                <Translation>
                    {(t) => t("invoices.paymentUnsuccessful", "Makse tühistatud.")}
                </Translation>
            )
            type = alertType.ERROR
        } else {
            message = (
                <Translation>
                    {(t) => t("invoices.paymentInProgress", "Makse ei ole veel kinnitatud. Eduka makse korral saadetakse lõplik arve Teie e-mailile 24 tunni jooksul!")}
                </Translation>
            )
            type = alertType.INFO
        }
        return {message, type};
    })
}

export default {
    initiatePayment,
    checkPaymentState,
    getPaymentStateAlertContent
}
