import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import { Row, Col, Button } from "reactstrap";

import {
    fetchPackageTests,
    //packageTestStatuses,
} from "util/Partner";
import {
    sendToTestingFromDetails,
} from "util/admin/PackageDetails";
import {
    fetchStandardComments,
    fetchLocations,
} from "util/General";

import WrapperLoader from "components/Loader/WrappedLoader";
import LoadingAnimation from "components/Loader/WrappedPulseLoader";
import PackageTest from "./PackageTest";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
} from "util/Alerts";

import "./PackageTestList.scss";


class PackageTestList extends React.Component {
    static propTypes = {
        packageId: PropTypes.number.isRequired,
        onPackageTestSubmitSuccess: PropTypes.func.isRequired,
        onPackageTestConfirmSuccess: PropTypes.func,
        readOnly: PropTypes.bool,
        isAdminMode: PropTypes.bool,
        showSendToTestingButton: PropTypes.bool,
        onSendToTestingSuccess: PropTypes.func,
        // redux
        locations: PropTypes.array,
        standardComments: PropTypes.array,
    }

    static defaultProps = {
        readOnly: false,
        isAdminMode: false,
    }

    state = {
        packageTests: [],
        packageTestsLoading: false,
        sendToTestingLoading: false,
    }

    componentDidMount() {
        fetchStandardComments(this.props.standardComments);
        fetchLocations(this.props.locations);

        this.getPackageTests(this.props.packageId);
    }

    render() {
        if (this.state.packageTestsLoading) {
            return <WrapperLoader />;
        }
        let noPackagesText = <></>;
        if (this.state.packageTests && !this.state.packageTests.length) {
            noPackagesText = <div className="text-center regular-14 py-2">Pakendil ei ole ühtegi testi.</div>;
        }
        if (!this.state.packageTests) {
            return <></>;
        }

        const packageTestElements = this.state.packageTests.map((packageTest) => (
            <PackageTest
                readOnly={this.props.readOnly}
                key={packageTest.test_id}
                packageTest={packageTest}
                onSubmitSuccess={this.onSubmitSuccess}
                onConfirmSuccess={this.onConfirmSuccess}
                locations={this.props.locations}
                standardComments={this.props.standardComments}
                isAdminMode={this.props.isAdminMode}
            />
        ));

        return (
            <div className="package-test-list">
                {packageTestElements}
                {noPackagesText}
                {this.renderAddNewTest()}
            </div>
        );
    }

    renderAddNewTest() {
        if (!this.props.showSendToTestingButton || !this.props.isAdminMode || !this.state.packageTests || this.hasOpenTest()) {
            return <></>;
        }

        return (
            <Row className="no-gutters">
                <Col className="my-2 text-center">
                    <Button
                        outline
                        color="secondary"
                        onClick={this.onSendToTesting}
                        disabled={this.state.sendToTestingLoading}
                    >
                        {this.state.sendToTestingLoading ? <LoadingAnimation /> : "Saada testimisse"}
                    </Button>
                </Col>
            </Row>
        );
    }

    hasOpenTest = () => {
        const packageTests = this.state.packageTests;
        if (!packageTests || !packageTests.length) {
            return false;
        }

        for (const test of packageTests) {
            if (!test.tested_at || !test.confirmed_at) {
                return true;
            }
        }

        return false;
    }

    getPackageTests = packageId => {
        this.setState({
            packageTestsLoading: true,
        }, () => fetchPackageTests(packageId)
            .then(packageTests => {
                this.setState({
                    packageTests,
                    packageTestsLoading: false,
                });
            })
            .catch(error => {
                this.handleError(error);
                this.setState({
                    packageTestsLoading: false,
                });
            })
        );
    }

    onSubmitSuccess = updatedPackageTest => {
        this.setState(prevState => {
            return {
                packageTests: prevState.packageTests.map(pkgTest => pkgTest.test_id === updatedPackageTest.test_id ? updatedPackageTest : pkgTest),
            }
        })
        this.props.onPackageTestSubmitSuccess(updatedPackageTest);
    }

    onConfirmSuccess = updatedPackageTest => {
        this.setState(prevState => {
            return {
                packageTests: prevState.packageTests.map(pkgTest => pkgTest.test_id === updatedPackageTest.test_id ? updatedPackageTest : pkgTest),
            }
        })
        this.props.onPackageTestConfirmSuccess(updatedPackageTest);
    }

    onSendToTesting = () => {
        clearLocalAlerts();

        this.setState({
            sendToTestingLoading: true,
        });

        sendToTestingFromDetails(this.props.packageId)
            .then(data => {
                this.setState({
                    sendToTestingLoading: false,
                });

                this.props.onSendToTestingSuccess(data);
                this.getPackageTests(this.props.packageId);
            })
            .catch(error => {
                this.setState({
                    sendToTestingLoading: false,
                });
                addNewLocalErrorAlert(error.message, this.props.location.pathname);
            })
    }

    handleError = error => {
        addNewLocalErrorAlert(error.message, this.props.location.pathname);
    }
}

const mapStateToProps = state => {
    return {
        standardComments: state.general.standardComments,
        locations: state.general.locations,
    }
}

export default withRouter(connect(mapStateToProps)(PackageTestList));
