import React from "react";
import PackagesTable from "../components/Packages/PackagesTable/PackagesTable";


class PackageView extends React.Component {

    render() {
        return (
            <PackagesTable />
        );
    }
}

export default PackageView;