import axios from "../../axios";
import store from "redux/store";
import moment from "moment";
//import _ from "lodash";

import { secondDateIsSameOrAfterFirstDate } from "util/DateTime";

import { getServerErrorMessage } from "util/Errors";
import { addNewLocalErrorAlert, clearLocalAlerts } from "util/Alerts";

import {
    ADMIN_ALL_SALES_REPORTS,
    ADMIN_UNSUBMITTED_SALES_REPORTS,
    ADMIN_UNCONFIRMED_SALES_REPORTS,
    ADMIN_GET_SALES_REPORT,
    ADMIN_GET_REPAIR_SALES_REPORT,
    //ADMIN_CLIENT_SALES_REPORTS,
    ADMIN_REPAIR_SALES_REPORT_APPROVAL,
    ADMIN_DELETE_SALES_REPORT,
} from "config/Api";
import {
    adminSalesReportsFetchStart,
    adminSalesReportsFetchSuccess,
    adminSalesReportsFetchFail,
} from "redux/actions/admin/salesReports";
import {
    adminUnsubmittedSalesReportsFetchStart,
    adminUnsubmittedSalesReportsFetchSuccess,
    adminUnsubmittedSalesReportsFetchFail,
} from "redux/actions/admin/unsubmittedSalesReports";
import {
    adminUnconfirmedSalesReportsFetchStart,
    adminUnconfirmedSalesReportsFetchSuccess,
    adminUnconfirmedSalesReportsFetchFail,
} from "redux/actions/admin/unconfirmedSalesReports";
import {
    salesReportFetchStart,
    salesReportFetchFail,
    salesReportFetchSuccess,
    salesReportDeleteOriginalStart,
    salesReportDeleteOriginalSuccess,
    salesReportDeleteOriginalFail,
} from "redux/actions/reports/salesReport";
//import { b64toBlob } from "util/Files";
//import FileSaver from "file-saver";

export function fetchSalesReports(filters, alertGroup) {
    store.dispatch(adminSalesReportsFetchStart());

    const filtersToUse = { ...filters };

    const dateFormat = "YYYY-MM-DD";
    const formattedFromDate = moment(filtersToUse.startDate)
        .startOf("month")
        .format(dateFormat);
    const formattedToDate = moment(filtersToUse.endDate)
        .endOf("month")
        .format(dateFormat);
    const endpoint = ADMIN_ALL_SALES_REPORTS.replace(
        "<from_date>",
        formattedFromDate
    ).replace("<to_date>", formattedToDate);

    let params = {};
    if (filtersToUse.client) {
        params = {
            client_id: filtersToUse.client.value,
        };
    }

    return axios
        .get(endpoint, {
            params,
        })
        .then((resp) => {
            const unfilteredSalesReports = resp.data;
            const filteredSalesReports = filterSalesReports(
                unfilteredSalesReports,
                filtersToUse
            );
            store.dispatch(
                adminSalesReportsFetchSuccess(
                    unfilteredSalesReports,
                    filteredSalesReports,
                    filtersToUse
                )
            );

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(adminSalesReportsFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    `Müügiaruannete laadimine ebaõnnestus: ${errorMessage}`,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function fetchUnsubmittedSalesReports(filters, alertGroup) {
    store.dispatch(adminUnsubmittedSalesReportsFetchStart());

    const filtersToUse = { ...filters };

    const dateFormat = "YYYY-MM-DD";
    const formattedFromDate = moment(filtersToUse.startDate)
        .startOf("month")
        .format(dateFormat);
    const formattedToDate = moment(filtersToUse.endDate)
        .endOf("month")
        .format(dateFormat);
    const endpoint = ADMIN_UNSUBMITTED_SALES_REPORTS.replace(
        "<from_date>",
        formattedFromDate
    ).replace("<to_date>", formattedToDate);

    let params = {};
    if (filtersToUse.client) {
        params = {
            client_id: filtersToUse.client.value,
        };
    }

    return axios
        .get(endpoint, {
            params,
        })
        .then((resp) => {
            const unfilteredSalesReports = resp.data;
            const filteredSalesReports = filterSalesReports(
                unfilteredSalesReports,
                filtersToUse
            );
            store.dispatch(
                adminUnsubmittedSalesReportsFetchSuccess(
                    unfilteredSalesReports,
                    filteredSalesReports,
                    filtersToUse
                )
            );

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(adminUnsubmittedSalesReportsFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    `Müügiaruannete laadimine ebaõnnestus: ${errorMessage}`,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

export function fetchUnconfirmedSalesReports(filters, alertGroup) {
    store.dispatch(adminUnconfirmedSalesReportsFetchStart());

    const filtersToUse = { ...filters };

    const dateFormat = "YYYY-MM-DD";
    const formattedFromDate = moment(filtersToUse.startDate)
        .startOf("month")
        .format(dateFormat);
    const formattedToDate = moment(filtersToUse.endDate)
        .endOf("month")
        .format(dateFormat);
    const endpoint = ADMIN_UNCONFIRMED_SALES_REPORTS.replace(
        "<from_date>",
        formattedFromDate
    ).replace("<to_date>", formattedToDate);

    let params = {};
    if (filtersToUse.client) {
        params = {
            client_id: filtersToUse.client.value,
        };
    }

    return axios
        .get(endpoint, {
            params,
        })
        .then((resp) => {
            const unfilteredSalesReports = resp.data;
            const filteredSalesReports = filterSalesReports(
                unfilteredSalesReports,
                filtersToUse
            );
            store.dispatch(
                adminUnconfirmedSalesReportsFetchSuccess(
                    unfilteredSalesReports,
                    filteredSalesReports,
                    filtersToUse
                )
            );

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(adminUnconfirmedSalesReportsFetchFail(errorMessage));

            if (alertGroup) {
                clearLocalAlerts();
                addNewLocalErrorAlert(
                    `Müügiaruannete laadimine ebaõnnestus: ${errorMessage}`,
                    alertGroup,
                    true
                );
            }

            return { isSuccess: false, errorMessage };
        });
}

function filterSalesReports(salesReports, filters) {
    if (!salesReports || !salesReports.length || !filters) {
        return salesReports;
    }

    return salesReports.filter((report) => {
        if (filters.client && report.client.value !== filters.client.value) {
            return false;
        }
        if (
            filters.testResult &&
            report.last_test.value !== filters.testResult.value
        ) {
            return false;
        }
        if (
            filters.startDate &&
            !secondDateIsSameOrAfterFirstDate(
                filters.startDate,
                report.period,
                "month"
            )
        ) {
            return false;
        }
        if (
            filters.endDate &&
            !secondDateIsSameOrAfterFirstDate(
                report.period,
                filters.endDate,
                "month"
            )
        ) {
            return false;
        }

        return true;
    });
}

export function adminFetchSalesReport(id) {
    store.dispatch(salesReportFetchStart());
    return axios
        .get(ADMIN_GET_SALES_REPORT.replace("<sales_report_id>", id))
        .then((resp) => {
            store.dispatch(salesReportFetchSuccess(resp.data));

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportFetchFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function adminFetchRepairSalesReport(id) {
    store.dispatch(salesReportFetchStart());
    return axios
        .get(ADMIN_GET_REPAIR_SALES_REPORT.replace("<sales_report_id>", id))
        .then((resp) => {
            store.dispatch(salesReportFetchSuccess(resp.data));

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportFetchFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}

export function adminRepairSalesReportApproval(id, isApprove, comment = "") {
    const data = {
        repair_report_id: id,
        is_approve: isApprove,
        comment: comment,
    };

    return axios
        .put(ADMIN_REPAIR_SALES_REPORT_APPROVAL, data)
        .then((resp) => {
            store.dispatch(salesReportFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);

            throw Error(errorMessage);
        });
}

export function adminDeleteSalesReport(id) {
    /**
     * Used to delete an unsubmitted sales report as EPP admin
     */
    store.dispatch(salesReportDeleteOriginalStart());
    return axios
        .delete(`${ADMIN_DELETE_SALES_REPORT}${id}/`)
        .then((resp) => {
            store.dispatch(salesReportDeleteOriginalSuccess());

            return { isSuccess: true };
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(salesReportDeleteOriginalFail(errorMessage));

            return { isSuccess: false, errorMessage };
        });
}
