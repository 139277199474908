import React from "react";
import {ReactComponent as LeftIcon} from "assets/icons/left.svg";


export default function LeftText(props) {
    return (
        <div onClick={props.onClick} className="cursor-pointer semibold-14">
            <LeftIcon className="icon-left"/>
            <span className="ml-3 align-middle color-info">{props.text}</span>
        </div>
    );
}