import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";
import { Button, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, Row, Col } from 'reactstrap';


import { ReactComponent as MoreVerticalIcon } from 'assets/icons/more-vertical.svg';
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";
import { isAdminMode } from "util/Access";

import ConfirmDetailsModal from "./ConfirmDetailsModal";
import ImportModal from "./ImportModal";
import reportUtils from "./utils";

import "./ActionsMenu.scss";

const StyledDropdownItem = (props) => <DropdownItem {...props} className="semibold-14 text-muted">{props.children}</DropdownItem>;

class ReturnReportActionsMenu extends React.Component {
    static propTypes = {
        details: PropTypes.object.isRequired,
        onConfirmDetailsSuccess: PropTypes.func.isRequired,
        onImportSuccess: PropTypes.func.isRequired,
        isDetailsViewMode: PropTypes.bool,
        // automatic
        isAdmin: PropTypes.bool
    }

    constructor(props) {
        super(props);
        this.state = {
            isConfirmDetailsOpen: false,
            isImportOpen: false
        }
    }

    render() {
        const { isDetailsViewMode } = this.props;

        return (
            <div className="return-report-actions">
                {
                    isDetailsViewMode ?
                        this.renderDetailsViewMode()
                        :
                        this.renderDropdownMenu()
                }
                {this.renderConfirmationModal()}
                {this.renderImportModal()}
            </div>
        )
    }

    renderDetailsViewMode() {
        return (
            <Row>
                {this.renderActions("button-col")}
            </Row>
        )
    }

    renderDropdownMenu() {
        return (
            <UncontrolledButtonDropdown direction="left" onClick={e => { e.stopPropagation(); }}>
                <DropdownToggle tag="div">
                    <Button color="link" className="icon-default return-report-icon-button">
                        <MoreVerticalIcon />
                    </Button>
                </DropdownToggle>
                <DropdownMenu>
                    {this.renderActions("dropdown")}
                </DropdownMenu>
            </UncontrolledButtonDropdown>
        );
    }

    renderConfirmationModal = () => {
        const { details, onConfirmDetailsSuccess } = this.props;
        const { isConfirmDetailsOpen } = this.state;

        const onSuccess = list => {
            this.setState({
                isConfirmDetailsOpen: false
            })
            if (onConfirmDetailsSuccess) {
                onConfirmDetailsSuccess(list);
            }
        }
        return (
            <ConfirmDetailsModal ids={[details?.id]} isOpen={isConfirmDetailsOpen} onToggle={this.onToggleConfirmationModal} onSuccess={onSuccess} />
        )
    }

    renderImportModal = () => {
        const { details, onImportSuccess } = this.props;
        const { isImportOpen } = this.state;

        const onSuccess = (data, details) => {
            this.setState({
                isImportOpen: false
            })
            if (onImportSuccess) {
                onImportSuccess(data, details);
            }
        }
        return (
            <ImportModal details={details} isOpen={isImportOpen} onToggle={this.onToggleImportModal} onSuccess={onSuccess} />
        )
    }

    renderActions(mode) {
        const renderActionList = [
            this.renderActionOpenDetails,
            this.renderActionExport,
            this.renderConfirmDetails,
            this.renderImportFile
        ]

        return renderActionList.map((renderAction) => renderAction(mode))
    }

    renderItem(text, onClick, options) {
        const config = _.defaultsDeep(options, {
            mode: "dropdown", // "dropdown" or "button-col"
        });
        if (config.mode === "button-col") {
            return (
                <Col key={text} xs="auto" className="px-1 py-1">
                    <Button color="success" className="details-button" outline onClick={() => onClick()}>{text}</Button>
                </Col>
            );
        }

        return (
            <StyledDropdownItem
                onClick={() => onClick()}
                key={text}
            >
                {text}
            </StyledDropdownItem>
        );
    }

    renderActionOpenDetails = (mode) => {
        const { t, isDetailsViewMode } = this.props;

        if (isDetailsViewMode) return null;
        const label = t("reports.return.menu.openDetails", "Ava detailvaade");
        return this.renderItem(label, this.onView, { mode });
    }

    renderActionExport = mode => {
        const { t } = this.props;

        const text = t("reports.return.menu.exportLabel", "Ekspordi aruanne");
        return this.renderItem(text, this.onGetExportFile, { mode });
    }

    renderConfirmDetails = mode => {
        const { t, details, isAdmin } = this.props;
        if (!isAdmin) return;
        if (!details?.is_confirmable) return;
        const text = t("reports.return.menu.confirmDetailsLabel", "Kinnita tagastusaruanne")
        return this.renderItem(text, this.onToggleConfirmationModal, { mode })
    }

    renderImportFile = mode => {
        const { t, isAdmin } = this.props;
        if (!isAdmin) return;
        const text = t("reports.return.menu.importLabel", "Uuenda failist")
        return this.renderItem(text, this.onToggleImportModal, { mode })
    }

    onView = (isOpenInTab = false) => {
        const { history, details } = this.props;
        const route = reportUtils.getDetailsViewUrl(details.id);
        if (isOpenInTab) {
            window.open(route, '_blank');
        } else {
            history.push(route);
        }
    }

    onToggleConfirmationModal = (value) => {
        this.setState(prevState => ({
            isConfirmDetailsOpen: !prevState.isConfirmDetailsOpen
        }));
    }

    onToggleImportModal = (value) => {
        this.setState(prevState => ({
            isImportOpen: !prevState.isImportOpen
        }));
    }

    onGetExportFile = () => {
        const { t, location, details } = this.props;
        clearLocalAlerts();
        reportUtils.fetchExportFile(details.id)
            .catch(error => {
                // can use error.message because error is already parsed
                addNewLocalErrorAlert(
                    t("reports.return.menu.exportExcelTemplateFailed", "Aruande ekspordi allalaadimine ebaõnnestus: ") + error.message,
                    location.pathname
                );
            })
    }
}

const mapStateToProps = (state) => {
    // Module state reducer
    return {
        isAdmin: isAdminMode()
    };
};

export default withRouter(connect(mapStateToProps, null)(withTranslation()(ReturnReportActionsMenu)));
