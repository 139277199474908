import React from "react";
import { Link, NavLink } from "react-router-dom";

import { addNewGlobalWarningAlert } from "util/Alerts";
import urls from "../config/urls";
import {
    isConnectedToClientWithPendingOrActiveContract,
    isConnectedToClientWithPendingContract,
    isClientMode,
    isVerifiedUser,
} from "util/Access";

import { Translation, useTranslation } from "react-i18next";

export function showMissingContractAlert() {
    if (!isClientMode()) {
        return;
    }

    if (!isConnectedToClientWithPendingOrActiveContract()) {
        const message = <CreateContractAlertContent />;
        addNewGlobalWarningAlert(message, true);
    } else if (isConnectedToClientWithPendingContract()) {
        const message = (
            <div className="d-flex align-items-center w-100">
                <span>
                    <Translation>
                        {(t) =>
                            t(
                                "alerts.contractPendingSignature.text",
                                "Pakendite registreerimiseks peate ootama kuni Pandipakend allkirjastab omalt poolt liitumise."
                            )
                        }
                    </Translation>
                </span>
            </div>
        );
        addNewGlobalWarningAlert(message, true);
    }
}

export function showNotVerifiedAlert() {
    if (!isVerifiedUser()) {
        const message = (
            <div className="d-flex align-items-center w-100">
                <span>
                    <Translation>
                        {(t) =>
                            t(
                                "alerts.clientUserNotVerified.text",
                                "Puuduvad õigused antud ettevõtte andmeid näha! Võta ühendust administraatoriga."
                            )
                        }
                    </Translation>
                </span>
            </div>
        );
        addNewGlobalWarningAlert(message, true);
    }
}
function CreateContractAlertContent(props) {
    const { t } = useTranslation();
    return (
        <div className="d-flex align-items-center w-100">
            <span>
                {t(
                    "alerts.createContract.text",
                    "Pakendite registreerimiseks on teil tarvis vormistada leping Eesti Pakendiregistriga."
                )}
            </span>

            <NavLink
                to={urls.CONTRACT}
                tag={Link}
                className="btn-success ml-auto d-flex align-items-center"
            >
                {t("alerts.createContract.button", "Lepingut vormistama")}
            </NavLink>
        </div>
    );
}
