import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { Form, Card, CardBody, Row, Col, Button } from "reactstrap";

// css
import "./SalesSearch.scss";
import SmallLoader from "components/Loader/WrappedPulseLoader";

// datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
    salesSearchFilterUpdate,
    salesClearFilters,
} from "redux/actions/reports/sales";

import { fetchSalesList } from "util/SalesReports";
import { clearLocalAlerts } from "util/Alerts";

import { salesReportPeriodSearchFormat, getFromAndToDatePeriodAsString } from "util/DateTime";

import {
    clearNavigationHeaderSuffix,
    updateNavigationHeaderSuffix,
} from "util/Navigation";
import { withTranslation } from "react-i18next";

class SalesReportsSearch extends React.Component {
    componentDidMount() {
        if (this.props.salesList) {
            updateNavigationHeaderSuffix(
                getFromAndToDatePeriodAsString(
                    this.props.salesListFromDate,
                    this.props.salesListToDate
                )
            );
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.salesList &&
            this.props.salesList !== prevProps.salesList
        ) {
            updateNavigationHeaderSuffix(
                getFromAndToDatePeriodAsString(
                    this.props.salesListFromDate,
                    this.props.salesListToDate
                )
            );
        }
    }

    componentWillUnmount() {
        clearNavigationHeaderSuffix();
    }

    render() {
        const { t } = this.props;
        // https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md
        return (
            <Form>
                <Card className="sales-search-card mb-3">
                    <CardBody className="">
                        <Row className="align-items-center mx-0">
                            <Col className="search-period-text bold-19 col-md-12 col-lg-auto mb-2 px-2">
                                <span className="">
                                    {t("reports.filter.period", "Periood")}
                                </span>
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-2">
                                <DatePicker
                                    className="search-input"
                                    selected={this.props.filters.fromDate}
                                    onChange={(date) =>
                                        this.props.salesSearchFilterUpdate(
                                            "fromDate",
                                            date
                                        )
                                    }
                                    placeholderText={t(
                                        "reports.filter.from",
                                        "Alates"
                                    )}
                                    dateFormat={salesReportPeriodSearchFormat}
                                    showMonthYearPicker
                                    maxDate={
                                        this.props.filters.toDate
                                            ? moment(
                                                this.props.filters.toDate
                                            ).toDate()
                                            : new Date()
                                    }
                                />
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-2">
                                <DatePicker
                                    className="search-input"
                                    selected={this.props.filters.toDate}
                                    onChange={(date) =>
                                        this.props.salesSearchFilterUpdate(
                                            "toDate",
                                            date
                                        )
                                    }
                                    placeholderText={t("reports.filter.to", "Kuni")}
                                    dateFormat={salesReportPeriodSearchFormat}
                                    showMonthYearPicker
                                    minDate={
                                        this.props.filters.fromDate &&
                                        moment(this.props.filters.fromDate).toDate()
                                    }
                                    maxDate={new Date()}
                                />
                            </Col>
                            <Col lg="2" md="6" className="mb-2 px-2">
                                <Button
                                    block
                                    color="success"
                                    className="search-button"
                                    type="submit"
                                    onClick={e => { e.preventDefault(); this.onSearch(); }}
                                    disabled={this.props.salesListLoading}
                                >
                                    {this.props.salesListLoading ? (
                                        <SmallLoader />
                                    ) : (
                                            t("buttons.search", "Otsi")
                                        )}
                                </Button>
                            </Col>
                            <Col lg="3" md="6" className="ml-md-auto mb-2 px-2">
                                <Button
                                    outline
                                    block
                                    color="success"
                                    className="search-button"
                                    onClick={this.props.salesClearFilters}
                                >
                                    {t("buttons.clearSearch", "Tühjenda otsing")}
                                </Button>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Form>
        );
    }

    onSearch = () => {
        clearLocalAlerts();
        this.getSalesList();
    };

    getSalesList = () => {
        fetchSalesList(
            this.props.filters.fromDate,
            this.props.filters.toDate,
            this.props.location.pathname
        );
    };
}

const mapStateToProps = (state) => {
    return {
        activeClientId: state.auth.user
            ? state.auth.user.active_client_id
            : null,
        salesListLoading: state.sales.salesListLoading,
        salesList: state.sales.salesList,
        salesListFromDate: state.sales.salesListFromDate,
        salesListToDate: state.sales.salesListToDate,
        filters: state.sales.filters,
    };
};

const reduxActions = {
    salesSearchFilterUpdate,
    salesClearFilters,
};

export default withRouter(
    connect(
        mapStateToProps,
        reduxActions
    )(withTranslation("common")(SalesReportsSearch))
);
