import axios from "../axios";
import {LATEST_REPORTS, LATEST_PACKAGES, STATISTICS} from "../config/Api";
import store from 'redux/store';
import {
    lastPackagesFetchFail,
    lastPackagesFetchStart,
    lastPackagesFetchSuccess, lastReportsFetchFail,
    lastReportsFetchStart, lastReportsFetchSuccess, statisticsFetchFail, statisticsFetchStart, statisticsFetchSuccess
} from "../redux/actions/dashboard";

export function fetchLastPackages() {
    store.dispatch(lastPackagesFetchStart());
    return axios
        .get(LATEST_PACKAGES)
        .then(resp => {
            store.dispatch(lastPackagesFetchSuccess(resp.data));
        })
        .catch(err => {
            store.dispatch(lastPackagesFetchFail(err));
        });
}

export function fetchLastReports() {
    store.dispatch(lastReportsFetchStart());
    return axios
        .get(LATEST_REPORTS)
        .then(resp => {
            store.dispatch(lastReportsFetchSuccess(resp.data));
        })
        .catch(err => {
            store.dispatch(lastReportsFetchFail(err));
        });
}

export function fetchStatistics() {
    store.dispatch(statisticsFetchStart());
    return axios
        .get(STATISTICS)
        .then(resp => {
            store.dispatch(statisticsFetchSuccess(resp.data));
        })
        .catch(err => {
            store.dispatch(statisticsFetchFail(err));
        });
}