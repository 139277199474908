import React from "react";
import PropTypes from "prop-types";
import {
    Row,
    Col,
} from "reactstrap";
import Dropzone from "react-dropzone";

import "./FileUpload.scss";


class FileUpload extends React.Component {
    static propTypes = {
        file: PropTypes.object,
        onDrop: PropTypes.func.isRequired,
        onDropRejected: PropTypes.func,
        onDropAccepted: PropTypes.func,
        accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
        maxSize: PropTypes.number,
        dropzoneText: PropTypes.string,
    }

    static defaultProps = {
        accept: "",
        dropZoneText: "Drag 'n' drop some files here, or click to select files",
        maxSize: 5e6,
    }

    getContainerClassName = (props) => {
        let className = "dropzone-container";
        
        /* isDragReject and isDragAccept not reliable.
        if (props.isDragReject) {
            className += " is-drag-reject";
        }
        else if (props.isDragAccept) {
            className += " is-drag-accept";
        }
        else if (props.isDragActive) {
            className += " is-drag-active";
        }
        */
        if (props.isDragActive || props.isDragAccept || props.isDragReject) {
            className += " is-drag-active";
        }

        return className;
    }

    render() {
        return (
            <>
                <Row>
                    <Col>
                        <Dropzone 
                            onDrop={this.props.onDrop}
                            onDropRejected={this.props.onDropRejected}
                            onDropAccepted={this.props.onDropAccepted}
                            accept={this.props.accept}
                            multiple={false}
                            className="test"
                            maxSize={this.props.maxSize}
                        >
                            {({getRootProps, getInputProps, isFocused, isDragActive, isDragAccept, isDragReject}) => (
                                <section>
                                    <div 
                                        {...getRootProps()} 
                                        className={this.getContainerClassName({isFocused, isDragAccept, isDragReject, isDragActive})}
                                    >
                                        <input {...getInputProps()} />
                                        <p>{this.props.dropzoneText}</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone>
                    </Col>
                </Row>
            </>
        );
    }
}

export default FileUpload;
