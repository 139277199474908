import React from "react";
import PropTypes from "prop-types";
import {FadeLoader} from "react-spinners";
import {Row, Col} from "reactstrap";

function WrappedLoader(props) {
    return (
        <Row className="text-center justify-content-center p-3">
            <Col className="col-auto">
                <FadeLoader
                    color={"#46A72A"}
                    loading={props.loading}
                    {...props.extraProps}
                />
            </Col>
        </Row>
    );
}

WrappedLoader.propTypes = {
    loading: PropTypes.bool,
    //size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    extraProps: PropTypes.object,
};

WrappedLoader.defaultProps = {
    //size: 15,
    extraProps: {},
    loading: true,
}

export default WrappedLoader;