import React from "react";
import PropTypes from "prop-types";

import { isAdminMode, isPartnerMode, isClientMode } from "util/Access";
import commentUtils from "./utils";
import moment from "moment";

import { ReactComponent as DeleteIcon } from "./icons/delete.svg";

import "./Comment.scss";

class Comment extends React.Component {
  static propTypes = {
    // comment data
    id: PropTypes.number.isRequired, // Comment ID
    body: PropTypes.string.isRequired, // Content
    created_at: PropTypes.string.isRequired, // ISO-8601
    created_by: PropTypes.string.isRequired,
    role: PropTypes.string.isRequired, // commentUtils.TYPE
    type: PropTypes.string.isRequired, // commentUtils.TYPE
    visibility: PropTypes.string.isRequired, // commentUtils.VISIBILITY
    can_delete: PropTypes.bool,
    // functionality
    onDelete: PropTypes.func // Expect to return a Promise
  }

  static defaultProps = {
    can_delete: false
  }

  constructor(props) {
    super(props);
    this.state = {
      currentDate: new Date(), // Updating this in interval is enough to cause rerender, don't need to use it actual logic.
    }
    this.currentDateIntervalId = null;
  }

  componentDidMount() {
    const handler = () => {
      this.setState({ currentDate: new Date() });
    };
    const delay = 1000 * 60; // 1 minute
    this.currentDateIntervalId = setInterval(handler, delay);
  }

  componentWillUnmount() {
    clearInterval(this.currentDateIntervalId);
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    return (
      <div className={this.getCommentClassNames()}>
        {this.renderDelete()}
        <div className="comment__wrapper">
          <div className="comment__body">
            <div className="comment__content">
              {this.props.body}
            </div>
          </div>
          <div className="comment__info">
            {this.renderInfo()}
          </div>
        </div>
      </div>
    )
  }

  renderInfo() {
    const { created_by } = this.props;
    const createdAt = this.formattedCreatedAt();
    if (created_by) {
      return `${created_by}, ${createdAt}`;
    }

    return createdAt;
  }

  renderDelete() {
    if (!this.canDelete()) return null;

    return (
      <div className="comment__delete-wrapper">
        <button
          className="comment__delete-button"
          onClick={this.deleteComment}
        >
          <DeleteIcon />
        </button>
      </div>
    )
  }

  canDelete() {
    if (!this.props.onDelete) return false;
    return this.props.can_delete;
  }

  deleteComment = () => {
    if (!this.canDelete()) return
    this.props.onDelete(this.props.id)
  }

  formattedCreatedAt() {
    const { created_at } = this.props;
    const createdAt = moment(created_at);
    return createdAt.fromNow();
  }

  getCommentRoleClass() {
    const { role } = this.props;
    const ROLE = commentUtils.ROLE;
    if (role === ROLE.SYSTEM) {
      return "comment--system";
    }

    const isActiveRole = {
      [commentUtils.ROLE.EPP]: isAdminMode(),
      [commentUtils.ROLE.CLIENT]: isClientMode(),
      [commentUtils.ROLE.PARTNER]: isPartnerMode()
    };
    return isActiveRole[this.props.role] ? "comment--active" : "comment--default";
  }

  getCommentClassNames() {
    return `comment ${this.getCommentRoleClass()}`;
  }
}

export default Comment;
