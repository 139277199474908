import React from "react";

import { NavLink, Route } from "react-router-dom";
import { Switch } from "react-router";

import urls from "config/urls";
import { Button } from "reactstrap";
import {
    fetchSettingsFromNav,
    fetchAdminSettingsCategories,
    fetchAdminSettingsColors,
    fetchAdminSettingsProcessFees,
    fetchAdminSettingsTypes,
    fetchAdminSettingsVolumes,
} from "util/admin/Settings";

import LoadingAnimation from "components/Loader/WrappedPulseLoader";

class SettingsView extends React.Component {
    state = {
        loading: false,
    };

    fetchSettingsFromNav = () => {
        this.setState({
            loading: true,
        });
        fetchSettingsFromNav()
            .then(() => {
                fetchAdminSettingsCategories();
                fetchAdminSettingsColors();
                fetchAdminSettingsProcessFees();
                fetchAdminSettingsTypes();
                fetchAdminSettingsVolumes();
                this.setState({
                    loading: false,
                });
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
            });
    };

    render() {
        function RouteWithSubRoutes(route) {
            return (
                <Route
                    path={route.path}
                    render={(props) => (
                        // pass the sub-routes down to keep nesting
                        <route.component {...props} routes={route.routes} />
                    )}
                />
            );
        }

        return (
            <>
                <div className="navigation-buttons pb-4">
                    <NavLink
                        to={urls.EPP_SETTINGS_CATEGORIES}
                        className="btn btn-default mr-1 mb-2"
                    >
                        Kategooriad
                    </NavLink>
                    <NavLink
                        to={urls.EPP_SETTINGS_TYPES}
                        className="btn btn-default ml-1 mr-1 mb-2"
                    >
                        Tüübid
                    </NavLink>
                    <NavLink
                        to={urls.EPP_SETTINGS_COLORS}
                        className="btn btn-default ml-1 mr-1 mb-2"
                    >
                        Värvid
                    </NavLink>
                    <NavLink
                        to={urls.EPP_SETTINGS_VOLUMES}
                        className="btn btn-default ml-1 mr-1 mb-2"
                    >
                        Mahud
                    </NavLink>
                    <NavLink
                        to={urls.EPP_SETTINGS_PROCESS_FEES}
                        className="btn btn-default ml-1 mr-1 mb-2"
                    >
                        Käitlustasud
                    </NavLink>
                </div>
                <div>
                    <span>
                        <Button
                            outline
                            className="mb-3"
                            color="success"
                            onClick={this.fetchSettingsFromNav}
                        >
                            {this.state.loading ? (
                                <LoadingAnimation
                                    loading={this.state.loading}
                                />
                            ) : (
                                    "Uuenda klassifikaatoreid"
                                )}
                        </Button>
                    </span>
                </div>

                <Switch>
                    {this.props.routes.map((route, i) => (
                        <RouteWithSubRoutes key={i} {...route} />
                    ))}
                </Switch>
            </>
        );
    }
}

export default SettingsView;
