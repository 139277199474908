import * as actionTypes from 'redux/actions/actionTypes';

export const adminApplicationsFetchStart = () => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_FETCH_START
    }
};

export const adminApplicationsFetchSuccess = (unfilteredPackagesList, packagesList, filters) => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_FETCH_SUCCESS,
        unfilteredPackagesList, packagesList, filters,
    }
};

export const adminApplicationsFetchFail = (error) => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_FETCH_FAIL,
        error,
    }
};

export const adminApplicationsFilterUpdate = (key, value) => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_FILTER_UPDATE,
        key: key,
        value: value,
    }
}

export const adminApplicationsFilterClear = () => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_FILTER_CLEAR,
    }
}

export const adminApplicationsSortingUpdate = (sorting) => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_SORTING_UPDATE,
        sorting,
    }
}

export const adminApplicationsPaginationUpdate = (pagination) => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_PAGINATION_UPDATE,
        pagination,
    }
};

export const adminApplicationsUpdateSelected = (selected) => {
    return {
        type: actionTypes.ADMIN_APPLICATIONS_UPDATE_SELECTED,
        selected,
    }
}

export const adminSendToTestingStart = () => {
    return {
        type: actionTypes.ADMIN_SEND_TO_TESTING_START
    }
};

export const adminSendToTestingSuccess = (ids) => {
    return {
        type: actionTypes.ADMIN_SEND_TO_TESTING_SUCCESS,
        ids,
    }
};

export const adminSendToTestingFail = (error) => {
    return {
        type: actionTypes.ADMIN_SEND_TO_TESTING_FAIL,
        error,
    }
};