import * as actionTypes from "redux/actions/actionTypes";
import _ from "lodash";
import { updateObject } from "redux/utility";

import { getPageCount } from "util/General";

const initialFilters = {
    ean: "",
    name: "",
    client: null,
    category: null,
    type: null,
    conditions: [],
    testResult: null,
    //startDate: null,
    //endDate: null,
};

const initialSorting = {
    dataField: "date",
    order: "desc",
};

const initialPagination = {
    page: 1,
    sizePerPage: 25,
    dataSize: 0,
    sizePerPageList: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 0, text: "Kõik" },
    ],
};

const initialState = {
    filters: initialFilters,
    sorting: initialSorting,
    pagination: initialPagination,

    unfilteredPackagesList: null,
    packagesList: null,
    packagesListFiltersUsed: initialFilters,
    packagesListLoading: false,
    loading: false,
    error: null,
    selectedIds: [],
    confirmPackageRetestingLoading: false,
};

const filterIdsInList = (ids, packages) => {
    const packageIds = packages.map((pkg) => pkg.id);
    return ids.filter((id) => packageIds.includes(id));
};

const resetUserSpecificData = () => {
    return initialState;
};

const onAdminRetestingPackagesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        packagesListLoading: true,
        error: null,
        packagesList: null,
    });
};

const onAdminRetestingPackagesFetchSuccess = (state, action) => {
    const areFilterSame = _.isEqual(
        state.packagesListFiltersUsed,
        action.filters
    );

    const newDataSize = action.packagesList.length;
    const currentPage = state.pagination.page;
    const sizePerPage = state.pagination.sizePerPage;
    const isCurrentPageValid =
        currentPage <= getPageCount(newDataSize, sizePerPage);

    return updateObject(state, {
        loading: false,
        packagesListLoading: false,
        error: null,
        packagesList: action.packagesList,
        unfilteredPackagesList: action.unfilteredPackagesList,
        packagesListFiltersUsed: action.filters,
        pagination: {
            ...state.pagination,
            page: areFilterSame && isCurrentPageValid ? currentPage : 1,
            dataSize: newDataSize,
        },
        selectedIds: filterIdsInList(
            state.selectedIds,
            action.unfilteredPackagesList
        ),
    });
};

const onAdminRetestingPackagesFetchFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        packagesListLoading: false,
        selectedIds: [],
    });
};

const onAdminRetestingPackagesFilterUpdate = (state, action) => {
    return updateObject(state, {
        filters: {
            ...state.filters,
            [action.key]: action.value,
        },
    });
};

const onAdminRetestingPackagesSortingUpdate = (state, action) => {
    return updateObject(state, {
        sorting: action.sorting,
    });
};

const onAdminRetestingPackagesPaginationUpdate = (state, action) => {
    return updateObject(state, {
        pagination: action.pagination,
    });
};

const onAdminRetestingPackagesFilterClear = (state, action) => {
    return updateObject(state, {
        filters: {
            ...initialFilters,
        },
    });
};

const onAdminRetestingConfirmPackageTestStart = (state) => {
    return updateObject(state, {
        confirmPackageRetestingLoading: true,
    });
};

const onAdminRetestingConfirmPackageTestSuccess = (state, action) => {
    const idsToRemove = action.ids;

    return updateObject(state, {
        confirmPackageRetestingLoading: false,
        selectedIds: state.selectedIds.filter(
            (id) => !idsToRemove.includes(id)
        ),
    });
};

const onAdminRetestingConfirmPackageTestFail = (state, action) => {
    return updateObject(state, {
        confirmPackageRetestingLoading: false,
    });
};

const onAdminRetestingUpdateSelected = (state, action) => {
    return updateObject(state, {
        selectedIds: action.selected,
    });
};

export const retesting = (state = initialState, action) => {
    switch (action.type) {
        // CONFIRM PACKAGE TESTS
        case actionTypes.ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_START:
            return onAdminRetestingConfirmPackageTestStart(state, action);
        case actionTypes.ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_SUCCESS:
            return onAdminRetestingConfirmPackageTestSuccess(state, action);
        case actionTypes.ADMIN_RETESTING_CONFIRM_PACKAGE_TEST_FAIL:
            return onAdminRetestingConfirmPackageTestFail(state, action);
        // UPDATE SELECTED
        case actionTypes.ADMIN_RETESTING_UPDATE_SELECTED:
            return onAdminRetestingUpdateSelected(state, action);
        // SEARCH
        case actionTypes.ADMIN_RETESTING_PACKAGES_FETCH_START:
            return onAdminRetestingPackagesFetchStart(state, action);
        case actionTypes.ADMIN_RETESTING_PACKAGES_FETCH_SUCCESS:
            return onAdminRetestingPackagesFetchSuccess(state, action);
        case actionTypes.ADMIN_RETESTING_PACKAGES_FETCH_FAIL:
            return onAdminRetestingPackagesFetchFail(state, action);
        // FILTER
        case actionTypes.ADMIN_RETESTING_PACKAGES_FILTER_UPDATE:
            return onAdminRetestingPackagesFilterUpdate(state, action);
        case actionTypes.ADMIN_RETESTING_PACKAGES_FILTER_CLEAR:
            return onAdminRetestingPackagesFilterClear(state, action);
        // SORT
        case actionTypes.ADMIN_RETESTING_PACKAGES_SORTING_UPDATE:
            return onAdminRetestingPackagesSortingUpdate(state, action);
        // PAGINATION
        case actionTypes.ADMIN_RETESTING_PACKAGES_PAGINATION_UPDATE:
            return onAdminRetestingPackagesPaginationUpdate(state, action);
        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};
