import XLSX from "xlsx";

export const exportClientPackagesTable = (
    fileName,
    packages,
    filtersUsed,
    client
) => {
    /* Generate Workbook */
    const wb = XLSX.utils.book_new();

    const packagesSheet = getPackagesSheet(packages);
    XLSX.utils.book_append_sheet(wb, packagesSheet, "Pakendid");

    const extraInfoSheet = getExtraInfoSheet(filtersUsed, client);
    XLSX.utils.book_append_sheet(wb, extraInfoSheet, "Lisainfo");

    /* Trigger Download with `writeFile` */
    XLSX.writeFile(wb, fileName + ".xlsx", { compression: true });
};

function getExtraInfoSheet(filters, client) {
    const extraInfoRows = [
        ["Klient", client],
        ["Koostatud", new Date()],
        [],
        ["Filtrid"],
        ["EAN", filters.filterEan],
        ["EAN tüüp", filters.filterEanType ? filters.filterEanType.value : ""],
        ["Toote nimetus", filters.filterProductName],
        [
            "Kategooria",
            filters.filterCategory ? filters.filterCategory.description : "",
        ],
        [
            "Pakendi tüüp",
            filters.filterPackageType ? filters.filterPackageType.value : "",
        ],
        ["Maht", filters.filterCapacity ? filters.filterCapacity.value : ""],
        ["Staatus", filters.filterStatus ? filters.filterStatus.value : ""],
        [
            "Testitulemus",
            filters.filterTestResult ? filters.filterTestResult.label : "",
        ],
        ["Alates", filters.startDate],
        ["Kuni", filters.endDate],
    ];

    const ws = XLSX.utils.aoa_to_sheet(extraInfoRows, { dateNF: "DD-MM-YYYY" });

    // Set widths
    const colWidths = [25, 25];
    ws["!cols"] = colWidths.map((x) => ({ width: x }));

    return ws;
}

function getPackagesSheet(packages) {
    const packagesHeaders = [
        "EAN",
        "EAN tüüp",
        "Nimetus",
        "Kategooria",
        "Tüüp",
        "Maht",
        "Staatus",
        "Kuupäev",
        "Testiulemus",
    ];
    const packagesDataRows = getPackagesDataRows(packages);
    const packagesRows = [packagesHeaders, ...packagesDataRows];
    const ws = XLSX.utils.aoa_to_sheet(packagesRows, { dateNF: "DD-MM-YYYY" });

    if (packagesDataRows.length === 0) {
        XLSX.utils.sheet_add_aoa(
            ws,
            [["Ükski pakend ei vasta otsingutingimustele."]],
            { origin: { r: 1, c: 0 } }
        ); //sheet_add_aoa expects array of arrays.
        ws["!merges"] = [
            { s: { r: 1, c: 0 }, e: { r: 1, c: packagesHeaders.length - 1 } },
        ];
    }
    // Set widths
    const colWidths = [16, 16, 25, 25, 20, 8, 15, 12, 15];
    ws["!cols"] = colWidths.map((x) => ({ width: x }));

    return ws;
}

function getPackagesDataRows(packages) {
    return packages.map((pkg) => [
        pkg.EAN,
        pkg.EAN_type,
        pkg.name,
        pkg.package_category,
        pkg.package_type,
        pkg.volume,
        pkg.status.label,
        pkg.combined_at,
        pkg.package_test ? pkg.package_test : "",
    ]);
}
