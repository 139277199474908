import axios from '../axios';
import store from 'redux/store';
import moment from "moment";
import i18n from "i18n";

import {getServerErrorMessage} from "util/Errors";
import {b64toBlob} from "util/Files";
import FileSaver from "file-saver";

import {
    GET_SUMMARY_REPORT,
    EXPORT_SUMMARY_REPORT
} from "../config/Api";

import {
    summaryReportSearchStart,
    summaryReportSearchSuccess,
    summaryReportSearchFail,
    summaryReportExportStart,
    summaryReportExportSuccess,
    summaryReportExportFail,
} from "../redux/actions/reports/summaryReport";
import {
    salesReportPeriodServerFormat,
} from "util/DateTime";


export function fetchSummaryReport(filters) {
    store.dispatch(summaryReportSearchStart());
    return axios
        .get(GET_SUMMARY_REPORT, {
            params: createSummaryReportQueryParameters(filters)
        })
        .then(resp => {
            store.dispatch(summaryReportSearchSuccess(resp.data, filters.fromDate, filters.toDate));
            return {isSuccess: true};
        })
        .catch(err => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(summaryReportSearchFail(errorMessage));

            return {isSuccess: false, errorMessage};
        });
}

export function fetchSummaryReportExportFile(filters) {
    store.dispatch(summaryReportExportStart());
    return axios
        .get(EXPORT_SUMMARY_REPORT, {
            params: createSummaryReportQueryParameters(filters)            
        })
        .then(resp => {
            const blob = b64toBlob(resp.data);
            const filename = i18n.t("filename.summaryReportExcel", {date: moment().format("YYYY-MM-DD")})
            FileSaver.saveAs(blob, filename);
            store.dispatch(summaryReportExportSuccess());

            return {isSuccess: true};
        })
        .catch(err => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(summaryReportExportFail(errorMessage));

            return {isSuccess: false, errorMessage};
        });
}

function createSummaryReportQueryParameters(filters) {
    return {
        fromDate: filters.fromDate ? moment(filters.fromDate).format(salesReportPeriodServerFormat)  : "",
        toDate: filters.toDate ? moment(filters.toDate).format(salesReportPeriodServerFormat)  : "",
        packageType: filters.packageType ? filters.packageType.value : "",
        packageCategory: filters.packageCategory ? filters.packageCategory.value : "",
        showEANCode: filters.showEANCode,
        showEANType: filters.showEANType,
        showPackageType: filters.showPackageType,
        showName: filters.showName,
        showPackageCategory: filters.showPackageCategory,
        showVolume: filters.showVolume,
        showDeposit: filters.showDeposit,
    }
}

export function findAndSortPeriods(summaryList, filters) {
    if (!summaryList) {
        return [];
    }
    const uniquePeriodsSet = new Set();
    for (const item of summaryList) {
        Object.keys(item.periods).forEach(period => {
            uniquePeriodsSet.add(period);   
        });
    }

    let periods = [...uniquePeriodsSet.values()];
    /*
    const fromDate = filters.fromDate;
    if (fromDate) {
        periods = periods
        .filter(period => secondDateIsSameOrAfterFirstDate(fromDate, parseSalesReportPeriodDate(period)));
    }
    const toDate = filters.toDate;
    if (toDate) {
        periods = periods
        .filter(period => secondDateIsSameOrAfterFirstDate(parseSalesReportPeriodDate(period), toDate));
    }
    */
    return periods.sort();
}
