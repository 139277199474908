import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import _ from "lodash";
import { Modal, ModalBody, Row, Col, Button } from "reactstrap";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";

import urls from "config/urls";
import { INVOICE_PREVIEW_ALERT_GROUP, fetchInvoiceData } from "util/Invoices";
import Everypay from "util/Everypay";
import Alerts from "components/Alerts/Alerts";
import {
    clearLocalAlerts,
    addNewLocalErrorAlert,
    addNewLocalInfoAlert,
} from "util/Alerts";
import { getServerErrorMessage } from "util/Errors";

import { currencyFormatter } from "util/General";

import { ReactComponent as CloseIcon } from "assets/icons/diagonal-plus-close.svg";

import WrapperLoader from "components/Loader/WrappedLoader";
import SmallLoader from "components/Loader/WrappedPulseLoader";

import CenteredRowCol from "components/Misc/CenteredRowCol";

import "./PreviewModal.scss";
import { withTranslation, Trans } from "react-i18next";

class InvoicePreviewModal extends Component {
    static propTypes = {
        invoice: PropTypes.object,
        isInvoiceLoading: PropTypes.bool,
        alerts: PropTypes.array,
        alertGroup: PropTypes.string,
    };

    state = {
        modal: true,
        initiatePaymentLoading: false
    };

    componentDidMount() {
        clearLocalAlerts();
        const id = this.props.match.params.id;
        fetchInvoiceData(id)
            .then((data) => { })
            .catch((error) => {
                addNewLocalErrorAlert(
                    error.message,
                    this.props.alertGroup,
                    false
                );
            });
    }

    toggle = () => {
        this.setState(
            { modal: !this.state.modal },
            this.props.history.push(urls.INVOICES, { skipMountFetchInvoices: true })
        );
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                    centered
                    size="xl"
                    className="invoice-preview-modal"
                >
                    <ModalBody className="">
                        <Row className="pb-2 header-section">
                            <Col>
                                <span className="modal-title">
                                    {t("invoices.preview", "Arve eelvaade")}
                                </span>
                            </Col>
                            <Col className="float-right text-right col-auto">
                                <span
                                    onClick={this.toggle}
                                    disabled={this.props.isLoading}
                                    className="modal-close-button"
                                >
                                    <span>{t("buttons.close", "Sulge")} </span>
                                    <CloseIcon className="close-icon" />
                                </span>
                            </Col>
                        </Row>
                        <Alerts
                            alerts={this.props.alerts}
                            hideGlobal={true}
                            filterGroups={[this.props.alertGroup]}
                        />
                        {this.renderContent()}
                    </ModalBody>
                </Modal>
            </div>
        );
    }

    renderContent() {
        if (this.props.isInvoiceLoading) {
            return this.renderLoading();
        } else if (!this.props.invoice) {
            return;
        }

        const showPreview = _.get(this.props, "invoice.totals.show_invoice_preview", false);

        if (showPreview) {
            return (
                <>
                    {this.renderReceiverSection()}
                    {this.renderTableSection()}
                    {this.renderFooterSection()}
                </>
            );
        }
        else {
            return (
                <>
                    {/*this.renderReceiverSection()*/}
                    {this.renderNoPreview()}
                    {this.renderFooterSection()}
                </>
            );
        }
    }

    renderNoPreview() {
        const invoiceSum = currencyFormatter.format(_.get(this.props, "invoice.totals.with_VAT", 0));
        return (
            <>
                <p className="regular-14">{this.props.t("invoices.noPreview.explanation", "Eelvaadet ei ole võimalik kuvada.")}</p>
                <p className="regular-14"><Trans i18nKey="invoices.noPreview.invoiceSum">Arve summa on {{ invoiceSum }} koos käibemaksuga.</Trans></p>
            </>
        );
    }

    renderReceiverSection() {
        const { t } = this.props;
        const invoiceReceiver = _.get(this.props, "invoice.invoice_receiver", {});
        return (
            <Row className="invoice-preview-receiver-section">
                <Col>
                    <Row>
                        <Col className="bold-12 mb-3">
                            {t("invoices.receiver", "Saaja")}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="semibold-16">
                            {invoiceReceiver.name}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="semibold-16">
                            {this.getCombinedAddress(invoiceReceiver)}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="semibold-16">
                            {invoiceReceiver.reg_number}
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    }

    renderTableSection() {
        const { t } = this.props;
        const invoiceRows = _.get(this.props, "invoice.rows", []);
        const salesPeriod = _.get(this.props, "invoice.period", "");

        const columns = [
            {
                dataField: "value",
                text: t("invoices.description", "KIRJELDUS") + (salesPeriod ? ` (${salesPeriod})` : ""),
                headerClasses: "bold-12",
                classes: "regular-14",
                footer: "",
            },
            {
                dataField: "quantity",
                text: t("invoices.quantity", "KOGUS (TK)"),
                headerClasses: "bold-12",
                classes: "regular-14",
                align: "right",
                headerAlign: "right",
            },
            {
                dataField: "unit_price",
                text: t("invoices.price", "HIND (€)"),
                headerClasses: "bold-12",
                classes: "regular-14",
                align: "right",
                headerAlign: "right",
            },
            {
                dataField: "vat_id",
                text: t("invoices.vatId", "KM ID"),
                headerClasses: "bold-12",
                classes: "regular-14",
                align: "right",
                headerAlign: "right",
                footerClasses: "semibold-14",
                footerAlign: "right",
                footerAttrs: { colSpan: "3" },
                footer: () => {
                    return (
                        <>
                            <div className="regular-14 mb-3">
                                {t("invoices.amount", "Summa")}
                            </div>
                            <div className="bold-14 pb-3 bottom-divider">
                                {t("invoices.vat", "Käibemaks")}
                            </div>
                            <div className="bold-16 mt-3">
                                {t(
                                    "invoices.amountWithVat",
                                    "Summa koos käibemaksuga"
                                )}
                            </div>
                        </>
                    );
                },
            },
            {
                dataField: "total",
                text: t("invoices.invoicePreviewModal.amountTableHeader", "SUMMA (€)"),
                headerClasses: "bold-12",
                classes: "regular-14",
                align: "right",
                headerAlign: "right",
                footerAlign: "right",
                footer: () => {
                    return (
                        <>
                            <div className="regular-14 mb-3">
                                {this.totalWithoutVAT()}
                            </div>
                            <div className="bold-14 pb-3 bottom-divider">
                                {this.totalVAT()}
                            </div>
                            <div className="bold-16 mt-3">
                                {this.totalWithVAT()}
                            </div>
                        </>
                    );
                },
            },
        ];

        return (
            <Row className="invoice-preview-receiver-section">
                <Col>
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="invoice-preview-table"
                        headerClasses="invoice-preview-table-header"
                        rowClasses="invoice-preview-table-row"
                        keyField="value"
                        data={invoiceRows}
                        columns={columns}
                    />
                </Col>
            </Row>
        );
    }

    renderFooterSection() {
        const { t } = this.props;
        return (
            <Row className="align-items-center">
                <Col lg="6" className="mb-2 mb-lg-0">
                    <span className="semibold-16">
                        {t(
                            "invoices.paymentInfo",
                            "Lõplik arve saadetakse Teie e-mailile 24 tunni jooksul!"
                        )}
                    </span>
                </Col>
                <Col md="6" lg="3" className="mb-2 mb-lg-0">
                    <Button
                        outline
                        block
                        color="success"
                        onClick={this.onClickPayManually}
                    >
                        {t("invoices.payWithBankTransfer", "Tasun ülekandega")}
                    </Button>
                </Col>
                <Col md="6" lg="3" className="mb-2 mb-lg-0">
                    <Button
                        className="semibold-14"
                        color="success"
                        block
                        onClick={this.onClickPayByLink}
                        disabled={!this.getReferenceNumber()}
                    >
                        {this.state.initiatePaymentLoading ?
                            <SmallLoader />
                            :
                            t("invoices.payWithBankLink", "Tasun pangalingiga")
                        }
                    </Button>
                </Col>
            </Row>
        );
    }

    renderLoading() {
        return (
            <CenteredRowCol>
                <WrapperLoader />
            </CenteredRowCol>
        );
    }

    onClickPayManually = () => {
        addNewLocalInfoAlert(
            this.props.t(
                "invoices.paymentInfo",
                "Lõplik arve saadetakse Teie e-mailile 24 tunni jooksul!"
            ),
            urls.INVOICES,
            false
        );
        this.toggle();
    };

    getReferenceNumber = () => {
        if (this.props.invoice && this.props.invoice.invoice_receiver) {
            return this.props.invoice.invoice_receiver.reference_number;
        }

        return null;
    };

    onClickPayByLink = () => {
        if (this.state.initiatePaymentLoading) return;
        const amount = this.totalWithVAT();
        clearLocalAlerts()
        this.setState({
            initiatePaymentLoading: true
        })
        Everypay.initiatePayment(amount, this.props.activeClientId, null, null, this.props.invoice?.id)
            .then(payment_link => {
                window.location.href = payment_link;
            })
            .catch((error) => {
                addNewLocalErrorAlert(
                    getServerErrorMessage(error),
                    this.props.alertGroup,
                    false
                );
            })
            .finally(() => {
                this.setState({
                    initiatePaymentLoading: false
                })
            });
    };

    getCombinedAddress = (invoiceReceiver = {}) => {
        const { street, zipCode, county } = invoiceReceiver;
        if (!street && !zipCode && !county) {
            return "";
        }
        const dividerA = street && (zipCode || county) ? ", " : "";
        const dividerB = zipCode && county ? " " : "";

        return `${street || ""}${dividerA}${zipCode || ""}${dividerB}${county || ""
            }`;
    };

    totalWithoutVAT = () => {
        const invoice = this.props.invoice;
        if (invoice && invoice.totals) {
            const withoutVAT = invoice.totals.without_VAT;
            if (!withoutVAT && withoutVAT !== 0) {
                return "";
            }
            return withoutVAT;
        }
        return "";
    };

    totalVAT = () => {
        const invoice = this.props.invoice;
        if (invoice && invoice.totals) {
            const VAT = invoice.totals.VAT;
            if (!VAT && VAT !== 0) {
                return "";
            }
            return VAT;
        }
        return "";
    };

    totalWithVAT = () => {
        const invoice = this.props.invoice;
        if (invoice && invoice.totals) {
            const with_VAT = invoice.totals.with_VAT;
            if (!with_VAT && with_VAT !== 0) {
                return "";
            }
            return with_VAT;
        }
        return "";
    };
}

const mapStateToProps = (state) => {
    return {
        isInvoiceLoading: state.invoices.invoiceLoading,
        invoice: state.invoices.invoice,
        alerts: state.alerts.alertsList,
        alertGroup: INVOICE_PREVIEW_ALERT_GROUP,
        activeClientId: state.auth?.user?.active_client_id || null,
    };
};

export default withRouter(
    connect(mapStateToProps)(withTranslation("common")(InvoicePreviewModal))
);
