import axios from "../axios";
import store from "redux/store";

import { INVOICES } from "../config/Api";
import {
    invoicesFetchStart,
    invoicesFetchSuccess,
    invoicesFetchFail,
    invoiceDataFetchStart,
    invoiceDataFetchSuccess,
    invoiceDataFetchFail,
} from "../redux/actions/invoices";
import { getServerErrorMessage } from "util/Errors";

export const INVOICE_PREVIEW_ALERT_GROUP = "invoicePreviewGroup";

export function fetchInvoices() {
    store.dispatch(invoicesFetchStart());
    return axios
        .get(INVOICES)
        .then((resp) => {
            store.dispatch(invoicesFetchSuccess(resp.data));
        })
        .catch((err) => {
            store.dispatch(invoicesFetchFail(err));
        });
}

export function fetchInvoiceData(id) {
    store.dispatch(invoiceDataFetchStart());
    return axios
        .get(INVOICES + id + "/")
        .then((resp) => {
            store.dispatch(invoiceDataFetchSuccess(resp.data));
            return resp.data;
        })
        .catch((err) => {
            const errorMessage = getServerErrorMessage(err);
            store.dispatch(invoiceDataFetchFail(errorMessage));
            throw Error(errorMessage);
        });
}
