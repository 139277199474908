import React from "react";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col } from "reactstrap";

// datatable
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import filterFactory from "react-bootstrap-table2-filter";


import { ReactComponent as ExpandIcon } from 'assets/icons/document-correction.svg';

// cells
import DateCell from "components/Table/Cell/DateCell";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import DocumentCell from "components/Table/Cell/DocumentCell";
import MonthPeriodCell from "components/Reports/Sales/cells/MonthPeriodCell";
import AdminMenuCell from "components/Admin/Reports/cells/MenuCell";
import WrappedLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";

import { getSaleStatusType } from "components/Reports/Sales/helpers/Options";

import { parseSalesReportPeriodDate } from "util/DateTime";
import { getSaleReportUrl, getSaleReportFixUrl } from "config/urls";
import { isSalesReportHasRepairReport } from "util/SalesReports";


// css
import "./Table.scss";


function NoDataIndication(props) {
    if (props.loading) {
        return <WrappedLoader />;
    }

    let content = "Ei leitud ühtegi müügiaruannet, mis vastaks otsingutingimustele.";
    if (props.error) {
        content = <span className="color-error">{props.error}</span>;
    }

    return (
        <div className="text-center">
            {content}
        </div>
    );
}


class AdminSalesReportsTable extends React.Component {
    static propTypes = {
        salesReportsList: PropTypes.array,
        salesReportsListLoading: PropTypes.bool,
    }

    getDefaultSorted = () => {
        return [
            {
                dataField: "period",
                order: "desc"
            }
        ];
    }

    render() {
        return (
            <>
                {this.renderTable()}
            </>
        );
    }

    renderTable() {
        if (this.props.salesReportsListLoading) {
            return (
                <CenteredRowCol>
                    <WrappedLoader />
                </CenteredRowCol>
            );
        }
        if (this.props.salesReportsList === null || this.props.salesReportsList === undefined) {
            return <></>;
        }

        const columns = [
            {
                dataField: "client.label",
                text: "Ettevõte",
                sort: true,
                classes: "client-col semibold-14",
                headerClasses: "client-col-header",
            },
            {
                dataField: "period",
                text: "Periood",
                sort: true,
                classes: "period-col",
                headerClasses: "period-col-header",
                formatter: (period, row) => this.periodColumnFormatter(row),
                sortValue: period => parseSalesReportPeriodDate(period)
            },
            {
                dataField: "submitted_at",
                text: "Sisestatud",
                sort: true,
                align: "left",
                headerAlign: "left",
                classes: "submitted-col regular-14",
                headerClasses: "submitted-col-header",
                formatter: (value, row) => this.submittedAtColumnFormatter(value, row),
            },
            {
                dataField: "status.label",
                text: "Staatus",
                sort: true,
                classes: "status-col",
                headerClasses: "status-col-header",
                formatter: (statusLabel, row) => this.statusColumnFormatter(row),
            }, {
                dataField: "invoice",
                text: "Arve",
                sort: true,
                align: "center",
                headerAlign: "center",
                classes: "invoice-col",
                headerClasses: "invoice-col-header",
                formatter: (invoices, row) => this.invoicesColumnFormatter(row),
            },
            {
                dataField: "id",
                text: "",
                align: "right",
                alignHeader: "right",
                classes: "action-col",
                headerClasses: "action-col-header",
                formatter: (id, salesReport) => this.actionColumnFormatter(salesReport),
            }
        ];

        const rowClasses = (row, rowIndex) => {
            return "pointer";
        };

        const rowEvents = {
            onClick: (e, row, rowIndex) => {
                this.onRowClick(e, row);
            },
        };

        return (
            <Card className="admin-sales-reports-card">
                <CardBody className="admin-sales-reports-card-body">
                    <BootstrapTable
                        bootstrap4
                        bordered={false}
                        classes="admin-sales-reports-table no-top-border table-layout-auto"
                        wrapperClasses="admin-sales-reports-table-wrapper table-responsive"
                        keyField="id"
                        data={this.props.salesReportsList}
                        columns={columns}
                        filter={filterFactory()}
                        filterPosition="inline"
                        defaultSorted={this.getDefaultSorted()}
                        noDataIndication={
                            <NoDataIndication
                                error={false}
                                loading={this.props.salesReportsListLoading}
                            />
                        }
                        rowClasses={rowClasses}
                        rowEvents={rowEvents}
                    />
                </CardBody>
            </Card>
        );
    }

    periodColumnFormatter = row => {
        if (!row.repair_report) {
            return <MonthPeriodCell salesReport={row} />;
        }

        return (
            <>
                <Row>
                    <Col>
                        <MonthPeriodCell salesReport={row} />
                    </Col>
                </Row>
                {row.repair_report &&
                    <Row className="mt-3">
                        <Col>
                            <ExpandIcon className="expand-icon mr-2" /><MonthPeriodCell salesReport={row.repair_report} />
                        </Col>
                    </Row>
                }
            </>
        );
    }

    onRowClick = (event, row) => {
        let reportUrl = getSaleReportUrl(row.id);
        if (isSalesReportHasRepairReport(row)) {
            reportUrl = getSaleReportFixUrl(row.repair_report.id);
        }
        this.props.history.push(reportUrl, { previousViewRoute: this.props.location.pathname });
    }

    submittedAtColumnFormatter = (submitted_at, row) => {
        return (
            <>
                <Row>
                    <Col>
                        <DateCell value={submitted_at} />
                    </Col>
                </Row>
                {row.repair_report &&
                    <Row className="mt-3">
                        <Col>
                            <DateCell value={row.repair_report.submitted_at} />
                        </Col>
                    </Row>
                }
            </>
        );
    }

    statusColumnFormatter = (row) => {
        return (
            <>
                <Row>
                    <Col>
                        <StatusCell
                            status={row.status.label}
                            type={getSaleStatusType(row.status.value, true)}
                            className="status-cell"
                        />
                    </Col>
                </Row>
                {row.repair_report &&
                    <Row className="mt-3">
                        <Col>
                            <StatusCell
                                status={row.repair_report.status.label}
                                type={getSaleStatusType(row.repair_report.status.value, true)}
                                className="status-cell"
                            />
                        </Col>
                    </Row>
                }
            </>
        );
    }

    invoicesColumnFormatter = (row) => {
        const invoicesToComponents = (invoices, isRepair) => {
            return (invoices || []).filter(invoice => invoice.name).map((invoice, index) => (
                <Col xs="12" key={`}${isRepair ? "repair-invoice" : "invoice"}-${invoice.name}-${index}`} >
                    <DocumentCell
                        name={invoice.name}
                        url={invoice.url}
                        onClick={() => { }}
                    />
                </Col >
            ))
        }

        const salesReportInvoices = invoicesToComponents(row.invoice, false);
        let salesReportRepairInvoices = [];
        if (row.repair_report && row.repair_report.invoice) {
            salesReportRepairInvoices = invoicesToComponents(row.repair_report.invoice, true);
        }

        return (
            <>
                <Row>
                    {salesReportInvoices}
                    {salesReportRepairInvoices}
                </Row>
            </>
        );
    };


    actionColumnFormatter = salesReport => {
        return <AdminMenuCell salesReport={salesReport} />
    }

}

export default withRouter(AdminSalesReportsTable);
