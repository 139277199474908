import React, { Component } from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { Modal, ModalBody, Row, Col, Form } from "reactstrap";

import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";
import {
    isClientMode,
    isUserClientAdmin,
    isPartnerMode,
    isAdminMode,
} from "util/Access";
import { updateUserInfo } from "util/Profile";
import userRoles from "config/userRoles";

import CloseTextIcon from "components/Misc/CloseTextIcon";
import Alerts from "components/Alerts/Alerts";
import WrapperLoader from "components/Loader/WrappedLoader";
import Input from "components/Form/Input";
import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";

import "./UserDetailsModal.scss";

class UserDetailsModal extends Component {
    static propTypes = {
        user: PropTypes.object.isRequired,
        userRole: PropTypes.string.isRequired,
        isOpen: PropTypes.bool,
        alertGroup: PropTypes.string,
        toggle: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
        onSave: PropTypes.func,
    };

    static defaultProps = {
        onSave: () => { },
    }

    static ALERT_GROUP = "userDetailsModalAlertGroup";

    state = {
        userDetails: {},
    };

    componentDidMount() {
        clearLocalAlerts();
        this.setStateUserDetails(this.props.user);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.user !== this.props.user) {
            this.setState({ userDetails: this.props.user });
            this.setStateUserDetails(this.props.user);
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div>
                <Modal
                    centered
                    isOpen={this.props.isOpen}
                    toggle={this.props.toggle}
                    size="md"
                    className="client-user-details-modal"
                >
                    <ModalBody className="">
                        <Row className="pb-2 header-section">
                            <Col>
                                <span className="bold-19 modal-title">
                                    {t("users.userDetailsModal.header", "Muuda andmeid")}
                                </span>
                            </Col>
                            <Col className="float-right text-right col-auto">
                                <CloseTextIcon
                                    text={t("buttons.close", "Sulge")}
                                    onClick={this.props.toggle}
                                />
                            </Col>
                        </Row>
                        <Row className="content-section">
                            <Col>{this.renderContent()}</Col>
                        </Row>
                    </ModalBody>
                </Modal>
            </div >
        );
    }

    renderContent() {
        if (this.props.loading) {
            return <WrapperLoader />;
        }
        const { t } = this.props;

        const disableFields = this.isDisabled();
        const isShowUserRoleSelection = this.props.userRole === "CLIENT" && (isClientMode() || isAdminMode());
        const isUserRoleSelectionDisabled = disableFields || (!isAdminMode() && !isUserClientAdmin());

        return (
            <Form id="add-user-form" onSubmit={this.onClickAddUser}>
                {isShowUserRoleSelection && (
                    <Row>
                        <Col className="mb-2">
                            <CheckboxButton
                                isSelected={
                                    this.state.userDetails.type ===
                                    userRoles.CLIENT_USER
                                }
                                disabled={isUserRoleSelectionDisabled}
                                labelText={t("users.fields.userRoleLabel.user", "Kasutaja")}
                                onClick={() =>
                                    this.onChange("type")(userRoles.CLIENT_USER)
                                }
                                extraProps={{ block: true }}
                            />
                        </Col>
                        <Col className="mb-2">
                            <CheckboxButton
                                isSelected={
                                    this.state.userDetails.type ===
                                    userRoles.CLIENT_ADMIN
                                }
                                disabled={isUserRoleSelectionDisabled}
                                labelText={t("users.fields.userRoleLabel.admin", "Admin")}
                                onClick={() =>
                                    this.onChange("type")(
                                        userRoles.CLIENT_ADMIN
                                    )
                                }
                                extraProps={{ block: true }}
                            />
                        </Col>
                    </Row>
                )}
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.userDetails.name}
                            labelText={t("users.fields.firstNameLabel", "Eesnimi")}
                            placeholder={t("users.fields.firstNamePlaceholder", "Nimi")}
                            className="user-given-name"
                            onBlur={this.onChange("name")}
                            disabled={disableFields}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.userDetails.last_name}
                            labelText={t("users.fields.lastNameLabel", "Perekonnanimi")}
                            placeholder={t("users.fields.lastNamePlaceholder", "Perekonnanimi")}
                            className="user-surname"
                            onBlur={this.onChange("last_name")}
                            disabled={disableFields}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.userDetails.personal_code}
                            type="number"
                            labelText={t("users.fields.personalCodeLabel", "Isikukood")}
                            placeholder={t("users.fields.personalCodePlaceholder", "Isikukood")}
                            className="user-personal-code"
                            onBlur={this.onChange("personal_code")}
                            disabled={disableFields}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.userDetails.phone}
                            type="tel"
                            labelText={t("users.fields.phoneLabel", "Telefoninumber")}
                            placeholder={t("users.fields.phonePlaceholder", "Telefoninumber")}
                            className="user-phone"
                            onBlur={this.onChange("phone")}
                            disabled={disableFields}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <Input
                            value={this.state.userDetails.email}
                            type="email"
                            labelText={t("users.fields.emailLabel", "E-mail")}
                            placeholder={t("users.fields.emailPlaceholder", "E-mail")}
                            className="user-email"
                            onBlur={this.onChange("email")}
                            disabled={disableFields}
                            extraProps={{
                                required: true,
                            }}
                        />
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <CheckboxButton
                            isSelected={this.state.userDetails.is_verified}
                            disabled={disableFields}
                            labelText={
                                this.state.userDetails.is_verified
                                    ? t("users.fields.isVerified.true", "Kinnitatud")
                                    : t("users.fields.isVerified.false", "Kinnitamata")
                            }
                            onClick={() =>
                                this.onChange("is_verified")(
                                    !this.state.userDetails.is_verified
                                )
                            }
                            extraProps={{ block: true }}
                        />
                    </Col>
                </Row>
                {!isAdminMode() && (
                    <>
                        <Row className="mb-2">
                            <Col>
                                <Input
                                    value={this.state.userDetails.position}
                                    labelText={t("users.fields.professionLabel", "Ametinimetus")}
                                    placeholder={t("users.fields.professionPlaceholder", "Ametinimetus")}
                                    className="user-profession"
                                    onBlur={this.onChange("position")}
                                    disabled={disableFields}
                                    extraProps={{
                                        required: true,
                                    }}
                                />
                            </Col>
                        </Row>
                    </>
                )}
                <Alerts
                    hideGlobal={true}
                    filterGroups={
                        this.props.alertGroup
                            ? [
                                UserDetailsModal.ALERT_GROUP,
                                this.props.alertGroup,
                            ]
                            : [UserDetailsModal.ALERT_GROUP]
                    }
                />
            </Form>
        );
    }

    setStateUserDetails = (userDetails) => {
        this.setState({
            userDetails,
        });
    };

    isDisabled = () => {
        return (
            this.props.disabled ||
            !(isUserClientAdmin() || isPartnerMode() || isAdminMode())
        );
    };

    onChange = (field) => (value) => {
        //this.props.onChange(field, value);
        clearLocalAlerts();
        const oldUserDetails = { ...this.state.userDetails };
        this.setState(
            (prevState) => {
                return {
                    userDetails: {
                        ...prevState.userDetails,
                        [field]: value,
                    },
                };
            },
            () => this.saveUpdate(oldUserDetails, this.state.userDetails)
        );
    };

    saveUpdate = (oldUserDetails, newUserDetails) => {
        updateUserInfo(newUserDetails)
            .then((data) => {
                this.setStateUserDetails(data);
                this.props.onSave(data);
            })
            .catch((err) => {
                clearLocalAlerts();
                this.setStateUserDetails(oldUserDetails);
                addNewLocalErrorAlert(
                    err.message,
                    UserDetailsModal.ALERT_GROUP
                );
            });
    };
}

export default withTranslation()(UserDetailsModal);
