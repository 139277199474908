import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

import { Card, CardBody, Row, Col } from "reactstrap";
// datatable
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
//import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

import WrapperLoader from "components/Loader/WrappedLoader";
import CenteredRowCol from "components/Misc/CenteredRowCol";
import StatusCell from "components/Table/Cell/Status/StatusCell";
import PackageTestList from "components/PackageTest/PackageTestList";
import CustomPagination from "components/Table/Pagination/CustomPagination";
import "./Table.scss";

import { getTestStatusType } from "util/Partner";
import { DATE_FORMAT } from "util/DateTime";

class PartnerPackagesTable extends React.Component {
    static propTypes = {
        packagesList: PropTypes.array,
        packagesListLoading: PropTypes.bool,
        onPackageTestSubmitSuccess: PropTypes.func.isRequired,
        sorting: PropTypes.object.isRequired,
        onSort: PropTypes.func.isRequired,
        pagination: PropTypes.object.isRequired,
        onPaginationChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        getDatatableNode: PropTypes.func,
        dateField: PropTypes.string.isRequired,
        expanded: PropTypes.array,
        onExpand: PropTypes.func,
    }

    state = {
    }

    render() {
        if (this.props.packagesListLoading) {
            return (
                <WrapperLoader />
            );
        }
        if (!this.props.packagesList) {
            return <></>;
        }

        return (
            <Card className="partner-packages-card">
                <CardBody className="p-0">
                    {this.renderTable()}
                </CardBody>
            </Card>
        );
    }

    renderTable() {
        const data = this.props.packagesList;

        const columns = [
            {
                dataField: "package_id",
                text: "ID",
                classes: "semibold-14",
                sort: true,
                onSort: this.props.onSort,
                hidden: true,
            },
            {
                dataField: "ean",
                text: "EAN kood",
                classes: "semibold-14",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                dataField: "name",
                text: "Toote nimetus",
                classes: "semibold-14",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                dataField: "client.label",
                text: "Ettevõte",
                classes: "semibold-14",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                dataField: "category.label",
                text: "Toote kategooria",
                classes: "regular-14",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                dataField: "type",
                text: "Pakendi tüüp",
                classes: "regular-14",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                dataField: "volume",
                text: "Maht",
                classes: "regular-14",
                sort: true,
                onSort: this.props.onSort,
            },
            {
                dataField: "created_at",
                text: "Testimisse saadetud",
                classes: "regular-14",
                sort: true,
                onSort: this.props.onSort,
                formatter: (date) => date ? moment(date).format(DATE_FORMAT) : "",
                align: "right",
                headerAlign: "right",
                hidden: this.props.dateField !== "created_at",
            },
            {
                dataField: "tested_at",
                text: "Viimati testitud",
                classes: "regular-14",
                sort: true,
                onSort: this.props.onSort,
                formatter: (date) => date ? moment(date).format(DATE_FORMAT) : "",
                align: "right",
                headerAlign: "right",
                hidden: this.props.dateField !== "tested_at",
            },
            {
                dataField: "status",
                text: "Staatus",
                classes: "regular-14",
                align: "right",
                headerAlign: "right",
                sort: true,
                onSort: this.props.onSort,
                sortValue: (cell, row) => cell ? cell.label : "",
                formatter: status => (
                    <StatusCell
                        status={status.label}
                        type={getTestStatusType(status.value)}
                        className="d-inline-flex"
                    />
                ),
            },
        ];

        const rowClasses = (row, rowIndex) => {
            return "partner-packages-table-row";
        };

        const emptyTableIndication = this.renderCenteredContent(<p className="my-3 regular-14">Ükski pakend ei vastanud otsingutingimustele.</p>);

        let expandRow = {
            renderer: row => (
                <PackageTestList
                    readOnly={this.props.readOnly}
                    packageId={row.package_id}
                    onPackageTestSubmitSuccess={this.props.onPackageTestSubmitSuccess}
                />
            )
        };
        if (this.props.expanded && this.props.onExpand) {
            expandRow = {
                ...expandRow,
                expanded: this.props.expanded,
                onExpand: this.props.onExpand,
            }
        }

        const pagination = this.props.pagination;
        const paginationOptions = {
            custom: true,
            page: pagination.page,
            sizePerPage: pagination.sizePerPage || pagination.dataSize,
            showTotal: true,
            totalSize: pagination.dataSize,
            sizePerPageList: pagination.sizePerPageList,
            onSizePerPageChange: (sizePerPage, page) => {
                this.props.onPaginationChange(page, sizePerPage);
            },
            onPageChange: (page, sizePerPage) => {
                this.props.onPaginationChange(page, sizePerPage);
            }

        };
        const defaultSorted = [this.props.sorting,];

        return (
            <PaginationProvider
                pagination={paginationFactory(paginationOptions)}
            >
                {
                    ({
                        paginationProps,
                        paginationTableProps
                    }) => (
                        <>
                            <Row>
                                <Col>
                                    <BootstrapTable
                                        ref={node => {
                                            if (this.props.getDatatableNode) {
                                                this.props.getDatatableNode(node);
                                            }
                                        }}
                                        bootstrap4
                                        bordered={false}
                                        classes="partner-packages-table bottom-border"
                                        keyField="package_id"
                                        data={data}
                                        columns={columns}
                                        expandRow={expandRow}
                                        noDataIndication={emptyTableIndication}
                                        rowClasses={rowClasses}
                                        defaultSorted={defaultSorted}
                                        {...paginationTableProps}
                                    />
                                </Col>
                            </Row>
                            <Row className="mx-3">
                                <Col className="px-3 mr-1">
                                    <CustomPagination
                                        unknownSizePerPageText="Kõik"
                                        {...paginationProps}
                                    />
                                </Col>
                            </Row>
                        </>
                    )
                }
            </PaginationProvider>
        );
    }

    renderCenteredContent = content => {
        return (
            <CenteredRowCol>
                {content}
            </CenteredRowCol>
        );
    }
}

export default PartnerPackagesTable;
