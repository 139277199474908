import React from "react";
import PropTypes from "prop-types";

import "./Checkbox.scss"
import { FormGroup } from "reactstrap";

import { ReactComponent as UncheckedIcon } from "assets/icons/unchecked.svg";
import { ReactComponent as CheckedIcon } from "assets/icons/checked.svg";


class CustomCheckbox extends React.Component {
    static propTypes = {
        value: PropTypes.bool,
        labelText: PropTypes.any,
        onClick: PropTypes.func.isRequired,
        className: PropTypes.string,
        inline: PropTypes.bool,
        valid: PropTypes.bool,
        invalid: PropTypes.bool,
        required: PropTypes.bool,
        disabled: PropTypes.bool,
        readonly: PropTypes.bool
    }

    static DISABLED_CSS = "custom-is-disabled";
    static READONLY_CSS = "custom-is-readonly";
    static ACTIVE_CSS = "is-active";
    static VALID_CSS = "custom-is-valid";
    static INVALID_CSS = "custom-is-invalid";
    static REQUIRED_CSS = "custom-required";

    onClick = (event) => {
        if (this.props.disabled || this.props.readonly) {
            return;
        }
        this.props.onClick(!this.props.value);
    }

    getAdditionalIconClasses = () => {
        if (this.props.disabled) {
            return CustomCheckbox.DISABLED_CSS;
        }
        if (this.props.invalid) {
            return CustomCheckbox.INVALID_CSS;
        }
        else if (this.props.valid) {
            return CustomCheckbox.VALID_CSS;
        }
        else if (this.props.readonly) {
            return CustomCheckbox.READONLY_CSS;
        }

        return "";
    }

    render() {
        return (
            <FormGroup
                check
                inline={this.props.inline}
                className={`custom-checkbox ${this.getAdditionalIconClasses()} ${this.props.className || ""}`}
            >
                {this.props.value ?
                    <CheckedIcon
                        className={`custom-checkbox-svg custom-checkbox-checked`}
                        onClick={this.onClick}
                    />
                    :
                    <UncheckedIcon
                        className={`custom-checkbox-svg custom-checkbox-unchecked`}
                        onClick={this.onClick}
                    />
                }
                {this.props.labelText &&
                    <span className={`custom-checkbox-label${this.props.required ? " " + CustomCheckbox.REQUIRED_CSS : ""}`}>
                        {this.props.labelText}
                    </span>
                }
            </FormGroup>
        )
    }
}

export default CustomCheckbox;
