import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Row, Col } from "reactstrap";

import { fetchCompanies } from "util/General";
import { fetchCompanyDetailedInformation } from "util/BusinessRegister";
import { companyProfileUpdate } from "redux/actions/profile";
import {
    fetchCompanyProfile,
    updateCompanyProfile,
    fetchRepresentationOptions,
} from "util/Profile";
import {
    isConnectedToClientWithPendingOrActiveContract,
    isPartnerMode,
    isAdminMode,
} from "util/Access";
import { getServerErrorMessage } from "util/Errors";
import { clearLocalAlerts, addNewLocalErrorAlert } from "util/Alerts";
// Custom inputs
import Input from "../../../Form/Input";
import Select from "../../../Form/Select";

import CenteredRowCol from "components/Misc/CenteredRowCol";
import WrapperLoader from "components/Loader/WrappedLoader";
import { withTranslation } from "react-i18next";

class CompanyProfileForm extends Component {
    static propTypes = {
        alertGroup: PropTypes.string,
        contractMode: PropTypes.bool,
        invalidFields: PropTypes.array.isRequired,
        user: PropTypes.object,
        readOnly: PropTypes.bool,
    };

    static defaultProps = {
        contractMode: false,
        invalidFields: [],
    };

    state = {
        companies: [],
        disableBasisOption: true,
    };

    componentDidMount() {
        fetchCompanyProfile();
        fetchRepresentationOptions();
    }

    handleSelectInputChange = (name) => {
        if (name.length > 2) {
            fetchCompanies(name).then((companies) => {
                this.setState({ companies: companies });
            });
        }
    };

    getAlertGroupOrDefault = () => {
        return this.props.alertGroup || this.props.location.pathname;
    };

    handleCompanyChanged = (company) => {
        if (company.reg_number) {
            fetchCompanyDetailedInformation(company.reg_number).then(
                async (resp) => {
                    await this.props.companyProfileUpdate("name", resp.name);

                    const newBasis = this.updatesBasisDisabledOptionAndReturnNewBasis(
                        resp.board_members
                    );

                    // Update kmkr and reg nr only when client does not have active contract
                    if (!isConnectedToClientWithPendingOrActiveContract()) {
                        await this.props.companyProfileUpdate(
                            "reg_number",
                            resp.reg_code //company.reg_number
                        );
                        await this.props.companyProfileUpdate(
                            "kmkr",
                            resp.kmkr
                        );
                        await this.props.companyProfileUpdate(
                            "full_address",
                            resp.address
                        );
                        await this.props.companyProfileUpdate(
                            "zip_code",
                            resp.zip_code
                        );
                        await this.props.companyProfileUpdate(
                            "city",
                            resp.county
                        );
                        await this.props.companyProfileUpdate(
                            "basis",
                            newBasis
                        );
                    }

                    if (
                        !this.props.contractMode &&
                        this.hasAllProperties(this.props.companyProfile)
                    ) {
                        clearLocalAlerts();
                        updateCompanyProfile(this.props.companyProfile).catch(
                            (err) => {
                                addNewLocalErrorAlert(
                                    getServerErrorMessage(err),
                                    this.getAlertGroupOrDefault()
                                );
                            }
                        );
                    }
                }
            );
        }
    };

    updatesBasisDisabledOptionAndReturnNewBasis = (boardMembers) => {
        if (boardMembers && boardMembers.length) {
            const personalCode = this.props.user
                ? this.props.user.personal_code
                : null;
            const boardMembersPersonalCodes = boardMembers.map(
                (member) => member.ssid
            );
            if (
                personalCode &&
                boardMembersPersonalCodes.includes(personalCode)
            ) {
                this.setState({ disableBasisOption: false });
                return 1;
            }
        }

        this.setState({ disableBasisOption: true });
        return 2;
    };

    hasAllProperties = (object) => {
        return Object.values(object).every(
            (x) => x !== "" && x !== undefined && x !== null
        );
    };

    handleBasisChange = async (selectedObject) => {
        const name = "basis";
        await this.props.companyProfileUpdate(
            name,
            selectedObject ? selectedObject.value : null
        );

        if (
            !this.props.contractMode &&
            this.hasAllProperties(this.props.companyProfile) &&
            !isConnectedToClientWithPendingOrActiveContract()
        ) {
            clearLocalAlerts();
            updateCompanyProfile(this.props.companyProfile).catch((err) => {
                addNewLocalErrorAlert(
                    getServerErrorMessage(err),
                    this.getAlertGroupOrDefault()
                );
            });
        }
    };

    handleInputChange = (name) => async (value) => {
        await this.props.companyProfileUpdate(name, value);

        if (
            !this.props.contractMode &&
            this.hasAllProperties(this.props.companyProfile)
        ) {
            clearLocalAlerts();
            updateCompanyProfile(this.props.companyProfile).catch((err) => {
                addNewLocalErrorAlert(
                    getServerErrorMessage(err),
                    this.getAlertGroupOrDefault()
                );
            });
        }
    };

    getBasisLabel = (id) => {
        const basisOptions = this.props.profile.representationOptions || [];
        for (const option of basisOptions) {
            if (option.value === id) {
                return option.label;
            }
        }
        return null;
    };

    getBasisValueObject = () => {
        const value = this.props.companyProfile.basis;
        if (!value && value !== 0) {
            return null;
        }
        const label = this.getBasisLabel(value);

        return {
            value,
            label,
        };
    };

    isFieldsDisabled = () => {
        if (this.props.contractMode || isAdminMode()) {
            return false;
        }
        return (
            !isConnectedToClientWithPendingOrActiveContract() || isPartnerMode()
        );
    };

    isInvalid = (field) => {
        return this.props.invalidFields.includes(field);
    };

    getIsReadOnly = () => {
        return this.props.readOnly;
    }

    renderSelectBasis() {
        const { t } = this.props;
        const basisValue = this.getBasisValueObject();
        //const isDisabled = this.isFieldsDisabled();
        const isNotContractMode = !this.props.contractMode;
        const isReadOnly = this.getIsReadOnly();

        return (
            <Select
                disabled={isNotContractMode || isReadOnly}
                labelText={t(
                    "profile.representationBasis",
                    "Allkirjaõiguslikkuse alus"
                )}
                options={this.props.profile.representationOptions}
                getOptionLabel={(option) => option.label}
                getOptionValue={(option) => option.value}
                placeholder={t(
                    "profile.representationBasis",
                    "Allkirjaõiguslikkuse alus"
                )}
                name="basis"
                value={basisValue}
                onChange={this.handleBasisChange}
                className="select"
                required={this.props.contractMode}
                invalid={this.isInvalid("basis")}
                isOptionDisabled={(option) =>
                    option.value === 1 && this.state.disableBasisOption === true
                }
            />
        );
    }

    render() {
        if (this.props.companyProfileLoading) {
            return (
                <CenteredRowCol>
                    <WrapperLoader />
                </CenteredRowCol>
            );
        }

        const { t } = this.props;
        const isDisabled = this.isFieldsDisabled();
        const isReadOnly = this.getIsReadOnly();
        const isNotPartnerMode = !isPartnerMode();
        const isNotContractMode = !this.props.contractMode;
        return (
            <Row>
                <Col md={6}>
                    <Select
                        disabled={isNotContractMode || isReadOnly}
                        labelText={t("profile.companyName", "Ettevõtte nimi")}
                        options={this.state.companies}
                        value={
                            this.props.companyProfile.name
                                ? { name: this.props.companyProfile.name }
                                : null
                        }
                        placeholder={t("profile.companyName", "Ettevõtte nimi")}
                        name="name"
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.name}
                        onChange={this.handleCompanyChanged}
                        onInputChange={this.handleSelectInputChange}
                        className="select"
                        noOptionsMessage={() =>
                            t("profile.companyNameNoOptionsMessage", "Sisesta vähemalt kolm tähemärki")
                        }
                        required={this.props.contractMode}
                        invalid={this.isInvalid("name")}
                    />

                    <Input
                        disabled
                        type="text"
                        name="regCode"
                        value={this.props.companyProfile.reg_number}
                        placeholder={t("profile.regCode", "Registrikood")}
                        className="input-md"
                        required={this.props.contractMode}
                        labelText={t("profile.regCode", "Registrikood")}
                        invalid={this.isInvalid("reg_number")}
                    />
                    {isNotPartnerMode && (
                        <Input
                            disabled
                            type="text"
                            name="kmkr"
                            value={this.props.companyProfile.kmkr}
                            placeholder={t("profile.kmkr", "Kmkr")}
                            className="input-md"
                            required={this.props.contractMode}
                            labelText={t("profile.kmkr", "Kmkr")}
                            invalid={this.isInvalid("kmkr")}
                        />
                    )}
                    {this.renderSelectBasis()}
                </Col>
                <Col md={6}>
                    <Input
                        type="email"
                        name="email"
                        value={this.props.companyProfile.email}
                        placeholder={t(
                            "profile.invoiceEmail",
                            "Arve saamise email"
                        )}
                        className="input-md"
                        onBlur={this.handleInputChange("email")}
                        required={this.props.contractMode}
                        labelText={t(
                            "profile.invoiceEmail",
                            "Arve saamise email"
                        )}
                        invalid={this.isInvalid("email")}
                        disabled={isDisabled || isReadOnly}
                    />
                    {isNotPartnerMode && (
                        <>
                            <Input
                                type="text"
                                name="iban"
                                value={this.props.companyProfile.iban}
                                placeholder={t(
                                    "profile.bankAccountNumber",
                                    "Kontonumber"
                                )}
                                className="input-md"
                                onBlur={this.handleInputChange("iban")}
                                required={this.props.contractMode}
                                labelText={t(
                                    "profile.bankAccountNumber",
                                    "Kontonumber"
                                )}
                                invalid={this.isInvalid("iban")}
                                disabled={isDisabled || isReadOnly}
                            />
                            <Input
                                type="text"
                                name="full_address"
                                value={this.props.companyProfile.full_address}
                                placeholder={t("profile.address", "Aadress")}
                                className="input-md"
                                onBlur={this.handleInputChange("full_address")}
                                required={this.props.contractMode}
                                labelText={t("profile.address", "Aadress")}
                                invalid={this.isInvalid("full_address")}
                                disabled={isDisabled || isReadOnly}
                            />
                            <Input
                                type="text"
                                name="city"
                                value={this.props.companyProfile.city}
                                placeholder={t("profile.city", "Haldusüksus")}
                                className="input-md"
                                onBlur={this.handleInputChange("city")}
                                required={this.props.contractMode}
                                labelText={t("profile.city", "Haldusüksus")}
                                invalid={this.isInvalid("city")}
                                disabled={isDisabled || isReadOnly}
                            />
                            <Input
                                type="text"
                                name="zip_code"
                                value={this.props.companyProfile.zip_code}
                                placeholder={t(
                                    "profile.postalCode",
                                    "Postiindeks"
                                )}
                                className="input-md"
                                onBlur={this.handleInputChange("zip_code")}
                                required={this.props.contractMode}
                                labelText={t(
                                    "profile.postalCode",
                                    "Postiindeks"
                                )}
                                invalid={this.isInvalid("zip_code")}
                                disabled={isDisabled || isReadOnly}
                            />
                        </>
                    )}
                    {/*
                    {isNotPartnerMode &&
                        this.renderSelectBasis()
                    }
                    */}
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        companyProfile: state.profile.companyProfile,
        companyProfileLoading: state.profile.companyProfileLoading,
        clients: state.auth.user ? state.auth.user.clients : [],
        profile: state.profile,
        user: state.auth.user,
    };
};

export default withRouter(
    connect(mapStateToProps, { companyProfileUpdate })(
        withTranslation("common")(CompanyProfileForm)
    )
);
