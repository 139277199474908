import {updateObject} from "../utility";
import * as actionTypes from "../actions/actionTypes";


const initialCompanyProfile = {
    name: "",
    reg_number: "",
    email: "",
    iban: "",
    kmkr: "",
    full_address: "",
    basis: ""
};

const initialPersonalProfile = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    profession: "",
};

const initialUserSettings = {};

const initialState = {
    companyProfile: initialCompanyProfile,
    companyProfileLoading: false,
    personalProfile: initialPersonalProfile,
    personalProfileLoading: false,
    contracts: [],
    contractsLoading: false,
    users: [],
    usersLoading: false,
    representationOptions: [],
    userSettings: initialUserSettings,
    userSettingsLoading: false,
    loading: false,
    userSettingsError: null,
};

const resetUserSpecificData = (state, action) => {
    return updateObject(state, {  
        companyProfile: initialCompanyProfile,
        personalProfile: initialPersonalProfile,
        contracts: [],
        users: [],
        userSettings: initialUserSettings,
    });
}

const companyProfileUpdate = (state, action) => {
    return updateObject(state, {
        companyProfile: {
            ...state.companyProfile,
            [action.key]: action.value
        }
    });
};

const companyProfileFetchStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        companyProfileLoading: true,
        companyProfile: initialCompanyProfile,
    })
};

const companyProfileFetchSuccess = (state, profile) => {
    return updateObject(state, {
        companyProfile: profile,
        loading: false,
        companyProfileLoading: false,
    })
};

const companyProfileFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false,
        companyProfileLoading: false,
    })
};

const personalProfileUpdate = (state, action) => {
    return updateObject(state, {
        personalProfile: {
            ...state.personalProfile,
            [action.key]: action.value
        }
    });
};

const personalProfileFetchStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        personalProfileLoading: true,
        personalProfile: initialPersonalProfile,
    })
};

const personalProfileFetchSuccess = (state, profile) => {
    return updateObject(state, {
        personalProfile: profile,
        loading: false,
        personalProfileLoading: false,
    })
};

const personalProfileFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false,
        personalProfileLoading: false,
    })
};

const representationOptionsFetchStart = (state, action) => {
    return updateObject(state, {
        loading: true
    })
};

const representationOptionsFetchSuccess = (state, options) => {
    return updateObject(state, {
        representationOptions: options,
        loading: false
    })
};

const representationOptionsFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false
    })
};

const contractsFetchStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        contracts: [],
        contractsLoading: true,
    })
};

const contractsFetchSuccess = (state, contracts) => {
    return updateObject(state, {
        contracts: contracts,
        loading: false,
        contractsLoading: false,
    })
};

const contractsFetchFail = (state, action) => {
    return updateObject(state, {
        errorMessage: action.error,
        loading: false,
        contractsLoading: false,
    })
};

const fetchUsersStart = (state) => {
    return updateObject(state, {
        loading: true,
        users: [],
        usersLoading: true,
    })
};

const fetchUsersSuccess = (state, action) => {
    return updateObject(state, {
        users: action.users,
        loading: false,
        error: null,
        usersLoading: false,
    })
};

const fetchUsersFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false,
        usersLoading: false,
    })
};

const updateUserStart = (state) => {
    return updateObject(state, {
        loading: true,
    })
};

const updateUserSuccess = (state, updatedUser) => {
    return updateObject(state, {
        users: state.users.map(user => user.id === updatedUser.id ? updatedUser : user)
    });
};

const updateUserFail = (state, action) => {
    return updateObject(state, {
        error: action.error,
        loading: false
    })
};

const userSettingsFetchStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        userSettingsError: null,
        userSettings: initialUserSettings,
        userSettingsLoading: true,
    });
}

const userSettingsFetchSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        userSettings: action.userSettings,
        userSettingsLoading: false,
    });
}

const userSettingsFetchFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        userSettingsError: action.error,
        userSettingsLoading: false,
    });
}

const userSettingsPutStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        userSettingsError: null,
        //userSettings: initialUserSettings,
    });
}

const userSettingsPutSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        userSettings: action.userSettings,
    });
}

const userSettingsPutFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        userSettingsError: action.error,
    });
}

export const profile = (state = initialState, action) => {
    switch (action.type) {
        // COMPANY PROFILE
        case actionTypes.COMPANY_PROFILE_FETCH_START:
            return companyProfileFetchStart(state, action);
        case actionTypes.COMPANY_PROFILE_FETCH_SUCCESS:
            return companyProfileFetchSuccess(state, action.profile);
        case actionTypes.COMPANY_PROFILE_FETCH_FAIL:
            return companyProfileFetchFail(state, action);
        case actionTypes.COMPANY_PROFILE_UPDATE:
            return companyProfileUpdate(state, action);
        // PERSONAL PROFILE
        case actionTypes.PERSONAL_PROFILE_FETCH_START:
            return personalProfileFetchStart(state, action);
        case actionTypes.PERSONAL_PROFILE_FETCH_SUCCESS:
            return personalProfileFetchSuccess(state, action.profile);
        case actionTypes.PERSONAL_PROFILE_FETCH_FAIL:
            return personalProfileFetchFail(state, action);
        case actionTypes.PERSONAL_PROFILE_UPDATE:
            return personalProfileUpdate(state, action);
        // CONTRACTS
        case actionTypes.CONTRACTS_FETCH_START:
            return contractsFetchStart(state, action);
        case actionTypes.CONTRACTS_FETCH_SUCCESS:
            return contractsFetchSuccess(state, action.contracts);
        case actionTypes.CONTRACTS_FETCH_FAIL:
            return contractsFetchFail(state, action);
        case actionTypes.FETCH_USERS_START:
            return fetchUsersStart(state, action);
        case actionTypes.FETCH_USERS_SUCCESS:
            return fetchUsersSuccess(state, action);
        case actionTypes.FETCH_USERS_FAIL:
            return fetchUsersFail(state, action);
        case actionTypes.UPDATE_USER_START:
            return updateUserStart(state, action);
        case actionTypes.UPDATE_USER_SUCCESS:
            return updateUserSuccess(state, action.user);
        case actionTypes.UPDATE_USER_FAIL:
            return updateUserFail(state, action);
        case actionTypes.REPRESENTATION_OPTIONS_FETCH_START:
            return representationOptionsFetchStart(state, action);
        case actionTypes.REPRESENTATION_OPTIONS_FETCH_SUCCESS:
            return representationOptionsFetchSuccess(state, action.representationOptions);
        case actionTypes.REPRESENTATION_OPTIONS_FETCH_FAIL:
            return representationOptionsFetchFail(state, action);
        // USER SETTINGS
        case actionTypes.USER_SETTINGS_FETCH_START:
            return userSettingsFetchStart(state, action);
        case actionTypes.USER_SETTINGS_FETCH_SUCCESS:
            return userSettingsFetchSuccess(state, action);
        case actionTypes.USER_SETTINGS_FETCH_FAIL:
            return userSettingsFetchFail(state, action);
        case actionTypes.USER_SETTINGS_PUT_START:
            return userSettingsPutStart(state, action);
        case actionTypes.USER_SETTINGS_PUT_SUCCESS:
            return userSettingsPutSuccess(state, action);
        case actionTypes.USER_SETTINGS_PUT_FAIL:
            return userSettingsPutFail(state, action);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);
        default:
            return state;
    }
};