import React from "react";
import { connect } from "react-redux";
import { Card, CardBody, Form, Row, Col } from "reactstrap";
import "./Profile.scss";
import PersonalProfileForm from "./PersonalProfileForm/PersonalProfileForm";
import UserSettingsForm from "./UserSettingsForm/UserSettingsForm";
import CompanyProfileForm from "./CompanyProfileForm/CompanyProfileForm";
import WarrantedUserForm from "components/Profile/Profile/WarrantedUserForm/WarrantedUserForm";

import { isClientMode, isUserClientAdmin, isAdminMode } from "util/Access";

import CheckboxButton from "components/Form/CheckboxButton/CheckboxButton";
import { withTranslation } from "react-i18next";

class Profile extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <Card className="profile-card">
                <CardBody className="">
                    <Row className="justify-content-between mb-2">
                        <Col>
                            <span className="heading">
                                {t("profile.personalData", "Isiklikud andmed")}
                            </span>
                        </Col>
                        <Col className="float-right text-right col-auto mb-3">
                            {this.props.userRole && (
                                <CheckboxButton
                                    isSelected
                                    disabled
                                    labelText={this.props.userRole}
                                    className="checkbox-button-user-role"
                                />
                            )}
                        </Col>
                    </Row>
                    <Form className="profile-form">
                        <PersonalProfileForm />
                    </Form>
                    {(isClientMode() || isAdminMode()) && (
                        <>
                            <hr className="my-2" />
                            <div className="heading my-4">
                                <span>
                                    {t(
                                        "profile.emailNotifications",
                                        "E-maili teavitused"
                                    )}
                                </span>
                            </div>
                            <Form className="profile-form">
                                <UserSettingsForm />
                            </Form>
                        </>
                    )}
                    {isClientMode() && (
                        <>
                            <hr className="my-2" />
                            <div className="heading my-4">
                                <span>
                                    {t(
                                        "profile.businessData",
                                        "Äriregistri andmed"
                                    )}
                                </span>
                            </div>
                            <Form className="profile-form">
                                <CompanyProfileForm
                                    readOnly={isClientMode() && !isUserClientAdmin()}
                                />
                            </Form>
                        </>
                    )}
                    {isClientMode() &&
                        <WarrantedUserForm clientId={this.props.match.params.id} />
                    }

                </CardBody>
            </Card >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        userRole:
            state.profile &&
            state.profile.userSettings &&
            state.profile.userSettings.role,
    };
};

export default connect(mapStateToProps)(withTranslation("common")(Profile));
