import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
    packagesList: [],
    packagesListLoading: false,
    eanTypes: [],
    categories: [],
    packageTypes: [],
    packageColors: [],
    packageVolumes: [],
    packageStatuses: [],
    packageTestResults: [],
};

const resetUserSpecificData = (state, action) => {
    return updateObject(state, {
        packagesList: [],
        packageStatuses: [],
    });
};

const resetLanguageDependentOptions = (state) => {
    return updateObject(state, {
        eanTypes: [],
        categories: [],
        packageTypes: [],
        packageColors: [],
        packageStatuses: [],
    });
};

const packagesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
        packagesList: [],
        packagesListLoading: true,
    });
};

const packagesFetchSuccess = (state, packages) => {
    return updateObject(state, {
        packagesList: packages,
        loading: false,
        packagesListLoading: false,
    });
};

const packagesFetchFail = (state, err) => {
    return updateObject(state, {
        packagesList: [],
        error: err,
        loading: false,
        packagesListLoading: false,
    });
};

const eanTypesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const eanTypesFetchSuccess = (state, eanTypes) => {
    return updateObject(state, {
        eanTypes: eanTypes,
    });
};

const eanTypesFetchFail = (state, err) => {
    return updateObject(state, {
        eanTypes: [],
        error: err,
    });
};

const categoriesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const categoriesFetchSuccess = (state, categories) => {
    return updateObject(state, {
        categories: categories,
        loading: false,
    });
};

const categoriesFetchFail = (state, err) => {
    return updateObject(state, {
        categories: [],
        loading: false,
        error: err,
    });
};

const packageTypesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const packageTypesFetchSuccess = (state, packageTypes) => {
    return updateObject(state, {
        packageTypes: packageTypes,
        loading: false,
    });
};

const packageTypesFetchFail = (state, err) => {
    return updateObject(state, {
        packageTypes: [],
        loading: false,
        error: err,
    });
};

const packageColorsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const packageColorsFetchSuccess = (state, packageColors) => {
    return updateObject(state, {
        packageColors: packageColors,
        loading: false,
    });
};

const packageColorsFetchFail = (state, err) => {
    return updateObject(state, {
        packageColors: [],
        loading: false,
        error: err,
    });
};

const packageVolumesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const packageVolumesFetchSuccess = (state, packageVolumes) => {
    return updateObject(state, {
        packageVolumes: packageVolumes,
        loading: false,
    });
};

const packageVolumesFetchFail = (state, err) => {
    return updateObject(state, {
        packageVolumes: [],
        loading: false,
        error: err,
    });
};

const packageStatusesFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const packageStatusesFetchSuccess = (state, packageStatuses) => {
    return updateObject(state, {
        packageStatuses: packageStatuses,
        loading: false,
    });
};

const packageStatusesFetchFail = (state, err) => {
    return updateObject(state, {
        packageStatuses: [],
        loading: false,
        error: err,
    });
};

const packageTestResultsFetchStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const packageTestResultsFetchSuccess = (state, packageTestResults) => {
    return updateObject(state, {
        packageTestResults: packageTestResults,
        loading: false,
    });
};

const packageTestResultsFetchFail = (state, err) => {
    return updateObject(state, {
        loading: false,
        error: err,
    });
};

const packagePostStart = (state) => {
    return updateObject(state, {
        loading: true,
    });
};

const packagePostSuccess = (state) => {
    return updateObject(state, {
        error: null,
        loading: false,
    });
};

const packagePostFail = (state, err) => {
    return updateObject(state, {
        loading: false,
        error: err,
    });
};

const packages = (state = initialState, action) => {
    switch (action.type) {
        // PACKAGES
        case actionTypes.PACKAGES_FETCH_START:
            return packagesFetchStart(state, action);
        case actionTypes.PACKAGES_FETCH_SUCCESS:
            return packagesFetchSuccess(state, action.packagesList);
        case actionTypes.PACKAGES_FETCH_FAIL:
            return packagesFetchFail(state, action);

        // EAN TYPES
        case actionTypes.EAN_TYPES_FETCH_START:
            return eanTypesFetchStart(state, action);
        case actionTypes.EAN_TYPES_FETCH_SUCCESS:
            return eanTypesFetchSuccess(state, action.eanTypes);
        case actionTypes.EAN_TYPES_FETCH_FAIL:
            return eanTypesFetchFail(state, action);

        // CATEGORIES
        case actionTypes.PRODUCT_CATEGORIES_FETCH_START:
            return categoriesFetchStart(state, action);
        case actionTypes.PRODUCT_CATEGORIES_FETCH_SUCCESS:
            return categoriesFetchSuccess(state, action.categories);
        case actionTypes.PRODUCT_CATEGORIES_FETCH_FAIL:
            return categoriesFetchFail(state, action);

        // PACKAGE TYPES
        case actionTypes.PACKAGE_TYPES_FETCH_START:
            return packageTypesFetchStart(state, action);
        case actionTypes.PACKAGE_TYPES_FETCH_SUCCESS:
            return packageTypesFetchSuccess(state, action.packageTypes);
        case actionTypes.PACKAGE_TYPES_FETCH_FAIL:
            return packageTypesFetchFail(state, action);

        // COLORS
        case actionTypes.PACKAGE_COLORS_FETCH_START:
            return packageColorsFetchStart(state, action);
        case actionTypes.PACKAGE_COLORS_FETCH_SUCCESS:
            return packageColorsFetchSuccess(state, action.packageColors);
        case actionTypes.PACKAGE_COLORS_FETCH_FAIL:
            return packageColorsFetchFail(state, action);

        // VOLUMES
        case actionTypes.PACKAGE_VOLUMES_FETCH_START:
            return packageVolumesFetchStart(state, action);
        case actionTypes.PACKAGE_VOLUMES_FETCH_SUCCESS:
            return packageVolumesFetchSuccess(state, action.packageVolumes);
        case actionTypes.PACKAGE_VOLUMES_FETCH_FAIL:
            return packageVolumesFetchFail(state, action);

        // STATUSES
        case actionTypes.PACKAGE_STATUSES_FETCH_START:
            return packageStatusesFetchStart(state, action);
        case actionTypes.PACKAGE_STATUSES_FETCH_SUCCESS:
            return packageStatusesFetchSuccess(state, action.packageStatuses);
        case actionTypes.PACKAGE_STATUSES_FETCH_FAIL:
            return packageStatusesFetchFail(state, action);

        // TEST RESULTS
        case actionTypes.PACKAGE_TEST_RESULTS_FETCH_START:
            return packageTestResultsFetchStart(state, action);
        case actionTypes.PACKAGE_TEST_RESULTS_FETCH_SUCCESS:
            return packageTestResultsFetchSuccess(
                state,
                action.packageTestResults
            );
        case actionTypes.PACKAGE_TEST_RESULTS_FETCH_FAIL:
            return packageTestResultsFetchFail(state, action);

        // POST PACKAGE
        case actionTypes.PACKAGE_POST_START:
            return packagePostStart(state);
        case actionTypes.PACKAGE_POST_SUCCESS:
            return packagePostSuccess(state);
        case actionTypes.PACKAGE_POST_FAIL:
            return packagePostFail(state, action.error.message);

        case actionTypes.LANGUAGE_UPDATE:
            return resetLanguageDependentOptions(state);

        case actionTypes.GET_AUTH_USER:
            return resetUserSpecificData(state, action);

        default:
            return state;
    }
};

export default packages;
