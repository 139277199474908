import * as actionTypes from "./actionTypes";

export const notificationsFetchStart = () => {
    return {
        type: actionTypes.NOTIFICATIONS_FETCH_START,
    };
};

export const notificationsFetchSuccess = (data) => {
    return {
        type: actionTypes.NOTIFICATIONS_FETCH_SUCCESS,
        data,
    };
};

export const notificationsFetchFail = (err) => {
    return {
        type: actionTypes.NOTIFICATIONS_FETCH_FAIL,
        error: err,
    };
};
